import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import EventClipsContainer from '../../smart/menuComponentsContainer/EventClipsContainer';
import EventFlagged from '../../smart/menuComponentsContainer/EventFlagged';
import EventClipsQuarantine from '../../smart/menuComponentsContainer/EventClipsQuarantine';
import GreyHeader from '../../GreyHeader'
import CustomButton from '../../CustomButton';
import { sendGA } from '../../helpers/helperUsers'

const Events = (props) => {
	const { roles, permissions } = props.user;
	const userRole = roles[0];
	const { t } = useTranslation();
	const [tabKey, setTabKey] = useState(1)
	const [location, setLocation] = useState(null)
	const [selectedEvent, setSelectedEvent] = useState()

	useEffect(() => {
		const { location } = props;
		if (location && location.state) {
			setLocation(location)
		}
		else {
			setLocation(false)
		}
	}, [])

	useEffect(() => {
		const { location } = props
		if (location && location.state) {
			setLocation(location)
		}
		else {
			setLocation(false)
		}
	}, [props.location])

	const handleSelect = (tabKey, selectedEvent = null) => {
		console.log("handling select")
		selectedEvent && setSelectedEvent(selectedEvent);
		setTabKey(tabKey);

		const label = tabKey === 1 ? 'Daily Events' : tabKey === 2 ? 'Flagged Events' : 'Quarantine'
		sendGA({ category: userRole, action: 'Change Events Tab', label })
	};

	const hasEditPermission = !(['custom_user', 'custom_partner'].includes(userRole) && _.find(permissions, { 'perm_category_id': 2, 'a_edit': false }))

	return (
		<div className="events-page">
			<GreyHeader
				title={t('eventsPage.events.titleEvents')}
				renderSubtitle={
					hasEditPermission &&
					(<nav className='page-subtitle'>
						<CustomButton variant='subtitle' active={tabKey === 1} onClick={() => handleSelect(1)}>
							{t('eventsPage.events.btnDailyEvents')}
						</CustomButton>
						<CustomButton variant='subtitle' active={tabKey === 2} onClick={() => handleSelect(2)}>
							{t('eventsPage.events.btnFlaggedEvents')}
						</CustomButton>
						<CustomButton variant='subtitle' active={tabKey === 3} onClick={() => handleSelect(3)}>
							{t('eventsPage.events.btnQuarantine')}
						</CustomButton>
					</nav>)
				}
			/>

			{/* {location !== null && (tabKey === 1 ? <EventClipsContainer location={location} /> : <EventFlagged />)} */}
			{location !== null && tabKey === 1 && <EventClipsContainer location={location} handleSelect={handleSelect} />}
			{location !== null && tabKey === 2 && <EventFlagged />}
			{location !== null && tabKey === 3 && <EventClipsQuarantine selectedEvent={selectedEvent} />}
		</div>
	)
}

Events.propTypes = {
	user: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = ({ user }) => ({
	user: user.user
});

export default connect(mapStateToProps)(Events);
