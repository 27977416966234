import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import './Releases.sass'
import { apiCall } from '../../action/RouterActions'
import { useState } from 'react'
import { useEffect } from 'react'
import CustomButton from '../../component/CustomButton';

const ModalProjectVersion = (props) => {
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'releasesPage.modalProjectVersion' });
	const { version, onHide, toggleLoader } = props;

	const [number1, setNumber1] = useState('');
	const [number2, setNumber2] = useState('');

	useEffect(() => {
		const numbers = version.split('.')

		setNumber1(+numbers[1])
		setNumber2(+numbers[2])
	}, [])

	const fillForm = (e, name) => {
		if (name === 'number1') {
			setNumber1(number1 + 1)
			setNumber2(0)
		} else {
			setNumber2(number2 + 1)
		}
	}

	const handleClickSave = () => {
		const { updateProjectVersion } = props

		const newVersion = `2.${number1}.${number2}`

		if (version === newVersion) {
			toastr.warning('', t('changeProjectVersion'))
		} else {
			toggleLoader(true)
			apiCall('POST', '/version', { content: newVersion })
				.then((res, err) => {
					toggleLoader(false)
					toastr.success('Success', t('projectVersionChanged'))
					updateProjectVersion(newVersion)
					onHide()
				})
				.catch((error) => {
					toggleLoader(false)
					let errDescription = i18n.t('errorMessage.errDescription');
					if (error.response.data.response.error) {
						errDescription = error.response.data.response.error
					}
					toastr.error(errDescription);
				})
		}
	}

	const versionNumbers = version.split('.')

	return (
		<Modal
			size='sm'
			show
			onHide={onHide}
			dialogClassName="custom-request-modal"
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-lg">{t('titleUpdateVersion')}</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<div>{t('currentVersion')}<b>{version}</b></div>
				<div>{t('newVersion')}<b>{`2.${number1}.${number2}`}</b></div>
				<div className="version-wrapper">
					<div className="version-number">
						<div>2</div>
					</div>
					<div className="version-number">
						<div>{number1}</div>
						{+versionNumbers[1] === +number1
							&& (
								<Button
									variant='success'
									name="1"
									onClick={e => fillForm(e, 'number1')}
								>
									<i className="fa fa-plus" />
								</Button>
							)}
					</div>
					<div className="version-number">
						<div>{number2}</div>
						{+versionNumbers[2] === +number2
							&& (
								<Button
									variant='success'
									name="2"
									onClick={e => fillForm(e, 'number2')}
								>
									<i className="fa fa-plus" />
								</Button>
							)}
					</div>
				</div>
			</Modal.Body>

			<Modal.Footer bsPrefix="default-modal-footer modal-footer">
				<CustomButton
					variant='primary'
					onClick={handleClickSave}
				>
				{t('btnSave')}
				</CustomButton>
			</Modal.Footer>
		</Modal>
	)
}

ModalProjectVersion.propTypes = {
	version: PropTypes.string.isRequired,
	onHide: PropTypes.func.isRequired,
	updateProjectVersion: PropTypes.func.isRequired
}

export default connect(
	state => ({
		version: state.user.version
	}),
	dispatch => ({
		updateProjectVersion: (version) => {
			dispatch({ type: 'SET_PROJECT_VERSION', payload: version });
		},
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(ModalProjectVersion);
