import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Button } from 'react-bootstrap'
import ReactBootstrapSlider from 'react-bootstrap-slider'
import moment from 'moment'
import _ from 'lodash'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { FaChevronLeft, FaChevronRight, FaPlay, FaPause } from 'react-icons/fa'
import momenttz from '../../../component/helpers/momenttz'
import useInterval from '../../../component/common/UseInterval'

import './TripReplayControls.sass'
import 'bootstrap-slider/dist/css/bootstrap-slider.css'

// const usePreviousValue = value => {
// 	// debugger
// 	const ref = useRef(null);
// 	useEffect(() => {
// 		// debugger
// 		ref.current = value;
// 	});
// 	return ref.current;
// }

const TripReplayControls = (props) => {
	const { tripsGPS, tripTimelineTimestamp, selectedTrip, setTripTimelineTimestamp, onClickPlayback, setTripPlaybackTimestamp } = props;
	const { t } = useTranslation('translation', { keyPrefix: 'homePage.vehicleSidebar.tripsGrid.tripReplayControls' });
	const [intervalPlayback, setIntervalPlayback] = useState(null)
	const [intervalLongPress, setIntervalLongPress] = useState(null)
	const [initPlaybackSpeed, setInitPlaybackSpeed] = useState(1)
	const [playbackSpeed, setPlaybackSpeed] = useState(1)
	const [currentTimelineDate, setCurrentTimelineDate] = useState(null)
	// const prevCurrentTimelineDate = usePreviousValue(currentTimelineDate)
	const [isPlaying, setIsPlaying] = useState(false)
	const [playbackDuration, setPlaybackDuration] = useState(1) // selected speed of playback (30sec, 45sec, Real Time)

	const [sliderValManually, setSliderValManually] = useState(0)
	const [delay, setDelay] = useState(null)
	const [speed, setSpeed] = useState(0)

	useEffect(() => {
		if (sliderValManually > 0) {
			// debugger
			const val = sliderValManually;
			// const currentTimelineDate = momenttz(selectedTrip.start_gps.timestamp).add(val, 'seconds')

			const currentTimelineDate = momenttz(selectedTrip.start_gps.timestamp + '+00').add(val, 'seconds')
			// const currentTimelineDate = moment(selectedTrip.start_gps.timestamp).add(val, 'seconds')

			// const currentTimelineDate = momenttz(selectedTrip.start_gps.timestamp+'+00').add(val, 'seconds')
			// console.log('!!!!!!ADD0 currentTimelineDate: ', selectedTrip.start_gps.timestamp)
			// console.log('!!!!!!ADD1 currentTimelineDate: ', momenttz(selectedTrip.start_gps.timestamp).format('YYYY-MM-DD HH:mm:ss'))
			// console.log('!!!!!!ADD2 currentTimelineDate: ', currentTimelineDate.format('YYYY-MM-DD HH:mm:ss'))
			setCurrentTimelineDate(currentTimelineDate)
			setTripTimelineTimestamp(currentTimelineDate.format('YYYY-MM-DD HH:mm:ss'))
		}
	}, [sliderValManually])

	useEffect(() => {
		// debugger
		// console.log('props: ', props)
		if (tripsGPS.length) {
			// debugger
			setCurrentTimelineDate(tripsGPS[0].polygon.length ? moment(tripsGPS[0].polygon[0].timestamp) : null)
			setIsPlaying(false)
			setDelay(null)

			if (intervalPlayback) {
				clearInterval(intervalPlayback)
				setIntervalPlayback(null)
			}
		}

	}, [tripsGPS])

	useEffect(() => {
		if (selectedTrip && selectedTrip.duration > 45) {
			// debugger
			let playbackSpeed = (selectedTrip.duration / 45).toFixed(1);
			setPlaybackSpeed(playbackSpeed);
			setInitPlaybackSpeed(playbackSpeed);
			setPlaybackDuration(1)

			if (intervalPlayback) {
				clearInterval(intervalPlayback)
				setIntervalPlayback(null)
			}
		}
	}, [selectedTrip])

	useEffect(() => {
		if (tripTimelineTimestamp) {
			// debugger
			setCurrentTimelineDate(moment(tripTimelineTimestamp))
			setIsPlaying(false)
			setDelay(null)

			if (intervalPlayback) {
				clearInterval(intervalPlayback)
				setIntervalPlayback(null)
			}
		}
	}, [tripTimelineTimestamp])

	useInterval(() => {
		// let speed = playbackSpeed
		const currentPlaybackTime = moment(currentTimelineDate).add((1 * Math.abs(speed) / 10), 'seconds')
		const pos = _.find(tripsGPS[0].polygon, (item) => {
			return moment(item.timestamp) >= currentPlaybackTime
		})
		if (pos) {
			setCurrentTimelineDate(currentPlaybackTime)
			onClickPlayback(currentPlaybackTime)
			setTripPlaybackTimestamp(currentPlaybackTime.format('YYYY-MM-DD HH:mm:ss'))
		} else {
			// clearInterval(this.intervalPlayback)
			// setIntervalPlayback(null)
			// setCurrentTimelineDate(currentPlaybackTime)	
			setDelay(null)
		}
	}, delay);
	// }, isPaused ? null : 100);

	const playStopPlayback = (e, playback_speed, argDelay) => {
		// console.log('playStopPlayback tripsGPS: ', tripsGPS)
		// if (intervalPlayback) {
		// 	clearInterval(intervalPlayback)
		// 	setIntervalPlayback(null)
		// 	setIsPlaying(false)
		// }
		const varDelay = argDelay === undefined ? delay : argDelay

		if (varDelay) {
			setDelay(null)
			setIsPlaying(false)
		}
		else {
			let speedNew = playbackSpeed
			if (playback_speed) {
				speedNew = playback_speed
			}
			let add_sec = 1
			if (speedNew < 0) {
				add_sec = -1
			}

			setSpeed(speedNew)
			setDelay(100)
			setIsPlaying(true)
		}
	}

	const changePlaybackDuration = (event) => {
		let value = event.target && event.target.value;
		let speed = value !== "0" ? value * initPlaybackSpeed : 1;

		// if (intervalPlayback) {
		// 	clearInterval(intervalPlayback)
		// 	setIntervalPlayback(null)
		// 	playStopPlayback(event, speed)
		// }
		if (delay) {
			playStopPlayback(event, speed, null)
		}

		setPlaybackSpeed(speed);
		setPlaybackDuration(value)
	}

	// const changeTripControlSlider = (e, newval) => {
	const changeTripControlSlider = (e) => {
		// console.log('!!!changeTripControlSlider e.target.value: ', e.target.value)
		setSliderValManually(e.target.value)
		// // debugger
		// console.log('changeTripControlSlider props: ', props)
		// console.log('changeTripControlSlider argProps: ', argProps)
		// // const val = (typeof (newval) !== 'undefined') ? newval : e.target.value;
		// const val = e.target.value;
		// // const currentTimelineDate = momenttz(selectedTrip.start_gps.timestamp).add(val, 'seconds')
		// const currentTimelineDate = momenttz(argProps.selectedTrip.start_gps.timestamp).add(val, 'seconds')
		// // console.log('!!!!!!ADD0 currentTimelineDate: ', selectedTrip.start_gps.timestamp)
		// // console.log('!!!!!!ADD1 currentTimelineDate: ', momenttz(selectedTrip.start_gps.timestamp).format('YYYY-MM-DD HH:mm:ss'))
		// // console.log('!!!!!!ADD2 currentTimelineDate: ', currentTimelineDate.format('YYYY-MM-DD HH:mm:ss'))
		// setCurrentTimelineDate(currentTimelineDate)
		// setTripTimelineTimestamp(currentTimelineDate.format('YYYY-MM-DD HH:mm:ss'))
	}

	const clearIntervalLongPress = () => {
		if (intervalLongPress) {
			clearInterval(intervalLongPress);
			setIntervalLongPress(null)
		}
	}

	const handleArrowDown = (direction) => {
		clearIntervalLongPress()
		let timer = 0
		let secs = 1
		setIntervalLongPress(setInterval(() => {
			timer += 1
			if (timer < 20) {
				secs = 5
			} else if (timer < 40) {
				secs = 30
			} else {
				secs = 60
			}

			let shiftTime = null
			if (direction === 'prev') {
				// console.log('PREV: ')
				shiftTime = currentTimelineDate.add(-secs, 'seconds')
				// console.log('secs: ', secs)
				// console.log('currentTimelineDate: ', currentTimelineDate)
				// console.log('shiftTime: ', shiftTime)
				// console.log('selectedTrip.end_gps.timestamp: ', selectedTrip.end_gps.timestamp)
				if (shiftTime < momenttz(selectedTrip.start_gps.timestamp + '+00')) {
					shiftTime = momenttz(selectedTrip.start_gps.timestamp + '+00')
				}
				// console.log('shiftTime1: ', shiftTime)
			} else {
				// console.log('NEXT: ')
				shiftTime = currentTimelineDate.add(secs, 'seconds')
				// console.log('secs: ', secs)
				// console.log('currentTimelineDate: ', currentTimelineDate)
				// console.log('shiftTime: ', shiftTime)
				// console.log('selectedTrip.end_gps.timestamp: ', selectedTrip.end_gps.timestamp)
				if (shiftTime > momenttz(selectedTrip.end_gps.timestamp + '+00')) {
					shiftTime = momenttz(selectedTrip.end_gps.timestamp + '+00')
				}
				// console.log('shiftTime1: ', shiftTime)
			}

			setCurrentTimelineDate(shiftTime)
			// if (!intervalPlayback) {
			// 	setTripTimelineTimestamp(shiftTime.format('YYYY-MM-DD HH:mm:ss'))
			// }
			if (!delay) {
				setTripTimelineTimestamp(shiftTime.format('YYYY-MM-DD HH:mm:ss'))
			}
		}, 100))
	}

	// console.log('PROPS TripReplayControls: ', props)		
	const isDisabledControls = _.isEmpty(selectedTrip)

	let options = [<option key={1.5} value={1.5}>{t('option30s')}</option>];
	if (selectedTrip) {
		if (selectedTrip.duration > 45) {
			options.push(
				<option key={1} value={1}>{t('option45s')}</option>
			)
		}
		if (selectedTrip.duration > 60) {
			options.push(<option key={0.75} value={0.75}>{t('option1m')}</option>)
		}
		if (selectedTrip.duration > 120) {
			options.push(<option key={0.375} value={0.375}>{t('option2m')}</option>)
		}
		options.push(<option key={0} value={0}>{t('optionRealTime')}</option>);
	}

	let sliderValue = 0
	if (currentTimelineDate && !isDisabledControls) {
		// sliderValue = currentTimelineDate.diff(moment(selectedTrip.start_gps.timestamp + '+00'), 'seconds')
		sliderValue = currentTimelineDate.diff(momenttz(selectedTrip.start_gps.timestamp + '+00'), 'seconds')
	}

	let sliderMax = 10
	if (!isDisabledControls) {
		sliderMax = selectedTrip.duration
	}

	// console.log('!!!!!!!!!!currentTimelineDate :', currentTimelineDate)
	// console.log('!!!!!!!!!!sliderValue :', sliderValue)
	// console.log('!!!!!!!!!!selectedTrip.start_gps.timestamp :', selectedTrip ? selectedTrip.start_gps.timestamp : '')
	// console.log('!!!!!!!!!!currentTimelineDate2 :', currentTimelineDate && currentTimelineDate.format('YYYY-MM-DD h:mm:ss a'))
	// console.log('!!!!!!!!!!currentTimelineDate1 :', selectedTrip.start_gps.timestamp)
	return (
		<div className={`tripReplayControls ${isDisabledControls && 'disabled'}`}>
			<div className="header">
				{t('titleTripReplayControls')}
			</div>
			<div>
				<div className="timeWrapper">
					<div>
						<b>{t('titleStart')}</b>
						<div className="timeValue">
							{!isDisabledControls && selectedTrip.start_gps
								? momenttz(`${selectedTrip.start_gps.timestamp}+00`).format('h:mm:ss a')
								: '--:--:--'
							}
							{/* 9:32:16 am */}
						</div>
					</div>
					<div className="currentValueWrapper">
						{/* <span
							className="glyphicon glyphicon-chevron-left arrow prevTime"
							onMouseDown={() => handleArrowDown('prev')}
							onMouseUp={clearIntervalLongPress}
						/> */}
						<FaChevronLeft
							className="arrow prevTime"
							onMouseDown={() => handleArrowDown('prev')}
							onMouseUp={clearIntervalLongPress}
						/>
						<span className="currentValue">
							{currentTimelineDate
								// ? momenttz(currentTimelineDate.format('YYYY-MM-DD HH:mm:ss+00')).format('h:mm:ss a')
								// ? momenttz(currentTimelineDate.format('YYYY-MM-DD HH:mm:ss')).format('h:mm:ss a')
								? momenttz(currentTimelineDate).format('h:mm:ss a')
								: '--:--:--'
							}
							{/* 11:40:26 am */}
						</span>
						{/* <span
							className="glyphicon glyphicon-chevron-right arrow nextTime"
							onMouseDown={() => handleArrowDown('next')}
							onMouseUp={clearIntervalLongPress}
						/> */}
						<FaChevronRight
							className="arrow nextTime"
							onMouseDown={() => handleArrowDown('next')}
							onMouseUp={clearIntervalLongPress}
						/>
					</div>
					<div>
						<b>{(!isDisabledControls && selectedTrip.isLiveTrip) ? t('titleLive') : t('titleEnd') }</b>
						<div className="timeValue">
							{!isDisabledControls && selectedTrip.end_gps
								? momenttz(`${selectedTrip.end_gps.timestamp}+00`).format('h:mm:ss a')
								: '--:--:--'
							}
							{/* 1:14:52 pm */}
						</div>
					</div>
				</div>
				<ReactBootstrapSlider
					id="video-slider"
					name="horizontalSliderName"
					value={sliderValue}
					max={sliderMax}
					min={0}
					step={1}
					tooltip="hide"
					// change={(e) => changeTripControlSlider(e, props)}
					change={changeTripControlSlider}
					disabled={isDisabledControls ? 'disabled' : 'enabled'}
				/>
				<Row className="text-center" style={{ margin: 'auto' }}>
					<Col xs={3}>
						{/* <span
							className={`glyphicon startPlayback ${isPlaying ? 'glyphicon-pause' : 'glyphicon-play'}`}
							onClick={(e) => isDisabledControls ? null : playStopPlayback(e)}
						/> */}
						{isPlaying
							? <FaPause className="startPlayback" onClick={(e) => isDisabledControls ? null : playStopPlayback(e)} />
							: <FaPlay className="startPlayback" onClick={(e) => isDisabledControls ? null : playStopPlayback(e)} />
						}
					</Col>
					{selectedTrip && selectedTrip.duration > 30 &&
						<Col xs={9} style={{ textAlign: 'right' }}>
							<b style={{ fontSize: '16px' }}>{t('titleReplayDuration')}</b>
							<select
								id="trip-duration-select"
								placeholder={t('option45s')}
								onChange={(e) => changePlaybackDuration(e)}
								value={playbackDuration}
							>
								{options}
							</select>
						</Col>
					}
				</Row>
			</div>
		</div>
	)
}

const mapStateToProps = ({ dashboardData }) => ({
	tripsGPS: dashboardData.tripsGPS,
	selectedTrip: dashboardData.selectedTrip,
	tripTimelineTimestamp: dashboardData.tripTimelineTimestamp
});

const mapDispatchToProps = dispatch => ({
	setTripTimelineTimestamp: (state) => {
		dispatch({ type: 'SET_TRIP_TIMELINE_TIMESTAMP', payload: state })
	},
	setTripPlaybackTimestamp: (state) => {
		dispatch({ type: 'SET_TRIP_PLAYBACK_TIMESTAMP', payload: state })
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(TripReplayControls);
