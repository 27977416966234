import React, { useState, useEffect } from 'react'
import { Button, Panel } from 'react-bootstrap'
// import { Link } from 'react-router'
import { toastr } from 'react-redux-toastr'
import { connect } from 'react-redux'
import classnames from 'classnames'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import AutocompleteComponent from '../../common/AutocompleteComponent'
import ModemFirmwaresListBlock from './ModemFirmwaresListBlock'
import { getAvailableModemList, pushModemFile } from '../../../action/FleetAdminActions'
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";

import CustomButton from '../../CustomButton';

const FleetAdminModemUploadForm = (props) => {
    const { t, i18n } = useTranslation('translation', { keyPrefix: 'modemsPage.fleetAdminModemContainer.fleetAdminModemUploadForm' });
    const { toggleLoader } = props;

    const [showPanelBody, setShowPanelBody] = useState(true);
    const [modems, setModems] = useState([]);
    const [file, setFile] = useState('');
    const [model, setModel] = useState('');
    const [version, setVersion] = useState('');
    const [release_note, setRelease_note] = useState('');
    const [errfile, setErrfile] = useState('');
    const [errversion, setErrversion] = useState('');
    const [errmodel, setErrmodel] = useState('');

    useEffect(() => {
        // console.log('!!!!!!!!componentDidMount FleetAdminModemUploadForm')
        getModemsList();
    }, []);

    const getModemsList = () => {
        toggleLoader(true)

        getAvailableModemList().then((res, err) => {
            console.log('!getAvailableModemList res:', res, err)
            const modems = res.data.response.modems
            setModems(modems);
            toggleLoader(false)
        })
            .catch((error) => {
                toggleLoader(false)
                setModems([]);
                let errDescription = i18n.t('errorMessage.errDescription');
                if (error.response.data.response.error) {
                    errDescription = error.response.data.response.error
                }
                toastr.error(errDescription)
            })
    }

    const handleInputChange = (event) => {
        const target = event.target,
            value = target.type === 'checkbox' ? target.checked : target.value,
            name = target.name
        if (name === "release_note") setRelease_note(value)
    }

    const onFileChange = (event) => {
        const value = event.target.value,
            file = event.target.files[0] || ''

        setFile(file);

        let i
        if (value.lastIndexOf('\\')) {
            i = value.lastIndexOf('\\') + 1;
            console.log('first', value.lastIndexOf('\\'));
        }
        else {
            i = value.lastIndexOf('/') + 1;
            console.log('second');
        }
        const filename = value.slice(i);
        uploadFileNameClear(filename);
    }

    const uploadFileNameClear = (filename) => {
        const upload_file_name = document.getElementById("upload-form-label");
        upload_file_name.innerHTML = filename || "";
    }

    const checkUploadModem = (data, event) => {
        console.log('checkUploadModem', data)
        let error = false,
            errMessage = { errfile: '', errmodel: '', errversion: '' }

        for (let i in data) {
            if (i != 'release_note' && (data[i] == '' || data[i] == 'undefined')) {
                error = true;
                errMessage['err' + i] = t('enterYour') + i + '!';
            }
            else if (errMessage[i]) {
                errMessage['err' + i] = null;
            }
        }

        // if (!errMessage['errfile'] && data.file.name.split(".").pop() !== 'fw') {
        //     // alert('TTT')
        //     error = true;
        //     errMessage['errfile'] = 'Select file with extension ".fw"';
        // }
        setErrfile(errMessage.errfile);
        setErrmodel(errMessage.errmodel);
        setErrversion(errMessage.errversion);
        return error
    }

    const uploadModem = (event) => {
        event.preventDefault()

        const data = {
            file,
            model,
            version,
            release_note
        },
            error = checkUploadModem(data, event)

        if (!error) {
            toggleLoader(true)

            pushModemFile(data).then((res, err) => {
                console.log('!pushModemFile res:', res)
                // toggleLoader(false)

                // uploadFileNameClear();
                // setFile("");
                getModemsList()
                toastr.success('Success', t('modemFirmwareUploaded'))
            })
                .catch((error) => {
                    toggleLoader(false)
                    console.log('!pushModemFile error:', error.response)
                    let errDescription = i18n.t('errorMessage.errDescription');
                    if (error.response.data.response.error) {
                        errDescription = error.response.data.response.error
                    }
                    toastr.error(errDescription)
                })
        }
    }

    const togglePanelBody = () => {
        setShowPanelBody(!showPanelBody);
    }

    const
        models = _.sortBy(_.uniq(_.map(modems, 'model'))),
        versions = _.sortBy(_.uniq(_.map(modems.filter(modem => modem.model == model), 'version'))),
        // { errfile, errmodel, errversion } = errMessage,
        textright = { right: '10px' },
        release_note_label = { verticalAlign: 'top', fontWeight: 400, marginRight: '5px' },
        file_uploader_bottom = { paddingBottom: '10px' };

    //         const models = _.map(modems, i => _.pick(i, 'model', 'version'))

    return (
        <div className="panel panel-default modem-uploader-container" style={{ marginTop: '40px' }}>
            <div className="panel-heading" onClick={togglePanelBody}>
                <span className='toggle-accordion'>
                    {showPanelBody ? <AiFillCaretUp /> : <AiFillCaretDown />}
                </span>
                <h5>{t('titleModemUploader')}</h5>
            </div>
            <div className={`panel-body ${!showPanelBody ? "hide" : ""}`} style={{ margin: '20px 0' }}>

                <div className="col-md-12 modem-uploader-block">
                    <div className="autoselect-container">
                        <div className={classnames('autoselect-block', { 'has-error': errmodel })}>
                            <AutocompleteComponent
                                label={t('labelModemModel')}
                                items={models}
                                value={model}
                                placeholder={t('placeholderSelect/Type')}
                                //                            placeholder={_.get(this,'props.company.company.partner')}
                                onChange={(e, value) => {
                                    // console.log('onChange', e.target.value)
                                    setModel(value)

                                    const _model = _.find(modems, ['model', value])
                                    if (value && !_model) {
                                        setErrmodel(t('newModemModel'))
                                    }
                                    else {
                                        setErrmodel('')
                                    }
                                }}
                                onSelect={(value) => {
                                    // console.log('onSelect', value)
                                    setModel(value);
                                    setErrmodel('')
                                }}
                                clear={() => {
                                    setModel('');
                                    setVersion('');
                                    setErrmodel('');
                                    setErrversion('');
                                }}
                            />
                            {errmodel && <span className="help-block" style={textright}>{errmodel}</span>}
                        </div>

                        <div className={classnames('autoselect-block', { 'has-error': errversion })}>
                            <AutocompleteComponent
                                label={t('labelModemVersion')}
                                items={versions}
                                value={version}
                                placeholder={t('placeholderSelect/Type')}
                                //                            placeholder={_.get(this,'props.company.company.partner')}
                                onChange={(e, value) => {
                                    // console.log('onChange version', e.target.value)
                                    setVersion(value)

                                    const _version = _.find(modems, ['version', value])
                                    if (value && !_version) {
                                        setErrversion(t('newModemVersion'))
                                    }
                                    else {
                                        setErrversion('')
                                    }
                                }}
                                onSelect={(value) => {
                                    // console.log('onSelect version', value)
                                    setVersion(value);
                                    setErrversion('');
                                }}
                                clear={() => {
                                    setVersion('');
                                    setErrversion('');
                                }}
                            />
                            {errversion && <span className="help-block" style={textright}>{errversion}</span>}
                        </div>
                    </div>

                    <div style={file_uploader_bottom}>
                        <div className={classnames('form-group upload-file-form', { 'has-error': errfile })}>
                            <div id="upload-form-label"></div>
                            <div className="selectbutton">{t('selectFile')}</div>
                            <input type="file" name="file" id="upload-input" onChange={onFileChange} />
                            <div style={{ height: '32px' }}></div>
                            {errfile && <span className="help-block">{errfile}</span>}
                        </div>
                    </div>

                    <div className="form-group firmware-note-container">
                        <label htmlFor="release_note" className="control-label" style={release_note_label}>
                            {t('labelReleaseNote')}
                        </label>
                        <textarea
                            name="release_note"
                            style={{ width: '250px', height: '80px', maxHeight: '200px', resize: 'vertical' }}
                            onChange={handleInputChange}
                        ></textarea>
                    </div>

                    <div className="upload-wrapper">
                        <CustomButton
                            variant="secondary"
                            onClick={uploadModem}
                        >
                            {t('btnUpload')}
                        </CustomButton>
                    </div>
                </div>

                <h5 style={{ margin: '15px' }}>{t('firmwaresList')}</h5>
                <ModemFirmwaresListBlock
                    modems={modems}
                    getModemsList={getModemsList}
                />
            </div>
        </div>
    )
}

export default connect(
    null,
    dispatch => ({
        toggleLoader: (show) => {
            dispatch({ type: 'TOGGLE_LOADER', payload: show });
        }
    })
)(FleetAdminModemUploadForm);
