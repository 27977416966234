import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SearchBar from '../../SearchBar'
import CategoryItem from '../../dump/menuComponents/CategoryItem';
import './Reports.sass'
import StandardReportTable from './StandardReportTable';
import ReportSubscriptionsModal from './Modals/ReportSubscriptionsModal';
import NewReportModal from './Modals/NewReportModal'
import _ from 'lodash';
import { standardReportData } from './ReportsHelpers';
import { isInternalUser } from '../../helpers/constants';
import { sendGA } from '../../helpers/helperUsers'
import CustomButton from '../../CustomButton';

const ReportsContainer = (props) => {
    const { t, i18n } = useTranslation('translation', { keyPrefix: 'reportsPage.reportsContainer' });
    const fleetReports = [], driverReports = [], cameraHealthReports = [], adminReports = [];
    standardReportData.forEach(report => {
        switch (report.type) {
            case 'Fleet Activity':
                fleetReports.push(report)
                break;
            case 'Driver Activity':
                driverReports.push(report)
                break;
            case 'Camera Health':
                cameraHealthReports.push(report)
                break;
            case 'Admin Reports':
                adminReports.push(report)
                break;
        }
    })

    const [showSubscriptions, setShowSubscriptions] = useState(false);
    const [showNewReport, setShowNewReport] = useState(false);
    const [selectedReport, setSelectedReport] = useState(null);
    const [reportSearch, setReportSearch] = useState('');

    const { showReport, user } = props;
    const userRole = user.roles[0];
    const user_permissions = user.permissions || [];

    useEffect(() => {
        const { selectedReport } = props;
        if (selectedReport && selectedReport.name) {
            selectReport(selectedReport);
        }
    }, [])

    const selectReport = (selectedReport) => {
        setSelectedReport(selectedReport)
        sendGA({
            category: userRole,
            action: 'Select Report',
            label: selectedReport.name
        })
        props.handleShowReport();
    }

    const viewReport = (report) => {
        if (report.report_type === 'standard') {
            selectReport(standardReportData.find(standardReport => standardReport.id === report.report_id.toString()));
        }
        else {
            const formattedReport = {
                id: report.report_id,
                name: report.report_name,
                fields: report.report_fields,
                vehicles: report.report_vehicles,
                owner_id: report.report_owner === user.email ? user.id : null,
                user: report.report_users
            }
            setShowSubscriptions(false)
            props.handleNavigation(2, formattedReport)
        }
        setShowSubscriptions(false)
    }

    const goToCustomReports = () => {
        setShowNewReport(false)
        props.handleNavigation(2);
    }

    return (
        <div className="reports-container">
            {!showReport ?
                <>
                    <div className='page-subheader'>
                        <div className='subheader-section search-flex'>
                            <SearchBar term={reportSearch} onSearch={(e) => setReportSearch(e.target.value)} />
                            {(!['custom_user', 'custom_partner'].includes(userRole) || _.find(user_permissions, { 'perm_category_id': 3, 'a_edit': true })) &&
                                <CustomButton
                                    variant="primary"
                                    size="sm"
                                    onClick={() => setShowNewReport(true)}
                                >
                                    {t('btnAddReport')}
                                </CustomButton>
                            }
                        </div>
                        <div className='subheader-section'>
                            <CustomButton
                                variant="primary-outline"
                                onClick={() => setShowSubscriptions(true)}
                            >
                                {t('btnAutomatedReports')}
                            </CustomButton>
                        </div>
                    </div>

                    <main className={'reports-body main-content-block' + (['custom_user', 'custom_partner'].includes(userRole) ? ' two-columns' : '')}>
                        <section className='reports-category'>
                            <h3 className='category-title'>{t('titleFleetActivity')}</h3>
                            <div className='category-subheader'>{t('subheaderFleetActivity')}</div>
                            <div className='category-list'>
                                {fleetReports.map(report => {
                                    if (!reportSearch || report.name.toLowerCase().includes(reportSearch.toLowerCase())) {
                                        return <CategoryItem
                                            key={report.id}
                                            onClick={() => selectReport(report)}
                                            text={i18n.t(`reportsPage.standardReportData.${report.name.replace(/\s/g, "_")}`)}
                                            withArrow
                                        />
                                    }
                                })}
                            </div>
                        </section>

                        {!['custom_user', 'custom_partner'].includes(userRole) &&
                            <section className='reports-category'>
                                <h3 className='category-title'>{t('titleDriverActivity')}</h3>
                                <div className='category-subheader'>{t('subheaderDriverActivity')}</div>
                                <div className='category-list'>
                                    {driverReports.map(report => {
                                        if (!reportSearch || report.name.toLowerCase().includes(reportSearch.toLowerCase())) {
                                            return <CategoryItem
                                                key={report.id}
                                                onClick={() => selectReport(report)}
                                                text={i18n.t(`reportsPage.standardReportData.${report.name.replace(/\s/g, "_")}`)}
                                                withArrow
                                            />
                                        }
                                    })}
                                </div>
                            </section>
                        }

                        <section className='reports-category'>
                            <h3 className='category-title'>{t('titleCameraHealth')}</h3>
                            <div className='category-subheader'>{t('subheaderCameraHealth')}</div>
                            <div className='category-list'>
                                {cameraHealthReports.map(report => {
                                    if (!reportSearch || report.name.toLowerCase().includes(reportSearch.toLowerCase())) {
                                        return <CategoryItem
                                            key={report.id}
                                            onClick={() => selectReport(report)}
                                            text={i18n.t(`reportsPage.standardReportData.${report.name.replace(/\s/g, "_")}`)}
                                            withArrow
                                        />
                                    }
                                })}
                            </div>
                        </section>

                        {isInternalUser(userRole) &&
                            <section className='reports-category'>
                                <h3 className='category-title'>{t('titleAdminReports')}</h3>
                                <div className='category-subheader'>{t('subheaderAdminReports')}</div>
                                <div className='category-list'>
                                    {adminReports.map(report => {
                                        if (!reportSearch || report.name.toLowerCase().includes(reportSearch.toLowerCase())) {
                                            return <CategoryItem
                                                key={report.id}
                                                onClick={() => selectReport(report)}
                                                text={i18n.t(`reportsPage.standardReportData.${report.name.replace(/\s/g, "_")}`)}
                                                withArrow
                                            />
                                        }
                                    })}
                                </div>
                            </section>
                        }
                    </main>
                </> :
                <StandardReportTable
                    goBack={() => props.handleNavigation(1)}
                    selectedReport={selectedReport}
                />
            }
            {showSubscriptions &&
                <ReportSubscriptionsModal
                    onHide={() => setShowSubscriptions(false)}
                    viewReport={viewReport}
                />}
            {showNewReport &&
                <NewReportModal
                    onHide={() => setShowNewReport(false)}
                    userReports={[]}
                    goToCustomReports={goToCustomReports}
                />}
        </div>
    )
}

ReportsContainer.propTypes = {
    user: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = ({ user }) => ({
    user: user.user
});

export default connect(mapStateToProps)(ReportsContainer);
