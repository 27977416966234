import React, { useState, useRef, useEffect } from 'react'
import _ from 'lodash'
import onResize from 'simple-element-resize-detector'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

const SimpleMapExampleHEREMap = (props) => {
	const { direction, coordinates, polyline, accidentTime } = props;
	const { t } = useTranslation('translation', { keyPrefix: 'components.advancedPlayer.simpleMapExampleMap' });

	let mapRef = useRef(null);
	const [mapState, setMapState] = useState({});
	const [map, setMap] = useState(null);
	const [mapUI, setMapUI] = useState(null);
	const [polylineObj, setPolylineObj] = useState(null);

	useEffect(() => {
		if (!map) {
			// instantiate a platform, default layers and a map as usual
			const platform = new H.service.Platform({
				apikey: process.env.REACT_APP_HERE_MAPS_API_KEY
			});
			const layers = platform.createDefaultLayers();
			const MAP = new H.Map(
				mapRef,
				layers.vector.normal.map,
				{
					center: { lat: -34.397, lng: 150.644 },
					zoom: 18,
					pixelRatio: window.devicePixelRatio || 1
				},
			);

			onResize(mapRef, (a, p, c) => {
				MAP.getViewPort().resize();
			});
			// attach the listener
			MAP.addEventListener('mapviewchange', handleMapViewChange);
			// add the interactive behaviour to the map
			new H.mapevents.Behavior(new H.mapevents.MapEvents(MAP));
			// Create the default UI components
			const ui = H.ui.UI.createDefault(MAP, layers);
			ui.getControl('mapsettings').setAlignment('top-left');
			ui.getControl('zoom').setAlignment('right-top');

			setMap(MAP);
			setMapUI(ui);
		}

		return () => {
			if (map) map.removeEventListener('mapviewchange', handleMapViewChange);
		}
	}, [])

	useEffect(() => {
		if (map) {
			map.setCenter(coordinates);

			if (polyline.length && !polylineObj) {
				const polylineOptions = {
					strokeColor: '#FF0000',
					lineWidth: 3,
				};

				const lineString = new H.geo.LineString();
				polyline.map(elm => { lineString.pushPoint({ lat: elm.lat, lng: elm.lng }) });
				const polylineElm = new H.map.Polyline(lineString, { style: polylineOptions });
				map.addObject(polylineElm);
				setPolylineObj(polylineElm);
			}

			const marker = map.getObjects().filter(item => (item.type === H.map.Object.Type.DOM_MARKER))[0],
				divIcon = document.createElement('div'),
				img = document.createElement('img');

			divIcon.setAttribute('class', 'map-narker');
			img.setAttribute('src', '/images/navigation.png');
			img.style.transform = `translate(-50%, -50%) rotate(${direction + 'deg'})`;
			divIcon.appendChild(img);

			if (!marker) {
				map.addObject(new H.map.DomMarker(coordinates, { icon: new H.map.DomIcon(divIcon) }));
			} else {
				marker.setGeometry(coordinates);
				marker.setIcon(new H.map.DomIcon(divIcon));
			}
		}
	}, [coordinates]);

	const addLocation = () => {
		axios.get(`https://revgeocode.search.hereapi.com/v1/revgeocode?at=${coordinates.lat},${coordinates.lng}&lang=en-US&apiKey=${process.env.REACT_APP_HERE_MAPS_API_KEY}`)
			.then(async (res) => {
				if (res.status === 200) {
					const address = await res.data.items[0].address.label || t('textUnknown');

					const content = `<div style="background-xolor: white; padding: 0; margin: 0; width: 275px">
						<div style="font-size: 10px; font-color: #08233B">
							<div style="margin: 0 10px">
								<p style="font-weight: bold; margin: 0">${t('titleTimeCollision')}</p>
								<p style="margin: 0">${accidentTime}</p>
							</div>
							<div style="margin: 10px">
								<p style="font-weight: bold; margin: 0">${t('titleLocation')}</p>
								<p style="margin: 0">${address}</p>
							</div>
						</div>
					</div>`;

					const bubbleElm = new H.ui.InfoBubble(coordinates, { content });
					bubbleElm.addClass("infoTripBubble");
					mapUI.addBubble(bubbleElm);

					mapUI.getControl('zoom').setVisibility(false);
					mapUI.getControl('scalebar').setVisibility(false);
					mapUI.getControl('mapsettings').setVisibility(false);
				};
			});
	};

	const handleMapViewChange = (e) => {
		if (e.newValue && e.newValue.lookAt) {
			const lookAt = e.newValue.lookAt;
			// adjust precision
			const lat = Math.trunc(lookAt.position.lat * 1E7) / 1E7;
			const lng = Math.trunc(lookAt.position.lng * 1E7) / 1E7;
			const zoom = Math.trunc(lookAt.zoom * 1E2) / 1E2;

			setMapState({ lat, lng, zoom })
		}
	}

	return (
		<div
			id="map"
			style={{ position: 'relative', width: '100%', height: '100%' }}
			ref={(c) => { mapRef = c }}
		>
			{!!accidentTime && !mapUI.getBubbles().length && addLocation()}
		</div>
	)
};

export default SimpleMapExampleHEREMap;
