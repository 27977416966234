import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dropdown, Modal } from 'react-bootstrap'
import _ from 'lodash'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { useTranslation } from 'react-i18next'

import CustomButton from '../../component/CustomButton'
import { CustomButtonToggle } from '../../component/DropdownComponents'
import ModalUploadFile from '../../component/common/modals/ModalUploadFile'
import { apiCall } from '../../action/RouterActions'
import { sendGeotabLink } from '../../action/CompaniesActions'

const CompanyExternalIntegrations = (props) => {
	// console.log('PROPS CompanyExternalIntegrations: ', props)
	const { company, user, modalConfirm, getCompany, toggleLoader } = props;
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'companiesPage.companyExternalIntegrations' });

	const [modal, setModal] = useState({ show: false, content: '', type_modal: '', error: false })
	const [integrationType, setIntegrationType] = useState('')
	const [geotabProvider, setGeotabProvider] = useState('')
	const [email, setEmail] = useState('')
	const [showProvisionLink, setShowProvisionLink] = useState('')
	const [showIntegrationDropdown, setShowIntegrationDropdown] = useState(false)

	const [modalUploadFile, setModalUploadFile] = useState(false);
	const [fileUploadDevices, setFileUploadDevices] = useState(null);

	// console.log("STATES CompanyExternalIntegrations modal, integrationType, geotabProvider, email: ", { modal, integrationType, geotabProvider, email })

	const userRole = user.roles[0]
	const isNextDisabled = !integrationType
	const isSendEmailDisabled = (integrationType === 'Geotab' && !geotabProvider) || !email

	const closeModal = () => {
		setIntegrationType('')
		setShowProvisionLink('')
		setGeotabProvider('')
		setModal({ show: false, content: '', type_modal: '', error: false })
		// setState({ ...state, modal: { ...state.modal, error: false, show: false }, log_in: { user_login: '', user_password: '', fleet_token_r1: '' }, errMessage: { ...errMessage, geotab_email: '' } });
	}

	const copyProvisionLink = () => {
		const copyText = document.querySelector('#geotab-provision-link')
		copyText.select()
		document.execCommand("copy")
	}

	const getProvisionLink = (argExternalType) => {
		const env = window.location.hostname;
		const hash = company.hash
		const serverType = (argExternalType || integrationType).toLowerCase()

		let domain = '';
		switch (env) {
			case 'localhost':
				domain = 'http://localhost:3000'
				break
			case 'test.roscolive.com':
				domain = 'https://test.roscolive.com'
				break
			case 'dev.roscolive.com':
				domain = 'https://dev.roscolive.com'
				break
			case 'staging.roscolive.com':
				domain = 'https://staging.roscolive.com'
				break
			case 'canada.roscolive.com':
				domain = 'https://canada.roscolive.com'
				break
			case 'roscocloud.com':
			case 'www.roscocloud.com':
			case 'fleet.roscolive.com':
				domain = 'https://fleet.roscolive.com'
				break
			default:
				break
		}

		if (serverType === 'geotab') {
			return `${domain}/mygeotab/credentials/${geotabProvider}/${hash}`
		}
		// if (integrationType === 'SyncUp') {
		// 	return `${domain}/mygeotab/credentials/2/${hash}`
		// }

		return `${domain}/${serverType}/credentials/${hash}`
	}

	const setModalType = (e, action) => {
		// const dataUpdate = state.data;
		let title, content;

		switch (action) {
			case 'integrations':
				title = t('integrationsTitle')
				content = t('integrationsContent')
				break
			case 'email_entering':
				title = t('emailEnteringTitle', { integrationType })
				content = t('emailEnteringContent')
				break
			default:
				break
		}

		setModal({ show: true, title, content, type_modal: action })

		// setState((prevState) => {
		// 	return {
		// 		...prevState, modal: { ...state.modal, show: true, title, content, type_action: action }, errMessage: { ...state.errMessage, user_login: '', user_password: '' }
		// 	}
		// });
	}

	const onFileChange = (e) => {
		const { value } = e.target;
		const file = e.target.files[0];
		setFileUploadDevices(file);

		let i
		if (value.lastIndexOf('\\')) {
			i = value.lastIndexOf('\\') + 1;
		} else {
			i = value.lastIndexOf('/') + 1;
		}
		const filename = value.slice(i);
		const uploaded = document.getElementById('upload-form-label');
		uploaded.innerHTML = filename;
	}

	const onUploadVehicles = (e) => {
		e.preventDefault()

		if (!fileUploadDevices) {
			toastr.warning('toastrSelectCSV/ExcelFile')
			return
		}

		const params = {
			file: fileUploadDevices
		}

		toggleLoader(true);

		apiCall('PUT', `/geotab/companies/${company.id}/devices/file`, params)
			.then((res, err) => {
				toggleLoader(false);
				toastr.success('', t('toastrFileUploaded'))
			})
			.catch((error) => {
				toggleLoader(false);
				const { status, data } = error.response
				if (status >= 400 && status < 500) {
					toastr.error(data.response.error);
				} else {
					toastr.error(t('toastrUnableUploadDevices'));
				}
			})
	}

	const checkForm = (data) => {
		let error = false

		for (let i in data) {
			if (data[i] === '') {
				error = t('enterYour') + t(i) + '!'
			}
		}

		if (data.geotab_email) {
			const re = /^([a-zA-Z0-9_\-\.\+]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			if (!re.test(data.geotab_email)) {
				error = t('emailNotValid')
			}
		}

		if (error) {
			toastr.error('', error)
		}
		return error
	}

	const sendEmail = (e, argSendType) => { // argSendType = [firstsending, resend]
		e.preventDefault()
		const serverType = (argSendType === 'firstsending' ?  integrationType : company.external_srv_pending).toLowerCase()
		// if (['geotab', 'syncup'].includes(argExternalType)) {
		if (['geotab'].includes(serverType)) {
			const params = {
				geotab_email: argSendType === 'firstsending' ? email : company.geotab_email,
				company_name: company.company_name || '',
				partner_name: company.partner_company || '',
				provision_link: getProvisionLink(serverType),
			}
			const error = checkForm(params);
			if (!error) {
				toggleLoader(true);
				sendGeotabLink(params)
					.then((res, err) => {
						console.log('!sendGeotabLink res', res, err)
						toggleLoader(false);

						toastr.success("", t('emailSent'));
						getCompany()
						closeModal()
					})
					.catch((error) => {
						toggleLoader(false);
						let err = _.get(error, 'response.data.response.error')
						console.log('!sendGeotabLink error', err)
						let errDescription = i18n.t('errorMessage.errDescription');
						if (error.response.data.response.error) {
							errDescription = error.response.data.response.error
						}
						toastr.error(errDescription);
					})
			}
		}
		else {
			const params = {
				email: argSendType === 'firstsending' ?  email : company.external_srv_email,
				company_id: company.id,
				provision_link: getProvisionLink(serverType),
				server_type: serverType,
			}
			const error = checkForm(params);
			if (!error) {
				toggleLoader(true);
				apiCall('POST', '/external_service/{server_type}/provision_link/email', params)
					.then(res => {
						toggleLoader(false);
						toastr.success(t('emailSent'));
						getCompany()
						closeModal()
					})
					.catch((error) => {
						toggleLoader(false);
						console.log('!updateProfile error:', error.response, error)
						toastr.error(t('unablePushProfile'));
					})
			}
		}
	}

	return (
		<div className='subheader-section company-info'>
			{company.geotab_database || company.external_srv_type || company.external_srv_pending
				? (
					<Dropdown
						show={showIntegrationDropdown}
						drop
						id="geotab-dropdown"
						role="menuitem"
						autoClose="outside"
						onToggle={(isOpen) => setShowIntegrationDropdown(isOpen)}
					>
						<Dropdown.Toggle as={CustomButtonToggle} variant="primary-outline" size="lg">
							{t('titleIntegrations')}
						</Dropdown.Toggle>
						<Dropdown.Menu className="geotab-relations-menu">
							<div className={`dropdown-body${company.geotab_database ? ' lg' : ''}`}>
								<h5 className="category-title mb-10">{t('titleIntegrationInfo')}</h5>
								<div className={`info-section ${!company.geotab_database ? ' justify-left' : ''}`}>
									<div className="mr-20">
										<label>{t('labelType')}</label> {company.geotab_database ? 'Geotab' : _.upperFirst(company.external_srv_type || company.external_srv_pending)}
									</div>
									{company.geotab_database &&
										<div>
											<label>{t('labelDatabase')}</label> {company.geotab_database || 'N/A'}
										</div>
									}
									<div>
										<label>{t('labelStatus')}</label> {company.external_srv_type ? t('complete') : company.external_srv_pending ? t('pending') : 'N/A'}
									</div>
								</div>
								<div className='info-section'>
									<div>
										<label>{t('labelPOCEmail')}</label> {company.external_srv_email}
									</div>
								</div>
							</div>
							<div className='dropdown-footer'>
								<CustomButton variant="delete-outline" onClick={(e) => modalConfirm(e, 'delete_relation')}>
									{t('btnDelete')}
								</CustomButton>
								<div>
									{!company.external_srv_type && company.external_srv_email &&
										<CustomButton variant="primary-outline" prefix="mr-10" onClick={(e) => sendEmail(e, 'resend')}>
											{t('btnResendEmail')}
										</CustomButton>
									}
									<CustomButton variant="primary" onClick={() => setShowIntegrationDropdown(false)}>
										{t('btnClose')}
									</CustomButton>
								</div>
							</div>
						</Dropdown.Menu>
					</Dropdown>
				) : (
					<CustomButton
						variant="primary-outline"
						size="sm"
						id="geotab-dropdown"
						onClick={(e) => setModalType(e, 'integrations')}
					>
						{t('btnIntegrations')}
					</CustomButton>
				)}

			{modalUploadFile &&
				<ModalUploadFile
					title={t('modalUploadFileTitle')}
					description={t('modalUploadFileDescription')}
					exampleFileTitle={t('modalUploadFileExampleFileTitle')}
					btnUploadTitle={t('modalUploadFileBtnUploadTitle')}
					exampleFileLink="/files/rosco_devices_example.csv"
					acceptFileExtensions=".csv"
					onFileChange={onFileChange}
					onUploadFile={onUploadVehicles}
					onHide={() => setModalUploadFile(false)}
				/>}

			<Modal
				show={modal.show}
				size="lg"
				onHide={closeModal}
			>
				<Modal.Header closeButton>
					<Modal.Title className={`${modal.type_modal === 'delete' && 'delete-title'}`}>
						{`${t('titleIntegrations')}${modal.type_modal === 'integrations' ? '' : ' - ' + integrationType}`}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="mt-20">
						<div className="text-center">
							<p><b>
								{modal.type_modal === 'integrations'
									? t('bodyIntegrations')
									: integrationType === 'Geotab'
										? t('bodyGeotab')
										: t('bodyEmailEntering')
								}
								{/* {modal.content} */}
							</b></p>
						</div>
						<div className="import-form">
							{modal.type_modal === 'integrations' && (
								<div>
									<label>{t('labelSelectIntegrationType')}</label>
									<select
										name="geotab_provider"
										className="rosco-select block"
										onChange={(e) => setIntegrationType(e.target.value)}
									>
										<option></option>
										<option value="Geotab">Geotab</option>
										<option value="Ituran">Ituran</option>
										<option value="Webfleet">Webfleet</option>
									</select>
								</div>
							)}

							{modal.type_modal === 'email_entering' &&
								<>
									{integrationType === 'Geotab' &&
										<div className="form-group">
											<label>{t('labelGeotabIntegrationType')}</label>
											<select
												name="geotab_provider"
												className="rosco-select block"
												onChange={(e) => setGeotabProvider(e.target.value)}
											>
												<option></option>
												<option value="1">MyGeotab</option>
												<option value="2">SyncUp</option>
												<option value="4">MyGeotab Gov</option>
												<option value="3">MyPreview</option>
											</select>
										</div>
									}
									<div className="form-group">
										<label>{t('labelEmail')}</label>
										<input
											type="text"
											name="geotab_email"
											className="vehicle-search"
											onChange={(e) => setEmail(e.target.value)}
										/>
									</div>
									{showProvisionLink &&
										<div className="geotab-provision-link">
											<input type="text" id="geotab-provision-link" className='vehicle-search' value={getProvisionLink()} readOnly />
											<CustomButton
												size="icon"
												prefix="clipboard-btn"
												title={t('titleCopyLink')}
												onClick={copyProvisionLink}
											>
												{t('btnCopy')}
											</CustomButton>
										</div>
									}
								</>
							}
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer bsPrefix="default-modal-footer modal-footer">
					{modal.type_modal === 'integrations'
						? <>
							<CustomButton variant="primary-outline" onClick={closeModal}>{t('btnClose')}</CustomButton>
							<CustomButton
								variant="primary"
								prefix={`${isNextDisabled ? 'custom-disabled' : ''} `}
								disabled={isNextDisabled}
								onClick={(e) => setModalType(e, 'email_entering')}
							>
								{t('btnNext')}
							</CustomButton>
						</>
						: <>
							<CustomButton
								variant="primary-outline"
								onClick={(e) => { setIntegrationType(''); setShowProvisionLink(''); setGeotabProvider(''); setModal({ show: true, type_modal: 'integrations' }); }}
							>
								{t('btnBack')}
							</CustomButton>
							<CustomButton
								variant="primary"
								prefix={`${isSendEmailDisabled ? 'custom-disabled' : ''} `}
								style={{ paddingLeft: '25px', paddingRight: '25px' }}
								disabled={isSendEmailDisabled}
								// onClick={(e) => setModalType(e, 'email_entering')}
								onClick={(e) => sendEmail(e, 'firstsending')}
							>
								{t('btnSendEmail')}
							</CustomButton>
							{userRole === 'system_admin' &&
								<CustomButton
									variant="primary"
									size="sm"
									onClick={() => setShowProvisionLink(!showProvisionLink)}
								>
									{t('btnGenerateLink')}
								</CustomButton>
							}
						</>
					}
				</Modal.Footer>
			</Modal>
		</div>
	)
}

CompanyExternalIntegrations.propTypes = {
	company: PropTypes.objectOf(PropTypes.any).isRequired,
	getCompany: PropTypes.func.isRequired,
	modalConfirm: PropTypes.func.isRequired,
}

const mapStateToProps = ({ user }) => ({
	user: user.user,
})

const mapDispatchToProps = dispatch => ({
	toggleLoader: (show) => {
		dispatch({ type: 'TOGGLE_LOADER', payload: show });
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(CompanyExternalIntegrations);
