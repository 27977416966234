import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const LiveStreams = (props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'components.liveStreams' });

    return (
        <div>{t('liveStreams')}</div>
    )
};

export default LiveStreams;
