import ambulance from '../../../../assets/vehicle_icon/ambulance.png';
import fire_marshal from '../../../../assets/vehicle_icon/fire_marshal.png';
import ambulancel from '../../../../assets/vehicle_icon/ambulancel.png';
import arrow1 from '../../../../assets/vehicle_icon/1.png';
import arrow2 from '../../../../assets/vehicle_icon/arrow_2.png';
import arrow3 from '../../../../assets/vehicle_icon/arrow.png';
import charter_bus from '../../../../assets/vehicle_icon/charter_bus.png';
import transit_bus from '../../../../assets/vehicle_icon/transit_bus.png';
import transitl_bus from '../../../../assets/vehicle_icon/transitl_bus.png';
import chopper from '../../../../assets/vehicle_icon/chopper.png';
import fire_truck from '../../../../assets/vehicle_icon/fire_truck.png';
import firetruck from '../../../../assets/vehicle_icon/firetruck.png';
import trash_truck_ram from '../../../../assets/vehicle_icon/trash_truck_ram.png';
import humvee from '../../../../assets/vehicle_icon/humvee.png';
import suv from '../../../../assets/vehicle_icon/suv.png';
import limo from '../../../../assets/vehicle_icon/limo.png';
import pick_up_truck from '../../../../assets/vehicle_icon/pick_up_truck.png';
import picup from '../../../../assets/vehicle_icon/picup.png';
import pic_up_ram from '../../../../assets/vehicle_icon/pic_up_ram.png';
import police_car from '../../../../assets/vehicle_icon/police_car.png';
import schoolbus from '../../../../assets/vehicle_icon/schoolbus.png';
import red_sedan from '../../../../assets/vehicle_icon/red_sedan.png';
import truck from '../../../../assets/vehicle_icon/truck.png';
import semi_truck from '../../../../assets/vehicle_icon/semi_truck.png';
import sports_car_1 from '../../../../assets/vehicle_icon/sports_car_1.png';
import sports_car_2 from '../../../../assets/vehicle_icon/sports_car_2.png';
import green_sport from '../../../../assets/vehicle_icon/green_sport.png';
import taxi from '../../../../assets/vehicle_icon/taxi.png';
import taxi_3d from '../../../../assets/vehicle_icon/taxi_3d.png';
import red_bus from '../../../../assets/vehicle_icon/red_bus.png';
import van from '../../../../assets/vehicle_icon/van.png';
import step_van from '../../../../assets/vehicle_icon/step_van.png';
import * as _ from "lodash";
import { sendGA } from './helperUsers';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

export const vehicleIcons = [
	{ value: "ambulance", name: "Ambulance 1", src: ambulance },
	{ value: "fire_marshal", name: "Ambulance 2", src: fire_marshal },
	{ value: "ambulancel", name: "Ambulance 3", src: ambulancel },
	{ value: "1", name: "Arrow 1", src: arrow1 },
	{ value: "arrow_2", name: "Arrow 2", src: arrow2 },
	{ value: "arrow", name: "Arrow 3", src: arrow3 },
	{ value: "charter_bus", name: "Bus 1", src: charter_bus },
	{ value: "transit_bus", name: "Bus 2", src: transit_bus },
	{ value: "transitl_bus", name: "Bus 3", src: transitl_bus },
	{ value: "chopper", name: "Chopper", src: chopper },
	{ value: "fire_truck", name: "Fire Truck 1", src: fire_truck },
	{ value: "firetruck", name: "Fire Truck 2", src: firetruck },
	{ value: "trash_truck_ram", name: "Garbage Truck", src: trash_truck_ram },
	{ value: "humvee", name: "Humvee", src: humvee },
	{ value: "suv", name: "Jeep", src: suv },
	{ value: "limo", name: "Limousine", src: limo },
	{ value: "pick_up_truck", name: "Pickup Truck 1", src: pick_up_truck },
	{ value: "picup", name: "Pickup Truck 2", src: picup },
	{ value: "pic_up_ram", name: "Pickup Truck 3", src: pic_up_ram },
	{ value: "police_car", name: "Police Car", src: police_car },
	{ value: "schoolbus", name: "School Bus", src: schoolbus },
	{ value: "red_sedan", name: "Sedan", src: red_sedan },
	{ value: "truck", name: "Semi Truck 1", src: truck },
	{ value: "semi_truck", name: "Semi Truck 2", src: semi_truck },
	{ value: "sports_car_1", name: "Sports Car 1", src: sports_car_1 },
	{ value: "sports_car_2", name: "Sports Car 2", src: sports_car_2 },
	{ value: "green_sport", name: "Sports Car 3", src: green_sport },
	{ value: "taxi", name: "Taxi 1", src: taxi },
	{ value: "taxi_3d", name: "Taxi 2", src: taxi_3d },
	{ value: "red_bus", name: "Van 1", src: red_bus },
	{ value: "van", name: "Van 2", src: van },
	{ value: "step_van", name: "Van 3", src: step_van },
];


export function findVehicleImg(imgArr, searchParams) {
	const img = _.find(imgArr, { 'value': searchParams });
	return img?.src;
}

export const parkingDurations = [
	{ value: '0', name: 'Disabled' },
	{ value: '30', name: '1/2 Hour' },
	{ value: '60', name: '1 Hour' },
	{ value: '90', name: '1 1/2 Hour' },
	{ value: '120', name: '2 Hour' },
	{ value: '180', name: '3 Hour' },
	{ value: '240', name: '4 Hour' },
	{ value: '360', name: '6 Hour' },
	{ value: '480', name: '8 Hour' },
	{ value: '600', name: '10 Hour' },
	{ value: '720', name: '12 Hour' },
	{ value: '1440', name: '24 Hour' },
	{ value: '2880', name: '48 Hour' },
	{ value: '4320', name: '72 Hour' },
]

export const getDeviceDataLimit = (deviceDataLimit, companyData) => {
	// let dataLimit = 1;
	// switch (deviceDataLimit) {
	// case 3:
	// 	dataLimit = 1;
	// 	break;
	// case 2:
	// 	dataLimit = 0.5;
	// 	break;
	// default:
	// 	dataLimit = companyData.data_limit === 2 ? 0.5 : 1;
	// 	break;
	// }
	// return dataLimit

	return 1
}

//FWSD-4623 - remove VIN validation
export const vinValidation = (argVin) => {
	const isNumeric = (mixedVar) => {
		return (typeof (mixedVar) === 'number' || typeof (mixedVar) === 'string') && mixedVar !== '' && !isNaN(mixedVar);
	}
	// var pattern = /^[^\Wioq]{17}$/;
	const weights = [8, 7, 6, 5, 4, 3, 2, 10, 0, 9, 8, 7, 6, 5, 4, 3, 2];
	const transliterations = {
		a: 1, b: 2, c: 3, d: 4,
		e: 5, f: 6, g: 7, h: 8,
		j: 1, k: 2, l: 3, m: 4,
		n: 5, p: 7, r: 9, s: 2,
		t: 3, u: 4, v: 5, w: 6,
		x: 7, y: 8, z: 9,
	};
	const vin = argVin.toLowerCase();
	// if(!vin.match(pattern)) { return false; }

	let sum = 0;
	for (let i = 0; i < vin.length; i++) {
		if (!isNumeric(vin.charAt(i))) {
			sum += transliterations[vin.charAt(i)] * weights[i];
		}
		else {
			sum += parseInt(vin.charAt(i)) * weights[i];
		}
	}

	let checkdigit = sum % 11;
	const p = vin.charAt(8)
	if (checkdigit === 10) { // check digit of 10 represented by X
		checkdigit = 'x';
	}

	return (checkdigit.toString() === vin.charAt(8));
}

//FWSD-4624
//source: https://sndeep.info/en/tools/checksum_calculator
export const imeiValidation = (imei) => {
	// console.debug('imeiValidation',imei);
	var odd_sum = 0;
	var even_sum = 0;
	var result = 0;
	var check_digit = 0;
	const numbers_map_list = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];

	//sum odd numbers, map even numbers to list and sum mapped numbers
	//do not include the check digit in these calculations
	for (var i = 0; i < imei.length - 1; i++) {
		var char = imei[i];

		if (isNaN(char)) {
			return false;
		}

		if ((i + 1) % 2 !== 0) {
			odd_sum += Number(char);
		} else {
			even_sum += numbers_map_list[Number(char)];
		}
	}
	//sum results
	result = odd_sum + even_sum

	//check digit is number needed to sum with result to get nearest 10s
	for (var i = 0; i < 10; i++) {
		if ((result + i) % 10 === 0) {
			check_digit = i
			break;
		}
	}

	console.debug('check_digit', check_digit);
	return check_digit === Number(imei.charAt(imei.length - 1)) ? true : false;
}

// FWSD-6275, the dot in the resolutions field is a required separator for list items
export const errorStatusCodes = () => {
	return ([
		{ code: "C100", name: i18n.t('constants.errorStatusCodes.nameCANBusFailure'), type: "warn", definitiona: i18n.t('constants.errorStatusCodes.definitionaCANBusFailure'), resolutions: i18n.t('constants.errorStatusCodes.resolutionsCANBusFailure') },
		{ code: "E005", name: i18n.t('constants.errorStatusCodes.nameChannel3/4VideoLoss'), type: "warn", definitiona: i18n.t('constants.errorStatusCodes.definitionaChannel3/4VideoLoss'), resolutions: i18n.t('constants.errorStatusCodes.resolutionsChannel3/4VideoLoss') },
		{ code: "E008", name: i18n.t('constants.errorStatusCodes.nameHighTemperature'), type: "warn", definitiona: i18n.t('constants.errorStatusCodes.definitionaHighTemperature'), resolutions: i18n.t('constants.errorStatusCodes.resolutionsHighTemperature') },
		{ code: "PW23", name: i18n.t('constants.errorStatusCodes.nameShutdown'), type: "warn", definitiona: i18n.t('constants.errorStatusCodes.definitionaShutdown'), resolutions: i18n.t('constants.errorStatusCodes.resolutionsShutdown') },
		{ code: "SD20", name: i18n.t('constants.errorStatusCodes.nameSDCardMissing'), type: "warn", definitiona: i18n.t('constants.errorStatusCodes.definitionaSDCardMissing'), resolutions: i18n.t('constants.errorStatusCodes.resolutionsSDCardMissing') },
		{ code: "SD21", name: i18n.t('constants.errorStatusCodes.nameSDCardMountError'), type: "warn", definitiona: i18n.t('constants.errorStatusCodes.definitionaSDCardMountError'), resolutions: i18n.t('constants.errorStatusCodes.resolutionsSDCardMountError') },
		{ code: "SD22", name: i18n.t('constants.errorStatusCodes.nameSDCardRead-Only'), type: "warn", definitiona: i18n.t('constants.errorStatusCodes.definitionaSDCardRead-Only'), resolutions: i18n.t('constants.errorStatusCodes.resolutionsSDCardRead-Only') },
		{ code: "SD23", name: i18n.t('constants.errorStatusCodes.nameSDCardInitializationError'), type: "warn", definitiona: i18n.t('constants.errorStatusCodes.definitionaSDCardInitializationError'), resolutions: i18n.t('constants.errorStatusCodes.resolutionsSDCardInitializationError') },
		{ code: "SD24", name: i18n.t('constants.errorStatusCodes.nameIncorrectSDCard'), type: "warn", definitiona: i18n.t('constants.errorStatusCodes.definitionaIncorrectSDCard'), resolutions: i18n.t('constants.errorStatusCodes.resolutionsIncorrectSDCard') },
		{ code: "SD26", name: i18n.t('constants.errorStatusCodes.nameSDCardRecordingError'), type: "warn", definitiona: i18n.t('constants.errorStatusCodes.definitionaSDCardRecordingError'), resolutions: i18n.t('constants.errorStatusCodes.resolutionsSDCardRecordingError') },
		{ code: "SD28", name: i18n.t('constants.errorStatusCodes.nameSDCardPartitionError'), type: "warn", definitiona: i18n.t('constants.errorStatusCodes.definitionaSDCardPartitionError'), resolutions: i18n.t('constants.errorStatusCodes.resolutionsSDCardPartitionError') },
		{ code: "E001,E002,E003,E006,G002,SD27", name: i18n.t('constants.errorStatusCodes.nameCriticalError'), type: "crit", definitiona: i18n.t('constants.errorStatusCodes.definitionaCriticalError'), resolutions: i18n.t('constants.errorStatusCodes.resolutionsCriticalError') },
	])
}

export const rssiList = () => {
	return ({
		good: { title: i18n.t('constants.rssiList.goodTitle'), image: 'rssi_good.png', description: i18n.t('constants.rssiList.goodDescription') },
		fair: { title: i18n.t('constants.rssiList.fairTitle'), image: 'rssi_fair.png', description: i18n.t('constants.rssiList.fairDescription') },
		weak: { title: i18n.t('constants.rssiList.weakTitle'), image: 'rssi_weak.png', description: i18n.t('constants.rssiList.weakDescription') },
	})
}

export const detectRSSIQuality = (rssi) => {
	let obj = ''

	if (rssi === 0) {
		obj = ''
	}
	else if (rssi >= -60) {
		obj = rssiList.good
	}
	else if ((-75 <= rssi) && (rssi < -60)) {
		obj = rssiList.fair
	}
	else if (rssi < -75) {
		obj = rssiList.weak
	}

	return obj
}

export const detectDeviceStatus = (device) => {
	const { active, sleep, online, ignition, general_status, ads_online } = device
	let status = ''

	if (!active) {
		status = 'disabled'
	}
	else if (general_status === 'pending sleep') {
		status = 'sleep'
	}
	else if (general_status === 'pending awake') {
		status = 'awake'
	}
	else if (sleep && sleep > 0) {
		status = general_status || 'sleep'
	}
	else if (!general_status) {
		status = 'offline'
	}
	else {
		status = general_status
	}

	// if (!device.active) {
	// 	status = 'disabled'
	// }
	// else if (sleep && sleep > 0) {
	// 	status = general_status || 'sleep'
	// }
	// else if (!online) {
	// 	status = 'offline'
	// }
	// else if (online && ignition && ignition.state) {
	// 	status = 'driving'
	// }
	// else {
	// 	status = 'parked'
	// }

	// if (!device.active) {
	// 	status = 'disabled'
	// }
	// else if (!device.online) {
	// 	status = 'offline'
	// }
	// else {
	// 	const ignitionOffTS = device.ignition && device.ignition.ts ? device.ignition.ts : ''; // 2023-01-04 18:50:37
	// 	const duration = moment.duration(moment.utc().diff(moment.utc(ignitionOffTS), 'seconds'), 'seconds')
	// 	const durationInMins = duration.asMinutes()

	// 	// FWSD-4314 - If device does not receive GPS from device and ignition is Off more 24 hours (1440 mins) then display Online status.
	// 	// Device stopped receive GPS, broken antena or something, and FileServer does not know when ignition is On.
	// 	if ((device.ignition && device.ignition.state) || (!device.gps_online && durationInMins > 1440)) {
	// 		status = 'driving'
	// 	}
	// 	else (
	// 		status = 'parked'
	// 	)
	// }

	return status
}

export const getDeviceStatusName = (statusName) => {
	let res = statusName
	switch (statusName) {
		case 'pending sleep':
			// res = 'Going To Sleep'
			res = 'Sleep'
			break;
		case 'pending awake':
			// res = 'Waking Up'
			res = 'Awake'
			break;
		default:
			break;
	}

	return res
}

export const checkStatus = (groupName) => {
	const { t } = useTranslation('translation', { keyPrefix: 'constants.statuses' });

	if (groupName === "Driving") groupName = t('Driving');
	if (groupName === "Parked") groupName = t('Parked');
	if (groupName === "Offline") groupName = t('Offline');
	if (groupName === "Disabled") groupName = t('Disabled');
	if (groupName === "Sleep") groupName = t('Sleep');
	if (groupName === "Awake") groupName = t('Awake');
	if (groupName === "Pending awake") groupName = t('Pending_awake');
	if (groupName === "Pending sleep") groupName = t('Pending_sleep');

	return groupName
}

export const openLiveStream = (userRole, device) => {
	const deviceId = device.device_id

	sendGA({ category: userRole, action: 'Custom Video Request', label: 'Open Live Stream' })

	const width = 660
	const height = 400
	const w = window
	const d = document
	const e = d.documentElement
	const g = d.getElementsByTagName('body')[0]
	const winWidth = w.innerWidth || e.clientWidth || g.clientWidth
	const winHeight = w.innerHeight || e.clientHeight || g.clientHeight
	const winX = (document.all) ? window.screenLeft : window.screenX
	const winY = (document.all) ? window.screenTop : window.screenY
	const left = ((winWidth - width) / 2) + winX
	const top = ((winHeight - height) / 2) + winY
	const windowSpecs = `toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=1,width=${width},height=${height},left=${left},top=${top}`
	const target = deviceId
	const win = window.open(`/live/video/${target}`, `${target}1`, windowSpecs);

	return win
}

export const deviceStatusPopups = () => {
	return ({
		'driving': i18n.t('manageVehiclesPage.manageAssets.deviceStatusPopups.textDriving'),
		'parked': i18n.t('manageVehiclesPage.manageAssets.deviceStatusPopups.textParked'),
		'awake': i18n.t('manageVehiclesPage.manageAssets.deviceStatusPopups.textAwake'),
		'sleep': i18n.t('manageVehiclesPage.manageAssets.deviceStatusPopups.textSleep'),
		'offline': i18n.t('manageVehiclesPage.manageAssets.deviceStatusPopups.textOffline'),
		'disabled': i18n.t('manageVehiclesPage.manageAssets.deviceStatusPopups.textDisabled'),
		// 'pending awake': i18n.t('manageVehiclesPage.manageAssets.deviceStatusPopups.textPending awake'),
		// 'pending sleep': i18n.t('manageVehiclesPage.manageAssets.deviceStatusPopups.textPending sleep'),
	})
}

export default vehicleIcons;
