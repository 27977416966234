import React from 'react';
import SearchBar from '../../dump/menuComponents/SearchBar';
import { useTranslation } from 'react-i18next';

const Recent24 = (props) => {
	const { term, alerts, update } = props;
	const { t } = useTranslation();

	const dataSearch = (e) => {
		const value = e.target.value.toLowerCase();
		const filter = alerts.filter(event => {
			return (event.vehicle_name && (event.vehicle_name.toLowerCase().includes(value)
				|| event.type.toLowerCase().includes(value)))
		});

		update({
			alerts: filter,
			searchText: value
		});
	};

	return (
		<div className="select-asset recent24">
			<div style={{ width: '80%', margin: 'auto', marginBottom: '10px' }}>
				<SearchBar term={term} onSearch={dataSearch} placeholder={t('eventsPage.eventClipsContainer.trackTraceAlertSearch.recent24.searchEvent/Vehicle')} />
			</div>
		</div>
	)
};

export default Recent24;
