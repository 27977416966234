/* eslint max-classes-per-file: ["error", 2] */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Toggle from 'react-toggle'
import { RiAlertFill } from 'react-icons/ri'
import { useTranslation } from 'react-i18next'
// import { Button } from 'react-bootstrap'
// import { isFirefox } from 'react-device-detect'

import CustomButton from '../../CustomButton'
import CustomOverlayTrigger from '../../CustomOverlayTrigger'
import { channelFPSValidation, channelQualityValidation } from '../../helpers/helperProfile'
import { linkIcon, unlinkIcon } from '../../helpers/iconHelpers'

const FleetConfigurationChannel = (props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'manageVehiclesPage.fleetConfigurationContainer.editProfile.fleetConfigurationChannel' });
    // console.log('PROPS FleetConfigurationChannel: ', props)
    const { settingsType, configuration, configurationdv6, fillFormEnableChannel, fillForm, fillFormInt, advanced_quality_channels, setAdvancedQualityChannels, linked_fps, setLinkedFPS } = props
    const isDV6 = settingsType === 'dv6'
    const isDV4 = settingsType !== 'dv6'
    const currentConfig = isDV6 ? configurationdv6 : configuration
    const channelsCount = isDV6 ? 3 : 4

    const isQualityActive = (channelItem, nameQuality) => {
        const { MainQuality, MainFPS, index } = channelItem

        if (isDV6) {
            switch (nameQuality) {
                case 'Most Storage':
                    return MainQuality === (index && 1) && MainFPS === 24 ? ' active' : ''
                case 'Best Video':
                    return MainQuality === 3 && MainFPS === 30 ? ' active' : ''
                default:
                    return MainQuality === 2 && MainFPS === 24 ? ' active' : ''
            }
        }
        else {
            switch (nameQuality) {
                case 'Most Storage':
                    return (MainQuality === 1 && MainFPS === 7) ? ' active' : ''
                case 'Best Video':
                    return (MainQuality === 3 && MainFPS === 30) ? ' active' : ''
                default:
                    return (MainQuality === 2 && MainFPS === 15) ? ' active' : ''
            }
        }
    }

    const setChannelQuality = (e) => {
        const index = parseInt(e.target.name);
        const value = e.target.value;
        const newChannel = { ...currentConfig.Channels[index] }

        if (isDV6) {
            switch (value) {
                case 'Most Storage':
                    newChannel.MainFPS = 24
                    newChannel.ParkingFPS = 24
                    newChannel.MainQuality = index === 0 ? 0 : 1
                    break;
                case 'Best Video':
                    newChannel.MainFPS = 30
                    newChannel.ParkingFPS = 30
                    newChannel.MainQuality = 3
                    break;
                default:
                    newChannel.MainFPS = 24
                    newChannel.ParkingFPS = 24
                    newChannel.MainQuality = 2
                    break;
            }
        }
        else {
            switch (value) {
                case 'Most Storage':
                    newChannel.MainFPS = 7
                    newChannel.MainQuality = 1
                    break;
                case 'Best Video':
                    newChannel.MainFPS = 30
                    newChannel.MainQuality = 3
                    break;
                default:
                    newChannel.MainFPS = 15
                    newChannel.MainQuality = 2
                    break;
            }
        }

        fillForm(e, isDV6, `Channels.${index}`, newChannel)
    }

    const getFileSize = (model) => {
        let fileSize = 0;
        switch (model) {
            case 'dv6':
                for (const [index, channel] of configurationdv6.Channels.entries()) {
                    if (channel.Enabled) {
                        switch (channel.MainQuality) {
                            case 0:
                            case 1:
                                fileSize += index === 0 ? 6.336 : (channel.Audio ? 10.48 : 10.08)
                                break;
                            case 2:
                                fileSize += index === 0 ? 14.976 : (channel.Audio ? 11.728 : 11.328)
                                break;
                            case 3:
                            case 4:
                            case 5:
                                fileSize += index === 0 ? 28.8 : (channel.Audio ? 19.1 : 18.7)
                                break;
                            default:
                                return 0;
                        }
                    }
                }
                break;
            default:
                for (const [index, channel] of configuration.Channels.entries()) {
                    if (channel.Enabled) {
                        switch (channel.MainQuality) {
                            case 0:
                            case 1:
                                fileSize += index === 0 ? 2.33 : (channel.Audio ? 2.18 : 1.22)
                                break;
                            case 2:
                                fileSize += index === 0 ? 8.15 : (channel.Audio ? 5.11 : 4.15)
                                break;
                            case 3:
                            case 4:
                            case 5:
                                fileSize += index === 0 ? 22.21 : (channel.Audio ? 12.19 : 11.23)
                                break;
                            default:
                                return 0;
                        }
                    }
                }
                break;
        }
        return fileSize * 60;
    };

    // const edit_only = (user_role === 'sales_demo' || (user_role === 'custom_user' && !_.find(user_permissions, { 'perm_category_id': 4, 'a_edit': true })));
    const dv6fileSize = getFileSize('dv6');
    const dvxc4fileSize = getFileSize('dvxc4');

    return (
        <main className="channel-settings main-content-block">
            <h3 className="category-title">{t('titleVideoSettings')}</h3>
            <div className="category-subheader" dangerouslySetInnerHTML={{ __html: t('subheaderVideoSettings') }}>
            </div>

            <div className="settings-card">
                <div className="card-header">
                    {t('headerEstimatedRecordingHours')}
                </div>
                <div className="card-body">
                    <p>{t('bodyEstimatedRecordingHours')}</p>

                    <div className="stats-container">
                        {isDV6
                            ? (
                                <>
                                    <div className="stat-wrapper">
                                        <div className="file-size">128GB</div>
                                        <div className="recording-hours">
                                            {dv6fileSize ? Math.round(112400 / (dv6fileSize)) + 'h' : 'N/A'}
                                        </div>
                                    </div>
                                    <div className="stat-wrapper">
                                        <div className="file-size">256GB</div>
                                        <div className="recording-hours">
                                            {dv6fileSize ? Math.round(228800 / (dv6fileSize)) + 'h' : 'N/A'}
                                        </div>

                                    </div>
                                    <div className="stat-wrapper">
                                        <div className="file-size">512GB</div>
                                        <div className="recording-hours">
                                            {dv6fileSize ? Math.round(461600 / (dv6fileSize)) + 'h' : 'N/A'}
                                        </div>
                                    </div>
                                    <div className="stat-wrapper">
                                        <div className="file-size">1TB</div>
                                        <div className="recording-hours">
                                            {dv6fileSize ? Math.round(923200 / (dv6fileSize)) + 'h' : 'N/A'}
                                        </div>

                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="stat-wrapper">
                                        <div className="file-size">64GB</div>
                                        <div className="recording-hours">
                                            {dvxc4fileSize ? Math.round(64000 / (0.9 * dvxc4fileSize)) + 'h' : 'N/A'}
                                        </div>
                                    </div>
                                    <div className="stat-wrapper">
                                        <div className="file-size">128GB</div>
                                        <div className="recording-hours">
                                            {dvxc4fileSize ? Math.round(128000 / (0.9 * dvxc4fileSize)) + 'h' : 'N/A'}
                                        </div>
                                    </div>
                                    <div className="stat-wrapper">
                                        <div className="file-size">256GB</div>
                                        <div className="recording-hours">
                                            {dvxc4fileSize ? Math.round(256000 / (0.9 * dvxc4fileSize)) + 'h' : 'N/A'}
                                        </div>

                                    </div>
                                    <div className="stat-wrapper">
                                        <div className="file-size">512GB</div>
                                        <div className="recording-hours">
                                            {dvxc4fileSize ? Math.round(512000 / (0.9 * dvxc4fileSize)) + 'h' : 'N/A'}
                                        </div>
                                    </div>
                                </>
                            )}
                    </div>
                </div>
            </div>

            <div className="settings-card">
                <div className="advanced-quality-settings">
                    <div className="mr-10">{t('titleAdvancedQualitySettings')}</div>
                    <CustomOverlayTrigger
                        {...{ type: 'warn', title: t('titleAdvancedQualitySettings'), body: t('bodyEnablingAdvancedQuality') }}
                        placement="top"
                        trigger="click"
                        closeBtn={true}
                        // prefix={type}
                        prefix='warn'
                    >
                        <div className="mr-30">
                            <CustomButton variant="link">
                                <RiAlertFill className="alert-error" />
                            </CustomButton>
                        </div>
                    </CustomOverlayTrigger>
                    <Toggle
                        checked={advanced_quality_channels}
                        icons={false}
                        onChange={setAdvancedQualityChannels}
                    />
                </div>
            </div>

            {currentConfig.Channels.slice(0, channelsCount).map(item => {
                const { index } = item
                const channelNumber = index + 1
                // const linkedFPSName = `${isDV6 ? 'dv6_ch' : 'dv4_ch'}${channelNumber}`
                const linkedFPSName = `dv6_ch${channelNumber}`
                return (
                    <div key={index} className="settings-card">
                        <div className="card-header">
                            {t('headerChannel')} {channelNumber}
                            <Toggle
                                name={`Channels.${index}.Enabled`}
                                checked={currentConfig.Channels[index].Enabled}
                                icons={false}
                                onChange={(e) => fillFormEnableChannel(e, isDV6)}
                            />
                        </div>
                        {/* animation of card-body content displaing with delay 0.5s */}
                        <div className="card-body">
                            <p>{t('bodyChannel')} {channelNumber}.</p>
                            <div className={`${!currentConfig.Channels[index].Enabled ? 'hide' : ''}`}>
                            {advanced_quality_channels
                                ? (
                                <>
                                <div className="toggle-wrapper button-group">
                                    <label className="settings-title">{t('titleRecordingQuality')}</label>
                                    <div>
                                        {isDV6 &&
                                            <span className="synchronize-fps-icon">
                                                <CustomButton variant="link" onClick={() => setLinkedFPS(linkedFPSName)} >
                                                    { linked_fps[linkedFPSName] ? linkIcon : unlinkIcon }
                                                </CustomButton>
                                            </span>
                                        }
                                        <label className="settings-title mr-10">FPS(1 to 30):</label>
                                        <input
                                            type="number"
                                            step="1"
                                            min="1"
                                            max="30"
                                            maxLength="2"
                                            name={`Channels.${index}.MainFPS`}
                                            value={currentConfig.Channels[index].MainFPS}
                                            className={`vehicle-search number-input mr-10 pt-5${channelFPSValidation(currentConfig.Channels[index].MainFPS) ? ' error-input' : ''}`}
                                            onChange={(e) => isDV6 ? fillForm(e, isDV6) : fillFormInt(e, isDV6)}
                                        />
                                    </div>
                                    <div>
                                        <label className="settings-title mr-10">{t('titleQuality')}(1 to 5):  </label>
                                        <input
                                            type="number"
                                            step="1"
                                            min="1"
                                            max="5"
                                            maxLength="1"
                                            name={`Channels.${index}.MainQuality`}
                                            value={currentConfig.Channels[index].MainQuality + 1} // FWSD-7690, For the Quality field we have to display 1-5 values instead of stored 0-4
                                            className={`vehicle-search number-input mr-10 pt-5${channelQualityValidation(currentConfig.Channels[index].MainQuality) ? ' error-input' : ''}`}
                                            onChange={(e) => fillFormInt(e, isDV6, null, (+e.target.value - 1))}
                                        />
                                    </div>
                                </div>
                                
                                <div className="toggle-wrapper button-group">
                                    <label className="settings-title">{t('titleParkingModeQuality')}</label>
                                    <div>
                                        {isDV6 &&
                                            <span className="synchronize-fps-icon">
                                                <CustomButton variant="link" onClick={() => setLinkedFPS(linkedFPSName)} >
                                                    { linked_fps[linkedFPSName] ? linkIcon : unlinkIcon }
                                                </CustomButton>
                                            </span>
                                        }
                                        <label className="settings-title mr-10">FPS(1 to 30):</label>
                                        <input
                                            type="number"
                                            step="1"
                                            min="1"
                                            max="30"
                                            maxLength="2"
                                            name={`Channels.${index}.ParkingFPS`}
                                            value={currentConfig.Channels[index].ParkingFPS}
                                            className={`vehicle-search number-input mr-10 pt-5${channelFPSValidation(currentConfig.Channels[index].ParkingFPS) ? ' error-input' : ''}${isDV6 && linked_fps[linkedFPSName] ? ' disabled' : ''}`}
                                            disabled={isDV6 && linked_fps[linkedFPSName]}
                                            onChange={(e) => fillFormInt(e, isDV6)}
                                        />
                                    </div>
                                    <div>
                                        <label className="settings-title mr-10">{t('titleQuality')}(1 to 5):  </label>
                                        <input
                                            type="number"
                                            step="1"
                                            min="1"
                                            max="5"
                                            maxLength="1"
                                            name={`Channels.${index}.ParkingQuality`}
                                            value={currentConfig.Channels[index].ParkingQuality + 1} // FWSD-7690, For the Quality field we have to display 1-5 values instead of stored 0-4
                                            className={`vehicle-search number-input mr-10 pt-5${channelQualityValidation(currentConfig.Channels[index].ParkingQuality) ? ' error-input' : ''}`}
                                            onChange={(e) => fillFormInt(e, isDV6, null, (+e.target.value - 1))}
                                        />
                                    </div>
                                </div>
                                </>
                                ) : (
                                <div className="toggle-wrapper button-group">
                                    <label className="settings-title">{t('titleRecordingQuality')}</label>
                                    <div className="button-group">
                                        <CustomButton
                                            variant='toggle-group'
                                            active={!!isQualityActive(item, 'Most Storage')}
                                            value='Most Storage'
                                            name={index}
                                            onClick={setChannelQuality}
                                        >
                                            {t('btnMostStorage')}
                                        </CustomButton>
                                        <CustomButton
                                            variant='toggle-group'
                                            active={!!isQualityActive(item, 'Default')}
                                            value='Default'
                                            name={index}
                                            onClick={setChannelQuality}
                                        >
                                            {t('btnDefault')}
                                        </CustomButton>
                                        <CustomButton
                                            variant='toggle-group'
                                            active={!!isQualityActive(item, 'Best Video')}
                                            value='Best Video'
                                            name={index}
                                            onClick={setChannelQuality}
                                        >
                                            {t('btnBestVideo')}
                                        </CustomButton>
                                    </div>
                                </div>
                                )}

                                {((isDV6 && [1, 2].includes(index)) || (isDV4 && index !== 0)) &&
                                    <div className="toggle-wrapper button-group">
                                        <label className="settings-title">{t('titleRecordAudio')}</label>
                                        {isDV6 && index === 2 && (<label className="sublabel">{t('textRequired')} DV607 Rev. B</label>)}
                                        <Toggle
                                            name={`Channels.${index}.Audio`}
                                            checked={currentConfig.Channels[index].Audio === 1}
                                            icons={false}
                                            onChange={(e) => fillForm(e, isDV6)}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                )
            })}
        </main>
    )
}

FleetConfigurationChannel.propTypes = {
    webfleetDeviceId: PropTypes.string,
}

FleetConfigurationChannel.defaultProps = {
    webfleetDeviceId: '',
}

export default FleetConfigurationChannel;
