import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import { expandTableOptions } from '../../component/helpers/table'
import FleetAdminModemContainerBSTable from './FleetAdminModemContainerBSTable'

import { getDevicesModems } from '../../action/DeviceActions'
import { upgradeGroupModemFirmware } from '../../action/FleetAdminActions'

import SearchBar from '../../component/dump/menuComponents/SearchBar'
import FleetAdminModemUploadForm from '../../component/smart/menuComponentsContainer/FleetAdminModemUploadForm'
import FleetAdminRouterUploadForm from './FleetAdminRouterUploadForm'
import GreyHeader from '../../component/dump/menuComponents/GreyHeader'
import { right } from '@popperjs/core'

import '../../component/dump/menuComponents/menuComponents.sass'
import CustomButton from '../../component/CustomButton'

const FleetAdminModemContainer = (props) => {
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'modemsPage.fleetAdminModemContainer' });
	const { user, company, toggleLoader } = props;

	const [searchText, setSearchText] = useState("");
	const [modems, setModems] = useState([]);
	const [initialModems, setInitialModems] = useState([]);
	const [groups, setGroups] = useState([]);
	const [activeDevice, setActiveDevice] = useState(null);

	useEffect(() => {
		// console.log('!!!!!!!!componentDidMount FleetAdminModemContainer')
		switch (user.roles[0]) {
			case 'system_admin':
			case 'customer_service':
				if (company.company && company.company.name && company.company.partner) {
					devicesModems(company.company.name, company.company.partner)
				}
				break;
			case 'partner':
				if (company.company.name) {
					devicesModems(company.company.name)
				}
				break;
			default:
				break;
		}
	}, [company.company.name, company.company.partner]);

	const devicesModems = async (_company_name = null, _partner_name = null) => {
		toggleLoader(true);

		const data = {
			user_role: user.roles[0],
			company_name: _company_name || company.company.name || user.company_name,
			partner_name: _partner_name || company.company.partner
		}

		if (data.company_name, data.partner_name) {
			await getDevicesModems(data)
				.then((res, err) => {
					console.log('!getDevicesModems res:', res, err)

					const modems = res.data.response.devices
					let groupsArr = []

					const groups = _.groupBy(modems, (device) => device.groups[0])
					// console.log('!groups', groups);

					const keys = Object.keys(groups); // keys - groups name, ["group1", "MASTER GROUP", "test group", "group2", "undefined", "master", "testing_group"]
					// console.log('!keys', keys);

					for (let i = 0; i < keys.length; i++) {
						let k = keys[i];
						const group = groups[k];
						if (k === 'undefined' || k === 'null') {
							k = t('withoutGroup')
						}

						groupsArr.push({ group_name: k, expand: group })
					}
					groupsArr = _.sortBy(groupsArr, group => group.group_name.toLowerCase());
					// console.log('!groupsArr', groupsArr);

					setModems(modems);
					setInitialModems(modems.slice(0));
					setGroups(groupsArr);
				})
				.catch((error) => {
					toggleLoader(false);
					console.log('!getDevicesModems error', error.response)
					setModems([]);
					let errDescription = i18n.t('errorMessage.errDescription');
					if (error.response.data.response.error) {
						errDescription = error.response.data.response.error
					}
					toastr.error(errDescription);
				})
		}

		toggleLoader(false);
	};

	const dataSearch = (e) => {
		const value = e.target.value.toLowerCase()
		const modems = initialModems.filter((devices) => {
			return (
				(devices.device_id && devices.device_id.toString().toLowerCase().includes(value))
				|| (devices.vehicle_name && devices.vehicle_name.toLowerCase().includes(value))
				|| (devices.modem.imei && ("" + devices.modem.imei).toLowerCase().includes(value))
			)
		});

		let groupsArr = [];

		const groups = _.groupBy(modems, device => device.groups[0])
		const keys = Object.keys(groups); //keys - groups name, ["group1", "MASTER GROUP", "test group", "group2", "undefined", "master", "testing_group"]

		for (let i = 0; i < keys.length; i++) {
			let k = keys[i];
			const group = groups[k];
			if (k == 'undefined' || k == 'null') {
				k = t('withoutGroup')
			}

			groupsArr.push({ 'group_name': k, 'expand': group /*, import_status: import_status*/ });
		}
		groupsArr = _.sortBy(groupsArr, group => group.group_name.toLowerCase());
		console.log('!groupsArr', groupsArr);

		setModems(modems);
		setGroups(groupsArr);
		setSearchText(value);
	};

	const setConfigParams = (states) => {
		setSearchText(states.searchText);
		setModems(states.modems);
		setInitialModems(states.initialModems);
		setGroups(states.groups);
		setActiveDevice(states.activeDevice);
	};

	const expandComponent = row => {
		return (
			<FleetAdminModemContainerBSTable
				data={row}
				setConfigParams={setConfigParams}
			/>
		);
	};

	const upgradeGroupFirmware = (e, group_name) => {
		console.log('upgradeGroupFirmware', e, group_name)
		e.stopPropagation()
		// e.preventDefault();

		toggleLoader(true);

		let groupsValue = groups;
		const group = _.find(groupsValue, { 'group_name': group_name })

		const data = {
			company_name: company.company.name,
			partner_name: company.company.partner,
			group_name: group_name,
			devices_id: (group) ? _.map(group.expand, 'device_id').join(',') : ''
		};
		upgradeGroupModemFirmware(data)
			.then(res => {
				console.log('!upgradeGroupModemFirmware res', res)
				toggleLoader(false);

				const response = res.data.response;
				let result;

				// let group = _.find(groups, function(obj) {
				//     return (obj.group_name == group_name)
				// })
				// console.log('!!!group', group)

				if (group) {
					for (let key in response) {
						group.expand.map((item, i) => {
							if (item.device_id == key) {
								item.modem.firmware_status = response[key]
							}

							return item
						})
					}
					setGroups(groupsValue);
				}
			})
			.catch((error) => {
				console.log('!upgradeGroupModemFirmware error', error.response)
				toggleLoader(false);
				let errDescription = i18n.t('errorMessage.errDescription');
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription);
			})
	};

	const upgradeStatus = (row) => {
		return (
			<CustomButton variant="primary" size="xs" prefix="widthAuto" onClick={(e) => { upgradeGroupFirmware(e, row.group_name) }}>{t('btnUpgradeGroup')}</CustomButton>
		)
	};

	const fromatGroupName = (cell, row) => {
		const size = row.expand.length;
		let amount = t('vehicles');

		if (size == 1) {
			amount = t('vehicle');
		}

		return <span>{row.group_name} ({size} {amount}) <span className="group-import-indicator" style={{ float: 'right' }}>{upgradeStatus(row)}</span></span>;
	};

	const userRole = user.roles[0];
	const company_name = company.company.name;
	const companyData = _.find(props.company_collection, ['name', company_name]);
	const companyId = companyData ? companyData.id : null;

	return (
		<div className="fleet-admin-modem">
			<GreyHeader
				title={t('titleModems')}
				subtitle={company_name}
			/>

			<div className='page-subheader'>
				<div className='subheader-section search'>
					<SearchBar
						term={searchText}
						onSearch={dataSearch}
					/>
				</div>
			</div>

			<main className="main-content-block">
				<div className="manage-assets-table modem-page-table">
					<BootstrapTable
						data={groups}
						{...expandTableOptions}
						expandComponent={expandComponent}
					>
						<TableHeaderColumn
							isKey
							width="100%"
							dataField="group_name"
							dataFormat={fromatGroupName}
						>
							{t('headerGroupName')}
						</TableHeaderColumn>
					</BootstrapTable>
				</div>

				{['system_admin', 'partner'].includes(userRole)
					&& (
						<div>
							{userRole === 'system_admin'
								&& <FleetAdminModemUploadForm />
							}

							{companyId
								&& (
									<FleetAdminRouterUploadForm
										companyId={companyId}
										userRole={userRole}
										setConfigParams={setConfigParams}
									/>
								)}
						</div>
					)}
			</main>
		</div>
	)
}

export default connect(
	state => ({
		user: state.user.user,
		company: state.company,
		company_collection: state.partnerList.company_collection,
	}),
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(FleetAdminModemContainer);
