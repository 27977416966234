import i18n from 'i18next';
const env = location.hostname;

export function transformationLabel(label) {
	switch (label) {
		case 'bar_top_10':
			// return 'Bar Graph Top 10 vehicles';
			return i18n.t('reportsPage.charts.transformationLabel.bar_top_10');
		case 'bar_bottom_10':
			// return 'Bar Graph Bottom 10 Vehicles';
			return i18n.t('reportsPage.charts.transformationLabel.bar_bottom_10');
		case 'bar_custom':
			return i18n.t('reportsPage.charts.transformationLabel.bar_custom');
		case 'pie_top_10':
			// return 'Pie Chart Top 10 Vehicles';
			return i18n.t('reportsPage.charts.transformationLabel.pie_top_10');
		case 'pie_bottom_10':
			// return 'Pie Chart Bottom 10 Vehicles';
			return i18n.t('reportsPage.charts.transformationLabel.pie_bottom_10');
		case 'pie_custom':
			return i18n.t('reportsPage.charts.transformationLabel.pie_custom');
		case 'last_6_month':
			return i18n.t('reportsPage.charts.transformationLabel.last_6_month');
		case 'today':
			return i18n.t('reportsPage.charts.transformationLabel.today');
		case 'yesterday':
			return i18n.t('reportsPage.charts.transformationLabel.yesterday');
		case 'prev_24_hours':
			return i18n.t('reportsPage.charts.transformationLabel.prev_24_hours');
		case 'prev_7_days':
			return i18n.t('reportsPage.charts.transformationLabel.prev_7_days');
		case 'this_week':
			return i18n.t('reportsPage.charts.transformationLabel.this_week');
		case 'last_week':
			return i18n.t('reportsPage.charts.transformationLabel.last_week');
		case 'prev_14_days':
			return i18n.t('reportsPage.charts.transformationLabel.prev_14_days');
		case 'this_month':
			return i18n.t('reportsPage.charts.transformationLabel.this_month');
		case 'last_month':
			return i18n.t('reportsPage.charts.transformationLabel.last_month');
		case 'prev_4_weeks':
			return i18n.t('reportsPage.charts.transformationLabel.prev_4_weeks');
		case 'prev_30_days':
			return i18n.t('reportsPage.charts.transformationLabel.prev_30_days');
		case 'name':
			return i18n.t('reportsPage.charts.transformationLabel.name');
		case 'type':
			return i18n.t('reportsPage.charts.transformationLabel.type');
		// case 'date_range':
		//     return i18n.t('reportsPage.charts.transformationLabel.date_range');
		//     break;
		case 'events':
			return i18n.t('reportsPage.charts.transformationLabel.events');
		case 'alerts':
			return i18n.t('reportsPage.charts.transformationLabel.alerts');
		default:
			break;
	}
}

export function defineEnvironmentDokuWiki() {
	if (process.env.REACT_APP_dokuwikiLinks && process.env.REACT_APP_dokuwikiLinks.url) {
		return process.env.REACT_APP_dokuwikiLinks.url
	}

	switch (env) {
		case 'localhost':
		case 'test.roscolive.com':
		case 'dev.roscolive.com':
		case 'staging.roscolive.com':
			return 'devwiki.roscocloud.com';
		case 'canada.roscolive.com':
		case 'roscocloud.com':
		case 'www.roscocloud.com':
		case 'fleet.roscolive.com':
			return 'wiki.roscocloud.com';
		default:
			break;
	}
}

export function getDirection(direction, abbreviation = false) {
	let dir = 'Unknown'
	if (direction > 337.5 || direction <= 22.5)
		dir = abbreviation ? 'N' : 'North'
	else if (direction > 22.5 && direction <= 67.5)
		dir = abbreviation ? 'NE' : 'North East'
	else if (direction > 67.5 && direction <= 112.5)
		dir = abbreviation ? 'E' : 'East'
	else if (direction > 112.5 && direction <= 157.5)
		dir = abbreviation ? 'SE' : 'South East'
	else if (direction > 157.5 && direction <= 202.5)
		dir = abbreviation ? 'S' : 'South'
	else if (direction > 202.5 && direction <= 247.5)
		dir = abbreviation ? 'SW' : 'South West'
	else if (direction > 247.5 && direction <= 292.5)
		dir = abbreviation ? 'W' : 'West'
	else if (direction > 292.5 && direction <= 337.5)
		dir = abbreviation ? 'NW' : 'North West'

	return dir
}

export const getDirectionIcon = (direction) => {
	switch (direction) {
		case 'n':
			return 'North'
		case 'ne':
			return 'North East'
		case 'e':
			return 'East'
		case 'se':
			return 'South East'
		case 's':
			return 'South'
		case 'sw':
			return 'South West'
		case 'w':
			return 'West'
		case 'nw':
			return 'North West'
		default:
			return 'Unknown'
	}
}
