import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Modal } from 'react-bootstrap'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import SearchBar from '../../../SearchBar'
import { defaultTableOptions } from '../../../helpers/table'
import { deleteReportSubscription, getReportSubscriptions, updateReportSubscription } from '../../../../action/ReportsActions'
import { toastr } from 'react-redux-toastr'
import { useTranslation } from 'react-i18next'
import '../../../dump/menuComponents/menuComponents.sass'
import { formatTimestamp } from '../../../helpers/tablesFuncHelpers'
import CustomButton from '../../../CustomButton'

const ReportSubscriptionsModal = (props) => {
    const { t, i18n } = useTranslation('translation', { keyPrefix: 'reportsPage.reportSubscriptionsModal' });
    const { user, onHide, loaderModal, toggleLoaderModal } = props;

    const [reportSearch, setReportSearch] = useState('');
    const [subscriptions, setSubscriptions] = useState([]);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [selectedReport, setSelectedReport] = useState({});

    useEffect(() => {
        getSubscriptions()
    }, [])

    const getSubscriptions = () => {
        toggleLoaderModal(true);
        getReportSubscriptions(user.email)
            .then(res => {
                toggleLoaderModal(false);
                setSubscriptions(res.data.response.subscriptions)
            })
            .catch(err => {
                toggleLoaderModal(false);
            })
    }

    const unsubscribe = () => {
        if (props.user.id === selectedReport.owner_id || selectedReport.subscribers.length === 1) {
            toggleLoaderModal(true);
            deleteReportSubscription(selectedReport.id)
                .then(res => {
                    toastr.success(t('toastrUnsubscribed', { selectedReportName: selectedReport.report_name }))
                    setSelectedReport({})
                    setShowConfirmModal(false)
                    getSubscriptions();
                })
                .catch(err => {
                    toastr.error(t('toastrUnableUnsubscribe'))
                    setSelectedReport({})
                    setShowConfirmModal(false)
                    getSubscriptions();
                })
        }
        else {
            toggleLoaderModal(true);
            const data = {
                id: selectedReport.id,
                subscribers: selectedReport.subscribers.filter(user => user !== props.user.email),
            }
            updateReportSubscription(data)
                .then(res => {
                    toastr.success(t('toastrUnsubscribed', { selectedReportName: selectedReport.report_name }))
                    setSelectedReport({})
                    setShowConfirmModal(false)
                    getSubscriptions();
                })
                .catch(err => {
                    toastr.error(t('toastrUnableUnsubscribe'))
                    setSelectedReport({})
                    setShowConfirmModal(false)
                    getSubscriptions();
                })
        }
    }

    const filteredSubscriptions = reportSearch ? subscriptions.filter(sub => sub.report_name.toLowerCase().includes(reportSearch.toLowerCase())) : subscriptions;

    const formatActions = (cell, row) => {
        if (row) {
            return <span>
                <CustomButton variant="link" onClick={() => props.viewReport(row)}>{t('btnView')}</CustomButton>
                <CustomButton variant="link" onClick={() => {
                    setShowConfirmModal(true)
                    setSelectedReport(row)
                }}>
                    {t('btnUnsubscribe')}
                </CustomButton>
            </span>
        }
    }

    const formatReportType = (cell, row) => {
        if (cell === 'custom') {
            const customReportType = t(row.custom_report_type) + t('textReport')
            return t('textCustom') + customReportType
        }
        else {
            if (cell === "standard")
                return t('textStandard')
        }
    }

    const formatReportName = (cell, row) => {
        if (row.report_type === "standard") {
            return i18n.t(`reportsPage.standardReportData.${cell.replace(/\s/g, "_")}`)
        } else {
            return cell
        }
    }

    return (
        <Modal
            size='xl'
            show
            onHide={onHide}
            className="reports-modal email-report-modal table-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>{t('titleReportSubscriptions')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="subscriptions-body">
                    <div className='modal-subtitle'>{t('subtitle')}</div>
                    <div className='modal-search'>
                        <SearchBar term={reportSearch} onSearch={(e) => setReportSearch(e.target.value)} />
                    </div>
                    <BootstrapTable
                        data={filteredSubscriptions}
                        {...defaultTableOptions}
                    >
                        <TableHeaderColumn
                            width="20%"
                            dataField="report_name"
                            dataFormat={formatReportName}
                            isKey
                        >
                            {t('tabReportName')}
                        </TableHeaderColumn>

                        <TableHeaderColumn width="12%"
                            dataField="report_type"
                            dataFormat={formatReportType}>
                            {t('tabReportType')}
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            width="12%"
                            dataField="frequency"
                            dataFormat={(cell) => cell ? i18n.t(`reportsPage.reportConst.${cell}`) : ''}
                        >
                            {t('tabTimePeriod')}
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            width="10%"
                            dataField="day_run"
                            dataFormat={(cell) => cell ? i18n.t(`reportsPage.reportConst.${cell}`) : '-'}
                        >
                            {t('tabWeekday')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            width="10%"
                            dataField="last_run"
                            dataFormat={formatTimestamp}
                        >
                            {t('tabLastRun')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            width="15%"
                            dataField=""
                            dataFormat={formatActions}
                        >
                            {t('tabActions')}
                        </TableHeaderColumn>
                    </BootstrapTable>
                </div>
            </Modal.Body>
            {loaderModal}

            <Modal
                show={showConfirmModal}
                onHide={() => setShowConfirmModal(false)}
                className="reports-modal modal-lg-size"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t('btnAreYouSure')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='text-center'>
                        {t('btnUnsubscribeReport', { selectedReportName: selectedReport.report_name })}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <CustomButton variant="delete" onClick={unsubscribe}>{t('btnFooterUnsubscribe')}</CustomButton>
                </Modal.Footer>
            </Modal>
        </Modal>
    )
}

export default connect(
    state => ({
        user: state.user.user,
        company: state.company.company,
        loaderModal: state.loader.loaderModal,
    }),
    dispatch => ({
        toggleLoaderModal: (show) => {
            dispatch({ type: 'TOGGLE_LOADER_MODAL', payload: show });
        }
    }),
)(ReportSubscriptionsModal);
