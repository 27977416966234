import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { toastr } from 'react-redux-toastr'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { companyHash, getWebfleetToken } from '../../../action/GeotabActions'
import { apiCall } from '../../../action/RouterActions'

import '../../dump/menuComponents/menuComponents.sass'

const WebfleetCredentialsContainer = (props) => {
	const { toggleLoader } = props;
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'geotab.webfleetCredentialsContainer' });
	const [company, setCompany] = useState('');
	const [systemSettings, setSystemSettings] = useState('');
	const [data, setData] = useState({
		username: '', // romabogdan@gmail.com
		password: '', // roman2012
		account: '',
	});
	const [errMessage, setErrMessage] = useState({
		err_username: '', // romabogdan@gmail.com
		err_password: '', // roman2012
		err_account: '',
	});
	const [modal, setModal] = useState({
		show: false,
		title: '',
		content: '',
		error: false,
	});
	const [togglePassword, setTogglePassword] = useState(true);

	useEffect(() => {
		toggleLoader(true);

		const hash = props.params.companyHash;
		companyHash(hash)
			.then((res, err) => {
				toggleLoader(false);
				console.log('!companyHash res: ', res)

				setCompany(res.data.response);
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!gcompanyHash error', error)
				console.log(JSON.stringify(error))

				let errDescription = i18n.t('errorMessage.errDescription');
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}, []);

	const fillForm = (e) => {
		const { target } = e;
		setData(prevState => ({ ...prevState, [target.name]: target.value }));
	}

	const checkForm = (data) => {
		let error = false;
		const errMessageUpdate = errMessage;

		for (const i in data) {
			if (data[i] === '') {
				error = true;
				errMessageUpdate[['err_' + i]] = t('errEnterYour') + i + '!';
			}
			else if (errMessageUpdate[['err_' + i]]) {
				errMessageUpdate[['err_' + i]] = null;
			}
		}

		setErrMessage(prevState => ({ ...prevState, ...errMessageUpdate }))
		return error;
	}

	const sendForm = (e) => {
		e.preventDefault();

		const error = checkForm(data)
		if (!error) {
			toggleLoader(true);

			// const url = `https://csv.webfleet.com/extern?account=${data.account}&username=${data.username}&password=${data.password}&lang=en&action=createSession&outputformat=json&apikey=${constants.webfleet_apikey}`
			// axios.get(url)
			const params = {
				account: data.account,
				username: data.username,
				password: encodeURI(data.password),
			}
			getWebfleetToken(params)
				.then((res) => {
					console.log('sendForm res: ', res)
					if (res.data.errorMsg) {
						toggleLoader(false);
						toastr.error(`Webfleet: ${res.data.errorMsg}`);
					}
					else {
						apiCall('PUT', '/external_service/webfleet/account', { ...data, company_id: company.id, role: 'fleet_manager' })
							.then((res) => {
								toggleLoader(false);
								toastr.success('Success', t('toastrCredentialsWereSaved'));
							})
							.catch((error) => {
								toggleLoader(false);
								console.log('!sendForm error: ', error.response, error)
								if (error.response.data.response.error) {
									toastr.error(error.response.data.response.error)
								}
								else {
									toastr.error(i18n.t('errorMessage.errDescription'));
								}
							})
					}
				})
				.catch((error) => {
					toggleLoader(false);
					console.log('Error on Authentication error: ', error)
					let errDescription = i18n.t('errorMessage.errDescription');
					if (error.response.data.response.error) {
						errDescription = error.response.data.response.error
					}
					toastr.error(errDescription)
				});
		}
	}

	// console.log('PROPS WebfleetCredentialsContainer: ', props);
	// console.log('STATE WebfleetCredentialsContainer: ', state);
	const { err_username, err_password, err_account } = errMessage;

	return (
		<div className="panel-body">
			<h3 className="login-header">{t('headerWebfleetCredentials')}</h3>
			<div className="form-wrapper">
				<form onSubmit={sendForm} autoComplete="of" id="login-form">
					<div className={classnames('form-group', { 'has-error': err_account })} style={{ paddingTop: '10px' }}>
						<label className={`login-label ${(data.account.length) ? 'active' : ''}`}>{t('labelAccount')}</label>
						<input
							type="text"
							id="myDatabase"
							name="account"
							className="vehicle-search"
							autoComplete='new-password'
							placeholder={t('placeholderAccount')}
							value={data.account}
							onChange={fillForm}
						/>
						{err_account && <span className="help-block">{err_account}</span>}
					</div>
					<div className={classnames('form-group', { 'has-error': err_username })}>
						<label className={`login-label ${(data.username.length) ? 'active' : ''}`}>{t('labelUsername')}</label>
						<input
							type="text"
							id="myGemail"
							name="username"
							className="vehicle-search"
							autoComplete="new-password"
							placeholder={t('placeholderUsername')}
							value={data.username}
							onChange={fillForm}
						/>
						{err_username && <span className="help-block">{err_username}</span>}
					</div>
					<div className={classnames('form-group has-feedback', { 'has-error': err_password })}>
						<label className={`login-label ${(data.password.length) ? 'active' : ''}`}>{t('labelPassword')}</label>
						<input
							type={togglePassword ? 'password' : 'text'}
							id="myPassword"
							name="password"
							className="vehicle-search"
							data-toggle="password"
							autoComplete="new-password"
							placeholder={t('placeholderPassword')}
							value={data.password}
							onChange={fillForm}
						/>
						<span className="fa fa-eye form-control-feedback" onClick={() => setTogglePassword(!togglePassword)} />
						{err_password && <span className="help-block">{err_password}</span>}
					</div>
					<div className="form-group submit-btn" style={{ paddingTop: '30px' }}>
						<input type="submit" className="ui account-btn" id="login-button" value={t('btnSave')} />
					</div>
				</form>
			</div>
		</div>
	)
}

const mapDispatchToProps = (dispatch) => {
	return {
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		},
	}
}

export default connect(null, mapDispatchToProps)(WebfleetCredentialsContainer);
