import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'
import moment from 'moment-timezone'
import { jsPDF } from 'jspdf'
import { useTranslation } from 'react-i18next'

import momenttz from '../../helpers/momenttz'
import { apiCallGet } from '../../../action/RouterActions'
import CustomButton from '../../CustomButton'
import logo from '../../../../../assets/rosco-logo-horizontal.jpg'

const ModalInstallReport = (props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'manageVehiclesPage.editAssetContainer.modalInstallReport' });
	const { device, onHide, toggleLoader } = props;

	const deviceInstallImages = [
		{ name: 'channel_1', description: t('Channel 1') },
		{ name: 'channel_2', description: t('Channel 2') },
		{ name: 'channel_3', description: t('Channel 3') },
		{ name: 'channel_4', description: t('Channel 4') },
		{ name: 'windshield_img', description: t('Camera on Windshield') },
		{ name: 'obd_mount', description: t('OBD Mount') },
		{ name: 'obd_zip_tie', description: t('OBD Zip Tie') },
		{ name: 'antenna_img', description: t('Antenna Installation') },
		{ name: 'power_img', description: t('Power Connection Zip Tie') },
		{ name: 'pdc_img', description: t('PDC') },
		{ name: 'modem_img', description: t('PDC / Modem Secure') }
	];

	const [deviceinstall, setDeviceinstall] = useState({});
	const [showModalImageView, setShowModalImageView] = useState(false);
	const [imageViewSRC, setImageViewSRC] = useState('');

	useEffect(() => {
		getDeviceInstall();
	}, [])

	const getDeviceInstall = () => {
		toggleLoader(true);
		const deviceId = device.serial_number ? device.serial_number : device.device_id

		apiCallGet('/installerapp/device/{deviceId}', { deviceId })
			.then((res, err) => {
				toggleLoader(false);

				setDeviceinstall(res.data.response)
			})
			.catch((error) => {
				toggleLoader(false);

				if (error.response && error.response.data.response.error) {
					toastr.error(error.response.data.response.error)
				} else {
					toastr.error(JSON.stringify(error))
				}
				onHide()
			})
	}

	const hideModalImageView = () => {
		setShowModalImageView(false)
	}

	const handleImageViewClick = (e) => {
		setImageViewSRC(e.target.getAttribute('src'));
		setShowModalImageView(true);
	}

	const handleExportPDFClick = (rows) => {
		const stylePDF = {
			rows: {
				paddingTop: 35,
				paddingLeft: 25,
				fontSize: 11,
				lineHeight: 7,
				width: 150,
				chapter: {
					fontSize: 18,
				},
			},
			images: {
				width: 80,
				height: 40,
			}
		}

		const doc = new jsPDF()

		// Report Header
		doc.setFontSize(10);
		doc.addImage(`${logo}?r=${Math.floor(Math.random() * 100000)}`, 'JPEG', 5, 5, 55, 15);
		doc.setFont(undefined, 'bold');
		doc.text('Rosco Vision', 55 + 15, 5 + 5);
		doc.setFont(undefined, 'normal');
		doc.text('90-21144th Place, Jamaica, New York 11435', 55 + 15, 5 + 5 + 5);
		doc.text('(718) 526-2601', 55 + 15, 5 + 5 + 5 * 2);

		// Rows
		let rowsCount = -2
		rows.map((itemTitle) => {
			// Rows Header
			rowsCount += 2
			doc.setFontSize(stylePDF.rows.chapter.fontSize)
			doc.setTextColor(41, 169, 224)
			doc.setFont(undefined, 'bold')
			doc.text(itemTitle[0], stylePDF.rows.paddingLeft, stylePDF.rows.paddingTop + stylePDF.rows.lineHeight * rowsCount);

			// Rows Cell
			doc.setFontSize(stylePDF.rows.fontSize)
			doc.setTextColor('black')
			doc.setFont(undefined, 'normal')
			itemTitle.map((itemCell, i) => {
				if (i !== 0) {
					rowsCount += 1
					doc.text(itemCell[0], stylePDF.rows.paddingLeft, stylePDF.rows.paddingTop + stylePDF.rows.lineHeight * rowsCount);
					doc.text(itemCell[1], stylePDF.rows.width, stylePDF.rows.paddingTop + stylePDF.rows.lineHeight * rowsCount, null, null, 'right');
					const cellTitleLength = doc.getTextWidth(itemCell[0])
					const cellValueLength = doc.getTextWidth(itemCell[1])
					doc.setLineDash([0.3, 0.3])
					doc.line(
						stylePDF.rows.paddingLeft + cellTitleLength + 1, // x1
						stylePDF.rows.paddingTop + stylePDF.rows.lineHeight * rowsCount, // y1
						stylePDF.rows.width - cellValueLength - 1, // x2
						stylePDF.rows.paddingTop + stylePDF.rows.lineHeight * rowsCount, // y2
					)
				}
			})
		})

		// Images Header
		rowsCount += 2
		doc.setFontSize(stylePDF.rows.chapter.fontSize)
		doc.setTextColor(41, 169, 224)
		doc.setFont(undefined, 'bold')
		doc.text(t('installImg'), stylePDF.rows.paddingLeft, stylePDF.rows.paddingTop + stylePDF.rows.lineHeight * rowsCount);

		let imagesPaddingTop = stylePDF.rows.paddingTop + stylePDF.rows.lineHeight * rowsCount + 10

		// Images
		doc.setFontSize(stylePDF.rows.fontSize + 1)
		doc.setTextColor('black')
		doc.setFont(undefined, 'normal')

		let imagesCount = -1
		deviceInstallImages.forEach(({ name, description }) => {
			if (deviceinstall[name]) {
				// if images more 4 then adds new page and draws other images there
				if (imagesCount === 3) {
					imagesPaddingTop = 10
					imagesCount = -1
					doc.addPage();
				}

				imagesCount += 1
				const imageRowNumber = Math.trunc(imagesCount / 2)

				if (imagesCount % 2) { // if is !odd (even) image then draw it in second/right column
					doc.text(description, stylePDF.rows.paddingLeft + stylePDF.images.width + 10, imagesPaddingTop + 50 * imageRowNumber);

					const imgData = new Image();
					imgData.crossOrigin = 'anonymous'
					imgData.src = `${deviceinstall[name]}&r=${Math.floor(Math.random() * 100000)}`
					// console.log('!!!imgData: ', imgData)

					doc.addImage(
						imgData,
						'JPEG',
						stylePDF.rows.paddingLeft + stylePDF.images.width + 10, // x
						imagesPaddingTop + 50 * imageRowNumber + 3, // y
						stylePDF.images.width, // width
						stylePDF.images.height, // height
					)
				} else {
					doc.text(description, stylePDF.rows.paddingLeft, imagesPaddingTop + 50 * imageRowNumber);

					const imgData = new Image();
					imgData.crossOrigin = 'anonymous'
					imgData.src = `${deviceinstall[name]}&r=${Math.floor(Math.random() * 100000)}`
					// console.log('!!!imgData: ', imgData)

					doc.addImage(
						imgData,
						'JPEG',
						stylePDF.rows.paddingLeft, // x
						imagesPaddingTop + 50 * imageRowNumber + 3, // y
						stylePDF.images.width, // width
						stylePDF.images.height, // height
					)
				}
			}
		})

		doc.save('install_report.pdf');
	}

	const getInstallDate = () => {
		const { install_ts, created_at } = deviceinstall;

		if (install_ts) {
			return moment(install_ts).format('M/D/YYYY hh:mm:ss a')
		}

		if (created_at) {
			return momenttz(deviceinstall.created_at).format('M/D/YYYY hh:mm:ss a')
		}

		return ''
	}

	const rows = [
		[
			t('Installation Info'),
			[t('Form ID'), deviceinstall.installed_id ? deviceinstall.installed_id.toString().padStart(5, '0') : ''],
			[t('Install Date'), getInstallDate()],
			[t('VIN'), deviceinstall.vin_number || ''],
			[t('Plate #'), deviceinstall.tag_plate || ''],
			[t('Camera Model'), deviceinstall.device_type ? deviceinstall.device_type.toUpperCase() : ''],
			[t('Camera S/N'), deviceinstall.serial_number || ''],
			[t('First Connection Date'), deviceinstall.activated_timestamp ? momenttz(deviceinstall.activated_timestamp).format('M/D/YYYY hh:mm:ss a') : ''],
		],
		[
			t('Installer Info'),
			[t('Company'), deviceinstall.company_name || ''],
			[t('Name'), [deviceinstall.first_name, deviceinstall.last_name].join(' ') || ''],
			[t('Email'), deviceinstall.email || ''],
			[t('Phone'), deviceinstall.phone_number || ''],
			[t('MECP Certified'), deviceinstall.mecp_cert ? t('Yes') : t('No')],
		],
	];

	return (
		<Modal
			show
			onHide={onHide}
			size="lg"
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-lg">
					{t('titleInstallReport')}
					<span className="sub-title">{`${t('FORM_ID')} ${rows[0][1][1]}`}</span>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<section>
					<h4 className="category-title">{rows[0][0]}</h4>
					<div className="three-column-grid with-margin">
						<div>
							<label className="control-label">{t('Camera Model')}:</label>
							<input
								type="text"
								className="vehicle-search"
								value={rows[0][5][1]}
								readOnly
							/>
						</div>
						<div>
							<label className="control-label">{t('timeInstall')}:</label>
							<input
								type="text"
								className="vehicle-search"
								value={rows[0][2][1]}
								readOnly
							/>
						</div>
						<div>
							<label className="control-label">{t('First Connection Date')}:</label>
							<input
								type="text"
								className="vehicle-search"
								value={rows[0][7][1]}
								readOnly
							/>
						</div>
						<div>
							<label className="control-label">{t('Camera S/N')}:</label>
							<input
								type="text"
								className="vehicle-search"
								value={rows[0][6][1]}
								readOnly
							/>
						</div>
						<div>
							<label className="control-label">{t('VIN')}:</label>
							<input
								type="text"
								className="vehicle-search"
								value={rows[0][3][1]}
								readOnly
							/>
						</div>
						<div>
							<label className="control-label">{t('TAG/PLATE')}:</label>
							<input
								type="text"
								className="vehicle-search"
								value={rows[0][4][1]}
								readOnly
							/>
						</div>
					</div>
				</section>

				<section>
					<h4 className="category-title">{rows[1][0]}</h4>
					<div className="three-column-grid with-margin">
						<div>
							<label className="control-label">{t('Name')}:</label>
							<input
								type="text"
								className="vehicle-search"
								value={rows[1][2][1]}
								readOnly
							/>
						</div>
						<div>
							<label className="control-label">{t('Email')}:</label>
							<input
								type="text"
								className="vehicle-search"
								value={rows[1][3][1]}
								readOnly
							/>
						</div>
						<div>
							<label className="control-label">{t('Phone')}:</label>
							<input
								type="text"
								className="vehicle-search"
								value={rows[1][4][1]}
								readOnly
							/>
						</div>
						<div>
							<label className="control-label">{t('Company')}:</label>
							<input
								type="text"
								className="vehicle-search"
								value={rows[1][3][1]}
								readOnly
							/>
						</div>
						<div>
							<label className="control-label">{t('MECP Certified')}:</label>
							<input
								type="text"
								className="vehicle-search"
								value={rows[1][5][1]}
								readOnly
							/>
						</div>
					</div>
				</section>

				<section>
					<h4 className="category-title">{t('installImg')}</h4>
					<div className="device-install-images">
						{
							deviceInstallImages.map(({ name, description }) => {
								if (deviceinstall[name]) {
									return (<img
										role="button"
										key={name}
										src={deviceinstall[name]}
										className={name}
										alt={description}
										title={description}
										onClick={handleImageViewClick}
										onError={(e) => {
											console.log('Image load error name: ', name)
											e.target.onerror = null
											setDeviceinstall({ ...deviceinstall, [name]: null })
										}}
									/>)
								}
							})
						}
					</div>
				</section>
			</Modal.Body>
			<Modal.Footer>
				<CustomButton
					variant="primary"
					onClick={() => handleExportPDFClick(rows)}
				>
					{t('btnExportPDF')}
				</CustomButton>
			</Modal.Footer>

			{showModalImageView
				&& (
					<ModalImageView
						src={imageViewSRC}
						onHide={hideModalImageView}
					/>
				)}
		</Modal>
	)
}

ModalInstallReport.propTypes = {
	device: PropTypes.objectOf(PropTypes.any).isRequired,
	onHide: PropTypes.func.isRequired
}

export default connect(
	null,
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(ModalInstallReport);

const ModalImageView = (props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'manageVehiclesPage.editAssetContainer.modalInstallReport' });
	const { src, onHide } = props;
	return (
		<Modal
			show
			onHide={onHide}
			backdropClassName="upper"
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-lg">{t('titleImageView')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div>
					<img src={src} width="100%" id="event-snapshot" alt="" />
				</div>
			</Modal.Body>
		</Modal>
	);
}
