import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'
import _ from 'lodash'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import CustomButton from '../../CustomButton';
import AdasCalibrationSettings from '../../../pages/vehicles/AdasCalibrationSettings'
import { apiCall } from '../../../action/RouterActions'
import { fillConfig } from '../../helpers/helperConfig'

const AdasCalibrationSettingsModal = (props) => {
	// console.log('PROPS AdasCalibrationSettingsModal: ', props)
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'manageVehiclesPage.fleetConfigurationContainer.сalibrationNeededModal.adasCalibrationSettingsModal' });
	const { device, saveAdasCalibrationSettings, onHide, toggleLoader } = props
	const [selectedDevice, setSelectedDevice] = useState(null)

	useEffect(() => {
		setSelectedDevice(device)
	}, [])

	const saveAdasCalibrationSettingsModal = (argDevice) => {
		setSelectedDevice({ ...selectedDevice, ...argDevice })
	}

	const onSaveClick = () => {
		const { adas_vehicle_hood_len, adas_camera_height, adas_vehicle_width, adas_camera_offset } = selectedDevice
		const params = {
			device_id: selectedDevice.device_id,
		}
		const changedData = _.reduce(_.keys(selectedDevice), (result, key) => {
			if (!Object.prototype.hasOwnProperty.call(device, key) || !_.isEqual(device[key], selectedDevice[key])) {
				result[key] = selectedDevice[key]
				params[key] = selectedDevice[key]
			}
			return result;
		}, {});
		if (_.isEmpty(changedData)) {
			toastr.warning('', t('toastrSettingsNoChanges'))
			return
		}

		toggleLoader(true)
		apiCall('POST', '/devices/{device_id}', params)
			.then(res => {
				const paramsAdas = {}
				// Adas updates
				if (adas_vehicle_hood_len !== device.adas_vehicle_hood_len) {
					if (paramsAdas.Event && paramsAdas.Event.Adas) {
						paramsAdas.Event.Adas.VehicleHoodLength = adas_vehicle_hood_len * 1
					}
					else {
						const Adas = { VehicleHoodLength: adas_vehicle_hood_len * 1 }
						paramsAdas.Event = { Adas }
					}
				}
				if (adas_camera_height !== device.adas_camera_height) {
					if (paramsAdas.Event && paramsAdas.Event.Adas) {
						paramsAdas.Event.Adas.CameraHeight = adas_camera_height * 1
					}
					else {
						const Adas = { CameraHeight: adas_camera_height * 1 }
						paramsAdas.Event = { Adas }
					}
				}
				if (adas_vehicle_width !== device.adas_vehicle_width) {
					if (paramsAdas.Event && paramsAdas.Event.Adas) {
						paramsAdas.Event.Adas.VehicleWidth = adas_vehicle_width * 1
					}
					else {
						const Adas = { VehicleWidth: adas_vehicle_width * 1 }
						paramsAdas.Event = { Adas }
					}
				}
				if (adas_camera_offset !== device.adas_camera_offset) {
					if (paramsAdas.Event && paramsAdas.Event.Adas) {
						paramsAdas.Event.Adas.CameraOffset = adas_camera_offset * 1
					}
					else {
						const Adas = { CameraOffset: adas_camera_offset * 1 }
						paramsAdas.Event = { Adas }
					}
				}

				toastr.success('', t('toastrSuccessfullyUpdatedVehicle'))

				if (!_.isEmpty(paramsAdas)) {
					sendDeviceConfiguration(JSON.stringify(paramsAdas))
				} else {
					toggleLoader(false)
				}

				saveAdasCalibrationSettings(selectedDevice)
				onHide()
			})
			.catch((error) => {
				toggleLoader(false)
				console.log('!updateDevice sendForm error: ', error.response, error)
				toastr.error(i18n.t('errorMessage.errDescription'));
			})
	}

	const sendDeviceConfiguration = (params) => {
		apiCall('GET', '/config/{device_id}/file', { device_id: selectedDevice.device_id, checknewconfig: true })
			.then((res) => {
				let config = res.data.split('\n')
				console.log('config: ', config)
				config = fillConfig(config, params)

				let profileName = 'manual'
				if (config['IdInfo.FleetProfile']) {
					profileName = config['IdInfo.FleetProfile']
				}
				else if (selectedDevice.config_checksum === 1 && selectedDevice.pending_company_profile) {
					profileName = selectedDevice.pending_company_profile
				}
				else if (selectedDevice.uploaded_company_profile) {
					profileName = selectedDevice.uploaded_company_profile
				}

				console.log('config1: ', config)
				let result = ''
				Object.keys(config).forEach((key) => {
					if (config.hasOwnProperty(key)) {
						result += `${key}=${((typeof config[key]) === 'string') ? `"${config[key]}"` : config[key]}\r\n`
					}
				})

				console.log('result: ', result)
				const blob = new Blob([result], { type: 'text/plain;charset=utf-8' });
				const file = new File([blob], 'config.txt', { type: 'text/plain;charset=utf-8' })

				const paramsPost = {
					device_id: selectedDevice.device_id,
					file,
					restart: true,
					profile_name: profileName,
				}
				apiCall('POST', '/config/{device_id}/file', paramsPost)
					.then(res => {
						toggleLoader(false)
						toastr.success(t('toastrSuccessfullySentConfig'))
					})
					.catch((error) => {
						toggleLoader(false)
						console.log('!uploadConfig pushConfigFile error:', error.response, error)
						toastr.error(t('toastrUnableSendConfig'))
					})
			})
			.catch((error) => {
				toggleLoader(false)
				console.log('!getConfigFile error: ', error.response, error)
				let errDescription = t('toastrUnableSendConfig')
				if (error.response.data.response.error) {
					errDescription = errDescription + ': ' + error.response.data.response.error
				}
				toastr.error(errDescription)
			})
	}

	// console.log('PROPS AdasCalibrationSettingsModal: ', props)
	return (
		<Modal
			show
			size="lg"
			// size="large"
			onHide={onHide}
			className="config-modal"
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-lg">{device.vehicle_name} {t('titleCalibration')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="adas-calibration-modal">
					<p>
						<a href="https://support.roscovision.com/knowledge/how-do-i-take-measurements-for-dv6-adas-calibration" target="_blank" className="mb-10">{t('textADASCalibration')}</a>
					</p>
					{selectedDevice &&
						<AdasCalibrationSettings
							device={selectedDevice}
							onSave={saveAdasCalibrationSettingsModal}
						/>
					}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<CustomButton
					variant="primary-outline"
					onClick={onHide}
				>
				{t('btnBack')}
				</CustomButton>
				<CustomButton
					variant="secondary"
					disabled={selectedDevice && (!selectedDevice.adas_camera_height || !selectedDevice.adas_vehicle_width)}
					onClick={onSaveClick}
				>
					{t('btnSave')}
				</CustomButton>
			</Modal.Footer>
		</Modal>
	)
}

AdasCalibrationSettingsModal.propTypes = {
	device: PropTypes.objectOf(PropTypes.any).isRequired,
	saveAdasCalibrationSettings: PropTypes.func.isRequired,
	onHide: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => ({
	toggleLoader: (show) => {
		dispatch({ type: 'TOGGLE_LOADER', payload: show });
	},
})

export default connect(null, mapDispatchToProps)(AdasCalibrationSettingsModal);
