import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Toggle from 'react-toggle'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import CustomButton from '../../CustomButton'

const ModalNewMapOptions = (props) => {
	const { onHide, renderHomepage, setShowNewMapFeedbackModal, setRenderHomepage } = props;
	const { t } = useTranslation('translation', { keyPrefix: 'components.layout.modalNewMapOptions' });
	const [isToggled, setIsToggled] = useState(false)

	const rsNewMap = localStorage.getItem("rsNewMap")
	const isSaveDisabled = (isToggled && rsNewMap) || (!isToggled && !rsNewMap)

	// Set toggle state based on localStorage
	useEffect(() => {
		if (rsNewMap) {
			setIsToggled(true) // Enable toggle if rsNewMap exists
		}
	}, [])

	const onSaveClick = () => {
		const now = Date.now()

		if (isToggled && !rsNewMap) {
			// Enable toggle: Save time
			const updatedData = { start: now, feedback: false }
			localStorage.setItem("rsNewMap", JSON.stringify(updatedData))
			setRenderHomepage(!renderHomepage)
			// location.href = "/"
		}
		else if (!isToggled && rsNewMap) {
			// Disable toggle: Clear stored data
			localStorage.removeItem("rsNewMap")
			setShowNewMapFeedbackModal(true)
			setRenderHomepage(!renderHomepage)
			// location.href = "/"
		}

		onHide()
	}

	return (
		<Modal
			show
			onHide={onHide}
			className="modal-lg-size modal-new-map-options"
		>
			<Modal.Header closeButton>
				<Modal.Title>{t('titleNewMapOptions')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<h3>{t('tryNewMap')}</h3>
				<p>{t('text_1')}</p>
				<p dangerouslySetInnerHTML={{ __html: t('text_2') }}></p>
				<div className="toggle-wrapper">
					<label>{t('enableNewMap')}</label>
					<Toggle
						checked={isToggled}
						onChange={() => setIsToggled(!isToggled)}
					/>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<CustomButton variant="primary-outline" onClick={onHide}>
					{t('btnBack')}
				</CustomButton>
				<CustomButton variant="primary" disabled={isSaveDisabled} prefix={`${isSaveDisabled ? 'custom-disabled' : ''}`} onClick={onSaveClick}>
					{t('btnSave')}
				</CustomButton>
			</Modal.Footer>
		</Modal>
	)
}

ModalNewMapOptions.propTypes = {
	onHide: PropTypes.func.isRequired,
}

const mapStateToProps = ({ dashboardData }) => ({
	showNewMapFeedbackModal: dashboardData.showNewMapFeedbackModal,
	renderHomepage: dashboardData.renderHomepage,
});

const mapDispatchToProps = dispatch => ({
	setShowNewMapFeedbackModal: (state) => {
		dispatch({ type: 'SET_SHOW_NEW_MAP_FEEDBACK_MODAL', payload: state })
	},
	setRenderHomepage: (state) => {
		dispatch({ type: 'SET_RENDER_HOMEPAGE', payload: state })
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalNewMapOptions);
