import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import SearchBar from '../../component/SearchBar'
import ModalUnknownDriverTrips from '../../component/smart/modals/ModalUnknownDriverTrips'
import ModalSelectDriver from '../../component/smart/modals/ModalSelectDriver'
import AddDriver from './AddDriver'
import DriversTable from './DriversTable'

import { getCompanyGroups } from '../../action/GroupsActions'
import { apiCallGet } from '../../action/RouterActions'
import { getDevicesList } from '../../action/DeviceActions'
import { formatName } from '../../component/helpers/tablesFuncHelpers'
import CustomButton from '../../component/CustomButton'

const DriversPage = (props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'driversPage.driversPage' });
	const { user, company, toggleLoader } = props;
	const userRole = user.roles[0];

	const [location, setLocation] = useState(null)
	const [searchText, setSearchText] = useState('')
	const [devices, setDevices] = useState([])
	const [drivers, setDrivers] = useState([])
	const [initialData, setInitialData] = useState([])
	const [groups, setGroups] = useState([])
	const [unknowndriverShow, setUnknowndriverShow] = useState(false)
	const [selectdriverShow, setSelectdriverShow] = useState(false)
	const [selectedTripId, setSelectedTripId] = useState(null)
	const [refreshModalUnknownDriver, setRefreshModalUnknownDriver] = useState(false) // TODO: replace in future

	useEffect(() => {
		const { location } = props;

		let locationNew = null;
		if (location && location.state && location.state.type === 'redirectFromTripReplay') {
			locationNew = {
				type: location.state.type,
				event: location.state.event,
			}

			setLocation(locationNew)
			setUnknowndriverShow(true)
			browserHistory.replace({ pathname: '/managedrivers', state: {} });
		}

		if (company.company_id) {
			setSearchText('');
			getDriversGroups();
		}
	}, [company.company_id])

	const getDriversGroups = () => {
		toggleLoader(true)
		const requestArray = []
		requestArray.push(apiCallGet('/company/{companyId}/driver', { companyId: company.company_id }))
		requestArray.push(getDevicesList(company.company_id));
		if (['partner_view', 'sales_demo'].indexOf(userRole) === -1) {
			requestArray.push(getCompanyGroups(userRole, company, '?getids'))
		}

		Promise.all(requestArray)
			.then(res => {
				toggleLoader(false);

				const drivers = _.get(res[0], 'data.response.drivers', [])
				const devices = _.get(res[1], 'data.response.devices', [])
				const groups = _.get(res[2], 'data.response.groups', [])

				setGroups(groups)
				setDevices(devices)
				setDrivers(drivers)
				setInitialData([...drivers])
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('getDriversError: ', error);
			})
	}

	const dataSearch = (e) => {
		const value = e.target.value.toLowerCase()
		const filter = initialData.filter(item => (
			!!item.first_name && formatName(item.first_name, item).toLowerCase().includes(value)
			|| (item.card_id && item.card_id.toLowerCase().includes(value))
		))

		setDrivers(filter)
		setSearchText(value)
	}

	const openUnknownDriverTrips = () => {
		setUnknowndriverShow(true)
	}

	const closeUnknownDriverTrips = () => {
		setUnknowndriverShow(false)
	}

	const openViewTrip = (argTripUnknownDriverId) => {
		setSelectedTripId(argTripUnknownDriverId);
		setSelectdriverShow(true);
	}

	const openSelectDriver = (argTripUnknownDriverId) => {
		setSelectedTripId(argTripUnknownDriverId)
		setSelectdriverShow(true)
	}

	const closeSelectDriver = () => {
		setSelectdriverShow(false)
		setRefreshModalUnknownDriver(!refreshModalUnknownDriver)
	}

	const redirectToTripReplay = (unknownDriver) => {
		const { setDashboardRedirect } = props;
		setDashboardRedirect({
			type: 'redirectFromUnknownDrivers',
			...unknownDriver,
		})
		browserHistory.push({ pathname: '/' });
		// browserHistory.push({
		// 	pathname: '/',
		// 	state: {
		// 		unknownDriver,
		// 		type: 'redirectFromUnknownDrivers',
		// 	},
		// });
	}

	const setPropStates = (states) => {
		setInitialData(states.initialData)
		setDrivers(states.drivers)
	}

	return (
		<>
			<div className='page-subheader'>
				<div className='subheader-section search'>
					<SearchBar
						term={searchText}
						placeholder={t('placeholderSearchDriver/CardID')}
						onSearch={dataSearch}
					/>
				</div>
				{['partner_view', 'sales_demo'].indexOf(userRole) === -1 && (
					<div className="subheader-section">
						<CustomButton variant="primary-outline" onClick={openUnknownDriverTrips}>{t('btnUnknownDrivers')}</CustomButton>
					</div>
				)}
			</div>

			<main className="main-content-block">
				{['partner_view', 'sales_demo'].indexOf(userRole) === -1
					&& (
						<AddDriver
							drivers={drivers}
							initialData={initialData}
							groups={groups}
							setPropStates={setPropStates}
						/>
					)}

				<DriversTable
					drivers={drivers}
					initialData={initialData}
					groups={groups}
					setPropStates={setPropStates}
				/>
			</main>

			{unknowndriverShow
				&& (
					<ModalUnknownDriverTrips
						location={location}
						companyData={company}
						devices={devices}
						onHide={closeUnknownDriverTrips}
						redirectToTripReplay={redirectToTripReplay}
						onOpenSelectDriver={openSelectDriver}
						refreshModalUnknownDriver={refreshModalUnknownDriver}
						selectdriverShow={selectdriverShow}
					/>
				)}

			{selectdriverShow
				&& (
					<ModalSelectDriver
						drivers={initialData}
						selectedTripId={selectedTripId}
						onHide={closeSelectDriver}
					/>
				)}
		</>
	);
}

DriversPage.propTypes = {
	user: PropTypes.objectOf(PropTypes.any).isRequired,
	company: PropTypes.objectOf(PropTypes.any).isRequired,
	setDashboardRedirect: PropTypes.func.isRequired
};

const mapStateToProps = ({ user, company }) => ({
	user: user.user,
	company: company.company
});

const mapDispatchToProps = dispatch => ({
	setDashboardRedirect: (state) => {
		dispatch({ type: 'SET_DASHBOARD_REDIRECT', payload: state })
	},
	toggleLoader: (show) => {
		dispatch({ type: 'TOGGLE_LOADER', payload: show });
	}
})

export default connect(mapStateToProps, mapDispatchToProps)(DriversPage);
