import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SearchBar from '../../SearchBar'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import CustomButton from '../../CustomButton'

const VehicleSelectBox = (props) => {
	const { vehicles, selectedVehicleIds, readOnly, error, onToggle, configurationdv6 } = props;
	const { t } = useTranslation('translation', { keyPrefix: 'components.vehicleSelectBox' });
	const groupsObj = _.groupBy(vehicles, vehicle => vehicle.group_name)
	let initGroups = [];
	for (const [groupName, vehicles] of Object.entries(groupsObj)) {
		initGroups.push({ 'name': groupName === 'null' ? t('textNoGroup') : groupName, 'vehicles': vehicles, 'collapsed': false })
	}
	const [groups, setGroups] = useState(initGroups);
	const [searchTerm, setSearchTerm] = useState('');

	const filterGroups = (searchTermText = null) => {
		let groupsValue = groups;
		let searchTermValue = searchTerm;
		!!searchTermText ? searchTermValue = searchTermText : searchTermValue;
		return (
			groups.reduce((totalGroups, group) => {
				if (group.name.toLowerCase().includes(searchTerm.toLowerCase())) {
					totalGroups.push(group)
				}
				else {
					let vehicles = group.vehicles.filter(vehicle => {
						if (!!vehicle.activated_timestamp) {
							if (!vehicle.vehicle_name) {
								return vehicle.device_id.toString().toLowerCase().includes(searchTerm.toLowerCase())
							}
							return vehicle.vehicle_name.toLowerCase().includes(searchTerm.toLowerCase())
						}
					})
					if (vehicles.length) {
						totalGroups.push({ ...group, ...{ vehicles } })
					}
				}
				return totalGroups
			}, [])
		)
	}

	const filterVehicleIds = (searchTerm = null) => {
		return filterGroups(searchTerm).reduce((vehicleIds, { vehicles }) => {
			vehicles.forEach(vehicle => {
				if (!!vehicle.activated_timestamp && !isDeviceUncalibrated(vehicle)) {
					vehicleIds.push(vehicle.device_id)
				}
			})
			return vehicleIds
		}, [])
	}

	const toggleVehicle = (device_id) => {
		const newVehicles = selectedVehicleIds.includes(device_id) ? selectedVehicleIds.filter(vehicleId => vehicleId !== device_id) : [...selectedVehicleIds, device_id]
		onToggle(newVehicles)
	}

	const toggleGroup = (e, groupName) => {
		let newVehicles = [...selectedVehicleIds]
		const groupedDevices = filterGroups().find(group => group.name === groupName).vehicles.filter(item => !!item.activated_timestamp)
		// for (let { device_id } of groupedDevices) {
		for (let device of groupedDevices) {
			if (e.target.checked) {
				!isDeviceUncalibrated(device) && !newVehicles.includes(device.device_id) && newVehicles.push(device.device_id)
			}
			else {
				newVehicles.includes(device.device_id) && newVehicles.splice(newVehicles.indexOf(device.device_id), 1)
			}
		}
		onToggle(newVehicles)
	}

	const selectAllVehicles = (e) => {
		const filteredVehicles = filterVehicleIds();
		let newVehicles = [...selectedVehicleIds]
		if (e.target.checked) {
			newVehicles = [...new Set(selectedVehicleIds.concat(filteredVehicles))]
		}
		else {
			filteredVehicles.forEach(device_id => {
				(newVehicles.includes(device_id) && newVehicles.splice(newVehicles.indexOf(device_id), 1))
			})
		}
		onToggle(newVehicles)
	}

	const toggleCollapse = (name, collapsed) => {
		const newGroups = groups.map(group => {
			if (group.name === name) {
				group.collapsed = !collapsed
			}
			return group
		})
		setGroups(newGroups)
	}

	const toggleAllGroups = (expand) => {
		const newGroups = groups.map(group => {
			return { ...group, ...{ collapsed: !expand } }
		})
		setGroups(newGroups)
	}

	const onSearch = (e) => {
		const searchTerm = e.target.value;
		toggleAllGroups(true);
		setSearchTerm(searchTerm);
		let { selectedVehicleIds } = props;
		let filteredSearchIds = filterVehicleIds(searchTerm);
		selectedVehicleIds.map(id => { if (!filteredSearchIds.includes(id)) selectedVehicleIds = selectedVehicleIds.filter(item => item !== id) });
	}

	const isDeviceUncalibrated = (argDevice) => {
		return (
			configurationdv6 &&
			(
				configurationdv6.Event.Adas.LaneDeparture.Enabled ||
				configurationdv6.Event.Adas.ForwardCollision.Enabled ||
				configurationdv6.Event.Adas.Headway.Enabled ||
				configurationdv6.Event.Adas.FollowingDistance.Enabled
			) &&
			argDevice.device_type === 'dv6' &&
			(
				!argDevice.adas_camera_height ||
				!argDevice.adas_vehicle_width
			)
		)
	}

	const renderVehicleRow = (argDevice, argSelectedVehicleIds) => {
		// FWSD-5968
		const deviceUncalibrated = isDeviceUncalibrated(argDevice)
		return (
			<div className="asset-select-wrapper" key={argDevice.device_id}>
				<input
					type="checkbox"
					disabled={!argDevice.activated_timestamp || deviceUncalibrated}
					checked={argSelectedVehicleIds.includes(argDevice.device_id)}
					onChange={() => toggleVehicle(argDevice.device_id)}
				/>
				<span className="asset-name">{argDevice.vehicle_name || argDevice.device_id} {!!deviceUncalibrated && <span className="error-label">{t('labelNeedsCalibration')}</span>}</span>
			</div>
		)
	}

	let selectedVehicleIdsValue = selectedVehicleIds;
	let filteredGroups = filterGroups();
	let filteredSearchIds = filterVehicleIds(searchTerm);
	selectedVehicleIdsValue.map(id => {
		if (!filteredSearchIds.includes(id)) {
			selectedVehicleIdsValue = selectedVehicleIdsValue.filter(item => item !== id)
		}
	})
	const allVehiclesChecked = _.isEqual(filterVehicleIds().sort(), [...selectedVehicleIdsValue].sort()) && selectedVehicleIdsValue.length;
	const allGroupsCollapsed = groups.find(group => group.collapsed === true) ? true : false;

	const isGroupChecked = (index) => {
		let groupSelectedVehicles = [];
		filteredGroups[index].vehicles.map(vehicle => {
			if (!vehicle.activated_timestamp || isDeviceUncalibrated(vehicle)) {
				selectedVehicleIdsValue = selectedVehicleIdsValue.filter(item => item !== vehicle.device_id);
			}
			else {
				groupSelectedVehicles.push(vehicle.device_id);
			}
		})
		if (groupSelectedVehicles.length && groupSelectedVehicles.every(vehicle => selectedVehicleIdsValue.includes(vehicle))) {
			return true;
		}
		else {
			return false;
		}
	}

	// console.log('PROPS VehicleSelectBox: ', props)
	return (
		<div className={`select-box ${readOnly ? 'disabled' : ''}`}>
			<div className='select-box-wrapper'>
				<div className='select-box-header'>
					<div className='column-title'>{t('titleVehicles')}</div>
					<input
						type='checkbox'
						disabled={!filteredSearchIds.length}
						onChange={selectAllVehicles}
						checked={allVehiclesChecked}
						className='select-all-checkbox'
					/>
					<span className='select-all-label'>
						{t('selectAll') + selectedVehicleIds.length + t('textSelected')}
					</span>
				</div>
				<SearchBar term={searchTerm} onSearch={onSearch} placeholder={t('placeholderSearchGroup/Vehicle')} />
				<div className={`select-box-body ${(error ? 'error' : '')}`}>
					{groups.length > 0 && filteredGroups.map((group, index) =>
						<div className='group-list' key={group.name}>
							<div className='asset-select-wrapper'>
								<input
									type='checkbox'
									disabled={group.vehicles.every(vehicle => !vehicle.activated_timestamp)}
									checked={isGroupChecked(index)}
									onChange={(e) => toggleGroup(e, group.name)}
								/>
								<strong onClick={() => toggleCollapse(group.name, group.collapsed)} className="asset-name">
									{group.name}
								</strong>
								<svg className={`bi bi-chevron-down collapse-group ${group.collapsed ? '' : 'rotated'}`} onClick={() => toggleCollapse(group.name, group.collapsed)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
									<path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
								</svg>
							</div>
							{!group.collapsed &&
								<div className='vehicle-list'>
									{!group.collapsed && (
										<div className="vehicle-list">
											{group.vehicles.map(vehicle => renderVehicleRow(vehicle, selectedVehicleIds))}
										</div>
									)}
								</div>
							}
						</div>
					)}
				</div>
				<CustomButton
					variant='link'
					onClick={() => toggleAllGroups(allGroupsCollapsed)}
				>
					{(allGroupsCollapsed ? t('textExpand') : t('textCollapse')) + t('textAll')}
				</CustomButton>
				<div className='error-msg'>{error}</div>
			</div>
		</div>
	)
}

VehicleSelectBox.propTypes = {
	vehicles: PropTypes.array.isRequired,
	selectedVehicleIds: PropTypes.array.isRequired,
	onToggle: PropTypes.func.isRequired
}

export default VehicleSelectBox;
