import React, { useState, useEffect } from 'react'
import { Link, browserHistory } from 'react-router';
import moment from 'moment';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { apiCallGet } from '../../../action/RouterActions'

const PatternsDriverIdContainer = (props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'patternsPage' });
	const { toggleLoader } = props;

	const [error, setError] = useState('');

	useEffect(() => {
		getDriverTrip();
	}, []);

	const getDriverTrip = () => {
		toggleLoader(true);

		const { driver_trip_id } = props.params
		apiCallGet('/driver/trip/{driver_trip_id}', { driver_trip_id })
			.then((res, err) => {
				console.log('!getDriverTrip res', res)

				const { driver_trip } = res.data.response
				props.setSelectedObject(driver_trip)
				// if (driver_trip.company_id != props.company.company.company_id) {
				//     props.updateCompany({
				//         name:       driver_trip.company_name,
				//         partner:    driver_trip.partner_name,
				//         company_id: driver_trip.company_id,
				//         type:       driver_trip.company_type,
				//         driver_recognition: driver_trip.driver_recognition
				//     })
				// }

				// location.href='/fleet-admin/driver'
				// setTimeout(()=> {
				browserHistory.push({
					pathname: '/fleet-admin/driver',
					state: {
						type: 'driverid',
					},
				})
				// }, 1000)
			})
			.catch((error) => {
				console.log('!getDriverTrip error', error.response, error)

				let _error = JSON.stringify(error);
				if (error.response && error.response.data.response.error) {
					_error = error.response.data.response.error
				}
				setError(_error);
			})
	}

	return (
		<article>
			{error
				&& (
					<div>
						<h3>{t('error404')}</h3>
						<div>{error}</div>
						<div>
							{t('goBack')}
							<Link to="/" className="btn">{t('btnHome')}</Link>
						</div>
					</div>
				)}
		</article>
	)
}

export default connect(
	state => ({
		user: state.user,
		company: state.company,
	}),
	dispatch => ({
		updateCompany: (company) => {
			dispatch({ type: 'GET_COMPANY_SUCCESS', payload: company })
		},
		setSelectedObject: (state) => {
			dispatch({ type: 'SET_OBJECT_NOTIFICATIONS', payload: state })
		},
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(PatternsDriverIdContainer);
