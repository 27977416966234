import React, { useState, useEffect } from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { Pie, Bar } from 'react-chartjs-2'
import moment from 'moment'
import { isMobileOnly } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { transformationLabel } from '../../helpers/labels'

import '../../../../../../node_modules/react-grid-layout/css/styles.css'
import '../../../../../../node_modules/react-resizable/css/styles.css'
import './Reports.sass'

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const Charts = (props) => {
	// console.log('PROPS Charts: ', props);
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'reportsPage.charts' });

	const [charts, setCharts] = useState({
		user_settings: {
			charts: [],
			layout: []
		},
		layout: []
	});

	useEffect(() => {
		setCharts(props.state);
	}, []);

	useEffect(() => {
		setCharts(props.state);
	}, [props.state]);

	let current_state = charts;
	let clone_state = {};

	let a = JSON.stringify(current_state);
	clone_state = JSON.parse(a);

	let mainCharts, mainLayout;

	let allLayouts = {};
	let result = {};

	if (charts.user_settings.length && clone_state.layout.lg) {
		mainCharts = clone_state.user_settings.filter((item) => item.active === true);
		let activeChartValue = mainCharts.map((item) => {
			return item.i //id активных графиков
		});

		mainLayout = clone_state.layout.lg.filter((layout) => {
			return activeChartValue.indexOf(layout.i) > -1;
		});

		allLayouts = clone_state.layout;

		for (let i in allLayouts) {
			result = allLayouts[i].filter((layout) => {
				return activeChartValue.indexOf(layout.i) > -1;
			})
			allLayouts[i] = result;
		}
	}

	let chart = null;
	let oneLayout = null;
	let optionsBar, optionsPie, myChart, customChart, datasets;
	let allDatasets = [];
	let data = null;

	const colors = ["#CD6155", "#AF7AC5", "#2471A3", "#85C1E9", "#17A589", "#F7DC6F", "#DC7633", "#85929E", "#bada55", "#F29220", "#4365B0", "#ff7256"];
	const chart_padding = { padding: { left: 0, right: 0, top: 0, bottom: 40 } };

	if (charts.no_settings === true) {
		return (
			<h5 className='charts-message' dangerouslySetInnerHTML={{ __html: t('addNewReport') }}></h5>
		)
	}

	if (charts.content === 'no_devices') {
		return (
			<h5 className='charts-message'>{t('noDevices')}</h5>
		)
	}

	return (
		<div className="dashboard">
			{charts.dynamic_data && mainLayout !== null && mainCharts !== null && (
				<ResponsiveReactGridLayout
					isDraggable={(isMobileOnly) ? false : true}
					isResizable={(isMobileOnly) ? false : true}
					className="layout"
					cols={{ lg: 12, md: 12, sm: 6, xs: 6, xxs: 6 }}
					onLayoutChange={props.onLayoutChange}
					layouts={{ lg: allLayouts.lg, md: allLayouts.md, sm: allLayouts.sm, xs: allLayouts.xs, xxs: allLayouts.xxs }}
				>
					{
						mainCharts.map((charts, j) => {
							// debugger
							chart = mainCharts[j]; // 2
							oneLayout = mainLayout[j]; // 2

							if (oneLayout && chart && oneLayout.i === chart.i) {
								let numChart = chart.i;
								if (chart.data && chart.data.length > 0) {
									if (chart.type === 'bar_top_10' || chart.type === 'bar_bottom_10' || chart.type === 'bar_custom') {
										allDatasets = [];
										allDatasets = chart['all_events'].map((oneEvent, i) => {
											data = chart.data[i];
											datasets = {
												label: i18n.t(`constants.eventNames.${oneEvent.replace(/\s/g, "_")}`, oneEvent),
												backgroundColor: colors[i],
												borderColor: colors[i],
												data: data,
											};
											return datasets

										});

										myChart = {
											labels: chart.device_name,
											datasets: allDatasets,
											custom_data_usage: chart.data_usage // my custom data_usage
										};
										optionsBar = {
											title: {
												display: true,
												text: transformationLabel(chart.type) + t('textFrom') + moment(chart.start_date).format('MM/DD/YYYY') + t('textTo') + moment(chart.end_date).format('MM/DD/YYYY'),
												position: 'top',
											},
											scales: {
												xAxes: [{ stacked: chart.stacked }],
												yAxes: [{ stacked: chart.stacked }]
											},
											maintainAspectRatio: false,
											layout: chart_padding,
											tooltips: {
												callbacks: {
													title: function (tooltipItem, data) {
														let vehicle_name = data.labels[tooltipItem[0].index];
														let title = t('titleVehicle') + vehicle_name;
														return title;
													},
													afterFooter: function (tooltipItem, data) {
														let data_usage = data.custom_data_usage[tooltipItem[0].index];
														let label = t('labelDataUsage', { data_usage });
														return label;
													},
												},
												backgroundColor: '#FFF',
												titleFontSize: 14,
												titleFontColor: '#0066ff',
												bodyFontColor: '#000',
												bodyFontSize: 12,
												footerFontStyle: 'bold',
												footerFontColor: '#000',
												borderColor: '#3f3f3f',
												borderWidth: 1,
											},
										}

										customChart = <Bar data={myChart} options={optionsBar} />
									}
									else if (chart.type === 'pie_top_10' || chart.type === 'pie_bottom_10' || chart.type === 'pie_custom') {
										if (Array.isArray(chart.data) && chart.data.length > 0) {
											myChart = {
												datasets: [{
													label: 'Top 10',
													data: chart.data, // my custom data
													data_pie: chart.data_pie, // my custom data
													all_events: chart['all_events'], // my custom data
													data_usage: chart.data_usage, // my custom data
													backgroundColor: [
														"#AF7AC5",
														"#2471A3",
														"#85C1E9",
														"#17A589",
														"#F7DC6F",
														"#85929E",
														"#bada55",
														"#F29220",
														"#4365B0",
														"#ff7256",
													],
													borderWidth: 1,
												}],
												labels: chart.device_name
											};

											optionsPie = {
												title: {
													display: true,
													text: transformationLabel(chart.type) + t('textFrom') + moment(chart.start_date).format('MM/DD/YYYY') + t('textTo') + moment(chart.end_date).format('MM/DD/YYYY'),
													position: 'top'
												},
												maintainAspectRatio: false,
												layout: chart_padding,
												legend: {
													display: true,
													labels: {
														generateLabels: function (chart) {
															let data = chart.data;
															if (data.labels.length && data.datasets.length) {
																return data.labels.map(function (label, i) {
																	let ds = data.datasets[0];
																	let fillColor = ds.backgroundColor[i];

																	return {
																		text: t('titleVehicle') + label,
																		fillStyle: fillColor,
																		strokeStyle: fillColor,
																		lineWidth: 1,
																		hidden: Object.values(ds._meta)[0].data[i].hidden,
																		index: i,
																	};
																});
															}
															return [];
														}
													}
												},

												tooltips: {
													enabled: false,
													mode: 'point',
													intersect: false,
													custom: (tooltipModel) => {
														let tooltipEl = document.getElementById('chartjs-tooltip');
														const chartEl = document.getElementsByClassName('react-grid-item')[j];
														if (!tooltipEl) {
															tooltipEl = document.createElement('div');
															tooltipEl.id = 'chartjs-tooltip';
															tooltipEl.innerHTML = '<table></table>';
															chartEl.appendChild(tooltipEl);

														}
														else { chartEl.appendChild(tooltipEl) }

														if (tooltipModel.opacity === 0) {
															tooltipEl.style.opacity = 0;
															return;
														}

														tooltipEl.classList.remove('above', 'below', 'no-transform');
														if (tooltipModel.yAlign) {
															tooltipEl.classList.add(tooltipModel.yAlign);
														} else {
															tooltipEl.classList.add('no-transform');
														}

														function getBody(bodyItem) {
															return bodyItem.lines;
														}
														function getQuantity(item) {
															return item.after;
														}

														if (tooltipModel.body) {
															let titleLines = tooltipModel.title || [];
															let bodyEvents = tooltipModel.body.map(getBody);

															let bodyQuantity = tooltipModel.body.map(getQuantity);
															let styleBold = 'font-weight: bold;';
															let styleTitle = 'color: #0066ff; font-size: 14px; padding-bottom: 2px';


															let innerHtml = '<thead>';

															titleLines.forEach(function (title) {
																innerHtml += '<tr><th style="' + styleTitle + '">' + title + '</th></tr>';
															});
															innerHtml += '</thead><tbody>';
															bodyEvents[0].map((label) => {
																return innerHtml += '<tr><td >' + '- ' + label + '</td></tr>';
															})
															bodyQuantity[0].map((label) => {
																return innerHtml += '<tr><td>' + label + '</td></tr>';
															})

															if (tooltipModel.footer) {
																tooltipModel.footer.map((label) => {
																	return innerHtml += '<tr><td style="' + styleBold + '">' + label + '</td></tr>';
																})
															}

															innerHtml += '</tbody>';

															let tableRoot = tooltipEl.querySelector('table');
															tableRoot.innerHTML = innerHtml;
														}

														tooltipEl.style.opacity = 1;
														tooltipEl.style.position = 'absolute';
														tooltipEl.style.bottom = '5px';
														tooltipEl.style.fontSize = '12px';
														tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
														tooltipEl.style.backgroundColor = '#FFF';
														tooltipEl.style.border = '1px solid #a2a1a1';
														tooltipEl.style.borderRadius = '5px';

													},

													callbacks: {
														title: function (tooltipItem, data) {
															let vehicle_name = data.labels[tooltipItem[0].index];
															let title = t('titleVehicle') + vehicle_name;
															return title;
														},

														//events list
														label: function (tooltipItem, data) {
															let all_events = data.datasets[0].all_events;
															let all_events_data = data.datasets[0].data_pie[tooltipItem.index];
															let label = all_events_data.map((item, i) => {
																return i18n.t(`constants.eventNames.${all_events[i].replace(/\s/g, "_")}`, all_events[i]) + ': ' + all_events_data[i];
															})
															return label;
														},

														// Total quantity of events
														afterLabel: function (tooltipItem, data) {
															let total_quantity_events = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
															let label = t('totalQuantityEvents') + total_quantity_events;
															return label;

														},

														// Percentage of total events
														beforeFooter: function (tooltipItem, data) {
															let dataset = data.datasets[tooltipItem[0].datasetIndex];
															let total = dataset.data.reduce(function (previousValue, currentValue, currentIndex, array) {
																return previousValue + currentValue;
															});
															let currentValue = dataset.data[tooltipItem[0].index];
															let precentage = Math.floor(((currentValue / total) * 100) + 0.5);
															let label = t('percentageTotalEvents') + precentage + "%";
															return label;
														},

														// Data usage
														afterFooter: function (tooltipItem, data) {
															let data_usage = data.datasets[0].data_usage[tooltipItem[0].index];
															let label = t('labelDataUsage', { data_usage });
															return label;

														},

													},
												}
											}
										}

										customChart = <Pie data={myChart} options={optionsPie} />
									}
								}
								else if (chart.data && (chart.data.length < 1 || chart.data[0].length < 1)) {
									customChart = (
										<div className='text-center'>
											<h5>
												{`${transformationLabel(chart.type)} ${t('textFrom')} ${moment(chart.start_date).format('MM/DD/YYYY')} ${t('textTo')} ${moment(chart.end_date).format('MM/DD/YYYY')}`}
											</h5>
											<span className='error-label'>{t('labelNoAssetsEvents')}</span>
										</div>
									)
								}
							}

							if (oneLayout && oneLayout.i) {
								return (
									<div key={oneLayout.i} className="chart-canv" ref={oneLayout.i}>
										<h3 className={`${chart.error ? 'has-error' : ''}`}>
											{chart.name}
											<div>{chart.error && <div>{`${t('chartError')} ${chart.error}`}</div>}</div>
										</h3>

										{customChart}
									</div>
								)
							}
						})
					}
				</ResponsiveReactGridLayout>
			)}
		</div>
	)
}

export default Charts;
