import React, { useState } from 'react'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router';
import classnames from 'classnames';
import { Button } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { useTranslation } from 'react-i18next';

import { changeUserPassword } from '../../../action/ClientActions';
import GreyHeader from '../../GreyHeader';
import { formatName } from '../../helpers/tablesFuncHelpers';
import { passwordValidation } from '../../helpers/helperAccount';
import CustomButton from '../../CustomButton';

const ChangePasswordContainer = (props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'manageUsersPage.changePasswordContainer' });
    const [data, setData] = useState({
        new_password: '',
        new_password_confirm: ''
    });
    const [errMessage, setErrMessage] = useState({
        new_password: '',
        new_password_confirm: ''
    });
    const [toggle_password, setToggle_password] = useState(true);
    const [toggle_password_confirm, setToggle_password_confirm] = useState(true);
    const [upd, setUpd] = useState(false);

    const togglePassword1 = () => {
        setToggle_password(!toggle_password);
    }
    const togglePassword2 = () => {
        setToggle_password_confirm(!toggle_password_confirm);
    }

    const handleInputChange = (e) => {
        const { value, name } = e.target;

        let dataUpdate = data;
        dataUpdate[name] = value;
        setData(dataUpdate);
    }

    const checkForm = (data, e) => {
        let error = false;
        let errMessageUpdate = errMessage;

        for (let i in data) {
            if (data[i] == '') {
                error = true;
                errMessageUpdate[i] = t('enterPassword');
            }
            else if (errMessageUpdate[i]) {
                errMessageUpdate[i] = null;
            }
        }

        if (!errMessageUpdate.new_password) {
            const validation = passwordValidation(data.new_password)
            if (!validation.result) {
                error = true;
                errMessageUpdate.new_password = validation.error;
            }
            else if (data.new_password !== data.new_password_confirm) {
                error = true;
                errMessageUpdate.new_password = t('passwordsDoNotMatch');
            }
        }

        if (!errMessageUpdate.new_password_confirm) {
            if (data.new_password !== data.new_password_confirm) {
                error = true;
                errMessageUpdate.new_password_confirm = t('passwordsDoNotMatch');
            }
        }
        setErrMessage(errMessageUpdate)
        return error;
    }

    const sendForm = (e) => {
        e.preventDefault();
        let dataUpdate = {
            user_email: props.location.state.user.email,
            new_password: data.new_password,
            new_password_confirm: data.new_password_confirm
        };

        const error = checkForm(dataUpdate, e);
        setUpd(!upd);

        if (!error) {
            props.toggleLoader(true);
            changeUserPassword(dataUpdate)
                .then(res => {
                    props.toggleLoader(false);
                    browserHistory.push('/manageusers')
                    toastr.success('', t('changedPassword'));
                })
                .catch(error => {
                    props.toggleLoader(false);
                    console.log('!ChangePasswordContainer sendForm error', error);
                    toastr.error(t('issueChangingPassword'));
                });
        }
    }

    const { new_password, new_password_confirm } = errMessage;

    return (
        <div>
            <GreyHeader title={t('titleChangePassword')} subtitle={formatName(null, props.location.state.user)} />

            <main className='change-password main-content-block'>
                <h3 className="category-title text-center">{t('titleChangePassword')}</h3>
                <form onSubmit={sendForm}>
                    <section>
                        <div className={classnames('form-group fa-custom has-feedback', { 'has-error': new_password })}>
                            <label className="control-label">{t('newPassword')}</label>
                            <div style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                <input type={toggle_password ? "password" : "text"} name="new_password" className="vehicle-search" data-toggle="new_password" onChange={handleInputChange} />
                                <span className="fa fa-eye form-control-feedback" style={{ top: "25px" }} onClick={togglePassword1}></span>
                            </div>
                            {new_password ? <span className="help-block">{new_password}</span> : <span className="help-block"></span>}
                        </div>
                    </section>

                    <section className={classnames({ 'has-error': new_password_confirm })}>
                        <div className={'form-group fa-custom has-feedback'}>
                            <label className="control-label">{t('confirmNewPassword')}</label>
                            <div style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                <input type={toggle_password_confirm ? "password" : "text"} name="new_password_confirm" className="vehicle-search" onChange={handleInputChange} />
                                <span className="fa fa-eye form-control-feedback" style={{ top: "25px" }} name="new_password_confirm" onClick={togglePassword2}></span>
                            </div>
                            {new_password_confirm ? <span className="help-block">{new_password_confirm}</span> : <span className="help-block"></span>}
                        </div>
                    </section>

                    <section className='text-center'>
                        <CustomButton onClick={sendForm} variant="secondary" size='lg'>{t('btnChangePassword')}</CustomButton>
                    </section>

                </form>
            </main>
        </div>
    );
}

export default connect(
    null,
    dispatch => ({
        toggleLoader: (show) => {
            dispatch({ type: 'TOGGLE_LOADER', payload: show });
        }
    })
)(ChangePasswordContainer);
