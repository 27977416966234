import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { Dropdown } from 'react-bootstrap'
import Draggable from 'react-draggable'
import { Resizable } from 'react-resizable'
import { FaCheck } from 'react-icons/fa'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import SearchBar from '../../component/dump/menuComponents/SearchBar'

import momenttz from '../../component/helpers/momenttz'
import { tableExportOptions } from '../../component/helpers/table'
import { filterIconWithSize } from '../../component/helpers/iconHelpers'
import { timestampSortFunc } from '../../component/helpers/tablesFuncHelpers'
import CustomButton from '../../component/CustomButton'
import { filterIcon } from '../../component/helpers/iconHelpers'

import { apiCallGet } from '../../action/RouterActions'

import './DeviceArchiveHistory.sass'

const DeviceArchiveHistory = (props) => {
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'manageVehiclesPage.deviceArchiveHistory' });
	const { device, onHide, toggleLoader } = props;

	const [history, setHistory] = useState([]);
	const [initialData, setInitialData] = useState([]);
	const [windowHeight, setWindowHeight] = useState(560);
	const [windowWidth, setWindowWidth] = useState(1100);
	const [searchText, setSearchText] = useState('');
	const [filters, setFilters] = useState({
		camera: true,
		vehicle: true,
	});
	const [sortHistoryName, setSortHistoryName] = useState('replace_ts');
	const [sortHistoryOrder, setSortHistoryOrder] = useState('desc');

	useEffect(() => {
		getHistory();
	}, []);

	const onResize = (event, { element, size }) => {
		setWindowWidth(size.width);
		setWindowHeight(size.height);
	}

	const getHistory = () => {
		const { device: { device_id } } = props;
		toggleLoader(true);
		apiCallGet('/devices/{device_id}/replace_history', { device_id })
			.then((res) => {
				toggleLoader(false);
				setHistory(res.data.response);
				setInitialData(res.data.response);
			})
			.catch((error) => {
				console.log('!DeviceArchiveHistory error: ', error.response, error);
				toggleLoader(false);
				setHistory([]);
				setInitialData([]);

				let errDescription = i18n.t('errorMessage.errDescription');
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription);
			})
	}

	const historyFilterSearch = (argSearchText = null, argFilters = null) => {
		const lastSearchText = argSearchText !== null ? argSearchText : searchText
		const lastFilters = argFilters || filters

		const activeFilters = [];
		_.forOwn(lastFilters, (value, key) => {
			if (value) {
				activeFilters.push(key)
			}
		})

		let history = initialData.filter(item => activeFilters.includes(item.replace_type))
		if (lastSearchText) {
			history = history.filter(item => {
				return (
					(item.vehicle_name && item.vehicle_name.toLowerCase().includes(lastSearchText))
					|| (item.serial_number && item.serial_number.toLowerCase().includes(lastSearchText))
				)
			})
		}

		setHistory(history);
	}

	const fillSearchText = (e) => {
		const searchText = e ? e.target.value.toLowerCase() : searchText;
		setSearchText(searchText);
		historyFilterSearch(searchText)
	}

	const fillFilters = (name) => {
		const newFilters = { ...filters, [name]: !filters[name] }
		setFilters(newFilters)
		historyFilterSearch(null, newFilters)
	}

	const setHistorySort = (sortName, sortOrder) => {
		setSortHistoryName(sortName);
		setSortHistoryOrder(sortOrder)
	}

	const formatCSVFilename = () => {
		return `device_history_${device.device_id}.csv`;
	}

	const timestampFormatter = (cell, row) => {
		return momenttz(`${row.replace_ts}+00`).format('M/D/YYYY h:mm:ss A');
	}

	// console.log('PROPS DeviceArchiveHistory: ', props)
	const { vehicle_name, device_id } = device;

	return (
		<Draggable
			handle=".modal-header"
			bounds="body"
		>
			<div className="alert-search-container track-trace-alert" style={{ left: '210px', width: `${windowWidth}px` }}>
				<div className="modal-lg1">
					<Resizable
						height={windowHeight}
						width={windowWidth}
						onResize={onResize}
						axis="both"
						minConstraints={[100, 100]}
					>
						<div className="modal-content" role="document">
							<div className="modal-header" style={{ cursor: 'move' }}>
								<h4 id="contained-modal-title-lg" className="modal-title">
									{`${t('titleDeviceHistory')} ${vehicle_name || device_id}`}
								</h4>
								<CustomButton size="icon" prefix="close" onClick={onHide}>
									<span aria-hidden="true">×</span>
									<span className="sr-only">{t('btnClose')}</span>
								</CustomButton>
							</div>

							<div className="modal-body" style={{ height: `${windowHeight}px`, maxHeight: 'calc(100vh - 100px)' }}>
								<div className="form-group" style={{ display: 'flex', alignItems: 'center', paddingTop: '5px' }}>
									<div style={{ minWidth: '200px', maxWidth: '600px', width: '100%', height: '40px', marginRight: '10px' }}>
										<SearchBar term={searchText} onSearch={fillSearchText} placeholder={t('placeholderSearch')} />
									</div>

									<div className="filters-wrapper">
										<Dropdown
											id="type-filter-btn"
											pull-right="true"
										>
											<Dropdown.Toggle
												variant='outline-primary'
												bsPrefix="no-caret event-filter-toggle"
											>
												{filterIcon}
											</Dropdown.Toggle>
											<Dropdown.Menu
												className="typeFilterMenu"
											>
												<li onClick={() => fillFilters('camera')}>
													{t('labelCamera')}
													{filters.camera && <FaCheck className='filter-check' />}
												</li>
												<li onClick={() => fillFilters('vehicle')}>
													{t('labelVehicle')}
													{filters.vehicle && <FaCheck className='filter-check' />}
												</li>
											</Dropdown.Menu>
										</Dropdown>
									</div>
								</div>

								<div>
									<BootstrapTable
										data={history}
										{...tableExportOptions}
										options={{
											...tableExportOptions.options,
											sortName: sortHistoryName,
											sortOrder: sortHistoryOrder,
											onSortChange: setHistorySort,
										}}
										csvFileName={formatCSVFilename}
									>
										<TableHeaderColumn
											dataField="id"
											hidden
											isKey
										>
											{t('headerID')}
										</TableHeaderColumn>
										<TableHeaderColumn
											dataField="replace_ts"
											width="18%"
											csvHeader={t('headerDateReplaced')}
											dataFormat={timestampFormatter}
											csvFormat={timestampFormatter}
											dataSort
											sortFunc={timestampSortFunc('replace_ts')}
										>
											{t('headerDateReplaced')}
										</TableHeaderColumn>
										<TableHeaderColumn
											dataField="replace_type"
											width="10%"
											csvHeader={t('headerReplaceType')}
										>
											{t('headerReplaceType')}
										</TableHeaderColumn>
										<TableHeaderColumn
											dataField="vehicle_name"
											width="20%"
											csvHeader={t('headerVehicleName')}
										>
											{t('headerVehicleName')}
										</TableHeaderColumn>
										<TableHeaderColumn
											dataField="serial_number"
											width="20%"
											csvHeader={t('headerSerialNumber')}
										>
											{t('headerSerialNumber')}
										</TableHeaderColumn>
										<TableHeaderColumn
											dataField="camera_type"
											width="8%"
											csvHeader={t('headerCameraType')}
										>
											{t('headerCameraType')}
										</TableHeaderColumn>
										<TableHeaderColumn
											dataField="vin_number"
											width="20%"
											csvHeader={t('headerVIN')}
										>
											{t('headerVIN')}
										</TableHeaderColumn>
										<TableHeaderColumn
											dataField="tag_number"
											width="10%"
											csvHeader={t('headerLicensePlate')}
										>
											{t('headerLicensePlate')}
										</TableHeaderColumn>
									</BootstrapTable>
								</div>
							</div>
							<div className="modal-footer" />
						</div>
					</Resizable>
				</div>
			</div>
		</Draggable>
	)
}

DeviceArchiveHistory.propTypes = {
	device: PropTypes.objectOf(PropTypes.any).isRequired,
	onHide: PropTypes.func.isRequired,
}

export default connect(
	null,
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(DeviceArchiveHistory);
