import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { browserHistory } from 'react-router';
import classnames from 'classnames';
import { toastr } from 'react-redux-toastr';
import { useTranslation } from 'react-i18next';

import { restorePassword } from '../../../../action/AccountActions';
import CustomButton from '../../../CustomButton';
import { sendGA } from '../../../helpers/helperUsers'

const RestorePasswordForm = (props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'loginForms.restorePasswordForm' });
    const { toggleLoader } = props

    const [data, setData] = useState({
        email: ''
    })
    const [errMessage, setErrMessage] = useState({
        email: ''
    })
    const [modal, setModal] = useState({
        show: false,
        content: '',
        error: false,
    })

    const closeModal = () => {
        if (!modal.error) {
            browserHistory.push('/login');
        }
        else {
            let modaClose = modal;
            modaClose['error'] = false;
            modaClose['show'] = false;
            setModal(modaClose);
        }
    }

    const openModal = () => {
        setModal((prevState) => ({ ...prevState, show: true }))
    }

    const fillForm = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    }

    const checkForm = (data) => {
        let error = false;
        const updatedErrMessage = { ...errMessage };

        for (let i in data) {
            if (data[i] == '') {
                error = true;
                updatedErrMessage[i] = t('errEnterYour') + i + '!';
            }
            else if (updatedErrMessage[i]) {
                updatedErrMessage[i] = null;
            }
        }

        if (!updatedErrMessage['email']) {
            var re = /^([a-zA-Z0-9_\-\.\+]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(data['email'])) {
                error = true;
                updatedErrMessage['email'] = t('errEmail');
            }
        }

        setErrMessage(updatedErrMessage);
        return error;
    }

    const sendForm = (e) => {
        e.preventDefault();

        const error = checkForm(data);
        if (!error) {
            sendGA({ category: 'Password Recovery', action: 'Password Recovery', label: 'Submit' })
            const content = t('contentModal');
            toggleLoader(true);
            restorePassword(data)
                .then((res, err) => {
                    toggleLoader(false)
                    setModal((prevState) => ({ ...prevState, content }))
                    openModal()
                    // toastr.info('Check your email to reset password.')
                })
                .catch((error, p) => {
                    toggleLoader(false);

                    if (error.response.data.response.error && (error.response.data.response.error !== "Specified user does not exist")) {
                        let modalUpdate = modal;
                        modalUpdate['error'] = true;
                        modalUpdate['content'] = error.response.data.response.error;
                        setModal(modalUpdate);

                        openModal()
                        toastr.error(error.response.data.response.error);
                    } else {
                        setModal((prevState) => ({ ...prevState, content }))
                        openModal()
                    }
                });
        }
    }

    const onCancelClick = () => {
        sendGA({ category: 'Password Recovery', action: 'Password Recovery', label: 'Back' })
        browserHistory.push('/')
    }

    const { email } = errMessage;

    return (
        <div className="panel-body">
            <h3 className='login-header'>{t('headerPasswordRecovery')}</h3>
            <div className="form-wrapper">

                <form onSubmit={sendForm}>
                    <div className={classnames('login-form form-group', { 'has-error': email })}>
                        <label className={`login-label ${(data.email.length) ? 'active' : ''}`}>{t('labelE-mail')}</label>
                        <input type="text" name="email" className="vehicle-search" onChange={fillForm} placeholder={t('placeholderE-Mail')} />
                        {email && <span className="error-label">{email}</span>}
                    </div>

                    <div className="change-password-submit form-group submit-btn">
                        <CustomButton type="submit" variant="primary" size="lg" id="restore-button">{t('btnSubmit')}</CustomButton>
                        <CustomButton variant="primary-outline" size="lg" onClick={onCancelClick}>{t('btnBack')}</CustomButton>
                    </div>
                </form>

            </div>
            <Modal size="small" show={modal.show} onHide={closeModal} dialogClassName={classnames('modal-custom', { 'has-error': modal.error })}>
                <Modal.Header>
                    <Modal.Title>
                        <div className={classnames({ 'has-error': modal.error })}>
                            {modal.error ? t('textAlarm') : t('textSuccess')}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modal.content}
                </Modal.Body>
                <Modal.Footer>
                    <Button className='rosco-primary' onClick={closeModal}>{t('btnClose')}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default connect(
    null,
    dispatch => ({
        toggleLoader: (show) => {
            dispatch({ type: 'TOGGLE_LOADER', payload: show });
        }
    })
)(RestorePasswordForm);
