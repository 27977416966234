// FWSD-3788
import React from 'react'
import { useTranslation } from 'react-i18next'

const ConfirmationPage = () => {
	const { t } = useTranslation('translation', { keyPrefix: 'components.сonfirmationPage' });

	return (
		<div className='account-form-heading'>
			{t('heading_1')}
			<br />
			{t('heading_2')}
			<div className='account-form-text'>
				{t('textClosePage')}
			</div>
		</div>
	)
}

export default ConfirmationPage;
