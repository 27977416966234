import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { Button } from 'react-bootstrap';
import classnames from 'classnames';
import { toastr } from 'react-redux-toastr';
import Select from 'react-select';
import { constants } from '../../helpers/constants';
import { errorDetection } from '../../helpers/helperErrors';
import { useTranslation } from 'react-i18next';

import { createFleetUserWithGroup, createFleetUser } from '../../../action/ClientActions';
import { getCompanyGroups, addUserToGroup } from '../../../action/GroupsActions';
import { getCompaniesList } from '../../../action/CompaniesActions';
import { getRandomPassword } from '../../helpers/helperAccount';

import './menuComponents.sass'
import _ from 'lodash';
import CustomButton from '../../CustomButton';

const ManageUsersCreateUser = (props) => {
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'manageUsersPage.manageUsersCreateUser' });
	const { company, toggleLoader } = props;
	const userRole = props.user_role;

	const [data, setData] = useState({
		email: '',
		first_name: '',
		last_name: '',
		role: '',
		password: '',
		group: '',
		company_name: '',
		partner_name: '',
		user_id: null,
	})
	const [errMessage, setErrMessage] = useState({
		email: '',
		first_name: '',
		last_name: '',
		company_name: '',
		partner_name: '',
		group: '',
	})
	const [userSaved, setUserSaved] = useState(false)
	const [groups, setGroups] = useState([])
	const [partner_companies, setPartner_companies] = useState([])
	const [open, setOpen] = useState(false)

	useEffect(() => {
		if (company.company_id) {
			const updatedData = { ...data };
			updatedData.partner_name = company.partner;

			setData(updatedData);

			getGroups(company.partner, company.name);
			switch (userRole) {
				case 'system_admin':
				case 'customer_service':
				case 'partner':
				case 'sales_demo':
					getPartners();
					break;
			}
		}
	}, [company.company_id])

	useEffect(() => {
		if (company.partner == 'Rosco Vision WCS' || company.type == 'WCS') {
			const availableRolesWCS = constants.availableCreateRolesWCS;
			let availableRoles = constants.availableCreateRolesPerUser;

			if (company.partner == 'Rosco Vision WCS' || company.type == 'WCS') {
				availableRoles = availableRolesWCS;
			}

			data.role = availableRoles[userRole][0];
		}
	}, [company.partner])

	const getPartners = () => {
		const company_type = 'PARTNER';
		getCompaniesList(userRole, company_type)
			.then((res, err) => {
				const companies = _.sortBy(res.data.response['companies list'], (num) => num.toLowerCase());

				setPartner_companies(companies);
			})
			.catch((error) => {
				setPartner_companies([]);
			});
	}

	const cancelUser = () => {
		const availableRolesWCS = constants.availableCreateRolesWCS;
		let availableRoles = constants.availableCreateRolesPerUser;

		if (company.partner === 'Rosco Vision WCS' || company.type === 'WCS') {
			availableRoles = availableRolesWCS;
		}

		const data = {
			company_name: '',
			partner_name: company.partner,
			email: '',
			first_name: '',
			last_name: '',
			role: availableRoles[userRole][0],
			password: '',
			group: '',
			user_id: null
		};

		setData(data)
		setUserSaved(false)
		setErrMessage({
			email: '',
			first_name: '',
			last_name: '',
			company_name: '',
			partner_name: '',
			group: '',
		})
	}

	const editUser = () => {
		browserHistory.push({ pathname: "/manageusers/edit", state: { email: data.email, userId: data.user_id } });
	}

	const clearSelectedGroupsState = () => {
		if (data && data.group[0]) {
			setData({ ...data, group: '' });
		}
	}

	const getGroups = (partner_name, company_name) => {
		clearSelectedGroupsState();
		const company = {
			name: company_name,
			partner: partner_name
		}

		getCompanyGroups(userRole, company)
			.then(res => {
				setGroups(res.data.response.groups);
			})
			.catch(error => {
				setGroups([]);
			});
	}

	const handleInputChange = (event) => {
		const target = event.target
		const name = target.name
		setData({ ...data, [name]: target.value, group: '' })
		name === 'company_name' ? selectDefaultPartnerCompany() : ''
	}

	const selectDefaultPartnerCompany = () => {
		setData({ ...data, partner_name: 'Rosco Vision' });
	}

	const checkForm = (data, e) => {
		let error = false;
		let updatedErrMessage = { ...errMessage }

		for (let i in data) {

			if (data[i] == '' && e.target[i] && e.target[i].type == 'text') {
				error = true;
				if (i === 'email') updatedErrMessage[i] = t('textEnterYourEmail');
				if (i === 'first_name') updatedErrMessage[i] = t('textEnterYourFirstName');
				if (i === 'last_name') updatedErrMessage[i] = t('textEnterYourLastName');
			}
			else if (data[i] == '' && e.target[i] && e.target[i].type !== 'text' && i === "partner_name") {
				error = true;
				updatedErrMessage[i] = t('selectPartnerCompany');
			}
			else if (updatedErrMessage[i]) {
				updatedErrMessage[i] = null;
			}
		}

		if (!updatedErrMessage['email']) {
			let re = /^([a-zA-Z0-9_\-\.\+]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			if (!re.test(data['email'])) {
				error = true;
				updatedErrMessage['email'] = t('emailNotValid');
			}
		}

		if (data['role'] == 'group_manager' && (!data['group'] || !data['group'].length)) {
			error = true;
			updatedErrMessage['group'] = t('selectGroup(s)');
		}

		setErrMessage(updatedErrMessage);
		return error;
	}

	const sendForm = (e) => {
		e.preventDefault();
		const newUser = { ...data };
		if (data.role && ['partner', 'partner_view', 'custom_partner'].includes(data.role)) {
			newUser.company_name = data.partner_name;
			newUser.partner_name = data.partner_name;
		}
		else if (data.role) {
			newUser.company_name = company.name;
		}

		newUser.password = getRandomPassword();
		setData(prevState => ({ ...prevState, ...newUser }));

		const error = checkForm(newUser, e);
		if (!error) {
			toggleLoader(true);
			createFleetUser(newUser)
				.then(resUser => {
					!newUser.group?.length && toggleLoader(false);

					newUser.user_id = resUser.user_id
					setUserSaved(true);
					setData(newUser);
					props.changedUsersList();

					toastr.success(t('createdNewUser'));

					//* Adds groups for created user
					if (newUser.group?.length > 0) {
						const groupParams = {
							user_role: userRole,
							partner_name: newUser.partner_name,
							company_name: newUser.company_name,
							group_name: '',
						}
						newUser.group.forEach((item) => {
							groupParams.group_name = item.value
							// requestArray.push(addUserToGroup(groupParams, newUser.email))
							addUserToGroup(groupParams, newUser.email)
								.then(
									resGroup => {
										toggleLoader(false)
										// toastr.success('Successfully added groups')
									},
									errGroup => {
										toggleLoader(false)

										let errDescription = i18n.t('errorMessage.errDescription');
										if (errGroup.response.data.response.error) {
											errDescription = '"' + item.value + '"' + errGroup.response.data.response.error
										}
										toastr.error(errDescription)
									},
								)
						})
					}
					// Promise.all(requestArray)
					// 	.then(
					// 		resGroups => {
					// 			toggleLoader(false)
					// 			toastr.success('Successfully added groups')
					// 		},
					// 		errGroups => {
					// 			toggleLoader(false)

					// 			let errDescription = i18n.t('errorMessage.errDescription');
					// 			if (errGroups.response.data.response.error) {
					// 				errDescription = errGroups.response.data.response.error
					// 			}
					// 			toastr.error(errDescription)
					// 		},
					// 	)
					// 	// .catch((errorPromise, err5, err6) => {
					// 	// 	toggleLoader(false);
					// 	// 	console.log('errorPromise error: ', errorPromise);

					// 	// 	let errDescription = i18n.t('errorMessage.errDescription');
					// 	// 	if (error.response.data.response.error) {
					// 	// 		errDescription = error.response.data.response.error
					// 	// 	}
					// 	// 	toastr.error(errDescription)
					// 	// });
				})
				.catch(errUser => {
					toggleLoader(false);
					console.log('addOneGroup error: ', errUser)

					let errDescription = t('unableAddGroup')
					if (errUser.response.data.body) {
						errDescription = errorDetection(errUser.response.data.body) || errUser.response.data.body
					}
					else if (errUser.response.data.response) {
						errDescription = errorDetection(errUser.response.data.response.error) || errUser.response.error
					}
					if (errUser.response.data.response.error === 'user exist') {
						errDescription = t('userAlreadyExists')
					}
					toastr.error(errDescription)
				});

			// createFleetUserWithGroup(newUser, userRole, (err, res) => {
			// 	if (err) {
			// 		toggleLoader(false);
			// 		if (_.get(err, 'response.data.response.error') === 'user exist') {
			// 			toastr.error('This user already exists. Please use a different email address.')
			// 		}
			// 		else if (!!_.get(err, 'response.data.body')) {
			// 			toastr.error(err.response.data.body)
			// 		}
			// 		else {
			// 			toastr.error('There was an issue creating this user. Please try again later.')
			// 		}
			// 	}
			// 	else {
			// 		toggleLoader(false);

			// 		newUser.user_id = res.user_id
			// 		setUserSaved(true);
			// 		setData(newUser);
			// 		props.changedUsersList();

			// 		toastr.success('Successfully created new user');
			// 	}
			// })
		}
	}

	const toggleAddBlock = () => {
		setOpen(!open);
		cancelUser();
	}

	const rolesOptions = () => {
		const availableRolesWCS = constants.availableCreateRolesWCS,
			rolesNames = constants.roleNames;
		let availableRoles = constants.availableCreateRolesPerUser;

		let options = [],
			i = 0;

		if (company.partner === 'Rosco Vision WCS' || company.type === 'WCS') {
			availableRoles = availableRolesWCS
		}

		if (availableRoles.hasOwnProperty(userRole)) {
			availableRoles[userRole].forEach(function (key) {
				if (rolesNames.hasOwnProperty(key)) {
					options.push(<option value={key} key={i++}>{rolesNames[key]}</option>)
				}
			});
		}

		if (userRole === 'system_admin'
			&& (company.partner === 'Rosco Vision Partner' || company.partner === 'Rosco Vision Testing')
			&& company.name === 'Rosco Vision'
		) {
			options.push(<option value='system_admin' key={i++}>{t('optionSystemAdmin')}</option>)
			options.push(<option value='customer_service' key={i++}>{t('optionCustomerSupport')}</option>)
		}

		return options
	}

	const { email, first_name, last_name, partner_name, group } = errMessage;

	const partnerList = () => {
		let option = [];
		if (partner_companies) {
			partner_companies.map((elem, index) => {
				option[index] = (<option key={index} value={elem}>{elem}</option>)
			});
			return (
				<select
					placeholder="select"
					className="rosco-select block"
					name="partner_name"
					id="partner_name"
					defaultValue={data.partner_name || company.partner}
					onChange={handleInputChange}
				>
					{option}
				</select>
			);
		}
	};

	const _company_name = company.name;
	const _partner_name = data.partner_name || t('textSelectPartnerCompany');
	const isUserSaved = userSaved

	if (isUserSaved) {
		return (
			<div className='add-asset-block'>
				<CustomButton variant='secondary-outline' onClick={cancelUser}>
					{t('btnAddAnotherUser')}
				</CustomButton>
				<div className='label-wrapper'>
					<label>{t('labelWrapperNewUser_1')} <b>{data.first_name} {data.last_name}</b>{t('labelWrapperNewUser_2')}</label>
				</div>

				{data.role !== "notification_recipient" &&
					<p>
						{t('textBlockNewUser_1')} <b>{data.password}</b> <br />
						{t('textBlockNewUser_2')}
					</p>
				}

				<div>
					<CustomButton onClick={editUser} variant="primary">{t('editSettings', { first_name: data.first_name })}</CustomButton>
				</div>
			</div>
		)
	}

	let options = [];
	groups.map(group => {
		if (group !== "") {
			options.push({ value: group, label: group });
		}
	}
	);

	return (
		<div className='add-asset-block'>
			<CustomButton variant='secondary-outline' onClick={toggleAddBlock}>
				{open ? t('btnCancel') : t('btnAddNewUser')}
			</CustomButton>
			{open && (
				<form onSubmit={sendForm}>
					<div className='label-wrapper'>
						<label className="control-label">
							{['partner', 'partner_view', 'custom_partner'].includes(data.role)
								? <span>{t('wrapperUserPartner')} <b>{_partner_name}</b></span>
								: <span>{t('wrapperUserCompany')} <b>{_company_name}</b></span>
							}
						</label>
					</div>

					<div className='three-column-grid'>
						<div className={classnames({ 'has-error': email })}>
							<label className="control-label">{t('labelEmailAddress')}</label>
							<input type="text" name="email" className="vehicle-search" onChange={handleInputChange} />
							{email && <span className="help-block">{email}</span>}
						</div>

						<div className={classnames({ 'has-error': first_name })}>
							<label className="control-label">{t('labelFirstName')}</label>
							<input type="text" name="first_name" className="vehicle-search" onChange={handleInputChange} />
							{first_name && <span className="help-block">{first_name}</span>}
						</div>

						<div className={classnames({ 'has-error': last_name })}>
							<label className="control-label">{t('labelLastName')}</label>
							<input type="text" name="last_name" className="vehicle-search" onChange={handleInputChange} />
							{last_name && <span className="help-block">{last_name}</span>}
						</div>

						<div>
							<label htmlFor="user-role" className="control-label">
								{t('labelRole')}
							</label>

							<select
								placeholder="select"
								className="rosco-select block"
								name="role"
								id="user-role"
								value={data.role}
								onChange={handleInputChange}
							>
								{rolesOptions()}
							</select>
						</div>

						{['user', 'group_manager', 'storage_user'].includes(data.role) &&
							<div className={classnames({ 'has-error': group })}>
								<label htmlFor="user-groups" className="control-label">
									{t('labelGroup')}
								</label>
								<Select
									isMulti
									name="group"
									options={options}
									id="user-groups"
									onChange={value => setData({ ...data, group: value })}
									placeholder={t('placeholderSelectGroups')}
									className="rosco-select"
									value={data.group}
								/>
								{group && <span className="help-block">{group}</span>}
							</div>
						}

						{
							['partner', 'partner_view'].includes(data.role)
							|| (data.role === 'custom_partner' && ['system_admin', 'customer_service'].includes(userRole))
							&&
							<div className={classnames(' ', { 'has-error': partner_name })}>
								<label htmlFor="partner_name" className="control-label">
									{t('labelPartner')}
								</label>
								{partnerList()}
								{partner_name && <span className="help-block">{partner_name}</span>}
							</div>
						}
					</div>

					<CustomButton type="submit" variant="primary">{t('btnAddUser')}</CustomButton>
				</form>
			)}
		</div>
	);
}

export default connect(
	state => ({
		user: state.user.user,
		company: state.company.company
	}),
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(ManageUsersCreateUser);
