import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'
import { connect } from 'react-redux'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import GreyHeader from '../../dump/menuComponents/GreyHeader'
import IconTooltip from '../../dump/menuComponents/IconTooltip'
import { getCompanyById, updateCompany } from '../../../action/CompaniesActions'
import Toggle from 'react-toggle'
import FleetDriverIdModal from './FleetDriverIdModal'
import CustomButton from '../../CustomButton'

const AccountFleetSettings = (props) => {
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'accountPage.accountFleetSettings' });
	const { toggleLoader, user, company } = props;
	const userRole = user.roles[0];
	const [companyData, setCompanyData] = useState({});
	const [data, setData] = useState({
		event_expiration: '365',
	});
	const [showFleetDriverIDModal, setShowFleetDriverIDModal] = useState(false);
	const [upd, setUpd] = useState(false);

	useEffect(() => {
		getCompany();
	}, []);

	useEffect(() => {
		getCompany();
	}, [company.company_id]);

	const getCompany = () => {
		toggleLoader(true);
		if (company && company.company_id) {
			getCompanyById(user.roles[0], company.company_id)
				.then((res) => {
					console.log('!getCompany res: ', res)
					toggleLoader(false);
					const companyData = res.data.response.company;
					setCompanyData(companyData);
					setData({ event_expiration: companyData.event_expiration || '365' });
				})
				.catch((error) => {
					toggleLoader(false);
					console.log('!getCompany error: ', error.response, error)

					let errDescription = i18n.t('errorMessage.errDescription');
					if (error.response.data.response.error) {
						errDescription = error.response.data.response.error
					}
					toastr.error(errDescription)
				})
		}
	};

	const fillForm = (e) => {
		const { target } = e
		const value = target.type === 'checkbox' ? target.checked : target.value
		const { name } = target

		let UpdateData = data
		UpdateData[name] = value
		setData(UpdateData);
		setUpd(!upd);
	};

	const checkForm = (data) => {
		let error = false;
		let errMessage = ''

		for (let i in data) {
			if (data[i] === null) {
				error = true;
				errMessage = t('errEnterYour') + i + '!'
			} else if (errMessage[i]) {
				errMessage = null
			}
		}

		if (errMessage) {
			toastr.error(errMessage)
		}
		return error;
	};

	const sendForm = (e) => {
		e.preventDefault();

		const changedData = _.reduce(_.keys(data), (result, key) => {
			if (!Object.prototype.hasOwnProperty.call(companyData, key) || !_.isEqual(companyData[key], data[key])) {
				result[key] = data[key]
			}
			return result;
		}, {});
		if (_.isEmpty(changedData)) {
			toastr.warning('', t('fleetSettingsChanges'))
			return
		}

		const params = {
			company: companyData,
			data: changedData,
		}

		const error = checkForm(changedData)
		if (!error) {
			toggleLoader(true);
			updateCompany(params, userRole)
				.then((res) => {
					console.log('!AccountFleetSettings updateCompany res: ', res)
					toggleLoader(false);

					setCompanyData(res.data.response.company);
					toastr.success('', t('fleetSettingsChanged'));
				})
				.catch((error) => {
					toggleLoader(false);
					console.log('!AccountFleetSettings updateCompany error: ', error.response, error)

					let errDescription = i18n.t('errorMessage.errDescription');
					if (error.response.data.response.error) {
						errDescription = error.response.data.response.error
					}
					toastr.error(errDescription)
				})
		}
	};

	const showFleetDriverModal = () => {
		setShowFleetDriverIDModal(true);
	};

	const driverIdEnabled = props.company.driver_recognition === "cardreader";

	return (
		<div className="account-settings">
			<GreyHeader title={t('titleFleetSettings')} />

			<div className="page-subheader">
				<CustomButton
					disabled={data.event_expiration === companyData.event_expiration}
					variant="secondary"
					onClick={sendForm}
				>
				{t('btnSaveChanges')}
				</CustomButton>
			</div>

			<main className="main-content-block">
				<div className="two-column-grid">
					<div className="user-timezone">
						<IconTooltip
							description={t('descriptionVideoExpire')}
						/>
						<label className="user-settings-label">{t('labelExpiration')}</label>
						<select
							placeholder={t('placeholderSelect')}
							className="rosco-select timezone-select"
							name="event_expiration"
							value={data.event_expiration}
							onChange={fillForm}
						>
							<option value="14">2 {t('textWeeks')}</option>
							<option value="30">1 {t('textMonth')}</option>
							<option value="90">3 {t('textMonths')}</option>
							<option value="180">6 {t('textMonths')}</option>
							<option value="365">12 {t('textMonths')}</option>
						</select>
					</div>

					<div className="toggle-wrapper">
						<IconTooltip
							description={t('descriptionDriverID')}
						/>
						<label className="user-settings-label">{t('labelDriverID')}</label>
						<Toggle
							checked={driverIdEnabled}
							onChange={showFleetDriverModal}
						/>
					</div>
				</div>
			</main>
			{showFleetDriverIDModal &&
				<FleetDriverIdModal
					driverIdEnabled={driverIdEnabled}
					onHide={() => setShowFleetDriverIDModal(false)}
				/>
			}
		</div>
	)
}

AccountFleetSettings.propTypes = {
	user: PropTypes.objectOf(PropTypes.any).isRequired,
	company: PropTypes.objectOf(PropTypes.any).isRequired
};

export default connect(({ user, company }) => ({
	user: user.user,
	company: company.company
}),
	dispatch => ({
		updateUser: (user) => {
			dispatch({ type: 'GET_USER_SUCCESS', payload: user });
		},
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	}))(AccountFleetSettings);
