import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import Autocomplete from 'react-autocomplete';
import { toastr } from 'react-redux-toastr';
import { useTranslation } from 'react-i18next';

import { deviceAddGeotabSerialN } from '../../../action/GeotabActions'
import { getWebfleetVehicles, setWebfleetCard, getIturanVehicles } from '../../../action/GeotabActions'
import { apiCall, apiCallGet } from '../../../action/RouterActions'
import { geotabAuthentication } from '../../helpers/helperGeotab'
import CustomButton from '../../CustomButton'

const ituran_video_options_default = { "ldw_l": "snapshot_only", "ldw_r": "snapshot_only", "hmw": "snapshot_only", "ufcw": "video_snapshot", "fcw": "video_snapshot", "pdz": "snapshot_only", "pcw": "snapshot_only", "tsr": "snapshot_only" }

const ModalProvisionDevice = (props) => {
    // console.log('PROPS ModalProvisionDevice: ', props)
    const { t, i18n } = useTranslation('translation', { keyPrefix: 'manageVehiclesPage.editAssetContainer.modalProvisionDevice' });
    const { device, companyData, externalCredentials, onHide, toggleLoader } = props

    const [state, setState] = useState({
        externalDevices: [],
        autoValue: '',
        data: {
            serial_number: '',
            license_plate: '',
            additional: '',
        },
    });

    // console.log('STATE ModalProvisionDevice: ', state)
    const provisionType = device.geotab.serial_number || companyData.geotab_database ? 'geotab' : device.external_srv.type || companyData.external_srv_type

    useEffect(() => {
        switch (provisionType) {
            case 'geotab':
                setState(prevState => ({
                    ...prevState,
                    data: { serial_number: device?.geotab?.serial_number || '' }
                }))
                getGeotabDevices();
                break
            case 'webfleet':
                setState(prevState => ({
                    ...prevState,
                    data: { serial_number: device?.external_srv?.serial_number || '' }
                }))
                getWebfleetDevices();
                break
            case 'ituran':
                const newData = {
                    serial_number: device?.external_srv?.serial_number || '',
                    license_plate: device?.external_srv?.license_plate || '',
                    // additional: device?.external_srv?.additional ? JSON.parse((device.external_srv.additional.replace(/'/g, '"'))) : ituran_video_options_default
                    additional: device?.external_srv?.additional ? JSON.parse(device.external_srv.additional) : ituran_video_options_default
                }
                setState(prevState => ({
                    ...prevState,
                    data: newData
                }))
                getIturanDevices();
                break
        }
    }, [])

    const getGeotabDevices = useCallback(async () => {
        toggleLoader(true);
        // FWSD-4475
        let databaseCredentials = {
            company_id: device.company_id,
            device_id: device.device_id,
            serial_number: (device.geotab && device.geotab.serial_number) ? device.geotab.serial_number : '',
            password: externalCredentials.password,
            path: externalCredentials.path,
            fleet_token: externalCredentials.token,
            database: externalCredentials.database,
            email: externalCredentials.email,
            sessionId: externalCredentials.session_id,
        }

        const resultAuth = await geotabAuthentication(databaseCredentials)
        if (resultAuth.error) {
            toggleLoader(false);
            toastr.error(i18n.t('errorMessage.errDescription'));
            return;
        }

        const { geotabApi, geotabData } = resultAuth;
        databaseCredentials = { ...databaseCredentials, ...geotabData }

        // gets list of geotab devices
        geotabApi.call(
            'Get',
            {
                typeName: 'Device',
                "search": {
                    "fromDate": new Date().toISOString(),
                    "excludeUntrackedAssets": true,
                },
                "sort": {
                    sortBy: "name",
                    sortDirection: "asc"
                },
                "propertySelector": {
                    "fields": [
                        "id",
                        "name",
                        "serialNumber",
                        "groups",
                        "licensePlate"
                    ]
                },
                credentials: {
                    database: databaseCredentials.database,
                    userName: databaseCredentials.email,
                    sessionId: databaseCredentials.sessionId,
                },
            },
            resVeh => {
                // console.log('!GET Device res:', resVeh);
                toggleLoader(false);

                // if (err) {
                //     console.log('!GET Device error:', err);
                //     toastr.error(i18n.t('errorMessage.errDescription'));
                //     return;
                // }

                if (!resVeh || _.isEmpty(resVeh)) {
                    toastr.error(t('noAssignedDevices'));
                    return;
                }

                const newStates = {
                    externalDevices: resVeh.map(({ serialNumber, name }) => {
                        return { serialNumber, name }
                    }),
                    autoValue: '',
                }

                if (device?.geotab?.serial_number) {
                    const objDevice = _.find(resVeh, (item) => {
                        return item.serialNumber === device.geotab.serial_number;
                    });

                    if (objDevice) {
                        newStates.autoValue = objDevice.name || objDevice.serialNumber
                    }
                }

                setState(prevState => ({ ...prevState, ...newStates }))
            },
            errGet => {
                toggleLoader(false);
                console.log('!GET Device error: ', errGet)
                toastr.error(i18n.t('errorMessage.errDescription'));
            }
        )
    }, [])

    const getWebfleetDevices = () => {
        toggleLoader(true);
        const params = {
            account: externalCredentials.account,
            username: externalCredentials.username,
            password: encodeURI(externalCredentials.password),
        }
        getWebfleetVehicles(params)
            .then((resVeh) => {
                console.log('getWebfleetVehicles resVeh: ', resVeh)
                toggleLoader(false);
                if (resVeh.data.errorMsg) {
                    toastr.error(`Webfleet: ${resVeh.data.errorMsg}`);
                }
                else {
                    toggleLoader(false);
                    const newStates = {
                        externalDevices: resVeh.data.map(({ objectuid, objectname }) => {
                            return { 'serialNumber': objectuid, 'name': objectname }
                        }),
                        autoValue: '',
                    }

                    if (device?.external_srv?.serial_number) {
                        const objDevice = _.find(resVeh.data, ({ objectuid }) => {
                            return objectuid === device.external_srv.serial_number;
                        });

                        if (objDevice) {
                            newStates.autoValue = objDevice.objectname || objDevice.objectuid
                        }
                    }

                    setState(prevState => ({ ...prevState, ...newStates }));
                }
            })
            .catch((error) => {
                toggleLoader(false);
                console.log('getWebfleetDevices error: ', error)
                let errDescription = i18n.t('errorMessage.errDescription');
                if (error.response.data.response.error) {
                    errDescription = error.response.data.response.error
                }
                toastr.error(errDescription)
            });
    }

    const getIturanDevices = () => {
        toggleLoader(true);
        const params = {
            username: externalCredentials.username, // 'Metrolink'
            password: encodeURI(externalCredentials.password), // 'ShieldPlusBus1122!'
            grant_type: 'password',
        }
        getIturanVehicles(params)
            .then((resVeh) => {
                console.log('getIturanDevices resVeh: ', resVeh)
                toggleLoader(false)
                const newStates = {
                    externalDevices: resVeh.data.vehicles.map(({ nickname, license_plate, vehicle_id }) => {
                        return { 'serialNumber': vehicle_id, 'name': nickname + ' - ' + license_plate, license_plate }
                    }),
                    autoValue: '',
                }

                if (device?.external_srv?.serial_number) {
                    const objDevice = _.find(resVeh.data.vehicles, ({ vehicle_id }) => {
                        return vehicle_id.toString() === device.external_srv.serial_number.toString()
                    })

                    if (objDevice) {
                        newStates.autoValue = objDevice.nickname + ' - ' + objDevice.license_plate || objDevice.vehicle_id
                    }
                }

                setState(prevState => ({ ...prevState, ...newStates }))
            })
            .catch((error) => {
                toggleLoader(false);
                console.log('getIturanDevices error: ', error)
                let errDescription = i18n.t('errorMessage.errDescription');
                if (error.response.data.response.error) {
                    errDescription = error.response.data.response.error
                }
                toastr.error(errDescription)
            });
    }

    const checkForm = (data) => {
        let error = ''
        for (let i in data) {
            if ((data[i] == '') && (i !== 'serial_number')) {
                error = t('enterYour') + i + '!';
            }
        }

        if (provisionType === 'geotab' && ((!data.serial_number && !device.geotab.serial_number) || (device.geotab.serial_number && !data.serial_number && state.autoValue))) {
            error = t('selectVehicle')
        }

        if (error) {
            toastr.error(error)
        }

        return error
    }

    const onSaveClick = (e) => {
        switch (provisionType) {
            case 'geotab':
                saveGeotabPairing()
                break
            case 'webfleet':
                saveWebfleetPairing()
                break
            case 'ituran':
                saveIturanPairing()
                break
        }
    }

    const saveGeotabPairing = (e) => {
        const params = {
            device_id: device.device_id,
            serial_number: state.data.serial_number,
        }

        const error = checkForm(params)
        if (!error) {
            toggleLoader(true);

            deviceAddGeotabSerialN(params)
                .then((res, err) => {
                    toggleLoader(false);
                    console.log('!deviceAddGeotabSerialN res', res);

                    toastr.success('', t('snSaved'));

                    props.updateDeviceData();
                })
                .catch((error) => {
                    toggleLoader(false);
                    console.log('!deviceAddGeotabSerialN error', error);

                    let errDescription = i18n.t('errorMessage.errDescription')
                    if (error.response.data.response.error) {
                        errDescription = error.response.data.response.error
                    }
                    toastr.error(errDescription)
                });
        }
    }

    const saveWebfleetPairing = (e) => {
        const params = {
            device_id: device.device_id,
            serial_number: state.data.serial_number,
        }

        const error = checkForm(params)
        if (!error) {
            apiCall('PUT', '/external_service/webfleet/pair', params)
                .then((res, err) => {
                    console.log('saveWebfleetPairing res: ', res, err)
                    toggleLoader(false);
                    toastr.success('', t('snSaved'));

                    // FWSD-5295 - Sends webfleet setExternalObjectData request to update Rosco Live webfleet card
                    if (state.data.serial_number) {
                        sendWebfleetCard()
                    }

                    props.updateDeviceData();
                })
                .catch((error) => {
                    console.log('saveWebfleetPairing error: ', error.response, error)
                    toggleLoader(false);
                    const { status, data } = error.response
                    if (status >= 400 && status < 500) {
                        toastr.error(data.response.error);
                    } else {
                        toastr.error(i18n.t('errorMessage.errDescription'));
                    }
                })
        }
    }

    const saveIturanPairing = (e) => {
        const params = {
            device_id: device.device_id,
            serial_number: state.data.serial_number,
            license_plate: state.data.license_plate,
            additional: JSON.stringify(state.data.additional),
        }

        const error = checkForm(params)
        if (!error) {
            toggleLoader(true)
            apiCall('PUT', '/external_service/ituran/pair', params)
                .then((res, err) => {
                    console.log('saveIturanPairing res: ', res, err)
                    toggleLoader(false);
                    toastr.success('', t('snSaved'));

                    props.updateDeviceData();
                })
                .catch((error) => {
                    console.log('saveWebfleetPairing error: ', error.response, error)
                    toggleLoader(false);
                    const { status, data } = error.response
                    if (status >= 400 && status < 500) {
                        toastr.error(data.response.error);
                    } else {
                        toastr.error(i18n.t('errorMessage.errDescription'));
                    }
                })
        }
    }

    const sendWebfleetCard = () => {
        const params = {
            account: externalCredentials.account,
            username: externalCredentials.username,
            password: encodeURI(externalCredentials.password),
            access_hash: externalCredentials.access_hash,
            vehicle_name: device.vehicle_name,
            online: device.ads_online,
            webfleet_sn: state.data.serial_number,
        }
        setWebfleetCard(params)
            .then()
            .catch(error => {
                console.log('sendWebfleetCard error:', error.response, error)
                toastr.error(t('errWebfleet'))
            })
    }

    const getModalTitle = () => {
        switch (provisionType) {
            case 'geotab':
                return t('pairGODevice')
            case 'webfleet':
            case 'ituran':
                return t('pairWebfleetDevice', { provisionType: _.upperFirst(provisionType) })
        }
    }

    const displayItemName = (item) => {
        return item.name || item.serialNumber;
    }

    const getItemValue = (item) => {
        return item.name || item.serialNumber;
    }

    const matchStateToTerm = (item, value) => {
        if (item.name)
            return item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
        else
            return item.serialNumber.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    }

    const detectSaveDisabled = () => {
        switch (provisionType) {
            case 'geotab':
                return state.data.serial_number === device.geotab.serial_number || (!state.data.serial_number && !device.geotab.serial_number)
            case 'webfleet':
                return state.data.serial_number === device.external_srv.serial_number || (!state.data.serial_number && !device.external_srv.serial_number)
            case 'ituran':
                return state.data.serial_number
                    && device.external_srv?.serial_number?.toString() === state.data?.serial_number?.toString()
                    // && _.isEqual(state.data.additional, JSON.parse((device.external_srv.additional.replace(/'/g, '"'))))
                    && _.isEqual(state.data?.additional, JSON.parse(device.external_srv?.additional))
        }

        return false
    }

    const isSaveDisabled = detectSaveDisabled()

    return (
        <Modal
            show
            size='lg'
            className="modal-pair-device"
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {getModalTitle()}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {t('selectDevice')} <b>"{device.vehicle_name || device.device_id}"</b>
                </p>
                <label>{t('devicePaired')}</label>
                <Autocomplete
                    // open
                    items={state.externalDevices}
                    autoHighlight={true}
                    value={state.autoValue}
                    inputProps={{ id: 'states-autocomplete' }}
                    wrapperProps={{ className: 'search-autocomplete' }}
                    wrapperStyle={{ position: 'relative', zIndex: 1 }}
                    getItemValue={getItemValue}
                    shouldItemRender={matchStateToTerm}
                    renderInput={(props) => (
                        <input {...props} placeholder={t('placeholder')} className="form-control" />
                    )}
                    menuStyle={{
                        borderRadius: '3px',
                        boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                        background: 'rgba(255, 255, 255, 0.98)',
                        padding: '2px 0',
                        fontSize: '90%',
                        position: 'absolute',
                        overflow: 'auto',
                        maxHeight: '220px',
                        left: '0',
                        top: 'auto',
                    }}
                    renderMenu={function (items, value, style) {
                        return <div className="items-list" style={{ ...style, ...this.menuStyle }} children={items} />
                    }}
                    renderItem={(item, isHighlighted) => {
                        // console.log('renderItem: ', item)
                        return (
                            <div
                                className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                                key={item.serialNumber}
                            >
                                {displayItemName(item)}
                            </div>
                        )
                    }}
                    // sortItems={sortStates}
                    onChange={(e, value) => {
                        // console.log('onChange', value);
                        setState(prevState => ({ ...prevState, autoValue: value, data: { ...state.data, serial_number: '', license_plate: '' } }))
                    }}
                    onSelect={(value, item) => {
                        // console.log('onSelect', value, item);
                        const selectedExternalDevice = state.externalDevices.find(({ serialNumber }) => serialNumber === item.serialNumber)
                        setState(prevState => ({
                            ...prevState,
                            autoValue: value,
                            data: { ...state.data, serial_number: item.serialNumber, license_plate: selectedExternalDevice.license_plate || '' }
                        }))
                    }}
                />

                {provisionType === 'ituran' &&
                    <>
                        <p className="mt-20 mb-10">{t('titleSnapshot/VideoOptions')}</p>
                        {Object.keys(state.data.additional).map((key, index) => {
                            return (
                                <div key={key} className="toggle-wrapper button-group">
                                    <label className="settings-title">{key.toUpperCase()}</label>
                                    <div className="toggle-buttons">
                                        <CustomButton
                                            variant='toggle'
                                            active={state.data.additional[key] === 'snapshot_only'}
                                            value="snapshot_only"
                                            name="video_option"
                                            onClick={(e) =>
                                                setState(prevState => ({
                                                    ...prevState,
                                                    data: { ...state.data, additional: { ...state.data.additional, [key]: 'snapshot_only' } }
                                                }))
                                            }
                                        >
                                            {t('btnSnapshot')}
                                        </CustomButton>
                                        <CustomButton
                                            variant='toggle'
                                            active={state.data.additional[key] === 'video_snapshot'}
                                            value="video_snapshot"
                                            name="video_option"
                                            onClick={(e) =>
                                                setState(prevState => ({
                                                    ...prevState,
                                                    data: { ...state.data, additional: { ...state.data.additional, [key]: 'video_snapshot' } }
                                                }))
                                            }
                                        >
                                            {t('btnVideo+Snapshot')}
                                        </CustomButton>
                                    </div>
                                </div>
                            )
                        })}
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                <CustomButton
                    variant="primary-outline"
                    onClick={onHide}
                >
                    {t('btnClose')}
                </CustomButton>
                <CustomButton
                    variant='primary'
                    prefix={`${isSaveDisabled ? 'custom-disabled' : ''} `}
                    disabled={isSaveDisabled}
                    onClick={onSaveClick}
                >
                    {t('btnSave')}
                </CustomButton>
            </Modal.Footer>
        </Modal>
    )
}

ModalProvisionDevice.propTypes = {
    device: PropTypes.objectOf(PropTypes.any).isRequired,
    externalCredentials: PropTypes.objectOf(PropTypes.any).isRequired,
    onHide: PropTypes.func.isRequired,
}

export default connect(
    null,
    dispatch => ({
        toggleLoader: (show) => {
            dispatch({ type: 'TOGGLE_LOADER', payload: show });
        }
    })
)(ModalProvisionDevice);
