import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { getDevicesList } from '../../../action/DeviceActions';
import CategoryItem from '../../dump/menuComponents/CategoryItem';
import CustomButton from '../../CustomButton';

const DeleteProfileModal = (props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'manageVehiclesPage.fleetConfigurationContainer.deleteProfileModal' });
    const { company, profiles, profile, onHide, deleteProfile, loaderModal, toggleLoaderModal } = props;

    const [currentVehicles, setCurrentVehicles] = useState([]);
    const [newProfileId, setNewProfileId] = useState(-1);
    const [step, setStep] = useState();

    useEffect(() => {
        toggleLoaderModal(true);
        getDevicesList(company.company_id)
            .then(res => {
                let totalVehicles = res.data.response.devices
                let currentVehicles = []
                totalVehicles.forEach(vehicle => {
                    if (vehicle.uploaded_company_profile === profile.name || vehicle.pending_company_profile === profile.name) {
                        currentVehicles.push(vehicle)
                    }
                })
                setCurrentVehicles(currentVehicles)
                currentVehicles.length === 0 && setStep(2)
                toggleLoaderModal(false);
            })
            .catch(err => {
                toggleLoaderModal(false);
            })
    }, [])

    const selectNewProfile = (e) => {
        setNewProfileId(e.target.value)
    }

    const vehiclesTerm = currentVehicles.length > 1 ? t('vehicles') : t('vehicle');

    return (
        <Modal
            show
            onHide={onHide}
            className='config-modal modal-lg-size'
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-lg">{t('title>DeleteFleetProfile')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='config-modal-body'>
                    {currentVehicles.length > 0 ?
                        <>
                            <label>{t('labelProfile')} <strong>{profile.name}</strong> {t('textApplied')} {vehiclesTerm}:</label>
                            <div className='profile-vehicle-list'>
                                {currentVehicles.map((vehicle) => {
                                    return (
                                        <CategoryItem
                                            key={vehicle.device_id}
                                            text={vehicle.vehicle_name || vehicle.device_id}
                                            small
                                        />
                                    )
                                })}
                            </div>
                            <label>
                                {t('labelDeleteProfile')} {vehiclesTerm}.
                            </label>
                            <div className="text-center">
                                <select name="profile-select" id="profile-select" className="rosco-select" value={newProfileId} onChange={selectNewProfile}>
                                    <option hidden value={-1}>{t('selectNewProfile')}</option>
                                    {profiles.map((item, index) =>
                                        <option key={index} value={item.id} className="menu-item">{item.name}</option>
                                    )}
                                </select>
                            </div>
                        </> :
                        <label>
                            {t('textDeleteProfile')} <strong>{profile.name}</strong>? {t('textCannotUndone')}
                        </label>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                {currentVehicles.length ?
                    <>
                        <CustomButton variant="primary-outline" onClick={onHide}>
                            {t('btnCancel')}
                        </CustomButton>
                        <CustomButton type="button" variant="delete" onClick={() => deleteProfile(currentVehicles.map(({ device_id }) => device_id), newProfileId)} disabled={newProfileId === -1}>
                            {t('btnDeleteProfile/UpdateVehicles')}
                        </CustomButton>
                    </> :
                    <CustomButton variant="delete" onClick={deleteProfile}>
                        {t('btnDeleteProfile')}
                    </CustomButton>
                }
            </Modal.Footer>
            {loaderModal}
        </Modal>
    );
};

export default connect(
    state => ({
        user: state.user.user,
        company: state.company.company,
        loaderModal: state.loader.loaderModal,
    }),
    dispatch => ({
        toggleLoaderModal: (show) => {
            dispatch({ type: 'TOGGLE_LOADER_MODAL', payload: show });
        }
    })
)(DeleteProfileModal);
