import React from 'react';
import PropTypes from 'prop-types'
import { Link, browserHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

const PatternsDeviceInstalledReport = (props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'patternsPage' });
	console.log('!!!!!PatternsDeviceInstalledReport:', props)
	const { params } = props
	const reportId = params.report_id
	let error = ''
	if (!reportId) {
		error = 'The device install report number is required.'
	} else {
		browserHistory.push({ pathname: '/managevehicles/installation/report', state: { reportId } });
	}

	return (
		<article>
			{error
				&& (
					<div>
						<h3>{t('error404')}</h3>
						<div>{error}</div>
						<div>{t('goBack')} <Link to="/" className="btn">{t('btnHome')}</Link></div>
					</div>
				)}
		</article>
	)
}

PatternsDeviceInstalledReport.propTypes = {
	params: PropTypes.object.isRequired,
}

export default PatternsDeviceInstalledReport;
