/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SearchBar from '../../SearchBar'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import ShareReportModal from './Modals/ShareReportModal';
import AddSubscriptionModal from './Modals/AddSubscriptionModal';
import { tableExportOptions } from '../../helpers/table';
import EditReportModal from './Modals/EditReportModal';
import moment from 'moment';
import { useTranslation } from 'react-i18next'
import momenttz from '../../helpers/momenttz';
import { deleteCustomReport, getCustomReportById } from '../../../action/ReportsActions';
import { toastr } from 'react-redux-toastr';
import ModalDelete from '../modals/ModalDelete';
import { getDateRangeFromTabKey } from './ReportsHelpers';
import DateDropdown from '../../dump/menuComponents/DateDropdown';
import CustomButton from '../../CustomButton';
import { CustomMenu, CustomButtonToggle } from '../../DropdownComponents';

const CustomReportTable = (props) => {
    const { t, i18n } = useTranslation('translation', { keyPrefix: 'reportsPage.customReportTable' });
    const { user, goBack, reportInfo, toggleLoader } = props;
    const userRole = user.roles[0];

    const [reportSearch, setReportSearch] = useState('');
    const [showShareReport, setShowShareReport] = useState(false);
    const [showEditReport, setShowEditReport] = useState(false);
    const [showAddSubscription, setShowAddSubscription] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [report, setReport] = useState([]);
    const [tabKey, setTabKey] = useState(3);
    const [start_date, setStart_date] = useState(moment().clone().startOf('week').format('YYYYMMDD'));
    const [end_date, setEnd_date] = useState(moment().format('YYYYMMDD'));

    useEffect(() => {
        if (reportInfo) {
            getReportData(reportInfo.id, start_date, end_date)
        }
    }, [])

    useEffect(() => {
        changeDate(3)
    }, [reportInfo])

    const getReportData = (id, start_date, end_date) => {
        toggleLoader(true);
        getCustomReportById(id, start_date, end_date, props.company.company_id)
            .then(res => {
                if (props.reportInfo.type === 'driver') {
                    toastr.error(t('toastrUnknownError'));
                }
                setReport(res.data.response);
                toggleLoader(false);
            })
            .catch(err => {
                toastr.error(t('toastrErrorGettingCustomReports'))
                toggleLoader(false);
            })
    }

    const changeDate = (tabKey, selected_start_date, selected_end_date) => {
        let new_start_date, new_end_date;
        if (tabKey) {
            const dateRange = getDateRangeFromTabKey(tabKey)
            new_start_date = dateRange[0];
            new_end_date = dateRange[1];
        }
        else {
            //manually selected date
            new_start_date = selected_start_date ? moment(selected_start_date).format('YYYYMMDD') : start_date;
            new_end_date = selected_end_date ? moment(selected_end_date).format('YYYYMMDD') : end_date;
        }
        setTabKey(tabKey)
        setStart_date(new_start_date)
        setEnd_date(new_end_date)
        getReportData(reportInfo.id, new_start_date, new_end_date)
    }

    const getUpdatedReport = (newReport) => {
        if (newReport && newReport.response) {
            props.showUpdatedReports(newReport.response)
        }
        setShowEditReport(false)
        setShowShareReport(false)
    }

    const deleteReport = () => {
        toggleLoader(true);
        deleteCustomReport(reportInfo.id)
            .then(res => {
                toastr.success(t('toastrDeletedCustomReport'))
                props.goBack()
                props.showUpdatedReports()
            })
            .catch(err => {
                toastr.error(t('toastrUnableDeleteCustomReport'))
                toggleLoader(false);
            })
    }

    const formatColumnName = (field) => {
        switch (field) {
            case 'last_connected_ts':
                return t('lastConnected')
            case 'first_connected_ts':
                return t('firstConnected')
            case 'last_event_ts':
                return t('lastEvent')
            case 'last_trip_ts':
                return t('lastTrip')
            case 'last_disconnected_ts':
                return t('lastOnline')
            case 'gsensor':
                return t('g-Sensor')
            case 'critical_gsensor':
                return t('highG-Force')
            case 'sd_status':
                return t('recordingStatus')
            case 'vin':
            case 'imei':
                return field.toUpperCase()
            case 'harshbraking':
                return t('harshBraking')
            case 'harshaccel':
                return t('harshAcceleration')
            case 'adas_fcw':
            case 'adas_hw':
            case 'adas_ldw':
            case 'adas_pcw':
                return field.toUpperCase().replace('_', '-');
            default:
                field = field.toLowerCase().split('_').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
                return i18n.t(`reportsPage.standardReportData.${field.replace(/\s/g, "_")}`)
        }
    }

    const formatField = (cell, row) => {
        let field;
        if (!row) {
            field = cell;
        }
        else {
            field = Object.keys(row).find(key => row[key] === cell);
        }
        switch (field) {
            case 'last_trip_ts':
            case 'first_connected_ts':
            case 'last_connected_ts':
            case 'last_event_ts':
            case 'last_disconnected_ts':
                return (cell ? momenttz(cell).format('M/DD/YYYY h:mm:ss A') : '-');
            case 'vin':
            case 'imei':
            case 'model':
            case 'manufacturer':
            case 'version':
                return cell || '-'
            default:
                return cell ? cell : 0;
        }
    }

    const formatCSVFileName = () => {
        let name = props.reportInfo.name.toLowerCase().split(' ').join('_');
        let timeframe = moment(start_date).format('M-D-YY') + (start_date === end_date ? '' : '--' + moment(end_date).format('M-D-YY'))
        return name + '_' + timeframe + '.csv'
    }

    let filteredData = [];
    if (reportInfo.type === 'vehicle') {
        filteredData = reportSearch ? report.filter(e => (e.vehicle_name && e.vehicle_name.toLowerCase().includes(reportSearch.toLowerCase())) || (e.device_id && (e.device_id + '').includes(reportSearch))) : report;
    }
    else {
        filteredData = reportSearch ? report.filter(e => (e.driver_name && e.driver_name.toLowerCase().includes(reportSearch.toLowerCase()))) : report;
    }

    return (
        <div className="report">
            <div className='page-subheader'>
                <div className='subheader-section search-flex'>
                    <SearchBar term={reportSearch} onSearch={(e) => setReportSearch(e.target.value)} />
                    <DateDropdown
                        start_date={start_date}
                        end_date={end_date}
                        tabKey={tabKey}
                        onSelectDate={changeDate}
                    />
                </div>
                <div className='subheader-section'>
                    {reportInfo.owner_id === user.id ?
                        <Dropdown
                            id="report-actions"
                            className='actions-dropdown'
                            role="menuitem"
                            drop
                        >
                            <Dropdown.Toggle
                                as={CustomButtonToggle}
                                variant='primary-outline'
                                withCaret
                                id='action-dropdown'
                                prefix="actions dropdown-toggle btn btn-default"
                            >
                                {t('dropdownAction')}
                                <span className="caret" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu as={CustomMenu}>
                                {['user', 'partner_view'].indexOf(userRole) === -1 &&
                                    <button className='menu-action custom' onClick={() => setShowShareReport(true)}>{t('btnShare')}</button>
                                }
                                <button
                                    className='menu-action custom'
                                    onClick={() => setShowAddSubscription(true)}
                                >
                                    {t('btnAutomateReport')}
                                </button>
                                <button className='menu-action custom' onClick={() => setShowEditReport(true)}>{t('btnEdit')}</button>
                                <button className='menu-action custom delete' onClick={() => setShowDeleteModal(true)}>{t('btnDelete')}</button>
                            </Dropdown.Menu>
                        </Dropdown> :
                        <CustomButton
                            variant="primary-outline"
                            size="sm"
                            onClick={() => setShowAddSubscription(true)}
                        >
                            {t('btnAutomateReport')}
                        </CustomButton>
                    }
                    <CustomButton
                        variant="secondary-outline"
                        onClick={goBack}
                    >
                        {t('btnBack')}
                    </CustomButton>
                </div>
            </div>

            <main className="main-content-block">
                <h3 className='category-title report-title'>
                    {reportInfo.name}
                </h3>
                {report &&
                    <BootstrapTable
                        data={filteredData}
                        {...tableExportOptions}
                        csvFileName={formatCSVFileName}
                    >
                        {reportInfo.fields.map(field => {
                            let dataFormatFunction = formatField;
                            if (field === 'vehicle_name') {
                                dataFormatFunction = (cell, row) => cell || row.device_id
                            }
                            return (<TableHeaderColumn
                                width="20%"
                                dataField={field}
                                dataFormat={dataFormatFunction}
                                csvFormat={dataFormatFunction}
                                csvHeader={formatColumnName(field)}
                                dataSort
                                key={field}
                                isKey={field === 'vehicle_name' || field === 'driver_name' || field === 'device_id'}
                            >
                                {formatColumnName(field)}
                            </TableHeaderColumn>);
                        })}
                    </BootstrapTable>
                }
            </main>

            {showShareReport && <ShareReportModal onHide={(newReport) => getUpdatedReport(newReport)} reportInfo={reportInfo} />}
            {showAddSubscription && <AddSubscriptionModal onHide={() => setShowAddSubscription(false)} report={{ type: 'custom', report_id: reportInfo.id }} />}
            {showEditReport && <EditReportModal onHide={(newReport) => getUpdatedReport(newReport)} reportInfo={reportInfo} />}
            {showDeleteModal && <ModalDelete title={t('titleModalDelete')} content={t('contentModalDelete', { reportName: reportInfo.name })} closeModal={() => setShowDeleteModal(false)} deleteModal={deleteReport} />}
        </div>
    )
}

CustomReportTable.propTypes = {
    user: PropTypes.objectOf(PropTypes.any).isRequired,
    company: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default connect(
    state => ({
        user: state.user.user,
        company: state.company.company,
    }),
    dispatch => ({
        toggleLoader: (show) => {
            dispatch({ type: 'TOGGLE_LOADER', payload: show });
        }
    })
)(CustomReportTable);
