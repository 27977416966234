import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { RiAlertFill } from 'react-icons/ri'
import { BsCheckLg, BsCheck } from 'react-icons/bs'
import { FaCheck } from 'react-icons/fa'

const CalibrationItem = (props) => {
	// console.log('PROPS CalibrationItem: ', props)
	const { t } = useTranslation('translation', { keyPrefix: 'manageVehiclesPage.fleetConfigurationContainer.сalibrationNeededModal.calibrationItem' });
	const { device, onClick } = props
	const { device_id, vehicle_name, adas_camera_height, adas_vehicle_width } = device

	const handleClick = (e) => {
		e.preventDefault()
		onClick()
	}

	return (
		<div className="toggle-wrapper">
			<label>{vehicle_name || device_id}</label>

			{(!adas_camera_height || !adas_vehicle_width)
				? (
					<a href="/" onClick={handleClick}>
						<div>
							<RiAlertFill className="glyphicon-alert" />
							<span className="value">{t('needsCalibration')}</span>
						</div>
					</a>
				)
				: <FaCheck className="glyphicon glyphicon-ok" />
			}

		</div>
	)
}

CalibrationItem.propTypes = {
	device: PropTypes.objectOf(PropTypes.any).isRequired,
	onClick: PropTypes.func.isRequired,
}

export default CalibrationItem;
