import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import { Dropdown, Modal } from 'react-bootstrap'
import Toggle from 'react-toggle'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
// import API from 'mg-api-node'
import NumericInput from 'react-numeric-input'
import Datetime from '@iftek/react-datetime'
import Select from 'react-select'
import moment from 'moment'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import GreyHeader from '../../component/dump/menuComponents/GreyHeader'
import CompanyDataThreshold from './CompanyDataThreshold'
import CompanyExternalIntegrations from './CompanyExternalIntegrations'
import CompanyEditGeotabSettings from './CompanyEditGeotabSettings'
import CompanyEditWebfleetSettings from './CompanyEditWebfleetSettings'
import CustomAPN from './CustomAPN'

import { geotabAuthentication } from '../../component/helpers/helperGeotab'
import { isInternalUser, timezoneDBOptions } from '../../component/helpers/constants'
import { errorDetection } from '../../component/helpers/helperErrors'
import { getCompanyById, updateCompany, deleteCompany, getCompaniesList, disableCompany, sendGeotabLink } from '../../action/CompaniesActions'
import { signupImport, checkImportStatus } from '../../action/AccountActions'
import { getCompanyGeotabData, deleteGeotabCompany } from '../../action/GeotabActions'
import { getUsers, getAssignedUsersOfCompany } from '../../action/ClientActions'
import { apiCallGet, apiCall } from '../../action/RouterActions'

import '../../component/dump/menuComponents/menuComponents.sass'
import CustomButton from '../../component/CustomButton'
import { clipboardIcon } from '../../component/helpers/iconHelpers'
import { CustomButtonToggle } from '../../component/DropdownComponents'

const CompanyEditContainer = (props) => {
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'companiesPage.companyEditContainer' });
	console.log('PROPS CompanyEditContainer: ', props);
	const { location, user, toggleLoader } = props;
	const userRole = user.roles[0];

	const [state, setState] = useState({
		trialAvailableUsers: [],
		company: {},
		data: {
			data_limit: '',
			billing_date: '',
			partner_company: '',
			driver_recognition: 'manual',
		},
		modal: {
			show: false,
			content: '',
			error: false,
		},
		errMessage: {
			company_name: '',
			company_city: '',
			company_state: '',
			company_zipcode: '',
			company_address: '',
			company_country: '',
			billing_date: '',
			active: '',
			partner_company: '',
			company_type: '',
			user_login: '',
			user_password: '',
			fleet_token_r1: '',
			data_limit: '',
			geotab_email: '',
			industry_type: '',
			// data_threshold_pct: '',
		},
		initialCompany: location.state.company,
		toggle_password: true,
		log_in: {
			user_login: '',
			user_password: '',
			fleet_token_r1: '',
		},
		status: false,

		geotabCredentials: {
			email: '',
			password: '',
		},
		webfleetCredentials: {},
		allow_edit_company: true,
	})
	const { company, initialCompany, data, errMessage, companies, trialAvailableUsers, modal, geotabCredentials, webfleetCredentials } = state

	useEffect(() => {
		getData();
		if (userRole === 'system_admin') importStatus();
	}, []);

	useEffect(() => {
		return () => {
			localStorage.removeItem("edit_company_data");
		}
	}, [location]);

	// FWSD-6885
	const hideProvisionBtns = !isInternalUser(userRole) && state.company && (state.company.id === 2522 || state.company.created_by_company_id === 2522)
	const externalType = _.upperFirst(company.geotab_database ? 'Geotab' : company.external_srv_type || company.external_srv_pending)

	const getData = async () => {
		if (location) {
			toggleLoader(true);
			const companyType = 'PARTNER';
			let companyData = localStorage.getItem("edit_company_data");
			companyData ? companyData = JSON.parse(companyData) : companyData = { company_name: null, partner_company: null };
			const params = {
				name: companyData.company_name || state.initialCompany.company_name,
				partner: companyData.partner_company || state.initialCompany.partner_company,
				roles: 'sales_demo',
			};

			const requestArray = [];
			requestArray.push(getCompanyById(userRole, state.initialCompany.id));
			requestArray.push(apiCallGet('/external_service/webfleet/account', { company_id: state.initialCompany.id }));
			requestArray.push(getCompaniesList(userRole, companyType));
			requestArray.push(getUsers(userRole, params));
			['system_admin', 'customer_service'].includes(userRole) && requestArray.push(getAssignedUsersOfCompany({ userRole, partnerName: params.partner, companyName: params.name }));
			await Promise.all(requestArray)
				.then((res) => {
					console.log('!Promise.all res: ', res);
					toggleLoader(false);

					const companyData = res[0].data.response.company;
					const webfleetData = res[1].data.response;
					const companies = _.sortBy(res[2].data.response['companies list'], num => num.toLowerCase());
					if (state.data.company_type !== 'WCS') {
						_.remove(companies, (el) => { return el === 'Rosco Vision WCS' })
					}
					let resUsers = [];
					let assignedUsers;
					if (res[4]) assignedUsers = res[4].data.response;

					// Gets Geotab database data when company has geotab provision
					if (companyData.geotab_database) {
						const data = {
							company_name: companyData.company_name,
							partner_name: companyData.partner_company,
							user_role: userRole,
							company_id: companyData.id,
							get_p: true,
						}
						getCompanyGeotabData(data)
							.then(res => {
								setState(prevState => ({ ...prevState, geotabCredentials: res.data.response }));
							})
							.catch(err => {
								console.log(err)
							})
					}

					if (companyData.trial_subscribers) {
						resUsers = companyData.trial_subscribers.split(',').map(item => ({ value: item, label: item }))
					}

					resUsers = res[3].data.response.users.map(item => ({ value: item.email, label: item.email }));

					const sales = _.find(assignedUsers, ['role', 'sales_demo']);
					if (sales) {
						resUsers = sales.users.map((item) => ({ value: item, label: item }))
					}

					setState(prevState => ({
						...prevState,
						company: JSON.parse(JSON.stringify(companyData)),
						initialCompany: JSON.parse(JSON.stringify(companyData)),
						data: companyData,
						webfleetCredentials: webfleetData,
						companies: companies,
						trialAvailableUsers: _.uniqBy([...state.trialAvailableUsers, ...resUsers], 'value')
					}));

					// if (callback && typeof callback === 'function') {
					// 	callback()
					// }
				})
				.catch((error) => {
					toggleLoader(false);
					console.error('Promise.all error: ', error.response, error)
					toastr.error(t('issueGettingCompany'))
				});
		}
	}

	// const getCompany = () => {
	// 	toggleLoader(true)
	// 	getCompanyById(userRole, state.initialCompany.id)
	// 		.then(res => {
	// 			toggleLoader(false)
	// 			const companyData = res.data.response.company
	// 			setState(prevState => ({
	// 				...prevState,
	// 				company: companyData
	// 			}))
	// 		})
	// 		.catch(error => {
	// 			toggleLoader(false);
	// 			console.log('!getCompany error: ', error.response, error)

	// 			let errDescription = 'An unexpected error occurred. Please try again later'
	// 			if (error.response.data.response.error) {
	// 				errDescription = error.response.data.response.error
	// 			}
	// 			toastr.error(errDescription)
	// 		})
	// }

	const goBack = () => {
		if (location && location.state && location.state.type) {
			switch (location.state.type) {
				case 'reports':
					browserHistory.push({ pathname: '/reports' });
					break;
				default:
					browserHistory.push('/company')
					break;
			}
		}
		else {
			browserHistory.push('/company')
		}
	}

	const sendForm = (e) => {
		e.preventDefault();
		saveCompanyUpdates();
	}

	const saveCompanyUpdates = (e) => {
		const { user, addCompany, delCompany, editCompany, editCompanyCollection } = props
		const { data, company, initialCompany } = state

		const changedData = _.reduce(_.keys(data), (result, key) => {
			if (!Object.prototype.hasOwnProperty.call(company, key) || !_.isEqual(company[key], data[key])) {
				result[key] = data[key]
			}
			return result;
		}, {});
		if (_.isEmpty(changedData)) {
			toastr.warning('', t('companyNoChanges'))
			return
		}
		if (changedData.dms_notifs_cutoff === null) changedData.dms_notifs_cutoff = 0;

		const sendData = {
			company: initialCompany,
			data: changedData,
		}

		const error = checkForm(changedData)
		if (!error) {
			toggleLoader(true)

			updateCompany(sendData, userRole)
				.then((res, err) => {
					const newData = res.data.response.company

					if (data.partner_company !== company.partner_company) {
						if (props.company.partner === data.partner_company) {
							addCompany({ company: data.company_name });
						} else if (props.company.partner !== data.partner_company) {
							delCompany({ company: data.company_name });
						}
					}

					setState({ ...state, company: newData, initialCompany: JSON.parse(JSON.stringify(newData)) });
					localStorage.setItem("edit_company_data", JSON.stringify({ company_name: newData.company_name, partner_company: newData.partner_company }));
					getData();

					if (company.active !== data.active) {
						let active;
						if (data.active) {
							active = 'enable';
						} else {
							active = 'disable'
						}

						disableCompany(userRole, sendData, active)
							.then((res, err) => {
								toggleLoader(false);
								const company = res.data.response.company;
								setState({ ...state, company: company, initialCompany: company });
							})
							.catch((error) => {
								toggleLoader(false);
								console.log('!disableCompany error', error.response);
								let errDescription = i18n.t('errorMessage.errDescription');
								if (error.response.data.response.error) {
									errDescription = error.response.data.response.error
								}
								toastr.error(errDescription)
							});
					}

					// updates company state of the company reducer and company_collection state of the partnerList reducer if changed the driver_recognition value
					if (_.has(changedData, 'driver_recognition')) {
						if (props.company.company_id === company.id) {
							editCompany({ driver_recognition: changedData.driver_recognition })
						}
						editCompanyCollection({ id: company.id, driver_recognition: changedData.driver_recognition })
					}

					toggleLoader(false);
					toastr.success("", t('updatedCompany'));
				})
				.catch((error) => {
					toggleLoader(false)
					console.error('!CompanyEditContainer updateCompany error: ', error.response, error)

					let errDescription = t('unableUpdateCompany');
					if (error.response.data.body) {
						errDescription = errorDetection(error.response.data.body) || error.response.data.body
					}
					toastr.error(errDescription);
				})
		}
	}

	const checkForm = (data) => {
		let error = false;
		const { errMessage } = state;
		for (let i in data) {
			if (data[i] === '' && i !== 'trial_subscribers' && i !== 'company_phone_number' && i !== 'company_address2') {
				error = true;
				errMessage[i] = t('enterYour') + i.replaceAll('_', ' ') + '!';
			} else if (errMessage[i]) {
				errMessage[i] = null;
			}
		}

		if (data.geotab_email && !errMessage.geotab_email) {
			const re = /^([a-zA-Z0-9_\-\.\+]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			if (!re.test(data.geotab_email)) {
				error = true;
				errMessage.geotab_email = t('errEmail');
			}
		}

		if (!errMessage.company_name && !/^[a-zA-Z0-9 \_\-\.]+$/.test(data.company_name)) {
			error = true;
			errMessage.company_name = t('errCompanyName');
		}

		// FWSD-6805
		// if (!errMessage.data_threshold_pct && state.data.data_threshold_type === 'company' && data.data_threshold_pct < 100) {
		// 	error = true;
		// 	errMessage.data_threshold_pct = 'Enter a value at least 100'
		// }

		setState({ ...state, errMessage });
		if (error) {
			toastr.error('', t('errCompanyValue'))
		}
		return error;
	}

	const handleInputChange = (event) => {
		const { target } = event;
		const { name } = target;
		let value = target.type === 'checkbox' ? target.checked : target.value;

		if (name === 'download_files') {
			if (value) {
				value = 2 // all files
			} else {
				value = 1 // event files
			}
		}

		setState({ ...state, data: { ...state.data, [name]: value } })
	}

	const handleNumericChange = (valint, valstr, e) => {
		const name = $(e).attr('name');
		const value = valint;

		setState({ ...state, data: { ...state.data, [name]: value } })
	}

	const handleSelectChange = (event) => {
		const { target } = event;
		const { name, type } = target;
		const value = type === 'checkbox' ? target.checked : target.value;

		setState({ ...state, data: { ...state.data, [name]: value } })
	}

	const handleDateChanged = (current) => {
		if (!moment(current, 'MM/DD/YYYY', true).isValid()) {
			toastr.error('', t('errValidDateValue'))
			return
		}

		setState({ ...state, data: { ...state.data, trial_date: current } })
	}

	const loginInputChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		let data = state.log_in;
		data[name] = value;
		setState({ ...state, log_in: data });
	}

	const modalConfirm = (e, action) => {
		const dataUpdate = state.data;
		let title, content;
		switch (action) {
			case 'delete_company':
				title = t('titleDeleteCompany')
				content = t('contentDeleteCompany', { company_name: state.company.company_name })
				break
			// case 'import':
			// 	title = 'Import'
			// 	content = 'Please Log In in RoscoLive 1.0 for import'
			// 	break
			case 'delete_relation':
				title = t('titleRemoveRelation', { externalType })
				content = t('contentRemoveRelation', { company_name: `"${location.state.company.company_name}"`, externalType: externalType })
				break
			default:
				break
		}

		setState((prevState) => {
			return {
				...prevState, modal: { ...state.modal, show: true, title, content, type_action: action }, errMessage: { ...state.errMessage, user_login: '', user_password: '' }
			}
		});
	}

	const closeModal = () => {
		setState({ ...state, modal: { ...state.modal, error: false, show: false }, log_in: { user_login: '', user_password: '', fleet_token_r1: '' }, errMessage: { ...errMessage, geotab_email: '' } });
	}

	const deleteCompanyModal = () => {
		const { delCompany } = props
		const data = {
			company_name: state.company.company_name,
			partner_name: state.company.owner_company,
			user_role: userRole,
		}

		deleteCompany(data)
			.then((res, err) => {
				if (res.status === 200) {
					setState({ ...state, modal: { ...state.modal, show: false } });
					toastr.success('', t('companyDeleted'));

					if (data.partner_name === props.company.partner) {
						delCompany({ company: data.company_name });
					}

					browserHistory.push('/company');
				}
			})
			.catch((error) => {
				setState({ ...state, modal: { ...state.modal, show: false } });
				let errDescription = i18n.t('errorMessage.errDescription');
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription);
			});
	}

	const deleteGeotabRelationModal = () => {
		const data = {
			company_name: state.company.company_name,
			partner_name: location.state.company.owner_company,
			user_role: user.roles[0],
			company_id: state.company.id,
			get_p: true,
		}

		toggleLoader(true);
		getCompanyGeotabData(data)
			.then(async (resGeotab, errGeotab) => {
				console.log('!getCompanyGeotabData res: ', resGeotab, errGeotab);

				if (errGeotab) {
					toggleLoader(false);
					console.log('!getCompanyGeotabData err: ', errGeotab);
					toastr.error(i18n.t('errorMessage.errDescription'));
					return;
				}

				if (resGeotab.status === 200) {
					const geotab_company = resGeotab.data.response

					// REMOVES GEOTAB ADDINs
					// FWSD-4475
					let initData = {
						company_id: state.company.id,
						password: geotab_company.password,
						path: geotab_company.path,
						fleet_token: geotab_company.token,
						database: geotab_company.database,
						email: geotab_company.email,
						sessionId: geotab_company.session_id,
					}

					const resultAuth = await geotabAuthentication(initData)
					// console.log('resultAuth!!!', resultAuth)

					const delGeotabCompany = (data) => {
						// console.log('!SET SystemSettings res', resSys);
						// DELETE GEOTAB RELATIONS
						deleteGeotabCompany(data)
							.then((res, err) => {
								console.log('!deleteGeotabCompany res:', res, err)

								if (err) {
									toggleLoader(false);
									console.log('!deleteGeotabCompany err', err);
									toastr.error(i18n.t('errorMessage.errDescription'));
									return
								}

								getData();

								setState({ ...state, modal: { ...state.modal, show: false } });
								toastr.success('', t('geotabRelationDeleted'))
							})
							.catch((error) => {
								toggleLoader(false);
								console.log('!error', error.response.data.response.error);
								console.log(error.response);

								setState({ ...state, modal: { ...state.modal, show: false } });
								let errDescription = i18n.t('errorMessage.errDescription');
								if (error.response.data.response.error) {
									errDescription = error.response.data.response.error
								}
								toastr.error(errDescription);
							});
					}

					if (resultAuth.error) {
						toggleLoader(false);
						delGeotabCompany(data);
						toastr.warning(t('errRemoveGeotabDatabase'));
						return;
					}
					const { geotabApi, geotabData } = resultAuth
					data.path = geotabData.path
					data.database = geotabData.database
					data.sessionId = geotabData.sessionId
					data.email = geotabData.email

					geotabApi.multiCall([
						['Get', {
							typeName: 'AddIn',
						}],
						['GetNotificationWebRequestTemplates', {
							credentials: {
								database: data.database,
								userName: data.email,
								sessionId: data.sessionId,
								// password: newData.password
							}
						}],
					],
						(resCall) => {
							const addinsList = resCall[0]
							const notificationWebRequests = resCall[1]

							// // console.log('!GET Geotab Settings res: ', resCall)
							// let systemSettings = resCall[0][0]
							// const tmp = systemSettings.customerPages.filter(addin => {
							// 	return JSON.parse(addin).name !== 'RoscoLive';
							// });
							// systemSettings.customerPages = tmp

							let notif_snapshot_exist_id = false;
							let notif_video_exist_id = false;
							let notif_snapshot_and_video_exist_id = false;
							let rosco_add_in_id = false;
							const multicalls = [];

							addinsList.forEach((addin, i) => {
								if (addin?.configuration?.name === 'Dual-Vision Video Camera' && rosco_add_in_id === false) {
									rosco_add_in_id = addin?.id
								}
							})

							notificationWebRequests.map((notification, i) => {
								if (notification.name == 'Auto-upload snapshot to RoscoLive' && !notif_snapshot_exist_id) {
									notif_snapshot_exist_id = notification.id;
								}
								else if (notification.name == 'Auto-upload video to RoscoLive' && !notif_video_exist_id) {
									notif_video_exist_id = notification.id;
								}
								else if (notification.name == 'Auto-upload snapshot and video clip to RoscoLive' && !notif_snapshot_and_video_exist_id) {
									notif_snapshot_and_video_exist_id = notification.id;
								}
							})

							if (rosco_add_in_id) {
								multicalls.push(
									['Remove', {
										typeName: 'AddIn',
										entity: { id: rosco_add_in_id },
										credentials: {
											database: data.database,
											userName: data.email,
											sessionId: data.sessionId,
										}
									}]
								)
							}

							if (notif_snapshot_exist_id) {
								multicalls.push(
									['Remove', {
										typeName: 'NotificationBinaryFile',
										entity: { id: notif_snapshot_exist_id },
										credentials: {
											database: data.database,
											userName: data.email,
											sessionId: data.sessionId,
										}
									}]
								)
							}

							if (notif_video_exist_id) {
								multicalls.push(
									['Remove', {
										typeName: 'NotificationBinaryFile',
										entity: { id: notif_video_exist_id },
										credentials: {
											database: data.database,
											userName: data.email,
											sessionId: data.sessionId,
										}
									}]
								)
							}

							if (notif_snapshot_and_video_exist_id) {
								multicalls.push(
									['Remove', {
										typeName: 'NotificationBinaryFile',
										entity: { id: notif_snapshot_and_video_exist_id },
										credentials: {
											database: data.database,
											userName: data.email,
											sessionId: data.sessionId,
										}
									}]
								)
							}

							geotabApi.multiCall(
								multicalls,
								(resDel) => {
									delGeotabCompany(data)
								},
								(errorDel) => {
									toggleLoader(false);
									console.log('!SET SystemSettings error errSys: ', errorDel)
									toastr.error(i18n.t('errorMessage.errDescription'));
								}
							);
						},
						(errCall) => {
							toggleLoader(false);
							console.log('!GET SystemSettings error errCall: ', errCall)
							toastr.error(i18n.t('errorMessage.errDescription'));
						})
				}

			})
			.catch((error) => {
				toggleLoader(false);
				// NO GEOTAB COMPANY
				console.log('!getCompanyGeotabData error: ', error.response, error);
			});
	}

	const deleteExternalRelationModal = () => {
		toggleLoader(true);
		apiCall('DELETE', `/external_service/${externalType.toLowerCase()}/account`, { company_id: state.company.id })
			.then(res => {
				toggleLoader(false);
				// setState({ ...state, modal: { ...state.modal, show: false }, webfleetCredentials: {} });
				setState({ ...state, modal: { ...state.modal, show: false } })
				getData()
				toastr.success('', t('webfleetRelationDeleted'))
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!deleteWebfleetRelationModal error:', error.response, error)
				let errDescription = i18n.t('errorMessage.errDescription');
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription);
			})
	}

	const importStatus = () => {
		const { initialCompany } = state;
		const { token } = initialCompany;
		toggleLoader(true)
		checkImportStatus(token)
			.then((res, err) => {
				console.log('!importStatus res: ', res);
				const { status } = res.data.response;
				setState({ ...state, status: status });
				toggleLoader(false);
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!importStatus error: ', error);
				let errDescription = i18n.t('errorMessage.errDescription');
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription);
			});
	}

	const setPropStates = (states, callback = null) => {
		setState({ ...state, ...states });

		if (typeof callback === 'function') {
			callback()
		}
	}

	const {
		company_name,
		company_city,
		company_state,
		company_zipcode,
		company_address,
		company_country,
		billing_date,
		company_type,
		partner_company,
		user_login,
		user_password,
		fleet_token_r1,
		data_limit,
		geotab_email,
		file_retention_time,
		download_files,
		reformat_reminder,
		industry_type
	} = errMessage;

	const { trial_status, trial_date, trial_subscribers } = data;

	const industryTypeDisplayOptions = [
		i18n.t('companiesPage.industryTypes.FTL/LTL/Courier'),
		i18n.t('companiesPage.industryTypes.Medical/Health'),
		i18n.t('companiesPage.industryTypes.School/Education'),
		i18n.t('companiesPage.industryTypes.Construction'),
		i18n.t('companiesPage.industryTypes.Waste Management'),
		i18n.t('companiesPage.industryTypes.Mass Transit'),
		i18n.t('companiesPage.industryTypes.Agricultural'),
		i18n.t('companiesPage.industryTypes.Government'),
		i18n.t('companiesPage.industryTypes.Industrial'),
		i18n.t('companiesPage.industryTypes.Other')
	];

	const industryTypeValueOptions = [
		'ftl_ltl_courier',
		'medical_health',
		'school_education',
		'construction',
		'waste_management',
		'mass_transit',
		'agricultural',
		'government',
		'industrial',
		'other'
	];

	const companyList = () => {
		const option = []
		if (companies) {
			companies.map((elem, index) => {
				option[index] = (<option key={elem} value={elem}>{elem}</option>)
			})
		}
		return (
			<select
				placeholder="select"
				type="text"
				className="rosco-select block"
				name="partner_company"
				id="partner_company"
				value={data.partner_company}
				onChange={handleSelectChange}
			>
				<option disabled value />
				{option}
			</select>
		)
	};

	const validDate = (current) => {
		return current.isAfter(moment())
	}

	// console.log('PROPS CompanyEditContainer: ', props)
	// console.log('STATE CompanyEditContainer: ', state)

	if (!company) {
		return (
			<GreyHeader title={t('titleEditCompany')} hideSubtitle />
		)
	}

	return (
		<div>
			<GreyHeader
				title={t('titleEditCompany')}
				subtitle={initialCompany.company_name}
			/>

			<div className="page-subheader">

				<div className="subheader-section company-info">
					{!hideProvisionBtns &&
					<>
						<CompanyExternalIntegrations
							company={company}
							modalConfirm={modalConfirm}
							getCompany={getData}
						/>
						
						{/* <CompanyEditGeotabSettings
							company={company}
							geotabCredentials={geotabCredentials}
							modalConfirm={modalConfirm}
						/>
						<CompanyEditWebfleetSettings
							company={company}
							webfleetCredentials={webfleetCredentials}
							modalConfirm={modalConfirm}
						/> */}
					</>
					}
				</div>

				<div className="subheader-section">
					{(userRole === 'partner' || isInternalUser(userRole)) && (
						<CustomButton variant="secondary" disabled={!state.allow_edit_company} onClick={sendForm}>
							{t('btnSave')}
						</CustomButton>
					)}
					<CustomButton variant="secondary-outline" onClick={goBack}>{t('btnBack')}</CustomButton>
					{isInternalUser(userRole) && state.allow_edit_company &&
						<CustomButton variant="delete" onClick={(e) => modalConfirm(e, 'delete_company')}>{t('btnDelete')}</CustomButton>
					}
				</div>
			</div>

			<main className='main-content-block'>
				<form>
					<section>
						<h3 className="category-title">{t('titleCompanyInfo')}</h3>
						<div className="two-column-grid with-margin">
							<div className={classnames('two-columns', { 'has-error': company_name })}>
								<label className="control-label">{t('labelName')}</label>
								<input
									type="text"
									name="company_name"
									className="vehicle-search"
									onChange={(e) => { handleInputChange(e) }}
									value={data.company_name || ""}
									maxLength={50}
								/>
								{company_name && <span className="help-block">{company_name}</span>}
							</div>

							<div className={classnames({ 'has-error': company_address })}>
								<label className="control-label">{t('labelAddress')}</label>
								<input type="text" name="company_address" className="vehicle-search" onChange={handleInputChange} value={data.company_address || ""} />
								{company_address && <span className="help-block">{company_address}</span>}
							</div>

							<div className="optional">
								<label className="control-label">{t('labelAddress2')} <span>{t('optional')}</span></label>
								<input type="text" name="company_address2" className="vehicle-search" onChange={handleInputChange} value={data.company_address2 || ""} />
							</div>

							<div className={classnames({ 'has-error': company_city })}>
								<label className="control-label">{t('labelCity')}</label>
								<input type="text" name="company_city" className="vehicle-search" onChange={handleInputChange} value={data.company_city || ""} />
								{company_city && <span className="help-block">{company_city}</span>}
							</div>

							<div className={classnames({ 'has-error': company_state })}>
								<label className="control-label">{t('labelState')}</label>
								<input type="text" name="company_state" className="vehicle-search" onChange={handleInputChange} value={data.company_state || ""} />
								{company_state && <span className="help-block">{company_state}</span>}
							</div>

							<div className={classnames({ 'has-error': company_zipcode })}>
								<label className="control-label">{t('labelZipCode')}</label>
								<input type="text" name="company_zipcode" className="vehicle-search" onChange={handleInputChange} value={data.company_zipcode || ""} />
								{company_zipcode && <span className="help-block">{company_zipcode}</span>}
							</div>

							<div className={classnames({ 'has-error': company_country })}>
								<label className="control-label">{t('labelCountry')}</label>
								<input type="text" name="company_country" className="vehicle-search" onChange={handleInputChange} value={data.company_country || ""} />
								{company_country && <span className="help-block">{company_country}</span>}
							</div>

							<div className="optional">
								<label className="control-label">{t('labelPhoneNumber')} <span>{t('optional')}</span></label>
								<input
									type="text"
									name="company_phone_number"
									className="vehicle-search"
									onChange={handleInputChange}
									value={data.company_phone_number || ""}
								/>
							</div>

							{/* <div className={classnames({ 'has-error': billing_date })} style={{ marginTop: '-2px' }}>
								<label className="control-label">{t('labelBillingDate')}</label>
								<select
									placeholder="select"
									className="rosco-select block"
									name="billing_date"
									id="billing_date"
									onChange={handleInputChange}
									disabled={!isInternalUser(userRole)}
									value={data.billing_date ? data.billing_date : ''}
								>
									<option value="" />
									{_.range(1, 29).map((item, i) => (<option key={i} value={i + 1}>{item}</option>))}
								</select>
								{billing_date && <span className="help-block">{billing_date}</span>}
							</div> */}

							<div className={classnames({ 'has-error': industry_type })}>
								<label className="control-label">{t('labelIndustryType')}</label>
								<select
									placeholder="select"
									className="rosco-select block"
									name="industry_type"
									id="industry_type"
									onChange={(e) => { handleInputChange(e) }}
									value={data.industry_type ? data.industry_type : ''}
								>
									<option value="" />
									{industryTypeDisplayOptions.map((item, i) => (
										<option key={i} value={industryTypeValueOptions[i]}>{item}</option>
									))}
								</select>
								{industry_type && <span className="help-block">{industry_type}</span>}
							</div>
						</div>
					</section>

					{isInternalUser(userRole) && (
						<section>
							<h3 className="category-title">{t('labelAdminTools')}</h3>
							<div className="two-column-grid with-margin">

								<div className={classnames({ 'has-error': partner_company })}>
									<label htmlFor="partner_company" className="control-label">{t('labelPartnerName')}</label>
									{companyList()}
									{partner_company && <span className="help-block">{partner_company}</span>}
								</div>

								{/* <div className={classnames({ 'has-error': data_limit })}>
									<label className="control-label">{t('labelVehicleDataLimit')}</label>
									<select
										placeholder="select"
										className="rosco-select block"
										name="data_limit"
										id="data_limit"
										onChange={handleInputChange}
										value={data.data_limit ? data.data_limit : ''}
									>
										<option value="0">Unlimited</option>
										<option value="2">500 MB</option>
										<option value="3">1 GB</option>
									</select>
									{data_limit && <span className="help-block">{data_limit}</span>}
								</div> */}
								<div className={classnames({ 'has-error': reformat_reminder })}>
									<label className="control-label">{t('SDCardReformatReminder')}</label>
									<select
										placeholder="select"
										className="rosco-select block"
										name="reformat_reminder"
										id="reformat_reminder"
										onChange={handleInputChange}
										value={data.reformat_reminder ? data.reformat_reminder : ''}
									>
										<option value="0">{t('noReminder')}</option>
										<option value="1">{t('1month')}</option>
										{_.range(2, 12).map(item => <option value={item} key={item}>{`${item} ${t('months')}`}</option>)}
									</select>
									{reformat_reminder && <span className="help-block">{reformat_reminder}</span>}
								</div>

								<div>
									<label htmlFor="timezone" className="control-label">{t('labelTimeZone')}</label>
									<select
										placeholder="select"
										className="rosco-select block"
										name="timezone"
										id="timezone"
										value={data.timezone || 'US/Eastern'}
										onChange={handleInputChange}
									>
										{timezoneDBOptions()}
									</select>
								</div>

								{(data.partner_company === 'Rosco Vision WCS' || initialCompany.company_type === 'WCS')
									&& (
										<div className='toggles-container'>
											<div className={classnames('toggle-wrapper', { 'has-error': file_retention_time })}>
												<label>{t('labelFileRetention')}</label>
												<div>
													<NumericInput
														step={1}
														min={1}
														max={24}
														precision={0}
														maxLength={2}
														size={2}
														className="rosco-select"
														name="file_retention_time"
														value={data.file_retention_time}
														onChange={handleNumericChange}
													/>
												</div>
												{file_retention_time && <span className="help-block">{file_retention_time}</span>}
											</div>
											{userRole === 'system_admin'
												&& (
													<label className='toggle-wrapper'>
														<span className="toggle-left-title">{t('labelDownloadEventFiles')}</span>
														<div>
															<Toggle
																name="download_files"
																icons={false}
																checked={data.download_files === '2'}
																onChange={handleInputChange}
															/>
															<span>{t('labelAllFiles')}</span>
														</div>
													</label>
												)}
										</div>
									)}

								<div>
									<label htmlFor="active" className="control-label">{t('labelEnableDriverID')}</label>
									<select
										placeholder="select"
										className="rosco-select block"
										name="driver_recognition"
										id="driver_recognition"
										onChange={handleInputChange}
										value={data.driver_recognition}
									>
										<option value="manual">{t('optionManual')}</option>
										<option value="cardreader">{t('optionCardReader')}</option>
										<option value="faceid">{t('optionFaceID')}</option>
									</select>
								</div>

								{isInternalUser(userRole) &&
									<CustomAPN
										custom_apn={data.custom_apn || ''}
										onUpdateApn={(e, value) => setState({ ...state, data: { ...state.data, custom_apn: value } })}
									// setPropStates
									/>
									// <div className="optional">
									// 	<label className="control-label">{t('labelCustomAPN')} <span>(optional)</span></label>
									// 	<input type="text" name="custom_apn" className="vehicle-search" disabled onChange={(e) => { handleInputChange(e) }} value={data.custom_apn || ""} />
									// </div>
								}
							</div>

							<div className="two-column-grid with-margin margin-top">
								<div className="toggle-date-wrapper">
									<div className="toggle-label">
										<div>
											<label htmlFor="trial_company">{t('labelTrialCompany')}</label>
										</div>
										<label>
											<span className="toggle-left-title">{t('disable')}</span>
											<Toggle
												name="trial_status"
												checked={trial_status || false}
												onChange={handleInputChange}
											/>
											<span>{t('enable')}</span>
										</label>
									</div>
									{trial_status
										&& (
											<div>
												<label htmlFor="trial_date">{t('labelTrialEndDate')}</label>
												<Datetime
													id="trial_date"
													name="trial_date"
													inputProps={{ readOnly: true }}
													value={moment(trial_date).format('MM/DD/YYYY')}
													dateFormat="MM/DD/YYYY"
													timeFormat={false}
													closeOnSelect
													isValidDate={validDate}
													onChange={handleDateChanged}
												/>
											</div>
										)}
								</div>
								{trial_status
									&& (
										<div>
											<label htmlFor="trial_subscribers">{t('labelTrialSubscribers')}</label>
											<Select
												isMulti={true}
												name="trial_subscribers"
												options={trialAvailableUsers}
												id="trial_subscribers"
												onChange={(value) => setState({ ...state, data: { ...data, trial_subscribers: value.map(i => i = i.value).sort().join(",") } })}
												placeholder={t('placeholderSelectSubscriber')}
												className="rosco-select"
												value={trial_subscribers ? trial_subscribers.split(",").map(i => { if (!!i) return i = { value: i, label: i } }) : ""}
											/>
										</div>
									)}
							</div>

							<div className='two-column-grid with-margin margin-top'>
								<div className="toggles-container" style={{ fontSize: '14px' }}>
									<div className='toggle-wrapper'>
										<div>
											<label htmlFor="advanced_profile_settings" className="control-label">{t('labelAdvancedProfileSettings')}</label>
										</div>
										<label>
											<span className="toggle-left-title">{t('disable')}</span>
											<Toggle
												name="advanced_profile_settings"
												checked={data.advanced_profile_settings || false}
												onChange={handleInputChange}
											/>
											<span>{t('enable')}</span>
										</label>
									</div>
									<div className="toggle-wrapper">
										<div>
											<label htmlFor="active" className="control-label">{t('labelEnable/DisableCompany')}</label>
										</div>
										<label>
											<span className="toggle-left-title">{t('disable')}</span>
											<Toggle
												name="active"
												checked={data.active ? data.active : false}
												onChange={handleInputChange}
											/>
											<span>{t('enable')}</span>
										</label>
									</div>
									{userRole === 'system_admin'
										&& (
											<div className='toggle-wrapper'>
												<div>
													<label htmlFor="active" className="control-label">{t('labelAssetDeletionProtection')}</label>
												</div>
												<label>
													<span className="toggle-left-title">{t('disable')}</span>
													<Toggle
														name="asset_deletion_protection"
														checked={data.asset_deletion_protection ? data.asset_deletion_protection : false}
														onChange={handleInputChange}
													/>
													<span>{t('enable')}</span>
												</label>
											</div>
										)}
								</div>
								<div className="toggles-container" style={{ fontSize: '14px' }}>
									<div className='toggle-wrapper'>
										<div>
											<label htmlFor="advanced_profile_settings" className="control-label">{t('labelUsingRoscoSIM')}</label>
										</div>
										<label>
											<span className="toggle-left-title">{t('titleNo')}</span>
											<Toggle
												name="using_rosco_sim"
												checked={data.using_rosco_sim || false}
												onChange={handleInputChange}
											/>
											<span>{t('titleYes')}</span>
										</label>
									</div>
									<div className='toggle-wrapper'>
										<div>
											<label htmlFor="advanced_profile_settings" className="control-label">{t('labelOverrideOverageLimits')}</label>
										</div>
										<label>
											<span className="toggle-left-title">{t('disable')}</span>
											<Toggle
												name="override_overage_limits"
												checked={data.override_overage_limits || false}
												onChange={handleInputChange}
											/>
											<span>{t('enable')}</span>
										</label>
									</div>
									<div className='toggle-wrapper' />
								</div>
								{/** COMPANY DATA THRESHOLD */}
								{/* FWSD-6805 */}
								{/* {['system_admin'].includes(userRole) &&
									<CompanyDataThreshold
										data={data}
										errMessage={errMessage}
										setPropStates={setPropStates}
									/>
								} */}
							</div>
						</section>
					)}
				</form>
			</main>

			<Modal
				show={modal.show}
				onHide={closeModal}
				className="modal-lg-size"
			>
				<Modal.Header closeButton>
					<Modal.Title className={`${modal.type_action === 'delete_company' && 'delete-title'}`}>{modal.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{modal.type_action === 'delete_company' && <div className='modal-text'>{modal.content}</div>}
					{['delete_relation'].includes(modal.type_action) && <div>{modal.content}</div>}
				</Modal.Body>
				<Modal.Footer bsPrefix="default-modal-footer modal-footer">
					{modal.type_action === 'delete_company'
						&& (
							<CustomButton variant="delete" onClick={deleteCompanyModal}>{t('btnModalDelete')}</CustomButton>
						)}
					{modal.type_action === 'delete_relation' && externalType === 'Geotab'
						&& (
							<CustomButton variant="delete" onClick={deleteGeotabRelationModal}>{t('btnModalDelete')}</CustomButton>
						)}
					{modal.type_action === 'delete_relation' && externalType !== 'Geotab'
						&& (
							<CustomButton variant="delete" onClick={deleteExternalRelationModal}>{t('btnModalDelete')}</CustomButton>
						)}
				</Modal.Footer>
			</Modal>
		</div>
	)
}

CompanyEditContainer.propTypes = {
	user: PropTypes.objectOf(PropTypes.any).isRequired,
	company: PropTypes.objectOf(PropTypes.any).isRequired
}

export default connect(
	state => ({
		user: state.user.user,
		company: state.company.company
	}),
	dispatch => ({
		delCompany: (company) => {
			dispatch({ type: 'DEL_COMPANY_SUCCESS', payload: company });
		},
		addCompany: (company) => {
			dispatch({ type: 'ADD_COMPANY_SUCCESS', payload: company });
		},
		editCompany: data => dispatch({ type: 'EDIT_COMPANY', payload: data }),
		editCompanyCollection: data => dispatch({ type: 'EDIT_COMPANY_COLLECTION', payload: data }),
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		},
	}),
)(CompanyEditContainer);
