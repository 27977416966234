import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"

const CustomReactBootstrapSlider = (props) => {
    const mySliderRef = useRef(null)
    const nodeRef = useRef(null)

    useEffect(() => {
		const sliderAttributes = {
            ...props,
            tooltip: props.tooltip || "show",
        }

        mySliderRef.current = new Slider(nodeRef.current, sliderAttributes)

        if (props.change || props.handleChange) {
            const changeEvent = props.change || props.handleChange
            mySliderRef.current.on("change", e => {
                const fakeEvent = {
                    target: {
                        value: e.newValue
                    }
                };
                changeEvent(fakeEvent)
            })
        }

        if (props.slideStop) {
            mySliderRef.current.on("slideStop", e => {
                const fakeEvent = {
                    target: {
                        value: e
                    }
                };
                props.slideStop(fakeEvent)
            });
        }
        
        checkAndDoDisabled()

		return () => {
			mySliderRef.current.destroy()
		}
	}, [])

    useEffect(() => {
        updateSliderValues()
    })

    const checkAndDoDisabled = () => {
        const sliderEnable = props.disabled !== "disabled"
        const currentlyEnabled = mySliderRef.current.isEnabled()
        if (sliderEnable) {
            if (!currentlyEnabled) {
                mySliderRef.current.enable()
            }
        } else {
            if (currentlyEnabled) {
                mySliderRef.current.disable()
            }
        }
    }

    const updateSliderValues = () => {
        if (
            typeof props.min !== "undefined" &&
            (typeof mySliderRef.current.min !== "undefined" ||
                typeof mySliderRef.current.options.min !== "undefined")
        ) {
            mySliderRef.current.setAttribute("min", props.min);
        }
        if (
            typeof props.max !== "undefined" &&
            (typeof mySliderRef.current.max !== "undefined" ||
                typeof mySliderRef.current.options.max !== "undefined")
        ) {
            mySliderRef.current.setAttribute("max", props.max);
        }
        if (
            typeof props.step !== "undefined" &&
            (typeof mySliderRef.current.step !== "undefined" ||
                typeof mySliderRef.current.options.step !== "undefined")
        ) {
            mySliderRef.current.setAttribute("step", props.step);
        }
    
        mySliderRef.current.setValue(props.value)
        checkAndDoDisabled()
    }

    return <div ref={nodeRef} />
}

CustomReactBootstrapSlider.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.number.isRequired).isRequired
    ]).isRequired,
    disabled: PropTypes.string,
    tooltip: PropTypes.string,
    change: PropTypes.func,
    handleChange: PropTypes.func,
    slideStop: PropTypes.func,
    labelledby: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ])
};

export default CustomReactBootstrapSlider;
