// FWSD-6457
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import CustomButton from '../../component/CustomButton'

import { errorStatusCodes } from '../../component/helpers/helperVehicle'

import './CameraHealth.sass'

const ModalErrorStatus = (props) => {
	// console.log('PROPS ModalErrorStatus: ', props)
	const { t } = useTranslation('translation', { keyPrefix: 'manageVehiclesPage.cameraHealth.modalErrorStatus' });
	const { selectedAlert: { id, err_code, is_ack, device_id }, setCameraHealthAlert, onHide } = props

	const errorStatus = errorStatusCodes().find(({ code }) => code.split(',').includes(err_code)) || {}
	const { code, name, type, definitiona, resolutions } = errorStatus
	const resolutionsList = resolutions.trim().split('.').slice(0, -1)

	// flag of clicking on the Clear button of acknowledged critical alert
	const [clearCriticalConfirmation, setClearCriticalConfirmation] = useState(false)

	return (
		<Modal
			show
			onHide={onHide}
			dialogClassName="custom-request-modal modal-error-status"
		>
			<Modal.Header closeButton>
				<Modal.Title>
					{clearCriticalConfirmation
						? <>{t('clearCriticalIssue')}</>
						: (
							<>
								<CustomButton
									variant="primary"
									style={{ marginRight: '15px' }}
									size="hs"
									prefix={`no-cursor ${type}`}
								>
									{type === 'crit' ? 'CRITICAL' : 'WARN'}
								</CustomButton>
								{err_code} - {name}
							</>
						)
					}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{clearCriticalConfirmation
					? (
						<>
							<div className="mb-20">
								{t('clearingCriticalError')}
							</div>
							<div>
								{t('clearCriticalError')}
							</div>
						</>
					)
					: (
						<>
							<div className="mb-20">
								<label>{t('labelDescription')}</label>
								<div>{definitiona}</div>
							</div>

							<div className="mb-20">
								<label>{t('labelResolution')}</label>
								{
									resolutionsList.length > 0
										? <ul>
											{resolutionsList.map((item) => (
												<li>{item.trim()}.</li>
											))}
										</ul>
										: <div>{t('labelNoResolution')}</div>
								}
							</div>

							{type === 'crit'
								? <div>{t('criticalError')}</div>
								: <div>{t('warningError')}</div>
							}
						</>
					)
				}
			</Modal.Body>
			<Modal.Footer bsPrefix="default-modal-footer modal-footer">
				{
					clearCriticalConfirmation
						? (
							<>
								<CustomButton
									variant='primary-outline'
									onClick={() => { setCameraHealthAlert({ device_error_id: id, is_clr: true }) }}
								>
									{t('btnConfirmClear')}
								</CustomButton>
								<CustomButton
									variant='primary'
									onClick={() => setClearCriticalConfirmation(false)}
								>
									{t('btnBack')}
								</CustomButton>
							</>
						)
						: (
							is_ack
								?
								type === 'crit'
									? <CustomButton
										variant='primary'
										onClick={(e) => { e.target.blur(); /* removing focus on Confirm Clear button*/ setClearCriticalConfirmation(true); }}
									>
										{t('btnClear')}
									</CustomButton>
									: <div style={{ color: '#0FA739' }}>{t('textAcknowledged')}</div>
								: <CustomButton
									variant='primary'
									onClick={() => { setCameraHealthAlert({ err_code, device_id }) }}
								>
									{t('btnAcknowledge')}
								</CustomButton>
						)
				}
			</Modal.Footer>
		</Modal>
	)
}

ModalErrorStatus.propTypes = {
	selectedAlert: PropTypes.objectOf(PropTypes.any).isRequired,
	setCameraHealthAlert: PropTypes.func.isRequired,
	onHide: PropTypes.func.isRequired,
}

export default ModalErrorStatus;
