import React, { useState, useEffect } from 'react';
import { Link, browserHistory } from 'react-router';
import moment from 'moment';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

// import { getUserInfo } from '../../../action/ClientActions';
import { getEventById, getEventByTimestamp } from '../../../action/AlertActions';

const PattersEventContainer = (props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'patternsPage' });
	const { toggleLoader } = props;

	const [error, setError] = useState('');

	useEffect(() => {
		console.log('!!!componentDidMount PATTERN', props);
		toggleLoader(true);

		// getUserInfo().then((resUser, errUser)=>{
		// 	console.log('!getUserInfo PATTERN res', resUser, errUser);
		const { user, params, updateCompany } = props
		const data = {
			user_role: user.user.roles[0],
			// partner_name:   props.params.partner_name,
			// company_name:   props.params.company_name,
			// event_id:       props.params.event_id
			device_id: params.device_id,
			timestamp: params.timestamp,
		}
		// getEventById(data).then((resEvent, errEvent)=>{
		getEventByTimestamp(data)
			.then((resEvent, errEvent) => {
				console.log('!getEventById res', resEvent, errEvent);

				updateCompany({
					name: resEvent.data.response.event.company_name,
					partner: resEvent.data.response.event.partner_company,
					company_id: resEvent.data.response.event.company_id,
				});

				browserHistory.push({
					pathname: "/event",
					state: {
						event_device_id: resEvent.data.response.event.device_id, // 98293836, //row.device_id, 2018-08-03 06:55:19
						event_id: resEvent.data.response.event.id, // 22229, //row.event_id,
						event_ts: moment(resEvent.data.response.event.timestamp).utc().format('YYYY-MM-DD HH:mm:ss'), // "2018-08-01 17:54:30", //row.timestamp,
						// type: "tt_local" //(props.type=='storage_link') ? 'storage_local' : props.type,
					}
				});
			})
			.catch((error) => {
				toggleLoader(false);

				console.log('!getAlertsOfDevice error');
				console.log(error.response);
				console.log(error);

				let _error = JSON.stringify(error);
				if (error.response && error.response.data.response.error) {
					_error = error.response.data.response.error;
				}
				setError(_error);
			});
	}, []);

	return (
		<article>
			{error
				&& (
					<div>
						<h3>{t('error404')}</h3>
						<div>{error}</div>
						<div>
							{t('goBack')} <Link to="/" className="btn">{t('btnHome')}</Link>
						</div>
					</div>
				)}
		</article>
	)
}

export default connect(
	state => ({
		user: state.user,
		company: state.company
	}),
	dispatch => ({
		updateCompany: (company) => {
			dispatch({ type: 'GET_COMPANY_SUCCESS', payload: company });
		},
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(PattersEventContainer);
