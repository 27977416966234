import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { FaCheck } from 'react-icons/fa'
import { BsDot } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'

import CustomButton from '../../component/CustomButton'
import { filterIcon } from '../../component/helpers/iconHelpers'
import { getDeviceStatusName } from '../../component/helpers/helperVehicle'

import '../../component/FilterMenu.sass'

const TreeVehiclesFilter = (props) => {
    // console.log('PROPS TreeVehiclesFilter filters: ', props.filters)
    const { filters, setFiltersFleetPanel } = props
    const { t, i18n } = useTranslation('translation', { keyPrefix: 'homePage.treeVehiclesFilter' });
    const countSelectedGroups = Object.keys(filters.groups).reduce((sum, value) => (filters.groups[value] ? sum + 1 : sum), 0)
    const countSelectedStatuses = Object.keys(filters.statuses).reduce((sum, value) => (filters.statuses[value] ? sum + 1 : sum), 0)
    const showFilterActive = countSelectedGroups !== Object.keys(filters.groups).length || countSelectedStatuses !== Object.keys(filters.statuses).length

    const onSelectAllFiltersClick = (filterType) => {
        const newFilters = { ...filters }
        const countSelected = filterType === 'groups' ? countSelectedGroups : countSelectedStatuses
        if (countSelected === Object.keys(newFilters[filterType]).length) {
            for (let key in newFilters[filterType]) {
                newFilters[filterType][key] = false
            }
            setFiltersFleetPanel(newFilters)
            // getFleetEvents();
        } else {
            for (let key in newFilters[filterType]) {
                newFilters[filterType][key] = true
            }
            setFiltersFleetPanel(newFilters)
            // getFleetEvents();
        }
    }

    const onFilterItemClick = (filterType, filterItem) => {
        const newFilters = { ...filters }
        newFilters[filterType][filterItem] = !newFilters[filterType][filterItem]

        setFiltersFleetPanel(newFilters)
    }

    return (
        <Dropdown
            id="type-filter-btn"
            align="start"
            drop
        >
            <Dropdown.Toggle
                // nocaret
                bsPrefix="no-caret event-filter-toggle"
            >
                {filterIcon}
                {showFilterActive &&
                    <BsDot className='filter-dot' />
                }
            </Dropdown.Toggle>
            <Dropdown.Menu
                className="typeFilterMenu"
            >
                <h5 className="category-title">{t('groupsTitle')}</h5>
                <CustomButton
                    variant="link"
                    prefix="btn-select-all"
                    onClick={() => onSelectAllFiltersClick('groups')}
                >
                    {`${(countSelectedGroups === Object.keys(filters.groups).length ? t('unselect') : t('select')) + t('all')}`}
                </CustomButton>
                <ul className="filters-list">
                    {Object.keys(filters.groups).map((item) => (
                        <li
                            key={item}
                            title={item}
                            onClick={() => onFilterItemClick('groups', item)}
                        >
                            <span className="name">{item}</span>
                            {filters.groups[item] && <FaCheck className='flagSelected' />}
                        </li>
                    ))
                    }
                </ul>

                <h5 className="category-title">{t('statusTitle')}</h5>
                <CustomButton
                    variant="link"
                    prefix="btn-select-all"
                    onClick={() => onSelectAllFiltersClick('statuses')}
                >
                    {`${(countSelectedStatuses === Object.keys(filters.statuses).length ? t('unselect') : t('select')) + t('all')}`}
                </CustomButton>
                <ul className="filters-list">
                    {Object.keys(filters.statuses).map((item) => {
                        const title_status_name = i18n.t(`homePage.fleetStatuses.${item.replace(' ', '_')}`, item);
                        return (
                            <li
                                key={item}
                                title={title_status_name}
                                onClick={() => onFilterItemClick('statuses', item)}
                            >
                                {/* <span className="name">{ item.charAt(0).toUpperCase() + item.slice(1) }</span> */}
                                <span className="name">{title_status_name}</span>
                                {filters.statuses[item] && <FaCheck className='flagSelected' />}
                            </li>
                        )
                    })
                    }
                </ul>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default TreeVehiclesFilter;
