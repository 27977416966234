import React, { Component, useState } from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { useTranslation } from 'react-i18next'

import { renderCustomHeader, tableAdjustableColOptions } from '../../component/helpers/table'
import ModalDelete from '../../component/smart/modals/ModalDelete'
import CustomButton from '../../component/CustomButton'
import { eventIcons } from '../../component/helpers/iconHelpers'
import { apiCall } from '../../../js/action/RouterActions'

const RouterFirmwaresListBlock = (props) => {
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'modemsPage.routerFirmwaresListBlock' });
	const { firmwares, toggleLoader } = props;

	const [deleteModalShow, setDeleteModalShow] = useState(false);
	const [activeFirmware, setActiveFirmware] = useState(null);

	const deleteFormatter = (cell, row) => {
		return (
			<div className="actions-wrapper">
				<CustomButton variant="link" prefix="flexBtn" onClick={() => showDeleteModal(row)}>
					<i style={{ color: 'red' }} className="fas fa-times-circle" />
					{t('btnDelete')}
				</CustomButton>
			</div>
		)
	};

	const showDeleteModal = (row) => {
		setActiveFirmware(row);
		setDeleteModalShow(true);
	};

	const closeDeleteModal = () => {
		setDeleteModalShow(false);
	};

	const deleteRouterFirmware = () => {
		const { renderFirmwaresList } = props;
		const { version, url } = activeFirmware;

		toggleLoader(true);
		setDeleteModalShow(false);
		apiCall('DELETE', '/router/firmware', { version })
			.then((res, err) => {
				console.log('!deleteRouterFirmware res:', res, err)
				toggleLoader(false);
				toastr.success(t('routerFirmwareRemoved', { url: `"${url}"` }))
				setActiveFirmware(null);
				renderFirmwaresList();
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!deleteRouterFirmware error:', error.response);
				let errDescription = i18n.t('errorMessage.errDescription');
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription);
			})
	};

	return (
		<div>
			<div className="manage-users-table">
				<BootstrapTable
					data={firmwares}
					{...tableAdjustableColOptions}
				>
					<TableHeaderColumn dataField="version" isKey hidden>{t('headerFirmwarePortalID')}</TableHeaderColumn>

					<TableHeaderColumn width="20%" dataField="version" dataSort>
						{renderCustomHeader(0, t('headerFirmwareVersion'))}
					</TableHeaderColumn>
					<TableHeaderColumn width="30%" dataField="url">
						{renderCustomHeader(1, t('headerFirmwareURL'))}
					</TableHeaderColumn>
					<TableHeaderColumn width="40%" dataField="release_note" tdStyle={{ whiteSpace: 'normal' }}>
						{renderCustomHeader(2, t('headerReleaseNote'))}
					</TableHeaderColumn>
					<TableHeaderColumn width="10%" dataField="url" dataFormat={deleteFormatter}>
						{t('headerActions')}
					</TableHeaderColumn>
				</BootstrapTable>
			</div>

			{deleteModalShow
				&& (
					<ModalDelete
						content={`${t('modalDeleteContent')} "${activeFirmware.url}" ?`}
						closeModal={closeDeleteModal}
						deleteModal={deleteRouterFirmware}
					/>
				)
			}
		</div>
	)
}

export default connect(
	null,
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(RouterFirmwaresListBlock);
