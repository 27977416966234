// FWSD-3520
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'
// import { InfoWindow } from 'react-google-maps'
import moment from 'moment'
import { toastr } from 'react-redux-toastr'
import momentDurationFormatSetup from 'moment-duration-format'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { requestEventVideo } from '../../../action/AlertActions'
import { getDurationTimeFormat, getDurationUnitFormat } from '../../../component/helpers/helperTripsGrid'
import momenttz from '../../../component/helpers/momenttz'
import './MarkerInfoPopup.sass'

momentDurationFormatSetup(moment);

const TripSpeedEventPopup = (props) => {
	const { mapUI, user, event, position, selectedTrip, toggleLoader } = props;
	const { t } = useTranslation();

	useEffect(() => {
		// setTimeout(() => {
		// 	$('.gm-ui-hover-effect').remove()
		// }, 10)
		const bubbleElm = new H.ui.InfoBubble(position, { content: content });
		bubbleElm.addClass("infoTripBubble");
		bubbleElm.addClass("tripSpeedBubble");
		mapUI.addBubble(bubbleElm);

		return () => {
			mapUI.removeBubble(bubbleElm);
		}
	}, [])

	// 	useEffect(() => {
	// 		setShowAddress(false)
	// }, [lat, lng])

	globalThis.g_createCustomClip = (e) => {
		e.preventDefault()

		const { event, device_id } = props
		// FWSD-3813
		let startTs = momenttz(event.start_ts)
		let endTs = momenttz(event.end_ts)
		const diff = moment(event.end_ts).diff(moment(event.start_ts), 'seconds')
		if (diff < 10) {
			startTs = momenttz(event.start_ts).add(-10, 'seconds')
		} else if (diff > (5 * 60)) {
			endTs = momenttz(event.start_ts).add(5 * 60, 'seconds')
		}

		const params = {
			device_id,
			start_ts: startTs.format('YYYYMMDDHHmmss'),
			end_ts: endTs.format('YYYYMMDDHHmmss'),
		}
		toggleLoader(true);
		requestEventVideo(params)
			.then((res, err) => {
				toggleLoader(false);
				console.log('requestCustomVideo res: ', res);

				toastr.success('', t('toastMessage.toastrSuccessCVR'));
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('requestCustomVideo error:', error.response, error);
				let errDescription = t('errorMessage.errDescription');
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}

	const vehicleSpeed = () => {
		let speedRes = (selectedTrip.max_speed || event.speed) * 1
		if (user.speed_unit === 'mph') {
			speedRes /= 1.609344
		}

		return speedRes.toFixed(0)
	}

	const vehicleSpeedLimit = () => {
		if (!event.speed_limit) {
			return 'N/A'
		}

		let speedRes = (event.speed_limit || 0) * 1
		if (user.speed_unit === 'mph') {
			speedRes /= 1.609344
		}

		// FWSD-3893: tmp trick with rounding the Speed Limit value in icon multiple of 5
		return Math.ceil(speedRes.toFixed(0) / 5) * 5
		// return speedRes.toFixed(0)
	}

	// const position = event.positions[Math.round(event.positions.length / 2)]

	const content = `<div class="markerInfoPopup markerHistoryPopup">
		<div class="headerWrapper">
			<div class="header">
				<div class="row">
					<div class="menu col-md-12 col-sm-12 col-12">
						<div class="col-md-12 col-sm-12 col-12" style="display: inline-flex; alignItems: center; justifyContent: center; marginBottom: 10px; padding: 5px 8px 0px; color: #fff; minWidth: 120px">
							<div class="speed-limit-wrapper">
								<div class='speed-limit-label'>${t('homePage.tripSpeedEventPopup.textSpeed')}</div>
								<div class='speed-limit-label'>${t('homePage.tripSpeedEventPopup.textLimit')}</div>
								<div class='speed-limit-label speed'>${vehicleSpeedLimit()}</div>
							</div>
							<label class='speed-unit-label'>${user.speed_unit.toUpperCase()}</label>
						</div>
					</div>
				</div>
				<div class="addressWrapper">
					<div style={{ minHeight: '60px', paddingTop: '14px', fontSize: '16px', fontWeight: 'bold', textAlign: 'center' }}>
						<a href="/" class="link-request" onclick="g_createCustomClip()">${t('homePage.tripSpeedEventPopup.requestVideo')}</a>
					</div>
				</div>
			</div>
		</div>
	</div>`;

	return (<></>)
}

TripSpeedEventPopup.propTypes = {
	user: PropTypes.objectOf(PropTypes.any).isRequired,
	event: PropTypes.objectOf(PropTypes.any).isRequired,
	device_id: PropTypes.string.isRequired,
	position: PropTypes.objectOf(PropTypes.any).isRequired,
	selectedTrip: PropTypes.objectOf(PropTypes.any)
};

const mapStateToProps = ({ user, dashboardData }) => ({
	user: user.user,
	selectedTrip: dashboardData.selectedTrip
});

const mapDispatchToProps = (dispatch) => {
	return {
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TripSpeedEventPopup);
