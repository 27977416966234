import React, { useState } from 'react'
import PropTypes from 'prop-types'
// import { ReactBootstrapSlider } from 'react-bootstrap-slider'
import _, { find } from 'lodash';
import { Link } from 'react-router';
import Toggle from 'react-toggle';
import { useTranslation } from 'react-i18next'
// import Slider from 'rc-slider';
// import 'rc-slider/assets/index.css';

import SelectionEventVideoType from './SelectionEventVideoType'
import InfoPop from '../../common/InfoPop'
import CustomButton from '../../CustomButton'
import CustomReactBootstrapSlider from '../../CustomReactBootstrapSlider'
import LabelTag from '../../dump/LabelTag'
import ModalDelete from '../modals/ModalDelete'

import { isInternalUser } from '../../helpers/constants'

const FleetConfigurationEvent = (props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'manageVehiclesPage.fleetConfigurationContainer.editProfile.fleetConfigurationEvent' });
	const { settingsType, user, configuration, configurationdv6, event_types, webfleetDeviceId, setConfiguration, fillFormBoolean, fillFormInt, fillForm } = props

	const [showModalDMSSeatbeltToggle, setShowModalDMSSeatbeltToggle] = useState(false)

	const userRole = user.roles[0]
	// const userPermissions = user.permissions
	const isDV6 = settingsType === 'dv6'
	const isDV4 = settingsType !== 'dv6'
	const currentConfig = isDV6 ? configurationdv6 : configuration
	const Sensor4 = find(currentConfig.Event.Sensor, o => o.index === 4);
	// const Sensor8 = find(currentConfig.Event.Sensor, o => o.index === 8);
	// const edit_only = (userRole === 'sales_demo' || (userRole === 'custom_user' && !find(userPermissions, { perm_category_id: 4, a_edit: true })))
	// const isWebfleetMode = webfleetDeviceId.length > 0
	// FWSD-5156, access to change the DV6 ADAS settings for the users with partner role of the Whitelist Tenna LLC and Eagle Wireless partners
	// const hasPartnerAccess = [2171, 537].includes(user.company_id) && userRole === 'partner'
	// const hasPartnerAccess = [3].includes(user.company_id) && userRole === 'partner'

	const dv6ADASSettings = [
		{ name: t('nameForwardCollision'), event_setting: 'ForwardCollision', event_type_name: 'dv6_forward_collision', description: t('descriptionForwardCollision') },
		{ name: t('nameHeadwayMonitoring'), event_setting: 'Headway', event_type_name: 'dv6_headway_monitoring', description: t('descriptionHeadwayMonitoring') },
		{ name: t('nameTailgating'), event_setting: 'FollowingDistance', event_type_name: 'dv6_following_distance', description: t('descriptionTailgating') },
	]

	const dv6DMSSettings = [
		{ name: t('nameDistraction'), event_setting: 'Distraction', event_type_name: 'dv6_distraction', description: t('descriptionDistraction'), voice_prompt_setting: 'System.Chimes.Distraction' },
		{ name: t('nameYawning'), event_setting: 'Yawning', event_type_name: 'dv6_yawning', description: t('descriptionYawning'), voice_prompt_setting: 'System.Chimes.Yawning' },
		{ name: t('nameDozing'), event_setting: 'Dozing', event_type_name: 'dv6_drowsiness', description: t('descriptionDozing'), voice_prompt_setting: 'System.Chimes.Drowsiness' },
		{ name: t('namePhoneUse'), event_setting: 'PhoneUse', event_type_name: 'dv6_phone_use', description: t('descriptionPhoneUse'), voice_prompt_setting: 'System.Chimes.PhoneUse' },
		{ name: t('nameSmoking'), event_setting: 'Smoking', event_type_name: 'dv6_smoking', description: t('descriptionSmoking'), voice_prompt_setting: 'System.Chimes.Smoking' },
		// { name: t('nameSeatbelt'), event_setting: 'Seatbelt', event_type_name: 'dv6_seatbelt', description: t('descriptionSeatbelt'), voice_prompt_setting: 'System.Chimes.Seatbelt', isInternal: true },
		{ name: t('nameSeatbelt'), event_setting: 'Seatbelt', event_type_name: 'dv6_seatbelt', description: t('descriptionSeatbelt'), voice_prompt_setting: 'System.Chimes.Seatbelt' },
	]

	const dv6SensitivitySettings = [
		{ name: t('nameHighG-Force'), event_setting: 'Accelerometer', event_type_name: 'dv6_fatal_gsensor', description: t('descriptionHighG-Force'), sliderObj: { id: 1, min: 1.0, max: 4.0, step: 0.1, name: "Event.Accelerometer.FatalThreshold.X" } },
		{ name: t('nameHarshBraking'), event_setting: 'HarshBraking', event_type_name: 'dv6_harsh_braking', description: t('descriptionHarshBraking'), sliderObj: { id: 2, min: 7, max: 17, step: 0.1, name: "Event.HarshBraking.Threshold" }, sublabel: t('sublabelHarshBraking') },
		{ name: t('nameHarshAcceleration'), event_setting: 'HarshAccel', event_type_name: 'dv6_harsh_accel', description: t('descriptionHarshAcceleration'), sliderObj: { id: 3, min: 7, max: 17, step: 0.1, name: "Event.HarshAccel.Threshold" }, sublabel: t('sublabelHarshAcceleration') },
	]

	const dv4ADASSettings = [
		{ name: t('nameLaneDepartureWarning'), event_setting: 'LaneDeparture', description: t('descriptionLaneDepartureWarning') },
		{ name: t('nameForwardCollisionWarning'), event_setting: 'ForwardCollision', description: t('descriptionForwardCollisionWarning') },
		{ name: t('namePedestrianCollisionWarning'), event_setting: 'Pedestrian', description: t('descriptionPedestrianCollisionWarning') },
	]

	const dv4DMSSettings = [
		{ name: t('nameDistractionDv4'), event_setting: 'Distraction', event_setting: 'Distraction', event_type_name: 'dv4_distraction', description: t('descriptionDistractionDv4') },
		{ name: t('nameDrowsiness/LookingDown'), event_setting: 'Dozing', event_setting: 'Dozing', event_type_name: 'dv4_drowsiness', description: t('descriptionDrowsiness/LookingDown') },
		{ name: t('namePhoneUse/HandtoHead'), event_setting: 'PhoneUse', event_setting: 'PhoneUse', event_type_name: 'dv4_phone_use', description: t('descriptionPhoneUse/HandtoHead') },
	]

	const PotentialCh2Obstruction = [
		{ title: t('titleNoPrompt'), value: '0', description: t('descriptionNoPrompt') },
		{ title: t('titleOnePrompt'), value: '1', description: t('descriptionOnePrompt') },
		{ title: t('titleContinuousPrompt'), value: '2', description: t('descriptionContinuousPrompt') },
	]

	let unit = 'mph'
	if (currentConfig.System.SpeedUnit === 2) {
		unit = 'kph'
	}

	// console.log('PROPS FleetConfigurationEvent: ', props)
	// console.log('FleetConfigurationEvent currentConfig: ', currentConfig)
	// console.log('PROPS FleetConfigurationEvent configurationdv6: ', configurationdv6)
	// console.log('currentConfig: ', currentConfig)
	// console.log('currentConfig.Event.PanicButton.Enabled: ', currentConfig.Event.PanicButton.Enabled)

	const fillFormSensors = (e, idx) => {
		const { target } = e;
		const { name } = target;
		let value = target.value * 1;
		if (target.type === 'checkbox') {
			value = !!(target.checked);
		}
		else if (target.type === 'button') {
			value = target.value === 'true'
		}

		if (idx === 8) {
			currentConfig.Event.Sensor[4].SendToCloud = value;
			setConfiguration({ configurationdv6 })
		}

		currentConfig.Event.Sensor = currentConfig.Event.Sensor.map((sensor, sidx) => {
			if (idx === sensor.index) {
				sensor[name] = value;
				return sensor;
			}

			return sensor;
		});

		if (isDV6) {
			setConfiguration({ configurationdv6: currentConfig });
		}
		else {
			setConfiguration({ configuration: currentConfig });
		}

	}

	const getMinSpeedSlider = () => {
		const { configurationdv6 } = props
		let res = 32
		if (currentConfig.System.SpeedUnit === 1) {
			res = 20
		}
		return res
	}

	const getMaxSpeedSlider = () => {
		const { configurationdv6 } = props
		let res = 89
		if (currentConfig.System.SpeedUnit === 1) {
			res = 55
		}
		return res
	}

	const numberFormat = (argName, argValue = null) => {
		const { configurationdv6 } = props

		let res = configurationdv6.Event.Adas.Headway.SpeedThreshold
		if (currentConfig.System.SpeedUnit === 1) {
			res = Math.round((res / 1.609344) * 1e2) / 1e2
		}

		return +res.toFixed(0)
	}

	const onMinimumSpeedChange = (e, currentConfigArg) => {
		// debugger
		// FWSD-5160
		// let { configurationdv6, setConfiguration } = props
		let { setConfiguration } = props
		// let { value } = e.target
		let value = e * 1

		// const minSpeedValue = getMinSpeedSlider()
		// const maxSpeedValue = getMaxSpeedSlider()

		// if (value < minSpeedValue) {
		// 	value = minSpeedValue
		// }
		// else if (value > maxSpeedValue) {
		// 	value = maxSpeedValue
		// }
		// if (value < minSpeedValue) {
		// 	value = minSpeedValue
		// }
		// else if (value > maxSpeedValue) {
		// 	value = maxSpeedValue
		// }

		// if (configurationdv6.Event.Adas.Headway.SpeedUnit === 'mph') {
		// 	value = Math.round((value * 1.609344) * 1e2) / 1e2;
		// }

		// configurationdv6 = _.set(configurationdv6, 'Event.Adas.Headway.SpeedThreshold', value)
		// configurationdv6 = _.set(configurationdv6, 'Event.Adas.FollowingDistance.SpeedThreshold', value)
		const isMPH = currentConfig.System.SpeedUnit === 1;
		if (isMPH) value = Math.round((value * 1.609344) * 1e2) / 1e2;

		const obj = { target: { name: 'Event.Adas.Headway.SpeedUnit', value: isMPH ? "mph" : "kph" } };
		fillForm(obj, isDV6);
		let configurationdv6 = _.set(currentConfig, 'Event.Adas.Headway.SpeedThreshold', value)
		configurationdv6 = _.set(currentConfig, 'Event.Adas.FollowingDistance.SpeedThreshold', value)
		setConfiguration({ configurationdv6 })
	}

	const setEventTypes = (argTypeValue) => {
		setConfiguration({ event_types: { ...event_types, ...argTypeValue } })
	}

	const fillHighGForceFatalThreshold = (e) => {
		const value = e.target.value * 1

		currentConfig.Event.Accelerometer.FatalThreshold.X = value
		currentConfig.Event.Accelerometer.FatalThreshold.Y = value

		if (isDV6) {
			setConfiguration({ configurationdv6: currentConfig });
		}
		else {
			setConfiguration({ configuration: currentConfig });
		}
	}

	const eventSensitivityRender = (argObject) => {
		const { id, min, max, step, name } = argObject
		// FWSD-6898
		const tooltipFormatter = (value) => {
			let res = value

			if (id !== 1) {
				res = (value * 0.28 / 9.81).toFixed(2)
			}

			return `${Math.abs(res)} Gs`
		}

		return (
			<div className="toggle-wrapper slider-wrapper">
				<label className="settings-title">{t('titleSensitivity')}</label>
				<div className="backlight-slider">
					<CustomReactBootstrapSlider
						name={name}
						max={max}
						min={min}
						step={step}
						tooltip="show"
						reversed={true}
						value={_.get(currentConfig, name, '')}
						formatter={tooltipFormatter}
						change={(e) => id === 1 ? fillHighGForceFatalThreshold(e) : fillFormInt(e, isDV6, name)} // FWSD-6903
					// disabled="disabled"
					// ticks={[60, 70, 80, 90, 100]}
					/>
					<InfoPop
						title={t('infoPopTitle')}
						body={t('infoPopBody')}
						placement='top'
					>
						<div className="temp-bar-container slider slider-horizontal mt-10">
							<div className="cold temp-bar">{t('tempBarLessSensitive')}</div>
							<div className="med temp-bar" />
							<div className="hot temp-bar">{t('tempBarMoreSensitive')}</div>
						</div>
					</InfoPop>
				</div>
			</div>
		)
	}

	const DMSSettingRender = (dmsSetting) => {
		const { name, event_setting, event_type_name, isInternal, description, voice_prompt_setting } = dmsSetting
		// FWSD-7044
		const onToggleClick = (e) => {
			if (e.target.name === 'Event.Dms.Seatbelt.Enabled' && e.target.checked) {
				setShowModalDMSSeatbeltToggle({
					function: () => {
						fillFormBoolean({ target: { name: e.target.name, checked: true } }, isDV6)
						setShowModalDMSSeatbeltToggle(false)
					}
				})
			}
			// FWSD-7081
			else if (e.target.name === 'Event.Dms.Dozing.Enabled') {
				fillFormBoolean({ target: { name: 'Event.Dms.Dozing.Enabled', checked: e.target.checked } }, isDV6)
				fillFormBoolean({ target: { name: 'Event.Dms.HeadDown.Enabled', checked: e.target.checked } }, isDV6)
			}
			else {
				fillFormBoolean(e, isDV6)
			}
		}

		// FWSD-7044
		// const fillForm = (e, dv6 = null, argName = null, argValue = null) => {
		const onSensitivityClick = (e, ...arg) => {
			// console.log('arguments: ', arg)
			if (e.target.name === 'Event.Dms.Dozing.Sensitivity') {
				props.fillForm({ target: { name: 'Event.Dms.Dozing.Sensitivity', checked: e.target.checked } }, ...arg)
				props.fillForm({ target: { name: 'Event.Dms.HeadDown.Sensitivity', checked: e.target.checked } }, ...arg)
			}
			else {
				props.fillForm(e, ...arg)
			}
		}

		if (isInternal && !isInternalUser(userRole)) {
			return <div key="empty" />
		}
		else {
			return (
				<div key={name} className="settings-card">
					<div className="card-header">
						<div>
							DMS - {name}
							{isDV4 && <span className="subtitle">{t('subtitleRequires')}</span>}
							{isInternal && <LabelTag text={t('textInternal')} tag_type='ml-10 internal' />}
						</div>
						<Toggle
							name={`Event.Dms.${event_setting}.Enabled`}
							// checked={currentConfig.Event.Adas.ForwardCollision.Enabled}
							checked={_.get(currentConfig, `Event.Dms.${event_setting}.Enabled`)}
							icons={false}
							// onChange={(e) => fillFormBoolean(e, isDV6)}
							onChange={onToggleClick}
						/>
					</div>
					<div className="card-body">
						<p>{description}</p>
						{/* anumation of card-body content displaing with delay 0.5s */}
						<div className={`${!currentConfig.Event.Dms[event_setting].Enabled ? 'hide' : ''}`}>
							<SelectionEventVideoType
								name={event_type_name}
								value={event_types[event_type_name]}
								setEventTypes={setEventTypes}
								labelPosition="top"
							/>

							{isDV6 &&
								<>
									<div className="toggle-wrapper">
										<label className="settings-title">{t('titleEventVoicePrompt')}</label>
										<Toggle
											name={voice_prompt_setting}
											checked={_.get(currentConfig, voice_prompt_setting, '')}
											icons={false}
											onChange={(e) => fillFormBoolean(e, isDV6)}
										/>
									</div>

									<div className="toggle-wrapper button-group">
										<label className="settings-title">{t('settingsTitleSensitivity')}</label>
										<div className="button-group">
											<CustomButton
												variant="toggle-group"
												active={(+_.get(currentConfig, `Event.Dms.${event_setting}.Sensitivity`) === -1)}
												name={`Event.Dms.${event_setting}.Sensitivity`}
												// onClick={(e) => props.fillForm(e, true, '', -1)}
												onClick={(e) => onSensitivityClick(e, true, '', -1)}
											>
												{t('btnLessSensitive')}
											</CustomButton>
											<CustomButton
												variant="toggle-group"
												active={(+_.get(currentConfig, `Event.Dms.${event_setting}.Sensitivity`) === 0)}
												name={`Event.Dms.${event_setting}.Sensitivity`}
												// onClick={(e) => props.fillForm(e, true, '', 0)}
												onClick={(e) => onSensitivityClick(e, true, '', 0)}
											>
												{t('btnDefault')}
											</CustomButton>
											<CustomButton
												variant="toggle-group"
												active={(+_.get(currentConfig, `Event.Dms.${event_setting}.Sensitivity`) === 1)}
												name={`Event.Dms.${event_setting}.Sensitivity`}
												// onClick={(e) => props.fillForm(e, true, '', 1)}
												onClick={(e) => onSensitivityClick(e, true, '', 1)}
											>
												{t('btnMoreSensitive')}
											</CustomButton>
										</div>
									</div>
								</>
							}
						</div>
					</div>
				</div>
			)
		}
	}

	const ADASSettingRender = (adasSetting) => {
		const { name, event_setting, event_type_name, description } = adasSetting
		// debugger
		return (
			<div key={name} className="settings-card">
				<div className="card-header">
					<div>
						ADAS - {name}
						{isDV4 && <span className="subtitle">{t('adasSubtitleRequires')}</span>}
					</div>
					<Toggle
						name={`Event.Adas.${event_setting}.Enabled`}
						// checked={_.get(currentConfig, `Event.Adas.${name}.Enabled`)}
						checked={currentConfig.Event.Adas[event_setting]?.Enabled}
						icons={false}
						onChange={(e) => fillFormBoolean(e, isDV6)}
					/>
				</div>
				<div className="card-body">
					<p>{description}</p>
					{/* anumation of card-body content displaing with delay 0.5s */}
					<div className={`${!currentConfig.Event.Adas[event_setting]?.Enabled ? 'hide' : ''}`}>
						{isDV6 &&
							<>
								<SelectionEventVideoType
									name={event_type_name}
									value={event_types[event_type_name]}
									setEventTypes={setEventTypes}
								/>

								<div className="toggle-wrapper">
									<label className="settings-title">{t('settingsTitleEventVoicePrompt')}</label>
									<Toggle
										// name=System.Chimes.FollowingDistance"
										name={`System.Chimes.${event_setting}`}
										// checked={currentConfig.System.Chimes.FollowingDistance}
										checked={currentConfig.System.Chimes[event_setting]}
										icons={false}
										onChange={(e) => fillFormBoolean(e, isDV6)}
									/>
								</div>
							</>
						}
					</div>
				</div>
			</div>
		)
	}

	const dv6SensitivitySettingRender = (sensitivitySetting) => {
		const { name, event_setting, event_type_name, description, sliderObj, sublabel } = sensitivitySetting
		return (
			<div key={name} className="settings-card">
				<div className="card-header">
					<div>
						{name}
						{sublabel && <span className="sublabel">{sublabel}</span>}
					</div>

					<Toggle
						name={`Event.${event_setting}.Enabled`}
						checked={currentConfig.Event[event_setting].Enabled}
						icons={false}
						onChange={(e) => fillFormBoolean(e, isDV6)}
					/>
				</div>
				<div className="card-body">
					<p>{description}</p>
					{/* animation of card-body content displaing with delay 0.5s */}
					<div className={`${!currentConfig.Event[event_setting].Enabled ? 'hide' : ''}`}>
						{/* Upload Type */}
						<SelectionEventVideoType
							name={event_type_name}
							value={event_types[event_type_name]}
							setEventTypes={setEventTypes}
						/>
						{/* Event Chime */}
						<div className="toggle-wrapper">
							<label className="settings-title">{event_setting === 'Accelerometer' ? t('titleEventChime') : t('titleEventVoicePrompt')}</label>
							
							<Toggle
								name={`System.Chimes.${event_setting}`}
								checked={currentConfig.System.Chimes[event_setting]}
								icons={false}
								onChange={(e) => fillFormBoolean(e, isDV6)}
							/>
						</div>
						{/* Sensitivity */}
						{eventSensitivityRender(sliderObj)}
					</div>
				</div>
			</div>
		)
	}

	return (
		<main className="event-settings main-content-block">
			<h3 className="category-title">{t('titleEventSettings')}</h3>
			<div className="category-subheader">
				{t('subheaderEventSettings')}
			</div>

			<div className="settings-card">
				<div className="card-header">
					{t('headerDriverEvent')}
					<Toggle
						name={isDV6 ? "Enabled" : "Event.PanicButton.Enabled"}
						checked={isDV6 ? Sensor4.Enabled : currentConfig.Event.PanicButton?.Enabled}
						icons={false}
						onChange={(e) => { isDV6 ? fillFormSensors(e, 4) : fillFormBoolean(e, isDV6) }}
					/>
				</div>
				<div className="card-body">
					<p>{t('bodyDriverEvent')}</p>
					{/* anumation of card-body content displaing with delay 0.5s */}
					<div className={`${(isDV6 && !Sensor4.Enabled) || (isDV4 && !currentConfig.Event.PanicButton?.Enabled) ? 'hide' : ''}`}>
						<SelectionEventVideoType
							name={isDV6 ? "dv6_driver" : "dv4_driver"}
							value={isDV6 ? event_types.dv6_driver : event_types.dv4_driver}
							setEventTypes={setEventTypes}
						/>
					</div>
				</div>
			</div>

			{isDV4 &&
				<div className="settings-card">
					<div className="card-header">
						{t('headerExternalDriverEvent')}
						<Toggle
							name="Enabled"
							checked={Sensor4.Enabled}
							icons={false}
							onChange={(e) => fillFormSensors(e, 4)}
						/>
					</div>
					<div className="card-body">
						<p>{t('bodyExternalDriverEvent')}</p>
						{/* anumation of card-body content displaing with delay 0.5s */}
						<div className={`${!Sensor4.Enabled ? 'hide' : ''}`}>
							<SelectionEventVideoType
								name="dv4_ext_driver"
								value={event_types.dv4_ext_driver}
								setEventTypes={setEventTypes}
							/>
						</div>
					</div>
				</div>
			}

			<div className="settings-card">
				<div className="card-header">
					{t('headerMaxSpeed')}
					<Toggle
						name="Event.HighSpeed.Enabled"
						checked={currentConfig.Event.HighSpeed.Enabled}
						icons={false}
						onChange={(e) => fillFormBoolean(e, isDV6)}
					/>
				</div>
				<div className="card-body">
					<p>{t('bodyMaxSpeed')}</p>
					{/* anumation of card-body content displaing with delay 0.5s */}
					<div className={`${!currentConfig.Event.HighSpeed.Enabled ? 'hide' : ''}`}>
						<SelectionEventVideoType
							name={isDV6 ? "dv6_high_speed" : "dv4_high_speed"}
							value={isDV6 ? event_types.dv6_high_speed : event_types.dv4_high_speed}
							setEventTypes={setEventTypes}
						/>

						<div className="toggle-wrapper button-group">
							<label className="settings-title">{t('titleMaxSpeedThreshold')}</label>
							<div>
								<input
									type="number"
									step="1"
									min="0"
									max="1000"
									maxLength="4"
									value={(currentConfig.System.SpeedUnit === 2) ? currentConfig.Event.HighSpeed.Threshold : (currentConfig.Event.HighSpeed.Threshold / 1.609344).toFixed(0)}
									className="vehicle-search number-input mr-10 pt-5"
									name="Event.HighSpeed.Threshold"
									onChange={(e) => fillForm(e, isDV6)}
								/>
								<label style={{ marginLeft: '6px' }}>{currentConfig.System.SpeedUnit === 2 ? 'KPH' : 'MPH'}</label>
							</div>
						</div>

						{isDV6 &&
							<div className="toggle-wrapper button-group">
								<label className="settings-title">{t('settingsTitleEventChime')}</label>
								<Toggle
									name="System.Chimes.HighSpeed"
									checked={currentConfig.System.Chimes.HighSpeed}
									icons={false}
									onChange={(e) => fillFormBoolean(e, isDV6)}
								/>
							</div>
						}
					</div>
				</div>
			</div>

			<div className="settings-card">
				<div className="card-header">
					{t('headerSpeeding')}
					<LabelTag text={t('textUnderImprovement')} tag_type='beta' />
				</div>
				<div className="card-body">
					<p>{t('bodySpeeding')}</p>
					<ul>
						<li>{t('textLowSpeeding')}</li>
						<li>{t('textModerateSpeeding')}</li>
						<li>{t('textHighSpeeding')}</li>
						<li>{t('textSevereSpeeding')}</li>
					</ul>
					<p>
						{t('textSpeeding')} <Link to='/account/settings'>{t('linkSpeeding')}</Link>.
					</p>
				</div>
			</div>

			{isDV6 ? (
				<>
					{/* DV6 Sensitivity  */}
					{
						dv6SensitivitySettings.map((item) => dv6SensitivitySettingRender(item))
					}

					{/* DV6 ADAS  */}
					{
						dv6ADASSettings.map((item) => ADASSettingRender(item))
					}

					<div className="settings-card">
						<div className="card-header">
							{t('headerADAS')}
						</div>
						<div className="card-body">
							<p>{t('bodyADAS')}</p>
							<div className="toggle-wrapper slider-wrapper block">
								<label className="settings-title mb-10">{t('titleFollowingDistanceTime')}</label>
								<div className="toggle-wrapper slider-wrapper p-0 no-border">
									<div className="backlight-slider mr-20">
										<CustomReactBootstrapSlider
											// name="Event.Adas.Headway.Ttc.TriggerThreshold"
											max={3.0}
											min={0.1}
											step={0.1}
											tooltip="show"
											value={+currentConfig.Event.Adas.Headway.Ttc.TriggerThreshold}
											change={(e) => fillFormInt(e, isDV6, 'Event.Adas.Headway.Ttc.TriggerThreshold')}
										/>
										{/* <Slider
										// name="Event.Adas.Headway.Ttc.TriggerThreshold"
										max={3.0}
										min={0.1}
										step={0.1}
										value={+currentConfig.Event.Adas.Headway.Ttc.TriggerThreshold}
										onChange={(e) => fillFormInt({target:{name:"Event.Adas.Headway.Ttc.TriggerThreshold", value: e}}, isDV6, 'Event.Adas.Headway.Ttc.TriggerThreshold')}
									/> */}
										<InfoPop
											title={t('infoPopTitle')}
											body={t('infoPopBody')}
											placement='top'
										>
											<div className="temp-bar-container slider slider-horizontal mt-10">
												<div className="cold temp-bar">{t('tempBarLessSensitive')}</div>
												<div className="med temp-bar" />
												<div className="hot temp-bar">{t('tempBarMoreSensitive')}</div>
											</div>
										</InfoPop>
									</div>
									<div>
										<input
											type="number"
											step="0.1"
											min="0.1"
											max="3.0"
											maxLength="3"
											name="Event.Adas.Headway.Ttc.TriggerThreshold"
											value={currentConfig.Event.Adas.Headway.Ttc.TriggerThreshold}
											className="vehicle-search number-input mr-10 pt-5"
											onChange={(e) => fillFormInt(e, isDV6)}
										/>
										<label style={{ marginLeft: '6px' }}>{t('textSeconds')}</label>
									</div>
								</div>
							</div>

							<div className="toggle-wrapper slider-wrapper block">
								<label className="settings-title mb-10">{t('titleMinimumSpeed')}</label>
								<div className="toggle-wrapper slider-wrapper p-0 no-border">
									<div className="slider-wrapper header-slider">
										<div className="slider-minmax-value min-value">
											{`${getMinSpeedSlider()} ${currentConfig.System.SpeedUnit === 1 ? 'MPH' : 'KPH'}`}
										</div>
										<CustomReactBootstrapSlider
											min={getMinSpeedSlider()}
											max={getMaxSpeedSlider()}
											step={1}
											tooltip="show"
											value={numberFormat()}
											change={(e) => onMinimumSpeedChange(e.target.value)}
										/>
										{/* <Slider
										// name="Event.Adas.Headway.SpeedThreshold"
										min={getMinSpeedSlider()}
										max={getMaxSpeedSlider()}
										step={1}
										value={numberFormat()}
										onChange={onMinimumSpeedChange}
									/> */}
										<div className="slider-minmax-value max-value">
											{`${getMaxSpeedSlider()} ${currentConfig.System.SpeedUnit === 1 ? 'MPH' : 'KPH'}`}
										</div>
									</div>
									<div>
										<input
											type="number"
											step="1"
											min={getMinSpeedSlider()}
											max={getMaxSpeedSlider()}
											maxLength="3"
											name="Event.Adas.Headway.Ttc.TriggerThreshold"
											value={numberFormat()}
											className="vehicle-search number-input mr-10 pt-5"
											onChange={(e) => onMinimumSpeedChange(e.target.value)}
										/>
										<label style={{ marginLeft: '6px' }}>{currentConfig.System.SpeedUnit === 1 ? 'MPH' : 'KPH'}</label>
									</div>
									{/* <div className="toggle-buttons button-group">
									<CustomButton
										variant='toggle'
										active={currentConfig.Event.Adas.Headway.SpeedUnit === 'mph'}
										value="mph"
										name="Event.Adas.Headway.SpeedUnit"
										onClick={(e) => fillForm(e, isDV6)}
									>
										MPH
									</CustomButton>
									<CustomButton
										variant='toggle'
										active={currentConfig.Event.Adas.Headway.SpeedUnit === 'kph'}
										value="kph"
										name="Event.Adas.Headway.SpeedUnit"
										onClick={(e) => fillForm(e, isDV6)}
									>
										KPH
									</CustomButton>
								</div> */}
								</div>
							</div>
						</div>
					</div>

					{/* DV6 DMS  */}
					{
						dv6DMSSettings.map((item) => DMSSettingRender(item))
					}

					<div className="settings-card">
						<div className="card-header">
							{t('cardHeaderPotentialCh2')}
							<Toggle
								name="Event.Dms.CameraObstruction.Enabled"
								checked={currentConfig.Event.Dms.CameraObstruction.Enabled}
								icons={false}
								onChange={(e) => fillFormBoolean(e, isDV6)}
							/>
						</div>
						<div className="card-body">
							<p>{t('cardBodyPotentialCh2')}</p>
							{/* anumation of card-body content displaing with delay 0.5s */}
							<div className={`${!currentConfig.Event.Dms.CameraObstruction.Enabled ? 'hide' : ''}`}>
								<ul>
									<li><b>{t('titleNoPrompt')}</b> - {t('descriptionNoPrompt')}</li>
									<li><b>{t('titleOnePrompt')}</b> - {t('descriptionOnePrompt')}</li>
									<li><b>{t('titleContinuousPrompt')}</b> - {t('descriptionContinuousPrompt')}</li>
								</ul>

								<div className="toggle-wrapper button-group">
									<label className="settings-title">{t('titleVoicePrompt')}</label>
									<select
										placeholder="select"
										className="form-control rosco-select"
										name="System.Chimes.CameraObstruction.Enabled"
										onChange={(e) => fillFormInt(e, isDV6)}
										value={currentConfig.System.Chimes.CameraObstruction.Enabled}
									>
										{
											PotentialCh2Obstruction.map(item => (
												<option key={item.value} value={item.value}>{item.title}</option>
											))
										}
									</select>
								</div>

								<SelectionEventVideoType
									name="dv6_camera_block"
									value={event_types.dv6_camera_block}
									setEventTypes={setEventTypes}
								/>
							</div>
						</div>
					</div>
				</>
			) : (
				<>
					{/* DV4 ADAS  */}
					{
						dv4ADASSettings.map((item) => ADASSettingRender(item))
					}

					<div className="settings-card">
						<div className="card-header">
							<div>
								{t('titleSpeedWarning')}
								<span className="subtitle">{t('subtitleRequiresMobileye')}</span>
							</div>
							<select
								placeholder="select"
								className="rosco-select"
								name="Event.Adas.SpeedThreshold"
								onChange={(e) => fillFormInt(e, isDV6)}
								value={currentConfig.Event.Adas.SpeedThreshold}
							>
								<option value="0">{t('textLevel')} 0 +[0 {unit}]</option>
								<option value="1">{t('textLevel')} 1 +[0-5 {unit}]</option>
								<option value="2">{t('textLevel')} 2 +[5-10 {unit}]</option>
								<option value="3">{t('textLevel')} 3 +[10-15 {unit}]</option>
								<option value="4">{t('textLevel')} 4 +[15-20 {unit}]</option>
								<option value="5">{t('textLevel')} 5 +[20-25 {unit}]</option>
								<option value="6">{t('textLevel')} 6 +[25-30 {unit}]</option>
								<option value="7">{t('textLevel')} 7 +[30-35 {unit}]</option>
								<option value="8">{t('textLevel')} 8 +[35 {unit}]</option>
							</select>
						</div>
						<div className="card-body">
							<p>{t('bodySpeedWarning')}</p>
						</div>
					</div>

					{/* DV4 DMS  */}
					{
						dv4DMSSettings.map((item) => DMSSettingRender(item))
					}
				</>
			)}

			{showModalDMSSeatbeltToggle &&
				<ModalDelete
					title={t('modalDeleteTitle')}
					content={t('modalDeleteContent')}
					showClose
					deleteBtnName={t('deleteBtnName')}
					closeBtnName={t('closeBtnName')}
					styleView="primary"
					backdropClassName="upper"
					closeModal={() => { setShowModalDMSSeatbeltToggle(false) }}
					deleteModal={showModalDMSSeatbeltToggle.function}
				/>
			}
		</main>
	)
}

FleetConfigurationEvent.propTypes = {
	webfleetDeviceId: PropTypes.string,
}

FleetConfigurationEvent.defaultProps = {
	webfleetDeviceId: '',
}

export default FleetConfigurationEvent;
