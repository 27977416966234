import React from 'react'
import { Link, browserHistory } from 'react-router'
import { BiTime } from 'react-icons/bi'
import { useTranslation } from 'react-i18next';

import CustomButton from '../CustomButton';
import { apiCall } from '../../action/RouterActions'
import momenttz from '../helpers/momenttz'

import './HeaderNav.sass'

const BellNotificationNode = (props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'components.bellNotificationNode' });
	const { id, notification, createtime, type, removeNotification, setDashboardRedirect } = props

	const onCloseClick = (e) => {
		removeNotification(e, id)
	}

	const onCompletedClick = (e) => {
		removeNotification(e, id);
		apiCall('POST', '/user/notification/reformat/complete')
			.then((res, err) => {
				console.log('!onCompletedClick res:', res, err)
			})
			.catch((error) => {
				console.log('!onCompletedClick error:', error.response)
			})
	}

	const onOpenLSClick = (e) => {
		// console.log('onOpenLSClick props: ', props)
		// e.preventDefault()

		setDashboardRedirect({
			type: 'redirectFromBellNotificationsOpenLS',
			path: location.pathname,
			device_id: props.device_id,
			company_id: props.company_id,
			company_name: props.company_name,
			partner_id: props.partner_id,
			partner_name: props.partner_name,
		})

		// browserHistory.push({
		// 	pathname: '/',
		// 	state: {
		// 		device_id: props.device_id
		// 	},
		// });
	}

	const renderMessage = () => {
		const res = []

		res.push(<span key={1} className="item-info" dangerouslySetInnerHTML={{ __html: notification }} />)

		if (type === 'session') {
			res.push(<Link to="/" key={2} className="ml-5" onClick={onOpenLSClick}>{t('linkOpenLiveStream')}</Link>)
		}

		if  (type === 'sdformat') {
			res.push(<button key={3} className="btn-completed" onClick={onCompletedClick}>{t('btnCompleted')}</button>)
		}

		return <p>{res}</p>
	}

	return (
		<li role="presentation">
			<div className="notification-item">
				<CustomButton className="close-button" size="icon" prefix="transparent close" onClick={onCloseClick}>
					<span aria-hidden="true">×</span>
					<span className="sr-only">{t('btnClose')}</span>
				</CustomButton>
				{renderMessage()}					
				<p className="item-time">
					<BiTime />
					{` ${momenttz(createtime).format('MM/DD/YYYY h:mm:ss A')}`}
				</p>
			</div>
		</li>
	)
}

export default BellNotificationNode;
