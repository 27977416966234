import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { useTranslation } from 'react-i18next'

import CustomButton from '../CustomButton'
import ModalFeedbackOptions from './ModalFeedbackOptions'
import ModalDelete from '../smart/modals/ModalDelete'
import { eventIcons } from '../helpers/iconHelpers'
import { isCustomUserPermissions } from '../helpers/helperUsers'
import { apiCallGet, apiCall } from '../../action/RouterActions'

const EventFeedback = (props) => {
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'components.eventFeedback' });
	// console.log('PROPS EventFeedback: ', props)
	const { event, getFleetEvents, user, toggleLoader } = props;

	const [feedback, setFeedback] = useState({})
	const [showFeedbackOptions, setShowFeedbackOptions] = useState(false)
	const [showThankYouModal, setShowThankYouModal] = useState(false)
	const [showRemoveFeedback, setShowRemoveFeedback] = useState(false)
	const { has_feedback, updated_user_id } = feedback
	const userRole = user.roles[0]
	const userPermissions = user.permissions

	useEffect(() => {
		getEventFeedback()
		// return () => {
		// 	mySliderRef.current.destroy()
		// }
	}, [])

	const getEventFeedback = () => {
		// toggleLoader(true)
		apiCallGet('/events/{event_id}/feedback', { event_id: event.id })
			.then((res, err) => {
				const { feedback } = res.data.response
				setFeedback(feedback || {})
				// toggleLoader(false)
			})
			.catch((error) => {
				console.log('!getEventFeedback error:', error.response, error)
				// toggleLoader(false)
				let errDescription = i18n.t('errorMessage.errDescription');
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription);
			})
	}

	const onFeedbackClick = () => {
		if (has_feedback) {
			setShowRemoveFeedback(true)
		}
		else {
			setShowFeedbackOptions(true)
		}
	}

	const addFeedback = () => {
		setShowThankYouModal(true)
		getEventFeedback()
		if (getFleetEvents && typeof getFleetEvents === 'function') {
			getFleetEvents()
		}
	}

	const removeFeedback = (e) => {
		toggleLoader(true)
		const params = {
			event_id: event.id,
			selections: '',
			comment: '',
			has_feedback: false,
		}

		apiCall('POST', '/events/{event_id}/feedback', params)
			.then((res, err) => {
				toggleLoader(false)
				getEventFeedback()
				if (getFleetEvents && typeof getFleetEvents === 'function') {
					getFleetEvents()
				}
				setShowRemoveFeedback(false)
			})
			.catch((error) => {
				console.log('!POSR Feedback error:', error.response, error)
				toggleLoader(false)
				let errDescription = i18n.t('errorMessage.errDescription');
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription);
			})
	}

	const isFeedbackDisabled = () => {
		const canFeedbackAdd = ['system_admin', 'customer_service', 'sales_demo', 'partner'].includes(userRole)
			|| (['custom_user', 'custom_partner'].includes(userRole) && isCustomUserPermissions(userRole, userPermissions, 'event_feedback_add'))
		const canFeedbackEdit = ['system_admin', 'customer_service', 'sales_demo'].includes(userRole)
			|| updated_user_id === user.id
			|| (['custom_user', 'custom_partner'].includes(userRole) && isCustomUserPermissions(userRole, userPermissions, 'event_feedback_edit'))

		if (
			has_feedback && canFeedbackEdit
			|| !has_feedback && canFeedbackAdd
		) {
			return false
		}

		return true
	}

	return (
		<>
			<CustomButton
				variant='empty'
				size='icon'
				prefix='event-feedback-button'
				active={has_feedback || false}
				title={`${has_feedback ? t('remove') : t('add')} ${t('feedback')}`}
				disabled={isFeedbackDisabled()}
				onClick={onFeedbackClick}
			>
				{eventIcons.eventFeedbackIcon}
			</CustomButton>

			{showFeedbackOptions &&
				<ModalFeedbackOptions
					event={event}
					feedback={feedback}
					toggleLoader={toggleLoader}
					onSaveFeedback={addFeedback}
					onHide={() => setShowFeedbackOptions(false)}
				/>
			}

			{showThankYouModal &&
				<ModalDelete
					title={t('thankYouModalTitle')}
					content={t('thankYouModalContent')}
					styleView="primary"
					backdropClassName="upper"
					dialogClassName="event-feedback-thankyou"
					deleteBtnName={t('thankYouModalDeleteBtnName')}
					deleteModal={() => setShowThankYouModal(false)}
				/>
			}

			{showRemoveFeedback &&
				<ModalDelete
					title={t('removeFeedbackTitle')}
					content={t('removeFeedbackContent')}
					styleView="delete"
					backdropClassName="upper"
					dialogClassName="event-feedback-thankyou"
					showClose
					closeBtnName={t('removeFeedbackCloseBtnName')}
					deleteBtnName={t('removeFeedbackDeleteBtnName')}
					closeModal={() => setShowRemoveFeedback(false)}
					deleteModal={removeFeedback}
				/>
			}
		</>
	)
}

const mapStateToProps = ({ user }) => ({
	user: user.user,
})

const mapDispatchToProps = dispatch => ({
	toggleLoader: (show) => {
		dispatch({ type: 'TOGGLE_LOADER', payload: show });
	}
})

export default connect(mapStateToProps, mapDispatchToProps)(EventFeedback)
