import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

// FWSD-4092
const SelectionEventVideoType = (props) => {
	// console.log('PROPS SelectionEventVideoType: ', props)
	const { name, value, setEventTypes, disabled, labelPosition } = props
	const { t } = useTranslation('translation', { keyPrefix: 'manageVehiclesPage.fleetConfigurationContainer.editProfile.selectionEventVideoType' });

	const setNewValue = (e) => {
		setEventTypes({ [name]: e.target.value })
		// const newValue = e.target.value
		// if (['dv4_driver', 'dv6_driver'].includes(name)) {
		// 	setEventTypes({ dv4_driver: newValue, dv6_driver: newValue })
		// }
		// else if (['dv4_high_speed', 'dv6_high_speed'].includes(name)) {
		// 	setEventTypes({ dv4_high_speed: newValue, dv6_high_speed: newValue })
		// }
		// else if (['dv4_fatal_gsensor', 'dv6_fatal_gsensor'].includes(name)) {
		// 	setEventTypes({ dv4_fatal_gsensor: newValue, dv6_fatal_gsensor: newValue })
		// }
		// else {
		// 	setEventTypes({ [name]: e.target.value })
		// }
	}

	return (
		<div className="toggle-wrapper">
			<label className={`settings-title ${!labelPosition ? 'mr10' : labelPosition}`}>{t('uploadType')}</label>
			<select
				placeholder="select"
				className="form-control rosco-select mini inline"
				name={name}
				value={value}
				onChange={setNewValue}
				disabled={disabled}
			>
				<option value="alert_only">{t('alertOnly')}</option>
				<option value="snapshot_only">{t('snapshotOnly')}</option>
				<option value="video_snapshot">{t('videoSnapshot')}</option>
			</select>
		</div>
	)
}

SelectionEventVideoType.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	setEventTypes: PropTypes.func.isRequired,
	labelPosition: PropTypes.string,
}

SelectionEventVideoType.defaultProps = {
	labelPosition: '',
}

export default SelectionEventVideoType;
