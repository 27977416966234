import React, { useState, useEffect, useRef } from 'react'
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Button } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { useTranslation } from 'react-i18next';

import { changePasswordUserAccount } from '../../../action/AccountActions';
import { passwordValidation } from '../../helpers/helperAccount';
import CustomButton from '../../CustomButton';

const AccountChangePasswordContainer = (props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'accountPage.accountChangePasswordContainer' });
    const { toggleLoader } = props;

    const [data, setData] = useState({
        password: '',
        new_password: '',
        new_password_confirm: ''
    });
    const [errMessage, setErrMessage] = useState({
        password: '',
        new_password: '',
        new_password_confirm: ''
    });
    const [toggle_password_current, setToggle_password_current] = useState(true);
    const [toggle_password, setToggle_password] = useState(true);
    const [toggle_password_confirm, setToggle_password_confirm] = useState(true);
    const [disabledButton, setDisabledButton] = useState(true);
    const [upd, setUpd] = useState(false);

    const togglePassword = () => {
        setToggle_password_current(!toggle_password_current);
    };

    const togglePassword2 = () => {
        setToggle_password(!toggle_password);
    };

    const togglePassword3 = () => {
        setToggle_password_confirm(!toggle_password_confirm);
    };

    const fillForm = (e) => {
        if (data.password && data.new_password && data.new_password_confirm) {
            setDisabledButton(false)
        }
        else {
            setDisabledButton(true)
        }
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setData({ ...data, [name]: value });
    };

    const checkForm = (data, e) => {
        let error = false;
        let errMessageValue = errMessage;

        for (let i in data) {
            if (data[i] == '') {
                error = true;
                let errName = i;
                if (i === 'new_password' || i === 'new_password_confirm') {
                    errName = t('newPassword')
                }
                errMessageValue[i] = t('enterYour') + errName;
            }
            else if (errMessageValue[i]) {
                errMessageValue[i] = null;
            }
        }

        if (!errMessageValue.new_password) {
            const validation = passwordValidation(data.new_password)
            if (!validation.result) {
                error = true;
                errMessageValue.new_password = validation.error;
            }
            else if (data.new_password !== data.new_password_confirm) {
                error = true;
                errMessageValue.new_password = t('errPasswords');
            }
        }

        if (!errMessageValue.new_password_confirm) {
            if (data.new_password !== data.new_password_confirm) {
                error = true;
                errMessageValue.new_password_confirm = t('errPasswords');
            }
        }

        setErrMessage(errMessageValue);
        return error;
    };

    const sendForm = (e) => {
        e.preventDefault();

        const error = checkForm(data, e);
        setUpd(!upd);
        if (!error) {
            toggleLoader(true);
            changePasswordUserAccount(data).then(res => {
                console.log("Helloooo")
                toggleLoader(false);
                toastr.success('', t('passwordChanged'));
                setDisabledButton(true)
                window.location.replace('/login')
                // browserHistory.push({pathname: "/login", state: {} })

                if (props.user_status == 'firstly') {
                    browserHistory.push({ pathname: "/", state: {} })
                }
            })
                .catch((error) => {
                    toggleLoader(false);
                    if (error && error.response && error.response.data && error.response.data.response) {
                        toastr.error(error.response.data.response.error);
                    }
                    else {
                        toastr.error(t('unableChangePassword'));
                    }
                });
        }
    };

    const { password, new_password, new_password_confirm } = errMessage;

    return (
        <div className='account-settings'>
            <div className='page-subheader'>
                <CustomButton variant='secondary' size='lg' prefix={`save-changes ${disabledButton ? ' disabled' : ''}`} onClick={sendForm}>
                {t('textSaveChanges')}
                </CustomButton>
            </div>

            <main className='change-password main-content-block'>
                <h3 className='category-title text-center'>{t('titleChangePassword')}</h3>
                <div>
                    <p>
                    {t('textBlock_1')}
                        <b> {t('textSaveChanges')} </b>
                        {t('textBlock_2')}
                    </p>
                </div>
                <form onSubmit={sendForm}>
                    <div className={classnames('form-group fa-custom has-feedback', { 'has-error': password })}>
                        {data.password.length > 0 &&
                            <label className="control-label login-label">{t('labelCurrentPassword')}</label>
                        }
                        <input type={toggle_password_current ? "password" : "text"} placeholder={t('placeholderCurrentPassword')} name="password" className="vehicle-search" onChange={fillForm} />
                        <span className="fa fa-eye form-control-feedback" onClick={togglePassword} />
                        {password && <span className="help-block">{password}</span>}
                    </div>

                    <div className={classnames('form-group fa-custom has-feedback', { 'has-error': new_password })}>
                        {data.new_password.length > 0 &&
                            <label className="control-label login-label">{t('labelNewPassword')}</label>
                        }
                        <input type={toggle_password ? "password" : "text"} placeholder={t('placeholderNewPassword')} name="new_password" className="vehicle-search" onChange={fillForm} />
                        <span className="fa fa-eye form-control-feedback" onClick={togglePassword2} />
                        {new_password && <span className="help-block">{new_password}</span>}
                    </div>

                    <div className={classnames('form-group fa-custom has-feedback', { 'has-error': new_password_confirm })}>
                        {data.new_password_confirm.length > 0 &&
                            <label className="control-label login-label">{t('labelConfirmPassword')}</label>
                        }
                        <input type={toggle_password_confirm ? "password" : "text"} placeholder={t('placeholderConfirmPassword')} name="new_password_confirm" className="vehicle-search" onChange={fillForm} />
                        <span className="fa fa-eye form-control-feedback" onClick={togglePassword3} />
                        {new_password_confirm && <span className="help-block">{new_password_confirm}</span>}
                    </div>
                </form>
            </main>
        </div>
    );
};

export default connect(
    state => ({
        user: state.user
    }),
    dispatch => ({
        toggleLoader: (show) => {
            dispatch({ type: 'TOGGLE_LOADER', payload: show });
        }
    })
)(AccountChangePasswordContainer);
