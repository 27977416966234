import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { getDevicesList } from '../../../action/DeviceActions';
import CategoryItem from '../../dump/menuComponents/CategoryItem';
import CustomButton from '../../CustomButton';

const EditProfileCommentModal = (props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'manageVehiclesPage.fleetConfigurationContainer.editProfileCommentModal' });
	const { profile, onHide, onSave, loaderModal } = props;

	const [isEditing, setIsEditing] = useState(false);
	const [comments, setComments] = useState('');

	useEffect(() => {
		setComments(profile.comments || '');
	}, []);

	return (
		<Modal
			show
			onHide={onHide}
			className="config-modal modal-lg-size"
		>
			<Modal.Header>
				<Modal.Title id="contained-modal-title-lg" className="action-title">
					{`${profile.name} Comment`}
					{!isEditing &&
						<CustomButton
							variant="secondary"
							title="Edit Profile"
							size="icon"
							onClick={() => setIsEditing(true)}
						>
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-pencil edit-notif" viewBox="0 0 16 16">
								<path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
							</svg>
						</CustomButton>
					}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="config-modal-body auto-height">
					{isEditing > 0
						? (
							<div>
								<textarea
									value={comments}
									onChange={(e) => setComments(e.target.value)}
									style={{ width: '100%', height: '80px', resize: 'vertical' }}
								/>
							</div>
						) : (
							<div style={{ minHeight: '80px', textAlign: 'center' }}>
								{profile.comments}
							</div>
						)
					}
				</div>
			</Modal.Body>
			<Modal.Footer>
				{isEditing
					? (
						<div>
							<CustomButton variant="primary" onClick={onHide}>
								{t('btnCancel')}
							</CustomButton>
							<CustomButton variant="primary" type="button" onClick={() => onSave(comments)}>
								{t('btnSave')}
							</CustomButton>
						</div>
					) : (
						<CustomButton variant="primary-outline" onClick={onHide}>
							{t('btnClose')}
						</CustomButton>
					)
				}
			</Modal.Footer>
			{loaderModal}
		</Modal>
	);
};

EditProfileCommentModal.propTypes = {
	profile: PropTypes.objectOf(PropTypes.any).isRequired,
	onHide: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired
};

export default connect(
	null,
	dispatch => ({
		toggleLoaderModal: (show) => {
			dispatch({ type: 'TOGGLE_LOADER_MODAL', payload: show });
		}
	})
)(EditProfileCommentModal);
