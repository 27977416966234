import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { browserHistory } from 'react-router'
import AnnounceKit from 'announcekit-react'
import { Dropdown } from 'react-bootstrap'
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import CustomButton from '../CustomButton';
import AutocompleteComponent from '../common/AutocompleteComponent';
import ModalManageJobKey from '../smart/modals/ModalManageJobKey';

import { constants } from '../helpers/constants';
import { sendGA } from '../helpers/helperUsers'
import { viewedLastReleaseDescription } from '../../action/ClientActions';
import { getSpeedingSummaryReport } from '../../action/ReportsActions';

const UserDropdown = (props) => {
	const { user, company, dashboardRedirect, getPartnersCompanies, onSelectCompany } = props;
	const userRole = user.roles[0];
	const roleName = constants.roleNames[user.roles[0]];
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'components.userDropdown' });
	// FWSD-6888
	// const announceLink = document.location.hostname.includes('staging') ? 'https://updates.roscolive.com/widgets/v2/2Z9pGE' : 'https://updates.roscolive.com/widgets/v2/2Z9pGE'
	const announceLink = 'https://updates.roscolive.com/widgets/v2/2Z9pGE'
	// let widgetRef = useRef(null);

	const [company_name, setCompany_name] = useState('');
	const [partner_company_name, setPartner_company_name] = useState('');
	const [showManageJobKey, setShowManageJobKey] = useState(false);
	const ref = React.createRef();

	// FWSD-7345, we use this useEffect to change the partner and company of User Menu when user clicks on the "Open Live Stream" notification 
	// for device with different partner/company from current selected in User Menu
	useEffect(() => {
		if (
			dashboardRedirect
			&& dashboardRedirect.type === 'redirectFromBellNotificationsOpenLS'
			&& dashboardRedirect.partner_name !== company.partner
		) {
			setPartner_company_name(dashboardRedirect.partner_name)
			setCompany_name('')
			getPartnersCompanies(dashboardRedirect.partner_name, dashboardRedirect.company_name)
		}
		else if (
			dashboardRedirect
			&& dashboardRedirect.type === 'redirectFromBellNotificationsOpenLS'
			&& dashboardRedirect.company_id !== company.company_id
		) {
			setCompany_name(dashboardRedirect.company_name)
			onSelectCompany(dashboardRedirect.company_name)
		}
	}, [dashboardRedirect])

	const handleLanguageChange = (lng) => {
		i18n.changeLanguage(lng);
		localStorage.setItem('language', lng);
	};

	const handleClick = (url) => {
		props.closeDropdown();
		browserHistory.push({ pathname: url })
	}

	let partnerLocal = localStorage.getItem("partner_company_name");
	let companyLocal = localStorage.getItem("company_name");
	if (!!partner_company_name === false && !!company_name === false && !!companyLocal === true) setCompany_name(companyLocal);
	if (!!partner_company_name === false && !!partnerLocal === true) setPartner_company_name(partnerLocal);
	localStorage.setItem("partner_company_name", partner_company_name);
	localStorage.setItem("company_name", company_name);
	const lng = localStorage.getItem('language');

	return (
		<div>
			<div className="user-menu-item user-menu-title">
				{`${user.first_name} ${user.last_name}`}
				<div className="user-initials">
					<span>{user.first_name[0] + user.last_name[0]}</span>
				</div>
				<div className="user-menu-subtitle">
					<div>{roleName}</div>
					<strong style={{ fontWeight: '700' }}>{user.email}</strong>
				</div>
			</div>

			{['system_admin', 'customer_service', 'partner', 'installer', 'partner_view', 'sales_demo', 'custom_partner'].includes(userRole) && (
				<div className="company-select-menu">
					<span className="select-company-container">
						{['system_admin', 'customer_service', 'sales_demo'].indexOf(userRole) > -1 && (
							<div className="company-select-wrapper">
								<div className="company-select-label">
									{t('labelPartner')}
								</div>
								<AutocompleteComponent
									value={partner_company_name}
									items={_.get(props, 'partnerList.partner_list')}
									placeholder={_.get(props, 'company.partner')}
									onChange={(e, value) => {
										setPartner_company_name(value);
										if (!value) localStorage.removeItem("partner_company_name")
									}}
									onSelect={(value) => {
										setPartner_company_name(value);
										setCompany_name('');
										getPartnersCompanies(value)
									}}
									clear={() => {
										localStorage.removeItem("partner_company_name")
										localStorage.removeItem("company_name")
										setPartner_company_name('');
									}}
									partner={true}
								/>
							</div>
						)}
						<div className="company-select-wrapper">
							<div className="company-select-label">
								{t('labelCompany')}
							</div>
							<AutocompleteComponent
								value={company_name}
								items={_.get(props, 'partnerList.company_list')}
								placeholder={_.get(props, 'company.name')}
								onChange={(e, value) => {
									setCompany_name(value)
									if (!value) localStorage.removeItem("company_name")
								}}
								onSelect={(value) => {
									setCompany_name(value)
									onSelectCompany(value)
								}}
								clear={() => {
									localStorage.removeItem("company_name")
									setCompany_name('');
								}}
								company={true}
							/>
						</div>
					</span>
				</div>
			)}

			<nav>
				<div className="user-menu-item">
					<CustomButton
						variant='link'
						onClick={() => handleClick('/account/settings')}
						ga_params={{ category: userRole, action: 'User Menu', label: 'Settings' }}
					>
						{t('btnSettings')}
					</CustomButton>
				</div>
				<div className="user-menu-item">
					<CustomButton variant="link" onClick={async () => await ref.current.open()}>{t("btnWhat'sNew")}</CustomButton>
					<AnnounceKit
						widget={announceLink}
						onWidgetOpen={() => {
							sendGA({ category: userRole, action: 'User Menu', label: 'Whats New' })
							props.closeDropdown()
						}}
						// onWidgetClose={onReleaseViewed}
						// onWidgetUnread={() => alert('onWidgetUnread1')}
						ref={ref}
						user={{
							id: user.id,
							email: user.email
						}}
						data={{
							role: user.roles[0]
						}}
						widgetStyle={{
							height: '0.75em',
							top: '13px',
							position: 'absolute',
							left: '48px',
						}}
					/>
				</div>
				<div className='user-menu-item'>
					<CustomButton variant='link' onClick={() => handleClick('/support')} ga_params={{ category: userRole, action: 'User Menu', label: 'Support' }}>{t('btnSupport')}</CustomButton>
				</div>
				{
					['system_admin', 'customer_service', 'partner', 'fleet_manager'].includes(userRole) && (
						<div className="user-menu-item">
							<CustomButton
								variant='link'
								onClick={() => handleClick('/account/fleet-settings')}
								ga_params={{ category: userRole, action: 'User Menu', label: 'Fleet Settings' }}
							>
								{t('btnFleetSettings')}
							</CustomButton>
						</div>
					)
				}
				{
					['system_admin', 'customer_service', 'partner', 'fleet_manager', 'group_manager'].includes(userRole) &&
					<div className='user-menu-item'>
						<CustomButton
							variant='link'
							onClick={() => {
								setShowManageJobKey(true);
								props.closeDropdown()
							}}
							ga_params={{ category: userRole, action: 'User Menu', label: 'Company Key' }}
						>
							{t('btnCompanyKey')}
						</CustomButton>
					</div>
				}
				<Dropdown
					className="languageDropdown"
				>
					<Dropdown.Toggle className="no-caret">
						<svg xmlns="http://www.w3.org/2000/svg" width="0.7em" height="0.7em" fill="currentColor" className="bi bi-caret-left-fill" viewBox="0 0 16 16">
							<path d="M3.86 8.753l5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
						</svg>
						{lng === 'en' ? 'English' : lng === 'fr' ? 'Français' : lng === 'es' ? 'Español' : 'English'}
					</Dropdown.Toggle>
					<Dropdown.Menu className="languageDropdownMenu">
						<Dropdown.Item
							onClick={() => handleLanguageChange('en')}
						>
							English
						</Dropdown.Item>
						<Dropdown.Item
							onClick={() => handleLanguageChange('fr')}
						>
							Français
						</Dropdown.Item>
						<Dropdown.Item
							onClick={() => handleLanguageChange('es')}
						>
							Español
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
				<div className='user-menu-item logout'>
					<CustomButton variant='link' onClick={props.logOut}>{t('btnLogOut')}</CustomButton>
				</div>
			</nav>

			{showManageJobKey
				&& (
					<ModalManageJobKey
						onHide={() => setShowManageJobKey(false)}
					// setPropStates={setPropStates}
					/>
				)}
		</div>
	)
}

const mapStateToProps = ({ user, company, dashboardData }) => ({
	user: user.user,
	company: company.company,
	dashboardRedirect: dashboardData.dashboardRedirect,
})

export default connect(mapStateToProps)(UserDropdown);
