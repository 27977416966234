import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Datetime from '@iftek/react-datetime'
import Toggle from 'react-toggle';
import Autocomplete from 'react-autocomplete';
import moment from 'moment';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { useTranslation } from 'react-i18next';

import SearchBar from '../../SearchBar'
import CustomButton from '../../CustomButton';
import DateDropdown from '../../dump/menuComponents/DateDropdown';
import AddSubscriptionModal from './Modals/AddSubscriptionModal';

import { getDateRangeFromTabKey } from './ReportsHelpers';
import { tableExportOptions } from '../../helpers/table';
import momenttz from '../../helpers/momenttz'
import { getReportFirmware } from '../../../action/AccountActions';
import { getDevicesFeatureUsage, getFleetAdminEventsSummary } from '../../../action/FleetAdminActions';
import { apiCallGet, apiCall } from '../../../action/RouterActions';
import { getDevicesList } from '../../../action/DeviceActions';
import { getAllDevices } from '../../../action/DeviceActions';
import { getCompanyById } from '../../../action/CompaniesActions';
import { getEventsOfCompany } from '../../../action/AlertActions';
import { getIdlingBreakdownReport, getIdlingSummaryReport, getSpeedingBreakdownReport, getSpeedingSummaryReport } from '../../../action/ReportsActions';


const StandardReportTable = (props) => {
    // console.log('PROPS StandardReportTable: ', props)
    const { t, i18n } = useTranslation('translation', { keyPrefix: 'reportsPage.standardReportTable' });
    let initTabKey = 3;
    switch (props.selectedReport.defaultDateRange) {
        case 'Today':
            initTabKey = 1;
            break;
        case 'Yesterday':
            initTabKey = 2;
            break;
        case 'Last Week':
            initTabKey = 4;
            break;
        case 'This Month':
            initTabKey = 5;
            break;
        case 'Last Month':
            initTabKey = 6;
            break;
    }
    const dateRange = getDateRangeFromTabKey(initTabKey);

    const { user, company, goBack, selectedReport, toggleLoader } = props;
    const userRole = user.roles[0];

    const [reportSearch, setReportSearch] = useState('');
    const [reportData, setReportData] = useState([]);
    const [secondaryReportSearch, setSecondaryReportSearch] = useState('');
    const [secondaryReportData, setSecondaryReportData] = useState([]);
    const [showAddSubscription, setShowAddSubscription] = useState(false);
    const [showSelectDate, setShowSelectDate] = useState(false);
    const [tabKey, setTabKey] = useState(initTabKey)
    const [start_date, setStart_date] = useState(dateRange[0]);
    const [end_date, setEnd_date] = useState(dateRange[1]);
    const [allCompanies, setAllCompanies] = useState(false);
    const [didMount, setDidMount] = useState(false);
    const [value, setValue] = useState('');
    const [autoValue, setAutoValue] = useState('');
    const [devices, setDevices] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState({});
    const [companyData, setCompanyData] = useState({});
    const [selectedCompanies, setSelectedCompanies] = useState([]) // used for the Data Tracking report FWSD-6747

    const disabledReport = (selectedReport.name === 'Recording Status (Under Improvement)');

    // useEffect(() => {
    //     debugger
    //     getReportParameters(start_date, end_date);
    // }, [])

    useEffect(() => {
        if (!allCompanies) {
            setReportData([])
            setReportSearch('')
            setAutoValue('')
            getReportParameters(start_date, end_date, '');
        }
    }, [company.company_id])

    const getReportParameters = (start_date, end_date, autoValueArg) => {
        switch (user.roles[0]) {
            case 'fleet_maintainer':
            case 'fleet_manager':
            case 'group_manager':
            case 'user':
            case 'storage_manager':
            case 'storage_user':
            case 'custom_user':
                getReportData(start_date, end_date, null, null, autoValueArg);
                break;
            case 'system_admin':
            case 'customer_service':
            case 'sales_demo':
                if (company.name && company.partner) {
                    getReportData(start_date, end_date, company.name, company.partner, autoValueArg);
                }
                break;
            case 'partner':
            case 'partner_view':
            case 'custom_partner':
                if (company.name) {
                    getReportData(start_date, end_date, company.name, null, autoValueArg);
                }
                break;
            default:
                break;
        }
    }

    const getReportData = (start_date, end_date, company_name, partner_name, autoValueArg, allCompanies) => {
        toggleLoader(true);

        const data = { start_date, end_date }

        switch (selectedReport.name) {
            case 'Vehicle Activity':
                getFleetAdminEventsSummary(userRole, company_name, partner_name)
                    .then((res) => {
                        setReportData(res.data.response)
                        toggleLoader(false)
                    })
                    .catch((err) => {
                        toastr.error(t('toastrUnableGetData'))
                        toggleLoader(false)
                    })
                break;
            case 'Feature Usage':
                if (!(['custom_user', 'custom_partner'].includes(userRole) && !_.find(user.permissions, { perm_category_id: 7, a_view: true }))) {
                    getDevicesFeatureUsage(data, userRole, company_name, partner_name)
                        .then(res => {
                            setReportData(res.data.response.report)
                            toggleLoader(false);
                        })
                        .catch(err => {
                            toastr.error(t('toastrUnableGetData'));
                            toggleLoader(false);
                        })
                } else {
                    toastr.error(t('toastrUnableGetData'));
                    toggleLoader(false);
                }
                break;
            case 'Vehicle Event Breakdown':
                apiCallGet('/companies/{company_id}/reports/event_type', {
                    company_id: company.company_id,
                    start_date: momenttz(moment(`${start_date}000000`, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')).utc().format('YYYYMMDDHHmmss'),
                    end_date: momenttz(moment(`${end_date}235959`, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')).utc().format('YYYYMMDDHHmmss'),
                })
                    .then(res => {
                        const vehicleEvents = res.data.response.report.map((item) => {
                            const { device_id, vehicle_name, vehicle_group, events } = item
                            return { device_id, vehicle_name, vehicle_group, ...events }
                        })
                        setReportData(vehicleEvents)
                        toggleLoader(false);
                    })
                    .catch(err => {
                        toastr.error(t('toastrUnableGetData'));
                        toggleLoader(false);
                    })
                break;
            case 'Group Event Breakdown':
                apiCallGet('/companies/{company_id}/reports/event_type', {
                    company_id: company.company_id,
                    start_date: momenttz(moment(`${start_date}000000`, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')).utc().format('YYYYMMDDHHmmss'),
                    end_date: momenttz(moment(`${end_date}235959`, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')).utc().format('YYYYMMDDHHmmss'),
                })
                    .then(res => {
                        const groupEvents = _(res.data.response.report)
                            .groupBy('vehicle_group')
                            .map((group, id) => {
                                const events = { vehicle_group: id }
                                group.forEach((event) => {
                                    // get list of keys
                                    Object.keys(event.events).forEach((key) => {
                                        events[key] = (events[key] ? events[key] : 0) + event.events[key]
                                    })
                                })

                                return events
                            })
                            .value()
                        setReportData(groupEvents)
                        toggleLoader(false);
                    })
                    .catch(err => {
                        toastr.error(t('toastrUnableGetData'));
                        toggleLoader(false);
                    })
                break;
            case 'Driver Event Breakdown':
                apiCallGet('/companies/{company_id}/reports/driver/event_breakdown', {
                    company_id: company.company_id,
                    start_date: momenttz(moment(`${start_date}`, 'YYYYMMDD').format('YYYY-MM-DD')).utc().format('YYYYMMDD'),
                    end_date: momenttz(moment(`${end_date}`, 'YYYYMMDD').format('YYYY-MM-DD')).utc().format('YYYYMMDD'),
                })
                    .then(res => {
                        const eventBreakdown = res.data.response.report
                            .map((item) => {
                                let obj = item['event_counts'];
                                return { ...item, ...obj }
                            })
                        setReportData(eventBreakdown)
                        toggleLoader(false);
                    })
                    .catch(err => {
                        toastr.error(t('toastrUnableGetData'));
                        toggleLoader(false);
                    })
                break;
            case 'Driver Event Performance':
                apiCallGet('/companies/{company_id}/reports/driver/event_performance', {
                    company_id: company.company_id,
                    start_date: momenttz(moment(`${start_date}`, 'YYYYMMDD').format('YYYY-MM-DD')).utc().format('YYYYMMDD'),
                    end_date: momenttz(moment(`${end_date}`, 'YYYYMMDD').format('YYYY-MM-DD')).utc().format('YYYYMMDD'),
                })
                    .then(res => {
                        setReportData(res.data.response.report)
                        toggleLoader(false);
                    })
                    .catch(err => {
                        toastr.error(t('toastrUnableGetData'));
                        toggleLoader(false);
                    })
                break;
            case 'Firmware':
                getReportFirmware(userRole, company_name, partner_name)
                    .then(res => {
                        setReportData(res.data.response.devices)
                        toggleLoader(false);
                    })
                    .catch(err => {
                        toastr.error(t('toastrUnableGetData'));
                        toggleLoader(false);
                    })
                break;
            case 'Recording Status (Under Improvement)':
                setReportData([]);
                toggleLoader(false);
            case 'Channel Status':
                getAllDevices(userRole, company_name, partner_name, null, true, `ignition,${selectedReport.name === 'Channel Status' ? 'channel_status' : 'sd_status'}`)
                    .then(res => {
                        let devices = selectedReport.name === 'Recording Status (Under Improvement)' ?
                            res.data.response.devices.sort((a, b) => a.sd_status === 'Needs Reformat' ? -1 : 1) :
                            res.data.response.devices.sort((a, b) => a.channel_status.length > b.channel_status.length ? -1 : 1);
                        setReportData(devices)
                        toggleLoader(false);
                    })
                    .catch(err => {
                        toastr.error(t('toastrUnableGetData'));
                        toggleLoader(false);
                    })
                break;
            case 'Data Usage':
                const requestArray = []
                requestArray.push(getCompanyById(userRole, company.company_id));
                requestArray.push(getAllDevices(userRole, company_name, partner_name, null, true, 'data_usage,ignition'));

                Promise.all(requestArray).then(
                    (res) => {
                        const companyData = res[0].data.response.company
                        let devices = res[1].data.response.devices.sort((a, b) => a.data_usage > b.data_usage ? -1 : 1)
                        setReportData(devices);
                        setCompanyData(companyData);
                        toggleLoader(false);
                    }
                )
                    .catch(err => {
                        toastr.error(t('toastrUnableGetData'));
                        toggleLoader(false);
                    })
                break;
            case 'DMS Events':
                getEventsOfCompany({
                    user_role: userRole,
                    company_name: company_name,
                    partner_name: partner_name,
                    start_ts: moment(start_date).utc().format('YYYYMMDDHHmmss'),
                    end_ts: moment(end_date).add(1, 'days').subtract(1, 'seconds').utc().format('YYYYMMDDHHmmss'),
                    alert_types: '',
                    event_types: 'phoneuse,drowsiness,yawning,distraction,seatbelt,smoking',
                    device_ids: 'all'
                })
                    .then(res => {
                        const typeFormatter = {
                            'Drowsiness': 'Drowsiness/Head Down',
                            'Distraction': 'Distraction',
                            'Phone Use': 'Phone Use/Hand to Head',
                            'Yawning': 'Yawning',
                            'Seatbelt': 'Seatbelt',
                            'Smoking': 'Smoking',
                        }
                        const reportData = [];
                        res.data.response.forEach((device) => {
                            device.events.forEach((event) => {
                                reportData.push({
                                    device_id: device.device_id,
                                    vehicle_name: device.vehicle_name,
                                    timestamp: event.timestamp,
                                    type: i18n.t(`constants.eventNames.${(typeFormatter[event.event_type] || event.event_type).replace(/\s/g, "_")}`, (typeFormatter[event.event_type] || event.event_type)),
                                    event_id: event.id,
                                })
                            })
                        })
                        setReportData(reportData)
                        toggleLoader(false);
                    })
                    .catch(err => {
                        toggleLoader(false);
                        toastr.error(t('toastrUnableGetData'))
                    });
                break;
            case 'ADAS Events':
                getEventsOfCompany({
                    user_role: userRole,
                    company_name: company_name,
                    partner_name: partner_name,
                    start_ts: moment(start_date).utc().format('YYYYMMDDHHmmss'),
                    end_ts: moment(end_date).add(1, 'days').subtract(1, 'seconds').utc().format('YYYYMMDDHHmmss'),
                    alert_types: '',
                    event_types: 'headwaywarning,forwardcollisionwarning,pedestrianwarning,lanedeparture',
                    device_ids: 'all'
                })
                    .then(res => {
                        const typeFormatter = {
                            'headwaywarning': 'Headway Warning',
                            'forwardcollisionwarning': 'Forward Collision',
                            'pedestrianwarning': 'Pedestrian Warning',
                            'lanedeparture': 'Lane Departure',
                            'followingdistancewarning': 'Tailgating',
                        }
                        const reportData = [];
                        res.data.response.forEach((device) => {
                            device.events.forEach((event) => {
                                reportData.push({
                                    device_id: device.device_id,
                                    vehicle_name: device.vehicle_name,
                                    timestamp: event.timestamp,
                                    type: i18n.t(`constants.eventNames.${(typeFormatter[event.event_type] || event.event_type).replace(/\s/g, "_")}`, (typeFormatter[event.event_type] || event.event_type)),
                                    event_id: event.id,
                                })
                            })
                        })
                        setReportData(reportData);
                        toggleLoader(false);
                    })
                    .catch(err => {
                        setReportData([]);
                        toggleLoader(false);
                        console.log(err)
                        toastr.error(t('toastrUnableGetData'))
                    });
                break;
            case 'Event Detail':
                getEventsOfCompany({
                    user_role: userRole,
                    company_name: company_name,
                    partner_name: partner_name,
                    start_ts: moment(start_date).utc().format('YYYYMMDDHHmmss'),
                    end_ts: moment(end_date).add(1, 'days').subtract(1, 'seconds').utc().format('YYYYMMDDHHmmss'),
                    alert_types: '',
                    event_types: 'fatalgsensor,highspeed,inputsensor,%_clip,%_alert,%_event,forwardcollisionwarning,lanedeparture,pedestrianwarning,headwaywarning,drowsiness,distraction,phoneuse,yawning,seatbelt,smoking', // 'all',
                    device_ids: 'all'
                })
                    .then(res => {
                        const typeFormatter = {
                            'Drowsiness': 'Drowsiness/Head Down',
                            'Distraction': 'Distraction',
                            'Phone Use': 'Phone Use/Hand to Head',
                            'Yawning': 'Yawning',
                            'Seatbelt': 'Seatbelt',
                            'Smoking': 'Smoking',
                        }
                        const reportData = [];
                        res.data.response.forEach((device) => {
                            device.events.forEach((event) => {
                                reportData.push({
                                    device_id: device.device_id,
                                    vehicle_name: device.vehicle_name,
                                    timestamp: event.timestamp,
                                    type: i18n.t(`constants.eventNames.${(typeFormatter[event.event_type] || event.event_type).replace(/\s/g, "_")}`, (typeFormatter[event.event_type] || event.event_type)),
                                    event_id: event.id,
                                })
                            })
                        })
                        setReportData(reportData)
                        toggleLoader(false);
                    })
                    .catch(err => {
                        toggleLoader(false);
                        toastr.error(t('toastrUnableGetData'))
                    });
                break;
            case 'Idling Summary':
                getIdlingSummaryReport(company.company_id, start_date, end_date)
                    .then(res => {
                        setReportData(res.data.response.report)
                        toggleLoader(false);
                    })
                    .catch(err => {
                        toggleLoader(false);
                        toastr.error(t('toastrUnableGetData'))
                    })
                break;
            case 'Idling Breakdown':
                getIdlingBreakdownReport(company.company_id, start_date, end_date)
                    .then(res => {
                        setReportData(res.data.response.report)
                        toggleLoader(false);
                    })
                    .catch(err => {
                        toggleLoader(false);
                        toastr.error(t('toastrUnableGetData'))
                    })
                break;
            // case 'Speeding Breakdown':
            //     getSpeedingBreakdownReport(company.company_id, start_date, end_date)
            //         .then(res => {
            //             setReportData(res.data.response.report)
            //             toggleLoader(false);
            //         })
            //         .catch(err => {
            //             toggleLoader(false);
            //             toastr.error(t('toastrUnableGetData'))
            //         })
            //     break;
            // case 'Speeding Summary':
            //     getSpeedingSummaryReport(company.company_id, start_date, end_date)
            //         .then(res => {
            //             setReportData(res.data.response.report)
            //             toggleLoader(false);
            //         })
            //         .catch(err => {
            //             toggleLoader(false);
            //             toastr.error(t('toastrUnableGetData'))
            //         })
            //     break;
            case 'Location History':
                apiCallGet(`/companies/${company.company_id}/devices/list`)
                    .then(res => {
                        toggleLoader(false);
                        setDevices(res.data.response.devices);
                    })
                    .catch(e => {
                        console.error('getDevicesList', e)
                        toastr.error(t('toastrUnableRetrieveVehicles'))
                        setDevices(undefined);
                        setReportData([]);
                    })

                //If a vehicle is selected, refresh data
                if ((autoValueArg !== undefined && autoValueArg !== '') || (autoValueArg === undefined && autoValue !== '')) {
                    getLocationHistory(selectedDevice.device_id, start_date);
                }
                break;
            // case 'Data Tracking1':
            //     const params1 = {
            //         companyId: allCompanies ? 'all' : props.company.company_id,
            //         start_date: start_date,
            //         end_date: end_date,
            //     }
            //     apiCallGet('/companies/{companyId}/reports/data_tracking', params1)
            //         .then(res => {
            //             toggleLoader(false);

            //             const companyGroup = {}
            //             _(res.data.response.report)
            //                 .groupBy('company_id')
            //                 .forEach((devices, id) => {
            //                     let fleetLimit = 0
            //                     let fleetUsage = 0
            //                     devices.forEach((item) => {
            //                         // Set a rule so that a vehicle is not included in the tally of fleet total until it has been on RL 2.0 for 15 days (date of creation, NOT activation).
            //                         // This will help us match the tracking with our current billing policy which is to not bill the customer until 15 days after we ship (which will be equal
            //                         // or close enough to the date created).
            //                         if (
            //                             (item.status === 'deleted' && item.existed_days > 15)
            //                             || (item.status === 'used' && item.created_days > 15)
            //                         ) {
            //                             fleetLimit += (item.device_limit ? item.device_limit : 0)
            //                             fleetUsage += (item.data_usage ? item.data_usage : 0)
            //                         }
            //                     })

            //                     companyGroup[id] = { fleetLimit, fleetUsage }
            //                 })

            //             const reportDataTotal = _.map(res.data.response.report, (item) => {
            //                 const { company_id } = item
            //                 const { fleetLimit, fleetUsage } = companyGroup[company_id]
            //                 return { ...item, ...{ fleetLimit, fleetUsage } }
            //             })

            //             const large_limit = reportDataTotal.filter(({ device_limit }) => device_limit === 1);
            //             const small_limit = reportDataTotal.filter(({ device_limit }) => device_limit === 0.5);
            //             setReportData(large_limit);
            //             setSecondaryReportData(small_limit);
            //         })
            //         .catch(err => {
            //             toggleLoader(false);
            //             toastr.error('Unable to get report data. Please try again later.')
            //         })
            //     break;
            case 'Data Tracking':
                apiCallGet('/reports/data_tracking/companies', {
                    start_date: start_date,
                    end_date: end_date,
                })
                    .then(res => {
                        const { companies } = res.data.response
                        setReportData(companies)
                        if (selectedCompanies.length) {
                            getDevicesReportData(selectedCompanies, start_date, end_date)
                        }
                        toggleLoader(false);
                    })
                    .catch(err => {
                        toastr.error(t('toastrUnableGetCompaniesData'));
                        toggleLoader(false);
                    })
                break;
        }
    }

    const getDevicesReportData = (selectedCompaniesArg, startDateArg, endDateArg) => {
        // const tmpDevicesData = [
        //     {device_id: '3512185635', vehicle_name: 'Test staging', company_name: 'Gi Joe Mission', company_id: '7094',  device_type: 'dv6', imei: '015974000019090', total_data_used: '2', data_pull_usage: '10', active: true},
        //     {device_id: '1019171804', vehicle_name: 'ZachCam', company_name: 'NBI Test Company', company_id: '2326',  device_type: 'dv4', imei: '', total_data_used: '1', data_pull_usage: '5', active: true},
        //     {device_id: '3175555917', vehicle_name: 'MikeOfficeDv6', company_name: 'Rosco-Geotab1', company_id: '825',  device_type: 'dv6', imei: '015974000002872', total_data_used: '18', data_pull_usage: '120', active: false},
        // ]
        const selectedCompaniesParam = selectedCompaniesArg || selectedCompanies
        const startDateParam = startDateArg || start_date
        const endDateParam = endDateArg || end_date

        if (!selectedCompaniesParam.length) {
            setSecondaryReportData([])
            return
        }

        toggleLoader(true)
        apiCallGet('/reports/data_tracking/devices', {
            company_ids: selectedCompaniesParam,
            start_date: startDateParam,
            end_date: endDateParam,
        })
            .then(res => {
                const { devices } = res.data.response
                setSecondaryReportData(devices)
                toggleLoader(false);
            })
            .catch(err => {
                toastr.error(t('toastrUnableGetDevicesData'));
                toggleLoader(false);
            })
    }

    const changeDate = (tabKey, selected_start_date, selected_end_date) => {
        let new_start_date, new_end_date;
        if (tabKey) {
            const dateRange = getDateRangeFromTabKey(tabKey)
            new_start_date = dateRange[0];
            new_end_date = dateRange[1];
        }
        else {
            //manually selected date
            new_start_date = selected_start_date ? moment(selected_start_date).format('YYYYMMDD') : start_date;
            new_end_date = selected_end_date ? moment(selected_end_date).format('YYYYMMDD') : end_date;
        }
        setTabKey(tabKey)
        setStart_date(new_start_date)
        setEnd_date(new_end_date)
        getReportParameters(new_start_date, new_end_date)
    }

    const getLocationHistory = (device_id, start_date) => {
        //normalize timestamp into user's tz setting
        const start_ts = momenttz(moment(`${start_date}`, 'YYYYMMDD').format('YYYY-MM-DD')).utc().format('YYYYMMDDHHmmss')
        const end_ts = moment(start_ts, 'YYYYMMDDHHmmss').add(24, 'h').format('YYYYMMDDHHmmss')
        toggleLoader(true);

        switch (user.roles[0]) {
            case 'system_admin':
            case 'customer_service':
            case 'partners':
                apiCallGet(`/${user.roles[0]}/devices/${device_id}/metadata`, {
                    start_ts: start_ts,
                    end_ts: end_ts
                })
                    .then(res => {
                        console.debug('getLocationHistory', res.data)
                        parseLocationHistory(res.data.response.metadata)
                    })
                    .catch(err => {
                        console.error('getLocationHistory', err)
                        toastr.error(t('toastrGetDeviceLocationHistory'))
                        toggleLoader(false);
                    })
                break
            default:
                apiCallGet(`/devices/${device_id}/metadata`, {
                    start_ts: start_ts,
                    end_ts: end_ts
                })
                    .then(res => {
                        console.debug('getLocationHistory', res.data)
                        parseLocationHistory(res.data.response.metadata)
                    })
                    .catch(err => {
                        console.error('getLocationHistory', err)
                        toastr.error(t('toastrGetDeviceLocationHistory'))
                        toggleLoader(false);
                    })
                break
        }
    }

    const parseLocationHistory = (location_data) => {
        var report = []
        var coords = []
        //duration used for time interval between dates
        //at 2 min, max number of coord pairs shouldn't exceed 720
        var duration = moment.duration(2, 'minutes');
        var last_ts;

        for (var i = 0; i < location_data.length; i++) {
            if (report.length == 0 && location_data[i].type == "Gps") {
                last_ts = location_data[i].timestamp
                report.push({ time: location_data[i].timestamp })
                coords.push({ lat: location_data[i].latitude, lng: location_data[i].longitude })
            } else if (location_data[i].type == "Gps") {
                //if the current ts is more than a minute after last_ts, add to report.
                if (moment(location_data[i].timestamp).subtract(duration) >= moment(last_ts)) {
                    last_ts = location_data[i].timestamp
                    report.push({ time: location_data[i].timestamp })
                    coords.push({ lat: location_data[i].latitude, lng: location_data[i].longitude })
                } else {
                    continue
                }
            }
        }

        //handle no data returned for date
        // address/list API has max list length of 1000
        if (report.length > 0 && coords.length <= 1000) {
            getAddress(coords, report)
        } else {
            toggleLoader(false);
            toastr.info(t('toastrNoHistory'))
            setReportData([]);
        }
    }

    const getAddress = (coords, report) => {
        const coords_list = coords.map(data => JSON.stringify(data))
        toggleLoader(true);

        console.debug('getAddressCalled', coords_list);
        apiCall('POST', '/address/list', { coords_list: coords_list })
            .then(res => {
                const { response } = res.data;
                console.debug('got address list', response)

                if (response.length) {
                    report.map((data, i) => {
                        if (!!response[i]) {
                            const house_number = response[i].house_number || '';
                            const road = response[i].road || '';
                            const city = !!response[i].city ? `${response[i].city},` : '';
                            const state = response[i].state || '';
                            const postcode = response[i].postcode || '';

                            house_number ?
                                data.address = `${house_number} ${road} - ${city} ${state} ${postcode}` :
                                data.address = `${road} - ${city} ${state} ${postcode}`
                        } else {
                            data.address = " - ";
                        }
                    })
                } else {
                    report.map(data => {
                        data.address = " - ";
                    })
                }

                console.debug('report', report)
                toggleLoader(false);
                setReportData(report);
            })
            .catch(err => {
                console.error(err)
                const error = err.response.data.response.error;
                if ((error !== "required keys are: ['lng']") && (error !== "required keys are: ['lat']")) toastr.error(t('toastrErr'))
                report.map(data => {
                    data.address = " - "
                })
                console.debug('report', report)
                toggleLoader(false);
                setReportData(report);
            })
    }

    const nameFormat = (name) => (i18n.t(`reportsPage.standardReportData.${name.replace(/\s/g, "_")}`));

    const selectCompany = (row) => {
        let newSelectedCompanies = []

        if (selectedCompanies.includes(row.company_id)) {
            newSelectedCompanies = selectedCompanies.filter((company_id) => company_id !== row.company_id)
        }
        else {
            newSelectedCompanies = [...selectedCompanies, row.company_id]
        }

        setSelectedCompanies(newSelectedCompanies)
        getDevicesReportData(newSelectedCompanies)
    }

    const selectAllCompanies = (e) => {
        // e.preventDefault()
        // e.stopPropagation()
        const checked = e.target.type === 'checkbox' ? e.target.checked : e.target.value

        let newSelectedCompanies = []
        if (checked) {
            newSelectedCompanies = reportData.map(({ company_id }) => company_id)
        }

        setSelectedCompanies(newSelectedCompanies)
        getDevicesReportData(newSelectedCompanies)
    }

    const setDataFormat = (cell, row, column) => {
        if (selectedReport.name === 'Data Tracking' && column.name === 'Company') {
            return column.dataFormat(cell, row, selectedCompanies, selectCompany)
        }
        else if (selectedReport.name === 'Data Tracking' && column.name === 'Override Overage Limits') {
            return (
                column.dataFormat(
                    cell,
                    row,
                    null,
                    (e) => {
                        toggleLoader(true)
                        apiCallGet('/reports/data_tracking/companies', {
                            start_date,
                            end_date,
                        })
                            .then(res => {
                                const { companies } = res.data.response
                                setReportData(companies)
                                if (selectedCompanies.length) {
                                    getDevicesReportData()
                                }
                                toggleLoader(false)
                            })
                            .catch(err => {
                                toastr.error(t('toastrUnableGetCompaniesData'));
                                toggleLoader(false)
                            })
                        // const newReportData = reportData.map((item) => {
                        //     if (item.company_id === row.company_id) {
                        //         item.override_overage_limits = !item.override_overage_limits
                        //     }
                        //     return item
                        // })
                        // setReportData(newReportData)
                    },
                    toggleLoader
                )
            )
        }

        return column.dataFormat(cell, row, companyData, () => getReportData(start_date, end_date))
    }

    const setSecondaryDataFormat = (cell, row, column) => {
        if (selectedReport.name === 'Data Tracking' && column.name === 'Enable/Disable Device') {
            return (
                column.dataFormat(
                    +cell,
                    row,
                    null,
                    (e) => {
                        const newSecondaryReportData = secondaryReportData.map((item) => {
                            if (item.device_id === row.device_id) {
                                item.active = !item.active
                            }
                            return item
                        })
                        setSecondaryReportData(newSecondaryReportData)
                    }
                )
            )
        }

        return column.dataFormat(cell, row, companyData, () => getReportData(start_date, end_date))
    }

    const setColumnName = (column) => {
        const res = []

        if (selectedReport.name === 'Data Tracking' && column.name === 'Company') {
            res.push(
                <input
                    key={'data-tracking-company-column'}
                    type="checkbox"
                    className="company-checkbox column-header"
                    checked={selectedCompanies.length === reportData.length}
                    onChange={selectAllCompanies}
                />
            )
        }

        res.push(nameFormat(column.name))
        return res
    }

    const { dateEditable, defaultDateRange } = selectedReport;
    const searchTerm = selectedReport.searchTerm || ['vehicle_name'];
    const displayItemName = (item) => {
        return item.vehicle_name || item.device_id
    }
    const getItemValue = (item) => {
        return item.vehicle_name || item.device_id;
    }
    const matchStateToTerm = (item, value) => {
        if (item.vehicle_name) {
            return item.vehicle_name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
        }

        return item.device_id.toString().toLowerCase().indexOf(value.toLowerCase()) !== -1;
    }

    let filteredReportData = []
    if (searchTerm === ['driver_name']) {
        if (reportSearch) {
            filteredReportData = reportData && reportData.filter(driver => {
                let name = driver.first_name + ' ' + driver.last_name;
                if (name.toLowerCase().includes(reportSearch.toLowerCase())) {
                    return driver;
                }
            })
        }
        else {
            filteredReportData = reportData;
        }
    }
    else if (searchTerm === ['address']) {
        if (reportSearch) {
            filteredReportData = reportData && reportData.filter(address => {
                if (address.toLowerCase().includes(reportSearch.toLowerCase())) {
                    return address;
                }
            })
        } else {
            filteredReportData = reportData
        }
    }
    else {
        filteredReportData = reportData && (reportSearch ?
            reportData.filter(vehicle => {
                let filteredVehicle = false;
                searchTerm.forEach(term => {
                    if (vehicle[term] && vehicle[term].toString().toLowerCase().includes(reportSearch.toLowerCase())) {
                        filteredVehicle = vehicle;
                    }
                })
                return filteredVehicle
            }) :
            reportData
        );
    }

    const filteredSecondaryReportData = secondaryReportData && (
        secondaryReportSearch
            ? secondaryReportData.filter(vehicle => {
                let filteredVehicle = false;
                // searchTerm.forEach(term => {
                ['vehicle_name', 'imei'].forEach(term => {
                    if (vehicle[term] && vehicle[term].toString().toLowerCase().includes(secondaryReportSearch.toLowerCase())) {
                        filteredVehicle = vehicle;
                    }
                })
                return filteredVehicle
            })
            : secondaryReportData
    )

    switch (selectedReport.name) {
        case 'Driver Event Breakdown':
        case 'Driver Event Performance':
            if (!!reportSearch) filteredReportData = reportData.filter(item => Object.values(item).toString().toLowerCase().includes(reportSearch.toLowerCase()));
    }

    const formatCSVFileName = () => {
        const name = selectedReport.name.toLowerCase().replaceAll(' ', '_');
        const timeframe = moment(start_date).format('M-D-YY') + (start_date === end_date ? '' : '--' + moment(end_date).format('M-D-YY'))
        return name + t('CSVFileName') + timeframe + '.csv'
    }

    const formatCSVSecondaryFileName = () => {
        const timeframe = moment(start_date).format('M-D-YY') + (start_date === end_date ? '' : '--' + moment(end_date).format('M-D-YY'))
        return t('CSVFileName') + timeframe + '.csv'
    }

    return (
        <div className="report">
            <div className='page-subheader'>
                <div className='subheader-section search-flex'>
                    <SearchBar term={reportSearch} placeholder={t('placeholderSearchCompanies')} onSearch={(e) => setReportSearch(e.target.value)} />
                    {dateEditable ? (
                        selectedReport.name === 'Location History'
                            ?
                            <DateDropdown
                                start_date={start_date}
                                end_date={end_date}
                                tabKey={tabKey}
                                onSelectDate={changeDate}
                                singleDate={true}
                                limitDates={[i18n.t('components.dateDropdown.nameToday'), i18n.t('components.dateDropdown.nameYesterday')]}
                            />
                            : (
                                selectedReport.name === 'Data Tracking'
                                    ?
                                    <div className="report-date-time">
                                        <Datetime
                                            inputProps={{ readOnly: true }}
                                            closeOnSelect
                                            timeFormat={false}
                                            viewMode={'months'}
                                            dateFormat={'MMMM YYYY'}
                                            defaultValue={moment(start_date).format('MMMM YYYY')}
                                            value={moment(start_date).format('MMMM YYYY')}
                                            isValidDate={function (current) {
                                                return current.isBefore(Datetime.moment().endOf('month'))
                                            }}
                                            onChange={(e) => {/*console.log('e: ', e, e.format('YYYYMMDD HH:mm:ss'));*/ changeDate(null, moment(e).startOf('month'), moment(e).endOf('month')) }}
                                        />
                                    </div>
                                    :
                                    <DateDropdown
                                        start_date={start_date}
                                        end_date={end_date}
                                        tabKey={tabKey}
                                        onSelectDate={changeDate}
                                    />
                            )
                    ) : null
                    }

                    {!dateEditable && defaultDateRange &&
                        <CustomButton variant='primary' size='sm' disabled>{defaultDateRange}</CustomButton>
                    }
                </div>
                <div className='subheader-section'>
                    {/* {selectedReport.name === 'Data Tracking1' &&
                        <label>
                            <Toggle
                                name="restart"
                                checked={allCompanies}
                                onChange={(e) => {
                                    const checked = e.target.checked;
                                    setAllCompanies(checked);
                                    getReportData(start_date, end_date, null, null, null, checked);
                                }}
                            />
                            <span>All Vehicles</span>
                        </label>
                    } */}
                    {!['Location History', 'Data Tracking'].includes(selectedReport.name) &&
                        <CustomButton variant='primary-outline' size='sm' disabled={disabledReport} onClick={() => setShowAddSubscription(true)}>{t('btnAutomateReport')}</CustomButton>
                    }
                    <CustomButton variant="secondary-outline" onClick={goBack}>{t('btnBack')}</CustomButton>
                </div>
            </div>

            {disabledReport
                ? <main className='main-content-block'>
                    <div className='table-header'>
                        <span className='report-title'>{nameFormat(selectedReport.reportName || selectedReport.name)}</span>
                        <p>{t('titleReport')}</p>
                    </div>
                </main>
                : <main className='main-content-block'>
                    <div className='table-header'>
                        <span className='report-title'>{nameFormat(selectedReport.reportName || selectedReport.name)}</span>
                        {selectedReport.name === 'Location History' &&
                            <div className='autocomplete-wrapper'>
                                <span>{t('locationHistoryWrapper')}</span>
                                <Autocomplete
                                    autoHighlight
                                    value={autoValue}
                                    inputProps={{ id: 'states-autocomplete' }}
                                    wrapperProps={{ className: 'search-autocomplete event-search-input' }}
                                    getItemValue={getItemValue}
                                    shouldItemRender={matchStateToTerm}
                                    menuStyle={{
                                        borderRadius: '3px',
                                        boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                                        background: 'rgba(255, 255, 255, 0.9)',
                                        padding: '2px 0',
                                        fontSize: '90%',
                                        position: 'absolute',
                                        overflow: 'auto',
                                        maxHeight: '200px',
                                        right: '0',
                                        top: 'auto',
                                        zIndex: 100
                                    }}
                                    onChange={(e, value) => {
                                        setAutoValue(value)
                                    }}
                                    onSelect={(value, item) => {
                                        setAutoValue(value);
                                        setSelectedDevice(item);
                                        getLocationHistory(item.device_id, start_date);
                                    }}
                                    renderMenu={function (items, value, style) {
                                        return <div className="items-list" style={{ ...style, ...this.menuStyle }} children={items} />
                                    }
                                    }
                                    renderItem={(item, isHighlighted) => (
                                        <div
                                            className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                                            key={item.device_id}
                                        >
                                            {displayItemName(item)}
                                        </div>
                                    )}
                                    renderInput={(props) => (
                                        <input {...props} placeholder={t('placeholderSearchVehicle')} className="form-control event-page-search" />
                                    )}
                                    items={devices}
                                />
                            </div>
                        }
                    </div>
                    {filteredReportData &&
                        <BootstrapTable
                            data={filteredReportData}
                            {...tableExportOptions}
                            containerClass={selectedReport.containerClass}
                            csvFileName={formatCSVFileName}
                        >
                            {selectedReport.columns.map(column => {
                                return (
                                    <TableHeaderColumn
                                        key={column.dataField}
                                        width={column.width}
                                        isKey={column.isKey || false}
                                        hidden={column.hidden || false}
                                        dataField={column.dataField}
                                        dataFormat={
                                            column.dataFormat
                                                ? (cell, row) => setDataFormat(cell, row, column)
                                                : (cell, row) => cell
                                        }
                                        dataSort={column.dataSort}
                                        csvFormat={
                                            column.csvFormat
                                                ? (cell, row) => column.csvFormat(cell, row, companyData)
                                                : (cell, row) => cell
                                        }
                                        csvHeader={nameFormat(column.name)}
                                    >
                                        {setColumnName(column)}
                                    </TableHeaderColumn>
                                )
                            })}
                        </BootstrapTable>
                    }
                    {/* Second table of the Data Tracking report */}
                    {selectedReport.secondaryReportName &&
                        <div className='secondary-table-container'>
                            <div className='table-header'>
                                <span className='report-title'>{nameFormat(selectedReport.secondaryReportName)}</span>
                                <p>{t('textSelectCompanies')}</p>

                                {secondaryReportData.length > 0 &&
                                    <div style={{ width: '332px' }}>
                                        <SearchBar term={secondaryReportSearch} placeholder={t('placeholderSearchDevices')} onSearch={(e) => setSecondaryReportSearch(e.target.value)} />
                                    </div>
                                }
                            </div>
                            {secondaryReportData.length
                                ?
                                <BootstrapTable
                                    data={filteredSecondaryReportData}
                                    {...tableExportOptions}
                                    containerClass={selectedReport.containerClass}
                                    csvFileName={formatCSVSecondaryFileName}
                                >
                                    {selectedReport.secondaryColumns.map(column =>
                                        <TableHeaderColumn
                                            key={column.dataField}
                                            width={column.width}
                                            isKey={column.isKey || false}
                                            hidden={column.hidden || false}
                                            dataField={column.dataField}
                                            dataFormat={
                                                column.dataFormat
                                                    ? (cell, row) => setSecondaryDataFormat(cell, row, column)
                                                    : (cell, row) => cell
                                            }
                                            dataSort={column.dataSort}
                                            csvFormat={column.csvFormat}
                                            csvHeader={nameFormat(column.name)}
                                        >
                                            {nameFormat(column.name)}
                                        </TableHeaderColumn>
                                    )}
                                </BootstrapTable>
                                :
                                // <div className="p-5 bg-light text-muted rounded text-center">
                                <div className="jumbotron-custom text-center">
                                    {t('textNoCompanySelected')}
                                </div>

                            }
                        </div>
                    }
                </main>
            }

            {showAddSubscription && <AddSubscriptionModal onHide={() => setShowAddSubscription(false)} report={{ type: 'standard', report_id: selectedReport.id }} />}
        </div>
    )
}

StandardReportTable.propTypes = {
    user: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = (state) => ({
    user: state.user.user,
    company: state.company.company
});

const mapDispatchToProps = (dispatch) => {
    return {
        toggleLoader: (show) => {
            dispatch({ type: 'TOGGLE_LOADER', payload: show });
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StandardReportTable);
