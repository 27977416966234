import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap'

import CustomButton from '../../CustomButton'
import { toastr } from 'react-redux-toastr'

const AddProfileModal = (props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'manageVehiclesPage.fleetConfigurationContainer.addProfileModal' });
	const { onHide, profiles, addProfile } = props;

	const [selectedProfile, setSelectedProfile] = useState(profiles.find(profile => profile.name === 'Default'))

	const selectNewProfile = (e) => {
		const newProfile = profiles.find(profile => profile.id.toString() === e.target.value)
		setSelectedProfile(newProfile)
	}

	const onCreateClick = () => {
		const { name, configuration, configurationdv6 } = selectedProfile
		const isDV4ConfigurationEmpty = !configuration || configuration.length < 10
		const isDV6ConfigurationEmpty = !configurationdv6 || configurationdv6.length < 10

		if (isDV4ConfigurationEmpty) {
			toastr.error(t('DV4configurationEmpty', { name: `"${name}"` }))
			return true
		}

		if (isDV6ConfigurationEmpty) {
			toastr.error(t('DV6configurationEmpty', { name: `"${name}"` }))
			return true
		}

		addProfile(selectedProfile.name)
	}

	return (
		<Modal
			show
			onHide={onHide}
			className="modal-lg-size"
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-lg">{t('titleAddFleetProfile')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='config-modal-body add-profile-modal'>
					<label>
						{t('textSelectFleetProfile')}
					</label>
					<div className="text-center">
						<select name="profile-select" id="profile-select" className="rosco-select" value={selectedProfile.id} onChange={selectNewProfile}>
							{profiles.map((item, index) =>
								<option key={index} value={item.id} className="menu-item">{item.name}</option>
							)}
						</select>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<CustomButton variant="primary" onClick={onCreateClick}>{t('btnCreateNewProfile')}</CustomButton>
			</Modal.Footer>
		</Modal>
	)
}

export default AddProfileModal;
