import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SearchBar from '../../component//SearchBar'
import _ from 'lodash'
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { apiCall } from '../../action/RouterActions'
import { getAllCompanies } from '../../action/CompaniesActions'
import CustomButton from '../../component/CustomButton'
import { createImmutableStateInvariantMiddleware } from '@reduxjs/toolkit';

const RolloutBlacklistModal = (props) => {
	const { onHide, userRole, loaderModal, toggleLoaderModal, blacklistCompaniesId, loadFirmwareBlacklist } = props
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'firmwaresPage.rolloutBlacklistModal' });
	const [initPartnerCompanies, setInitPartnerCompanies] = useState([])
	const [partnerCompanies, setPartnerCompanies] = useState([])
	const [selectedCompaniesId, setSelectedCompaniesId] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');

	// console.log('PROPS RolloutBlacklistModal: ', props)
	// console.log('STATE RolloutBlacklistModal selectedCompaniesId: ', selectedCompaniesId)

	useEffect(() => {
		getCompanies()
		setSelectedCompaniesId([...blacklistCompaniesId])
	}, [])

	const getCompanies = () => {
		toggleLoaderModal(true)

		const requestArray = []
		// requestArray.push(apiCallGet('/companies/firmware/blacklist'))
		requestArray.push(getAllCompanies(userRole, 'customer,wcs'))
		Promise.all(requestArray)
			.then(res => {
				console.log('!Promise response: ', res)
				// const blacklistCompaniesId = _.get(res[0], 'data.response', []).map(({company_id}) => company_id)
				// setInitSelectedCompaniesId([...blacklistCompaniesId])
				// setSelectedCompaniesId([...blacklistCompaniesId])

				let companies = _.get(res[0], 'data.response.companies', [])
				companies = _.sortBy(companies, item => item.company_name.toLowerCase())
				const initPartnerCompanies = {}
				companies.forEach((item) => {
					if (initPartnerCompanies[item.partner_company]) {
						initPartnerCompanies[item.partner_company].companies.push({ id: item.id, company_name: item.company_name })
					}
					else {
						initPartnerCompanies[item.partner_company] = { companies: [{ id: item.id, company_name: item.company_name }] }
					}
				})
				const initPartnerCompaniesOrdered = Object.keys(initPartnerCompanies).sort((a, b) => {
					return a.toLowerCase().localeCompare(b.toLowerCase())
				});
				const newInitPartnerCompanies = {}
				initPartnerCompaniesOrdered.forEach((key) => {
					newInitPartnerCompanies[key] = initPartnerCompanies[key]
				})
				setInitPartnerCompanies(newInitPartnerCompanies)
				setPartnerCompanies(newInitPartnerCompanies)

				toggleLoaderModal(false)
			})
			.catch((error) => {
				console.log('!PromiseAll ERROR:', error.response, error)
				toggleLoaderModal(false)

				let errDescription = i18n.t('errorMessage.errDescription');
				if (error.response?.data?.response?.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			})
	}

	const onSave = () => {
		const addedIds = _.difference(selectedCompaniesId, blacklistCompaniesId)
		const removedIds = _.difference(blacklistCompaniesId, selectedCompaniesId)

		const requestArray = []
		if (addedIds.length) {
			requestArray.push(apiCall('PUT', '/companies/firmware/blacklist', { company_ids: addedIds }))
		}
		if (removedIds.length) {
			requestArray.push(apiCall('DELETE', '/companies/firmware/blacklist', { company_ids: removedIds }))
		}
		if (!requestArray.length) {
			toastr.error(t('noChangesBlacklist'))
		}

		toggleLoaderModal(true)
		Promise.all(requestArray)
			.then(res => {
				console.log('!Promise response: ', res)

				loadFirmwareBlacklist()
				toastr.success('', t('blacklistUpdated'))

				toggleLoaderModal(false)
			})
			.catch((error) => {
				console.log('!PromiseAll ERROR:', error.response, error)
				toggleLoaderModal(false)

				let errDescription = i18n.t('errorMessage.errDescription');
				if (error.response?.data?.response?.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			})
	}

	const filterGroups = (searchTermText = null) => {
		// let searchTermValue = searchTerm;
		// !!searchTermText ? searchTermValue = searchTermText : searchTermValue;
		// return (
		// 	partnerCompanies.reduce((totalGroups, group) => {
		// 		if (group.partner.toLowerCase().includes(searchTerm.toLowerCase())) {
		// 			totalGroups.push(group)
		// 		}
		// 		else {
		// 			let companies = group.companies.filter(company => { company.company_name.toLowerCase().includes(searchTerm.toLowerCase()) })
		// 			if (companies.length) {
		// 				totalGroups.push({ ...group, ...{ companies } })
		// 			}
		// 		}
		// 		return totalGroups
		// 	}, [])
		// )

		// const res = 

		// if (searchTerm) {

		// }
	}

	const filterGroups1 = (searchTermText = null) => {
		let groupsValue = groups;
		let searchTermValue = searchTerm;
		!!searchTermText ? searchTermValue = searchTermText : searchTermValue;
		return (
			groups.reduce((totalGroups, group) => {
				if (group.name.toLowerCase().includes(searchTerm.toLowerCase())) {
					totalGroups.push(group)
				}
				else {
					let vehicles = group.vehicles.filter(vehicle => {
						if (!!vehicle.activated_timestamp) {
							if (!vehicle.vehicle_name) {
								return vehicle.device_id.toString().toLowerCase().includes(searchTerm.toLowerCase())
							}
							return vehicle.vehicle_name.toLowerCase().includes(searchTerm.toLowerCase())
						}
					})
					if (vehicles.length) {
						totalGroups.push({ ...group, ...{ vehicles } })
					}
				}
				return totalGroups
			}, [])
		)
	}

	const filterCompany = (searchTerm = null) => {
		return filterGroups(searchTerm).reduce((company, { companies }) => {
			companies.forEach(item => { company.push(item.company_name) })
			return company
		}, [])
	}

	const toggleCompany = (argGroupName, argCompanyId) => {
		const newSelectedCompaniesId = selectedCompaniesId.includes(argCompanyId) ? selectedCompaniesId.filter(id => id !== argCompanyId) : [...selectedCompaniesId, argCompanyId]
		setSelectedCompaniesId(newSelectedCompaniesId)

		// const newPartnerCompanies = {}
		// Object.keys(partnerCompanies).forEach((key) => {
		// 	const partner = partnerCompanies[key]
		// 	if (key === argGroupName) {
		// 		const newCompanies = partner.companies.map((company) => {
		// 			if (company.id === argCompanyId) {
		// 				company.checked = !company.checked
		// 			}
		// 			return company
		// 		})

		// 		partner.companies = newCompanies
		// 	}

		// 	newPartnerCompanies[key] = partner
		// })

		// setPartnerCompanies(newPartnerCompanies)
	}

	const toggleGroup = (e, argGroupName) => {
		// let newSelectedCompaniesId = [...selectedCompaniesId]
		// const groupedCompanies = filterGroups().find(group => group.partner === partnerName).companies;
		// for (let company of groupedCompanies) {
		// 	if (e.target.checked) {
		// 		!newSelectedCompaniesId.includes(company.id) && newSelectedCompaniesId.push(company.id)
		// 	}
		// 	else {
		// 		newSelectedCompaniesId.includes(company.id) && newSelectedCompaniesId.splice(newSelectedCompaniesId.indexOf(company.id), 1)
		// 	}
		// }
		// setSelectedCompaniesId(newSelectedCompaniesId)

		// const isGroupChecked = partnerCompanies[argGroupName].companies.length === partnerCompanies[argGroupName].companies.filter(({checked}) => checked).length
		// const newPartnerCompanies = {...partnerCompanies}
		// newPartnerCompanies[argGroupName].companies.reduce((result, item) => {
		// 	item.checked = !isGroupChecked
		// 	return result
		// }, [])

		// setPartnerCompanies(newPartnerCompanies)

		const groupCompniesId = partnerCompanies[argGroupName].companies.map(({ id }) => id)
		if (isGroupChecked(argGroupName)) {
			setSelectedCompaniesId(_.difference(selectedCompaniesId, groupCompniesId))
		}
		else {
			setSelectedCompaniesId([...new Set([...selectedCompaniesId, ...groupCompniesId])])
		}
	}

	const onSearch = (e) => {
		const searchTerm = e.target.value
		setSearchTerm(searchTerm)

		// let updateSelectedCompanies = selectedCompaniesId;
		// let filteredSearchIds = filterCompany(searchTerm);
		// updateSelectedCompanies.map(value => { if (!filteredSearchIds.includes(value)) updateSelectedCompanies = updateSelectedCompanies.filter(item => item !== value) })

		// setSelectedCompaniesId(updateSelectedCompanies);

		const newPartnerCompanies = {}
		Object.keys(initPartnerCompanies).forEach((group) => {
			const companies = initPartnerCompanies[group].companies.filter(({ company_name }) => company_name.toString().toLowerCase().includes(searchTerm))
			if (companies.length) {
				newPartnerCompanies[group] = { companies }
			}
		})

		setPartnerCompanies(newPartnerCompanies)
	}

	// const renderCompanyRow = (argCompany, argSelectedCompany) => {
	const renderCompanyRow = (argGroupName, argCompany) => {
		const { id, company_name, checked } = argCompany
		return (
			<div className="asset-select-wrapper" key={id} style={{ paddingLeft: '0px' }}>
				<input
					type="checkbox"
					// checked={argSelectedCompany.includes(argCompany)}
					checked={selectedCompaniesId.includes(id)}
					// checked={checked}
					onChange={() => toggleCompany(argGroupName, id)}
				/>
				<span className="asset-name">{company_name}</span>
			</div>
		)
	}

	// const isGroupChecked = (index) => {
	const isGroupChecked = (argGroupName) => {
		// let groupSelectedCompanies = [];
		// filterGroups()[index].companies.map(company => {
		// 	groupSelectedCompanies.push(company.company_name);
		// })
		// if (groupSelectedCompanies.length && groupSelectedCompanies.every(company => selectedCompaniesId.includes(company))) {
		// 	return true;
		// }
		// else {
		// 	return false;
		// }
		// console.log('argGroupName: ', argGroupName, partnerCompanies[argGroupName].companies.length,  partnerCompanies[argGroupName].companies.filter(({checked}) => checked).length)
		// return partnerCompanies[argGroupName].companies.length === partnerCompanies[argGroupName].companies.filter(({checked}) => checked).length

		const groupCompniesId = partnerCompanies[argGroupName].companies.map(({ id }) => id)
		return !_.difference(groupCompniesId, selectedCompaniesId).length
	}

	const hasSelectedCompaniesIdChanges = _.difference(selectedCompaniesId, blacklistCompaniesId).length || _.difference(blacklistCompaniesId, selectedCompaniesId).length

	return (
		<Modal
			show
			onHide={onHide}
			className='rolloutBlacklistModal modal-lg-size'
		>
			<Modal.Header closeButton>
				<Modal.Title className="contained-modal-title">{t('titleFirmwareRolloutBlacklist')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='rolloutBlacklist-modal-body' dangerouslySetInnerHTML={{ __html: t('rolloutBlacklistBody') }}></div>
				<div className='select-box'>
					<div className='select-box-wrapper'>
						<SearchBar term={searchTerm} onSearch={onSearch} placeholder={t('placeholderSearchCompanies')} />
						<div className='select-box-body'>
							{/* {partnerCompanies.length > 0 && 
								filterGroups().map((group, index) =>
									<div className='group-list' key={group.partner}>
										<div className='asset-select-wrapper'>
											<input
												type='checkbox'
												checked={isGroupChecked(index)}
												onChange={(e) => toggleGroup(e, group.partner)}
											/>
											<strong>
												{group.partner}
											</strong>
										</div>
										<div className='vehicle-list'>
											<div className="vehicle-list">
												{group.companies.map(company => renderCompanyRow(company))}
											</div>
										</div>
									</div>
								)
							} */}


							{Object.keys(partnerCompanies).length > 0 &&
								Object.keys(partnerCompanies).map((group_name, index) => {
									return (
										<div className='group-list' key={group_name}>
											<div className='asset-select-wrapper'>
												<input
													type='checkbox'
													checked={isGroupChecked(group_name)}
													// onChange={(e) => toggleGroup(e, group.partner)}
													onChange={(e) => toggleGroup(e, group_name)}
												/>
												<strong>
													{group_name}
												</strong>
											</div>
											<div className='vehicle-list'>
												<div className="vehicle-list">
													{partnerCompanies[group_name].companies.map(company => renderCompanyRow(group_name, company))}
												</div>
											</div>
										</div>
									)
								})
							}
						</div>
						{`(${selectedCompaniesId.length} ${t('selected')}`}
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<div>
					<CustomButton type="button" variant="primary-outline" onClick={onHide}>
						{t('btnCancel')}
					</CustomButton>
					<CustomButton type="button" variant="secondary" disabled={!hasSelectedCompaniesIdChanges} onClick={onSave}>
						{t('btnSave')}
					</CustomButton>
				</div>
			</Modal.Footer>
			{loaderModal}
		</Modal>
	);
}

const mapStateToProps = (state) => ({
	company: state.company.company,
	loaderModal: state.loader.loaderModal,
});

const mapDispatchToProps = (dispatch) => ({
	toggleLoaderModal: (show) => dispatch({ type: 'TOGGLE_LOADER_MODAL', payload: show })
});

export default connect(mapStateToProps, mapDispatchToProps)(RolloutBlacklistModal);
