import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import Toggle from 'react-toggle';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { createDevicesCustomer } from '../../../action/DeviceActions';
import ModalUploadFile from '../../../component/common/modals/ModalUploadFile'
import { apiCall } from '../../../action/RouterActions'
import { vinValidation, imeiValidation } from '../../helpers/helperVehicle'

import '../../dump/menuComponents/menuComponents.sass'
import CustomButton from '../../CustomButton';

const AddAssetContainer = (props) => {
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'manageVehiclesPage.addAssetContainer' });
	const { company, user, devices, toggleLoader } = props;
	const userRole = user.roles[0];

	const [addVehicleModal, setAddVehicleModal] = useState(false);
	const [vehiclesObj, setVehiclesObj] = useState([{
		sn: '',
		vin: '',
		tag_plate: '',
		enable_ch3: false,
	}]);
	const [showUploadDevices, setShowUploadDevices] = useState(false);
	const [fileUploadDevices, setFileUploadDevices] = useState(null);

	const openAddVehiclesModal = () => {
		setAddVehicleModal(true)
	}

	const changeField = (e, i, type) => {
		const updatedVehicles = [...vehiclesObj];
		setVehiclesObj(_.set(updatedVehicles, `${i}.${type}`, e.target.value))
	}

	const removeVehicle = (index) => {
		const updatedVehicles = [...vehiclesObj];
		updatedVehicles.splice(index, 1)
		setVehiclesObj(updatedVehicles)
	}

	const validateVehiclesObj = () => {
		const { sn, vin, name } = vehiclesObj[0]
		if (!vehiclesObj.length) {
			toastr.error(t('enterIMEI/VIN/Name'))
			return false;
		}

		if (!sn) {
			toastr.error(t('enterIMEI'))
			return false;
		}

		if (!(/^(XC4RECO|DV401)/.test(sn) || /^\D*(?:\d\D*){15}$/.test(sn))) {
			toastr.error(t('tuastrIMEILength'))
			return false;
		}

		if (!imeiValidation(sn)) {
			toastr.error(t('tuastrIncorrectIMEI'))
			return false;
		}

		if (vin && !vinValidation(vin)) {
			toastr.error(t('tuastrIncorrectVIN'))
			return false;
		}

		let error = false;
		devices.map(device => {
			if (device.vehicle_name === name) {
				toastr.warning(t('tuastrDeviceExists'));
				error = true;
				return
			}
		})

		if (error) return false;

		// if (!vehicle.sn) {
		// 	toastr.error(t('enterIMEI'))
		// 	return false;
		// }
		//
		// for (let vehicle of vehiclesObj) {
		// 	if (!(/^(XC4RECO|DV401)/.test(vehicle.sn) || /^\D*(?:\d\D*){15}$/.test(vehicle.sn))) {
		// 		toastr.error(t('tuastrIMEILength'))
		// 		return false;
		// 	}
		//
		// 	if (!vinValidation(vehicle.vin)) {
		// 		toastr.error(t('tuastrIncorrectVIN'))
		// 		return false;
		// 	}
		// }

		return true;
	}

	const createVehicle = () => {
		const { user, company, changedDevicesList } = props;

		if (validateVehiclesObj()) {
			const data = {
				snlist: JSON.stringify(vehiclesObj),
				partner_name: company.partner,
				company_name: company.name,
				user_role: user.roles[0],
			}
			createDevicesCustomer(data)
				.then(() => {
					toastr.success(t('tuastrAddVehicles'))
					setAddVehicleModal(false);
					setVehiclesObj([{ sn: '', vin: '' }]);
					changedDevicesList();
				})
				.catch((err) => {
					console.error('AddAssetContainer createVehicle error: ', err.response)
					toastr.error(err.response.data.response.error)
				})
		}
	}

	const createRandomVehicle = () => {
		toggleLoader(true);

		const params = {
			number: vehiclesObj.length,
			partner_name: company.partner,
			company_name: company.name,
			user_role: userRole,
		};

		createDevicesCustomer(params)
			.then(res => {
				toggleLoader(false);

				toastr.success(t('tuastrAddVehicles'))
				setAddVehicleModal(false)
				props.changedDevicesList()
			})
			.catch(err => {
				toggleLoader(false);
				let errDescription = i18n.t('errorMessage.errDescription');
				if (err.response.data.response.error) {
					errDescription = err.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}

	const onFileChange = (e) => {
		const { value } = e.target;
		const file = e.target.files[0];
		setFileUploadDevices(file);

		let i
		if (value.lastIndexOf('\\')) {
			i = value.lastIndexOf('\\') + 1;
		} else {
			i = value.lastIndexOf('/') + 1;
		}
		const filename = value.slice(i);
		const uploaded = document.getElementById('upload-form-label');
		uploaded.innerHTML = filename;
	}

	const onUploadVehicles = (e) => {
		e.preventDefault()

		if (!fileUploadDevices) {
			toastr.warning(t('toastrSelectCSV/ExcelFile'))
			return
		}

		const params = {
			file: fileUploadDevices,
			company_id: company.company_id,
		}

		let error = false;
		fileUploadDevices.text().then(res => {
			res.slice(res.indexOf('\n') + 1).split('\n').map(row => {
				const name = row.split(',')[3];
				devices.map(device => {
					if (device.vehicle_name === name) {
						toastr.warning(t('tuastrDeviceExists'));
						error = true;
					}
				})
			})

			if (!error) {
				toggleLoader(true);
				apiCall('PUT', '/companies/{company_id}/devices/file', params)
					.then((res, err) => {
						// console.log('onUploadVehicles res: ', res, err)
						toggleLoader(false);
						toastr.success('', t('toastrFileUploaded'))
					})
					.catch((error) => {
						console.log('onUploadVehicles error: ', error.response, error)
						toggleLoader(false);
						const { status, data } = error.response
						if (status >= 400 && status < 500) {
							toastr.error(data.response.error);
						} else {
							toastr.error(t('toastrUnableUploadVehicles'));
						}
					})
			}
		});
	}

	// FWSD-4421
	const hasGeneralRandomAccess = () => {
		return !['partner'].includes(userRole)
	}

	const handleToggleChange = (e) => {
		const [data] = vehiclesObj;
		setVehiclesObj([{ ...data, enable_ch3: e.target.checked }]);
	}

	return (
		<div className="add-asset-block">
			<CustomButton
				variant="secondary-outline"
				onClick={openAddVehiclesModal}
			>
				{t('btnAddNewVehicle')}
			</CustomButton>

			<Modal
				show={addVehicleModal}
				onHide={() => {
					setAddVehicleModal(false);
					setVehiclesObj([{ sn: '', vin: '', name: '', tag_plate: '' }]);
				}}
				className='manage-vehicles-modal modal-lg-size'
			>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-lg">
						{t('titleAddVehicles')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						<h5 className="text-center">{t('enterIMEI/VIN/Name')}</h5>
						<div className='add-vehicles-container'>
							{vehiclesObj.map((vehicle, index) => (
								<div className="new-vehicle-wrapper" key={index}>
									{/* <div>{index + 1}</div> */}
									<input type="text" className="vehicle-search" value={vehicle.sn} placeholder={t('placeholderCameraIMEI')} onChange={(e) => changeField(e, index, 'sn')} />
									<input type="text" className="vehicle-search" value={vehicle.vin} placeholder={t('placeholderVIN')} onChange={(e) => changeField(e, index, 'vin')} />
									<input type="text" className="vehicle-search" value={vehicle.tag_plate} placeholder={t('placeholderLicensePlate')} onChange={(e) => changeField(e, index, 'tag_plate')} />
									<input type="text" className="vehicle-search" value={vehicle.name} placeholder={t('placeholderCameraName')} onChange={(e) => changeField(e, index, 'name')} />
									<div className="active-channel-3-container">
										<span className="toggle-left-title">{t('titleChannel3')}</span>
										<Toggle
											name="active_channel_3"
											checked={vehicle.enable_ch3}
											onChange={handleToggleChange}
										/>
									</div>
									{index > 0
										? <CustomButton variant="primary-outline" size="x-small" title='Remove Vehicle' onClick={() => removeVehicle(index)}>-</CustomButton>
										: <div className="empty-div" />
									}
								</div>
							))}
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					{hasGeneralRandomAccess() && (
						<CustomButton variant="primary-outline" onClick={createRandomVehicle}>
							{t('btnGenerateRandom')}
						</CustomButton>
					)}
					<CustomButton
						variant="primary-outline"
						title="Upload a Excel/CSV file with device serial and vin numbers"
						onClick={() => setShowUploadDevices(true)}
					>
						{t('btnUploadDevices')}
					</CustomButton>
					<CustomButton variant="primary" onClick={createVehicle}>{t('btnCreate')}</CustomButton>
				</Modal.Footer>
			</Modal>

			{showUploadDevices
				&& (
					<ModalUploadFile
						title={t('nodalUploadFileTitle')}
						description={t('modalUploadFileDescription')}
						exampleFileTitle={t('modalUploadFileExampleFileTitle')}
						exampleFileLink="/files/rosco_vehicles_example.csv"
						btnUploadTitle={t('modalUploadFileBtnUploadTitle')}
						acceptFileExtensions=".csv"

						onFileChange={onFileChange}
						onUploadFile={onUploadVehicles}
						onHide={() => setShowUploadDevices(false)}
					/>
				)}
		</div>
	)
}

export default connect(
	state => ({
		user: state.user.user,
		company: state.company.company
	}),
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(AddAssetContainer);
