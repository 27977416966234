import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Col } from 'react-bootstrap'
import moment from 'moment'
import _ from 'lodash'
import { toastr } from 'react-redux-toastr'
import { useTranslation } from 'react-i18next'

import Auth from '../../Auth'
import { defineEnvironmentDokuWiki } from '../../helpers/labels'
import { cameraHealthIcons } from '../../helpers/iconHelpers'

import './Modal.sass'
import { formatFailStatus, formatOnlineStatusCSV, formatTimestamp } from '../../helpers/tablesFuncHelpers'
import { apiCall } from '../../../action/RouterActions'
import CustomButton from '../../CustomButton';

const ModalChannelStatus = (props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'manageVehiclesPage.modalChannelStatus' });
	const { device, channel, userRole, getCompanyDevices, onHide } = props
	const { vehicle_name, device_id, channel_status, online } = device

	const getChannelLastChecked = () => {
		const maxTs = channel_status.filter((item) => item.channel === channel.channel).reduce((result, currentValue) => {
			let p = result

			if (currentValue.fail_ts && currentValue.fail_ts > p) {
				p = currentValue.fail_ts
			}
			if (currentValue.success_ts && currentValue.success_ts > p) {
				p = currentValue.success_ts
			}

			return p
		}, '')

		return maxTs ? formatTimestamp(maxTs) : 'N/A'
	}

	const onClickDismiss = () => {
		const params = { channel: channel.channel }
		apiCall('POST', `/devices/${device_id}/clear_channel_status`, params)
			.then(res => {
				if (res.data.response === "success") {
					onHide();
					getCompanyDevices();
				} else if (res.data.response.error) toastr.error(res.data.response.error);
			})
			.catch(error => {
				console.log(error.response, error)
				toastr.error(t('toastrUnableClearChannelStatus'))
			})
	}

	return (
		<Modal
			show
			onHide={onHide}
			dialogClassName="dialog-camera-status"
			className="rosco-modal modal"
			size="lg"
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-lg">
					{t('titleChannelStatus') + vehicle_name || device_id}
					<a
						href={`https://${defineEnvironmentDokuWiki()}/user:product:geotab_partner:geotab_how_to:camera_status?s_t=${new Auth().getSessionToken()}&env=${location.hostname}`}
						target="_blank"
						rel="noreferrer"
						className="header-question"
						style={{ marginTop: '-6px' }}
					>
						{cameraHealthIcons.headerQuestion}
					</a>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="statusInfoTopWrapper">
					<div className="statusLabel">
						{formatOnlineStatusCSV(online, device)}
					</div>
					<div className="statusLabel">
						{t('textLastChecked')} {getChannelLastChecked()}
					</div>
					{channel.fail_status &&
						<div className='statusLabel'>
							{t('textStatus')} {formatFailStatus(channel.fail_status)}
						</div>
					}
				</div>

				<Row className="statusInfoWrapper">
					{!_.isEmpty(channel)
						&& (
							channel.fail_snapshot_link
								? <img src={channel.fail_snapshot_link} className="channelSnapshot" alt="" />
								: <div className="channelNoImage">{t('channelNoImage')}</div>
						)}
				</Row>

			</Modal.Body>
			<Modal.Footer>
				{['group_manager', 'fleet_manager', 'partner', 'customer_service', 'system_admin'].includes(userRole) &&
					(<CustomButton variant="primary-outline" onClick={onClickDismiss}>
						{t('btnDismiss')}
					</CustomButton>)
				}
			</Modal.Footer>
		</Modal >
	)
}

ModalChannelStatus.propTypes = {
	device: PropTypes.objectOf(PropTypes.any).isRequired,
	channel: PropTypes.objectOf(PropTypes.any).isRequired,
	onHide: PropTypes.func.isRequired
}

export default ModalChannelStatus;
