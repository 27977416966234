import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button, Modal } from 'react-bootstrap'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import { getDevicesList } from '../../../action/DeviceActions'
import VehicleSelectBox from '../../dump/menuComponents/VehicleSelectBox'
import CategoryItem from '../../dump/menuComponents/CategoryItem'
import CustomButton from '../../CustomButton'

const FleetConfigurationModal = (props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'manageVehiclesPage.fleetConfigurationContainer.fleetConfigurationModal' });
	const { profile, profiles, company, isNewProfile, saveProfile, onHide, toggleLoaderModal, configurationdv6, webfleetDeviceId, isDV6ProfileUpdated, isDV4ProfileUpdated } = props

	const [profileName, setProfileName] = useState('')
	const [errMessage, setErrMessage] = useState({ errProfileName: '' })
	const [totalVehicles, setTotalVehicles] = useState([])
	const [profileId, setProfileId] = useState(props.profile.id)
	const [vehicleSearch, setVehicleSearch] = useState('')
	const [currentVehicles, setCurrentVehicles] = useState([]) // list of devices where uploaded_company_profile = profile.name, i.e. devices which used the current updated profile
	const [availableVehicles, setAvailableVehicles] = useState([]) // list of devices available for selection
	const [vehicle_ids, setVehicle_ids] = useState([]) // list of device ids where we send new or updated profile
	const [action, setAction] = useState(props.action)
	const [step, setStep] = useState(1)

	useEffect(() => {
		toggleLoaderModal(true);
		getDevicesList(company.company_id)
			.then(res => {
				let totalVehicles = res.data.response.devices
				if (webfleetDeviceId) {
					const arr = webfleetDeviceId.split(',')
					totalVehicles = totalVehicles.filter(item => arr.includes(item.device_id))
				}

				const vehicle_ids = []
				const currentVehicles = []
				let availableVehicles = []
				if (!isNewProfile && action !== 'Push') {
					totalVehicles.forEach(vehicle => {
						if (vehicle.uploaded_company_profile === profile.name) {
							// currentVehicles.push(vehicle)
							// vehicle_ids.push(vehicle.device_id)

							// FWSD-6734
							if (
								(isDV6ProfileUpdated && vehicle.device_type === 'dv6')
								|| (isDV4ProfileUpdated && vehicle.device_type === 'dv4')
							) {
								currentVehicles.push(vehicle)
								vehicle_ids.push(vehicle.device_id)
							}
						}
						else {
							availableVehicles.push(vehicle)
						}
					})
				}
				else {
					availableVehicles = res.data.response.devices;
				}

				// FWSD-6734
				if (isDV6ProfileUpdated && !isDV4ProfileUpdated) {
					availableVehicles = availableVehicles.filter(({ device_type }) => device_type === 'dv6')
				}
				else if (isDV4ProfileUpdated && !isDV6ProfileUpdated) {
					availableVehicles = availableVehicles.filter(({ device_type }) => device_type === 'dv4')
				}

				setTotalVehicles(totalVehicles)
				setCurrentVehicles(currentVehicles)
				setAvailableVehicles(availableVehicles)
				setVehicle_ids(vehicle_ids)
				currentVehicles.length === 0 && setStep(2)

				toggleLoaderModal(false);
			})
			.catch(err => {
				toggleLoaderModal(false);
			})
	}, [])

	const setVehicles = (vehicle_ids) => {
		setVehicle_ids(vehicle_ids)
	}

	const addProfile = () => {
		if (!profileName) {
			setErrMessage({
				errProfileName: t('errProfileName_1'),
			})
		} else if (profileName.toLowerCase() === 'manual') {
			setErrMessage({
				errProfileName: t('errProfileName_2', { manual: "manual" }),
			})
		} else if (profileName.toLowerCase() === 'default') {
			setErrMessage({
				errProfileName: t('errProfileName_3'),
			})
		} else if (profileName.length > 128) {
			setErrMessage({
				errProfileName: t('errProfileName_4'),
			})
		} else if (profiles.find(profile => profile.name === profileName)) {
			setErrMessage({
				errProfileName: t('errProfileName_5'),
			})
		} else {
			saveProfile(vehicle_ids, profileName);
		}
	}

	const pushProfile = () => {
		onHide()
		props.pushProfile(profileId, vehicle_ids)
	}

	const { errProfileName } = errMessage;

	const vehicleIdsLength = vehicle_ids.length;
	const vehiclesTerm = currentVehicles.length > 1 ? t('vehicles') : t('vehicle');
	const vehiclesActionTerm = `${vehicleIdsLength} ${vehicleIdsLength > 1 ? t('Vehicles') : t('Vehicle')}`

	// console.log('PROPS FleetConfigurationModal: ', props)

	return (
		<Modal
			show
			onHide={onHide}
			className='config-modal modal-lg-size'
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-lg">{`${t(action)} ${t('textFleetProfile')}`}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='config-modal-body'>
					{action === 'Update' && (
						step === 1 ? (
							<div>
								{currentVehicles.length > 0 &&
									<div>
										<label>{t('textProfile_1')} <strong>{profile.name}</strong> {t('textProfile_2')} {vehiclesTerm}:</label>
										<div className='profile-vehicle-list'>
											{currentVehicles.map((vehicle) => {
												return (
													<CategoryItem
														key={vehicle.device_id}
														text={vehicle.vehicle_name || vehicle.device_id}
														small
													/>
												);
											})}
										</div>
										<label>
											{t('textSaveProfile', { vehiclesTerm })}
										</label>
										<div className="profile-action">
											<CustomButton
												type="button"
												variant="primary" onClick={() => setStep(2)}>
												{t('btnContinue')}
											</CustomButton>
										</div>
										<div className='text-center message'><strong>OR</strong></div>
										<label>
											{t('textCreateNewProfile')}
										</label>
										<div className="profile-action">
											<CustomButton variant="primary-outline" onClick={() => {
												setAction('Add')
												setVehicle_ids([])
											}}>
												{t('btnCreateNewProfile')}
											</CustomButton>
										</div>
									</div>
								}
							</div>
						) : (
							<div>
								{currentVehicles.length === 0 ?
									<label dangerouslySetInnerHTML={{ __html: t('textApplyProfile_1', { profileName: profile.name }) }}>
									</label> :
									<label>
										{t('textApplyProfile_2')}
									</label>}
								{availableVehicles.length > 0 &&
									<VehicleSelectBox
										configurationdv6={configurationdv6}
										vehicles={availableVehicles}
										selectedVehicleIds={vehicle_ids}
										onToggle={setVehicles}
									/>
								}
							</div>
						)
					)}
					{(action === 'Add' || action === 'Push') &&
						<div>
							{action === 'Add'
								? (
									<div>
										<div className={classnames('form-group', { 'has-error': errProfileName })}>
											<label>{t('chooseNameProfile')}</label>
											<input
												name="profileName"
												className="vehicle-search"
												placeholder={t('placeholderProfileName')}
												onFocus={() => setErrMessage({ errProfileName: '' })}
												onChange={(e) => setProfileName(e.target.value)}
												value={profileName || ''} />
											{errProfileName && <span className="error-message">{errProfileName}</span>}
										</div>
										<label>{t('applyProfileImmediately')}</label>
									</div>
								) : (
									<label>{t('selectVehicles')}</label>
								)
							}
							{(totalVehicles && totalVehicles.length > 0) &&
								<VehicleSelectBox
									configurationdv6={configurationdv6}
									vehicles={totalVehicles}
									selectedVehicleIds={vehicle_ids}
									onToggle={setVehicles}
								/>
							}
							{!isNewProfile && action !== 'Push' &&
								<div>
									<hr />
									<span className='message'>{t('message')} <CustomButton variant="link" onClick={() => setAction('Update')}>{t('btnHere')}</CustomButton></span>
								</div>
							}
						</div>
					}
				</div>
			</Modal.Body>
			<Modal.Footer>
				{action === 'Update' && step === 2 &&
					<div>
						{currentVehicles.length > 0 && // current profile is already applied to some vehicles
							<CustomButton type="button" variant="primary-outline" onClick={() => setStep(1)}>
								{t('btnBack')}
							</CustomButton>
						}
						<CustomButton type="button" variant="primary" onClick={() => props.saveProfile(vehicle_ids)}>
							{vehicleIdsLength
								? t('btnSaveAndUpdate') + vehiclesActionTerm
								: t('btnSave')
							}
						</CustomButton>
					</div>
				}
				{action === 'Add' &&
					<CustomButton type="button" variant="primary" onClick={addProfile}>
						{vehicleIdsLength ?
							t('btnCreateAndUpdate') + vehiclesActionTerm :
							t('btnCreateProfile')
						}
					</CustomButton>
				}
				{action === 'Push' && vehicleIdsLength > 0 &&
					<CustomButton type="button" variant="primary" onClick={pushProfile}>
						{t('btnPushProfile') + vehiclesActionTerm}
					</CustomButton>
				}
			</Modal.Footer>
			{props.loaderModal}
		</Modal>
	);
}


FleetConfigurationModal.propTypes = {
	webfleetDeviceId: PropTypes.string,
}

FleetConfigurationModal.defaultProps = {
	webfleetDeviceId: '',
}

const mapStateToProps = ({ company, webfleetData }) => ({
	company: company.company,
	webfleetDeviceId: webfleetData.deviceId,
})

const mapDispatchToProps = (dispatch) => {
	return {
		toggleLoaderModal: (show) => {
			dispatch({ type: 'TOGGLE_LOADER_MODAL', payload: show });
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FleetConfigurationModal);
