import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { Dropdown, Modal, Popover, OverlayTrigger } from 'react-bootstrap'
import { Link } from 'react-router'
import moment from 'moment'
import { toastr } from 'react-redux-toastr'
import { connect } from 'react-redux'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import SearchBar from '../../component/dump/menuComponents/SearchBar'
import DateDropdown from '../../component/dump/menuComponents/DateDropdown'
import { getDateRangeFromTabKey } from '../../component/smart/reportsComponents/ReportsHelpers'
import CustomButton from '../../component/CustomButton'
import CustomOverlayTrigger from '../../component/CustomOverlayTrigger'
import ModalErrorStatus from './ModalErrorStatus'
import ModalSilencedErrors from './ModalSilencedErrors'

import momenttz from '../../component/helpers/momenttz'
import { renderCustomHeader, tableAdjustableColExportOptions, tableAdjustableColOptions } from '../../component/helpers/table'
import { formatGroup } from '../../component/helpers/tablesFuncHelpers'
import { filterIcon } from '../../component/helpers/iconHelpers'
import { apiCall, apiCallGet } from '../../action/RouterActions'
import { isInternalUser } from '../../component/helpers/constants'
import InfoPop from '../../component/common/InfoPop'

import '../../component/dump/menuComponents/menuComponents.sass'
import './CameraHealth.sass'
// import '../../component/smart/fleetConfiguration/fleetConfiguration.sass'
// import '../../component/dump/menuComponents/cloudStorage.sass'


const DetailedCodeDescription = {
	B004: 'The camera shut down improperly likely due to the car’s battery running out during parking mode',
	B005: 'The camera shut down improperly likely due to the car’s battery running out during parking mode',
	C100: 'Can bus has not connected properly and cannot relay data to the camera',
	D101: 'Possible Channel failure',
	D102: 'GPS not connected for an extended period of time',
	D103: 'Can bus has not connected properly and cannot relay data to the camera',
	D105: 'There is an object obstructing the camera for the DMS detection',
	E001: 'Possible Channel failure',
	E002: 'GPS not connected for an extended period of time',
	E003: 'G-Sensor event flood',
	E005: 'Possible Channel Failure',
	E006: 'PDC timeout',
	E007: 'PDC hub error',
	E008: 'Camera not operating correctly due to high temperature',
	E009: 'Possible Channel Failure',
	E255: 'Recording error involving the SD card',
	G002: 'GPS not connected for an extended period of time',
	M002: 'Not able to connect to 4G due to invalid credentials',
	M003: 'Not able to connect to 4G due to incorrect IP',
	M008: 'Not able to connect to 4G due to bad connection',
	R001: 'Channel failure recovery',
	R005: 'Channel failure recovery',
	Z001: 'SD Card was reformatted',
}

const CameraHealth = (props) => {
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'manageVehiclesPage.cameraHealth' });
	const { user, company, webfleetDeviceId, atGlanceSortName, toggleLoader, /*cameraHealthAlerts,*/ updateCameraHealthAlerts } = props
	const userRole = user.roles[0]

	const [state, setState] = useState({
		searchText: '',
		alerts: [],
		initialAlerts: [],
		groups: [],
		statuses: [],
		sortName: atGlanceSortName || 'vehicle_name',
		sortOrder: 'asc',
		selectedAlert: null, // stores alert clicked in the Status column
		showModalErrorStatus: false,
		selectedDevice: null, // stores device clicked in the Silenced Errors column
		showModalSilencedErrors: false,
	})
	const { searchText, alerts, initialAlerts, groups, statuses, sortName, sortOrder, selectedAlert, showModalErrorStatus, selectedDevice, showModalSilencedErrors } = state

	// const [searchText, setSearchText] = useState('');
	// const [start_date, setStart_date] = useState(moment().startOf('day').format('YYYYMMDD'));
	// const [end_date, setEnd_date] = useState(moment().endOf('day').format('YYYYMMDD'));
	// const [tabKey, setTabKey] = useState(1);
	// const [alerts, setAlerts] = useState([]);
	const [initialData, setInitialData] = useState([]);
	const [showModalDetailedDescription, setShowModalDetailedDescription] = useState(false);
	const [alertCodeDescription, setAlertCodeDescription] = useState('');
	const isWebfleetMode = webfleetDeviceId.length > 0;

	// console.log('PROPS CameraHealth: ', props)
	// console.log('STATE CameraHealth: ', state)

	// useEffect(() => {
	// 	console.log('componentDidMount CameraHealth')
	// 	// getHealthAlerts()
	// }, [])

	useEffect(() => {
		// console.log('componentDidUpdate CameraHealth')
		if (company && company.company_id) {
			// getHealthAlerts(company.company_id)
			getCameraHealthAlerts()
		}
	}, [company.company_id])

	const getCameraHealthAlerts = () => {
		// const tmpCameraHealthAlerts = [
		// 	{device_id: 3868616673, vehicle_name: 'Sergey DV6', serial_number: '015974000000868', group_name: "DV6", 
		// 		status: [
		// 			{id: 1, err_code:'C100', err_type:'warn', is_ack:false}, 
		// 			{id: 2, err_code:'E001', err_type:'crit', is_ack:false},
		// 			{id: 3, err_code:'SD20', err_type:'warn', is_ack:true},
		// 			{id: 4, err_code:'SD27', err_type:'crit', is_ack:false}
		// 		],
		// 		silenced: ['C100', 'SD27'],
		// 	},
		// 	{device_id: 68421682, vehicle_name: 'Brian_Office3_DV4', serial_number: null, group_name: "API Test Group",
		// 		status: [
		// 			{id: 5, err_code:'E005', err_type:'warn', is_ack:false},
		// 			{id: 6, err_code:'E002', err_type:'warn', is_ack:true}
		// 		]
		// 	},
		// 	{device_id: 68421683, vehicle_name: 'Avery', serial_number: '015974000000454', group_name: "API Test Group", 
		// 		status: [
		// 			{id: 7, err_code:'E002', err_type:'crit', is_ack:true}
		// 		],
		// 		silenced: ['E002'],
		// 	},
		// 	{device_id: 3775624846, vehicle_name: 'ATOM NONOP1', serial_number: '015974000015742', group_name: "Installed Devices", 
		// 		status: [
		// 			{id: 8, err_code:'E002', err_type:'crit', is_ack:false}
		// 		],
		// 		silenced: ['C100', 'E002'],
		// 	},
		// ]

		// // const initialGroups = tmpCameraHealthAlerts.reduce((totalGroups, { group_name }) => {
		// // 	if (group_name) {
		// // 		!totalGroups.includes(group_name) && totalGroups.push(group_name)
		// // 	}
		// // 	else {
		// // 		!totalGroups.includes('No group') && totalGroups.push('No group')
		// // 	}
		// // 	return totalGroups.sort()
		// // }, [])

		// let alerts = tmpCameraHealthAlerts
		// if (isWebfleetMode) {
		// 	alerts = [tmpCameraHealthAlerts[0]]
		// }

		// const initialGroups = []
		// const errorCodes = []
		// const errorTypes = []
		// alerts.forEach(({group_name, status}) => {
		// 	initialGroups.push(group_name)
		// 	status.forEach(({err_code, err_type}) => {
		// 		errorCodes.push(err_code)
		// 		errorTypes.push(err_type)
		// 	})
		// })

		// setState((prevState) => (
		// 	{
		// 		...prevState,
		// 		alerts,
		// 		initialAlerts: JSON.parse(JSON.stringify(alerts)),
		// 		groups: _.uniq(initialGroups).sort(),
		// 		// statuses: _.union(_.uniq(errorTypes).sort(), _.uniq(errorCodes).sort()).map((item) => ({status:item, checked: true}))
		// 		statuses: _.union(_.uniq(errorTypes).sort(), _.uniq(errorCodes).sort())
		// 	}
		// ))
		// // updateCameraHealthAlerts(alerts)

		// gets devices alerts/errors for company or for device when is isWebfleetMode
		let funcName
		if (isWebfleetMode) {
			funcName = apiCallGet('/devices/{device_id}/camera_health_error', { device_id: webfleetDeviceId })
		}
		else {
			funcName = apiCallGet('/companies/{company_id}/camera_health_error', { company_id: company.company_id })
		}

		toggleLoader(true);
		funcName
			.then(res => {
				toggleLoader(false)
				const { device_errors } = res.data.response
				// const alerts = Array.isArray(device_errors) ? device_errors : [device_errors] // webfleet request returns object instead of array

				// FWSD-6990, temporary locked displaing the  'E001','E003' errors
				const resAlerts = Array.isArray(device_errors) ? device_errors : [device_errors] // webfleet request returns object instead of array
				const alerts = []
				resAlerts.forEach((item) => {
					item.status = item.status.filter(({ err_code }) => !['E001', 'E003'].includes(err_code))

					//For internal users we have to display all of error BUT for other users just SD20 
					if (!isInternalUser(userRole)) {
						item.status = item.status.filter(({err_code}) => err_code === 'SD20')
					}

					if (item.status.length) {
						alerts.push(item)
					}
				})
				//***

				const initialGroups = []
				const errorCodes = []
				const errorTypes = []
				alerts.forEach(({ group_name, status }) => {
					initialGroups.push(group_name)
					status.forEach(({ err_code, err_type }) => {
						errorCodes.push(err_code)
						errorTypes.push(err_type)
					})
				})

				setState((prevState) => (
					{
						...prevState,
						alerts,
						initialAlerts: JSON.parse(JSON.stringify(alerts)),
						groups: _.uniq(initialGroups).sort(),
						// statuses: _.union(_.uniq(errorTypes).sort(), _.uniq(errorCodes).sort()).map((item) => ({status:item, checked: true}))
						statuses: _.union(_.uniq(errorTypes).sort(), _.uniq(errorCodes).sort())
					}
				))
			})
			.catch(err => {
				toggleLoader(false);
				console.log('!getHealthAlerts error: ', err.response, err)
				toastr.error(t('toastrUnableGetDevice'));
			})
	}

	const setCameraHealthAlertStatus = (stateArgs) => {
		const params = stateArgs
		toggleLoader(true);
		// apiCall('POST', '/camera_health_error/{device_error_id}', params)
		apiCall('POST', '/devices/{device_id}/camera_health_error/acknowledge_clear', params)
			.then(res => {
				toggleLoader(false)
				toastr.success('', i18n.t('toastMessage.toastrChangesSaved'))
				setState((prevState) => ({ ...prevState, showModalErrorStatus: false }))
				getCameraHealthAlerts()
			})
			.catch((error) => {
				toggleLoader(false)
				console.log('!updateDevice error: ', error.response, error)
				toastr.error(t('errUnableSaveDevice'));
			})
	}

	const setCameraHealthDeviceSilanced = (stateArgs) => {
		const params = stateArgs
		toggleLoader(true);
		apiCall('POST', '/devices/{device_id}', params)
			.then(res => {
				toggleLoader(false)
				toastr.success('', i18n.t('toastMessage.toastrChangesSaved'))
				setState((prevState) => ({ ...prevState, showModalSilencedErrors: false }))
				getCameraHealthAlerts()
				// const obj = _.find(vehicles, ['device_id', device_id])
				// if (obj) {
				// 	obj.active = +value
				// }
				// setVehicles(vehicles);
			})
			.catch((error) => {
				toggleLoader(false)
				console.log('!updateDevice error: ', error.response, error)
				toastr.error(t('errUnableSaveSilence'));
			})
	}

	const filterColumn = (e, column, value) => {
		let newAlerts = [];
		if (column === 'clear_all') {
			if (!alerts.length) {
				setState((prevState) => ({ ...prevState, alerts: initialAlerts }))
			}
			else {
				setState((prevState) => ({ ...prevState, alerts: [] }))
			}
			return
		}

		if (!e.target.checked) {
			/* if (column === 'firmware_status' && value === 'firmware update') {
				newDevices = devices.filter(e => !e[column].includes('pending'))
			}
			else */
			// if (column === 'ignition') {
			// 	newDevices = devices.filter(e => !(e.online && (value ? (e.ignition && e.ignition.state === value) : ((e.ignition && e.ignition.state === value) || e.ignition === null))));
			// }
			// else if (column === 'sd_status') {
			// 	value === 'Needs Reformat' ?
			// 		newDevices = devices.filter(e => e.sd_status.recording_status !== 'Needs Reformat') :
			// 		newDevices = devices.filter(e => e.sd_status.recording_status === 'Needs Reformat')
			// }
			if (column === 'latest_hdderror') {
				value === 'Needs Reformat'
					? newAlerts = alerts.filter(e => _.isEmpty(e.latest_hdderror) || e.latest_hdderror.count === 0)
					: newAlerts = alerts.filter(e => !_.isEmpty(e.latest_hdderror) && e.latest_hdderror.count > 0)
			}
			else if (column === 'group_name') {
				newAlerts = alerts.filter(e => value === 'No group' ? (e.group_name && e.group_name.length) : e.group_name !== value);
			}
			else if (column === 'status') {
				if (['crit', 'warn'].includes(value)) {
					// status: [{err_code:'C100', err_type:'warn'
					newAlerts = alerts.filter(e => {
						return !_.some(e.status, ['err_type', value])
						// e.status.err_type !== value
					});
				}
				else {
					newAlerts = alerts.filter(e => {
						return !_.some(e.status, ['err_code', value])
						// e.status.err_type !== value
					});
				}

				// setState((prevState) => ({
				// 	...prevState,
				// 	statuses: prevState.statuses.map(
				// 	  el => el.status === value ? { ...el, checked: false }: el
				// 	)
				// }))
			}
			else {
				newAlerts = alerts.filter(e => e[column] !== value);
			}
			e.target.checked = true;
		}
		else {
			/* if (column === 'firmware_status' && value === 'firmware update') {
				newDevices = [...initialData.filter(e => e[column].includes('pending')), ...devices];
			}
			else */
			// if (column === 'ignition') {
			// 	newDevices = [...initialData.filter(e => e.online && (value ? (e.ignition && e.ignition.state === value) : ((e.ignition && e.ignition.state === value) || e.ignition === null))), ...devices];
			// }
			// else if (column === 'sd_status') {
			// 	newDevices = [...devices];
			// 	value === 'Needs Reformat' ?
			// 		newDevices.unshift(...initialData.filter(e => e.sd_status.recording_status === 'Needs Reformat')) :
			// 		newDevices.unshift(...initialData.filter(e => e.sd_status.recording_status !== 'Needs Reformat'))
			// }
			if (column === 'latest_hdderror') {
				newAlerts = [...alerts];
				value === 'Needs Reformat'
					? newAlerts.unshift(...initialData.filter(e => !_.isEmpty(e.latest_hdderror) && e.latest_hdderror.count > 0))
					: newAlerts.unshift(...initialData.filter(e => _.isEmpty(e.latest_hdderror) || e.latest_hdderror.count === 0))
			}
			else if (column === 'group_name') {
				newAlerts = [...initialAlerts.filter(e => value === 'No group' ? (!e.group_name || !e.group_name.length) : e.group_name === value), ...alerts];
			}
			else if (column === 'status') {
				if (['crit', 'warn'].includes(value)) {
					newAlerts = [...initialAlerts.filter(e => _.some(e.status, ['err_type', value])), ...alerts]
				}
				else {
					newAlerts = [...initialAlerts.filter(e => _.some(e.status, ['err_code', value])), ...alerts]
				}

				// setState((prevState) => ({
				// 	...prevState,
				// 	statuses: prevState.statuses.map(
				// 	  el => el.status === value ? { ...el, checked: true }: el
				// 	)
				// }))
			}
			else {
				newAlerts = [...initialAlerts.filter(e => e[column] === value), ...alerts];
			}
			e.target.checked = false;
		}

		setState((prevState) => ({ ...prevState, alerts: newAlerts }))
	}

	// const getHealthAlerts = (companyIdArg = null, startDateArg = null, endDateArg = null) => {
	// 	const companyId = companyIdArg || company.company_id;
	// 	const startDate = startDateArg || start_date;
	// 	const endDate = endDateArg || end_date;
	// 	const params = {
	// 		company_id: companyId,
	// 		// start_ts: start_date,
	// 		// end_ts: end_date,
	// 		start_ts: moment(moment(startDate).startOf('day')).utc().format('YYYYMMDDHHmmss'),
	// 		end_ts: moment(moment(endDate).endOf('day')).utc().format('YYYYMMDDHHmmss')
	// 	}

	// 	toggleLoader(true);
	// 	apiCallGet('/devices/health_alerts', params)
	// 		.then(res => {
	// 			toggleLoader(false);

	// 			// const { alerts } = res.data.response
	// 			// alerts = _.orderBy(alerts, ['timestamp'], ['desc']);
	// 			setAlerts(res.data.response.alerts);
	// 			setInitialData(res.data.response.alerts);
	// 		})
	// 		.catch(err => {
	// 			toggleLoader(false);
	// 			console.log('!getHealthAlerts error: ', err.response)
	// 			toastr.error('Unable to get alerts. Please try again later');
	// 		})
	// }

	const alertsSearch = (e) => {
		const value = e.target.value.toLowerCase()
		const filter = initialAlerts.filter(item => {
			return (
				(item.vehicle_name && item.vehicle_name.toLowerCase().includes(value))
			)
		})

		setState((prevState) => ({ ...prevState, alerts: filter, searchText: e.target.value }))
	}

	const changeDate = (tabKey, start_dateData, end_dateData) => {
		let new_start_date, new_end_date;
		if (tabKey) {
			const dateRange = getDateRangeFromTabKey(tabKey)
			new_start_date = dateRange[0];
			new_end_date = dateRange[1];
		}
		else {
			//manually selected date
			new_start_date = start_dateData ? moment(start_dateData).format('YYYYMMDD') : start_date;
			new_end_date = end_dateData ? moment(end_dateData).format('YYYYMMDD') : end_date;
		}
		setTabKey(tabKey);
		setStart_date(new_start_date);
		setEnd_date(new_end_date);
		getHealthAlerts(company.company_id, new_start_date, new_end_date)
	}

	const onSilencedClick = (e, device) => {
		e.preventDefault()

		setState((prevState) => ({ ...prevState, selectedDevice: device, showModalSilencedErrors: true }))

		// const description = alrtCode && DetailedCodeDescription[alrtCode] ? DetailedCodeDescription[alrtCode] : 'No Description'
		// setShowModalDetailedDescription(true);
		// setAlertCodeDescription(description);
	}

	const timestampFormatter = (cell, row) => {
		if (cell) {
			return momenttz(cell).format('M/DD/YYYY hh:mm:ss A')
		}
		return ''
	}

	const closeModal = () => {
		setShowModalDetailedDescription(false)
	}

	const setAlertsSort = (sortName, sortOrder) => {
		setState((prevState) => ({ ...prevState, sortName, sortOrder }))
	}

	const isFilterStatusChecked = (status) => {
		if (['crit', 'warn'].includes(status)) {
			return alerts.filter(e => {
				return _.some(e.status, ['err_type', status])
			}).length
		}
		return alerts.filter(e => {
			return _.some(e.status, ['err_code', status])
		}).length
	}

	const cameraNameFormatter = (cell, row) => {
		const editVehicle = (e, id) => {
			e.preventDefault();

			// browserHistory.push({ pathname: '/managevehicles/edit', state: { id: id, company, last_disconnected_ts: last_disconnected_ts } });
			browserHistory.push({ pathname: '/managevehicles/edit', state: { id, company, type: 'from_camera_health' } });
		}

		return (
			<div className="camera-name-wrapper">
				{isWebfleetMode
					? <>{cell || t('unnamed')}</>
					: <CustomButton variant="link" onClick={(e) => editVehicle(e, row.device_id /* , row.firmware */)}>{cell || t('unnamed')}</CustomButton>
				}
			</div>
		)
	}

	const statusFormatter = (cell, row) => {
		const res = []

		const warnErrors = _.groupBy(row.status.filter(({ err_type, is_ack }) => err_type === 'warn' && !is_ack), 'err_code')
		const critErrors = _.groupBy(row.status.filter(({ err_type, is_ack }) => err_type === 'crit' && !is_ack), 'err_code')
		const acknErrors = _.groupBy(row.status.filter(({ is_ack }) => is_ack), 'err_code')

		let groupCodes = Object.keys(warnErrors)
		for (var i = 0; i < groupCodes.length; i++) {
			const code = groupCodes[i]
			const errorsArr = warnErrors[code]
			res.push(
				<CustomButton
					key={`warn${code}`}
					variant="primary"
					style={{ marginRight: '5px' }}
					size="hs"
					prefix="warn"
					onClick={(e) => { setState((prevState) => ({ ...prevState, selectedAlert: { ...errorsArr[0], device_id: row.device_id }, showModalErrorStatus: true })) }}
				>
					{code}
					<span className="count">{errorsArr.length}</span>
				</CustomButton>
			)
		}

		groupCodes = Object.keys(critErrors)
		for (var i = 0; i < groupCodes.length; i++) {
			const code = groupCodes[i]
			const errorsArr = critErrors[code]
			res.push(
				<CustomButton
					key={`crit${code}`}
					variant="primary"
					style={{ marginRight: '5px' }}
					size="hs"
					prefix="crit"
					onClick={(e) => { setState((prevState) => ({ ...prevState, selectedAlert: { ...errorsArr[0], device_id: row.device_id }, showModalErrorStatus: true })) }}
				>
					{code}
					<span className="count">{errorsArr.length}</span>
				</CustomButton>
			)
		}

		groupCodes = Object.keys(acknErrors)
		for (var i = 0; i < groupCodes.length; i++) {
			const code = groupCodes[i]
			const errorsArr = acknErrors[code]
			res.push(
				<CustomButton
					key={`ackn${code}`}
					variant="primary"
					style={{ marginRight: '5px' }}
					size="hs"
					prefix="ackn"
					onClick={(e) => { setState((prevState) => ({ ...prevState, selectedAlert: { ...errorsArr[0], device_id: row.device_id }, showModalErrorStatus: true })) }}
				>
					{code}
					<span className="count">{errorsArr.length}</span>
				</CustomButton>
			)
		}

		return <>{res}</>
	}

	const viewFormatter = (cell, row) => {
		return <Link to="/" onClick={(e) => onSilencedClick(e, row)}>{t('linkView')}</Link>
	}

	// console.log('STATE CameraHealth: ', state)

	return (
		<div className="camera-health">
			{!isWebfleetMode &&
				<div className="page-subheader">
					<div className="subheader-section search-flex">
						<SearchBar term={searchText} onSearch={alertsSearch} placeholder="Search Vehicles..." />
					</div>
				</div>
			}

			<div className="description-wrapper">
				<div className="description left-column">
					<p>{t('descriptionLeftColumn_1')}</p>
					<p>{t('descriptionLeftColumn_2')}</p>
					<p>{t('descriptionLeftColumn_3')}</p>
				</div>
				<div className={`description right-column${!isInternalUser(userRole) ? ' customer' : ''}`}>
					<div className="status-definitions-wrapper">
						<div className="title">{t('titleStatusDefinitions')}</div>
						<div className="content">
							<div className="mb-10">{t('textLearnMore')}</div>
							<div className="status-definitions">
								{[
									{ err_code: 'WARN', type: 'warn', title: t('statusTitleWarningError'), body: t('statusBodyWarningError') },
									{ err_code: 'CRITICAL', type: 'crit', title: t('statusTitleCriticalError'), body: t('statusBodyCriticalError') },
									{ err_code: 'ACKN', type: 'ackn', title: t('statusTitleAcknowledgedError'), body: t('statusBodyAcknowledgedError') },
								].map((item) => {
									const { err_code, type, is_ack, title } = item

									if (!isInternalUser(userRole) && type === 'crit') {
										return
									}

									return (
										<CustomOverlayTrigger
											key={title}
											{...item}
											placement="top"
											trigger="click"
											closeBtn={true}
											prefix={type}
										>
											<div>
												<CustomButton
													variant="primary"
													size="hs"
													prefix={type}
												>
													{err_code}
												</CustomButton>
											</div>
										</CustomOverlayTrigger>
									)
								})}
							</div>
						</div>
					</div>
				</div>
			</div>

			<main className='main-content-block pt-0'>
				<BootstrapTable
					data={alerts}
					{...tableAdjustableColOptions}
					options={{
						...tableAdjustableColOptions.options,
						sortName,
						sortOrder,
						onSortChange: setAlertsSort,
						// defaultSortName: 'vehicle_name',
						// defaultSortOrder: 'asc',
					}}
				>
					<TableHeaderColumn
						width="15%"
						dataField="vehicle_name"
						dataFormat={cameraNameFormatter}
						className="with-filter"
					>
						<div className="filter-wrapper">
							<label className="filter-label">{t('labelCameraName')}</label>
							<span className="order" style={{ cursor: 'pointer', marginLeft: '4px' }}>
								{(sortName !== 'vehicle_name' || (sortName === 'vehicle_name' && sortOrder === 'desc')) &&
									<span className="dropdown" onClick={() => setAlertsSort('vehicle_name', 'asc')}><span className="caret"></span></span>
								}
								{(sortName !== 'vehicle_name' || (sortName === 'vehicle_name' && sortOrder === 'asc')) &&
									<span className="dropup" onClick={() => setAlertsSort('vehicle_name', 'desc')}><span className="caret"></span></span>
								}
							</span>
							<Dropdown drop id="type-filter-btn" className="filter-dropdown">
								<Dropdown.Toggle as='span' className="typeFilter" bsPrefix="no-caret">
									{filterIcon}
								</Dropdown.Toggle>
								<Dropdown.Menu className="typeFilterMenu">
									<div className="groups-header">
										<h5 className="category-title">{t('titleVehicles')}</h5>
									</div>
									{initialAlerts.map(({ device_id, vehicle_name }) => {
										return (
											<li
												key={device_id}
												title={vehicle_name}
											>
												<input
													type="checkbox"
													checked={alerts.filter(e => e.vehicle_name === vehicle_name).length}
													onChange={(e) => filterColumn(e, 'vehicle_name', vehicle_name)}
												/>
												<span className="groupName">{vehicle_name}</span>
											</li>
										)
									})}
									<div className="clear-all-container">
										<CustomButton variant="primary-outline" size="sm" prefix="clear-all" onClick={(e) => filterColumn(e, 'clear_all')}>
											{`${alerts.length === 0 ? t('select') : t('clear')} ${t('all')}`}
										</CustomButton>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</TableHeaderColumn>
					<TableHeaderColumn
						width="15%"
						dataField="group_name"
						dataFormat={formatGroup}
						className="with-filter"
					>
						<div className="filter-wrapper">
							<label className="filter-label">{t('labelGroupName')}</label>
							<span className="order" style={{ cursor: 'pointer', marginLeft: '4px' }}>
								{(sortName !== 'group_name' || (sortName === 'group_name' && sortOrder === 'desc')) &&
									<span className="dropdown" onClick={() => setAlertsSort('group_name', 'asc')}><span className="caret"></span></span>
								}
								{(sortName !== 'group_name' || (sortName === 'group_name' && sortOrder === 'asc')) &&
									<span className="dropup" onClick={() => setAlertsSort('group_name', 'desc')}><span className="caret"></span></span>
								}
							</span>
							<Dropdown drop id="type-filter-btn" className="filter-dropdown">
								<Dropdown.Toggle as='span' className="typeFilter" bsPrefix="no-caret">
									{filterIcon}
								</Dropdown.Toggle>
								<Dropdown.Menu className="typeFilterMenu">
									<div className="groups-header">
										<h5 className="category-title">{t('titleGroups')}</h5>
									</div>
									{groups.map(groupItem => {
										return (
											<li
												key={groupItem}
												title={groupItem}
											>
												<input
													type="checkbox"
													checked={groupItem === 'No group' ? alerts.filter(e => !e.group_name).length : alerts.filter(e => e.group_name === groupItem).length}
													onChange={(e) => filterColumn(e, 'group_name', groupItem)}
												/>
												<span className="groupName">{groupItem}</span>
											</li>
										)
									})}
									<div className="clear-all-container">
										<CustomButton variant="primary-outline" size="sm" prefix="clear-all" onClick={(e) => filterColumn(e, 'clear_all')}>
											{`${alerts.length === 0 ? t('select') : t('clear')} ${t('all')}`}
										</CustomButton>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</TableHeaderColumn>
					<TableHeaderColumn
						width="60%"
						dataField="status"
						dataFormat={statusFormatter}
						className="with-filter"
					>
						<div className="filter-wrapper">
							<label className="filter-label">{t('labelStatus')}</label>
							<Dropdown drop id="type-filter-btn" className="filter-dropdown">
								<Dropdown.Toggle as='span' className="typeFilter" bsPrefix="no-caret">
									{filterIcon}
								</Dropdown.Toggle>
								<Dropdown.Menu className="typeFilterMenu">
									<div className="groups-header">
										<h5 className="category-title">{t('titleErrors')}</h5>
									</div>
									{statuses.map((status) => {
										let name = status
										switch (status) {
											case 'crit':
												name = t('nameCritical')
												break
											case 'warn':
												name = t('nameWarning')
												break
										}

										return (
											<li
												key={status}
												title={status}
											>
												<input
													type="checkbox"
													checked={isFilterStatusChecked(status)}
													onChange={(e) => filterColumn(e, 'status', status)}
												/>
												<span className="groupName">{name}</span>
											</li>
										)
									})}
									<div className="clear-all-container">
										<CustomButton variant="primary-outline" size="sm" prefix="clear-all" onClick={(e) => filterColumn(e, 'clear_all')}>
											{`${alerts.length === 0 ? t('select') : t('clear')} ${t('all')}`}
										</CustomButton>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</TableHeaderColumn>
					<TableHeaderColumn
						width="10%"
						dataField="device_id"
						dataFormat={viewFormatter}
						isKey
					>
					{t('labelSilencedErrors')}
					</TableHeaderColumn>
				</BootstrapTable>
			</main>

			{showModalDetailedDescription && (
				<Modal
					show
					onHide={closeModal}
				>
					<Modal.Header closeButton>
						<Modal.Title>{t('titleDetailedDescription')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="text-center modal-text" style={{ margin: '20px' }}>
							{alertCodeDescription}
						</div>
					</Modal.Body>
					<Modal.Footer>
						<CustomButton variant="primary-outline" onClick={closeModal}>{t('btnClose')}</CustomButton>
					</Modal.Footer>
				</Modal>
			)}

			{showModalErrorStatus && (
				<ModalErrorStatus
					selectedAlert={selectedAlert}
					setCameraHealthAlert={setCameraHealthAlertStatus}
					onHide={() => setState((prevState) => ({ ...prevState, showModalErrorStatus: false }))}
				/>
			)}

			{showModalSilencedErrors && (
				<ModalSilencedErrors
					selectedDevice={selectedDevice}
					setCameraHealthAlert={setCameraHealthDeviceSilanced}
					isInternalUser={isInternalUser(userRole)}
					onHide={() => setState((prevState) => ({ ...prevState, showModalSilencedErrors: false }))}
				/>
			)}
		</div>
	)
}

CameraHealth.propTypes = {
	company: PropTypes.objectOf(PropTypes.any).isRequired,
	// cameraHealthAlerts: PropTypes.arrayOf(PropTypes.object).isRequired,
}

// export default connect(({ company }) => ({
// 	company: company.company
// 	}),
// 	dispatch => ({
// 		toggleLoader: (show) => {
// 			dispatch({ type: 'TOGGLE_LOADER', payload: show });
// 		}
// 	}))(CameraHealth);

const mapStateToProps = ({ user, company, assets, webfleetData }) => ({
	user: user.user,
	company: company.company,
	// cameraHealthAlerts: assets.cameraHealthAlerts,
	webfleetDeviceId: webfleetData.deviceId,
})

const mapDispatchToProps = dispatch => ({
	// updateCameraHealthAlerts: (devices) => {
	// 	dispatch({ type: 'SET_CAMERA_HEALTH_ALERTS', payload: devices });
	// },
	toggleLoader: (show) => {
		dispatch({ type: 'TOGGLE_LOADER', payload: show });
	}
})

export default connect(mapStateToProps, mapDispatchToProps)(CameraHealth);
