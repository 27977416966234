import React, { useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import Autocomplete from 'react-autocomplete'
import { connect } from 'react-redux'
// import _ from 'lodash'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'

import SearchSelect from '../../dump/menuComponents/SearchSelect'

// Used to debounce search functions.
// call .cancel() before invoking to cancel the previous invokations for
// desired behavior
// const debounce = _.debounce(func => func(), 1000)

const EventClipsSearchType = (props) => {
	// console.log('PROPS EventClipsSearchType: ', props)
	const { devices, drivers, searchCategory, autoValue, onSearchCategoryChange, searchVehicle, setPropStates, webfleetDeviceId } = props
	const { t } = useTranslation('translation', { keyPrefix: 'eventsPage.eventClipsSearchType' });
	const prevAutoValue = useRef(props.autoValue)
	const prevSearchCategory = useRef(props.searchCategory)

	const SEARCH_OPTIONS = {
		0: [
			{ value: 'vehicle', name: t('Vehicle') },
			{ value: 'driver', name: t('Driver') },
			{ value: 'event', name: t('Event') },
		],
		1: [
			{ value: 'driver', name: t('Driver') },
			{ value: 'event', name: t('Event') },
		],
	}

	// const debouncedChangeHandler = debounce(func => func(), 1000)
	// const debouncedChangeHandler = useMemo(
	// 	debounce(() => searchVehicle(), 1000)
	//   , []);
	// const debouncedChangeHandler = useCallback(
	// 	() => debounce(searchVehicle, 1000),
	// 	[]
	// );
	const debouncedChangeHandler = (debounce(searchVehicle, 1000)) //, [props.autoValue])

	// useEffect(() => {
	// 	if (prevAutoValue.current !== props.autoValue) {
	// 		searchVehicle()
	// 		prevAutoValue.current = props.autoValue
	// 	}		
	// }, [props.autoValue])
	useEffect(() => {
		// debugger
		if (prevAutoValue.current !== props.autoValue) {
			// debugger
			// searchVehicle()
			debouncedChangeHandler(props.autoValue)
			prevAutoValue.current = props.autoValue
		}
	}, [props.autoValue])

	// Stop the invocation of the debounced function after unmounting
	useEffect(() => {
		return () => {
			debouncedChangeHandler.cancel();
		}
	}, [/*props.autoValue,*/ debouncedChangeHandler]);

	useEffect(() => {
		// debugger
		if (prevSearchCategory.current !== props.searchCategory) {
			// debugger
			onSearchCategoryChange()
			// searchVehicle()
			prevSearchCategory.current = props.searchCategory
		}

	}, [props.searchCategory])

	const menuStyle = {
		borderRadius: '3px',
		boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
		background: 'rgba(255, 255, 255, 0.9)',
		padding: '2px 0',
		fontSize: '90%',
		position: 'absolute',
		overflow: 'auto',
		maxHeight: '200px',
		left: '0',
		top: 'auto',
	}

	const matchStateToTerm = (item, value) => {
		if (item.vehicle_name) {
			return item.vehicle_name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
		}

		return item.device_id.toString().toLowerCase().indexOf(value.toLowerCase()) !== -1;
	}

	const displayItemName = (item) => {
		return item.vehicle_name || item.device_id
	}

	const matchStateToTermDriver = (item, value) => {
		if (item.name) {
			return item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
		}

		return item.id.toLowerCase().indexOf(value.toLowerCase()) !== -1
	}

	const displayItemNameDriver = (item) => {
		return item.name || item.id
	}

	return (
		<SearchSelect
			placeholder={t('placeholderSelect')}
			value={searchCategory}
			// onChangeCb={onSearchCategoryChange}
			onChangeCb={(e) => setPropStates({ searchCategory: e.target.value })}
			optionsList={SEARCH_OPTIONS[(webfleetDeviceId.length > 0) * 1]}
		>
			{searchCategory === 'vehicle' && (
				<Autocomplete
					autoHighlight
					value={autoValue}
					inputProps={{ id: 'states-autocomplete' }}
					wrapperProps={{ className: 'search-autocomplete event-search-input md-width' }}
					getItemValue={item => item.vehicle_name || item.device_id}
					shouldItemRender={matchStateToTerm}
					onChange={(e, value) => {
						// debounce.cancel()
						// debouncedChangeHandler.cancel()
						// setPropStates({ autoValue: value }, () => debounce(searchVehicle))
						setPropStates({ autoValue: value })
						// debouncedChangeHandler(value)
					}}
					onSelect={(value, item) => {
						// setPropStates({ autoValue: value }, () => searchVehicle())
						setPropStates({ autoValue: value })
					}}
					renderMenu={(items, value, style) => {
						return <div className="items-list" style={{ ...style, ...menuStyle }} children={items} />
					}}
					renderItem={(item, isHighlighted) => (
						<div
							className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
							key={item.device_id}
						>
							{displayItemName(item)}
						</div>
					)}
					renderInput={ownProps => <input {...ownProps} placeholder={t('placeholderSearchVehicle')} className="form-control event-page-search" />}
					items={devices}
				/>
			)}

			{searchCategory === 'driver' && (
				<Autocomplete
					autoHighlight
					value={autoValue}
					inputProps={{ id: 'states-autocomplete' }}
					wrapperProps={{ className: 'search-autocomplete event-search-input md-width' }}
					getItemValue={item => item.name || item.id}
					shouldItemRender={matchStateToTermDriver}
					onChange={(e, value) => {
						// debounce.cancel()
						// setPropStates({ autoValue: value }, () => debounce(searchVehicle))
						setPropStates({ autoValue: value })
					}}
					onSelect={(value, item) => {
						// setPropStates({ autoValue: value }, () => searchVehicle())
						setPropStates({ autoValue: value })
					}}
					renderMenu={(items, value, style) => {
						return <div className="items-list" style={{ ...style, ...menuStyle }} children={items} />
					}}
					renderItem={(item, isHighlighted) => (
						<div
							className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
							key={item.id}
						>
							{displayItemNameDriver(item)}
						</div>
					)}
					renderInput={ownProps => <input {...ownProps} placeholder={t('placeholderSearchDriver')} className="form-control event-page-search" />}
					items={drivers}
				/>
			)}

			{searchCategory === 'event' && (
				<input
					type="text"
					placeholder={t('placeholderSearchEvent')}
					id="event-search-input"
					className="form-control event-page-search event-search-input md-width"
					value={autoValue}
					onChange={(e) => {
						// debounce.cancel();
						// setPropStates({ autoValue: e.target.value }, () => debounce(searchVehicle))
						setPropStates({ autoValue: e.target.value })
					}}
				/>
			)}
		</SearchSelect>
	)
}

EventClipsSearchType.propTypes = {
	devices: PropTypes.arrayOf(PropTypes.object).isRequired,
	drivers: PropTypes.arrayOf(PropTypes.object).isRequired,
	searchCategory: PropTypes.string.isRequired,
	autoValue: PropTypes.string.isRequired,
	onSearchCategoryChange: PropTypes.func.isRequired,
	searchVehicle: PropTypes.func.isRequired,
	setPropStates: PropTypes.func.isRequired,
	webfleetDeviceId: PropTypes.string,
}

EventClipsSearchType.defaultProps = {
	webfleetDeviceId: '',
}

export default EventClipsSearchType;
