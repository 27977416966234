import React, { useState } from 'react';
import { browserHistory } from 'react-router';
import { Button } from 'react-bootstrap';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useTranslation } from 'react-i18next';

import { createPartner } from '../../../action/CompaniesActions';
import CustomButton from '../../CustomButton';

import '../../dump/menuComponents/menuComponents.sass';

const PartnerAddContainer = (props) => {
    const { t, i18n } = useTranslation('translation', { keyPrefix: 'partnersPage.partnerAddContainer' });
    const { user, toggleLoader } = props;
    const user_role = user.roles[0];

    const [data, setData] = useState({
        company_name: '',
        company_city: '',
        company_state: '',
        company_zipcode: '',
        company_address: '',
        company_country: '',
        company_address2: '',
        company_phone_number: ''
    })
    const [modal, setModal] = useState({
        show: false,
        content: '',
        error: false
    })
    const [errMessage, setErrMessage] = useState({
        company_name: '',
        company_city: '',
        company_state: '',
        company_zipcode: '',
        company_address: '',
        company_country: '',
        company_address2: '',
        company_phone_number: '',
        billing_date: ''
    })
    const [companySaved, setCompanySaved] = useState(false)
    const [open, setOpen] = useState(false)
    const [createdPartner, setCreatedPartner] = useState('')

    const addAnotherPartner = () => {
        const data = {
            company_name: '',
            company_city: '',
            company_state: '',
            company_zipcode: '',
            company_address: '',
            company_country: '',
            billing_date: '',
            live_active: true,
            cutoff_when_overage: true,
            storage_active: true,
            trace_active: true
        };
        setCompanySaved(false)
        setData(data)
        setOpen(true)
    }

    const sendForm = (e) => {
        e.preventDefault();
    }

    const savePartner = (e) => {
        const error = checkForm(data);

        if (!error) {
            toggleLoader(true);
            createPartner({ data }, user_role)
                .then((res, err) => {
                    toggleLoader(false);
                    console.log('!createPartner res', res);
                    const created_partner = res.data.response.company;
                    setCompanySaved(true)
                    setCreatedPartner(created_partner)
                    props.getPartners();
                    props.addPartner(created_partner.company_name);
                    toastr.success("Success", t('addedNewPartner'));
                })
                .catch((error) => {
                    toggleLoader(false);
                    console.log('!createPartner error', error.response);
                    toastr.error(t('unableCreatePartner'))
                });
        }
    }

    const checkForm = (data) => {
        let error = false;
        let updatedErrMessage = { ...errMessage };

        for (let i in data) {
            if (i === "company_name") {
                if (data[i] === '') {
                    error = true;
                    updatedErrMessage[i] = i18n.t('partnersPage.errCheckForm.errNameRequired');
                } else if (!/^[a-zA-Z0-9 ]+$/.test(data[i])) {
                    error = true;
                    updatedErrMessage[i] = i18n.t('partnersPage.errCheckForm.errNameSpecialCharacters');
                } else if (data[i].length < 3) {
                    error = true;
                    updatedErrMessage[i] = i18n.t('partnersPage.errCheckForm.errNameMinLength');
                } else if (updatedErrMessage[i]) updatedErrMessage[i] = null;
            }
            if (i === "company_city") {
                if (data[i] === '') {
                    error = true;
                    updatedErrMessage[i] = i18n.t('partnersPage.errCheckForm.errCityRequired');
                } else if (data[i].length < 3) {
                    error = true;
                    updatedErrMessage[i] = i18n.t('partnersPage.errCheckForm.errCityMinLength');
                } else if (data[i].length > 50) {
                    error = true;
                    updatedErrMessage[i] = i18n.t('partnersPage.errCheckForm.errCityMaxLength');
                } else if (!/^[a-zA-Z0-9 ]+$/.test(data[i])) {
                    error = true;
                    updatedErrMessage[i] = i18n.t('partnersPage.errCheckForm.errCitySpecialCharacters');
                } else if (updatedErrMessage[i]) updatedErrMessage[i] = null;
            }
            if (i === "company_state") {
                if (data[i] === '') {
                    error = true;
                    updatedErrMessage[i] = i18n.t('partnersPage.errCheckForm.errStateRequired');
                } else if (!/^[a-zA-Z0-9 ]+$/.test(data[i])) {
                    error = true;
                    updatedErrMessage[i] = i18n.t('partnersPage.errCheckForm.errStateSpecialCharacters');
                } else if (data[i].length < 2) {
                    error = true;
                    updatedErrMessage[i] = i18n.t('partnersPage.errCheckForm.errStateMinLength');
                } else if (data[i].length > 50) {
                    error = true;
                    updatedErrMessage[i] = i18n.t('partnersPage.errCheckForm.errStateMaxLength');
                } else if (updatedErrMessage[i]) updatedErrMessage[i] = null;
            }
            if (i === "company_zipcode") {
                if (data[i] === '') {
                    error = true;
                    updatedErrMessage[i] = i18n.t('partnersPage.errCheckForm.errZIPrequired');
                } else if (!/^[a-zA-Z0-9 ]+$/.test(data[i])) {
                    error = true;
                    updatedErrMessage[i] = i18n.t('partnersPage.errCheckForm.errZIPSpecialCharacters');
                } else if (data[i].length < 3) {
                    error = true;
                    updatedErrMessage[i] = i18n.t('partnersPage.errCheckForm.errZIPMinLength');
                } else if (data[i].length > 20) {
                    error = true;
                    updatedErrMessage[i] = i18n.t('partnersPage.errCheckForm.errZIPMaxLength');
                } else if (updatedErrMessage[i]) updatedErrMessage[i] = null;
            }
            if (i === "company_address") {
                if (data[i] === '') {
                    error = true;
                    updatedErrMessage[i] = i18n.t('partnersPage.errCheckForm.errAddressRequired');
                } else if (data[i].length < 3) {
                    error = true;
                    updatedErrMessage[i] = i18n.t('partnersPage.errCheckForm.errAddressMinLength');
                } else if (updatedErrMessage[i]) updatedErrMessage[i] = null;
            }
            if (i === "company_address2" && data[i] != '') {
                if (data[i] === '') {
                    error = true;
                    updatedErrMessage[i] = i18n.t('partnersPage.errCheckForm.errAddressRequired');
                } else if (data[i].length < 3) {
                    error = true;
                    updatedErrMessage[i] = i18n.t('partnersPage.errCheckForm.errAddressMinLength');
                } else if (updatedErrMessage[i]) updatedErrMessage[i] = null;
            }
            if (i === "company_country") {
                if (data[i] === '') {
                    error = true;
                    updatedErrMessage[i] = i18n.t('partnersPage.errCheckForm.errCountryRequired');
                } else if (!/^[a-zA-Z0-9 ]+$/.test(data[i])) {
                    error = true;
                    updatedErrMessage[i] = i18n.t('partnersPage.errCheckForm.errCountrySpecialCharacters');
                } else if (data[i].length < 3) {
                    error = true;
                    updatedErrMessage[i] = i18n.t('partnersPage.errCheckForm.errCountryMinLength');
                } else if (data[i].length > 50) {
                    error = true;
                    updatedErrMessage[i] = i18n.t('partnersPage.errCheckForm.errCountryTooLong');
                } else if (updatedErrMessage[i]) updatedErrMessage[i] = null;
            }
            if (i === "company_phone_number" && data[i] != '') {
                if (isNaN(data[i])) {
                    error = true;
                    updatedErrMessage[i] = i18n.t('partnersPage.errCheckForm.errOnlyNumbers');
                } else if (data[i].length < 9) {
                    error = true;
                    updatedErrMessage[i] = i18n.t('partnersPage.errCheckForm.errPhoneNumberLength');
                } else if (data[i].length > 10) {
                    error = true;
                    updatedErrMessage[i] = i18n.t('partnersPage.errCheckForm.errPhoneNumberTooLong');
                }
                else if (updatedErrMessage[i]) updatedErrMessage[i] = null;
            }
        }
        if (!updatedErrMessage['company_name'] && !/^[a-zA-Z0-9 \_\-\.]+$/.test(data.company_name)) {
            error = true;
            updatedErrMessage['company_name'] = i18n.t('partnersPage.errCheckForm.errPartnerNameFormat');
        }

        setErrMessage(updatedErrMessage);
        return error;
    }

    const handleInputChange = (event, optional = null) => {
        const target = event.target,
            value = target.type === 'checkbox' ? target.checked : target.value,
            name = target.name

        const updatedData = { ...data }
        updatedData[name] = value;

        if (optional && value === '') {
            delete updatedData[name]
        }

        setData(updatedData);
    }

    const editPartner = () => {
        browserHistory.push({ pathname: "/partner/edit", state: { company: createdPartner } });
    }

    const toggleAddBlock = () => {
        setErrMessage({
            company_name: '',
            company_city: '',
            company_state: '',
            company_zipcode: '',
            company_address: '',
            company_country: '',
            billing_date: ''
        });

        setOpen(!open)
    }

    const { company_name, company_city, company_state, company_zipcode, company_address, company_address2, company_country, company_phone_number } = errMessage;

    if (companySaved) {
        return (
            <div className='add-asset-block'>
                <CustomButton variant="secondary-outline" onClick={addAnotherPartner}>{t('addAnotherPartner')}</CustomButton>
                <div className="label-wrapper">
                    <label>{t('text_1')}<b>{data.company_name}</b>{t('text_2')}</label>
                </div>
                <div>
                    <CustomButton variant="primary" onClick={editPartner}>{t('btnEditPartner')}</CustomButton>
                </div>
            </div>
        )
    }

    return (
        <div className='add-asset-block'>
            <CustomButton variant="secondary-outline" onClick={toggleAddBlock}>{open ? t('btnCancel') : t('btnAddNewPartner')}</CustomButton>
            {open &&
                <div className='edit-content-block'>
                    <form onSubmit={sendForm}>
                        <div className='two-column-grid'>
                            <div className={classnames({ 'has-error': company_name })}>
                                <label className="control-label">{t('labelName')}</label>
                                <input type="text" name="company_name" className="vehicle-search" onChange={handleInputChange} maxLength={50} />
                                {company_name && <span className="help-block">{company_name}</span>}
                            </div>

                            <div className={classnames({ 'has-error': company_city })}>
                                <label className="control-label">{t('labelCity')}</label>
                                <input type="text" name="company_city" className="vehicle-search" onChange={handleInputChange} />
                                {company_city && <span className="help-block">{company_city}</span>}
                            </div>

                            <div className={classnames({ 'has-error': company_state })}>
                                <label className="control-label">{t('labelState')}</label>
                                <input type="text" name="company_state" className="vehicle-search" onChange={handleInputChange} />
                                {company_state && <span className="help-block">{company_state}</span>}
                            </div>

                            <div className={classnames({ 'has-error': company_zipcode })}>
                                <label className="control-label">{t('labelZipCode')}</label>
                                <input type="text" name="company_zipcode" className="vehicle-search" onChange={handleInputChange} />
                                {company_zipcode && <span className="help-block">{company_zipcode}</span>}
                            </div>

                            <div className={classnames({ 'has-error': company_address })}>
                                <label className="control-label">{t('labelAddress')}</label>
                                <input type="text" name="company_address" className="vehicle-search" onChange={handleInputChange} />
                                {company_address && <span className="help-block">{company_address}</span>}
                            </div>

                            <div className={classnames('optional', { 'has-error': company_address2 })}>
                                <label className="control-label">{t('labelAddress2')}<span>{t('optional')}</span></label>
                                <input type="text" name="company_address2" className="vehicle-search" onChange={(e) => handleInputChange(e, true)} />
                                {company_address2 && <span className="help-block">{company_address2}</span>}
                            </div>

                            <div className={classnames({ 'has-error': company_country })}>
                                <label className="control-label">{t('labelCountry')}</label>
                                <input type="text" name="company_country" className="vehicle-search" onChange={handleInputChange} />
                                {company_country && <span className="help-block">{company_country}</span>}
                            </div>

                            <div className={classnames('optional', { 'has-error': company_phone_number })}>
                                <label className="control-label">{t('labelPhoneNumber')}<span>{t('optional')}</span></label>
                                <input type="text" name="company_phone_number" className="vehicle-search" onChange={(e) => handleInputChange(e, true)} />
                                {company_phone_number && <span className="help-block">{company_phone_number}</span>}

                            </div>
                        </div>

                    </form>

                    <CustomButton variant="primary" onClick={savePartner}>{t('btnAddPartner')}</CustomButton>
                </div>
            }
        </div>
    )
}

export default connect(
    state => ({
        user: state.user.user,
        company: state.company.company
    }),
    dispatch => ({
        addPartner: (partner) => {
            dispatch({ type: 'ADD_PARTNER_SUCCESS', payload: partner });
        },
        toggleLoader: (show) => {
            dispatch({ type: 'TOGGLE_LOADER', payload: show });
        }
    })
)(PartnerAddContainer);
