import React from 'react'

export const constants = {
	userRoles: {
		customer_service: 'customer',
		partner: 'partner',
		fleet_manager: 'fleet',
		group_manager: 'group',
		user: 'user',
		fleet_maintainer: 'maintainer',
		system_admin: 'admin',
		installer: 'installer',
		partner_view: 'partnerview',
		sales_demo: 'sales',
		storage_manager: 'smanager',
		storage_user: 'suser',
		custom_user: 'customuser'
	},

	roleNames: {
		user: 'User',
		partner: 'Partner',
		group_manager: 'Group Manager',
		fleet_maintainer: 'Fleet Maintainer',
		fleet_manager: 'Fleet Manager',
		notification_recipient: 'Notification Recipient',
		installer: 'Installer',
		partner_view: 'Partner View',
		system_admin: 'System Admin',
		sales_demo: 'Sales Support',
		storage_manager: 'Storage Manager',
		storage_user: 'Storage User',
		custom_user: 'Custom User',
		custom_partner: 'Custom Partner',
		customer_service: 'Customer Service',
		rosco_customer: 'Rosco Customer'
	},

	eventNames: {
		adas_fcw: 'Forward Collision',
		adas_ldw: 'Lane Departure',
		adas_pcw: 'Pedestrian Warning',
		gsensor: 'High G-Force',
		max_speed: 'Max Speed',
		distraction: 'Distraction',
		drowsiness: 'Drowsiness/Head Down',
		yawning: 'Yawning',
		phone_use: 'Phone Use',
		harshaccel: 'Harsh Acceleration',
		harshbraking: 'Harsh Braking',
		low_speeding: 'Low Speeding',
		moderate_speeding: 'Moderate Speeding',
		high_speeding: 'High Speeding',
		severe_speeding: 'Severe Speeding',
		idling: 'Idling',
		seatbelt: 'Seatbelt',
		smoking: 'Smoking',
		camera_block: 'Camera Block',
	},

	eventVars: {
		'Forward Collision': 'adas_fcw',
		'Lane Departure': 'adas_ldw',
		'Pedestrian Warning': 'adas_pcw',
		'High G-Force': 'gsensor',
		'Max Speed': 'max_speed',
		Distraction: 'distraction',
		'Drowsiness/Head Down': 'drowsiness',
		Yawning: 'yawning',
		'Phone Use/Hand to Head': 'phone_use',
		Seatbelt: 'seatbelt',
		Smoking: 'smoking',
		'Harsh Acceleration': 'harshaccel',
		'Harsh Braking': 'harshbraking',
		'Low Speed': 'low_speeding',
		'Moderate Speed': 'moderate_speeding',
		'High Speed': 'high_speeding',
		'Severe Speed': 'severe_speeding',
		Idling: 'idling',
	},

	availableCreateRolesPerUser: {
		system_admin: ['user', 'group_manager', 'partner', 'fleet_maintainer', 'fleet_manager', 'notification_recipient', 'installer', 'partner_view', 'sales_demo', 'custom_user', 'custom_partner'],
		customer_service: ['user', 'group_manager', 'partner', 'fleet_maintainer', 'fleet_manager', 'notification_recipient', 'installer', 'partner_view', 'sales_demo', 'custom_user', 'custom_partner'],
		partner: ['user', 'group_manager', 'fleet_manager', 'custom_user', 'notification_recipient', 'custom_partner'],
		custom_partner: ['user', 'group_manager', 'fleet_manager', 'custom_user', 'notification_recipient'],
		fleet_manager: ['user', 'group_manager', 'custom_user'],
		fleet_maintainer: ['user', 'group_manager'],
		group_manager: ['user']
	},

	availableCreateRolesWCS: {
		storage_manager: ['storage_user'],
		system_admin: ['storage_user', 'storage_manager', 'installer'],
		customer_service: ['storage_user', 'storage_manager', 'installer'],
	},

	matchPermissionsPages: [
		{
			perm_category_id: 1,
			page: '/',
		},
		{
			perm_category_id: 2,
			page: '/event',
		},
		{
			perm_category_id: 3,
			page: '/reports',
		},
		{
			perm_category_id: 5,
			page: '/managevehicles',
		},
		{
			perm_category_id: 6,
			page: '/managegroups',
		},
		{
			perm_category_id: 7,
			page: '/fleet-admin/feature-usage',
		},
		{
			perm_category_id: 8,
			page: '/fleet-admin',
		},
	],

	getTimezoneDB() {
		const timezoneDB = {};

		timezoneDB['Africa/Abidjan'] = 'GMT0';
		timezoneDB['Africa/Accra'] = 'GMT0';
		timezoneDB['Africa/Addis Ababa'] = 'EAT-3';
		timezoneDB['Africa/Algiers'] = 'CET-1';
		timezoneDB['Africa/Asmera'] = 'EAT-3';
		timezoneDB['Africa/Bamako'] = 'GMT0';
		timezoneDB['Africa/Bangui'] = 'WAT-1';
		timezoneDB['Africa/Banjul'] = 'GMT0';
		timezoneDB['Africa/Bissau'] = 'GMT0';
		timezoneDB['Africa/Blantyre'] = 'CAT-2';
		timezoneDB['Africa/Brazzaville'] = 'WAT-1';
		timezoneDB['Africa/Bujumbura'] = 'CAT-2';
		timezoneDB['Africa/Cairo'] = 'EET-2EEST-3,M4.5.5/0,M9.5.4/24';
		timezoneDB['Africa/Casablanca'] = 'WET0';
		timezoneDB['Africa/Ceuta'] = 'CET-1CEST-2,M3.5.0/2,M10.5.0/3';
		timezoneDB['Africa/Conakry'] = 'GMT0';
		timezoneDB['Africa/Dakar'] = 'GMT0';
		timezoneDB['Africa/Dar_es_Salaam'] = 'EAT-3';
		timezoneDB['Africa/Djibouti'] = 'EAT-3';
		timezoneDB['Africa/Douala'] = 'WAT-1';
		timezoneDB['Africa/El_Aaiun'] = 'WET0';
		timezoneDB['Africa/Freetown'] = 'WAT0';
		timezoneDB['Africa/Gaborone'] = 'CAT-2';
		timezoneDB['Africa/Harare'] = 'CAT-2';
		timezoneDB['Africa/Johannesburg'] = 'SAST-2';
		timezoneDB['Africa/Kampala'] = 'EAT-3';
		timezoneDB['Africa/Khartoum'] = 'EAT-3';
		timezoneDB['Africa/Kigali'] = 'CAT-2';
		timezoneDB['Africa/Kinshasa'] = 'WAT-1';
		timezoneDB['Africa/Lagos'] = 'WAT-1';
		timezoneDB['Africa/Libreville'] = 'WAT-1';
		timezoneDB['Africa/Lome'] = 'GMT0';
		timezoneDB['Africa/Luanda'] = 'WAT-1';
		timezoneDB['Africa/Lubumbashi'] = 'CAT-2';
		timezoneDB['Africa/Lusaka'] = 'CAT-2';
		timezoneDB['Africa/Malabo'] = 'WAT-1';
		timezoneDB['Africa/Maputo'] = 'CAT-2';
		timezoneDB['Africa/Maseru'] = 'SAST-2';
		timezoneDB['Africa/Mbabane'] = 'SAST-2';
		timezoneDB['Africa/Mogadishu'] = 'EAT-3';
		timezoneDB['Africa/Monrovia'] = 'GMT0';
		timezoneDB['Africa/Nairobi'] = 'EAT-3';
		timezoneDB['Africa/Ndjamena'] = 'WAT-1';
		timezoneDB['Africa/Niamey'] = 'WAT-1';
		timezoneDB['Africa/Nouakchott'] = 'GMT0';
		timezoneDB['Africa/Ouagadougou'] = 'GMT0';
		timezoneDB['Africa/Porto-Novo'] = 'WAT-1';
		timezoneDB['Africa/Sao_Tome'] = 'GMT0';
		timezoneDB['Africa/Timbuktu'] = 'GMT0';
		timezoneDB['Africa/Tripoli'] = 'EET-2';
		timezoneDB['Africa/Tunis'] = 'CET-1';
		timezoneDB['Africa/Windhoek'] = 'WAT-1WAST-2,M9.1.0/2,M4.1.0/2';
		timezoneDB['America/Adak'] = 'HAST10HADT9,M3.2.0/2,M11.1.0/2';
		timezoneDB['America/Anchorage'] = 'AKST9AKDT8,M3.2.0/2,M11.1.0/2';
		timezoneDB['America/Anguilla'] = 'AST4';
		timezoneDB['America/Antigua'] = 'AST4';
		timezoneDB['America/Araguaina'] = 'BRT3BRST2,M10.2.0/0,M2.3.0/0';
		timezoneDB['America/Aruba'] = 'AST4';
		timezoneDB['America/Asuncion'] = 'PYT4PYST3,M10.1.0/0,M3.1.0/0';
		timezoneDB['America/Atka'] = 'HAST10HADT9,M3.2.0/2,M11.1.0/2';
		timezoneDB['America/Barbados'] = 'AST4';
		timezoneDB['America/Belem'] = 'BRT3';
		timezoneDB['America/Belize'] = 'CST6';
		timezoneDB['America/Boa_Vista'] = 'AMT4';
		timezoneDB['America/Bogota'] = 'COT5';
		timezoneDB['America/Boise'] = 'MST7MDT6,M3.2.0/2,M11.1.0/2';
		timezoneDB['America/Buenos Aires'] = 'ART3';
		timezoneDB['America/Cambridge Bay'] = 'MST7MDT6,M3.2.0/2,M11.1.0/2';
		timezoneDB['America/Cancun'] = 'CST6CDT5,M5.1.0/2,M9.5.0/2';
		timezoneDB['America/Caracas'] = 'VET4';
		timezoneDB['America/Catamarca'] = 'ART3';
		timezoneDB['America/Cayenne'] = 'GFT3';
		timezoneDB['America/Cayman'] = 'EST5';
		timezoneDB['America/Chicago'] = 'CST6CDT5,M3.2.0/2,M11.1.0/2';
		timezoneDB['America/Chihuahua'] = 'MST7MDT6,M5.1.0/2,M9.5.0/2';
		timezoneDB['America/Cordoba'] = 'ART3';
		timezoneDB['America/Costa Rica'] = 'CST6';
		timezoneDB['America/Cuiaba'] = 'AMT4AMST3,M10.2.0/0,M2.3.0/0';
		timezoneDB['America/Curacao'] = 'AST4';
		timezoneDB['America/Danmarkshavn'] = 'GMT0';
		timezoneDB['America/Dawson'] = 'PST8PDT7,M3.2.0/2,M11.1.0/2';
		timezoneDB['America/Dawson_Creek'] = 'MST7';
		timezoneDB['America/Denver'] = 'MST7MDT6,M3.2.0/2,M11.1.0/2';
		timezoneDB['America/Detroit'] = 'EST5EDT4,M3.2.0/2,M11.1.0/2';
		timezoneDB['America/Dominica'] = 'AST4';
		timezoneDB['America/Edmonton'] = 'MST7MDT6,M3.2.0/2,M11.1.0/2';
		timezoneDB['America/Eirunepe'] = 'ACT5';
		timezoneDB['America/El_Salvador'] = 'CST6';
		timezoneDB['America/Ensenada'] = 'PST8PDT7,M4.1.0/2,M10.5.0/2';
		timezoneDB['America/Fort_Wayne'] = 'EST5';
		timezoneDB['America/Fortaleza'] = 'BRT3BRST2,M10.2.0/0,M2.3.0/0';
		timezoneDB['America/Glace_Bay'] = 'AST4ADT3,M3.2.0/2,M11.1.0/2';
		timezoneDB['America/Godthab'] = 'WGT3WGST2,M3.5.0/-2,M10.5.0/-1';
		timezoneDB['America/Goose Bay'] = 'AST4ADT3,M3.2.0/0:01,M11.1.0/0:01';
		timezoneDB['America/Grand_Turk'] = 'EST5EDT4,M3.2.0/0,M11.1.0/0';
		timezoneDB['America/Grenada'] = 'AST4';
		timezoneDB['America/Guadeloupe'] = 'AST4';
		timezoneDB['America/Guatemala'] = 'CST6';
		timezoneDB['America/Guayaquil'] = 'ECT5';
		timezoneDB['America/Guyana'] = 'GYT4';
		timezoneDB['America/Halifax'] = 'AST4ADT3,M3.2.0/2,M11.1.0/2';
		timezoneDB['America/Havana'] = 'CST5CDT4,M3.2.0/0,M11.1.0/1';
		timezoneDB['America/Hermosillo'] = 'MST7';
		timezoneDB['America/Indiana/Indianapolis'] = 'EST5';
		timezoneDB['America/Indiana/Knox'] = 'EST5';
		timezoneDB['America/Indiana/Marengo'] = 'EST5';
		timezoneDB['America/Indiana/Vevay'] = 'EST5';
		timezoneDB['America/Indianapolis'] = 'EST5';
		timezoneDB['America/Inuvik'] = 'MST7MDT6,M3.2.0/2,M11.1.0/2';
		timezoneDB['America/Iqaluit'] = 'EST5EDT4,M3.2.0/2,M11.1.0/2';
		timezoneDB['America/Jamaica'] = 'EST5';
		timezoneDB['America/Jujuy'] = 'ART3';
		timezoneDB['America/Juneau'] = 'AKST9AKDT8,M3.2.0/2,M11.1.0/2';
		timezoneDB['America/Kentucky/Louisville'] = 'EST5EDT4,M3.2.0/2,M11.1.0/2';
		timezoneDB['America/Kentucky/Monticello'] = 'EST5EDT4,M3.2.0/2,M11.1.0/2';
		timezoneDB['America/Knox_IN'] = 'EST5';
		timezoneDB['America/La_Paz'] = 'BOT4';
		timezoneDB['America/Lima'] = 'PET5';
		timezoneDB['America/Los Angeles'] = 'PST8PDT7,M3.2.0/2,M11.1.0/2';
		timezoneDB['America/Louisville'] = 'EST5EDT4,M3.2.0/2,M11.1.0/2';
		timezoneDB['America/Maceio'] = 'BRT3BRST2,M10.2.0/0,M2.3.0/0';
		timezoneDB['America/Managua'] = 'CST6';
		timezoneDB['America/Manaus'] = 'AMT4';
		timezoneDB['America/Martinique'] = 'AST4';
		timezoneDB['America/Mazatlan'] = 'MST7MDT6,M5.1.0/2,M9.5.0/2';
		timezoneDB['America/Mendoza'] = 'ART3';
		timezoneDB['America/Menominee'] = 'CST6CDT5,M3.2.0/2,M11.1.0/2';
		timezoneDB['America/Merida'] = 'CST6CDT5,M5.1.0/2,M9.5.0/2';
		timezoneDB['America/Mexico_City'] = 'CST6';
		timezoneDB['America/Miquelon'] = 'PMST3PMDT2,M3.2.0/2,M11.1.0/2';
		timezoneDB['America/Monterrey'] = 'CST6CDT5,M5.1.0/2,M9.5.0/2';
		timezoneDB['America/Montevideo'] = 'UYT3';
		timezoneDB['America/Montreal'] = 'EST5EDT4,M3.2.0/2,M11.1.0/2';
		timezoneDB['America/Montserrat'] = 'AST4';
		timezoneDB['America/Nassau'] = 'EST5EDT4,M3.2.0/2,M11.1.0/2';
		timezoneDB['America/New_York'] = 'EST5EDT4,M3.2.0/2,M11.1.0/2';
		timezoneDB['America/Nipigon'] = 'EST5EDT4,M3.2.0/2,M11.1.0/2';
		timezoneDB['America/Nome'] = 'AKST9AKDT8,M3.2.0/2,M11.1.0/2';
		timezoneDB['America/Noronha'] = 'FNT2';
		timezoneDB['America/North_Dakota/Center'] = 'CST6CDT5,M3.2.0/2,M11.1.0/2';
		timezoneDB['America/Panama'] = 'EST5';
		timezoneDB['America/Pangnirtung'] = 'EST5EDT4,M3.2.0/2,M11.1.0/2';
		timezoneDB['America/Paramaribo'] = 'SRT3';
		timezoneDB['America/Phoenix'] = 'MST7';
		timezoneDB['America/Port_of_Spain'] = 'AST4';
		timezoneDB['America/Port-au-Prince'] = 'EST5';
		timezoneDB['America/Porto_Acre'] = 'ACT5';
		timezoneDB['America/Porto_Velho'] = 'AMT4';
		timezoneDB['America/Puerto_Rico'] = 'AST4';
		timezoneDB['America/Rainy_River'] = 'CST6CDT5,M3.2.0/2,M11.1.0/2';
		timezoneDB['America/Rankin_Inlet'] = 'CST6CDT5,M3.2.0/2,M11.1.0/2';
		timezoneDB['America/Recife'] = 'BRT3BRST2,M10.2.0/0,M2.3.0/0';
		timezoneDB['America/Regina'] = 'CST6';
		timezoneDB['America/Rio_Branco'] = 'ACT5';
		timezoneDB['America/Rosario'] = 'ART3';
		timezoneDB['America/Santiago'] = 'CLT4CLST3,M10.2.0/0,M3.2.0/0';
		timezoneDB['America/Santo_Domingo'] = 'AST4';
		timezoneDB['America/Sao_Paulo'] = 'BRT3BRST2,M10.2.0/0,M2.3.0/0';
		timezoneDB['America/Scoresbysund'] = 'EGT1EGST0,M3.5.0/0,M11.1.0/1';
		timezoneDB['America/Shiprock'] = 'MST7MDT6,M3.2.0/2,M11.1.0/2';
		timezoneDB['America/St_Johns'] = 'NST3:30NDT2:30,M4.1.0/0:01,M10.5.0/0:01';
		timezoneDB['America/St_Kitts'] = 'AST4';
		timezoneDB['America/St_Lucia'] = 'AST4';
		timezoneDB['America/St_Thomas'] = 'AST4';
		timezoneDB['America/St_Vincent'] = 'AST4';
		timezoneDB['America/Swift_Current'] = 'CST6';
		timezoneDB['America/Tegucigalpa'] = 'CST6';
		timezoneDB['America/Thule'] = 'AST4';
		timezoneDB['America/Thunder_Bay'] = 'EST5EDT4,M3.2.0/2,M11.1.0/2';
		timezoneDB['America/Tijuana'] = 'PST8PDT7,M4.1.0/2,M10.5.0/2';
		timezoneDB['America/Tortola'] = 'AST4';
		timezoneDB['America/Vancouver'] = 'PST8PDT7,M3.2.0/2,M11.1.0/2';
		timezoneDB['America/Virgin'] = 'AST4';
		timezoneDB['America/Whitehorse'] = 'PST8PDT7,M3.2.0/2,M11.1.0/2';
		timezoneDB['America/Winnipeg'] = 'CST6CDT5,M3.2.0/2,M11.1.0/3';
		timezoneDB['America/Yakutat'] = 'AKST9AKDT8,M3.2.0/2,M11.1.0/2';
		timezoneDB['America/Yellowknife'] = 'MST7MDT6,M3.2.0/2,M11.1.0/2';
		timezoneDB['Antarctica/Casey'] = 'WST-8';
		timezoneDB['Antarctica/Davis'] = 'DAVT-7';
		timezoneDB['Antarctica/DumontDUrville'] = 'DDUT-10';
		timezoneDB['Antarctica/Mawson'] = 'MAWT-6';
		timezoneDB['Antarctica/McMurdo'] = 'NZST-12NZDT-13,M10.1.0/2,M3.3.0/3';
		timezoneDB['Antarctica/Palmer'] = 'CLT4CLST3,M10.2.0/0,M3.2.0/0';
		timezoneDB['Antarctica/South_Pole'] = 'NZST-12NZDT-13,M10.1.0/2,M3.3.0/3';
		timezoneDB['Antarctica/Syowa'] = 'SYOT-3';
		timezoneDB['Antarctica/Vostok'] = 'VOST-6';
		timezoneDB['Arctic/Longyearbyen'] = 'CET-1CEST-2,M3.5.0/2,M10.5.0/3';
		timezoneDB['Asia/Aden'] = 'AST-3';
		timezoneDB['Asia/Almaty'] = 'ALMT-6ALMST-7,M3.5.0/0,M10.5.0/0';
		timezoneDB['Asia/Amman'] = 'EET-2EEST-3,M3.5.4/0,M9.5.4/1';
		timezoneDB['Asia/Anadyr'] = 'ANAT-12ANAST-13,M3.5.0/2,M10.5.0/3';
		timezoneDB['Asia/Aqtau'] = 'AQTT-4AQTST-5,M3.5.0/0,M10.5.0/0';
		timezoneDB['Asia/Aqtobe'] = 'AQTT-5AQTST-6,M3.5.0/0,M10.5.0/0';
		timezoneDB['Asia/Ashgabat'] = 'TMT-5';
		timezoneDB['Asia/Ashkhabad'] = 'TMT-5';
		timezoneDB['Asia/Baghdad'] = 'AST-3ADT-4,J91/3,J274/4';
		timezoneDB['Asia/Bahrain'] = 'AST-3';
		timezoneDB['Asia/Baku'] = 'AZT-4AZST-5,M3.5.0/1,M10.5.0/1';
		timezoneDB['Asia/Bangkok'] = 'ICT-7';
		timezoneDB['Asia/Beirut'] = 'EET-2EEST-3,M3.5.0/0,M10.5.0/0';
		timezoneDB['Asia/Bishkek'] = 'KGT-5KGST-6,M3.5.0/2:30,M10.5.0/2:30';
		timezoneDB['Asia/Brunei'] = 'BNT-8';
		timezoneDB['Asia/Calcutta'] = 'IST-5:30';
		timezoneDB['Asia/Choibalsan'] = 'CHOT-9';
		timezoneDB['Asia/Chongqing'] = 'CST-8';
		timezoneDB['Asia/Chungking'] = 'CST-8';
		timezoneDB['Asia/Colombo'] = 'LKT-6';
		timezoneDB['Asia/Dacca'] = 'BDT-6';
		timezoneDB['Asia/Damascus'] = 'EET-2EEST-3,J91/0,J274/0';
		timezoneDB['Asia/Dhaka'] = 'BDT-6';
		timezoneDB['Asia/Dili'] = 'TPT-9';
		timezoneDB['Asia/Dubai'] = 'GST-4';
		timezoneDB['Asia/Dushanbe'] = 'TJT-5';
		timezoneDB['Asia/Gaza'] = 'EET-2EEST-3,M4.3.5/0,M10.3.5/0';
		timezoneDB['Asia/Harbin'] = 'CST-8';
		timezoneDB['Asia/Hong_Kong'] = 'HKT-8';
		timezoneDB['Asia/Hovd'] = 'HOVT-7';
		timezoneDB['Asia/Irkutsk'] = 'IRKT-8IRKST-9,M3.5.0/2,M10.5.0/3';
		timezoneDB['Asia/Istanbul'] = 'EET-2EEST-3,M3.5.0/3,M10.5.0/4';
		timezoneDB['Asia/Jakarta'] = 'WIT-7';
		timezoneDB['Asia/Jayapura'] = 'EIT-9';
		timezoneDB['Asia/Jerusalem'] = 'IST-2IDT-3,J87/1,J276/1';
		timezoneDB['Asia/Kabul'] = 'AFT-4:30';
		timezoneDB['Asia/Kamchatka'] = 'PETT-12PETST-13,M3.5.0/2,M10.5.0/3';
		timezoneDB['Asia/Karachi'] = 'PKT-5';
		timezoneDB['Asia/Kashgar'] = 'CST-8';
		timezoneDB['Asia/Katmandu'] = 'NPT-5:45';
		timezoneDB['Asia/Krasnoyarsk'] = 'KRAT-7KRAST-8,M3.5.0/2,M10.5.0/3';
		timezoneDB['Asia/Kuala Lumpur'] = 'MYT-8';
		timezoneDB['Asia/Kuching'] = 'MYT-8';
		timezoneDB['Asia/Kuwait'] = 'AST-3';
		timezoneDB['Asia/Macao'] = 'CST-8';
		timezoneDB['Asia/Magadan'] = 'MAGT-11MAGST-12,M3.5.0/2,M10.5.0/3';
		timezoneDB['Asia/Manila'] = 'PHT-8';
		timezoneDB['Asia/Muscat'] = 'GST-4';
		timezoneDB['Asia/Nicosia'] = 'EET-2EEST-3,M3.5.0/3,M10.5.0/4';
		timezoneDB['Asia/Novosibirsk'] = 'NOVT-6NOVST-7,M3.5.0/2,M10.5.0/3';
		timezoneDB['Asia/Omsk'] = 'OMST-6OMSST-7,M3.5.0/2,M10.5.0/3';
		timezoneDB['Asia/Phnom_Penh'] = 'ICT-7';
		timezoneDB['Asia/Pontianak'] = 'WIT-7';
		timezoneDB['Asia/Pyongyang'] = 'KST-9';
		timezoneDB['Asia/Qatar'] = 'AST-3';
		timezoneDB['Asia/Rangoon'] = 'MMT-6:30';
		timezoneDB['Asia/Riyadh'] = 'AST-3';
		timezoneDB['Asia/Saigon'] = 'ICT-7';
		timezoneDB['Asia/Sakhalin'] = 'SAKT-10SAKST-11,M3.5.0/2,M10.5.0/3';
		timezoneDB['Asia/Samarkand'] = 'UZT-5';
		timezoneDB['Asia/Seoul'] = 'KST-9';
		timezoneDB['Asia/Shanghai'] = 'CST-8';
		timezoneDB['Asia/Singapore'] = 'SGT-8';
		timezoneDB['Asia/Taipei'] = 'CST-8';
		timezoneDB['Asia/Tashkent'] = 'UZT-5';
		timezoneDB['Asia/Tbilisi'] = 'GET-4GEST-5,M3.5.0/0,M10.5.0/0';
		timezoneDB['Asia/Tehran'] = 'IRT-3:30IRST-4:30,J80/0,J266/0';
		timezoneDB['Asia/Tel Aviv'] = 'IST-2IDT-3,J87/1,J276/1';
		timezoneDB['Asia/Thimbu'] = 'BTT-6';
		timezoneDB['Asia/Thimphu'] = 'BTT-6';
		timezoneDB['Asia/Tokyo'] = 'JST-9';
		timezoneDB['Asia/Ujung_Pandang'] = 'CIT-8';
		timezoneDB['Asia/Ulaanbaatar'] = 'ULAT-8';
		timezoneDB['Asia/Ulan Bator'] = 'ULAT-8';
		timezoneDB['Asia/Urumqi'] = 'CST-8';
		timezoneDB['Asia/Vientiane'] = 'ICT-7';
		timezoneDB['Asia/Vladivostok'] = 'VLAT-10VLAST-11,M3.5.0/2,M10.5.0/3';
		timezoneDB['Asia/Yakutsk'] = 'YAKT-9YAKST-10,M3.5.0/2,M10.5.0/3';
		timezoneDB['Asia/Yekaterinburg'] = 'YEKT-5YEKST-6,M3.5.0/2,M10.5.0/3';
		timezoneDB['Asia/Yerevan'] = 'AMT-4AMST-5,M3.5.0/2,M10.5.0/3';
		timezoneDB['Atlantic/Azores'] = 'AZOT1AZOST0,M3.5.0/0,M10.5.0/1';
		timezoneDB['Atlantic/Bermuda'] = 'AST4ADT3,M3.2.0/2,M11.1.0/2';
		timezoneDB['Atlantic/Canary'] = 'WET0WEST-1,M3.5.0/1,M10.5.0/2';
		timezoneDB['Atlantic/Cape_Verde'] = 'CVT1';
		timezoneDB['Atlantic/Faeroe'] = 'WET0WEST-1,M3.5.0/1,M10.5.0/2';
		timezoneDB['Atlantic/Jan_Mayen'] = 'CET-1CEST-2,M3.5.0/2,M10.5.0/3';
		timezoneDB['Atlantic/Madeira'] = 'WET0WEST-1,M3.5.0/1,M10.5.0/2';
		timezoneDB['Atlantic/Reykjavik'] = 'GMT0';
		timezoneDB['Atlantic/South Georgia'] = 'GST2';
		timezoneDB['Atlantic/St Helena'] = 'GMT0';
		timezoneDB['Atlantic/Stanley'] = 'FKT4FKST3,M9.1.0/2,M4.3.0/2';
		timezoneDB['Australia/ACT'] = 'EST-10EST-11,M10.5.0/2,M3.5.0/3';
		timezoneDB['Australia/Adelaide'] = 'CST-9:30CST-10:30,M10.5.0/2,M3.5.0/3';
		timezoneDB['Australia/Brisbane'] = 'EST-10';
		timezoneDB['Australia/Broken_Hill'] = 'CST-9:30CST-10:30,M10.5.0/2,M3.5.0/3';
		timezoneDB['Australia/Canberra'] = 'EST-10EST-11,M10.5.0/2,M3.5.0/3';
		timezoneDB['Australia/Darwin'] = 'CST-9:30';
		timezoneDB['Australia/Hobart'] = 'EST-10EST-11,M10.1.0/2,M3.5.0/3';
		timezoneDB['Australia/LHI'] = 'LHST-10:30LHST-11,M10.5.0/2,M3.5.0/2';
		timezoneDB['Australia/Lindeman'] = 'EST-10';
		timezoneDB['Australia/Lord_Howe'] = 'LHST-10:30LHST-11,M10.5.0/2,M3.5.0/2';
		timezoneDB['Australia/Melbourne'] = 'EST-10EST-11,M10.5.0/2,M3.5.0/3';
		timezoneDB['Australia/NSW'] = 'EST-10EST-11,M10.5.0/2,M3.5.0/3';
		timezoneDB['Australia/North'] = 'CST-9:30';
		timezoneDB['Australia/Perth'] = 'WST-8';
		timezoneDB['Australia/Queensland'] = 'EST-10';
		timezoneDB['Australia/South'] = 'CST-9:30CST-10:30,M10.5.0/2,M3.5.0/3';
		timezoneDB['Australia/Sydney'] = 'EST-10EST-11,M10.5.0/2,M3.5.0/3';
		timezoneDB['Australia/Tasmania'] = 'EST-10EST-11,M10.1.0/2,M3.5.0/3';
		timezoneDB['Australia/Victoria'] = 'EST-10EST-11,M10.5.0/2,M3.5.0/3';
		timezoneDB['Australia/West'] = 'WST-8';
		timezoneDB['Australia/Yancowinna'] = 'CST-9:30CST-10:30,M10.5.0/2,M3.5.0/3';
		timezoneDB['Brazil/DeNoronha'] = 'FNT2';
		timezoneDB['Brazil/East'] = 'BRT3BRST2,M10.2.0/0,M2.3.0/0';
		timezoneDB['Brazil/West'] = 'AMT4';
		timezoneDB['Canada/Atlantic'] = 'AST4ADT3,M3.2.0/2,M11.1.0/2';
		timezoneDB['Canada/Central'] = 'CST6CDT5,M3.2.0/2,M11.1.0/3';
		timezoneDB['Canada/East-Saskatchewan'] = 'CST6';
		timezoneDB['Canada/Eastern'] = 'EST5EDT4,M3.2.0/2,M11.1.0/2';
		timezoneDB['Canada/Mountain'] = 'MST7MDT6,M3.2.0/2,M11.1.0/2';
		timezoneDB['Canada/Newfoundland'] = 'NST3:30NDT2:30,M3.2.0/0:01,M11.1.0/0:01';
		timezoneDB['Canada/Pacific'] = 'PST8PDT7,M3.2.0/2,M11.1.0/2';
		timezoneDB['Canada/Saskatchewan'] = 'CST6';
		timezoneDB['Canada/Yukon'] = 'PST8PDT7,M3.2.0/2,M11.1.0/2';
		timezoneDB['Chile/Continental'] = 'CLT4CLST3,M10.2.0/0,M3.2.0/0';
		timezoneDB['Chile/EasterIsland'] = 'EAST6EASST5,M10.2.0/-2,M3.2.0/-2';
		timezoneDB.Cuba = 'CST5CDT4,M3.2.0/0,M11.1.0/1';
		timezoneDB.Egypt = 'EET-2EEST-3,M4.5.5/0,M9.5.4/24';
		timezoneDB.Eire = 'GMT0IST-1,M3.5.0/1,M10.5.0/2';
		timezoneDB['Europe/Amsterdam'] = 'CET-1CEST-2,M3.5.0/2,M10.5.0/3';
		timezoneDB['Europe/Andorra'] = 'CET-1CEST-2,M3.5.0/2,M10.5.0/3';
		timezoneDB['Europe/Athens'] = 'EET-2EEST-3,M3.5.0/3,M10.5.0/4';
		timezoneDB['Europe/Belfast'] = 'GMT0BST-1,M3.5.0/1,M10.5.0/2';
		timezoneDB['Europe/Belgrade'] = 'CET-1CEST-2,M3.5.0/2,M10.5.0/3';
		timezoneDB['Europe/Berlin'] = 'CET-1CEST-2,M3.5.0/2,M10.5.0/3';
		timezoneDB['Europe/Bratislava'] = 'CET-1CEST-2,M3.5.0/2,M10.5.0/3';
		timezoneDB['Europe/Brussels'] = 'CET-1CEST-2,M3.5.0/2,M10.5.0/3';
		timezoneDB['Europe/Bucharest'] = 'EET-2EEST-3,M3.5.0/3,M10.5.0/4';
		timezoneDB['Europe/Budapest'] = 'CET-1CEST-2,M3.5.0/2,M10.5.0/3';
		timezoneDB['Europe/Chisinau'] = 'EET-2EEST-3,M3.5.0/3,M10.5.0/4';
		timezoneDB['Europe/Copenhagen'] = 'CET-1CEST-2,M3.5.0/2,M10.5.0/3';
		timezoneDB['Europe/Dublin'] = 'GMT0IST-1,M3.5.0/1,M10.5.0/2';
		timezoneDB['Europe/Gibraltar'] = 'CET-1CEST-2,M3.5.0/2,M10.5.0/3';
		timezoneDB['Europe/Helsinki'] = 'EET-2EEST-3,M3.5.0/3,M10.5.0/4';
		timezoneDB['Europe/Istanbul'] = 'EET-2EEST-3,M3.5.0/3,M10.5.0/4';
		timezoneDB['Europe/Kaliningrad'] = 'EET-2EEST-3,M3.5.0/2,M10.5.0/3';
		timezoneDB['Europe/Kiev'] = 'EET-2EEST-3,M3.5.0/3,M10.5.0/4';
		timezoneDB['Europe/Lisbon'] = 'WET0WEST-1,M3.5.0/1,M10.5.0/2';
		timezoneDB['Europe/Ljubljana'] = 'CET-1CEST-2,M3.5.0/2,M10.5.0/3';
		timezoneDB['Europe/London'] = 'GMT0BST-1,M3.5.0/1,M10.5.0/2';
		timezoneDB['Europe/Luxembourg'] = 'CET-1CEST-2,M3.5.0/2,M10.5.0/3';
		timezoneDB['Europe/Madrid'] = 'CET-1CEST-2,M3.5.0/2,M10.5.0/3';
		timezoneDB['Europe/Malta'] = 'CET-1CEST-2,M3.5.0/2,M10.5.0/3';
		timezoneDB['Europe/Minsk'] = 'EET-2EEST-3,M3.5.0/2,M10.5.0/3';
		timezoneDB['Europe/Monaco'] = 'CET-1CEST-2,M3.5.0/2,M10.5.0/3';
		timezoneDB['Europe/Moscow'] = 'MSK-3MSD-4,M3.5.0/2,M10.5.0/3';
		timezoneDB['Europe/Nicosia'] = 'EET-2EEST-3,M3.5.0/3,M10.5.0/4';
		timezoneDB['Europe/Oslo'] = 'CET-1CEST-2,M3.5.0/2,M10.5.0/3';
		timezoneDB['Europe/Paris'] = 'CET-1CEST-2,M3.5.0/2,M10.5.0/3';
		timezoneDB['Europe/Prague'] = 'CET-1CEST-2,M3.5.0/2,M10.5.0/3';
		timezoneDB['Europe/Riga'] = 'EET-2EEST-3,M3.5.0/3,M10.5.0/4';
		timezoneDB['Europe/Rome'] = 'CET-1CEST-2,M3.5.0/2,M10.5.0/3';
		timezoneDB['Europe/Samara'] = 'SAMT-4SAMST-5,M3.5.0/2,M10.5.0/3';
		timezoneDB['Europe/San Marino'] = 'CET-1CEST-2,M3.5.0/2,M10.5.0/3';
		timezoneDB['Europe/Sarajevo'] = 'CET-1CEST-2,M3.5.0/2,M10.5.0/3';
		timezoneDB['Europe/Simferopol'] = 'EET-2EEST-3,M3.5.0/3,M10.5.0/4';
		timezoneDB['Europe/Skopje'] = 'CET-1CEST-2,M3.5.0/2,M10.5.0/3';
		timezoneDB['Europe/Sofia'] = 'EET-2EEST-3,M3.5.0/3,M10.5.0/4';
		timezoneDB['Europe/Stockholm'] = 'CET-1CEST-2,M3.5.0/2,M10.5.0/3';
		timezoneDB['Europe/Tallinn'] = 'EET-2';
		timezoneDB['Europe/Tirane'] = 'CET-1CEST-2,M3.5.0/2,M10.5.0/3';
		timezoneDB['Europe/Tiraspol'] = 'EET-2EEST-3,M3.5.0/3,M10.5.0/4';
		timezoneDB['Europe/Uzhgorod'] = 'EET-2EEST-3,M3.5.0/3,M10.5.0/4';
		timezoneDB['Europe/Vaduz'] = 'CET-1CEST-2,M3.5.0/2,M10.5.0/3';
		timezoneDB['Europe/Vatican'] = 'CET-1CEST-2,M3.5.0/2,M10.5.0/3';
		timezoneDB['Europe/Vienna'] = 'CET-1CEST-2,M3.5.0/2,M10.5.0/3';
		timezoneDB['Europe/Vilnius'] = 'EET-2';
		timezoneDB['Europe/Warsaw'] = 'CET-1CEST-2,M3.5.0/2,M10.5.0/3';
		timezoneDB['Europe/Zagreb'] = 'CET-1CEST-2,M3.5.0/2,M10.5.0/3';
		timezoneDB['Europe/Zaporozhye'] = 'EET-2EEST-3,M3.5.0/3,M10.5.0/4';
		timezoneDB['Europe/Zurich'] = 'CET-1CEST-2,M3.5.0/2,M10.5.0/3';
		timezoneDB['Great_Britain/Eire'] = 'GMT0BST-1,M3.5.0/1,M10.5.0/2';
		timezoneDB['Great_Britain/Great_Britain'] = 'GMT0BST-1,M3.5.0/1,M10.5.0/2';
		timezoneDB.Hong_Kong = 'HKT-8';
		timezoneDB.Iceland = 'GMT0';
		timezoneDB['Indian/Antananarivo'] = 'EAT-3';
		timezoneDB['Indian/Chagos'] = 'IOT-6';
		timezoneDB['Indian/Christmas'] = 'CXT-7';
		timezoneDB['Indian/Cocos'] = 'CCT-6:30';
		timezoneDB['Indian/Comoro'] = 'EAT-3';
		timezoneDB['Indian/Kerguelen'] = 'TFT-5';
		timezoneDB['Indian/Mahe'] = 'SCT-4';
		timezoneDB['Indian/Maldives'] = 'MVT-5';
		timezoneDB['Indian/Mauritius'] = 'MUT-4';
		timezoneDB['Indian/Mayotte'] = 'EAT-3';
		timezoneDB['Indian/Reunion'] = 'RET-4';
		timezoneDB.Iran = 'IRT-3:30IRST-4:30,J80/0,J266/0';
		timezoneDB.Israel = 'IST-2IDT-3,J87/1,J276/1';
		timezoneDB.Jamaica = 'EST5';
		timezoneDB.Japan = 'JST-9';
		timezoneDB.Kwajalein = 'MHT-12';
		timezoneDB.Libya = 'EET-2';
		timezoneDB['Mexico/BajaNorte'] = 'PST8PDT7,M4.1.0/2,M10.5.0/2';
		timezoneDB['Mexico/BajaSur'] = 'MST7MDT6,M5.1.0/2,M9.5.0/2';
		timezoneDB['Mexico/General'] = 'CST6';
		timezoneDB.Navajo = 'MST7MDT6,M3.2.0/2,M11.1.0/2';
		timezoneDB['New_Zealand/Chatham'] = 'CHAST-12:45CHADT-13:45,M10.1.0/2:45,M3.3.0/3:45';
		timezoneDB['New_Zealand/New Zealand'] = 'NZST-12NZDT-13,M10.1.0/2,M3.3.0/3';
		timezoneDB['Pacific/Apia'] = 'WST11';
		timezoneDB['Pacific/Auckland'] = 'NZST-12NZDT-13,M10.1.0/2,M3.3.0/3';
		timezoneDB['Pacific/Chatham'] = 'CHAST-12:45CHADT-13:45,M10.1.0/2:45,M3.3.0/3:45';
		timezoneDB['Pacific/Easter'] = 'EAST6EASST5,M10.2.0/-2,M3.2.0/-2';
		timezoneDB['Pacific/Efate'] = 'VUT-11';
		timezoneDB['Pacific/Enderbury'] = 'PHOT-13';
		timezoneDB['Pacific/Fakaofo'] = 'TKT10';
		timezoneDB['Pacific/Fiji'] = 'FJT-12';
		timezoneDB['Pacific/Funafuti'] = 'TVT-12';
		timezoneDB['Pacific/Galapagos'] = 'GALT6';
		timezoneDB['Pacific/Gambier'] = 'GAMT9';
		timezoneDB['Pacific/Guadalcanal'] = 'SBT-11';
		timezoneDB['Pacific/Guam'] = 'ChST-10';
		timezoneDB['Pacific/Honolulu'] = 'HST10';
		timezoneDB['Pacific/Johnston'] = 'HST10';
		timezoneDB['Pacific/Kiritimati'] = 'LINT-14';
		timezoneDB['Pacific/Kosrae'] = 'KOST-11';
		timezoneDB['Pacific/Kwajalein'] = 'MHT-12';
		timezoneDB['Pacific/Majuro'] = 'MHT-12';
		timezoneDB['Pacific/Marquesas'] = 'MART9:30';
		timezoneDB['Pacific/Midway'] = 'SST11';
		timezoneDB['Pacific/Nauru'] = 'NRT-12';
		timezoneDB['Pacific/Niue'] = 'NUT11';
		timezoneDB['Pacific/Norfolk'] = 'NFT-11:30';
		timezoneDB['Pacific/Noumea'] = 'NCT-11';
		timezoneDB['Pacific/Pago Pago'] = 'SST11';
		timezoneDB['Pacific/Palau'] = 'PWT-9';
		timezoneDB['Pacific/Pitcairn'] = 'PST8';
		timezoneDB['Pacific/Ponape'] = 'PONT-11';
		timezoneDB['Pacific/Port Moresby'] = 'PGT-10';
		timezoneDB['Pacific/Rarotonga'] = 'CKT10';
		timezoneDB['Pacific/Saipan'] = 'ChST-10';
		timezoneDB['Pacific/Samoa'] = 'SST11';
		timezoneDB['Pacific/Tahiti'] = 'TAHT10';
		timezoneDB['Pacific/Tarawa'] = 'GILT-12';
		timezoneDB['Pacific/Tongatapu'] = 'TOT-13';
		timezoneDB['Pacific/Truk'] = 'TRUT-10';
		timezoneDB['Pacific/Wake'] = 'WAKT-12';
		timezoneDB['Pacific/Wallis'] = 'WFT-12';
		timezoneDB['Pacific/Yap'] = 'YAPT-10';
		timezoneDB.Poland = 'CET-1CEST-2,M3.5.0/2,M10.5.0/3';
		timezoneDB.Portugal = 'WET0WEST-1,M3.5.0/1,M10.5.0/2';
		timezoneDB.Singapore = 'SGT-8';
		timezoneDB.Turkey = 'EET-2EEST-3,M3.5.0/3,M10.5.0/4';
		timezoneDB['US/Alaska'] = 'AKST9AKDT8,M3.2.0/2,M11.1.0/2';
		timezoneDB['US/Aleutian'] = 'HAST10HADT9,M3.2.0/2,M11.1.0/2';
		timezoneDB['US/Arizona'] = 'MST7';
		timezoneDB['US/Central'] = 'CST6CDT5,M3.2.0/2,M11.1.0/2';
		timezoneDB['US/East-Indiana'] = 'EST5';
		timezoneDB['US/Eastern'] = 'EST5EDT4,M3.2.0/2,M11.1.0/2';
		timezoneDB['US/Hawaii'] = 'HST10';
		timezoneDB['US/Indiana-Starke'] = 'EST5';
		timezoneDB['US/Michigan'] = 'EST5EDT4,M3.2.0/2,M11.1.0/2';
		timezoneDB['US/Mountain'] = 'MST7MDT6,M3.2.0/2,M11.1.0/2';
		timezoneDB['US/Pacific'] = 'PST8PDT7,M3.2.0/2,M11.1.0/2';
		timezoneDB['US/Samoa'] = 'SST11';

		return timezoneDB;
	},

	canbusItems: [
		{ value: '0', name: 'Off' },
		{ value: '2', name: 'OBD-II' },
		{ value: '4', name: 'J1939 Type 1' },
		{ value: '5', name: 'J1939 Type 2' },
	],

	defaultPartnerWhitelabelSettings: {
		themecolors: {
			primary: '#0071b6', secondary: '#ed1e79', tertiary: '#29a9e0', alternate: '#2e318c',
		},
		footer: '',
		favicon: '',
		title: '',
		header_logo: '',
		header_logo_ext: '',
		loader: '',
		// map_style: '',
		font: '',
	},

	availableFonts: [
		'Arial',
		'cursive',
		'Georgia',
		'Helvetica',
		'IBMPlexSans',
		'Roboto',
		'sans-serif',
		'Titillium',
	],
}

export const timezoneDBOptions = () => {
	const timezoneDB = constants.getTimezoneDB()
	const options = [];

	Object.keys(timezoneDB).forEach((key) => {
		if (Object.prototype.hasOwnProperty.call(timezoneDB, key)) {
			options.push(<option value={key} key={key}>{key}</option>)
		}
	})

	return options
}

export const isInternalUser = (userRole) => {
	return ['system_admin', 'customer_service'].includes(userRole)
}

export const isInternalUserWithPartner = (userRole) => {
	return ['system_admin', 'customer_service', 'partner'].includes(userRole)
}

export const isPartnerUser = (userRole) => {
	return ['partner', 'partner_view'].includes(userRole)
}

export const isDv6 = ({ serial_number, device_type }) => {
	if (serial_number) {
		return (serial_number && serial_number.length > 14 && serial_number.match(/^\d+$/)) ? true : false
	}

	return device_type === 'dv6'
}

export const faviconsFolder = '/images/whitelabel/favicon/';
export const headerLogosFolder = '/images/whitelabel/header_logos/';
export const headerLogosExtendedFolder = '/images/whitelabel/header_logos_ext/';
export const loadersFolder = '/images/whitelabel/loaders/';

export const faviconsList = ['tenna_favicon.png', 'vecima_favicon.png', 'Ingtech_Symbol_rgb_reverse.png', 'Ingtech_Symbol_rgb.png', 'ingtech_favicon.png', 'rovitracker_favicon.png'];
export const headerLogosList = ['tenna_logo.png', 'vecima_logo.png', 'Ingtech_Symbol_rgb_reverse.png', 'Ingtech_Symbol_rgb.png', 'ingtech_logo.png', 'rovitracker_logo.png'];
export const headerLogosExtendedList = [
	'tenna_logo_ext.png', 
	'tenna-logo-dark.svg', 
	'vecima_logo_ext.png', 
	'Ingtech_Logotype_rgb_reverse.png', 
	'Ingtech_Logotype_rgb.png',
	'ingtech_logo_ext.png',
	'rovitracker_logo_ext.png'
];
export const loadersList = ['tenna_loader.gif'];
export const googleMapStylesList = {
	default_map: [
		{
	    "elementType": "geometry",
	    "stylers": [
	      {
	        "color": "#f5f5f5"
	      }
	    ]
	  },
	  {
	    "elementType": "labels.icon",
	    "stylers": [
	      {
	        "visibility": "off"
	      }
	    ]
	  },
	  {
	    "elementType": "labels.text.fill",
	    "stylers": [
	      {
	        "color": "#616161"
	      }
	    ]
	  },
	  {
	    "elementType": "labels.text.stroke",
	    "stylers": [
	      {
	        "color": "#f5f5f5"
	      }
	    ]
	  },
	  {
	    "featureType": "administrative.country",
	    "elementType": "geometry.stroke",
	    "stylers": [
	      {
	        "color": "#484943"
	      }
	    ]
	  },
	  {
	    "featureType": "administrative.land_parcel",
	    "elementType": "labels.text.fill",
	    "stylers": [
	      {
	        "color": "#bdbdbd"
	      }
	    ]
	  },
	  {
	    "featureType": "administrative.neighborhood",
	    "elementType": "geometry.fill",
	    "stylers": [
	      {
	        "color": "#6a6a6a"
	      }
	    ]
	  },
	  {
	    "featureType": "administrative.province",
	    "elementType": "geometry.stroke",
	    "stylers": [
	      {
	        "color": "#8d8f85"
	      }
	    ]
	  },
	  {
	    "featureType": "landscape.natural.landcover",
	    "elementType": "geometry.fill",
	    "stylers": [
	      {
	        "color": "#cde1a8"
	      },
	      {
	        "visibility": "off"
	      }
	    ]
	  },
	  {
	    "featureType": "landscape.natural.terrain",
	    "elementType": "geometry.fill",
	    "stylers": [
	      {
	        "color": "#e4bc7e"
	      },
	      {
	        "visibility": "off"
	      }
	    ]
	  },
	  {
	    "featureType": "poi",
	    "elementType": "geometry",
	    "stylers": [
	      {
	        "color": "#eeeeee"
	      }
	    ]
	  },
	  {
	    "featureType": "poi",
	    "elementType": "labels.text.fill",
	    "stylers": [
	      {
	        "color": "#757575"
	      }
	    ]
	  },
	  {
	    "featureType": "poi.medical",
	    "elementType": "geometry",
	    "stylers": [
	      {
	        "color": "#f88787"
	      },
	      {
	        "visibility": "simplified"
	      }
	    ]
	  },
	  {
	    "featureType": "poi.medical",
	    "elementType": "labels.icon",
	    "stylers": [
	      {
	        "visibility": "on"
	      }
	    ]
	  },
	  {
	    "featureType": "poi.park",
	    "elementType": "geometry",
	    "stylers": [
	      {
	        "color": "#e5e5e5"
	      }
	    ]
	  },
	  {
	    "featureType": "poi.park",
	    "elementType": "geometry.fill",
	    "stylers": [
	      {
	        "color": "#bdd9aa"
	      }
	    ]
	  },
	  {
	    "featureType": "poi.park",
	    "elementType": "labels.text.fill",
	    "stylers": [
	      {
	        "color": "#9e9e9e"
	      }
	    ]
	  },
	  {
	    "featureType": "road",
	    "elementType": "geometry",
	    "stylers": [
	      {
	        "color": "#ffffff"
	      }
	    ]
	  },
	  {
	    "featureType": "road.arterial",
	    "elementType": "labels.text.fill",
	    "stylers": [
	      {
	        "color": "#757575"
	      }
	    ]
	  },
	  {
	    "featureType": "road.highway",
	    "elementType": "geometry",
	    "stylers": [
	      {
	        "color": "#dadada"
	      }
	    ]
	  },
	  {
	    "featureType": "road.highway",
	    "elementType": "labels.icon",
	    "stylers": [
	      {
	        "visibility": "on"
	      }
	    ]
	  },
	  {
	    "featureType": "road.highway",
	    "elementType": "labels.text.fill",
	    "stylers": [
	      {
	        "color": "#616161"
	      }
	    ]
	  },
	  {
	    "featureType": "road.local",
	    "elementType": "labels.text.fill",
	    "stylers": [
	      {
	        "color": "#9e9e9e"
	      }
	    ]
	  },
	  {
	    "featureType": "transit.line",
	    "elementType": "geometry",
	    "stylers": [
	      {
	        "color": "#e5e5e5"
	      }
	    ]
	  },
	  {
	    "featureType": "transit.station",
	    "elementType": "geometry",
	    "stylers": [
	      {
	        "color": "#eeeeee"
	      }
	    ]
	  },
	  {
	    "featureType": "water",
	    "elementType": "geometry",
	    "stylers": [
	      {
	        "color": "#c9c9c9"
	      }
	    ]
	  },
	  {
	    "featureType": "water",
	    "elementType": "geometry.fill",
	    "stylers": [
	      {
	        "color": "#b9e3fd"
	      }
	    ]
	  },
	  {
	    "featureType": "water",
	    "elementType": "labels.text.fill",
	    "stylers": [
	      {
	        "color": "#9e9e9e"
	      }
	    ]
	  }
	],
	default_map_old: [
		{
			elementType: 'geometry',
			stylers: [{ color: '#f5f5f5' }],
		},
		{
			elementType: 'labels.icon',
			stylers: [{ visibility: 'off' }],
		},
		{
			elementType: 'labels.text.fill',
			stylers: [{ color: '#616161' }],
		},
		{
			elementType: 'labels.text.stroke',
			stylers: [{ color: '#f5f5f5' }],
		},
		{
			featureType: 'administrative.land_parcel',
			elementType: 'labels.text.fill',
			stylers: [{ color: '#bdbdbd' }],
		},
		{
			featureType: 'poi',
			elementType: 'geometry',
			stylers: [{ color: '#eeeeee' }],
		},
		{
			featureType: 'poi',
			elementType: 'labels.text.fill',
			stylers: [{ color: '#757575' }],
		},
		{
			featureType: 'poi.park',
			elementType: 'geometry',
			stylers: [{ color: '#b1c1a4' }],
		},
		{
			featureType: 'poi.park',
			elementType: 'labels.text.fill',
			stylers: [{ color: '#9e9e9e' }],
		},
		{
			featureType: 'road',
			elementType: 'geometry',
			stylers: [{ color: '#ffffff' }],
		},
		{
			featureType: 'road.arterial',
			stylers: [{ color: '#969696' }],
		},
		{
			featureType: 'road.arterial',
			elementType: 'labels.text.fill',
			stylers: [{ color: '#757575' }],
		},
		{
			featureType: 'road.highway',
			elementType: 'geometry',
			stylers: [{ color: '#676767' }],
		},
		{
			featureType: 'road.highway',
			elementType: 'labels.text.fill',
			stylers: [{ color: '#616161' }],
		},
		{
			featureType: 'road.local',
			stylers: [{ color: '#b4c2c1' }],
		},
		{
			featureType: 'road.local',
			elementType: 'labels.text.fill',
			stylers: [{ color: '#9e9e9e' }],
		},
		{
			featureType: 'transit.line',
			elementType: 'geometry',
			stylers: [{ color: '#e5e5e5' }],
		},
		{
			featureType: 'transit.station',
			elementType: 'geometry',
			stylers: [{ color: '#eeeeee' }],
		},
		{
			featureType: 'water',
			elementType: 'geometry',
			stylers: [{ color: '#bdd5fb' }],
		},
		{
			featureType: 'water',
			elementType: 'labels.text.fill',
			stylers: [{ color: '#ffffff' }],
		},
	],
};
