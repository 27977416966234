import React from 'react'
import { connect } from 'react-redux'
import { Button, Modal } from 'react-bootstrap'
import CustomButton from '../../CustomButton'
import { useTranslation } from 'react-i18next'

const DisableSMSModal = (props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'accountPage.accountNotificationsContainer.disableSMSModal' });

    return (
        <Modal
            show={true}
            onHide={props.hideDisableSMSModal}
        >
            <Modal.Header closeButton>
                <Modal.Title>{t('disableSMSNotifications')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='notifications-modal-body modal-text'>
                    {t('modalText')}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton variant="delete" type='submit' onClick={props.disableAllSMS}>{t('btnDisable')}</CustomButton>
            </Modal.Footer>
            {props.loaderModal}
        </Modal>
    )
}

export default connect(
    state => ({
        loaderModal: state.loader.loaderModal
    })
)(DisableSMSModal);
