import React, { useState, useEffect } from 'react'
import { browserHistory } from 'react-router'
import moment from 'moment'
import { toastr } from 'react-redux-toastr'
import { useTranslation } from 'react-i18next'

import CustomButton from '../../../CustomButton'
import Auth from '../../../Auth'

import { getUserInfo, getDokuWikiEULA, agreeDokuWikiEULA } from '../../../../action/ClientActions'

const EulaAgreementPage = (props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'loginForms.eulaAgreementPage' });
    console.log('PROPS EulaModal: ', props)
    const { onHide } = props

    const [dokuWikiEula, setDokuWikiEula] = useState('')
    const [agreeEula, setAgreeEula] = useState(false)

    useEffect(() => {
        document.body.classList.add('body-bg');

        if (moment().hour() > 21 || moment().hour() < 6) {
            document.body.classList.add('bg-7')
        }
        else {
            let day = moment().day();
            day = day === 7 ? 6 : day
            day && document.body.classList.add('bg-' + day)
        }

        const modalBodyCustom = document.getElementsByClassName('modal-body-custom')
        modalBodyCustom[0].addEventListener('scroll', scrollEvent)

        getDokuWikiEULA(location.hostname)
            .then(res => {
                setDokuWikiEula(res.data)
            })
            .catch(error => {
                console.log('getDokuWikiEULA error: ', error.response, error)
            })

        getUserInfo()
            .then(res => {
                if (res.data.response.user.eula_was_viewed) {
                    // browserHistory.push('/')
                    toastr.warning(t('EULAaccepted'))
                }
            })
            .catch(error => {
                console.log('getUserInfo error: ', error.response, error)
            })

        return () => {
            document.body.classList.remove('body-bg')
            document.removeEventListener('scroll', scrollEvent)
        }
    }, [])

    const scrollEvent = (event) => {
        const { offsetHeight, scrollHeight, scrollTop } = event.target
        const result = scrollHeight - offsetHeight
        const percent = Math.round((scrollTop / result) * 100)

        if (percent > 85) {
            setAgreeEula(true)
        }
    }

    const onAgreeEula = () => {
        agreeDokuWikiEULA()
            .then(res => {
                // new Auth().authenticateUser(true, props.data.params.token, 1)
                new Auth().authenticateUser(true, 1, 1)
                browserHistory.push({ pathname: '/account/settings', state: { status: 'firstly' } })
            })
            .catch(error => {
                console.log('agreeDokuWikiEula error: ', error);
            });
    };

    const disagreeEula = () => {
        new Auth().deauthenticateUser()
        location.href = '/'
    }

    return (
        <div className="modal-eula">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="text-center mb-20">{t('textEndUserLicense')}</h2>
                        <div dangerouslySetInnerHTML={{ __html: t('textEULA') }}></div>
                    </div>
                    <div className='modal-body modal-body-custom'>
                        <div dangerouslySetInnerHTML={{ __html: dokuWikiEula }} />
                    </div>
                    <div className="modal-footer text-center">
                        <div dangerouslySetInnerHTML={{ __html: t('textEULAAccept') }}  ></div>
                        <div>
                            <CustomButton
                                disabled={!agreeEula}
                                onClick={onAgreeEula}
                                variant="primary"
                                size="sm"
                                title={t('btnTitleAgreeEula')}
                                prefix={`${!agreeEula ? 'custom-disabled' : ''}`}
                            >
                                {t('btnAgreeEula')}
                            </CustomButton>
                        </div>
                        <div>
                            <CustomButton
                                variant="link"
                                prefix="red mr-0"
                                onClick={disagreeEula}
                            >
                                {t('btnDecline')}
                            </CustomButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EulaAgreementPage;
