import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { toastr } from 'react-redux-toastr'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { apiCall } from '../../../action/RouterActions'
import CustomButton from '../../CustomButton'

const ModalEventLabel = (props) => {
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'components.advancedPlayer.modalEventLabel' });
	const { event, onSaveEventLabel, onHide, toggleLoader } = props;
	const [label, setLabel] = useState(event.event_label || "");

	const saveLabel = () => {
		toggleLoader(true);

		const params = { event_label: label };
		apiCall('POST', `/event/${event.id}/label`, params)
			.then(() => {
				onSaveEventLabel(label);
				toggleLoader(false);
				toastr.success('', t('displayLabelSaved'));
				onHide();
			})
			.catch((error) => {
				toggleLoader(false);
				let errDescription = i18n.t('errorMessage.errDescription');
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}

	return (
		<Modal
			show
			onHide={onHide}
			className="modalEventLabel modal-lg-size"
		>
			<Modal.Header closeButton closeVariant="white">
				<Modal.Title id="contained-modal-title-lg">
					{`${event.event_label ? t('edit') : t('add')} ${t('displayLabel')}`}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div>
					<p>{t('textBody')}</p>
					<div className="col-xs-6 col-sm-6 form-group" style={{ paddingTop: "5px" }}>
						<label className="control-label">{t('newDisplayLabel')}</label>
						<input type="text" className="vehicle-search" value={label} onChange={e => setLabel(e.target.value)} style={{ width: "350px" }} />
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<div>
					<CustomButton variant="primary-outline" onClick={onHide}>
						{t('btnBack')}
					</CustomButton>
					<CustomButton variant="primary" onClick={saveLabel}>
						{t('btnSave')}
					</CustomButton>
				</div>
			</Modal.Footer>
		</Modal>
	);
}

ModalEventLabel.propTypes = {
	event: PropTypes.objectOf(PropTypes.any).isRequired,
	onHide: PropTypes.func.isRequired
}

export default connect(
	null,
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(ModalEventLabel);
