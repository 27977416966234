import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { useTranslation } from 'react-i18next'

import IconTooltip from '../../component/dump/menuComponents/IconTooltip'
import { constants } from '../../component/helpers/constants'

import { tableExportOptions } from '../../component/helpers/table'
import { formatViewEvent, formatTimestamp, formatDate } from '../../component/helpers/tablesFuncHelpers'

const DriversScoreTable = (props) => {
	console.log('DriversScoreTable PROPS: ', props)
	const { t } = useTranslation('translation', { keyPrefix: 'driversPage.driversScoreTable' });

	const formatEventTimestamp = (cell, row) => {
		if (['Idling', 'Total Events Score', 'Total Speeding Score'].includes(row.type)) {
			return `${t('textSince')} ${formatDate(cell, row)}`
		}
		return formatTimestamp(cell, false)
	}

	const formatWeightLabel = (cell) => {
		const eventVariable = constants.eventVars[cell];
		const eventName = props.weights.find(event => event.name === eventVariable)
		return eventName ? eventName.weight : '-'
	}

	const formatViewEventColumn = (cell, row) => {
		if (row.type !== 'Max Speed' && row.type.includes('Speed')) {
			return '-'
		}
		if (['Idling', 'Total Events Score', 'Total Speeding Score'].includes(row.type) && row.event_id > 200) {
			return <div>
				<strong className="error-label">{t('labelHighImpact')}</strong>
				<IconTooltip
					id={`idling-${row.event_id}-tooltip`}
					description={t('descriptionHighImpact')}
				/>
			</div>
		}
		if (row.type === 'Idling') {
			return <div>
				<strong>{t('labelImpactScore')} +{Math.round(row.event_id)}</strong>
				<IconTooltip
					id={`idling-${row.event_id}-tooltip`}
					description={t('descriptionImpactScore')}
				/>
			</div>
		}
		if (row.type === 'Total Events Score') {
			return <div>
				<strong>{t('labelImpactScore')} +{Math.round(row.event_id)}</strong>
				<IconTooltip
					id={`total-events-${row.event_id}-tooltip`}
					description={t('descriptionEventsScore', { alertOnly: "Alert Only" })}
				/>
			</div>
		}
		if (row.type === 'Total Speeding Score') {
			return <div>
				<strong>{t('labelImpactScore')} +{Math.round(row.event_id)}</strong>
				<IconTooltip
					id={`speeding-${row.event_id}-tooltip`}
					description={t('descriptionSpeedingScore')}
				/>
			</div>
		}
		return formatViewEvent(cell, row)
	}

	const formatViewEventColumnCSV = (cell, row) => {
		if (row.type !== 'Max Speed' && row.type.includes('Speed')) {
			return '-'
		}
		if (['Idling', 'Total Events Score', 'Total Speeding Score'].includes(row.type)) {
			return `${t('textImpactOnScore')} ${Math.round(row.event_id)}`
		}
		return `${t('textEventID')} ${row.event_id}`
	}

	return (
		<BootstrapTable
			data={props.data}
			// data={expandedData}
			{...tableExportOptions}
			csvFileName={`${t('csvFileName')}.csv`}
		>
			<TableHeaderColumn
				width="10%"
				dataField="timestamp"
				dataSort
				dataFormat={formatEventTimestamp}
				csvFormat={formatEventTimestamp}
				csvHeader={t('headerDate/Time')}
			>
				{t('headerDate/Time')}
			</TableHeaderColumn>
			<TableHeaderColumn
				width="10%"
				dataField="type"
				dataSort
				csvHeader={t('csvHeaderEventType')}
			>
				{t('headerType')}
			</TableHeaderColumn>
			<TableHeaderColumn
				width="10%"
				dataField="id"
				isKey
				dataFormat={formatViewEventColumn}
				csvHeader={t('headerAction')}
				csvFormat={formatViewEventColumnCSV}
			>
				{t('headerAction')}
			</TableHeaderColumn>
			<TableHeaderColumn
				width="10%"
				dataField="type"
				dataFormat={formatWeightLabel}
				csvHeader={t('csvHeaderWeight')}
				csvFormat={formatWeightLabel}
			>
				{t('headerEventWeight')}
				<IconTooltip
					id="weight-tooltip"
					description={t('weightTooltipDescription')}
				/>
			</TableHeaderColumn>
		</BootstrapTable>
	)
}

export default DriversScoreTable;
