import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import CustomButton from '../../CustomButton'
import { useTranslation } from 'react-i18next'

const SMSAgreementModal = (props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'accountPage.accountNotificationsContainer.notificationsModal.SMSAgreementModal' });
    const { hideSMSAgreementModal, agreeToSMS } = props;

    return (
        <Modal
            show={true}
            onHide={hideSMSAgreementModal}
            className="notifications-modal modal-lg-size"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-lg">{t('titleSMSAgreement')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='agreement-modal-header'>
                    <img src='/images/rosco-logo-horizontal.png' />
                    <p>
                        {t('textMobileMessage')}
                    </p>
                    <p>
                        {t('lastUpdated')}
                    </p>
                </div>
                <div className='agreement-modal-body'>
                    <p>
                        {t('agreementText1', { "service": '(the "Service")', "rosco": '(“Rosco Inc.”, “we”, or “us”)', "mobileTerms": '(“Mobile Terms”)' })}
                    </p>
                    <p>
                        {t('agreementText2')}
                    </p>
                    <p dangerouslySetInnerHTML={{ __html: t('agreementText3') }}>
                    </p>
                    <p>
                        {t('agreementText4')}
                    </p>
                    <p>
                        {t('agreementText5')}
                    </p>
                    <p>
                        {t('agreementText6')}
                    </p>
                    <p>
                        {t('agreementText7')}
                    </p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton variant="delete" onClick={hideSMSAgreementModal}>{t('btnNoSMSNotifications')}</CustomButton>
                <CustomButton variant="primary" onClick={agreeToSMS}>{t('btnAgree')}</CustomButton>
            </Modal.Footer>
        </Modal>
    )
}

export default SMSAgreementModal;
