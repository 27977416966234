import React from 'react';
import momenttz from '../../helpers/momenttz'
import ReactPaginate from 'react-paginate'
import { useTranslation } from 'react-i18next'
import CustomButton from '../../CustomButton';
import { formatTimestamp } from '../../helpers/tablesFuncHelpers';
import { videoPlayerIcons } from '../../helpers/iconHelpers';

const EventClipsGrid = (props) => {
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'eventsPage.eventClipsContainer.eventClipsGrid' });
	const { sizePerPage, handlePageChange, totalSize, events, actionsFormatter, location, editVehicleUser, editVehicle, openEventSnapshot, openEventVideoAll, cardActionsFormatter, showDate } = props;

	const formatEventColor = (type) => {
		switch (type) {
			case 'G-Sensor (legacy)':
				return { 'backgroundColor': '#888', 'color': '#fff' }
			case 'High G-Force':
				return { 'backgroundColor': '#000', 'color': '#fff' }
			case 'Speed':
				return { 'backgroundColor': '#9AE78D' }
			default:
				return { 'backgroundColor': '#fff' }
		}
	}

	const formatTimestamp = (timestamp) => {
		if (timestamp) {
			return showDate ?
				momenttz(timestamp).format('M/DD/YY h:mm A') :
				momenttz(timestamp).format('h:mm A');
		}
	}

	const formatCardText = (event) => {
		let type;

		// FWSD-4541
		if (event.event_display_type) {
			type = event.event_display_type;
		}
		else {
			// FWSD-3958
			const re = /External Driver|InputSensor2|InputSensor3|InputSensor5|InputSensor9/g;
			let typesArr = String(event.type).replaceAll(re, 'Driver Event').replace('HighSpeed', 'Max Speed').split(',')

			// FWSD-4506
			if (typesArr.length > 1) {
				if (typesArr.includes('High G-Force')) {
					typesArr = ['High G-Force']
				}
				else {
					typesArr = typesArr.filter(item => item !== 'Critical G-Sensor')
				}
			}

			type = [...new Set(typesArr)].join(', ').replace('Custom Alert ', '')

			// FWSD-5202, FWSD-7032
			if (['Geotab', 'Ituran'].includes(event.type)) {
				type = event.rule || event.type
			} 
		}

		if (type.includes(',')) {
			const typeArr = type.split(',');

			return (
				<div className='multiple-events'>
					<div>
						{editVehicleUser ?
							<span className="vehicle-name clickable" onClick={() => editVehicle(event.device_id)}>{event.vehicle_name || event.device_id}</span> :
							<span className="vehicle-name">{event.vehicle_name || event.device_id}</span>
						}
						<div className="event-type" style={formatEventColor(typeArr[0])}>{i18n.t(`constants.eventNames.${typeArr[0].replace(/\s/g, "_")}`, typeArr[0])}</div>
					</div>
					<div>
						<span>{formatTimestamp(event.timestamp)}</span>
						<div className="event-type" style={formatEventColor(typeArr[1])}>{i18n.t(`constants.eventNames.${typeArr[1].replace(/\s/g, "_")}`, typeArr[1])}</div>
					</div>
				</div>
			);
		}
		
		return (
			<div>
				{editVehicleUser ?
					<span className="vehicle-name clickable" onClick={() => editVehicle(event.device_id)}>{event.vehicle_name || event.device_id}</span> :
					<span className="vehicle-name">{event.vehicle_name || event.device_id}</span>
				}
				<div className="event-type" style={formatEventColor(type)}>{i18n.t(`constants.eventNames.${type.replace(/\s/g, "_")}`, type)}</div>
				<div>{formatTimestamp(event.timestamp)}</div>
			</div>
		)
	}

	const showVideo = (event) => {
		if (event.uploading_status !== 'VIDEO_UPLOADED') {
			openEventSnapshot(event);
		}
		else {
			openEventVideoAll(event, 'Grid');
		}
	}

	const getImageSnapshot = (event) => {
		let firstEventType = event.type.split(',')[0]

		if (['archived', 'restoring'].includes(event.archive)) {
			return (
				<div className='image-error video-error'>
					<span className='video-error-message'>{event.archive === 'archived' ? t('eventArchived') : t('inRestoringProcess')}</span>
				</div>
			)
		}
		else if ((
			['External Driver', 'Driver', 'Drowsiness', 'Distraction', 'Phone Use', 'Phone Use/Hand to Head', 'Yawning', 'Drowsiness/Head Down', 'Seatbelt', 'Smoking'].includes(firstEventType)
			|| event.event_type.includes('DmsWarning')
		)
			&& event.snapshot_2
		) {
			let showPlayButton = event.uploading_status === 'VIDEO_UPLOADED';
			return (
				<div onClick={() => showVideo(event)}>
					<img src={event.snapshot_2} className='card-snapshot' />
					{showPlayButton && <CustomButton prefix='play-button' size="icon" variant="empty">
						{videoPlayerIcons.playIcon}
					</CustomButton>}
				</div>
			)
		}
		else if (event.uploading_status !== 'VIDEO_UPLOADED' && event.snapshot_status === 'SNAPSHOT_UPLOADED') {
			return <img src={event.snapshot_1 || event.snapshot_2} className='card-snapshot' onClick={() => showVideo(event)} />
		}
		else if (event.uploading_status === 'EVENT_REPORTED' && event.snapshot_status === 'SNAPSHOT_REQUESTED') {
			return (
				<div className='image-error video-error'>
					<span className='video-error-message'>{t('customVideoRequested')}</span>
				</div>
			)
		}
		else if (event.uploading_status === 'VIDEO_NON_EXIST' && event.clip_retry && [1, 2].includes(event.clip_retry)) {
			return (
				<div className='image-error video-error'>
					<span className='video-error-message'>{t('pending')}</span>
				</div>
			)
		}
		else if (event.uploading_status === 'VIDEO_NON_EXIST' && event.type === 'Custom Clip') {
			return <div className='image-error video-error'>
				<span className='video-error-message'>{t('noVideoFound')}</span>
			</div>
		}
		else if (event.uploading_status !== 'VIDEO_UPLOADED') {
			return <div className='image-error video-error'>
				<span className='video-error-message'>{t('noVideoAvailable')}</span>
			</div>
		}
		else if (!event.snapshot_1) {
			return <div className='image-error card-snapshot' onClick={() => showVideo(event)}>
				<CustomButton size="icon" variant="empty" prefix='play-button'>{videoPlayerIcons.playIcon}</CustomButton>
			</div>
		}

		return (
			<div onClick={() => showVideo(event)}>
				<img src={event.snapshot_1} className='card-snapshot' />
				<CustomButton size="icon" variant="empty" prefix='play-button'>{videoPlayerIcons.playIcon}</CustomButton>
			</div>
		)
	}

	if (!events.length) {
		return <div className='no-events-message'>
			{t('noEventsRecorded')}
		</div>
	}

	const onPageChange = (pageNumber) => {
		handlePageChange(pageNumber.selected + 1, sizePerPage)
	}

	const clickable = (event) => (event.uploading_status === 'VIDEO_UPLOADED' || event.snapshot_status === 'SNAPSHOT_UPLOADED')

	return (
		<>
			<div className='event-grid'>
				{events.map(event =>
					<div key={event.id} className='square-card'>
						<div className={`card-content ${(!clickable(event) || !event.snapshot_1) ? 'no-image' : ''}`}>
							{event && getImageSnapshot(event)}
							{cardActionsFormatter(event)}
						</div>
						<div
							className={`card-text ${(clickable(event) ? 'clickable-footer' : '')} ${((location && location.event_id === event.id) ? 'active' : '')}`}
							onClick={() => clickable(event) && showVideo(event)}
						>
							{formatCardText(event)}
						</div>
					</div>
				)}
			</div>
			{totalSize > sizePerPage &&
				<ReactPaginate pageCount={(Math.floor((totalSize - 1) / sizePerPage) + 1)} pageRangeDisplayed={3} marginPagesDisplayed={3} containerClassName='grid-pagination'
					activeClassName='active-pagination' disabledClassName='disabled-pagination' onPageChange={onPageChange} forcePage={props.page - 1} />
			}
		</>
	)
}

export default EventClipsGrid;
