// FWSD-2842
import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Button } from 'react-bootstrap'
import { InfoWindow } from 'react-google-maps'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import { useTranslation } from 'react-i18next'

import { getDirection, getDirectionIcon } from '../../../component/helpers/labels'
import momenttz from '../../../component/helpers/momenttz'

import './MarkerInfoPopup.sass'

momentDurationFormatSetup(moment);

const MarkerInfoPopup = (props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'components.markerInfoPopup' });
	const {
		driver_name,
		userRole,
		device_id,
		name,
		timestamp,
		spd,
		speed,
		speed_unit,
		lan,
		lat,
		lon,
		lng,
		ignition,
		online_gps,
		dir,
		icon_direction,
		online,
		address,
		zIndex,
		showTripPanel,
		liveVideoUserAccess,
		onCloseInfo
	} = props;

	const vehicleSpeed = () => {
		let speedRes = (spd || speed) * 1
		if (!online_gps || !ignition) {
			return 0;
		}
		if (speed_unit !== 'mph') {
			speedRes *= 1.609344
		}

		// return speedRes.toFixed(2)
		return speedRes.toFixed(0)
	}

	// If device is offline, then display OFF
	// If device is online and key/ignition is true, then display ON
	// If device is online and key/ignition is false, then display PARKED
	const ignitionStatus = () => {
		// console.log('ignitionStatus name, ignition: ', props.name, props.ignition)

		if (!online_gps) {
			return <span style={{ color: '#f00' }}>{t('StatusOFF')}</span>
		}
		if (ignition) {
			return <span style={{ color: '#48B838' }}>{t('StatusON')}</span>
		}

		return (
			<div style={{ textAlign: 'center', marginRight: '8px' /* minWidth: '80px' */ }}>
				<div style={{ color: '#fff', fontSize: '18px' }} className='pulse'>{t('StatusPARKED')}</div>
				<div style={{ color: '#fff', fontSize: '14px' }}>
					{moment.duration(moment.utc().diff(moment.utc(ignitionTimeChange), 'seconds'), 'seconds').format('hh:mm:ss', { trim: false })}
				</div>
			</div>
		)
	}

	const handleCloseInfoWindow = () => {
		onCloseInfo(device_id)
	}

	const handleClickAddress = (event) => {
		onClickAddress(device_id)
	}

	return (
		<InfoWindow
			onCloseClick={handleCloseInfoWindow}
			zIndex={zIndex}
		>
			<div className="markerInfoPopup">
				<div className="headerWrapper">
					<div className="title">
						{name || device_id}
						{driver_name
							&& <span>{`  - ${driver_name}`}</span>
						}
					</div>
					<div className="header">
						<div className="actionsTitle">
							{t('currentStatus')}
						</div>
						<Row>
							<Col className="menu" md={12} sm={12} xs={12}>
								<Col md={4} sm={12} xs={12} style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
									<div
										className="menuItem speed"
									/>
									<div>
										<div style={{ color: '#fff', fontSize: '22px', fontWeight: 'bold' }}>
											{vehicleSpeed()}
										</div>
										<div style={{ color: '#fff', fontSize: '14px' }}>
											{speed_unit.toUpperCase()}
										</div>
									</div>
								</Col>
								<Col md={4} sm={12} xs={12} style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
									<div
										className="menuItem direction"
									/>
									<div style={{ color: '#fff', fontSize: '26px', fontWeight: 'bold' }}>
										{(dir && dir > -1) ? getDirection(dir, true) : getDirectionIcon(icon_direction)}
									</div>
								</Col>
								<Col md={4} sm={12} xs={12} style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
									<div
										className="menuItem parking"
									/>
									<div style={{ color: '#fff', fontSize: '26px', fontWeight: 'bold' }}>
										{ignitionStatus()}
									</div>
								</Col>
							</Col>
						</Row>
					</div>
				</div>

				<div className="addressWrapper">
					<div className="actionsTitle" style={{ color: '#333' }}>
						{t('titleAddress')}
					</div>
					<Row style={{ minHeight: '60px' }}>
						<Col className="menu" md={12} sm={12} xs={12}>
							<Col md={3} sm={3} xs={3} className="text-right">
								<button
									className="btn-address"
									type="button"
									title={t('titleLookupAddress')}
									onClick={handleClickAddress}
									variant="primary-outline"
								>
									<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" className="bi bi-geo-alt-fill" viewBox="0 0 16 16">
										<path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
									</svg>
								</button>
							</Col>
							<Col md={7} sm={9} xs={9} style={{ fontSize: '14px', lineHeight: '18px' }}>
								{address}
							</Col>
						</Col>
					</Row>
					{['system_admin', 'customer_service', 'partner'].indexOf(userRole) > -1
						&& (
							<div className="reportTime">
								{`${t('reportTime')} ${!showTripPanel ? momenttz(`${timestamp}+00`).format('MM/DD/YYYY h:mm:ss A') : momenttz(timestamp).format('MM/DD/YYYY h:mm:ss A')}`}
							</div>
						)}
				</div>
			</div>
		</InfoWindow>
	)
}

MarkerInfoPopup.propTypes = {
	onClickAddress: PropTypes.func.isRequired,
	onCloseInfo: PropTypes.func.isRequired
};

export default MarkerInfoPopup;
