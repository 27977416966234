import React, { useState, useEffect } from 'react'
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import FileSaver from 'file-saver';
import axios from 'axios'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import { apiCallGet } from '../../../action/RouterActions'
import GreyHeader from '../../dump/menuComponents/GreyHeader';

const InstallationReportAssetContainer = (props) => {
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'components.installationReportAssetContainer' });
	const { location, toggleLoader } = props;

	const [reports, setReports] = useState([]);
	const [lastReport, setLastReport] = useState(null);
	const [reportWithUrl, setReportWithUrl] = useState(null);
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);

	useEffect(() => {
		if (!location) {
			return
		}

		const { deviceId, reportId } = location.state
		if (deviceId) {
			getDeviceReports();
		} else if (reportId) {
			getDeviceReportById();
		}
	}, []);

	const getDeviceReports = () => {
		const { deviceId } = location.state;
		toggleLoader(true);
		apiCallGet('/device/{deviceId}/install/report', { deviceId })
			.then((res, err) => {
				console.log('!getDeviceReports res:', res, err)

				let lastReport = null
				let reportWithUrl = null
				let { reports } = res.data.response

				reports = _.orderBy(reports, ['createtime'], ['desc']);
				if (reports.length) {
					lastReport = reports[0]
					reportWithUrl = _.find(reports, 'url')
				}

				setReports(reports);
				setLastReport(lastReport);
				setReportWithUrl(reportWithUrl);

				toggleLoader(false);
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!getDataUsage error:', error.response, error)

				let errDescription = i18n.t('errorMessage.errDescription');
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			})
	}

	const getDeviceReportById = () => {
		const { reportId } = location.state;
		toggleLoader(true);
		apiCallGet('/device/install/report/{reportId}', { reportId })
			.then((res, err) => {
				console.log('!getDeviceReportById res:', res, err)

				const lastReport = res.data.response
				setLastReport(lastReport);
				toggleLoader(false);
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!getDeviceReportById error:', error.response, error)

				let errDescription = i18n.t('errorMessage.errDescription');
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			})
	}

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	}

	const onDownloadPDF = () => {
		const url = (lastReport && lastReport.url) || (reportWithUrl && reportWithUrl.url)
		const settings = {
			// async: true,
			// crossDomain: true,
			url,
			method: 'GET',
			// withCredentials: true,
			responseType: 'blob',
		}

		axios(settings)
			.then((res, err) => {
				// console.log('!downloadConfig res:', res)
				toggleLoader(false);

				const file = res.data
				const blob = new Blob([file], { type: 'application/pdf' })

				FileSaver.saveAs(blob, 'install_report.pdf')
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!downloadConfig error:', error.response, error);

				let errDescription = i18n.t('errorMessage.errDescription');
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			})
	}

	// const onDownloadPDF = () => {
	// 	const pdfUrl = (lastReport && lastReport.url) || (reportWithUrl && reportWithUrl.url)

	// 	var oReq = new XMLHttpRequest();
	// 	// The Endpoint of your server 
	// 	// var URLToPDF = "http://mozilla.github.io/pdf.js/web/compressed.tracemonkey-pldi-09.pdf";
	// 	var URLToPDF = pdfUrl;

	// 	// Configure XMLHttpRequest
	// 	oReq.open("GET", URLToPDF, true);

	// 	// Important to use the blob response type
	// 	oReq.responseType = "blob";

	// 	// When the file request finishes
	// 	// Is up to you, the configuration for error events etc.
	// 	oReq.onload = function() {
	// 		// Once the file is downloaded, open a new window with the PDF
	// 		// Remember to allow the POP-UPS in your browser
	// 		var file = new Blob([oReq.response], { 
	// 			type: 'application/pdf' 
	// 		});

	// 		// Generate file download directly in the browser !
	// 		FileSaver.saveAs(file, "install_report.pdf");
	// 	};

	// 	oReq.send();
	// }

	const pdfUrl = (lastReport && lastReport.url) || (reportWithUrl && reportWithUrl.url);
	let errMessage = '';
	if (lastReport && !lastReport.url && reportWithUrl) {
		errMessage = t('errReport', { lastReport: `"${lastReport.createtime}"`, reportWithUrl: `"${reportWithUrl.createtime}"` });
	};

	if (!pdfUrl) {
		return (
			<div>
				<GreyHeader title={t('titleInstallationReport')} hideSubtitle />

				<h5 style={{ color: 'red' }}>{t('noDeviceReport')}</h5>
			</div>
		)
	};

	return (
		<div>
			<GreyHeader title={t('titleInstallationReport')} hideSubtitle />

			<div>
				<div className="form-inline">
					<div className="button-blue form-group" style={{ marginRight: '20px' }}>
						<button onClick={onDownloadPDF}>{t('btnDownload')}</button>
					</div>

					{errMessage && <div className="form-group" style={{ color: 'red', fontSize: '16px' }}>{errMessage}</div>}
				</div>

				<hr />

				<div style={{ height: '600px', overflow: 'auto' }}>
					<Document
						file={pdfUrl}
						onLoadSuccess={onDocumentLoadSuccess}
					>
						{_.range(1, (numPages + 1)).map(i => <Page key={i} pageNumber={i} scale={1.2} />)}
					</Document>
					{/* <p>Page {pageNumber} of {numPages}</p>
						<Document file="/pdf/install_report_1.pdf">
							<Page pageNumber={4} />
						</Document> */}
				</div>
			</div>
		</div>
	)
}

export default connect(
	null,
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(InstallationReportAssetContainer);
