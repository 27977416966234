import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Modal } from 'react-bootstrap'
import classnames from 'classnames'
import { toastr } from 'react-redux-toastr'
import { useTranslation } from 'react-i18next'

import { formatSD } from '../../../action/FleetAdminActions'
import { verifyUserPassword } from '../../../action/AccountActions'
import CustomButton from '../../CustomButton'
import { sendGA } from '../../helpers/helperUsers'

const ModalFormatSDBatch = (props) => {
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'modals.modalFormatSDBatch' });
	const { userRole, selectedDevices, clearSelection, getCompanyDevices, onHide, toggleLoader } = props;

	const [password, setPassword] = useState('')
	const [errPassword, setErrPassword] = useState('')
	const [togglePassword, setTogglePassword] = useState(true)

	const toggleShowPassword = () => {
		setTogglePassword(!togglePassword)
	}

	const fillForm = (e) => {
		setPassword(e.target.value)
	}

	const checkForm = (args) => {
		let error = false;
		let errPassword = ''

		Object.keys(args).forEach((key) => {
			if (args[key] === '') {
				error = true;
				errPassword = `${t('textEnteryour')} ${key}!`
			} else if (errPassword) {
				errPassword = null
			}
		})

		setErrPassword(errPassword)
		return error
	}

	const formatSDCart = () => {
		const params = {
			password,
		};
		const error = checkForm(params)

		if (!error) {
			toggleLoader(true)

			verifyUserPassword(params)
				.then((res, err) => {
					formatSD(userRole, selectedDevices.join(','))
						.then((resSD, errSD) => {
							toggleLoader(false)
							sendGA({
								category: userRole,
								action: 'Manage Vehicles Modal',
								label: 'Submit Format SD'
							})

							toastr.success('Success', resSD.data.response)
							onHide();
							clearSelection();
							getCompanyDevices && getCompanyDevices();
						})
						.catch((errorSD) => {
							toggleLoader(false)
							let errDescription = i18n.t('errorMessage.errDescription');
							if (errorSD.response.data.response.error) {
								errDescription = errorSD.response.data.response.error
							}
							toastr.error(errDescription)
						})
				})
				.catch((error1) => {
					toggleLoader(false)
					let errDescription = i18n.t('errorMessage.errDescription');
					if (error1.response.data.response.error) {
						errDescription = error1.response.data.response.error
					}
					toastr.error(errDescription)
				})
		}
	}

	return (
		<Modal
			show
			onHide={onHide}
			className="manage-vehicles-modal modal-lg-size"
		>
			<Modal.Header closeButton>
				<Modal.Title className='error-label'>{t('labelFormatSD')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className={classnames('text-center form-group has-feedback', { 'has-error': errPassword })}>
					<h5 className='error-label'>{t('labelWarning')}</h5>
					<p className='modal-text'>{t('textFormattingSDCard')}</p>
					<p className='modal-text'>{t('textEnterPassword')}</p>
					<div className='has-feedback password-container'>
						<input
							type={togglePassword ? 'password' : 'text'}
							id="password"
							name="password"
							className="vehicle-search"
							data-toggle="password"
							onChange={fillForm}
							autoComplete="new-password"
							placeholder={t('placeholderPassword')}
						/>
						<span
							className="fa fa-eye form-control-feedback"
							onClick={toggleShowPassword}
						/>
						{errPassword && <span className="help-block">{errPassword}</span>}
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<CustomButton
					variant="delete"
					onClick={formatSDCart}
				>
					{t('textReformat') + selectedDevices.length + t('textDevice') + (selectedDevices.length > 1 ? 's' : '')}
				</CustomButton>
			</Modal.Footer>
		</Modal>
	)
}

export default connect(
	null,
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(ModalFormatSDBatch);
