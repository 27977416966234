import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import classnames from 'classnames';
import { Link, browserHistory } from 'react-router';
import { toastr } from 'react-redux-toastr';
import { useTranslation } from 'react-i18next';

import Auth from '../../../Auth'
import CustomButton from '../../../CustomButton'

import { getUserInfo } from '../../../../action/ClientActions'
import { loginUser, logoutUser } from '../../../../action/AccountActions'
import { apiCallGet, apiCall } from '../../../../action/RouterActions'
import { sendGA } from '../../../helpers/helperUsers'

import '../../../dump/menuComponents/menuComponents.sass'

const LoginForm = (props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'loginForms.loginForm' });
	const [data, setData] = useState({
		email: '',
		password: '',
	})
	const [errMessage, setErrMessage] = useState({
		email: '',
		password: '',
	})
	const [modal, setModal] = useState({
		show: false,
		title: '',
		content: '',
		error: false,
	})
	const [toggle_password, setToggle_password] = useState(true)
	const [rememberMe, setRememberMe] = useState(1)

	useEffect(() => {
		setRememberMe(localStorage.getItem('r_u') === null ? 1 : localStorage.getItem('r_u') * 1);
	}, []);

	const togglePassword = () => {
		setToggle_password(!toggle_password);
	}

	const closeModal = () => {
		const modalClose = modal;
		modalClose.error = false;
		modalClose.show = false;
		setModal(modalClose);
	}

	const openModal = () => {
		const modalOpen = modal;
		modalOpen.show = true;
		setModal(modalOpen);
	}

	const fillForm = ({ target }) => {
		const value = target.type === 'checkbox' ? target.checked : target.value;

		if (target.name === 'rememberMe') {
			setRememberMe(value * 1);
		} else {
			let dataUpdate = data;
			dataUpdate[target.name] = value;
			setData({ ...dataUpdate, [target.name]: value });
			setErrMessage({ ...errMessage, email: '', password: '' });
		}
	}

	const checkForm = (data) => {
		let error = false;
		const updatedErrMessage = { ...errMessage };

		for (const i in data) {
			if (data[i] == '') {
				error = true;
				updatedErrMessage[i] = t('errEnterYour') + i;
			}
			else if (errMessage[i]) {
				updatedErrMessage[i] = null;
			}
		}

		if (!updatedErrMessage.email) {
			var re = /^([a-zA-Z0-9_\-\.\+]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			if (!re.test(data.email)) {
				error = true;
				updatedErrMessage.email = t('errEmail');
			}
		}

		setErrMessage(updatedErrMessage);
		return error;
	}

	const sendForm = (e) => {
		e.preventDefault()

		const { toggleLoader } = props
		const error = checkForm(data)

		if (!error) {
			toggleLoader(true);
			sendGA({ category: 'Login Form', action: 'Login Form', label: 'Login' })

			// apiCall('POST', '/auth/login', data).then(res => localStorage.setItem("r_t", JSON.stringify(res.data.response)));
			loginUser(data)
				.then((res, err) => {
					if (res.data.response.error && res.data.response.error === 'you have to logout first') {
						logoutUser()
							.then((res, err) => {
								new Auth().deauthenticateUser();
								sendForm(e);
							})
							.catch((error) => {
								console.log('!error');
								console.log(error);
							});
					}
					else if (res.data.response.error && ['Invalid password', 'Specified user does not exist'].includes(res.data.response.error)) {
						toggleLoader(false)
						setErrMessage({ ...errMessage, password: t('errEmail/Password') })
					}
					else if (res.data.response.error) {
						toggleLoader(false)
						toastr.error(t('unableLoginUser'));
						console.log("Error: ", res.data)
					}
					else if (res.data.response.roles && res.data.response.roles[0] === 'notification_recipient') {
						toggleLoader(false)
						browserHistory.push({ pathname: '/confirmation' })
					}
					else {
						// FWSD-6093, checks if EULA was accepted otherwise redirect to /eula-agreement
						getUserInfo()
							.then(resUserInfo => {
								toggleLoader(false)
								if (resUserInfo.data.response.user.eula_was_viewed) {
									const { session_token } = res.data.response
									new Auth().authenticateUser(true, session_token, rememberMe)
									location.href = '/'
								}
								else {
									location.href = '/eula-agreement'
								}
							})
							.catch(errorUserInfo => {
								toggleLoader(false)
								console.log('getUserInfo error: ', errorUserInfo.response, errorUserInfo)
								toastr.error(errorUserInfo)
							})
					}
				})
				.catch((error) => {
					console.log(error.response);

					toggleLoader(false)

					if (error.response && error.response.data.response.error) {
						let error_content = error.response.data.response.error;
						let error_title = '';
						if (error_content.toLowerCase() === 'invalid password') {
							error_title = t('errorTitle_1');
							error_content = t('errorContent_1');
						}
						else if (error_content.toLowerCase() === 'specified user does not exist') {
							error_title = t('errorTitle_2');
							error_content = t('errorContent_2');
						}

						const modalUpdate = modal;
						modalUpdate.error = true;
						modalUpdate.title = error_title;
						modalUpdate.content = error_content;
						setModal(modalUpdate);

						openModal();
						toastr.error(error_content);
					}
					else {
						toastr.error(t('unableLoginUser'));
					}
				});
		}
	}

	const { email, password } = errMessage;

	return (
		<div className="panel-body">
			<h3 className="login-header">{t('headerLogin')}</h3>
			<div className="form-wrapper">

				<form onSubmit={sendForm} autoComplete={rememberMe ? 'auto' : 'off'} id="login-form">
					{!rememberMe && <input autoComplete="false" name="hidden" type="text" className="hidden" hidden="none" />}
					<div className='login-form'>
						<label className={`login-label ${(data.email.length) ? 'active' : ''}`}>{t('labelE-mail')}</label>
						<input
							type="text"
							name="email"
							className={`vehicle-search ${(email || password) ? 'error' : ''}`}
							onChange={fillForm}
							placeholder={t('placeholderE-Mail')}
							autoComplete={rememberMe ? 'auto' : 'new-password'}
						/>
						{email && <span className="help-block error-label">{email}</span>}
					</div>
					<div className="login-form has-feedback">
						<label className={`login-label ${(data.password.length) ? 'active' : ''}`}>{t('labelPassword')}</label>
						<input
							type={toggle_password ? 'password' : 'text'}
							id="password"
							name="password"
							className={`vehicle-search ${password ? 'error' : ''}`}
							data-toggle="password"
							onChange={fillForm}
							placeholder={t('placeholderPassword')}
							autoComplete={rememberMe ? 'auto' : 'new-password'}
						/>
						{/* <span className="fa fa-eye form-control-feedback" onClick={togglePassword} /> */}
						<span
							className={`fas ${toggle_password ? 'fa-eye' : 'fa-eye-slash'} form-control-feedback`}
							onClick={togglePassword}
						/>
						{password && <span className="help-block error-label">{password}</span>}
					</div>
					<div className="login-form">
						<div className="checkbox">
							<label>
								<input
									type="checkbox"
									name="rememberMe"
									onChange={fillForm}
								/>
								{t('labelRememberMe')}
							</label>
						</div>
					</div>
					<div className="login-form submit-btn">
						<CustomButton variant="primary" type="submit" value="Login" id="login-button" style={{ padding: '8px 0px' }}>{t('btnLogin')}</CustomButton>
					</div>

					<div className="forgot-password">
						<Link to="/restore-password" onClick={() => sendGA({ category: 'Login Form', action: 'Login Form', label: t('labelForgotPassword') })}>
							{t('linkForgotPassword')}
						</Link>
					</div>
				</form>

			</div>
			<Modal show={modal.show} onHide={closeModal} dialogClassName={classnames('modal-custom', { 'has-error': modal.error })}>
				<Modal.Header>
					<Modal.Title>
						<div className={classnames({ 'has-error': modal.error })}>
							{modal.error ? modal.title : t('textSuccess')}
						</div>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div dangerouslySetInnerHTML={{ __html: modal.content }} />
				</Modal.Body>
				<Modal.Footer>
					<CustomButton variant="primary" onClick={closeModal}>{t('btnClose')}</CustomButton>
				</Modal.Footer>
			</Modal>
		</div>
	)
}

export default connect(
	null,
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(LoginForm);
