import moment from 'moment';
import React from 'react'
import { browserHistory, Link } from 'react-router';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'
import Toggle from 'react-toggle'
import _ from 'lodash'
import i18n from 'i18next'

import CustomButton from '../CustomButton'
import momenttz from './momenttz'
import store from '../../store/store'
import formatNumber from './formatNumber'
import { getDeviceStatusName } from './helperVehicle'
import { updateDevice } from '../../action/DeviceActions'
import { updateCompany } from '../../action/CompaniesActions'
import { FaLock } from 'react-icons/fa'

const formatStateNames = {
	Arizona: 'AZ',
	Alabama: 'AL',
	Alaska: 'AK',
	Arkansas: 'AR',
	California: 'CA',
	Colorado: 'CO',
	Connecticut: 'CT',
	Delaware: 'DE',
	Florida: 'FL',
	Georgia: 'GA',
	Hawaii: 'HI',
	Idaho: 'ID',
	Illinois: 'IL',
	Indiana: 'IN',
	Iowa: 'IA',
	Kansas: 'KS',
	Kentucky: 'KY',
	Louisiana: 'LA',
	Maine: 'ME',
	Maryland: 'MD',
	Massachusetts: 'MA',
	Michigan: 'MI',
	Minnesota: 'MN',
	Mississippi: 'MS',
	Missouri: 'MO',
	Montana: 'MT',
	Nebraska: 'NE',
	Nevada: 'NV',
	'New Hampshire': 'NH',
	'New Jersey': 'NJ',
	'New Mexico': 'NM',
	'New York': 'NY',
	'North Carolina': 'NC',
	'North Dakota': 'ND',
	Ohio: 'OH',
	Oklahoma: 'OK',
	Oregon: 'OR',
	Pennsylvania: 'PA',
	'Rhode Island': 'RI',
	'South Carolina': 'SC',
	'South Dakota': 'SD',
	Tennessee: 'TN',
	Texas: 'TX',
	Utah: 'UT',
	Vermont: 'VT',
	Virginia: 'VA',
	Washington: 'WA',
	'West Virginia': 'WV',
	Wisconsin: 'WI',
	Wyoming: 'WY',
}

export const formatVehicleName = (cell, row) => {
	return row.vehicle_name || row.device_name || row.device_id.toString()
}

export const formatName = (cell, row) => {
	if (row.first_name || row.driver_first_name) {
		return `${row.first_name || row.driver_first_name || ''} ${row.last_name || row.driver_last_name || ''}`
	}
	return row.driver_id
}

export const formatFileSize = (cell) => {
	if (cell > 1000) {
		return `${(cell / 1000).toFixed(2)} GB`
	}
	if (cell) {
		return `${cell.toFixed(2)} MB`
	}

	return '0 bytes'
}

export const formatDataUsageLink = (cell, row, companyData, handleClick) => {
	const data_limit = companyData && companyData.data_limit
	const dataUsageGB = row.data_usage / 1024 / 1024 / 1024
	let dataLimit = 1

	switch (row.data_limit) {
		case 3:
			dataLimit = 1;
			break;
		case 2:
			dataLimit = 0.5;
			break;
		default:
			dataLimit = data_limit === 2 ? 0.5 : 1;
			break;
	}

	if (dataUsageGB > dataLimit) {
		return (
			<Link to="/" className="data-usage-link" onClick={handleClick}>
				<span className="content">{`${dataUsageGB.toFixed(2)} / ${dataLimit} GB`}</span>
				{/* <span className="glyphicon glyphicon-lock" /> */}
				<FaLock />
			</Link>
		)
	}

	return `${dataUsageGB.toFixed(2)} / ${dataLimit} GB`
}

export const formatDataUsage = (cell, row, companyData) => {
	const data_limit = companyData && companyData.data_limit
	const dataUsageGB = row.data_usage / 1024 / 1024 / 1024
	let dataLimit = 1;
	switch (row.data_limit) {
		case 3:
			dataLimit = 1;
			break;
		case 2:
			dataLimit = 0.5;
			break;
		default:
			dataLimit = data_limit === 2 ? 0.5 : 1;
			break;
	}

	return (
		<span className={`${dataUsageGB > dataLimit ? 'error-label' : ''}`}>
			{dataUsageGB.toFixed(2) + ' / ' + dataLimit + ' GB'}
		</span>
	)
}

// FWSD-5354
export const formatStreamDataUsage = (cell, row) => {
	return `${(row.data_usage_live_stream / 1024 / 1024).toFixed(1)} Mb`
}

// FWSD-5226
export const formatLiveStream = (cell, row, isCSV = false) => {
	const usedMins = Math.trunc((row.live_stream.duration_month || 0) / 60)
	if (usedMins >= 100 && !isCSV) {
		return <span className="warning-label">{`${usedMins} / 100 min`}</span>
	}

	return `${usedMins} / 100 min`
}

export const formatDataUsageOld = (cell, row, companyData) => {
	const data_limit = companyData && companyData.data_limit;
	let dataLimit = 1;
	switch (row.data_limit) {
		case 3:
			dataLimit = 1;
			break;
		case 2:
			dataLimit = 0.5;
			break;
		default:
			dataLimit = data_limit === 2 ? 0.5 : 1;
			break;
	}
	return <span className={`${(row.data_usage / 1000 > dataLimit) ? 'error-label' : ''}`}>{(row.data_usage / 1000).toFixed(2) + ' / ' + dataLimit + ' GB'}</span>
}

export const formatDataUsageCSV = (cell, row, companyData) => (row.data_usage / 1024 / 1024 / 1024).toFixed(2) + ' / ' + (row.data_limit ? row.data_limit : companyData.data_limit) + ' (GB)'

export const formatDataUsageCSVOld = (cell, row, companyData) => (row.data_usage / 1000).toFixed(2) + ' / ' + (row.data_limit ? row.data_limit : companyData.data_limit) + ' (GB)'

export const formatChannelStatus = (cell, row, onClick = null) => {
	let failureOverlays = [];
	if (cell && cell.length) {
		failureOverlays = cell.reduce((totalFailures, channel) => {
			if (channel.fail_ts > (channel.success_ts || '1970-01-01 00:00:00') && row.channel_available.includes(channel.channel)) {
				totalFailures.length && totalFailures.push(', ');
				totalFailures = [
					...totalFailures,
					<OverlayTrigger
						key={channel.channel}
						placement="bottom"
						delayHide={100}
						overlay={<Tooltip id="vehicle-name-tooltip" className="tooltip custom-tooltips">
							{channel.fail_status
								? <span>{i18n.t('constants.tablesFuncHelpers.status')} {formatFailStatus(channel.fail_status)}</span>
								: <span>{i18n.t('constants.tablesFuncHelpers.possibleFailureDetected')}</span>
							}
						</Tooltip>}
					>
						{onClick
							? <span role="button" tabIndex="0" onClick={() => onClick(row, channel)}>{`ch${channel.channel}`}</span>
							: <span>{`ch${channel.channel}`}</span>
						}
					</OverlayTrigger>,
				]
			}
			return totalFailures
		}, [])
	}
	return failureOverlays.length ? <div className="error-label">{failureOverlays}</div> : <span>OK</span>
}

export const formatChannelStatusCSV = (cell, row) => {
	let failures = '';
	if (row.channel_status && row.channel_status.length) {
		failures = row.channel_status.reduce((channelFailureStr, channel) => {
			if (channel.fail_ts > (channel.success_ts || '1970-01-01 00:00:00') && row.channel_available.includes(channel.channel)) {
				channelFailureStr += `${channelFailureStr.length ? ', ' : ''}ch${channel.channel}`
			}
			return channelFailureStr
		}, '')
	}
	return failures || 'OK'
}

export const formatFailStatus = (status) => {
	if (status === 'solid') {
		return i18n.t('constants.tablesFuncHelpers.possibleFailureDetected')
	}
	return status.charAt(0).toUpperCase() + status.slice(1) + i18n.t('constants.tablesFuncHelpers.screenDetected')
}

export const formatRecordingStatus = (cell) => {
	if (_.isEmpty(cell) || cell.count === 0) {
		return <span>OK</span>
	}
	return <span className="error-label">{i18n.t('constants.tablesFuncHelpers.needsReformat')}</span>
}

export const formatRecordingStatusCSV = (cell) => ((_.isEmpty(cell) || cell.count === 0) ? 'OK' : i18n.t('constants.tablesFuncHelpers.needsReformat'))

export const formatOnlineStatusLocal = (cell, row) => {
	const getTime = () => {
		if (row.status === 'parked' && row.ignition && row.ignition.ts) {
			return <>{moment.duration(moment.utc().diff(moment.utc(row.ignition.ts || ''), 'seconds'), 'seconds').format('hh:mm:ss', { trim: false })}</>
		}
		if (row.status === 'offline' && row.last_disconnected_ts) {
			return <>{`${i18n.t('constants.tablesFuncHelpers.Since')} ${formatTimestamp(row.last_disconnected_ts, row.last_disconnected_ts.toString().includes('GMT'))}`}</>
		}

		return ''
	}

	return (
		<div className="status-row">
			<div className={`icon ${row.status.replace(' ', '_')}`} />
			<span className="title">
				{i18n.t(`homePage.fleetStatuses.${getDeviceStatusName(row.status).replace(' ', '_')}`, getDeviceStatusName(row.status))}
				<span className="status-time">{getTime()}</span>
			</span>
		</div>
	)
}

export const formatOnlineStatusCSVLocal = (cell, row) => {
	const statusName = i18n.t(`homePage.fleetStatuses.${getDeviceStatusName(row.status).replace(' ', '_')}`, getDeviceStatusName(row.status))
	const getTime = () => {
		if (row.status === 'parked' && row.ignition && row.ignition.ts) {
			return moment.duration(moment.utc().diff(moment.utc(row.ignition.ts || ''), 'seconds'), 'seconds').format('hh:mm:ss', { trim: false })
		}
		if (row.status === 'offline' && row.last_disconnected_ts) {
			return `${i18n.t('constants.tablesFuncHelpers.Since')} ${formatTimestamp(row.last_disconnected_ts, true)}`
		}

		return ''
	}

	return statusName.charAt(0).toUpperCase() + statusName.slice(1) + ' ' + getTime()
}

export const formatOnlineStatus = (cell, row) => {
	if (!row.online) {
		return (
			<div>
				<span>{i18n.t('constants.tablesFuncHelpers.offline')}</span>
				{row.last_disconnected_ts && <span><span>{i18n.t('constants.tablesFuncHelpers.since')}</span><div>{momenttz(`${row.last_disconnected_ts}+00`).format('M/D/YY h:mm:ss A')}</div></span>}
			</div>
		)
	}

	const ignitionOffTS = row.ignition && row.ignition.ts ? row.ignition.ts : ''; // 2023-01-04 18:50:37
	const duration = moment.duration(moment.utc().diff(moment.utc(ignitionOffTS), 'seconds'), 'seconds')
	// const durationInMins = duration.asMinutes()

	// FWSD-4314 - If device does not receive GPS from device and ignition is Off more 24 hours (1440 mins) then display Online status.
	// Device stopped receive GPS, broken antena or something, and FileServer does not know when ignition is On.
	if ((row.ignition && row.ignition.state) /*|| (!row.gps_online && durationInMins > 1440)*/) {
		return <span className="online-label">{i18n.t('constants.tablesFuncHelpers.online')}</span>
	}
	return (
		<div className="parked-label">
			<span>{i18n.t('constants.tablesFuncHelpers.parkedFor')}</span>
			<div>{duration.format('hh:mm:ss', { trim: false })}</div>
		</div>
	)
}

export const formatOnlineStatusCSV = (cell, row) => {
	const ignitionOffTS = row.ignition && row.ignition.ts ? row.ignition.ts : ''; // 2023-01-04 18:50:37
	const duration = moment.duration(moment.utc().diff(moment.utc(ignitionOffTS), 'seconds'), 'seconds')
	// const durationInMins = duration.asMinutes()

	if (cell && ((row.ignition && row.ignition.state) /*|| (!row.gps_online && durationInMins > 1440)*/)) {
		return i18n.t('constants.tablesFuncHelpers.online')
	}

	if (cell && (!row.ignition || !row.ignition.state)) {
		return `${i18n.t('constants.tablesFuncHelpers.parkedFor')} ${duration.format('hh:mm:ss', { trim: false })}`
	}

	return i18n.t('constants.tablesFuncHelpers.offline') + (row.last_disconnected_ts ? (` ${i18n.t('constants.tablesFuncHelpers.since')} ` + formatTimestamp(row.last_disconnected_ts)) : '')
}

export const formatGroup = (cell, row) => {
	if (row.group_name) {
		return row.group_name
	}
	if (row.groups) {
		return row.groups.join(', ');
	}
	return (row.vehicle_group && row.vehicle_group.length) ? row.vehicle_group.join(', ') : '';
}

export const formatTimestamp = (cell, isGMT = false) => {
	// Cell can be as "Fri, 13 Aug 2021 14:21:37 GMT" or "2021-11-29 16:00:00"
	let timestamp = cell
	if (cell && cell.timestamp) {
		timestamp = cell.timestamp
	}
	if (cell && cell.uploaded_timestamp) {
		timestamp = cell.uploaded_timestamp
	}

	if (isGMT) {
		return timestamp ? momenttz(`${timestamp}`).format('M/D/YY h:mm:ss A') : '-'
	}

	return timestamp ? momenttz(`${timestamp}+00`).format('M/D/YY h:mm:ss A') : '-'
}

export const formatTimestampUTC = (cell) => {
	return formatTimestamp(moment.utc(cell).format())
}

export const formatTimespanSeconds = (cell, row) => {
	return moment.duration(cell, 'seconds').format('h[h] m[m] s[s]');
}

export const formatFirmwareStatus = (cell) => {
	if (cell === 'latest firmware') {
		return <span className="online-label">{i18n.t('constants.tablesFuncHelpers.latest')}</span>
	}
	if (cell === 'test firmware') {
		return <span>{i18n.t('constants.tablesFuncHelpers.testFirmware')}</span>
	}
	if (cell === 'firmware out of date') {
		return <span className="error-label">{i18n.t('constants.tablesFuncHelpers.outOfDate')}</span>
	}
	return <span className="warning-label">{i18n.t('constants.tablesFuncHelpers.upgradeSent')}</span>
}

export const formatFirmwareCSV = (cell) => {
	if (cell === 'latest firmware') {
		return i18n.t('constants.tablesFuncHelpers.latest')
	}
	if (cell === 'test firmware') {
		return i18n.t('constants.tablesFuncHelpers.testFirmware')
	}
	if (cell === 'firmware out of date') {
		return i18n.t('constants.tablesFuncHelpers.outOfDate')
	}

	return i18n.t('constants.tablesFuncHelpers.upgradeSent')
}

export const formatSpeedKPH = (cell) => {
	const speedUnit = store.getState().user.user.speed_unit

	return speedUnit === 'mph' ? Math.round(cell * 0.62) + ' MPH' : Math.round(cell) + ' KPH'
}

export const formatSpeedMPH = (cell) => {
	const speedUnit = store.getState().user.user.speed_unit

	return speedUnit === 'kph' ? Math.round(cell * 1.609) + ' KPH' : Math.round(cell) + ' MPH'
}

export const formatViewEvent = (cell, row) => {
	if (row.event_id) {
		return <CustomButton
			variant="link"
			onClick={e => browserHistory.push({
				pathname: '/event',
				state: {
					event_device_id: row.device_id,
					event_id: row.event_id,
					event_ts: row.timestamp,
					type: 'report_link'
				},
			})}
		>
			{i18n.t('constants.tablesFuncHelpers.viewEvent')}
		</CustomButton>
	}
}

export const formatViewDriverEvent = (cell, row) => {
	if (row.action_item) {
		return <CustomButton
			variant="link"
			onClick={e => browserHistory.push({
				pathname: '/event',
				state: {
					event_id: row.action_item,
					event_ts: row.event_ts,
					type: 'driver_link'
				},
			})}
		>
			{i18n.t('constants.tablesFuncHelpers.viewEvent')}
		</CustomButton>
	}
}

export const formatEnableVehicle = (cell, row, companyData, callback, toggleLoader) => {
	return <div className="text-center">
		<Toggle
			name="active"
			checked={cell === 1}
			onChange={(e) => {
				const value = e.target.checked;
				const { device_id, company_name } = row
				const params = {
					data: {
						active: value,
					},
					deviceId: device_id
				}
				// toggleLoader(true);
				updateDevice(params, 'system_admin', company_name, err => {
					if (err) {
						console.log('!updateDevice sendForm error', err);
						toastr.error(i18n.t('constants.tablesFuncHelpers.unableUpdateDevice'));
					}
					else {
						if (callback && typeof callback === 'function') {
							callback();
						}
						toastr.success(i18n.t('constants.tablesFuncHelpers.changesSaved'))
					}
					// toggleLoader(false);
				})
			}}
		/>
	</div>
}

export const formatAddress = (address) => {
	const formattedAddress = [];

	if (address) {
		if (address.shop || address.retail) {
			address.shop ? formattedAddress.push(address.shop) : formattedAddress.push(address.retail)
		}
		else {
			address.house_number ? formattedAddress.push(address.house_number + ' ' + address.road) : (address.road && formattedAddress.push(address.road))
		}
		address.city ? formattedAddress.push(address.city) : (address.neighbourhood ? formattedAddress.push(address.neighbourhood) : (address.township && formattedAddress.push(address.township)));
		address.state && formattedAddress.push(formatStateNames[address.state] || address.state);
	}

	return formattedAddress.join(', ')
}

export const dataFormat = name => (cell, row) => (
	zeroAndRounding(totalValue(row[`bucket_usage_${name}`], row[`live_usage_${name}`]))
);

export const formatDate = val => moment(val).format('M/D/YY');

export const floatFormat = name => (cell, row) => (
	formatNumber(zeroAndRounding(row[name]))
);

export const totalValue = (a, b) => a + b;

export const zeroAndRounding = val => (
	(val && Number(val) !== 0) ? parseFloat(val.toFixed(2)) : '-'
);

export const checkEmail = (email) => {
	const regex = /^([a-zA-Z0-9_\-\.\+]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return email && regex.test(email)
}

export const customSortFunc = name => (a, b, order) => {
	const first = totalValue(a[`bucket_usage_${name}`], a[`live_usage_${name}`]);
	const second = totalValue(b[`bucket_usage_${name}`], b[`live_usage_${name}`]);
	return order === 'asc' ? second - first : first - second
};

export const vehicleNameSortFunc = (a, b, order) => {
	const first = (a.vehicle_name || a.device_id.toString())
	const second = (b.vehicle_name || b.device_id.toString())
	return order === 'asc' ? second.localeCompare(first) : first.localeCompare(second)
};

export const timestampSortFunc = name => (a, b, order) => {
	// can be passed onefield or object and name can be 'last_uploaded_event,uploaded_timestamp'
	const names = name.split(',')

	let val1 = a[names[0]]
	let val2 = b[names[0]]
	if (names[1]) {
		if (a[names[0]]) {
			val1 = a[names[0]][names[1]]
		}
		if (b[names[0]]) {
			val2 = b[names[0]][names[1]]
		}
	}

	const first = val1 ? moment(val1).valueOf() : 0
	const second = val2 ? moment(val2).valueOf() : 0

	return order === 'desc' ? second - first : first - second
};

export const formatOverrideOverageLimitsCompany = (cell, row, companyData, callback, toggleLoader) => {
	const userRole = store.getState().user.user.roles[0]
	// debugger
	return (
		<div className="text-center">
			<Toggle
				name="override_overage_limits"
				checked={cell}
				onChange={(e) => {
					toggleLoader(true)
					const value = e.target.checked
					const params = {
						company: {
							company_name: row.company_name,
							owner_company: row.partner_name,
						},
						data: {
							override_overage_limits: value,
						},
					}
					updateCompany(params, userRole)
						.then((res, err) => {
							if (callback && typeof callback === 'function') {
								callback()
							}
							toggleLoader(false)
							toastr.success('', i18n.t('constants.tablesFuncHelpers.companyChanged'));
						})
						.catch((error) => {
							toggleLoader(false)
							console.error('!updateCompany error: ', error.response, error)

							let errDescription = i18n.t('constants.tablesFuncHelpers.unableUpdateCompany')
							if (error.response.data.body) {
								errDescription = errorDetection(error.response.data.body) || error.response.data.body
							}
							toastr.error(errDescription);
						})
				}}
			/>
		</div>
	)
}

export const formatCompanyName = (cell, row, selectedCompanies, selectCompany) => {
	return (
		<div className="company-name-wrapper">
			<input
				type="checkbox"
				value={cell}
				checked={selectedCompanies.includes(row.company_id)}
				onChange={() => selectCompany(row)}
				className="company-checkbox"
			/>
			<CustomButton
				variant="link"
				onClick={() => browserHistory.push({ pathname: '/company/edit', state: { type: 'reports', company: { id: row.company_id, company_name: row.company_name, partner_company: row.partner_name, token: row.token } } })}
			>
				{cell || i18n.t('constants.tablesFuncHelpers.unnamed')}
			</CustomButton>
		</div>
	)
}

export const formatVehicleNameLink = (cell, row) => {
	return (
		<div className="company-name-wrapper">
			<CustomButton
				variant="link"
				onClick={() => browserHistory.push({ pathname: '/managevehicles/edit', state: { id: row.device_id, type: 'reports' } })}
			>
				{row.vehicle_name || row.device_name || row.device_id.toString()}
			</CustomButton>
		</div>
	)
}

export const formatTotalDataUsed = (cell, row) => {
	const res = (row.total_data_usage / 1024).toFixed(2)

	if (+row.total_data_usage > +row.available_data_pool) {
		return <div className="error-label">{res}</div>
	}

	return res
}
