// FWSD-7159
import React from 'react'
import { toastr } from 'react-redux-toastr'
import { connect } from 'react-redux'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import ModalDelete from '../../smart/modals/ModalDelete'
import { apiCallGet, apiCall } from '../../../action/RouterActions'

const ModalLayout = (props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'modals.modalLayout' });
	const { user, eventArchiveRestoring, setEventArchiveRestoring, toggleLoader } = props
	const { message_type, device_id, event_id, request_ts, callback } = eventArchiveRestoring

	const restoreEvent = () => {
		toggleLoader(true)
		apiCall('POST', '/event/{event_id}/restore', { event_id })
			.then(res => {
				toggleLoader(false)
				setEventArchiveRestoring({ showModal: false })

				if (typeof callback === 'function') {
					return callback()
				}

				toastr.success(t('toastrRequestSent'))
			})
			.catch(error => {
				toggleLoader(false)
				console.log('!restoreEvent error: ', error.response, error)
				toastr.error(t('toastrUnableSentRequest'));
			})
	}

	const addSubscriber = () => {
		toggleLoader(true)
		apiCall('POST', '/event/{event_id}/restore/subscribers', { event_id, user_ids: user.id })
			.then(res => {
				toggleLoader(false)
				setEventArchiveRestoring({ showModal: false })
				toastr.success(t('toastrRequestSent'))
			})
			.catch(error => {
				toggleLoader(false)
				console.log('!restoreEvent error: ', error.response, error)
				toastr.error(t('toastrUnableSentRequest'));
			})
	}

	const closeButtonHandle = () => {
		setEventArchiveRestoring({ showModal: false })
	}

	const closeModal = () => {
		switch (message_type) {
			case 'event_restore':
				return setEventArchiveRestoring({ showModal: false })
			case 'event_restoring':
				return addSubscriber()
			default:
				break;
		}
	}

	const deleteModal = () => {
		switch (message_type) {
			case 'event_restore':
				return restoreEvent()
			case 'event_restoring':
				return setEventArchiveRestoring({ showModal: false })
			default:
				break;
		}
	}

	const setContent = () => {
		let content = {}

		switch (message_type) {
			case 'event_restore':
				content = {
					title: t('titleEventRestore'),
					content: t('contentEventRestore'),
					showClose: true,
					deleteBtnName: t('deleteBtnNameEventRestore'),
					closeBtnName: t('closeBtnNameEventRestore'),
				}
				break;
			case 'event_restoring':
				content = {
					title: t('titleEventRestoring'),
					content: t('contentEventRestoring', { request_ts: moment(request_ts).format('MM/DD/YYYY hh:mm:ss A') }),
					showClose: true,
					deleteBtnName: t('deleteBtnNameEventRestoring'),
					closeBtnName: t('closeBtnNameEventRestoring'),
				}
				break;
			default:
				break;
		}

		return content

	}

	return (
		<>
			{eventArchiveRestoring.showModal &&
				<ModalDelete
					{...setContent()}
					styleView="primary"
					closeButtonHandle={closeButtonHandle}
					closeModal={closeModal}
					deleteModal={deleteModal}
				// deleteModal={() => eval(eventArchiveRestoring.deleteModal + '()')}
				// closeButtonHandle={() => eval(eventArchiveRestoring.closeButtonHandle + '()')}
				// closeModal={closeEventArchiveRestoring}
				// title=eventArchiveRestoring.title
				// content={`Camera "${notification.globalPopupData.vehicle_name}" is now awake and available for live streaming. \n\n Open your notifications and click "Open Live Stream" in the notification to open the live streaming window. \n\n Click "OK" to dismiss`}
				// styleView="primary"
				// backdropClassName="upper"
				// showClose
				// deleteBtnName="Open Notifications"
				// closeBtnName="OK"
				// closeModal={() => toggleGlobalPopup(false)}
				// deleteModal={() => setEventArchiveRestoring({ showModal: false })}
				/>
			}
		</>
	)
}

const mapStateToProps = ({ user, eventsData }) => ({
	user: user.user,
	eventArchiveRestoring: eventsData.eventArchiveRestoring,
})

const mapDispatchToProps = dispatch => ({
	toggleLoader: (show) => {
		dispatch({ type: 'TOGGLE_LOADER', payload: show });
	},
	setEventArchiveRestoring: (state) => {
		dispatch({ type: 'SET_EVENT_ARCHIVE_RESTORING', payload: state });
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalLayout);
