export const getPreferredSnapshot = (event) => {
	const firstEventType = event.type.split(',')[0];
	const snapshot2Types = [
		'External Driver', 
		'Driver', 
		'Drowsiness', 
		'Distraction', 
		'Phone Use', 
		'Phone Use/Hand to Head', 
		'Yawning', 
		'Drowsiness/Head Down'
	];
	if ((snapshot2Types.includes(firstEventType) || event.event_type.includes('DmsWarning')) && event.snapshot_2) {
		return event.snapshot_2;
	}
	return event.snapshot_1
}

// FWSD-4679
export const eventStatusFormatter = (cell) => {
	switch (cell) {
		case 'VIDEO_UPLOADED':
		case 'SNAPSHOT_UPLOADED':
			return 'Uploaded'
		case 'VIDEO_NON_EXIST':
			return 'No Video On Device'
		case 'CONVERTED_FAILED':
			return 'Conversion Error'
		case 'TRANSFERRING_FAILED':
			return 'Download Error'
		case 'FAILED':
			return 'Event Failed'
		case 'VIDEO_REQUESTED':
		case 'SNAPSHOT_REQUESTED':
		case 'EVENT_REPORTED':
			return 'Pending'
		default:
			return cell
	}
}

//* returns the filter name what should be displayed in the event filter menu
export const getFilterName = (filterArg) => {
	let name = ''

	switch (filterArg) {
		case 'videouploaded':
			name = 'Uploaded'
			break
		case 'videopending':
			name = 'Pending'
			break
		case 'videononexist':
			name = 'No Video On Device'
			break
		case 'videoconvertedfailed':
			name = 'Conversion Error'
			break
		case 'videotransferringfailed':
			name = 'Download Error'
			break
		case 'videofilesconverted':
			name = 'Files Converted'
			break
		case 'videofailed':
			name = 'Event Failed'
			break
		case 'videoundefined':
			name = 'Unknown'
			break
		default:
			name = filterArg
			break
	}

	return name
}

export const eventTypeDetection = (event) => {
	if (!event) {
		return 'No Event Type'
	}

	// FWSD-3958
	const re = /External Driver|InputSensor2|InputSensor3|InputSensor5|InputSensor9/g;
	let typesArr = event.type.replaceAll(re, 'Driver Event').replace('HighSpeed', 'Max Speed').split(',')
	// let typesArr = event.type.replace(re, 'Driver Event').replace('HighSpeed', 'Max Speed').split(',')

	// FWSD-4506
	if (typesArr.length > 1) {
		if (typesArr.includes('High G-Force')) {
			typesArr = ['High G-Force']
		}
		else {
			typesArr = typesArr.filter(item => item !== 'Critical G-Sensor')
		}
	}

	const type = [...new Set(typesArr)].join(', ')

	// FWSD-4541
	if (event.event_display_type) {
		return event.event_display_type
	}

	// FWSD-5202, FWSD-7032
	if (['Geotab', 'Ituran'].includes(event.type)) {
		return event.rule || event.type
	} 

	// FWSD-4379
	if (event.uploading_status && event.uploading_status.toLowerCase() === 'video_non_exist' && event.type === 'Custom Clip') {
		return 'Custom Clip'
	}

	// if (!event.uploading_status && event.snapshot_status === 'SNAPSHOT_UPLOADED') {
	// 	return type
	// }
	// FWSD-5254
	// if (!event.uploading_status || event.uploading_status.toLowerCase() === 'video_non_exist') {
	//     // return 'No video on device'
	//     return 'Video not found for this time'
	// }

	return type
}