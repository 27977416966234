import React, { useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router'
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next'

import { sendGA } from './helpers/helperUsers'
import './dump/forms/Form.sass'
import roscoLogo from '../../../assets/rosco-logo-horizontal.png'

const EmptyLayout = (props) => {
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'components.emptyLayout' });
	const { loader, children } = props;

	useEffect(() => {
		document.body.classList.add('body-bg');

		if (moment().hour() > 21 || moment().hour() < 6) {
			document.body.classList.add('bg-7')
		}
		else {
			let day = moment().day();
			day = day === 7 ? 6 : day
			day && document.body.classList.add('bg-' + day)
		}

		if (isMobileOnly) {
			document.body.classList.add('mobile');
		}

		const lng = i18n.language;
		if (!localStorage.getItem('language') && ['en', 'fr', 'es'].includes(lng)) {
			i18n.changeLanguage(lng);
			localStorage.setItem('language', lng);
		}

		return () => {
			document.body.classList.remove('body-bg');
		}
	}, []);

	// const panelAccess = !['/confirmation', '/reset-password', '/create-password'].includes(location.pathname);
	const panelAccess = !['/confirmation', '/reset-password', '/create-password'].find(item => location.pathname.includes(item))

	return (
		<div className="container">
			<div className="panel panel-default account-form">
				<div className="panel-heading ui-font-bold">
					<img src={roscoLogo} className="login-logo" />
				</div>
				{children}
				{panelAccess && (
					<div className="panel-body contact-section">
						<h3 className="login-header">
							{t('loginHeader')}
						</h3>
						<div className="login-subheader">
							{t('loginSubheader')}
						</div>
						<a
							href="mailto:sales@roscovision.com"
							className="sales-link"
							onClick={() => sendGA({ category: 'Empty Layout', action: 'Contact Rosco', label: 'Contact Rosco' })}
						>
							{t('linkContactRosco')}
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
								<path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
							</svg>
						</a>
					</div>
				)}
			</div>
			{loader}
		</div>
	);
}

export default connect(
	state => ({
		loader: state.loader.loader
	})
)(EmptyLayout);
