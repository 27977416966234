import React from 'react'
import PropTypes from 'prop-types'
import Toggle from 'react-toggle'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const NotificationToggle = (props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'accountPage.notificationToggle' });
    const { title, name, description, subtitle, email, tooltip, sms, readOnly, onChange, userRole, access } = props

    if (access && access.length && !access.includes(userRole)) {
        return ''
    }

    return (
        <div className="notification-wrapper">
            {!tooltip ?
                <label className='toggles'>
                    <span className='notification-title'>{title}</span>

                    {subtitle && <span className="notification-subtitle">{subtitle}</span>}

                    <span>
                        {(!!email || !!sms) &&
                            <span className='toggle-label'>
                                {!readOnly &&
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil edit-notif" viewBox="0 0 16 16">
                                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                    </svg>
                                }
                                {!!email ? (!!sms ? t('EmailAndSMS') : t('EmailOnly')) : t('SMSOnly')}
                            </span>
                        }
                        <Toggle
                            disabled={readOnly}
                            name={name}
                            checked={!!email || !!sms}
                            onChange={onChange}
                        />
                    </span>
                </label> :
                <OverlayTrigger
                    placement="top"
                    delayHide={100}
                    overlay={<Tooltip id="tooltip" className="tooltip custom-tooltips">{tooltip}</Tooltip>}
                >
                    <label className='toggles'>
                        <span className='notification-title'>{title}</span>
                        <span>
                            <Toggle
                                disabled={true}
                                name={name}
                                checked={false}
                                readOnly
                            />
                        </span>
                    </label>
                </OverlayTrigger>
            }
            <div className="description">{description}</div>
        </div>
    )
};

NotificationToggle.propTypes = {
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired
};

export default NotificationToggle;
