import React, { useState } from 'react'
import { toastr } from 'react-redux-toastr'
import { Button, Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import { apiCall } from '../../../action/RouterActions'
import CustomButton from '../../CustomButton'

const DriverRenamerModal = (props) => {
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'driversPage.fleetAdminDriverContainer.driverRenamerModal' });
	const { toggleDriverRenamer, selectedDriver, setIdentifiedDrivers, drivers, toggleLoader } = props;
	console.log('props', props);

	const [driver_name, setDriver_name] = useState(props.selectedDriver.name);
	const [errMessage, setErrMessage] = useState('');

	const handleInputChange = (event) => {
		const target = event.target,
			value = target.value,
			name = target.name
		if (name === "driver_name") setDriver_name(value);
		if (name === "errMessage") setErrMessage(value);
	}

	const onSave = () => {
		if (!driver_name) {
			setErrMessage(t('errDriverName'))
		}
		else if (driver_name == selectedDriver.name) {
			setErrMessage(t('errDriverNameNotChanged'))
		}
		else {
			setErrMessage('')
			renameDriver()
		}
	}

	const renameDriver = () => {
		toggleLoader(true)

		const data = {
			driver_id: selectedDriver.id,
			driver_name
		}
		apiCall('POST', '/driver/{driver_id}', data).then((res, err) => {
			console.log('!renameDriver res:', res, err)

			const newDrivers = drivers.map((item) => {
				if (item.id === selectedDriver.id) {
					item.name = driver_name
				}
				return item
			})
			setIdentifiedDrivers(newDrivers)
			toggleDriverRenamer(false)

			toggleLoader(false)
			toastr.success('Success', t('driverRenamed'))
		})
			.catch((error) => {
				toggleLoader(false)
				console.log('!renameDriver error:', error.response, error)

				console.log('!renameDriver error:', error.response, error)
				let errDescription = i18n.t('errorMessage.errDescription');
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			})
	}

	return (
		<Modal
			show={true}
			onHide={() => toggleDriverRenamer(false)}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-lg">{t('titleRenameDriver')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className={classnames({ 'has-error': errMessage })}>
					<label>{t('labelDriverName')}</label>
					<input
						type="text"
						name="driver_name"
						className="form-control"
						onChange={handleInputChange}
						value={driver_name}
					/>
					{errMessage && <span className="help-block">{errMessage}</span>}
				</div>
				<div className="photos-list">
					{selectedDriver.photos &&
						selectedDriver.photos.map((item, index) => {
							return <img className="driver-photo" key={item.id} src={item.url} alt="" />
						})
					}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<CustomButton type="button" variant="primary" onClick={onSave}>{t('btnUpdate')}</CustomButton>
			</Modal.Footer>
		</Modal>
	)
}


const mapStateToProps = (state) => {
	const { driver } = state
	return {
		drivers: driver.drivers,
		selectedDriver: driver.selectedDriver
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		toggleDriverRenamer: (state) => {
			dispatch({ type: 'SHOW_DRIVER_RENAMER', payload: state })
		},
		setIdentifiedDrivers: (drivers) => {
			dispatch({ type: 'SET_IDENTIFIED_DRIVERS', payload: drivers })
		},
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DriverRenamerModal);
