import React, { useState, useEffect, useRef } from 'react'
import TreeView from 'react-treeview'

import CustomButton from '../../component/CustomButton'
import { filterIcon, vehicleIcons } from '../../component/helpers/iconHelpers'
import TreeGroupNode from './TreeGroupNode'
import TreeVehicleNode from './TrackTrace/TreeVehicleNode'
import { isInternalUser } from '../../component/helpers/constants'

import './TreeVehicles.sass'

const TreeVehicles = (props) => {
	// console.log('PROPS TreeVehicles deviceGroups: ', props.deviceGroups)
	const { deviceGroups, collapsedBookkeeping, activeTreeItem, showTripsHistory, userRole, markers, onTreeItemClick, onTreeDeviceClick, onTreeGroupNodeClick } = props
	// console.log('PROPS TreeVehicles: ', props)
	// const [treeHeaderHeight, setTreeHeaderHeight] = useState(0)
	const [notGroupedDevices, setNotGroupedDevices] = useState(0)

	// useEffect(() => {
	// 	window.addEventListener('resize', calculateTreeHeaderHeight);

	// 	return () => {
	// 		window.removeEventListener('resize', calculateTreeHeaderHeight)
	// 	}
	// }, [])

	// const calculateTreeHeaderHeight = (a) => {
	// 	const newTreeHeaderHeight = document.getElementById('tree-header') && document.getElementById('tree-header').offsetHeight + 72
	// 	setTreeHeaderHeight(newTreeHeaderHeight)
	// }

	const sortFunc = (values) => {
		if (navigator && navigator.userAgent.search("Firefox") > -1) {
			return _.orderBy(values, ['online', 'vehicle_name', 'device_id'], ['desc', 'asc', 'asc']).sort((a, b) => (((a.online && a.online.state) && (b.online && b.online.state)) && ((a.ignition && a.ignition.state) < (b.ignition && b.ignition.state))) ? 1 : -1);
		}
		return _.orderBy(values, ['online', 'vehicle_name', 'device_id'], ['desc', 'asc', 'asc']).sort((a, b) => (((a.online && a.online.state) && (b.online && b.online.state)) && ((a.ignition && a.ignition.state) && (!b.ignition || !b.ignition.state))) ? -1 : 1);
	}

	// const getActiveMarkerInfo = (device_id) => {
	// 	if (activeTreeItem && activeTreeItem.device_id === device_id) {
	// 		let markerInfo = markers.find(marker => marker.device_id === device_id)
	// 		if (markerInfo) {
	// 			markerInfo.speed_unit = props.user.user.speed_unit
	// 		}
	// 		return markerInfo
	// 	}
	// 	else {
	// 		return null
	// 	}
	// }

	const getNogroupsDevices = () => {
		// if (nogroups != null) {
		if (notGroupedDevices) {
			return (
				<div className='tree-view no-groups-tree'>
					{
						sortFunc(deviceGroups[notGroupedDevices].value).map((entry, j) => {
							return (
								<TreeVehicleNode
									key={entry.device_id}
									{...entry}
									entry={entry}
									activeTreeItem={activeTreeItem}
									// markerInfo={getActiveMarkerInfo(entry.device_id)}
									onTreeDeviceClick={onTreeDeviceClick}
									isInternalUser={isInternalUser(userRole)}
									showTripsHistory={showTripsHistory}
								/>
							)
						})
					}
				</div>
			)
		}
	}

	const renderTree = () => {
		const res = []
		deviceGroups.forEach((group, i) => {
			// if (group.groupName !== 'null') {
			res.push(
				<TreeGroupNode
					key={group.groupName}
					group={group}
					onTreeGroupNodeClick={onTreeGroupNodeClick}
				/>
			)

			if (!group.collapsed) {
				// TODO: maybe make sense to do sorting before storing groups in Dashboard
				// BUT the /devices/eventupdates API updates the devices list and status can be changed after group sorting
				sortFunc(group.devices).map((device) => {
					res.push(
						<TreeVehicleNode
							key={device.device_id}
							device={device}
							// {...device}
							activeTreeItem={activeTreeItem}
							// markerInfo={getActiveMarkerInfo(entry.device_id)}
							onTreeDeviceClick={onTreeDeviceClick}
						// showTripsHistory={showTripsHistory}
						// isInternalUser={isInternalUser(userRole)}
						/>
					)
				})
			}
			// }
			// else {
			// 	setNotGroupedDevices(i)
			// }
		})

		return res
	}

	return (
		// <div className="devices-tree" style={{ height: `calc(100vh - ${treeHeaderHeight + 93}px)` }}>
		<div className="devices-tree" style={{ height: `calc(100vh - 393px)` }}>
			{renderTree()
				// deviceGroups.map((group, i) => {
				// 	if (group.id !== 'null') {
				// 		return (
				// 			<TreeGroupNode
				// 				group={group}
				// 				onTreeGroupNodeClick={onTreeGroupNodeClick}
				// 			>
				// 				{!group.collapsed &&
				// 				(
				// 					group.devices.map((device) => {
				// 						return (
				// 							<div key={device.device_id}>
				// 								{device.vehicle_name}
				// 							</div>
				// 						)
				// 					})
				// 				)

				// 				}
				// 			</TreeGroupNode>
				// 		)
				// const type = node.id;
				// const label = (
				// 	<CategoryItem
				// 		className='node'
				// 		small
				// 		text={type}
				// 		withArrow
				// 		// onClick={handleTreeClick.bind(this, i)}
				// 		onClick={onTreeItemClick.bind(this, i)}
				// 		rightLabel={
				// 			`${node.value.filter(device => device.online).length}/${node.value.length}`
				// 		}
				// 	/>
				// )
				// return (
				// 	<TreeView
				// 		key={node.id}
				// 		nodeLabel={label} // displays folders of the tree
				// 		collapsed={collapsedBookkeeping[i]}
				// 		// onClick={handleTreeClick.bind(this, i)}
				// 		onClick={onTreeItemClick.bind(this, i)}
				// 	>
				// 		{
				// 			sortFunc(node.value).map((entry, j) => {
				// 				return (
				// 					<TreeVehicleNode // displays vehicles in root of the tree
				// 						key={entry.device_id}
				// 						{...entry}
				// 						entry={entry}
				// 						activeTreeItem={activeTreeItem}
				// 						markerInfo={getActiveMarkerInfo(entry.device_id)}
				// 						onTreeDeviceClick={onTreeDeviceClick}
				// 						showTripsHistory={showTripsHistory}
				// 						isInternalUser={isInternalUser(userRole)}
				// 					/>
				// 				)
				// 			})
				// 		}
				// 	</TreeView>
				// )
				// }
				// else {
				// 	nogroups = i;
				// }
				// }) 

			}

			{/* {getNogroupsDevices()} */}

			{/* {state.singleTrucks.length > 0
			&& (
				<div className="button-blue" style={{ marginTop: "10px" }}>
					<button className="btn btn-default" onClick={clearSingleTrucks} style={{ margin: "0" }}>Clear vehicle filter</button>
				</div>
			)} */}

		</div>
	)
}

export default TreeVehicles;
