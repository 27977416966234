import React from 'react'
import Toggle from 'react-toggle'
import { useTranslation } from 'react-i18next'
// import ReactBootstrapSlider from 'react-bootstrap-slider'
// import Slider from 'rc-slider'
// import 'rc-slider/assets/index.css'
import { isInternalUser } from '../../helpers/constants'
import CustomButton from '../../CustomButton'
import CustomReactBootstrapSlider from '../../CustomReactBootstrapSlider'
import { timezoneDBOptions } from '../../helpers/constants'
import SelectionEventVideoType from './SelectionEventVideoType'

// import 'bootstrap-slider/dist/css/bootstrap-slider.css'

const FleetConfigurationGeneral = (props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'manageVehiclesPage.fleetConfigurationContainer.editProfile.fleetConfigurationGeneral' });
	const { settingsType, configuration, configurationdv6, fillFormBoolean, fillFormInt, fillForm, user, event_types, setConfiguration } = props
	const isDV6 = settingsType === 'dv6'
	const isDV4 = settingsType === 'dv4'
	const currentConfig = isDV6 ? configurationdv6 : configuration

	const PotentialCh2Obstruction = [
		{ title: t('title_0'), value: '0', description: t('description_0') },
		{ title: t('title_1'), value: '1', description: t('description_1') },
		{ title: t('title_2'), value: '2', description: t('description_2') },
	]

	const setEventTypes = (argTypeValue) => {
		setConfiguration({ event_types: { ...event_types, ...argTypeValue } })
	}

	return (
		<main className="general-settings main-content-block">
			<h3 className="category-title">{t('titleGeneralSettings')}</h3>
			<div className="category-subheader">
				{t('subheaderGeneralSettings')}
			</div>

			<div className="settings-card">
				<div className="card-header">
					{t('cardHeaderOverlaySpeed')}
					<Toggle
						name="Overlay.ShowSpeed"
						checked={currentConfig.Overlay.ShowSpeed}
						icons={false}
						onChange={(e) => fillFormBoolean(e, isDV6)}
					/>
				</div>
				{/* anumation of card-body content displaing with delay 0.5s */}
				<div className="card-body">
					<p>{t('cardBodyOverlaySpeed')}</p>
					<div className={`${!currentConfig.Overlay.ShowSpeed ? 'hide' : ''}`}>
						<div className="toggle-wrapper button-group">
							<label className="settings-title">{t('titleSpeedUnit')}</label>
							<div className="toggle-buttons">
								<CustomButton
									variant='toggle'
									active={currentConfig.System.SpeedUnit === 1}
									value="1"
									name="System.SpeedUnit"
									// onClick={(e) => fillFormThreshold(e, "both")}
									onClick={(e) => fillFormInt(e, isDV6)}
								>
									{t('btnMph')}
								</CustomButton>
								<CustomButton
									variant='toggle'
									active={currentConfig.System.SpeedUnit === 2}
									value="2"
									name="System.SpeedUnit"
									// onClick={(e) => fillFormThreshold(e, "both")}
									onClick={(e) => fillFormInt(e, isDV6)}
								>
									{t('btnKph')}
								</CustomButton>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* DV6 settings only */}
			{isDV6 &&
				<>
					<div className="settings-card">
						<div className="card-header">
							{t('cardHeaderCameraVolume')}
							<div className="slider-wrapper header-slider mr-10">
								<div className="slider-minmax-value min-value">
									0 %
								</div>
								<CustomReactBootstrapSlider
									name="System.Chimes.Volume"
									max={100}
									min={0}
									step={1}
									tooltip="show"
									value={currentConfig.System.Chimes.Volume}
									formatter={(v) => `${v}%`}
									change={(e) => fillFormInt(e, isDV6, 'System.Chimes.Volume')}
								// disabled="disabled"
								// ticks={[60, 70, 80, 90, 100]}
								/>
								<div className="slider-minmax-value max-value">
									100%
								</div>
							</div>
						</div>
						<div className="card-body">
							<p>{t('cardBodyCameraVolume')}</p>
						</div>
					</div>
				</>
			}

			{/* DV4 settings only */}
			{isDV4 &&
				<div className="settings-card">
					<div className="card-header">
						{t('cardHeaderCameraAudibleChimes')}
						<Toggle
							name="Event.BuzzerEnabled"
							checked={currentConfig.Event.BuzzerEnabled}
							icons={false}
							onChange={(e) => fillFormBoolean(e, isDV6)}
						/>
					</div>
					<div className="card-body">
						<p>{t('cardBodyCameraAudibleChimes')}</p>
					</div>
				</div>
			}

			<div className="settings-card">
				<div className="card-header">
					{t('cardHeaderParkingMode')}
					<Toggle
						name="ParkingMode"
						checked={currentConfig.General.IgnitionShutdownDelay > 0}
						icons={false}
						onChange={(e) => fillForm(e, isDV6)}
					/>
				</div>
				<div className="card-body">
					<p>{t('cardBodyParkingMode')}</p>
					{/* anumation of card-body content displaing with delay 0.5s */}
					<div className={`${currentConfig.General.IgnitionShutdownDelay === 0 ? 'hide' : ''}`}>
						<div className="toggle-wrapper button-group">
							<label className="settings-title">{t('titleParkingModeDuration')}</label>
							<select
								placeholder={t('placeholderSelect')}
								className="form-control rosco-select"
								name="General.IgnitionShutdownDelay"
								onChange={(e) => fillFormInt(e, isDV6)}
								value={currentConfig.General.IgnitionShutdownDelay}
							>
								<option value="0">{t('option0')}</option>
								<option value="30">{t('option30')}</option>
								<option value="60">{t('option60')}</option>
								<option value="90">{t('option90')}</option>
								<option value="120">{t('option120')}</option>
								<option value="180">{t('option180')}</option>
								<option value="240">{t('option240')}</option>
								<option value="360">{t('option360')}</option>
								<option value="480">{t('option480')}</option>
								<option value="600">{t('option600')}</option>
								<option value="720">{t('option720')}</option>
								<option value="1440">{t('option1440')}</option>
								{/* <option value="2880">{t('option2880')}</option>
								<option value="4320">{t('option4320')}</option> */}
							</select>
						</div>
					</div>
				</div>
			</div>

			<div className="settings-card">
				<div className="card-header">
					{t('cardHeaderTimezone')}
					<div>
						<select
							placeholder="select"
							className="form-control rosco-select"
							name="General.TimeZone"
							value={currentConfig.General.TimeZone}
							onChange={(e) => fillForm(e, isDV6)}
						>
							{timezoneDBOptions()}
						</select>
					</div>
				</div>
				<div className="card-body">
					<p>{t('cardBodyTimezone')}</p>
				</div>
				<div className="toggle-wrapper button-group">
					<label className="settings-title" style={{ marginBottom: "0px" }}>{t('displayTimezoneInVideo')}</label>
					<Toggle
						name="Overlay.ShowTZ"
						checked={currentConfig.Overlay.ShowTZ}
						icons={false}
						onChange={(e) => fillFormBoolean(e, isDV6)}
					/>
				</div>
			</div>

			{/* DV4 settings only */}
			{isDV4 &&
				<div className="settings-card">
					<div className="card-header">
						{t('cardHeaderLCDBacklight')}
					</div>
					<div className="card-body">
						<p>{t('cardBodyLCDBacklight')}</p>

						<div className="toggle-wrapper button-group">
							<label className="settings-title">{t('titleDayMode')}</label>
							<div className="slider-wrapper header-slider mr-10">
								<div className="slider-minmax-value min-value">
									0
								</div>
								<CustomReactBootstrapSlider
									name="System.BacklightHigh"
									max={1000}
									min={0}
									step={100}
									tooltip="show"
									value={currentConfig.System.BacklightHigh || 0}
									change={(e) => fillFormInt(e, isDV6, 'System.BacklightHigh')}
								// ticks={[60, 70, 80, 90, 100]}
								/>
								<div className="slider-minmax-value max-value">
									1000
								</div>
							</div>
						</div>

						<div className="toggle-wrapper button-group">
							<label className="settings-title">{t('titleNightMode')}</label>
							<div className="slider-wrapper header-slider mr-10">
								<div className="slider-minmax-value min-value">
									0
								</div>
								<CustomReactBootstrapSlider
									name="System.BacklightLow"
									max={1000}
									min={0}
									step={100}
									tooltip="show"
									value={currentConfig.System.BacklightHigh || 0}
									change={(e) => fillFormInt(e, isDV6, 'System.BacklightLow')}
								// ticks={[60, 70, 80, 90, 100]}
								/>
								<div className="slider-minmax-value max-value">
									1000
								</div>
							</div>
						</div>
					</div>
				</div>
			}

			{/* DV6 settings only */}
			{isDV6 &&
				<div className="settings-card">
					<div className="card-header">
						{t('titleSleepMode')}
						<Toggle
							name="Servers.SleepMode.Enabled"
							checked={currentConfig.Servers.SleepMode.Enabled}
							icons={false}
							onChange={(e) => fillFormBoolean(e, isDV6)}
						/>
					</div>
					<div className="card-body">
						<p>
							{t('textSleepMode')}<br /><br />
							<a href="https://support.roscovision.com/knowledge/sleep-mode-feature-guide" target="_blank">{t('linkLearnMore')}</a>
						</p>
					</div>
				</div>
			}
		</main>
	)
}

export default FleetConfigurationGeneral;
