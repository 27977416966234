import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, Dropdown } from 'react-bootstrap'
import { browserHistory } from 'react-router'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import _ from 'lodash'
import { RiAlertFill } from 'react-icons/ri'
import { useTranslation } from 'react-i18next'

import CustomButton from '../../CustomButton';
import ModalDelete from '../modals/ModalDelete'
import ModalDeviceAuditLog from '../modals/ModalDeviceAuditLog'
import ModalChannelStatus from '../modals/ModalChannelStatus'
import CustomOverlayTrigger from '../../CustomOverlayTrigger'

import { formatChannelStatus, formatChannelStatusCSV, formatDataUsage, /*formatDataUsageLink,*/ formatDataUsageCSV, formatStreamDataUsage, formatLiveStream, formatFailStatus, /* formatFirmwareCSV, formatFirmwareStatus, */ formatGroup, formatOnlineStatus, formatOnlineStatusCSV, formatOnlineStatusLocal, formatOnlineStatusCSVLocal, formatRecordingStatus, formatRecordingStatusCSV, formatTimestamp, formatVehicleName } from '../../helpers/tablesFuncHelpers'
import { tableExportOptions } from '../../helpers/table'
import { filterIcon } from '../../helpers/iconHelpers'
import { isInternalUser } from '../../helpers/constants'
import { hasSleepStatusesAccess } from '../../helpers/helperSleepMode'

import '../../dump/menuComponents/menuComponents.sass'

const ManageAssetsContainer = (props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'manageVehiclesPage.manageAssetsContainer' });
	// console.log('PROPS ManageAssetsContainer: ', props)
	const { initialData, company, devices, selectedDevices, filterColumn, filterConfigColumn, user, selectProfile, sortDevicesName, sortDevicesOrder, setDevicesSort, columns, getCompanyDevices, monthlyDataAvailable } = props;
	const userRole = user.roles[0];

	const [activeDevice, setActiveDevice] = useState(null);
	const [activeChannel, setActiveChannel] = useState(null);
	const [showModalDeviceAuditLog, setShowModalDeviceAuditLog] = useState(false);
	const [showModalChannelStatus, setShowModalChannelStatus] = useState(false);
	// const [showModalDataRestricted, setShowModalDataRestricted] = useState(false);
	const [sortName, setSortName] = useState('vehicle_name');
	const [sortOrder, setSortOrder] = useState('asc');
	const [groups, setGroups] = useState([]);
	const [sortGroupNames, setSortGroupNames] = useState(true);

	useEffect(() => {
		const initialGroups = initialData.reduce((totalGroups, { group_name }) => {
			if (group_name) {
				!totalGroups.includes(group_name) && totalGroups.push(group_name)
			}
			else {
				!totalGroups.includes('No group') && totalGroups.push('No group')
			}
			// if (groups[0]) {
			// 	!totalGroups.includes(groups[0]) && totalGroups.push(groups[0])
			// }
			// else {
			// 	!totalGroups.includes('No group') && totalGroups.push('No group')
			// }
			return totalGroups.sort()
		}, []);

		setGroups(initialGroups);
	}, [initialData]);

	const editVehicle = (e, id) => {
		e.preventDefault();

		// browserHistory.push({ pathname: '/managevehicles/edit', state: { id: id, company, last_disconnected_ts: last_disconnected_ts } });
		browserHistory.push({ pathname: '/managevehicles/edit', state: { id, company } });
	}

	const auditLogFormatter = (e, device) => {
		return (
			<CustomButton
				variant="link"
				onClick={() => {
					setShowModalDeviceAuditLog(true)
					setActiveDevice(device)
				}}
			>
				{t('btnView')}
			</CustomButton>
		)
	}

	const handleFailureChannelClick = (row, channel) => {
		setShowModalChannelStatus(true)
		setActiveDevice(row)
		setActiveChannel(channel)
	}

	const formatRecordingStatus = (cell) => {
		if (!cell) {
			return <span>OK</span>
		}
		return <span className="error-label">{t('labelNeedsReformat')}</span>
	}

	const formatRecordingStatusCSV = (cell) => (!cell ? t('labelOK') : t('labelNeedsReformat'))

	const { channelStatus, group, configProfile, onlineStatus, totalDataUsage, streamDataUsage, liveStream, recordingStatus, /* firmwareStatus, */ firstConnected, id, auditLog, vinNumber, plateNumber, imei, no_sd_card } = columns;

	const formatCameraName = (cell, row) => {
		return (
			<div className="camera-name-wrapper">
				<input
					type="checkbox"
					value={row.vehicle_name || row.device_id}
					checked={selectedDevices.includes(row.device_id)}
					onChange={() => props.selectDevice(row)}
					disabled={!row.activated_timestamp}
					className="device-checkbox"
				/>
				<CustomButton variant="link" onClick={(e) => editVehicle(e, row.device_id /* , row.firmware */)}>{cell || t('btnUnnamed')}</CustomButton>
			</div>
		)
	}

	const formatProfileName = (cell, row, isCSV = false) => {
		const { config_checksum, pending_company_profile, uploaded_company_profile } = row
		const canViewProfiles = ['system_admin', 'customer_service', 'partner', 'fleet_manager', 'sales_demo', 'storage_manager'].includes(userRole)
			|| (['custom_user', 'custom_partner'].includes(userRole) && _.find(user.permissions, { perm_category_id: 3, a_view: true }))
		if (config_checksum === 1) {
			if (pending_company_profile && pending_company_profile.toLowerCase() !== 'manual') {
				return canViewProfiles && !isCSV
					? <span>{t('textPendingUpdate')} <CustomButton variant="link" name={pending_company_profile} onClick={selectProfile}>{pending_company_profile}</CustomButton></span>
					: `${t('textPendingUpdate')} ${pending_company_profile}`
			}
			return `${t('textPendingUpdate')} ${t('textManual')}`
		}
		if (uploaded_company_profile) {
			return uploaded_company_profile === 'Manual'
				? t('textManual')
				: canViewProfiles && !isCSV
					? <CustomButton variant="link" name={uploaded_company_profile} onClick={selectProfile}>{uploaded_company_profile}</CustomButton>
					: uploaded_company_profile
		}

		return t('textManual')
	}

	const liveStreamDurationSortFunc = () => (a, b, order) => {
		const first = a.live_stream.duration_month || 0
		const second = b.live_stream.duration_month || 0

		if (order === 'desc') {
			return second - first
		}

		return first - second
	}

	const filterValues = [...new Set(initialData.map(d => {
		if (d && d.config_checksum === 1) {
			return t('textPending')
		}
		if (d && d.uploaded_company_profile) {
			return d.uploaded_company_profile
		}
		return t('textManual')
	}))];

	const configProfileFilter = () => {
		let filterList = [];
		for (const value of filterValues.sort()) {
			if (value) {
				filterList.push(
					<li
						key={value}
						title={value}
					>
						<input type="checkbox" checked={isConfigChecked(value)} onChange={(e) => filterConfigColumn(e, value)} />
						<span className="groupName">{value || t('textManual')}</span>
					</li>
				);
			}
		}
		return filterList;
	}

	const isConfigChecked = (value) => {
		let devicesUpdate = devices;
		if (value === 'Manual') {
			return devicesUpdate.filter(e => e['config_checksum'] !== 1 && (e['uploaded_company_profile'] === 'Manual' || !e['uploaded_company_profile'])).length;;
		}
		else if (value === 'Pending') {
			return devicesUpdate.filter(e => e['config_checksum'] === 1).length;
		}
		return devicesUpdate.filter(e => e['uploaded_company_profile'] === value).length;
	}

	const numberOfSelectedDevices = () => {
		let devicesUpdate = devices;
		let i = 0;
		devicesUpdate.map(device => { if (!!device.activated_timestamp) i++ });
		return i;
	}

	const handleSortGroupsClick = (sortGroupNames) => {
		setSortGroupNames(sortGroupNames);
		let data = groups;
		sortGroupNames ? setGroups(data.sort()) : setGroups(data.sort().reverse());
	}

	// FWSD-6749
	const formatDataUsageLocal = (cell, row) => {
		const deviceDataUsageGB = row.data_usage / 1024 / 1024 / 1024
		const deviceDataUsagePerc = (deviceDataUsageGB * 100 / monthlyDataAvailable).toFixed(1)
		const isDeviceDataUsageOver = (deviceDataUsageGB >= monthlyDataAvailable * 0.3)

		return (
			<div className="device-data-usage">
				{deviceDataUsageGB.toFixed(2)}
				{isInternalUser(userRole) && isDeviceDataUsageOver &&
					<CustomOverlayTrigger
						{...{ type: 'warn', title: t('overlayTriggerWarnTitle'), body: t('overlayTriggerWarnBody', { deviceDataUsagePerc }) }}
						placement="top"
						trigger="click"
						closeBtn={true}
						prefix='warn'
						bodyPrefix='text-center'
					>
						<div>
							<CustomButton variant="link">
								<RiAlertFill />
							</CustomButton>
						</div>
					</CustomOverlayTrigger>
				}
			</div>
		)
	}

	// DETERMINE TABLE MIN-HEIGHT BASED ON DROPDOWNS
	// const tableHeight = `${(groups.length > filterValues.length ? groups.length : filterValues.length) * 20 + 140}px`;
	const tableHeight = '0px';

	return (
		<div className="manage-assets-table">
			<BootstrapTable
				data={devices}
				{...tableExportOptions}
				options={{
					...tableExportOptions.options,
					sortName: sortDevicesName,
					sortOrder: sortDevicesOrder,
					onSortChange: setDevicesSort,
					// defaultSortName: 'vehicle_name',
					// defaultSortOrder: 'asc',
				}}
				tableStyle={{ minHeight: tableHeight }}
				tableContainerClass="rosco-table manage-vehicles-table"
				headerContainerClass="rosco-table-header manage-vehicles-table-header"
				bodyContainerClass="rosco-table-body manage-vehicles-table-body"
				csvFileName={`${t('csvFileName')}.csv`}
			>
				<TableHeaderColumn
					width="250px"
					dataField="vehicle_name"
					dataFormat={formatCameraName}
					csvFormat={formatVehicleName}
					isKey
					dataSort
					csvHeader={t('labelCameraName')}
				>
					<input type="checkbox" checked={numberOfSelectedDevices() && selectedDevices.length === numberOfSelectedDevices()} onChange={(e) => props.selectAllDevices(e)} className="device-checkbox" />
					<label className="camera-name-label">{t('labelCameraName')}</label>
				</TableHeaderColumn>
				<TableHeaderColumn
					width="100px"
					dataField="device_id"
					csvHeader={t('labelDeviceID')}
					hidden={!id}
				>
					{t('labelDeviceID')}
				</TableHeaderColumn>
				<TableHeaderColumn
					width="180px"
					dataField="group_name"
					dataFormat={formatGroup}
					csvHeader={t('labelGroupName')}
					hidden={!group}
					className="with-filter"
				// dataSort
				>
					<div className="filter-wrapper">
						<label className="filter-label">{t('labelGroupName')}</label>
						<span className="order" style={{ cursor: 'pointer', marginLeft: '4px' }}>
							{(sortDevicesName !== 'group_name' || (sortDevicesName === 'group_name' && sortDevicesOrder === 'desc')) &&
								<span className="dropdown" onClick={() => setDevicesSort('group_name', 'asc')}><span className="caret"></span></span>
							}
							{(sortDevicesName !== 'group_name' || (sortDevicesName === 'group_name' && sortDevicesOrder === 'asc')) &&
								<span className="dropup" onClick={() => setDevicesSort('group_name', 'desc')}><span className="caret"></span></span>
							}
						</span>
						<Dropdown drop id="type-filter-btn" className="filter-dropdown">
							<Dropdown.Toggle as='span' className="typeFilter" bsPrefix="no-caret">
								{filterIcon}
							</Dropdown.Toggle>
							<Dropdown.Menu className="typeFilterMenu">
								<div className="groups-header">
									<h5 className="category-title">{t('titleGroups')}</h5>
									<span className={`order ${sortGroupNames ? 'dropup' : ''}`}>
										<span className="caret" onClick={() => handleSortGroupsClick(!sortGroupNames)}></span>
									</span>
								</div>
								{groups.map(groupItem => {
									return (
										<li
											key={groupItem}
											title={groupItem}
										>
											<input
												type="checkbox"
												checked={groupItem === 'No group' ? devices.filter(e => !e.group_name).length : devices.filter(e => e.group_name === groupItem).length}
												onChange={(e) => filterColumn(e, 'group_name', groupItem)}
											/>
											<span className="groupName">{groupItem}</span>
										</li>
									)
								})}
								<div className="clear-all-container">
									<CustomButton variant="primary-outline" size="sm" prefix="clear-all" onClick={(e) => filterColumn(e, 'clear_all')}>
										{`${devices.length === 0 ? t('select') : t('clear')} ${t('all')}`}
									</CustomButton>
								</div>
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</TableHeaderColumn>
				<TableHeaderColumn
					width="260px"
					dataField="status"
					// dataFormat={formatOnlineStatus}
					dataFormat={formatOnlineStatusLocal}
					csvFormat={formatOnlineStatusCSVLocal}
					csvHeader={t('labelActivityStatus')}
					className="with-filter"
					columnClassName="fleet-activity-status"
					hidden={!onlineStatus}
				>
					<div className="filter-wrapper">
						<label className="filter-label">{t('labelActivityStatus')}</label>
						<span className="order" style={{ cursor: 'pointer', marginLeft: '4px' }}>
							{(sortDevicesName !== 'status' || (sortDevicesName === 'status' && sortDevicesOrder === 'desc')) &&
								<span className="dropdown" onClick={() => setDevicesSort('status', 'asc')}><span className="caret"></span></span>
							}
							{(sortDevicesName !== 'status' || (sortDevicesName === 'status' && sortDevicesOrder === 'asc')) &&
								<span className="dropup" onClick={() => setDevicesSort('status', 'desc')}><span className="caret"></span></span>
							}
						</span>
						<Dropdown drop id="type-filter-btn" className="filter-dropdown">
							<Dropdown.Toggle as='span' bsPrefix="no-caret" className="typeFilter">
								{filterIcon}
							</Dropdown.Toggle>
							<Dropdown.Menu className="typeFilterMenu">
								<h5 className="category-title">{t('labelActivityStatus')}</h5>
								<li
									key="online"
									title={t('labelDriving')}
								>
									<input type="checkbox" checked={devices.filter(e => e.status === 'driving').length} onChange={(e) => filterColumn(e, 'status', 'driving')} />
									<span className="groupName">{t('labelDriving')}</span>
								</li>
								<li
									key="parked"
									title={t('labelParked')}
								>
									<input type="checkbox" checked={devices.filter(e => e.status === 'parked').length} onChange={(e) => filterColumn(e, 'status', 'parked')} />
									<span className="groupName">{t('labelParked')}</span>
								</li>
								<li
									key="offline"
									title={t('labelOffline')}
								>
									<input type="checkbox" checked={devices.filter(e => e.status === 'offline').length} onChange={(e) => filterColumn(e, 'status', 'offline')} />
									<span className="groupName">{t('labelOffline')}</span>
								</li>
								<li
									key="disabled"
									title={t('labelDisabled')}
								>
									<input type="checkbox" checked={devices.filter(e => e.status === 'disabled').length} onChange={(e) => filterColumn(e, 'status', 'disabled')} />
									<span className="groupName">{t('labelDisabled')}</span>
								</li>
								{hasSleepStatusesAccess() === true &&
									<>
										<li
											key="sleep"
											title={t('labelSleep')}
										>
											<input type="checkbox" checked={devices.filter(e => e.status === 'sleep').length} onChange={(e) => filterColumn(e, 'status', 'sleep')} />
											<span className="groupName">{t('labelSleep')}</span>
										</li>
										<li
											key="awake"
											title={t('labelAwake')}
										>
											<input type="checkbox" checked={devices.filter(e => e.status === 'awake').length} onChange={(e) => filterColumn(e, 'status', 'awake')} />
											<span className="groupName">{t('labelAwake')}</span>
										</li>
										{/* <li
											key="pending sleep"
											title={t('labelGoingToSleep')}
										>
											<input type="checkbox" checked={devices.filter(e => e.status === 'pending sleep').length} onChange={(e) => filterColumn(e, 'status', 'pending sleep')} />
											<span className="groupName">{t('labelGoingToSleep')}</span>
										</li>
										<li
											key="pending awake"
											title={t('labelWakingUp')}
										>
											<input type="checkbox" checked={devices.filter(e => e.status === 'pending awake').length} onChange={(e) => filterColumn(e, 'status', 'pending awake')} />
											<span className="groupName">Waking Up</span>
										</li> */}
									</>
								}
								<div className="clear-all-container">
									<CustomButton variant="primary-outline" size="sm" prefix="clear-all" onClick={(e) => filterColumn(e, 'clear_all')}>
										{`${devices.length === 0 ? t('select') : t('clear')} ${t('all')}`}
									</CustomButton>
								</div>
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</TableHeaderColumn>
				<TableHeaderColumn
					width="160px"
					dataField="activated_timestamp"
					dataFormat={formatTimestamp}
					csvFormat={formatTimestamp}
					csvHeader={t('labelFirstConnected')}
					hidden={!firstConnected}
				>
					{t('labelFirstConnected')}
				</TableHeaderColumn>
				<TableHeaderColumn
					width="180px"
					dataField="uploaded_company_profile"
					dataFormat={formatProfileName}
					csvFormat={(cell, row) => formatProfileName(cell, row, true)}
					csvHeader={t('labelConfigProfile')}
					className="with-filter"
					hidden={!configProfile}
				>
					<div className="filter-wrapper">
						<label className="filter-label">{t('labelSettingsProfile')}</label>
						<Dropdown drop id="type-filter-btn" className="filter-dropdown">
							<Dropdown.Toggle as='span' bsPrefix="no-caret" className="typeFilter config-filter">
								{filterIcon}
							</Dropdown.Toggle>
							<Dropdown.Menu className="typeFilterMenu">
								<h5 className="category-title">{t('labelSettingsProfile')}</h5>
								{configProfileFilter()}
								<div className="clear-all-container">
									<CustomButton variant="primary-outline" size="sm" prefix="clear-all" onClick={(e) => filterColumn(e, 'clear_all')}>
										{`${devices.length === 0 ? t('select') : t('clear')} ${t('all')}`}
									</CustomButton>
								</div>
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</TableHeaderColumn>
				{/* <TableHeaderColumn
					width="11%"
					dataField="firmware_status"
					dataFormat={formatFirmwareStatus}
					csvFormat={formatFirmwareCSV}
					csvHeader="Firmware Status"
					className="with-filter"
					hidden={!firmwareStatus}
				>
					<div className="filter-wrapper">
						<label className="filter-label">Firmware</label>
						<Dropdown drop id="type-filter-btn" className="filter-dropdown">
							<Dropdown.Toggle as='span' bsPrefix="no-caret" className="typeFilter config-filter">
								{filterIcon}
							</Dropdown.Toggle>
							<Dropdown.Menu className="typeFilterMenu">
								<h5 className="category-title">Firmware Status</h5>
								<li
									key="Out of Date"
									title="Out of Date"
								>
									<input type="checkbox" checked={devices.filter(e => e['firmware_status'] === 'firmware out of date').length} onChange={(e) => filterColumn(e, 'firmware_status', 'firmware out of date')} />
									<span className="groupName">Out of Date</span>
								</li>
								<li
									key="Latest"
									title="Latest"
								>
									<input type="checkbox" checked={devices.filter(e => e['firmware_status'] === 'latest firmware').length} onChange={(e) => filterColumn(e, 'firmware_status', 'latest firmware')} />
									<span className="groupName">Latest</span>
								</li>
								<li
									key="Pending"
									title="Pending"
								>
									<input
										type="checkbox"
										checked={devices.filter(e => e['firmware_status'].includes('firmware update')).length}
										onChange={(e) => filterColumn(e, 'firmware_status', 'firmware update')}
									/>
									<span className="groupName">Pending</span>
								</li>
								<li
									key="Test Firmware"
									title="Test Firmware"
								>
									<input type="checkbox" checked={devices.filter(e => e['firmware_status'] === 'test firmware').length} onChange={(e) => filterColumn(e, 'firmware_status', 'test firmware')} />
									<span className="groupName">Test Firmware</span>
								</li>
								<div className="clear-all-container">
									<CustomButton variant="primary-outline" size="sm" prefix="clear-all" onClick={(e) => filterColumn(e, 'clear_all')}>
										{`${devices.length === 0 ? 'Select' : 'Clear'} All`}
									</CustomButton>
								</div>
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</TableHeaderColumn> */}
				{/* {isInternalUser(userRole) && (  // FWSD-4463 */}
				{/* <TableHeaderColumn
					width="11%"
					// dataField="latest_hdderror cameraError"
					dataField="recordingError"
					dataFormat={formatRecordingStatus}
					csvFormat={formatRecordingStatusCSV}
					csvHeader="Recording Status"
					className="with-filter"
					// dataSort
					hidden={!recordingStatus || !isInternalUser(userRole)}
				>
					<div className="filter-wrapper">
						<label className="filter-label">Recording</label>
						<Dropdown drop id="type-filter-btn" className="filter-dropdown">
							<Dropdown.Toggle as='span' bsPrefix="no-caret" className="typeFilter">
								{filterIcon}
							</Dropdown.Toggle>
							<Dropdown.Menu className="typeFilterMenu">
								<h5 className="category-title">Recording Status</h5>
								<li
									key="Ok"
									title="Ok"
								>
									<input
										type="checkbox"
										checked={devices.filter(e => _.isEmpty(e.latest_hdderror) || e.latest_hdderror.count === 0).length}
										onChange={(e) => filterColumn(e, 'latest_hdderror', 'OK')}
									/>
									<span className="groupName">Ok</span>
								</li>
								<li
									key="Needs Reformat"
									title="Needs Reformat"
								>
									<input
										type="checkbox"
										checked={devices.filter(e => !_.isEmpty(e.latest_hdderror) && e.latest_hdderror.count > 0).length}
										onChange={(e) => filterColumn(e, 'latest_hdderror', 'Needs Reformat')}
									/>
									<span className="groupName">Needs Reformat</span>
								</li>
								<div className="clear-all-container">
									<CustomButton variant="primary-outline" size="sm" prefix="clear-all" onClick={(e) => filterColumn(e, 'clear_all')}>
										{`${devices.length === 0 ? 'Select' : 'Clear'} All`}
									</CustomButton>
								</div>
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</TableHeaderColumn> */}
				{/* )} */}
				<TableHeaderColumn
					width="160px"
					dataField="cameraError"
					dataFormat={(cell, row) => formatChannelStatus(row.channel_status, row, handleFailureChannelClick)}
					csvFormat={formatChannelStatusCSV}
					csvHeader={t('labelChannelStatus')}
					dataSort
					hidden={!channelStatus}
				>
					{t('labelChannelStatus')}
				</TableHeaderColumn>
				<TableHeaderColumn
					width="160px"
					dataField="data_usage"
					// dataFormat={(cell, row) => formatDataUsageLink(cell, row, props.companyData, (e) => { e.preventDefault(); setShowModalDataRestricted(true) })}
					// dataFormat={(cell, row) => formatDataUsage(cell, row, props.companyData)}
					dataFormat={(cell, row) => formatDataUsageLocal(cell, row)}
					csvFormat={(cell, row) => formatDataUsageCSV(cell, row, props.companyData)}
					csvHeader={t('labelDataUsage')}
					dataSort
					hidden={!totalDataUsage}
				>
					{t('labelDataUsage')}
				</TableHeaderColumn>
				<TableHeaderColumn
					width="200px"
					dataField="data_usage_live_stream"
					dataFormat={(cell, row) => formatStreamDataUsage(cell, row)}
					csvFormat={(cell, row) => formatStreamDataUsage(cell, row, true)}
					csvHeader={t('labelLiveStreamDataUsage')}
					dataSort
					hidden={!streamDataUsage}
				>
					{t('labelLiveStreamDataUsage')}
				</TableHeaderColumn>
				{/* <TableHeaderColumn
					width="11%"
					dataField="liveStreamError"
					dataFormat={formatLiveStream}
					csvFormat={(cell, row) => formatLiveStream(cell, row, true)}
					csvHeader="Live Stream (min)"
					dataSort
					sortFunc={liveStreamDurationSortFunc()}
					hidden={!liveStream}
				>
					Live Stream (min)
				</TableHeaderColumn> */}
				<TableHeaderColumn
					width="200px"
					dataField="vin_number"
					csvHeader={t('labelVIN')}
					hidden={!vinNumber}
				>
					{t('labelVIN')}
				</TableHeaderColumn>
				<TableHeaderColumn
					width="200px"
					dataField="tag_plate"
					csvHeader={t('labelLicensePlateNumber')}
					hidden={!plateNumber}
				>
					{t('labelLicensePlateNumber')}
				</TableHeaderColumn>
				<TableHeaderColumn
					width="200px"
					dataField="serial_number"
					csvHeader={t('labelIMEI')}
					hidden={!imei}
				>
					{t('labelIMEI')}
				</TableHeaderColumn>
				<TableHeaderColumn
					width="200px"
					dataFormat={auditLogFormatter}
					hidden={!['system_admin', 'customer_service'].includes(userRole) || !auditLog}
				>
					{t('labelAuditLog')}
				</TableHeaderColumn>
				<TableHeaderColumn
					width="200px"
					dataField="no_sd_card"
					hidden={!no_sd_card}
					dataSort
				>
					{t('labelSDCardRemoval')}
				</TableHeaderColumn>
			</BootstrapTable>

			{showModalDeviceAuditLog && (
				<ModalDeviceAuditLog
					device={activeDevice}
					user_role={userRole}
					onHide={() => setShowModalDeviceAuditLog(false)}
				/>
			)}

			{showModalChannelStatus && (
				<ModalChannelStatus
					device={activeDevice}
					channel={activeChannel}
					userRole={userRole}
					getCompanyDevices={getCompanyDevices}
					onHide={() => setShowModalChannelStatus(false)}
				/>
			)}

			{/* {showModalDataRestricted && (
				<ModalDelete
					title="Data Restricted"
					content={'This camera has exceeded its data usage allowance and in order to protect you from additional overage charges, the data has been temporarily restricted. Live streaming is disabled and all events reported from this camera will go into Quarantine. Custom video requests and requesting event video from <b>Quarantine</b> is still available, however this will incur further data overages. This restriction will be lifted on the first of the next month.<br/><br/>If you feel this was in error, please <a href="mailto:techsupport@roscovision.com">contact RoscoLive technical support.</a>'}
					closeModal={() => setShowModalDataRestricted(false)}
					deleteModal={() => setShowModalDataRestricted(false)}
					deleteBtnName="Ok"
					styleView="primary"
				/>
			)} */}
		</div>
	)
}

ManageAssetsContainer.propTypes = {
	user: PropTypes.objectOf(PropTypes.any).isRequired,
	company: PropTypes.objectOf(PropTypes.any).isRequired
};

export default connect(
	state => ({
		user: state.user.user,
		company: state.company.company
	}),
)(ManageAssetsContainer);
