import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import Toggle from 'react-toggle';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { applyFirmwareAction, firmwareCancel } from '../../action/FleetAdminActions';

import '../../component/dump/menuComponents/menuComponents.sass';
import { isDv6 } from '../../component/helpers/constants';
import { tableNoPaginationOptions } from '../../component/helpers/table';
import CustomButton from '../../component//CustomButton';

const FleetAdminFirmwareBSTable = (props) => {
	// console.log('PROPS FleetAdminFirmwareBSTable: ', props)
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'firmwaresPage.fleetAdminFirmwareBSTable' });
	const { data, company, toggleLoader, blacklistCompaniesId, firmwares } = props
	const { userRole } = data

	const [selectedFirmware, setSelectedFirmware] = useState({});
	const [selectedDeviceRestart, setSelectedDeviceRestart] = useState({});
	const [expand, setExpand] = useState('');

	useEffect(() => {
		setExpand(data.expand)
	}, []);

	useEffect(() => {
		setExpand(data.expand);

		return () => {
			setExpand('')
		}
	}, [data]);

	const onSelectFirmware = (event) => {
		const { target } = event;
		// const value = target.value;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const { name } = target;

		if (target.type === 'checkbox') {
			const data = selectedDeviceRestart;
			data[name] = value;
			setSelectedDeviceRestart(data);
		} else {
			const data = selectedFirmware;
			data[name] = value;
			setSelectedFirmware(data);
		}
	}

	const upgradeRowFirmware = (argRow) => {
		const selectedDeviceId = argRow.device_id
		const result = firmwareAndRestart(selectedDeviceId)
		if (isDv6(argRow)) {
			if (!result.selected_firmware.includes('dv6')) {
				toastr.error(t('selectDV6firmware'));
				return
			}
		} else if (result.selected_firmware.includes('dv6')) {
			toastr.error(t('selectDV4firmware'));
			return
		}

		const params = {
			version: result.selected_firmware,
			device_id: selectedDeviceId,
			restart: result.selected_restart,
		}

		applyFirmware(params)
	};

	const upgradeGroupFirmware = (group_name) => {
		const result = firmwareAndRestart(group_name)
		const selectedDeviceId = [];

		// if selected dv6 firmware then will update just dv6 devices,
		// else if selected dv4 firmware then will update just dv4 devices
		data.expand.forEach(device => {
			if (result.selected_firmware.includes('dv6') && isDv6(device)) {
				selectedDeviceId.push(device.device_id)
			} else if (!result.selected_firmware.includes('dv6') && !isDv6(device)) {
				selectedDeviceId.push(device.device_id)
			}
		});

		const params = {
			version: result.selected_firmware,
			device_id: selectedDeviceId.join(','),
			restart: result.selected_restart,
		}

		applyFirmware(params)
	};

	const applyFirmware = (data) => {
		const error = checkForm(data);
		if (!error) {
			toggleLoader(true);
			applyFirmwareAction(userRole, data)
				.then((res, err) => {
					const { response } = res.data;
					let result;

					Object.keys(response).forEach((key) => {
						result = expand.map((item) => {
							if (item.device_id === key) {
								item.firmware_status = response[key];
							}
							return item;
						})
						setExpand(result);
					})

					toggleLoader(false);
					// eslint-disable-next-line eqeqeq
					if (res.status === 200) {
						// if (typeof response === "object") {
						//     for (var key in response) {
						//         toastr.success('Success', key + " - " + response[key]);
						//     }
						// }
						// else toastr.success ('Success', response);
						toastr.success('Success', t('firmwareUpgradeSent'));
					}
					if (err) {
						console.log('!applyFirmware err', err);
					}
				})
				.catch((errorApply) => {
					toggleLoader(false);
					console.log('!applyFirmware error: ', errorApply.response, errorApply);
					let errDescription = i18n.t('errorMessage.errDescription');
					if (errorApply.response.data.response.error) {
						errDescription = errorApply.response.data.response.error
					}
					toastr.error(errDescription);
				});
		}
	};

	const cancelFirmware = (row) => {
		const selectedDeviceId = row.device_id;
		applyCancel(selectedDeviceId);
	};

	const firmwareAndRestart = (item) => {
		let selected_firmware = '';
		let selected_restart = false;
		let result;

		for (const i in selectedFirmware) {
			if (i === item) {
				selected_firmware = selectedFirmware[i];
			}
		}

		for (const i in selectedDeviceRestart) {
			if (i === item) {
				selected_restart = selectedDeviceRestart[i];
			}
		}

		result = { selected_firmware, selected_restart };
		return result;
	};

	const applyCancel = (deviceId) => {
		toggleLoader(true);
		firmwareCancel(userRole, deviceId)
			.then((res, err) => {
				const { response } = res.data;
				let result;

				for (const key in response) {
					result = expand.map((item) => {
						if (item.device_id === key) {
							item.firmware_status = response[key];
						}
						return item;
					})
					setExpand(result);
				}

				toggleLoader(false);
				if (res.status === 200) {
					toastr.success('Success', t('firmwareГpgradeСanceled'));
				}
				if (err) {
					console.log('!applyCancel err', err);
				}
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!applyCancel error', error);
				let errDescription = i18n.t('errorMessage.errDescription');
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription);
			});
	};

	const checkForm = (data) => {
		let error = false;

		if (!data.version) {
			error = true;
			toastr.error(t('selectFirmware'));
		}

		return error;
	};

	const versionFormat = (cell, row, formatExtraData) => {
		if (formatExtraData && !_.isEmpty(row.firmware_version)) {
			if (formatExtraData === 'pdc' && !row.firmware_version[formatExtraData]) {
				return row.firmware_version.mcu
			}
			return row.firmware_version[formatExtraData]
		}

		return ''
	};

	const { group_name } = data;
	const devices = expand;
	let readOnly = ['sales_demo', 'partner', 'partner_view', 'custom_partner'].includes(userRole)
	// FWSD-5431, display the Firmware Portal menu option only if the company exists in the Firmware Rollout blacklist.
	if (['partner', 'custom_partner', 'fleet_manager'].includes(userRole)) {
		readOnly = !blacklistCompaniesId.includes(company.company_id)
	}

	const firmwareFormat = (cell, row) => {
		const { device_id } = row;
		const option = [];
		if (firmwares) {
			firmwares.map((elem, index) => {
				if (elem.version !== 0) { // hides the No Firmware firmware version
					option[index] = (<option key={elem.version} value={elem.version}>{elem.version}</option>)
				}
			})

			return (
				<select
					placeholder="select"
					className="form-control"
					name={device_id}
					id={device_id}
					onChange={e => onSelectFirmware(e, device_id)}
					style={{ appearance: 'auto' }}
				>
					<option />
					{option}
				</select>
			);
		}

		return ''
	};

	const restartFormat = (cell, row) => {
		const { device_id } = row;
		return (
			<Toggle
				name={device_id}
				onChange={e => onSelectFirmware(e, device_id)}
			/>
		)
	};

	const firmwareForGroup = () => {
		const option = [];
		if (firmwares) {
			firmwares.map((elem, index) => {
				if (elem.id !== 0) { // hides the No Firmware firmware version
					option[index] = (<option key={elem.version} value={elem.version}>{elem.version}</option>)
				}
			});
			return (
				<select
					placeholder={t('placeholderSelectFirmware')}
					className="form-control"
					name={group_name}
					id={group_name}
					style={{ appearance: 'auto' }}
					onChange={e => onSelectFirmware(e)}
				>
					<option />
					{option}
				</select>
			)
		}
	};

	const firmwareUpgradeFormat = (cell, row, extra) => {
		const search = row.firmware_status.search(/pending/i);
		return (
			<div className="upgrade-column">
				<CustomButton
					disabled={search > -1 ? true : false}
					size="xs"
					variant="primary"
					prefix="btnUpg"
					onClick={() => upgradeRowFirmware(row)}
				>
					{t('btnUpgrade')}
				</CustomButton>
			</div>
		)
	};

	const firmwareStatus = (cell, row) => {
		const search = cell.search(/pending/i);
		return (
			<div className="upgrade-column">
				<CustomButton
					disabled={search > -1 ? false : true}
					size="xs"
					variant="delete"
					onClick={() => cancelFirmware(row)}
				>
					{t('btnCancel')}
				</CustomButton>
			</div>
		)
	};

	const statusFormat = (cell, row) => {
		if (cell) {
			if (cell.includes('firmware out of date')) return t('firmwareOutOfDate');
			if (cell.includes("firmware update pending to")) return cell.replace("firmware update pending to", t('firmwareUpdatePendingTo'));
			if (cell.includes("latest firmware")) return t('latestFirmware');
			if (cell.includes("test firmware")) return t('testFirmware');
		}

		return cell
	};

	if (!expand) {
		return (<p>?</p>)
	};

	return (
		<div className="firmware-expanded-wrapper">
			<div className="firmware-expanded-body">
				<BootstrapTable
					data={devices}
					{...tableNoPaginationOptions}
				>
					<TableHeaderColumn dataField="device_id" isKey hidden>
						{t('headerID')}
					</TableHeaderColumn>
					<TableHeaderColumn width="10%" dataField="vehicle_name" dataSort>
						{t('headerVehicle')}
					</TableHeaderColumn>
					<TableHeaderColumn width="10%" formatExtraData="firmware" dataFormat={versionFormat}>
						{t('headerCurrentFirmwareVersion')}
					</TableHeaderColumn>
					<TableHeaderColumn width="10%" formatExtraData="pdc" dataFormat={versionFormat}>
						{t('headerCurrentPDCVersion')}
					</TableHeaderColumn>
					<TableHeaderColumn width="10%" formatExtraData="uboot" dataFormat={versionFormat}>
						{t('headerCurrentBootloaderVersion')}
					</TableHeaderColumn>
					{!readOnly
						&& <TableHeaderColumn width="15%" dataField="name" dataFormat={firmwareFormat}>
							{t('headerFirmwareSelection')}
						</TableHeaderColumn>}
					{!readOnly
						&& <TableHeaderColumn width="10%" dataField="name" dataFormat={restartFormat}>
							{t('headerReboot')}
						</TableHeaderColumn>}
					{!readOnly
						&& <TableHeaderColumn width="15%" dataField="name" dataFormat={firmwareUpgradeFormat}>
							{t('headerUpgrade')}
						</TableHeaderColumn>}
					{!readOnly
						&& <TableHeaderColumn width="15%" dataField="firmware_status" dataFormat={firmwareStatus}>
							{t('headerCancelUpgrade')}
						</TableHeaderColumn>}
					<TableHeaderColumn width="25%" dataField="firmware_status" dataSort dataFormat={statusFormat} tdStyle={{ whiteSpace: 'normal' }}>
						{t('headerStatus')}
					</TableHeaderColumn>
				</BootstrapTable>

				{!readOnly
					&& (
						<div className="col-md-12 firmware-group-table" style={{ paddingTop: '15px', paddingBottom: '10px' }}>
							<table>
								<tbody>
									<tr>
										<td className="name">{`${t('groupName')} "${group_name}"`}</td>
										<td className="firmware">{firmwareForGroup()}</td>
										<td className="reboot">
											<Toggle
												name={group_name}
												onChange={e => onSelectFirmware(e)}
											/>
										</td>
										<td className="button-upgr">
											<CustomButton variant="secondary" onClick={() => upgradeGroupFirmware(group_name)}>
												{t('btnUpgradeAll')}
											</CustomButton>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					)}
			</div>
		</div>
	)
}

export default connect(
	(state) => ({
		company: state.company.company,
	}),
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(FleetAdminFirmwareBSTable);
