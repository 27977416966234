import React, { Component, useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import { renderCustomHeader, tableAdjustableColOptions } from '../../component/helpers/table'

import { upgradeModemFirmware, upgradeModemFirmwareCancel } from '../../action/FleetAdminActions'

import '../../component/dump/menuComponents/menuComponents.sass'
import CustomButton from '../../component/CustomButton'

const FleetAdminModemContainerBSTable = (props) => {
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'modemsPage.fleetAdminModemContainerBSTable' });
	const { user_role, data, toggleLoader } = props;
	const [expandDevices, setExpandDevices] = useState([]);

	useEffect(() => {
		// console.log('!componentDidMount FleetAdminModemContainerBSTable', this.props.data.expand);

		const expandDevices = data.expand.map((device) => {
			let modem = {}
			if (!_.isEmpty(device.modem)) {
				modem = device.modem
				device.modem_type = 'modem'
			} else if (!_.isEmpty(device.router)) {
				modem = device.router
				device.modem_type = 'router'
			}

			for (var key in modem) {
				device[key] = modem[key]
			}

			return device;
		})

		setExpandDevices(expandDevices);
	}, []);

	useEffect(() => {
		const expandDevices = data.expand.map((device) => {
			let modem = {}
			if (!_.isEmpty(device.modem)) {
				modem = device.modem
				device.modem_type = 'modem'
			} else if (!_.isEmpty(device.router)) {
				modem = device.router
				device.modem_type = 'router'
			}

			for (var key in modem) {
				device[key] = modem[key]
			}

			return device;
		})

		setExpandDevices(expandDevices);

		return () => {
			setExpandDevices([]);
		}
	}, [data]);

	const activeFormatter = cell => (
		<div style={{ textAlign: 'center' }}>
			{cell ? <p className="active-dots" /> : <p className="non-active-dots" />}
		</div>
	);

	const vehicleFormatter = (cell, row) => row.vehicle_name || row.device_id;

	const upgradeFirmwareCancel = (deviceId) => {
		toggleLoader(true);
		upgradeModemFirmwareCancel(deviceId)
			.then((res, err) => {
				console.log('!upgradeModemFirmwareCancel res: ', res.data.response, err)
				const { response } = res.data
				let expand

				for (let key in response) {
					expand = expandDevices.map((item) => {
						if (item.device_id == key) {
							item.modem.firmware_status = response[key]
							item.firmware_status = response[key]
						}
						return item
					})
				}
				setExpandDevices(expand);

				toggleLoader(false);
				toastr.success('Success', t('modemFirmwareUpgradeCanceled'))
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!upgradeModemFirmwareCancel error', error)
				let errDescription = i18n.t('errorMessage.errDescription');
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription);
			})
	};

	const applyFirmware = (deviceId) => {
		toggleLoader(true);

		upgradeModemFirmware(deviceId)
			.then((res, err) => {
				console.log('!upgradeModemFirmware res: ', res.data.response, err)
				const { response } = res.data
				let expand

				for (let key in response) {
					expand = expandDevices.map((item) => {
						if (item.device_id == key) {
							item.modem.firmware_status = response[key];
							item.firmware_status = response[key];
						}

						return item
					})
				}
				setExpandDevices(expand);

				toggleLoader(false);
				if (res.status === 200) {
					toastr.success('Success',);
				}
				if (err) {
					console.log('!applyFirmware err', err);
				}
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!upgradeModemFirmware error', error.response)
				let errDescription = i18n.t('errorMessage.errDescription');
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription);
			})
	};

	const cancelFirmware = (row) => {
		upgradeFirmwareCancel(row.device_id)
	};

	const upgradeFirmware = (row) => {
		applyFirmware(row.device_id)
	};

	const upgradeFormatter = (cell, row, extra) => {
		let search = -1
		if (cell) {
			search = cell.search(/pending/i)
		}
		const getAction = () => {
			if (row.modem_type) {
				if (row.modem_type === 'router') {
					return (
						<a href="javascript:void(0)"
							onClick={() => props.setConfigParams({ routerManagementShow: true, activeDevice: row })}
							className="remote-management"
						>
							<img src="/images/remote_management.png" />
							{t('remoteManagement')}
						</a>
					)
				}
				if (row.modem_type === 'modem' && search > -1) {
					return (
						<div className="upgrade-column">
							<CustomButton
								size="xs"
								variant="delete"
								onClick={() => cancelFirmware(row)}
							>
								{t('btnCancel')}
							</CustomButton>
						</div>
					)
				}
				if (row.modem_type === 'modem' && search === -1) {
					return (
						<div className="upgrade-column">
							<CustomButton
								size="xs"
								variant="primary"
								onClick={() => upgradeFirmware(row)}
							>
								{t('btnUpgrade')}
							</CustomButton>
						</div>
					)
				}
			}
			return '';
		}

		return (
			<div style={{ textAlign: 'center' }}>
				{getAction()}
			</div>
		)
	};

	const statusFormat = (cell, row) => {
		if (cell) {
			if (cell.includes('firmware out of date')) return t('firmwareOutOfDate');
			if (cell.includes("firmware update pending to")) return cell.replace("firmware update pending to", t('firmwareUpdatePendingTo'));
			if (cell.includes("latest firmware")) return t('latestFirmware');
			if (cell.includes("test firmware")) return t('testFirmware');
		}

		return cell
	};

	if (data) {
		return (
			<div className="firmware-expanded-wrapper">
				<BootstrapTable
					data={expandDevices}
					{...tableAdjustableColOptions}
				>
					{/* //we can not use hidden column and renderCustomHeader function in the same time
							<TableHeaderColumn dataField="device_id" isKey hidden>ID</TableHeaderColumn>
						*/}

					<TableHeaderColumn
						isKey
						width="15%"
						dataField="vehicle_name"
						dataSort
						dataFormat={vehicleFormatter}
					>
						{renderCustomHeader(0, t('headerVehicleName'))}
					</TableHeaderColumn>

					<TableHeaderColumn
						width="5%"
						dataField="active"
						dataSort
						dataFormat={activeFormatter}
						editable={false}
					>
						{renderCustomHeader(1, t('headerEnable'))}
					</TableHeaderColumn>

					<TableHeaderColumn width="10%"
						dataField="firmware"
						dataSort
					>
						{renderCustomHeader(2, t('headerCurrentFirmware'))}
					</TableHeaderColumn>

					{/* <TableHeaderColumn
							width="15%"
							dataField='imei'
							formatExtraData="modem.imei"
							dataFormat={ trafficFormatter.bind(this)}
							dataSort={true}
						>
							{renderCustomHeader(3, 'IMEI')}
						</TableHeaderColumn>     */}
					{/* <TableHeaderColumn
							width="15%"
							dataField="imei"
							formatExtraData="imei"
							dataSort={true}
							dataFormat={ modemFormatter }
						>
							{renderCustomHeader(3, 'IMEI')}
						</TableHeaderColumn> */}
					<TableHeaderColumn
						width="15%"
						dataField="imei"
						dataSort
					>
						{renderCustomHeader(3, t('headerIMEI'))}
					</TableHeaderColumn>

					<TableHeaderColumn width="10%"
						dataField="manufacturer"
						dataSort>
						{renderCustomHeader(4, t('headerManufacturer'))}
					</TableHeaderColumn>

					<TableHeaderColumn width="10%"
						dataField="model"
						dataSort>
						{renderCustomHeader(5, t('headerModel'))}
					</TableHeaderColumn>

					<TableHeaderColumn width="10%"
						dataField="version"
						dataSort>
						{renderCustomHeader(6, t('headerVersion'))}
					</TableHeaderColumn>

					<TableHeaderColumn
						width="10%"
						dataField="firmware_status"
						dataSort={false}
						dataFormat={upgradeFormatter}
					>
						{renderCustomHeader(7, t('headerActions'))}
					</TableHeaderColumn>

					<TableHeaderColumn width="15%"
						dataField="firmware_status"
						dataFormat={statusFormat}
						dataSort>
						{t('headerStatus')}
					</TableHeaderColumn>
				</BootstrapTable>
			</div>
		)
	}

	return (<p>?</p>)
}

export default connect(
	null,
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(FleetAdminModemContainerBSTable);
