import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { Dropdown } from 'react-bootstrap'
import { Link, browserHistory } from 'react-router'
import moment from 'moment'
import classnames from 'classnames'
import _ from 'lodash'
import { connect } from 'react-redux'
import { CSVLink } from 'react-csv'
import { useTranslation } from 'react-i18next'

import { renderCustomHeader, paginationExportOptions, tableAdjustableColExportOptions } from '../../helpers/table'
import { isInternalUser } from '../../helpers/constants'
import { eventStatusFormatter, eventTypeDetection } from '../../helpers/helperEvents'
import { formatTimestamp } from '../../helpers/tablesFuncHelpers'
import momenttz from '../../helpers/momenttz'
import { generalIcons, exportIcon } from '../../helpers/iconHelpers'
import CustomButton from '../../CustomButton'
import { sendGA } from '../../helpers/helperUsers'

const EventClipsTable = (props) => {
	// console.log('PROPS EventClipsTable: ', props)
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'eventsPage.eventClipsContainer.eventClipsTable' });

	const COLUMNS_WIDTH = {
		0: {
			'Date / Time': '12%', Type: '12%', Status: '12%', Vehicle: '15%', Groups: '14%', Note: '7%', Reviewed: '10%', ID: '8%', Duration: '8%', RequestedAt: '12%',
		},
		1: {
			'Date / Time': '12%', Type: '12%', Status: '12%', Vehicle: '12%', Groups: '10%', Note: '6%', Reviewed: '12%', ID: '7%', Duration: '7%', RequestedAt: '12%', Driver: '8%',
		},
	};

	const CSV_HEADERS = [
		{ label: t('labelTime'), key: "timestamp" },
		{ label: t('labelEventType'), key: "type" },
		{ label: t('labelStatus'), key: "uploading_status" },
		{ label: t('labelLabel'), key: "event_label" },
		{ label: t('labelVehicleName'), key: "vehicle_name" },
		{ label: t('labelGroups'), key: "groups" },
		{ label: t('labelReviewedBy'), key: "last_viewed" },
		{ label: t('labelID'), key: "id" },
		{ label: t('labelDuration'), key: "duration" },
		{ label: t('labelRequestedAt'), key: "createtime" },
		{ label: t('labelDriverName'), key: "driver_name" },
		{ label: t('labelNote'), key: "custom_event_type" },
	];

	const { user, company, events, /*allEvents,*/ isWSCCompany, selectedRow, totalSize, eventDate, setDashboardRedirect, openEventNote, onFlagEvent, openEventVideoAll, downloadNVR, openShareEvent, openEventSnapshot, openModalDelete, downloadFlood, handlePageChange, handleSizePerPageChange, handleSortChange, page, sizePerPage, sortName, sortOrder, editVehicle, restoreWCSEvent, webfleetDeviceId, getAllFleetEvents, setEventArchiveRestoring, getFleetEvents } = props
	const userRole = user.roles[0]
	const userPermissions = user.permissions
	const options = {
		...paginationExportOptions,
		onPageChange: handlePageChange,
		onSizePerPageList: handleSizePerPageChange,
		page,
		sizePerPage,
		sortName,
		sortOrder,
		onSortChange: handleSortChange,
	}
	const driverFeatureAccess = (company.driver_recognition !== 'manual' || ['system_admin'].indexOf(userRole) > -1)
	const reviewedByFeatureAccess = (['system_admin', 'customer_service', 'partner', 'fleet_manager', 'fleet_maintainer', 'partner_view', 'group_manager'].indexOf(userRole) > -1)
	const tableColumnsWidth = COLUMNS_WIDTH[driverFeatureAccess * 1]

	// Export button
	const refExportBtn = useRef()
	const [csvData, setCsvData] = useState([]);
	const [columns, setColumns] = useState(JSON.parse(localStorage.getItem('eventsColumns')) || {
		label: false,
		status: true,
		vehicle: true,
		groups: true,
		reviewed_by: false,
		id: false,
		duration: true,
		requested_at: false,
		driver: false,
		trip: true,
	});

	useEffect(() => {
		if (csvData.length > 0) {
			refExportBtn.current.link.click()
		}
	}, [csvData])

	const timestampFormatter = (cell) => {
		if (cell) {
			return momenttz(cell).format('M/DD/YYYY hh:mm:ss A');
		}
		return t('textNotReviewed');
	}

	const csvTimestampFormatter = (cell) => {
		if (cell) {
			// return momenttz(cell).format('hh:mm:ss A')
			return momenttz(cell).format('M/DD/YYYY hh:mm:ss A')
		}
		return t('textNotReviewed');
	}

	const durationFormatter = (cell, row) => {
		// FWSD-4819
		if (eventStatusFormatter(row.uploading_status) !== 'Uploaded') {
			return '0'
		}

		let clipDuration = row.duration
		// FWSD-4558
		if (row.accurate_duration) {
			clipDuration = row.accurate_duration
		}

		return moment.duration(clipDuration, 'seconds').format('h [hrs] m [min] s [sec]', { trim: 'both' })
	}

	const driverFormatter = (cell, row) => row.driver_name || row.geotab_driver

	const vehicleFormatter = (cell, row) => {
		return isInternalUser(userRole)
			? <CustomButton variant="link" onClick={() => editVehicle(row.device_id)}>{cell || row.device_id}</CustomButton>
			: (cell || row.device_id);
	}

	const vehicleCSVFormatter = (cell, row) => row.vehicle_name || row.device_id

	const videostatusFormatter = (cell, row) => {
		if (row.size) {
			return `${(row.size).toFixed(2)} MB `
		}
		if (row.uploading_status && row.uploading_status.toLowerCase() === 'event_reported') {
			return t('textPending');
		}
		if (!row.uploading_status || row.uploading_status.toLowerCase() === 'video_non_exist') {
			return t('textUnavailable');
		}

		const re = /_/gi;
		return row.uploading_status.replace(re, ' ')
	}

	const typeFormatter = (cell, row) => {
		return i18n.t(`constants.eventNames.${eventTypeDetection(row).replace(/\s/g, "_")}`, eventTypeDetection(row));
	}

	const reviewedByFormatter = (cell, row) => {
		const res = []
		if (row.last_viewed.viewer.first_name) {
			res.push(row.last_viewed.viewer.first_name)
		}
		if (row.last_viewed.viewer.last_name) {
			res.push(row.last_viewed.viewer.last_name)
		}
		if (res.length > 0 && row.last_viewed.viewed_ts) {
			res.push('on')
		}
		if (row.last_viewed.viewed_ts) {
			res.push(momenttz(row.last_viewed.viewed_ts).format('M-D-YYYY'))
		}

		if (!res.length) {
			return t('textNotReviewed');
		}

		return res.join(' ')
	}

	const driverNameSort = name => (a, b, order) => {
		const first = a.driver_name || a.geotab_driver || '';
		const second = b.driver_name || b.geotab_driver || '';

		return order === 'asc' ? second.localeCompare(first) : first.localeCompare(second)
	}

	const reviewedBySort = name => (a, b, order) => {
		const first = a.last_viewed.viewer.first_name || a.last_viewed.viewer.last_name || '';
		const second = b.last_viewed.viewer.first_name || b.last_viewed.viewer.last_name || '';

		return order === 'asc' ? second.localeCompare(first) : first.localeCompare(second)
	}

	const redirectToTripReplay = (eventData) => {
		setDashboardRedirect({
			type: 'redirectFromDailyEvents',
			device_id: eventData.device_id,
			timestamp: moment(eventData.timestamp).utc().format('YYYY-MM-DD HH:mm:ss'),
		})
		browserHistory.push({ pathname: '/' });
	}

	const tripFormatter = (cell, row) => {
		return <Link to="/reports" onClick={(e) => { e.preventDefault(); redirectToTripReplay(row); }}>{t('labelViewTrip')}</Link>
	}

	const actionsFormatter = (cell, row) => {
		let playButton, snapshotButton, downloadButton, deleteButton, shareButton, flaggedButton, noteButton, requestVideoButton, archivedButton
		if (!(['custom_user', 'custom_partner'].includes(userRole) && _.find(userPermissions, { 'perm_category_id': 2, 'a_edit': false }))) {
			flaggedButton = <CustomButton prefix={`imageButton flaggedButton ${row.flagged && "on"}`} id={row.id} title={`${row.flagged ? t('titleUnflag') : t('titleFlag')} ${t('titleEvent')}`} onClick={() => onFlagEvent(row, 'Grid')} />

			noteButton = (
				<CustomButton
					size="icon"
					variant="tertiary"
					prefix={classnames("note-button", { 'with-note': row.custom_event_type })}
					active={row.custom_event_type}
					onClick={() => openEventNote(row)}
				>
					{t('btnNote')}
				</CustomButton>
			)
		}

		if (row.uploading_status === 'VIDEO_UPLOADED') {
			playButton = <CustomButton prefix="imageButton playButton" title={t('btnPlayVideo')} onClick={() => openEventVideoAll(row, 'Table')} />
			downloadButton = <CustomButton prefix="imageButton downloadButton" title={t('btnDownloadVideo')} onClick={() => downloadNVR(row.id)} />
			shareButton = <CustomButton prefix="imageButton shareButton" title={t('btnShareEvent')} onClick={() => openShareEvent(row)} />
		} else if (row.snapshot_status !== 'SNAPSHOT_UPLOADED') {
			playButton = <CustomButton prefix="imageButton playButton" title={t('btnPlayVideo')} disabled="disabled" />
			downloadButton = <CustomButton prefix="imageButton downloadButton" title={t('btnDownloadVideo')} disabled="disabled" />
		}

		if (row.snapshot_status === 'SNAPSHOT_UPLOADED') {
			snapshotButton = <CustomButton prefix="imageButton snapshotButton" title={t('btnViewSnapshot')} onClick={() => openEventSnapshot(row)} />
			if (!shareButton) {
				shareButton = <CustomButton prefix="imageButton shareButton" title={t('btnShareEvent')} onClick={() => openShareEvent(row)} />
			}
		} else {
			snapshotButton = <CustomButton prefix="imageButton snapshotButton" title={t('btnViewSnapshot')} disabled="disabled" />
		}

		if (['system_admin', 'customer_service', 'partner', 'fleet_manager',].indexOf(userRole) > -1 || (['custom_user', 'custom_partner'].includes(userRole) && _.find(userPermissions, { 'perm_category_id': 2, 'a_delete': true }))) {
			deleteButton = <CustomButton id="deleteButton" prefix="imageButton deleteButton" title={t('btnDeleteEvent')} onClick={() => openModalDelete(row.id)}>
				{generalIcons.crossDelete}
			</CustomButton>
		} else if (row.uploading_status === 'VIDEO_UPLOADED' && row.type.toLowerCase() === 'flood') {
			downloadButton = <CustomButton prefix="imageButton downloadButton" title={t('btnDownloadVideo')} onClick={() => downloadFlood(row)} />
		}

		if (['custom_user', 'custom_partner'].includes(userRole)) {
			if (!_.find(userPermissions, { perm_category_id: 9, a_view: true })) {
				downloadButton = null
			}
		} else if (userRole === 'sales_demo') {
			downloadButton = downloadButton ? <CustomButton prefix="imageButton downloadButton disabled" title={t('btnDownloadVideo')} disabled="disabled" /> : ''
			shareButton = shareButton ? <CustomButton prefix="imageButton shareButton disabled" title={t('btnShareEvent')} disabled="disabled" /> : ''
		}

		if (row.archive === 'archived') {
			archivedButton = <CustomButton prefix={`imageButton archivedButton`} title={t('btnRestoreEvent')} onClick={() => setEventArchiveRestoring({ showModal: true, message_type: 'event_restore', event_id: row.id, callback: getFleetEvents })} />
		}
		else if (row.archive === 'restoring') {
			archivedButton = <CustomButton prefix={`imageButton archivedButton rosco-tertiary active`} title={t('btnEventRestoring')} onClick={() => setEventArchiveRestoring({ showModal: true, message_type: 'event_restoring', event_id: row.id, request_ts: row.request_ts })} />
		}

		return (
			<div className="event-actions">
				{archivedButton}
				{!archivedButton && playButton}
				{!archivedButton && snapshotButton}
				{shareButton}
				{!archivedButton && downloadButton}
				{requestVideoButton}
				{flaggedButton}
				{noteButton}
				{deleteButton}
			</div>
		)
	}

	const actionsFormatterWCS = (cell, row) => {
		let buttons, playButton, downloadButton, deleteButton, shareButton;

		let noteButton = (
			<CustomButton size="icon" variant="tertiary" prefix={classnames("note-button", "btn", "btn-default", { 'with-note': row.custom_event_type })} active={row.custom_event_type} onClick={() => openEventNote(row)}>
				{t('btnNote')}
			</CustomButton>
		)

		if (row.type.toLowerCase() === 'custom clip' && row.uploading_status === 'EVENT_REPORTED') {
			buttons = (
				<div>
					<CustomButton id="deleteButton" prefix="imageButton deleteButton" title={t('btnDeleteCustomRequest')} onClick={() => openModalDelete(row.id)}>
						{generalIcons.crossDelete}
					</CustomButton>
				</div>
			);

			if (['custom_user', 'custom_partner'].includes(userRole) && !_.find(userPermissions, { perm_category_id: 2, a_delete: true })) {
				buttons = null
			}
		}
		else {
			switch (row.status) {
				case 'archived':
					buttons = (
						<CustomButton prefix="imageButton requestButton" title={t('btnRequest')} onClick={() => restoreWCSEvent(row)} />
					);
					break;
				case 'restoring':
					buttons = (
						<div>
							<p>{t('textRestoringProgress')}</p>
						</div>
					);
					break;
				default:
					if (row.uploading_status === 'VIDEO_UPLOADED') {
						playButton = <CustomButton prefix="imageButton playButton" title={t('btnPlayVideo')} onClick={() => openEventVideoAll(row)} />
						downloadButton = <CustomButton prefix="imageButton downloadButton" title={t('btnDownloadVideo')} onClick={() => downloadNVR(row.id)} />
						shareButton = <CustomButton prefix="imageButton shareButton" title={t('btnShareEvent')} onClick={() => openShareEvent(row)} />
					}
					else {
						playButton = <CustomButton prefix="imageButton playButton" title={t('btnPlayVideo')} disabled="disabled" />
						downloadButton = <CustomButton prefix="imageButton downloadButton" title={t('btnDownloadVideo')} disabled="disabled" />
					}

					if (['system_admin', 'customer_service', 'partner', 'fleet_manager'].indexOf(userRole) > -1) {
						deleteButton = <CustomButton id="deleteButton" prefix="imageButton deleteButton" title={t('btnDeleteCustomRequest')} onClick={() => openModalDelete(row.id)}>
							{generalIcons.crossDelete}
						</CustomButton>
					}
					else if (row.uploading_status === 'VIDEO_UPLOADED' && row.type.toLowerCase() === 'flood') {
						downloadButton = <CustomButton prefix="imageButton downloadButton" title={t('btnDownloadVideo')} onClick={() => downloadFlood(row)} />
					}

					if (['custom_user', 'custom_partner'].includes(userRole)) {
						if (!_.find(userPermissions, { perm_category_id: 9, a_view: true })) {
							downloadButton = null
						}
					}

					buttons = (
						<div className="event-actions">
							{playButton}
							{downloadButton}
							{shareButton}
							{noteButton}
							{deleteButton}
						</div>
					);
					break;
			}
		}

		return buttons;
	}

	const onExportClick = async () => {
		// debugger
		const events = await getAllFleetEvents()
		const newCsvData = []
		events.forEach((item) => {
			const obj = {
				id: item.id,
				device_id: item.device_id,
				vehicle_name: item.vehicle_name || item.device_id,
				timestamp: csvTimestampFormatter(item.timestamp),
				type: i18n.t(`constants.eventNames.${eventTypeDetection(item).replace(/\s/g, "_")}`, eventTypeDetection(item)),
				uploading_status: eventStatusFormatter(item.uploading_status),
				groups: item.groups,
				last_viewed: reviewedByFormatter(item.last_viewed, item),
				duration: durationFormatter(item.duration, item),
				createtime: csvTimestampFormatter(item.createtime),
				driver_name: driverFormatter(item.driver_name, item),
				custom_event_type: item.custom_event_type || '',
			}

			newCsvData.push(obj)
		})

		setCsvData(newCsvData)
	}

	const toggleCheckbox = ({ target }) => {
		const { name, checked } = target;
		sendGA({
			category: userRole,
			action: 'Select Column',
			label: (checked ? 'Add ' : 'Remove ') + name
		})
		const updatedColumns = { ...columns, ...{ [name]: checked } }
		setColumns(updatedColumns);
		localStorage.setItem('eventsColumns', JSON.stringify(updatedColumns))
	}

	const { label, status, vehicle, groups, reviewed_by, id, duration, requested_at, driver, trip } = columns;

	return (
		<div className="event-page-table">
			{!isWSCCompany
				? (
					<div className="blockWithDropdown">
						<Dropdown
							id="events-select-dropdown"
							className="column-select-dropdown"
							role="menuitem"
							drop
							align="start"
						>
							<Dropdown.Toggle bsPrefix="dropdown-toggle custom-button rosco-primary inverted small">
								{t('titleColumns')}
							</Dropdown.Toggle>
							<Dropdown.Menu className="vehicle-select-menu">
								<div className="vehicle-checkbox-group">
									<h5 className="category-title">{t('titleColumns')}</h5>
									<div style={{ display: "flex" }}>
										<div className="col-sm-6">
											<div>
												<input
													type="checkbox"
													className="vehicle-checkbox"
													checked={label}
													name="label"
													onChange={toggleCheckbox}
												/>
												{t('labelLabel')}
											</div>
											{isInternalUser(userRole) && (<div>
												<input
													type="checkbox"
													className="vehicle-checkbox"
													checked={status}
													name="status"
													onChange={toggleCheckbox}
												/>
												{t('labelStatus')}
											</div>)}
											<div>
												<input
													type="checkbox"
													className="vehicle-checkbox"
													checked={vehicle}
													name="vehicle"
													onChange={toggleCheckbox}
												/>
												{t('labelVehicle')}
											</div>
											<div>
												<input
													type="checkbox"
													className="vehicle-checkbox"
													checked={groups}
													name="groups"
													onChange={toggleCheckbox}
												/>
												{t('labelGroups')}
											</div>
											<div>
												<input
													type="checkbox"
													className="vehicle-checkbox"
													checked={reviewed_by}
													name="reviewed_by"
													onChange={toggleCheckbox}
												/>
												{t('labelStatus')}
											</div>
										</div>
										<div className="col-sm-6">
											<div>
												<input
													type="checkbox"
													className="vehicle-checkbox"
													checked={id}
													name="id"
													onChange={toggleCheckbox}
												/>
												{t('labelID')}
											</div>
											<div>
												<input
													type="checkbox"
													className="vehicle-checkbox"
													checked={duration}
													name="duration"
													onChange={toggleCheckbox}
												/>
												{t('labelDuration')}
											</div>
											<div>
												<input
													type="checkbox"
													className="vehicle-checkbox"
													checked={requested_at}
													name="requested_at"
													onChange={toggleCheckbox}
												/>
												{t('labelRequestedAt')}
											</div>
											<div>
												<input
													type="checkbox"
													className="vehicle-checkbox"
													checked={driver}
													name="driver"
													onChange={toggleCheckbox}
												/>
												{t('labelDriver')}
											</div>
											<div>
												<input
													type="checkbox"
													className="vehicle-checkbox"
													checked={trip}
													name="trip"
													onChange={toggleCheckbox}
												/>
												{t('labelTrip')}
											</div>
										</div>
									</div>
								</div>
							</Dropdown.Menu>
						</Dropdown>

						{/* Export button */}
						<div className="react-bs-table-tool-bar btn-group">
							<CustomButton onClick={onExportClick} variant='primary-outline' size='sm' prefix='export-button'>
								{exportIcon}
								<span className='export-label'>{t('titleExport')}</span>
							</CustomButton>
						</div>
						<CSVLink
							ref={refExportBtn}
							filename={`${t('textDailyEvents') + moment(eventDate).format('M-D-YY')}.csv`}
							headers={CSV_HEADERS}
							data={csvData}
						/>

						<BootstrapTable
							data={events}
							{...tableAdjustableColExportOptions}
							remote
							fetchInfo={{ dataTotalSize: totalSize }}
							selectRow={selectedRow}
							// options={{ ...options, onExportToCSV: () => allEvents }}
							// options={{ ...options, onExportToCSV:  async () => await handleExportToCSV().then(res => res.data.response.events) }}
							options={{ ...options }}
							// csvFileName={`daily_events_${moment(eventDate).format('M-D-YY')}.csv`}
							exportCSV={false}
						>
							<TableHeaderColumn
								width={tableColumnsWidth['Date / Time']}
								dataField="timestamp"
								dataSort
								dataFormat={timestampFormatter}
								csvFormat={csvTimestampFormatter}
								csvHeader={moment(eventDate).format('MM/DD/YYYY')}
								tdStyle={{ whiteSpace: 'normal' }}
							>
								{renderCustomHeader(0, t('labelDate/Time'))}
							</TableHeaderColumn>
							<TableHeaderColumn
								width={tableColumnsWidth.Type}
								dataField="type"
								dataSort
								dataFormat={typeFormatter}
								csvFormat={typeFormatter}
								csvHeader={t('labelEventType')}
								tdStyle={{ whiteSpace: 'normal' }}
							>
								{renderCustomHeader(1, t('labelType'))}
							</TableHeaderColumn>
							<TableHeaderColumn
								hidden={isInternalUser(userRole) ? !status : true}
								width={tableColumnsWidth.Status}
								dataField="uploading_status"
								dataSort
								dataFormat={(e) => e ? i18n.t(`homePage.vehicleSidebar.vehicleSidebarFilter.${eventStatusFormatter(e).replace(/\s/g, "_")}`) : ''}
								csvFormat={(e) => e ? i18n.t(`homePage.vehicleSidebar.vehicleSidebarFilter.${eventStatusFormatter(e).replace(/\s/g, "_")}`) : ''}
								csvHeader={t('labelStatus')}
								tdStyle={{ whiteSpace: 'normal' }}
							>
								{renderCustomHeader(2, t('labelStatus'))}
							</TableHeaderColumn>
							<TableHeaderColumn
								width="10%"
								dataField="event_label"
								dataSort
								csvHeader={t('labelLabel')}
								tdStyle={{ whiteSpace: 'normal' }}
								hidden={!label}
							>
								{renderCustomHeader(3, t('labelLabel'))}
							</TableHeaderColumn>
							<TableHeaderColumn
								width={tableColumnsWidth.Vehicle}
								dataField="vehicle_name"
								dataSort
								dataFormat={vehicleFormatter}
								csvFormat={vehicleCSVFormatter}
								csvHeader={t('labelVehicleName')}
								tdStyle={{ whiteSpace: 'normal' }}
								hidden={!vehicle}
							>
								{renderCustomHeader(2, t('labelVehicle'))}
							</TableHeaderColumn>
							<TableHeaderColumn
								width={tableColumnsWidth.Groups}
								dataField="groups"
								csvHeader={t('labelGroups')}
								dataSort
								tdStyle={{ whiteSpace: 'normal' }}
								hidden={!groups}
							>
								{renderCustomHeader(3, t('labelGroups'))}
							</TableHeaderColumn>
							<TableHeaderColumn
								hidden={reviewedByFeatureAccess ? !reviewed_by : true}
								width={tableColumnsWidth.Reviewed}
								dataField="last_viewed"
								dataSort
								dataFormat={reviewedByFormatter}
								csvHeader={t('labelReviewedBy')}
								csvFormat={reviewedByFormatter}
								tdStyle={{ whiteSpace: 'normal' }}
								sortFunc={reviewedBySort()}
							>
								{renderCustomHeader(4, t('labelReviewedBy'))}
							</TableHeaderColumn>
							<TableHeaderColumn
								width={tableColumnsWidth.ID}
								dataField="id"
								csvHeader={t('labelID')}
								dataSort
								isKey
								hidden={!id}
							>
								{renderCustomHeader(4, t('labelID'))}
							</TableHeaderColumn>
							<TableHeaderColumn
								width={tableColumnsWidth.Duration}
								dataField="duration"
								dataSort
								dataFormat={durationFormatter}
								csvFormat={durationFormatter}
								csvHeader={t('labelDuration')}
								hidden={!duration}
							>
								{renderCustomHeader(6, t('labelDuration'))}
							</TableHeaderColumn>
							<TableHeaderColumn
								width={tableColumnsWidth.RequestedAt}
								dataField="createtime"
								dataSort
								dataFormat={timestampFormatter}
								csvFormat={csvTimestampFormatter}
								csvHeader={t('labelRequestedAt')}
								hidden={!requested_at}
							>
								{renderCustomHeader(7, t('labelRequestedAt'))}
							</TableHeaderColumn>
							<TableHeaderColumn
								hidden={driverFeatureAccess ? !driver : true}
								width={tableColumnsWidth.Driver}
								dataField="driver_name"
								dataSort
								dataFormat={driverFormatter}
								csvHeader={t('labelDriverName')}
								tdStyle={{ whiteSpace: 'normal' }}
								sortFunc={driverNameSort()}
							>
								{renderCustomHeader(8, t('labelDriver'))}
							</TableHeaderColumn>
							<TableHeaderColumn
								hidden={!(webfleetDeviceId.length > 0 || (['custom_user', 'custom_partner'].includes(userRole) && _.find(userPermissions, { 'perm_category_id': 1, 'a_view': false }))) ? !trip : true}
								width="7%"
								dataFormat={tripFormatter}
								tdStyle={{ whiteSpace: 'normal' }}
								export={false}
								className="hidden"
							>
								{renderCustomHeader(9, t('labelTrip'))}
							</TableHeaderColumn>
							<TableHeaderColumn
								width="15%"
								dataFormat={actionsFormatter}
								dataField="custom_event_type"
								csvFormat={cell => cell || ''}
								csvHeader={t('labelNote')}
								tdStyle={{ whiteSpace: 'normal' }}
							>
								<div className="custom-table-header-row">
									<div className="custom-table-header-title">{t('titleActions')}</div>
								</div>
							</TableHeaderColumn>
						</BootstrapTable>
					</div>
				)
				: (
					<BootstrapTable
						data={events}
						{...tableAdjustableColExportOptions}
						remote
						fetchInfo={{ dataTotalSize: totalSize }}
						selectRow={selectedRow}
						options={options}
						csvFileName={`${t('textDailyEvents') + moment(eventDate).format('M-D-YY')}.csv`}
					>
						<TableHeaderColumn
							width="11%"
							dataField="timestamp"
							dataSort
							dataFormat={timestampFormatter}
							csvFormat={csvTimestampFormatter}
							csvHeader={moment(eventDate).format('MM/DD/YYYY')}
							tdStyle={{ whiteSpace: 'normal' }}
						>
							{renderCustomHeader(0, t('labelDate/Time'))}
						</TableHeaderColumn>
						<TableHeaderColumn
							width="10%"
							dataField="type"
							dataSort
							tdStyle={{ whiteSpace: 'normal' }}
							csvHeader={t('labelEventType')}
							csvFormat={typeFormatter}
							dataFormat={typeFormatter}
						>
							{renderCustomHeader(1, t('labelType'))}
						</TableHeaderColumn>
						<TableHeaderColumn
							width="9%"
							dataField="vehicle_name"
							dataSort
							dataFormat={vehicleFormatter}
							csvFormat={vehicleCSVFormatter}
							tdStyle={{ whiteSpace: 'normal' }}
						>
							{renderCustomHeader(2, t('labelVehicle'))}
						</TableHeaderColumn>
						<TableHeaderColumn
							width="6%"
							dataField="groups"
							dataSort
							csvHeader="Groups"
							tdStyle={{ whiteSpace: 'normal' }}
						>
							{renderCustomHeader(3, t('labelGroups'))}
						</TableHeaderColumn>
						<TableHeaderColumn
							width="8%"
							dataField="size"
							dataSort
							dataFormat={videostatusFormatter}
							csvHeader={t('labelFileSize')}
							csvFormat={videostatusFormatter}
							tdStyle={{ whiteSpace: 'normal' }}
						>
							{renderCustomHeader(4, t('labelFileSize'))}
						</TableHeaderColumn>
						<TableHeaderColumn
							width="11%"
							dataField="last_viewed"
							dataSort
							dataFormat={reviewedByFormatter}
							csvHeader={t('labelReviewedBy')}
							csvFormat={reviewedByFormatter}
							tdStyle={{ whiteSpace: 'normal' }}
							sortFunc={reviewedBySort()}
						>
							{renderCustomHeader(6, t('labelReviewedBy'))}
						</TableHeaderColumn>
						<TableHeaderColumn
							width="5%"
							dataField="id"
							dataSort
							csvHeader={t('labelID')}
							isKey
						>
							{renderCustomHeader(7, t('labelID'))}
						</TableHeaderColumn>
						<TableHeaderColumn
							width="7%"
							dataField="start_ts"
							dataSort
							dataFormat={formatTimestamp}
							csvHeader={t('labelStart')}
							csvFormat={formatTimestamp}
							tdStyle={{ whiteSpace: 'normal' }}
						>
							{renderCustomHeader(8, t('labelStart'))}
						</TableHeaderColumn>
						<TableHeaderColumn
							width="7%"
							dataField="end_ts"
							dataSort
							dataFormat={formatTimestamp}
							csvHeader={t('labelEnd')}
							csvFormat={formatTimestamp}
							tdStyle={{ whiteSpace: 'normal' }}
						>
							{renderCustomHeader(9, t('labelEnd'))}
						</TableHeaderColumn>
						<TableHeaderColumn
							width="11%"
							dataField="custom_event_type"
							dataFormat={actionsFormatterWCS}
							tdStyle={{ whiteSpace: 'normal' }}
						>
							<div className="custom-table-header-row">
								<div className="custom-table-header-title">{t('titleActions')}</div>
							</div>
						</TableHeaderColumn>
					</BootstrapTable>
				)}
		</div>
	)
}

EventClipsTable.propTypes = {
	user: PropTypes.objectOf(PropTypes.any).isRequired,
	company: PropTypes.objectOf(PropTypes.any).isRequired,
	events: PropTypes.arrayOf(PropTypes.any).isRequired,
	isWSCCompany: PropTypes.bool.isRequired,
	selectedRow: PropTypes.objectOf(PropTypes.any).isRequired,
	totalSize: PropTypes.number.isRequired,
	eventDate: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.instanceOf(moment),
	]),
	page: PropTypes.number.isRequired,
	sizePerPage: PropTypes.number.isRequired,
	sortName: PropTypes.string.isRequired,
	sortOrder: PropTypes.string.isRequired,
	setDashboardRedirect: PropTypes.func.isRequired,
	openEventNote: PropTypes.func.isRequired,
	onFlagEvent: PropTypes.func.isRequired,
	openEventVideoAll: PropTypes.func.isRequired,
	downloadNVR: PropTypes.func.isRequired,
	openShareEvent: PropTypes.func.isRequired,
	openEventSnapshot: PropTypes.func.isRequired,
	openModalDelete: PropTypes.func.isRequired,
	downloadFlood: PropTypes.func.isRequired,
	handlePageChange: PropTypes.func.isRequired,
	handleSizePerPageChange: PropTypes.func.isRequired,
	handleSortChange: PropTypes.func.isRequired,
	editVehicle: PropTypes.func.isRequired,
	restoreWCSEvent: PropTypes.func.isRequired,
	webfleetDeviceId: PropTypes.string,
}

EventClipsTable.defaultProps = {
	webfleetDeviceId: '',
}

const mapStateToProps = ({ user, company, webfleetData }) => ({
	user: user.user,
	company: company.company,
	webfleetDeviceId: webfleetData.deviceId,
});

const mapDispatchToProps = dispatch => ({
	setDashboardRedirect: (state) => {
		dispatch({ type: 'SET_DASHBOARD_REDIRECT', payload: state })
	},
	setEventArchiveRestoring: (state) => {
		dispatch({ type: 'SET_EVENT_ARCHIVE_RESTORING', payload: state });
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(EventClipsTable);
