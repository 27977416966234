import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { useTranslation } from 'react-i18next'

import ModalDelete from '../../component/smart/modals/ModalDelete'
import { apiCallGet, apiCall } from '../../action/RouterActions'
import CustomButton from '../../component/CustomButton'

const GlobalDataThreshold = (props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'companiesPage.globalDataThreshold' });
	const { toggleLoader } = props;

	const [defaultGlobalThreshold, setDefaultGlobalThreshold] = useState(999);
	const [globalThreshold, setGlobalThreshold] = useState(999);
	const [isEdit, setIsEdit] = useState(false);
	const [showModalDelete, setShowModalDelete] = useState(false);

	useEffect(() => {
		getGlobalDataThreshold();
	}, []);

	const getGlobalDataThreshold = () => {
		apiCallGet('/global_tags')
			.then((res) => {
				console.log('get global_tags res: ', res)
				const { tags } = res.data.response
				setGlobalThreshold(tags.data_threshold_cap);
				setDefaultGlobalThreshold(tags.data_threshold_cap)
			})
			.catch((error) => {
				console.log('!getGlobalDataThreshold error: ', error.response, error)
				if (error && error.response && error.response.data && error.response.data.response) {
					toastr.error(error.response.data.response.error)
				}
				else {
					toastr.error(t('unableGetGlobalDataThreshold'));
				}
			})
	}

	const checkForm = () => {
		let error = false

		if (!globalThreshold) {
			error = true
			toastr.error(t('enterGlobalDataThresholdValue'));
		}
		else if (globalThreshold < 100) {
			error = true
			toastr.error(t('enterValueAtLeast100'));
		}

		return error
	}

	const onSaveGlobalDataThreshold = () => {
		const error = checkForm()
		if (!error) {
			toggleLoader(true);
			apiCall('POST', '/global_tags', { tag_name: 'data_threshold_cap', tag_value: globalThreshold })
				.then((res) => {
					toggleLoader(false);
					setShowModalDelete(false);
					setIsEdit(false)
					toastr.success(t('savedGlobalDataThresholdCap'))
				})
				.catch((error) => {
					toggleLoader(false);
					console.log('!onSaveGlobalDataThreshold error: ', error.response, error)
					if (error && error.response && error.response.data && error.response.data.response) {
						toastr.error(error.response.data.response.error)
					}
					else {
						toastr.error(t('unableSaveGlobalDataThresholdCap'));
					}
				})
		}
	}

	const onInputChange = (e) => {
		const re = /^[0-9\b]+$/
		if (e.target.value === '' || re.test(e.target.value)) {
			setGlobalThreshold(e.target.value);
		}
	}

	return (
		<div>
			<h3 className="category-title" style={{ marginTop: '50px' }}>{t('titleGlobalDataThreshold')}</h3>
			<div className="category-subheader">
				{t('subheaderGlobalDataThreshold')}
			</div>
			<div>
				<label style={{ marginRight: '30px' }}>{t('labelGlobalDataThreshold')}</label>
				<input
					type="number"
					step="1"
					min="100"
					maxLength="4"
					value={(globalThreshold * 1).toFixed(0)}
					className="vehicle-search number-input mr-10"
					name="adas_vehicle_hood_len"
					onChange={onInputChange}
					readOnly={!isEdit}
				/>
				<span style={{ marginRight: '30px' }}>%</span>

				{isEdit
					? <span>
						<CustomButton
							variant="link"
							prefix="red"
							onClick={() => {
								setGlobalThreshold(defaultGlobalThreshold);
								setIsEdit(false)
							}}>
							{t('btnCancel')}
						</CustomButton>
						<CustomButton variant="link" onClick={() => setShowModalDelete(true)}>{t('btnSave')}</CustomButton>
					</span>
					: <CustomButton variant="link" onClick={() => setIsEdit(true)}>{t('btnEdit')}</CustomButton>
				}
			</div>

			{showModalDelete
				&& (
					<ModalDelete
						title={t('modalDeleteTitle')}
						content={t('modalDeleteContent')}
						styleView="primary"
						useLineBreaking
						showClose
						deleteBtnName={t('deleteBtnName')}
						closeModal={() => {
							setGlobalThreshold(defaultGlobalThreshold);
							setShowModalDelete(false);
							setIsEdit(false);
						}}
						// deleteModal={() => {
						// 	setShowModalDelete(false);
						// 	setIsEdit(false);
						// }}
						deleteModal={onSaveGlobalDataThreshold}
					/>
				)}
		</div>
	)
}

export default connect(
	null,
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		},
	}),
)(GlobalDataThreshold);
