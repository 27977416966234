import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import Toggle from 'react-toggle'
import moment from 'moment'
import { toastr } from 'react-redux-toastr';
import _ from 'lodash'
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SnapshotChannelNode from './SnapshotChannelNode'
import ModalRequestContextVideo from './ModalRequestContextVideo'
import CustomButton from '../../CustomButton'
import EventFeedback from '../../EventFeedback'
import { sendGA, isCustomUserPermissions } from '../../helpers/helperUsers'
import { getEventSnapshots, requestEventVideo } from '../../../action/AlertActions'

const EventViewSnapshotModal = (props) => {
	const { event, getFleetEvents, onHide, user, company, tripSnapshots, toggleLoader } = props;
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'homePage.vehicleSidebar.eventViewSnapshotModal' });
	const userRole = user.roles[0];
	const userPermissions = user.permissions;
	const canEventFeedbackView = ['system_admin', 'customer_service', 'sales_demo', 'partner'].includes(userRole) 
		|| (['custom_user', 'custom_partner'].includes(userRole) && isCustomUserPermissions(userRole, userPermissions, 'event_feedback_add'))
		|| (['custom_user', 'custom_partner'].includes(userRole) && isCustomUserPermissions(userRole, userPermissions, 'event_feedback_edit'))

	const [channel, setChannel] = useState(1)
	const [snapshots, setSnapshots] = useState([])
	const [channels, setChannels] = useState([])
	const [showRequestContextVideo, setShowRequestContextVideo] = useState(false)
	const [showCalibrationBox, setShowCalibrationBox] = useState(false)
	// const canEventFeedbackView = ['system_admin', 'customer_service', 'sales_demo', 'partner'].includes(userRole) 
	// 	|| isCustomUserPermissions(userRole, userPermissions, 'event_feedback_add')
	// 	|| isCustomUserPermissions(userRole, userPermissions, 'event_feedback_edit')

	useEffect(() => {
		let channels = [0, 0, 0, 0]
		let channel = 0

		const items = event ? event : tripSnapshots

		if (items.snapshot_4) {
			channels[3] = true;
			channel = 4;
		}
		if (items.snapshot_3) {
			channels[2] = true;
			channel = 3;
		}
		if (items.snapshot_2) {
			channels[1] = true;
			channel = 2;
		}
		if (items.snapshot_1) {
			channels[0] = true;
			channel = 1;
		}

		if (channel === 0) {
			channels = [];
		}

		if (event && items.snapshot_2 && ['External Driver', 'Driver', 'Drowsiness', 'Distraction', 'Phone Use', 'Custom Alert Distraction', 'Custom Alert Drowsiness', 'Custom Alert Phone Use', 'CameraBlock'].includes(items.type)) {
			channel = 2;
		}

		setChannel(channel);
		setChannels(channels);

		if (event) {
			viewSnapshot();
		} else if (tripSnapshots) {
			const snapshots = []
			if (tripSnapshots.snapshot_1) {
				snapshots.push(tripSnapshots.snapshot_1)
			}
			if (tripSnapshots.snapshot_2) {
				snapshots.push(tripSnapshots.snapshot_2)
			}
			if (tripSnapshots.snapshot_3) {
				snapshots.push(tripSnapshots.snapshot_3)
			}
			if (tripSnapshots.snapshot_4) {
				snapshots.push(tripSnapshots.snapshot_4)
			}
			setSnapshots(snapshots);

			const snapshot = document.getElementById('event-snapshot')
			snapshot.src = snapshots[0]

			// const snapshot = document.getElementById('event-snapshot');
			// if (event.snapshot_2 && (['External Driver', 'Driver', 'Drowsiness', 'Distraction', 'Phone Use', 'Custom Alert Distraction', 'Custom Alert Drowsiness', 'Custom Alert Phone Use'].indexOf(event.type) > -1)) {
			// 	snapshot.src = event.snapshot_2;
			// }
			// else {
			// 	snapshot.src = snapshots[0]
			// }
		}
	}, [])

	const changeChannel = (argChannel) => {
		if (argChannel === channel) return;

		setChannel(argChannel);

		const snapshot = document.getElementById('event-snapshot')

		const re = RegExp('\/' + argChannel + '.jpeg?', 'i')
		const re1 = RegExp('\_' + argChannel + '.jpeg?', 'i')
		snapshots.forEach((item) => {
			if (item.search(re) > -1 || item.search(re1) > -1) {
				snapshot.src = item
			}
		})

		// snapshot.src = snapshots[channel-1]
	}

	const viewSnapshot = () => {
		toggleLoader(true);

		const data = {
			user_role: user.roles[0],
			event_id: event.id,
			company_name: company.name,
			partner_name: company.partner,
		}
		getEventSnapshots(data)
			.then((res, err) => {
				toggleLoader(false);
				console.log('!getEventSnapshots res: ', res, err);

				if (res.data.response.error) {
					toastr.error(i18n.t('errorMessage.errDescription'));
				} else {
					setSnapshots(res.data.response.snapshots_urls);
					const snapshot = document.getElementById('event-snapshot');
					if (event.snapshot_2 && ['External Driver', 'Driver', 'Drowsiness', 'Distraction', 'Phone Use', 'Custom Alert Distraction', 'Custom Alert Drowsiness', 'Custom Alert Phone Use', 'CameraBlock'].includes(event.type)) {
						snapshot.src = event.snapshot_2;
					}
					else {
						snapshot.src = res.data.response.snapshots_urls[0];
					}
				}
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!getEventSnapshots error: ', error.response, error);
				let errDescription = i18n.t('errorMessage.errDescription');
				if (error.response?.data?.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}

	const openRequestContextVideo = () => {
		setShowRequestContextVideo(true);
		sendGA({
			category: userRole,
			action: 'Request Additional Video',
			label: 'Open Request Additional Video'
		})
	}

	const closeRequestContextVideo = () => {
		setShowRequestContextVideo(false);
	}

	// FWSD-4740, create custom clip for the trip snapshots from the Homepage
	const createCustomClip = (e) => {
		e.preventDefault()

		const params = {
			device_id: tripSnapshots.device_id,
			start_ts: moment(tripSnapshots.timestamp).add(-5, 'seconds').format('YYYYMMDDHHmmss'),
			end_ts: moment(tripSnapshots.timestamp).add(5, 'seconds').format('YYYYMMDDHHmmss'),
		}
		toggleLoader(true);
		requestEventVideo(params)
			.then((res, err) => {
				toggleLoader(false);
				console.log('requestCustomVideo res, err: ', res, err);

				toastr.success('', i18n.t('toastMessage.toastrSuccessCVR'));
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('requestCustomVideo error: ', error.response, error);
				let errDescription = i18n.t('errorMessage.errDescription');
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}

	const { roles, permissions } = user;

	const channelsList = () => {
		const options = []
		for (var i = 0; i < 4; i++) {
			if (channels[i]) {
				const index = i + 1
				options[i] = <SnapshotChannelNode
					key={index}
					index={index}
					activechannel={channel}
					changeChannel={changeChannel}
				/>
			}
		}

		return (
			<>
				<ul className="list-inline chanels-list" >
					{options}
				</ul>

				<div>
					{channel === 2 &&
						<label>
							<span className="toggle-left-title">{t('showDMSBox')}</span>
							<Toggle
								// name="active"
								icons={false}
								checked={showCalibrationBox || false}
								onChange={() => setShowCalibrationBox(!showCalibrationBox)}
							/>
						</label>
					}

					{!(['custom_user', 'custom_partner'].includes(roles[0]) && _.find(permissions, { 'perm_category_id': 2, 'a_edit': false })) && (
						<>
							{event
								? (
									<CustomButton
										variant="primary-outline"
										title={t('btnRequestAdditionalVideo')}
										style={{ margin: 0 }}
										disabled={event?.uploading_status === 'EVENT_REPORTED'}
										onClick={openRequestContextVideo}
									>
										{t('btnRequestAdditionalVideo')}
									</CustomButton>
								)
								: (
									<CustomButton
										variant="primary-outline"
										title={t('btnRequestVideo')}
										style={{ margin: 0 }}
										onClick={createCustomClip}
									>
										{t('btnRequestVideo')}
									</CustomButton>
								)
							}
						</>
					)}
				</div>
			</>
		)
	}

	// console.log('PROPS EventViewSnapshotModal props: ', props);

	return (
		<Modal
			size='lg'
			show
			onHide={onHide}
			// onEnter={onEnterModal}
			aria-labelledby="contained-modal-title-lg"
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-lg">
					<span style={{ marginRight: '15px' }}>{t('titleSnapshots')}</span>
					{canEventFeedbackView && 
					<EventFeedback
						event={event}
						getFleetEvents={getFleetEvents}
					/>
					}
				</Modal.Title>
			</Modal.Header>
			{/* <Modal.Body className='p-0'> */}
			<Modal.Body>
				<div>
					<img width="100%" id="event-snapshot" alt="" />
					{channel === 2 && showCalibrationBox && <div className="calibration-box" />}
				</div>
			</Modal.Body>
			<Modal.Footer className='flex-center' style={{ padding: 0, margin: "0 16px 16px", display: "flex", justifyContent: "space-between" }}>
				{channelsList()}
			</Modal.Footer>

			{showRequestContextVideo
				&& (
					<ModalRequestContextVideo
						event={event}
						onHide={closeRequestContextVideo}
					/>
				)}
		</Modal>
	);
}

export default connect(
	state => ({
		user: state.user.user,
		company: state.company.company
	}),
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	}),
)(EventViewSnapshotModal);
