import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import moment from 'moment'
import Datetime from '@iftek/react-datetime'
import { toastr } from 'react-redux-toastr'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { apiCallGet } from '../../../action/RouterActions'
import { tableExportOptions } from '../../helpers/table'
import momenttz from '../../helpers/momenttz'

import './LiveRecordingLogs.sass'
import { current } from '@reduxjs/toolkit'

const LiveRecordingLogs = (props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'manageVehiclesPage.editAssetContainer.configModal.liveRecordingLogs' });
	const { deviceId, loaderModal, toggleLoaderModal } = props;

	const [interval, setIntervalState] = useState(null);
	const [logsDate, setLogsDate] = useState(moment().format('YYYYMMDD'));
	const [logs, setLogs] = useState([]);
	const [number_gaps, setNumber_gaps] = useState(0);
	const [recording_time_missing, setRecording_time_missing] = useState(0);
	const [avg_time_between_gaps, setAvg_time_between_gaps] = useState(0);
	const [avg_gap_length, setAvg_gap_length] = useState(0);
	const [rec_stops_num, setRec_stops_num] = useState(0);

	useEffect(() => {
		getRecordingLogs();
		getRecordingLogsOnline();
	}, []);

	useEffect(() => {
		return () => {
			if (interval) {
				clearInterval(interval)
				setIntervalState(null)
				localStorage.removeItem("currentDate")
			}
		}
	}, [interval]);

	localStorage.setItem("currentDate", `${logsDate}`);

	const getRecordingLogs = (dateArg = null) => {
		// FWSD-5246
		const params = {
			device_ids: deviceId,
			start_ts: (dateArg ? dateArg : moment()).format('YYYYMMDD000000'),
			end_ts: (dateArg ? dateArg : moment()).format('YYYYMMDD235959'),
			// start_ts: moment.utc((dateArg || moment()).startOf('day')).format('YYYYMMDDHHmmss'),
			// end_ts: moment.utc((dateArg || moment()).endOf('day')).format('YYYYMMDDHHmmss'),
		}

		const requestArray = []
		requestArray.push(apiCallGet('/devices/{device_ids}/recording_log', { ...params }))
		requestArray.push(apiCallGet('/reports/recording_log/gaps', { ...params }))

		toggleLoaderModal(true);
		Promise.all(requestArray)
			.then(res => {
				const { logs } = res[0].data.response
				const { number_gaps, recording_time_missing, avg_time_between_gaps, avg_gap_length, rec_stops_num } = res[1].data.response.report.length && res[1].data.response.report[0]

				setLogs(logs);
				setNumber_gaps(number_gaps);
				setRecording_time_missing(recording_time_missing);
				setAvg_time_between_gaps(avg_time_between_gaps);
				setAvg_gap_length(avg_gap_length);
				setRec_stops_num(rec_stops_num);

				toggleLoaderModal(false);
			})
			.catch(error => {
				toggleLoaderModal(false);
				console.log('Promise.all error', error.response, error)
				toastr.error(t('issueGettingRecordingLogs'))
			})
	}

	// FWSD-5096
	const getRecordingLogsOnline = () => {
		if (interval) {
			clearInterval(interval)
			setInterval(null)
		}

		setIntervalState(setInterval(() => {
			const logsDate = localStorage.getItem("currentDate");
			const params = {
				device_ids: deviceId,
				start_ts: `${logsDate}000000`,
				end_ts: `${logsDate}235959`,
			}

			const requestArray = []
			requestArray.push(apiCallGet('/devices/{device_ids}/recording_log', { ...params }))
			requestArray.push(apiCallGet('/reports/recording_log/gaps', { ...params }))

			Promise.all(requestArray)
				.then(res => {
					const { logs } = res[0].data.response
					const { number_gaps, recording_time_missing, avg_time_between_gaps, avg_gap_length, rec_stops_num } = res[1].data.response.report.length && res[1].data.response.report[0]

					setLogs(logs);
					setNumber_gaps(number_gaps);
					setRecording_time_missing(recording_time_missing);
					setAvg_time_between_gaps(avg_time_between_gaps);
					setAvg_gap_length(avg_gap_length);
					setRec_stops_num(rec_stops_num);
				})
				.catch(error => {
					console.log('Promise.all online error: ', error.response, error)
				})
		}, 10000))
	}

	const changeLogsDate = (selectedDate) => {
		if (selectedDate.isAfter(moment())) {
			toastr.info(t('noFutureLogs'))
		}
		else {
			setLogsDate(selectedDate.format('YYYYMMDD'))
			getRecordingLogs(selectedDate)
		}
	}

	const getNextLogs = () => {
		const selectedDate = moment(logsDate).add(1, 'days')
		if (selectedDate.isAfter(moment())) {
			toastr.info(t('noFutureLogs'))
		}
		else {
			setLogsDate(selectedDate.format('YYYYMMDD'))
			getRecordingLogs(selectedDate)
		}
	}

	const getPrevLogs = () => {
		const selectedDate = moment(logsDate).subtract(1, 'days')
		setLogsDate(selectedDate.format('YYYYMMDD'))
		getRecordingLogs(selectedDate)
	}

	const timestampFormatter = (cell, row) => {
		if (cell) {
			// console.log('cell', cell)
			// return moment(cell).format('HH:mm:ss');
			// return momenttz(`${cell}+00`).format('HH:mm:ss');
			return cell.split(' ')[1]
		}

		return '';
	}

	const timestampFormatterCSV = (cell, row) => {
		if (cell) {
			// console.log('cell', cell)
			// return moment(cell).format('M/DD/YYYY hh:mm:ss A');
			// return momenttz(`${cell}+00`).format('YYYY-MM-DD HH:mm:ss');
			return cell
		}

		return '';
	}

	return (
		<div className="live-recording-logs">
			<h4 className="category-title text-center">{t('titleLogs')}</h4>
			<div className="logs-body">
				<div className="live-logs-date-container">
					<div className="datetime">
						<svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" fill="currentColor" className="bi bi-caret-left-fill" viewBox="0 0 16 16"
							onClick={getPrevLogs}
						>
							<path d="M3.86 8.753l5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
						</svg>
						<Datetime
							timeFormat={false}
							value={moment(logsDate).format('MM/DD/YYYY')}
							closeOnSelect
							isValidDate={date => {
								if (moment(date).isBefore(moment())) {
									return true
								}
								return false;
							}}
							onChange={changeLogsDate}
						/>
						<svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16"
							onClick={getNextLogs}
						>
							<path d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
						</svg>
					</div>
				</div>
			</div>

			<div className="table-logs">
				<div className="live-logs-container">
					{logs.length > 0
						? (
							<BootstrapTable
								data={logs}
								{...tableExportOptions}
								pagination={false}
								csvFileName={`recordinglogs_${deviceId}_${moment(logsDate).format('MM_DD_YYYY')}.csv`}
							>
								<TableHeaderColumn
									width="15%"
									dataField="start_ts"
									headerTitle={false}
									dataFormat={timestampFormatter}
									csvHeader={t('headerTime')}
									csvFormat={timestampFormatterCSV}
									isKey
								>
									{t('headerTime')}
								</TableHeaderColumn>
								<TableHeaderColumn
									dataField="filename"
									headerTitle={false}
									csvHeader={t('headerFilename')}
								>
									{t('headerFilename')}
								</TableHeaderColumn>
							</BootstrapTable>
						) : (
							<div className="text-center">
								{t('noLogsForDate')}
							</div>
						)
					}
				</div>
			</div>

			<hr />

			<h4 className="category-title text-center">{t('statForDate')}</h4>
			<ul className="no-style-list">
				<li className="statistic-line">{t('number_gaps')}<span>{number_gaps || 0}</span></li>
				<li className="statistic-line">{t('rec_stops_num')}<span>{rec_stops_num || 0}</span></li>
				<li className="statistic-line">{t('rec_time_missing')}<span>{recording_time_missing || 0}</span></li>
				<li className="statistic-line">{t('avg_time_between_gaps')}<span>{avg_time_between_gaps || 0}</span></li>
				<li className="statistic-line">{t('avg_gap_length')}<span>{avg_gap_length || 0}</span></li>
			</ul>
			{loaderModal}
		</div>
	)
}

LiveRecordingLogs.propTypes = {
	deviceId: PropTypes.string.isRequired
}

export default connect(
	null,
	dispatch => ({
		toggleLoaderModal: (show) => {
			dispatch({ type: 'TOGGLE_LOADER_MODAL', payload: show });
		}
	})
)(LiveRecordingLogs);
