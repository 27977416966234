import React, { useState, useEffect } from 'react';
import { Link, browserHistory } from 'react-router';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Auth from '../../Auth'
import { apiCallGet } from '../../../action/RouterActions'

const PatternsPartnerRedirectContainer = (props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'patternsPage' });
	const [error, setError] = useState('');

	useEffect(() => {
		// console.log('!!!componentDidMount PATTERN: ', props);
		const { params, updatePartnerPortalRedirect } = props;
		// if (params.page_type === 'company') {
		// 	// document.getElementsByClassName('loader')[0].style.display = 'block'
		// 	apiCallGet('/company/{company_id}', { company_id: params.id })
		// 		.then((res) => {
		// 			// document.getElementsByClassName('loader')[0].style.display = 'none'
		// 			const { company } = res.data.response
		// 			// partner authorization
		// 			new Auth().authenticateUser(true, 1, 1)
		// 			// sets the PartnerPortalRedirect reducer
		// 			updatePartnerPortalRedirect({
		// 				type: params.page_type,
		// 				id: params.id,
		// 				company_id: params.id,
		// 				portal_url: document.referrer,
		// 			})
		// 			// redirect to Company Edit page
		// 			browserHistory.push({
		// 				pathname: '/company/edit',
		// 				state: {
		// 					company,
		// 				},
		// 			})
		// 		})
		// 		.catch((err) => {
		// 			// document.getElementsByClassName('loader')[0].style.display = 'none'
		// 			setError(true);
		// 			console.log('!getCompanyById error: ', err.response, err)
		// 		})
		// }
		if (['vehiclescompany', 'company'].includes(params.page_type)) {
			// partner authorization
			new Auth().authenticateUser(true, 1, 1)
			// sets the PartnerPortalRedirect reducer
			updatePartnerPortalRedirect({
				type: params.page_type,
				id: params.company_id,
				company_id: params.company_id,
				portal_url: document.referrer,
			})
			// redirect to Homepage
			browserHistory.push({
				pathname: '/',
				state: undefined,
			})
		}
		else if (params.page_type === 'vehicle') {
			// partner authorization
			new Auth().authenticateUser(true, 1, 1)
			// sets the PartnerPortalRedirect reducer
			updatePartnerPortalRedirect({
				type: params.page_type,
				id: params.id,
				company_id: params.company_id,
				portal_url: document.referrer,
			})
			// redirect to Vehicle Edit page
			browserHistory.push({
				pathname: '/managevehicles/edit',
				state: {
					id: params.id,
					// company: paramsCompany,
				},
			})
		}
		else if (params.page_type === 'profile') {
			apiCallGet('/company/{company_id}', { company_id: params.company_id })
				.then((res) => {
					// document.getElementsByClassName('loader')[0].style.display = 'none'
					const { company } = res.data.response
					// partner authorization
					new Auth().authenticateUser(true, 1, 1)
					// sets the PartnerPortalRedirect reducer
					updatePartnerPortalRedirect({
						type: params.page_type,
						id: params.id,
						company_id: params.company_id,
						portal_url: document.referrer,
					})
					// redirect to Vehicle Edit page
					browserHistory.push({
						pathname: '/managevehicles',
						state: {
							tabKey: 2,
							profile_name: params.id,
							// selectedProfileName: params.id,
							device_id: 'all',
							company,
						},
					})
				})
				.catch((err) => {
					// document.getElementsByClassName('loader')[0].style.display = 'none'
					setError(true)
					console.log('!getCompanyById for profile error: ', err.response, err)
				})
		}

		if (!new Auth().isUserAuthenticated() && params.token) {
			new Auth().authenticateUser(true, params.token, 1);
		}
		// browserHistory.push({ pathname: '/company/edit', state: { company: created_company } });

		// document.getElementsByClassName('loader')[0].style.display = 'block';

		// const { user, params, updateCompany } = props
		// const data = {
		// 	// user_role: user.user.roles[0],
		// 	event_id: params.page_type,
		// 	event_type: params.id,
		// }
		// getEventByIdWithType(data)
		// 	.then((resEvent, errEvent) => {
		// 		console.log('!getEventByIdWithType res', resEvent, errEvent);

		// 		updateCompany({
		// 			name: resEvent.data.response.event.company_name,
		// 			partner: resEvent.data.response.event.partner_company,
		// 			company_id: resEvent.data.response.event.company_id,
		// 		});

		// 		browserHistory.push({
		// 			pathname: '/event',
		// 			state: {
		// 				event_device_id: resEvent.data.response.event.device_id, // 98293836, //row.device_id, 2018-08-03 06:55:19
		// 				event_id: resEvent.data.response.event.id, // 22229, //row.event_id,
		// 				event_ts: moment(resEvent.data.response.event.timestamp).utc().format('YYYY-MM-DD HH:mm:ss'), // "2018-08-01 17:54:30", //row.timestamp,
		// 				type: 'user_notification',
		// 				// type: "tt_local" //(props.type=='storage_link') ? 'storage_local' : props.type,
		// 			},
		// 		});
		// 	})
		// 	.catch((error)=>{
		// 		document.getElementsByClassName('loader')[0].style.display = 'none';

		// 		console.log('!getAlertsOfDevice error');
		// 		console.log(error.response);
		// 		console.log(error);

		// 		let _error = JSON.stringify(error);
		// 		if (error.response && error.response.data.response.error) {
		// 			_error = error.response.data.response.error;
		// 		}
		// 		setError(_error);
		// 	});
	}, []);

	return (
		<article>
			{error
				&& (
					<div>
						<h3>{t('error404')}</h3>
						<div>{error}</div>
						<div>
							{t('goBack')} <Link to="/" className="btn">{t('btnHome')}</Link>
						</div>
					</div>
				)}
		</article>
	)
}

// const PatternsPartnerRedirectContainer = (props) => {
// const WIKI_URL = config.dokuwikiLinks
// const { params } = props

// location.href = `https://${defineEnvironmentDokuWiki()}/${WIKI_URL[params.linkType]}?s_t=${new Auth().getSessionToken()}&env=${location.hostname}`

// return <div />
// }

PatternsPartnerRedirectContainer.propTypes = {
	params: PropTypes.objectOf(PropTypes.any).isRequired,
	updatePartnerPortalRedirect: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => {
	return {
		updatePartnerPortalRedirect: (payload) => {
			dispatch({ type: 'SET_PARTNER_PORTAL_REDIRECT', payload });
		},
	}
}

export default connect(null, mapDispatchToProps)(PatternsPartnerRedirectContainer);
