import React, { useEffect, useState } from 'react'
import { browserHistory } from 'react-router'
import { Modal } from 'react-bootstrap'
import classnames from 'classnames'
import { toastr } from 'react-redux-toastr'
import DualListBox from 'react-dual-listbox'
import _ from 'lodash'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import AccountNotificationsContainer from '../account/AccountNotificationsContainer'
import ToggleRowNode from './ManageAssetsUserToggleRowNode'
import CustomButton from '../../CustomButton'
import GreyHeader from '../../GreyHeader'

import { constants } from '../../helpers/constants'
import { isCustomUserPermissions } from '../../helpers/helperUsers'
import { deleteFleetUser, updateFleetUser, updateFleetUserSettings, activateUserAccount } from '../../../action/ClientActions'
import { getUserNotifications, getUserPermissions, updateUserPermissions } from '../../../action/AccountActions'
import { getCompanyGroups, removeUserFromGroup, addUserToGroup } from '../../../action/GroupsActions'
import { apiCallGet } from '../../../action/RouterActions'

import '../../dump/menuComponents/menuComponents.sass'
import 'react-toggle/style.css'
import 'react-dual-listbox/lib/react-dual-listbox.css'

const ManageAssetsUserEditContainer = (props) => {
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'manageUsersPage.manageAssetsUserEditContainer' });
	const { user, location, toggleLoader } = props;
	const userRole = user.roles[0];
	const userPermissions = user.permissions;

	const [data, setData] = useState({})
	const [userState, setUserState] = useState({})
	const [modal, setModal] = useState({
		show: false,
		content: '',
		error: false
	})
	const [errMessage, setErrMessage] = useState({
		first_name: '',
		last_name: '',
		phone_number: '',
	})
	const [groups, setGroups] = useState([])
	const [permissions, setPermissions] = useState([])
	const [permissionsInit, setPermissionsInit] = useState([])
	const [upd, setUpd] = useState(false)

	// console.log('PROPS ManageAssetsUserEditContainer: ', props)
	// console.log('STATE ManageAssetsUserEditContainer groups: ', groups)
	// console.log('STATE ManageAssetsUserEditContainer permissions: ', permissions)
	// console.log('STATE ManageAssetsUserEditContainer permissionsInit: ', permissionsInit)

	useEffect(() => {
		getAvailablePermissionCategories();
		getUser();
	}, [])

	const getAvailablePermissionCategories = () => {
		// Gets list of all available permission categories for the custom users and sets state.permissions default value
		apiCallGet('/custom_users/permissions/categories')
			.then(res => {
				const { permissions } = res.data.response
				const defaultPermissions = _.orderBy(permissions, ['order'], ['asc']).map((item) => {
					return {
						a_add: false,
						a_delete: false,
						a_edit: false,
						a_view: true,
						perm_category_id: item.id,
						perm_category_name: item.name,
					}
				})
				setPermissions(defaultPermissions)
				setPermissionsInit(JSON.parse(JSON.stringify(defaultPermissions)))
			})
			.catch((error) => {
				console.log('!getPermissionCategories error: ', error.response, error)
			})
	}

	const getUser = () => {
		if (location && location.state.email) {
			toggleLoader(true);
			getUserNotifications(location.state.email, userRole)
				.then(res => {
					const { user } = res.data.response;
					const editedUserRole = user.roles[0]
					setData(user);
					setUserState(user);

					if (editedUserRole !== 'partner') {
						getGroups(user)
					}

					if (['custom_user', 'custom_partner'].includes(editedUserRole)) {
						getCustomUserPermissions(user)
					}
					toggleLoader(false);
				})
				.catch((error) => {
					console.log(error.response);
					toggleLoader(false);
				})
		}
	}

	const getGroups = (data) => {
		const company = {
			name: data.company_name,
			partner: data.partner_company_name
		};

		getCompanyGroups(userRole, company)
			.then(res => {
				toggleLoader(false);
				setGroups(res.data.response.groups);
			})
			.catch((error) => {
				toggleLoader(false);
				console.log(error);
			})
	}

	const getCustomUserPermissions = (user) => {
		const data = {
			user_role: userRole,
			user_id: location.state.userId ? location.state.userId : user.id,
		}

		getUserPermissions(data)
			.then((res, err) => {
				toggleLoader(false);
				const orderedCustomUserPermissions = _.orderBy(res.data.response, ['perm_category_order', 'perm_category_id'])
				setPermissions(orderedCustomUserPermissions)
				setPermissionsInit(JSON.parse(JSON.stringify(orderedCustomUserPermissions)))
			})
			.catch((error) => {
				toggleLoader(false);
			})
	}

	const closeModal = () => {
		setModal({ ...modal, error: false, show: false });
	}

	const deleteUserConfirm = () => {
		setModal({
			...modal,
			show: true,
			content: t('deleteUserContent', { name: `${userState.first_name} ${userState.last_name}'s` })
		});
	}

	const deleteUserModal = () => {
		const email = location.state.email;
		if (email) {
			toggleLoader(true);

			deleteFleetUser(email, userRole)
				.then(res => {
					toggleLoader(false);
					setModal({ ...modal, show: false });

					browserHistory.push('/manageusers');
					toastr.success('', t('userDeleted'));
				})
				.catch((error) => {
					toggleLoader(false);
					setModal({ ...modal, show: false });

					toastr.error(t('unableDeleteUser'));
				});
		}
	}

	const fillForm = (e) => {
		const { target } = e;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const { name } = target

		if (name === 'roles') {
			setData({ ...data, roles: [value] })
		} else {
			setData({ ...data, [name]: value })
		}

	}

	const fillSettingsForm = (e) => {
		const { target } = e;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const { name } = target

		const settings = { ...data.settings }
		settings[name] = value

		setData({ ...data, settings: settings })
	}

	const fillFormPermissions = (e, index) => {
		const updatedPermissions = [...permissions];
		const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
		const { name } = e.target

		if (name === 'a_view' && value === false && index === 3) {
			updatedPermissions.map(item => {
				if (item.perm_category_id === 7 || item.perm_category_id === 8) {
					item.a_view = false;
					item.a_add = false;
					item.a_edit = false;
					item.a_delete = false;
				}
			})
			setPermissions(updatedPermissions);
		}

		// FWSD-5438
		if (!value && updatedPermissions.reduce((enabledToggles, { a_view, a_add, a_edit, a_delete }) => (enabledToggles + a_view * 1 + a_add * 1 + a_edit * 1 + a_delete * 1), 0) < 2) {
			toastr.error(t('errUserPermissionActive'));
		}
		else {
			const permission = _.find(updatedPermissions, ['perm_category_id', index])
			if (permission) {
				permission[name] = value

				if (['a_add', 'a_edit', 'a_delete'].includes(name) && value) {
					permission.a_view = true
				}

				setPermissions(updatedPermissions)
			}
		}
	}

	const checkForm = (newData) => {
		let error = false;
		const updatedErrMessage = { ...errMessage }

		Object.keys(errMessage).forEach((i) => {
			if (newData[i].trim() === '') {
				error = true;
				let message = i.replaceAll('_', ' ');
				updatedErrMessage[i] = `${t('pleaseEnter')} ${message}`;
			} else if (updatedErrMessage[i]) {
				updatedErrMessage[i] = null;
			}
		})

		if (newData.phone_number) {
			if (newData.phone_number.length < 3) {
				error = true;
				toastr.info(t('errMinPhoneNumber'));
			}
			if (newData.phone_number.length > 20) {
				error = true;
				toastr.info(t('errMaxPhoneNumber'));
			}
			if (!newData.phone_number.trim() || isNaN(newData.phone_number)) {
				error = true;
				toastr.info(t('errValidPhoneNumber'));
			}
		}

		if (data.roles['0'] === 'group_manager' && (!data.groups || !data.groups.length)) {
			error = true;
			toastr.info(t('selectGroup(s)'));
		}

		setErrMessage(updatedErrMessage);
		return error;
	}

	const sendForm = (e) => {
		e.preventDefault()
	}

	const handleSaveClick = (e) => {
		e.preventDefault()
		const oldUser = userState;
		const newUser = data;
		const oldUserGroups = oldUser.groups;
		const newUserGroups = newUser.groups;
		const userRole = user.roles[0];
		const userEmail = oldUser.email;
		const userDiff = _.reduce(_.keys(newUser), (result, key) => {
			if (key !== 'groups' && key !== 'settings' && newUser[key] !== oldUser[key]) {
				result[key] = newUser[key]
			}
			return result
		}, {});
		const user_settings_diff = _.reduce(_.keys(newUser.settings), (result, key) => {
			if (newUser.settings[key] !== oldUser.settings[key]) {
				result[key] = newUser.settings[key]
			}
			return result
		}, {})

		const customUserPermissionsDiff = _.differenceWith(permissions, permissionsInit, _.isEqual)
		let requestArray = []

		const error = checkForm(newUser)

		if (!error) {
			toggleLoader(true)

			if (_.keys(userDiff).length > 0) {
				requestArray.push(updateFleetUser({ user_role: userRole, user_email: userEmail, data: userDiff }))
			}

			if (_.keys(user_settings_diff).length > 0) {
				requestArray.push(updateFleetUserSettings({ user_role: userRole, user_email: userEmail, data: user_settings_diff }))
			}

			if (!_.isEqual(_.sortBy(newUserGroups), _.sortBy(oldUserGroups))) {
				const groupData = {
					user_role: userRole,
					partner_name: oldUser.partner_company_name,
					company_name: oldUser.company_name,
					group_name: '',
				}

				const removeGroups = _.difference(oldUserGroups, newUserGroups)
				removeGroups.forEach((group) => {
					groupData.group_name = group
					requestArray.push(removeUserFromGroup(groupData, userEmail))
				})

				const addGroups = _.difference(newUserGroups, oldUserGroups)
				addGroups.forEach((group) => {
					groupData.group_name = group
					requestArray.push(addUserToGroup(groupData, userEmail))
				})
			}

			if (['custom_user', 'custom_partner'].includes(newUser.roles['0']) && _.keys(customUserPermissionsDiff).length > 0) {
				const permissionsData = {
					user_role: userRole,
					user_id: location.state.userId ? location.state.userId : data.id,
					permissions: JSON.stringify(permissions),
				}
				requestArray.push(updateUserPermissions(permissionsData))
			}

			if (!requestArray.length) {
				toastr.info(t('userNoChanges'))
				toggleLoader(false)
				return
			}

			Promise.all(requestArray)
				.then(
					(res) => {
						setUserState({ ...data })
						setPermissionsInit(JSON.parse(JSON.stringify(permissions)))
						toggleLoader(false)
						toastr.success(t('updatedUser'))
					},
					error => {
						toggleLoader(false)

						let errDescription = i18n.t('errorMessage.errDescription');
						if (error.response.data.response.error) {
							errDescription = error.response.data.response.error
						}
						toastr.error(errDescription)
					},
				)
		}
	}

	const activateAccount = () => {
		activateUserAccount(data.email)
			.then(res => {
				const { response } = res.data;
				toggleLoader(false);
				setData({ ...data, confirmed_at: true });
				toastr.success('', `${response}`);
			})
			.catch(err => {
				toggleLoader(false);
				console.log(err)
				toastr.error(t('unableActivateAccount'));
			});
	}

	const rolesOptions = () => {
		const availableRolesWCS = constants.availableCreateRolesWCS;
		const rolesNames = constants.roleNames;
		let availableRoles = constants.availableCreateRolesPerUser;

		let options = [];
		let i = 0;

		if (data.partner_company_name === 'Rosco Vision WCS' || data.company_type === 'WCS') {
			availableRoles = availableRolesWCS
		}

		if (['partner_view', 'partner', 'custom_partner'].includes(data.roles[0])) {
			options.push(<option value='partner_view' key='partner_view'>{t('optPartnerView')}</option>)
			options.push(<option value='partner' key='partner'>{t('optPartner')}</option>)
			options.push(<option value='custom_partner' key='custom_partner'>{t('optCustomPartner')}</option>)
		}
		else {
			if (availableRoles.hasOwnProperty(userRole)) {
				availableRoles[userRole].forEach((key) => {
					if (rolesNames.hasOwnProperty(key) && !['partner', 'partner_view', 'custom_partner'].includes(key)) {
						options.push(<option value={key} key={i++}>{rolesNames[key]}</option>)
					}
				});
			}

			if (userRole === 'system_admin'
				&& (data.partner_company_name === 'Rosco Vision Partner' || data.partner_company_name === 'Rosco Vision Testing')
				&& data.company_name === 'Rosco Vision'
			) {
				options.push(<option value="system_admin" key={i++}>{t('optSystemAdmin')}</option>)
				options.push(<option value="customer_service" key={i++}>{t('optCustomerService')}</option>)
			}
		}

		return options
	}

	if (!data || !data.roles) {
		return (
			<GreyHeader title={t('titleEditUser')} hideSubtitle />
		)
	}

	const userName = user.email;
	const editableUserName = location.state.email;
	const editableUserRole = data.roles[0];
	// const readOnly = location.state.readOnly
	// 	|| (
	// 		(userRole !== 'customer_service' && userRole !== 'system_admin')
	// 		&& (editableUserRole === 'customer_service' || editableUserRole === 'system_admin')
	// 	);
	const readOnly = location.state.readOnly
		|| (
			!['system_admin', 'customer_service'].includes(userRole)
			&& (['system_admin', 'customer_service'].includes(editableUserRole))
		)
		|| (
			userRole === 'custom_partner'
			&& !isCustomUserPermissions(userRole, userPermissions, 'user_edit')
			&& !isCustomUserPermissions(userRole, userPermissions, 'user_delete')
		)
	const deleteUserAccess = ['system_admin', 'customer_service'].includes(userRole)
		|| (!['system_admin', 'customer_service', 'custom_partner'].includes(userRole) && !['system_admin', 'customer_service'].includes(editableUserRole))
		|| (userRole === 'custom_partner' && isCustomUserPermissions(userRole, userPermissions, 'user_delete'))
	const { first_name, last_name, phone_number } = errMessage

	let options = []
	groups.map(group => options.push({ value: group, label: group }))

	const opts = {}
	if (readOnly) {
		opts.disabled = true
		opts.readOnly = 'readOnly'
	}
	const optsRecordingStatus = {}
	if (['system_admin', 'customer_service', 'partner', 'partner_view', 'sales_demo', 'reviewer', 'installer'].indexOf(editableUserRole) > -1) {
		optsRecordingStatus.disabled = true
		optsRecordingStatus.readOnly = 'readOnly'
	}

	return (
		<div className="manage-users-edit">
			<GreyHeader
				title={t('titleEditUser')}
				subtitle={data.first_name + ' ' + data.last_name}
			/>

			<div className="page-subheader">
				<div className='subheader-text-container'>
					<span className='subheader-text'>
						<b>{t('subheaderEmail')}</b>{location ? location.state.email : props.email}
					</span>
					<span className='subheader-text'>
						<b>{t('subheaderCompanyName')}</b>{data.company_name}
					</span>
				</div>
				<div className='subheader-section'>
					{!readOnly && (
						<CustomButton
							variant="secondary"
							onClick={handleSaveClick}
						>
							{t('btnSave')}
						</CustomButton>
					)}
					<CustomButton
						variant="secondary-outline"
						onClick={browserHistory.goBack}
					>
						{t('btnBack')}
					</CustomButton>
					{editableUserName !== userName && deleteUserAccess && (
						<CustomButton
							variant="delete"
							onClick={deleteUserConfirm}
						>
							{t('btnDelete')}
						</CustomButton>
					)}
				</div>
			</div>
			<main className="main-content-block edit-user-container-mobile">
				<section>
					<h3 className='category-title'>{t('titleUserInfo')}</h3>
					<form onSubmit={sendForm}>
						<div className="three-column-grid with-margin">
							<div className={classnames('', { 'has-error': first_name })}>
								<label className="control-label">{t('labelFirstName')}</label>
								<input
									type="text"
									name="first_name"
									className="vehicle-search"
									onChange={fillForm}
									value={data.first_name}
									{...opts}
								/>
								{first_name && <span className="help-block">{first_name}</span>}
							</div>

							<div className={classnames('', { 'has-error': last_name })}>
								<label className="control-label">{t('labelLastName')}</label>
								<input
									type="text"
									name="last_name"
									className="vehicle-search"
									onChange={fillForm}
									value={data.last_name}
									{...opts}
								/>
								{last_name && <span className="help-block">{last_name}</span>}
							</div>

							<div className={classnames('', { 'has-error': phone_number })}>
								<label className="control-label">{t('labelPhoneNumber')}</label>
								<input
									type="text"
									name="phone_number"
									className="vehicle-search"
									onChange={fillForm}
									value={data.phone_number}
									{...opts}
								/>
								{phone_number && <span className="help-block">{phone_number}</span>}
							</div>

							{(readOnly || !rolesOptions().length)
								? (
									<div>
										<label htmlFor="roles" className="control-label">{t('labelRole')}</label>
										<input
											readOnly
											type="text"
											name="roles"
											id="roles"
											className="vehicle-search"
											value={constants.roleNames[editableUserRole]}
											{...opts}
										/>
									</div>
								)
								: (
									<div>
										<label htmlFor="roles" className="control-label">{t('labelRole')}</label>
										<div>
											<select
												placeholder="select"
												className="rosco-select block"
												name="roles"
												id="roles"
												value={editableUserRole}
												onChange={fillForm}
											>
												{rolesOptions()}
											</select>
										</div>
									</div>
								)
							}
						</div>
						{!readOnly && (['user', 'group_manager', 'storage_user'].indexOf(editableUserRole) > -1 && (
							<div className='list-box-wrapper'>
								<div className="label-wrapper">
									<label htmlFor="groups" className="control-label">
										{t('labelAvailableGroups')}
									</label>
									<label htmlFor="groups" className="control-label">
										{data.first_name + ' ' + data.last_name + t('textCurrentGroups')}
									</label>
								</div>
								<DualListBox
									canFilter
									options={options}
									selected={data.groups}
									onChange={(selected) => setData({ ...data, groups: selected })}
								/>
							</div>
						))}
					</form>
				</section>

				<hr />

				{!readOnly && ['custom_user', 'custom_partner'].includes(editableUserRole)
					&& (
						<section>
							<h3 className='category-title'>{t('Custom')} {editableUserRole === 'custom_user' ? t('User') : t('Partner')} {t('Permissions')}</h3>

							<div className="col-md-8 col-sm-10 col-xs-12">
								<table className="table">
									<thead>
										<tr>
											<td className="col-md-4">{t('tabPage')}</td>
											<td className="col-md-2">{t('tabView')}</td>
											<td className="col-md-2">{t('tabAdd')}</td>
											<td className="col-md-2">{t('tabEdit')}</td>
											<td className="col-md-2">{t('tabDelete')}</td>
										</tr>
									</thead>
									<tbody>
										{
											permissions.map(item => {
												// Users item available just for the Custom Partner role
												if (editableUserRole === 'custom_user' && (item.perm_category_id === 16 || item.perm_category_id === 10 || item.perm_category_id === 17)) { /*Users, Firmare Portal*/
													return ''
												}
												item.disabled = false;
												if ((item.perm_category_id === 7 || item.perm_category_id === 8) && _.find(permissions, { 'perm_category_id': 3, 'a_view': false })) {
													item.disabled = true;
												}
												return (
													<ToggleRowNode
														key={item.perm_category_id}
														{...item}
														onChange={fillFormPermissions}
													/>
												)
											})
										}
									</tbody>
								</table>
							</div>

							<hr />
						</section>
					)}

				<AccountNotificationsContainer
					userEmail={data.email}
					userRole={data.roles[0]}
					notifications={['storage_manager', 'storage_user'].includes(data.roles[0]) ? data : data.notifications}
					getNotifications={getUser}
					readOnly={readOnly}
					fillForm={fillForm}
				/>

				<div className="form-group">
					{!readOnly && !data.confirmed_at && (['system_admin', 'customer_service', 'partner'].indexOf(userRole) > -1) && data.roles[0] !== "notification_recipient" && (
						<CustomButton onClick={activateAccount} variant="primary">{t('btnActivateAccount')}</CustomButton>
					)}
				</div>
			</main>

			<Modal
				size='lg'
				show={modal.show}
				onHide={closeModal}
				className="modal-lg-size"
				dialogClassName="has-error"
			>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-lg" className='delete-title'>{t('titleDeleteUser')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className='modal-text'>
						{modal.content}
					</div>
				</Modal.Body>
				<Modal.Footer bsPrefix="default-modal-footer modal-footer">
					<CustomButton variant="delete" onClick={deleteUserModal}>{t('btnDeleteUser')}</CustomButton>
				</Modal.Footer>
			</Modal>

		</div>
	)
}

export default connect(
	state => ({
		user: state.user.user
	}),
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(ManageAssetsUserEditContainer);
