import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import { useTranslation } from 'react-i18next';

import ReportsList from './ReportsList'
import Charts from './Charts'

import {
	getDashboardData,
	saveDashboardData,
	getDynamicDataTop,
	getDynamicDataBottom,
	getDynamicDataDevices
} from '../../../action/AccountActions';

const ReportsChartMain = (props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'constants.eventNames' });
	const { user, company } = props;

	const [dynamic_data, setDynamic_data] = useState(false);
	const [no_settings, setNo_settings] = useState(false);
	const [user_settings, setUser_settings] = useState({
		charts: [],
		layout: []
	});
	const [initial_my_settings, setInitial_my_settings] = useState({
		charts: [],
		layout: []
	});
	const [initial_user_settings, setInitial_user_settings] = useState({});
	const [layout, setLayout] = useState({});
	const [modal, setModal] = useState({
		show: false,
		content: '',
		error: false
	});
	const [content, setContent] = useState('');
	const [edit_chart, setEdit_chart] = useState('');
	const [errMessage, setErrMessage] = useState({
		events: '',
		alerts: '',
		type: '',
		name: '',
		date_range: '',
		devices: '',
		events_new: '',
		alerts_new: '',
		type_new: '',
		name_new: '',
		date_range_new: '',
		devices_new: ''
	});
	const [events, setEvents] = useState([t('Driver'), t('High_G-Force'), t('Speed'), t('Custom_Video_Request'), t('Input_Sensor'), t('Pedestrian_Warning'), t('Forward_Collision'), t('Lane_Departure'), t('Headway_Warning'), t('Tailgating')]);
	const [alerts, setAlerts] = useState([t('SD_Card_Failure'), t('Improper_Shutdown'), t('Camera_Failure')]);
	const userRole = user.roles[0];

	// console.log('STATES ReportsChartMain initial_user_settings: ', initial_user_settings)
	// console.log('STATES ReportsChartMain user_settings: ', user_settings)

	// useEffect(() => {
	// 	switch (userRole) {
	// 		case 'system_admin':
	// 		case 'customer_service':
	// 		case 'sales_demo':
	// 			if (company.name && company.partner) {
	// 				getSettings(company.name, company.partner);
	// 			}
	// 			break;
	// 		case 'fleet_maintainer':
	// 		case 'fleet_manager':
	// 		case 'group_manager':
	// 		case 'user':
	// 		case 'storage_manager':
	// 		case 'storage_user':
	// 		case 'custom_user':
	// 			getSettings();
	// 			break;

	// 		case 'partner':
	// 		case 'partner_view':
	// 			if (company.name) {
	// 				getSettings(company.name);
	// 			}
	// 			break;
	// 		default:
	// 			break;
	// 	}
	// }, []);

	useEffect(() => {
		switch (userRole) {
			case 'system_admin':
			case 'customer_service':
			case 'sales_demo':
				if (company.name && company.partner) {
					getSettings(company.name, company.partner);
				}
				break;
			case 'fleet_maintainer':
			case 'fleet_manager':
			case 'group_manager':
			case 'user':
			case 'partner':
			case 'partner_view':
			case 'storage_manager':
			case 'storage_user':
			case 'custom_user':
			case 'custom_partner':
				if (company.name) {
					getSettings(company.name);
				}
				break;
			default:
				break;
		}
	}, [company.name, company.partner])

	const getSettings = async (company_name, partner_name) => {
		props.toggleLoader(true);
		const _company_name = company_name || company.name;

		props.toggleLoader(true)
		const resUserSettings = await getDashboardData()
			.then((res, err) => {
				return res
			})
			.catch((error) => {
				props.toggleLoader(false);
				console.log('!getSettings getDashboardData error', error);
				console.log(JSON.stringify(error));
				setNo_settings(true);
				setDynamic_data(false);
			})

		if (
			resUserSettings.data.response
			&& resUserSettings.data.response.settings
			&& resUserSettings.data.response.settings.dashboard_data
			&& JSON.parse(resUserSettings.data.response.settings.dashboard_data).charts
			&& JSON.parse(resUserSettings.data.response.settings.dashboard_data).charts.length > 0
		) {
			const dashboard = JSON.parse(resUserSettings.data.response.settings.dashboard_data);
			const dashboardMap = JSON.parse(JSON.stringify(resUserSettings.data.response.settings.dashboard_data));
			const { layout } = dashboard;

			let reqArray = [];
			let mapObj = JSON.parse(dashboardMap); // +
			let newwState = mapObj.charts.map((chart, i) => {
				const events = chart.events ? chart.events.join(',') : [];
				const alerts = chart.alerts ? chart.alerts.join(',') : [];

				if (chart.type === 'bar_top_10' || chart.type === 'pie_top_10') {
					reqArray.push(getDynamicDataTop(userRole, chart.start_date, chart.end_date, events, alerts, _company_name, partner_name));
				}
				else if (chart.type === 'bar_bottom_10' || chart.type === 'pie_bottom_10') {
					reqArray.push(getDynamicDataBottom(userRole, chart.start_date, chart.end_date, events, alerts, _company_name, partner_name));
				}
				else if (chart.type === 'bar_custom' || chart.type === 'pie_custom') {
					let devices;
					switch (userRole) {
						case 'system_admin':
						case 'customer_service':
						case 'sales_demo':
							if (chart.devices_custom[partner_name] && chart.devices_custom[partner_name][_company_name]) {
								devices = chart.devices_custom[partner_name][_company_name];
							} else {
								devices = ''
							}
							break;
						case 'partner':
						case 'partner_view':
						case 'custom_partner':
							if (chart.devices_custom[_company_name]) {
								devices = chart.devices_custom[_company_name];
							} else {
								devices = ''
							}
							break;
						case 'fleet_maintainer':
						case 'fleet_manager':
						case 'group_manager':
						case 'user':
						case 'storage_manager':
						case 'storage_user':
						case 'custom_user':
							devices = chart.devices;
							break;
						default:
							break;
					}

					reqArray.push(getDynamicDataDevices(userRole, chart.start_date, chart.end_date, events, alerts, devices, _company_name, partner_name));
				}

				return chart
			})

			const resGetEvents = await Promise.allSettled(reqArray)
				.then((res, err) => {
					// console.log('GetDynamicData promiseAll response: ', res);
					return res
				})
				.catch(error => {
					console.log('GetDynamicData promiseAll error', error);
					// props.toggleLoader(false)
				})
			// console.log('newwState: ', newwState)
			// console.log('resGetEvents: ', resGetEvents)
			const result = newwState.map((chart, i) => {
				// if (resGetEvents[i].status === 200) {
				if (resGetEvents[i].status === 'fulfilled') {
					const dynamic_data = resGetEvents[i].value.data.response.report;
					if (dynamic_data.data.length < 1) {
						chart.data = [];
						chart.device_name = chart.devices;
					} else {
						chart.device_name = dynamic_data.device_name;

						if (dynamic_data.data_usage) {
							let initialArrayUsage = dynamic_data.data_usage;
							let transformedArrayUsage = [];
							let rounded;

							for (let i = 0; i < initialArrayUsage[0].length; i++) {
								transformedArrayUsage[i] = 0;
								for (let j = 0; j < initialArrayUsage.length; j++) {
									transformedArrayUsage[i] += parseFloat(initialArrayUsage[j][i]);
								}
							}

							const transformedArrayUsageToFixed = transformedArrayUsage.map((item) => {
								if (item !== 0) {
									rounded = item.toFixed(2);
								} else {
									rounded = item;
								}
								return rounded;
							})

							chart.data_usage = transformedArrayUsageToFixed;
						}
						else {
							chart.data_usage = dynamic_data.device_name.map(() => { return 0 })
						}

						if (chart.type === 'pie_top_10' || chart.type === 'pie_bottom_10' || chart.type === 'pie_custom') {
							const initialArray = dynamic_data.data;

							let transformedArray = [];
							for (let i = 0; i < initialArray[0].length; i++) {
								transformedArray[i] = 0;
								for (let j = 0; j < initialArray.length; j++) {
									transformedArray[i] += parseInt(initialArray[j][i]);
								}
							}

							let transformedArrayDataPie = [];
							for (let i = 0; i < initialArray[0].length; i++) {
								transformedArrayDataPie[i] = [];
								for (let j = 0; j < initialArray.length; j++) {
									transformedArrayDataPie[i].push(initialArray[j][i])
								}
							}

							chart.data = transformedArray;
							chart.data_pie = transformedArrayDataPie;
						}
						else {
							chart.data = dynamic_data.data;
						}
					}

					chart.all_events = dynamic_data.events;
				}
				else if (resGetEvents[i].status === 'rejected') {
					chart.error = resGetEvents[i].reason.response.data?.response?.error || resGetEvents[i].reason?.message || 'Internal Error'
					chart.data = [];
					chart.device_name = chart.devices;
				}
				// else if (resGetEvents[i].status === 206) {
				// 	chart.data = [];
				// 	chart.device_name = chart.devices;
				// }
				return chart;
			})

			setInitial_my_settings(dashboard)
			setInitial_user_settings(dashboard)
			setUser_settings(result);
			setLayout(layout)
			setDynamic_data(true);
			setContent('');
			setNo_settings(false);

			props.toggleLoader(false)
		}
		else {
			setNo_settings(true);
			setUser_settings({
				charts: [],
				layout: { lg: [], md: [], sm: [], xs: [], xxs: [] }
			});
			setInitial_user_settings({
				charts: [],
				layout: { lg: [], md: [], sm: [], xs: [], xxs: [] }
			});
			props.toggleLoader(false);
		}
	}

	const save = () => {
		let error = false;
		const data = JSON.stringify(initial_my_settings);
		saveDashboardData(data)
			.then((res, err) => {
				console.log('!saveDashboardData res: ', res, err);
				if (res.status === 200) {
					error = false;
				}

			})
			.catch((error) => {
				console.log('!saveDashboardData error', error);
				error = true;
			});

		return error;
	}

	const onLayoutChange = (layout, allLayouts) => {
		let layoutId = layout.map((item) => { return item.i });

		let data = initial_my_settings;
		let initialMySettings = initial_my_settings.layout;

		for (let i in allLayouts) {
			if (allLayouts[i].length !== initialMySettings[i].length) {
				let offLayouts = initialMySettings[i].filter((layoutOne) => { //поиск id не активного layout
					return layoutId.indexOf(layoutOne.i) < 0;
				});

				let result = _.sortBy(allLayouts[i].concat(offLayouts), 'i');
				data['layout'][i] = result;
			}
			else if (allLayouts[i].length == initialMySettings[i].length) {
				data['layout'][i] = allLayouts[i];
			}
		}
		setInitial_my_settings(data);
		setLayout(allLayouts);
		save();
	}

	const getCharts = () => {
		switch (user.roles[0]) {
			case 'fleet_maintainer':
			case 'fleet_manager':
			case 'group_manager':
			case 'user':
			case 'storage_manager':
			case 'storage_user':
			case 'custom_user':
				getSettings();
				break;
			case 'system_admin':
			case 'customer_service':
			case 'sales_demo':
				if (company.name && company.partner) {
					getSettings(company.name, company.partner);
				}
				break;
			case 'partner':
			case 'partner_view':
			case 'custom_partner':
				getSettings(company.name);
				break;
			default:
				break;
		}
	}

	const forChart = { dynamic_data, no_settings, user_settings, initial_my_settings, initial_user_settings, layout, modal, edit_chart, errMessage, events, alerts, content }
	// const forReport = initial_user_settings;

	// const clone2 = {}
	// for (let key in forReport) {
	// 	clone2[key] = forReport[key];
	// }

	// let cloneState = {};
	// const a = JSON.stringify(forChart);
	// cloneState = JSON.parse(a);

	return (
		<main className="reports main-content-block">
			<div className="reports-block">
				<ReportsList
					updateCharts={getCharts}
					// user_settings={clone2}
					user_settings={_.cloneDeep(initial_user_settings)}
					company_name={company.name}
					partner_name={company.partner}
					user_role={userRole}
				/>
			</div>

			<div className="charts-block">
				<Charts
					// state={cloneState}
					state={_.cloneDeep(forChart)}
					onLayoutChange={onLayoutChange}
				/>
			</div>
		</main>
	)
}

ReportsChartMain.propTypes = {
	user: PropTypes.objectOf(PropTypes.any).isRequired,
	company: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = (state) => {
	const { user, company } = state
	return {
		user: user.user,
		company: company.company
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsChartMain);
