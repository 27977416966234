import classNames from 'classnames'
import React from 'react'
import CustomButton from '../CustomButton'
import { arrowIcon, exportIcon } from './iconHelpers'
import $ from 'jquery'
import i18n from '../../../../i18n'

export const paginationOptions = {
	page: 1,  // which page you want to show as default
	sizePerPage: 10,  // which size per page you want to locate as default
	pageStartIndex: 1, // where to start counting the pages
	paginationSize: 3,  // the pagination bar size.
	paginationShowsTotal: (start, to, total) => {
		return (
			<p className='pagination-top-text' dangerouslySetInnerHTML={{ __html: i18n.t('constants.paginationOptions.paginationTopText', { start, to, total }) }}></p>
		)
	},  // Accept bool or function
	paginationPosition: 'bottom',  // default is bottom, top and both is all available
	withFirstAndLast: true, // Hide the going to First and Last page button
	sizePerPageDropDown: (props) => {
		const { changeSizePerPage, sizesPageList } = props
		let recordsPerPage = [5, 10, 20]
		if (sizesPageList) {
			recordsPerPage = sizesPageList
		}

		return (
			<div className="pagination-btn-group">
				{i18n.t('constants.paginationOptions.showOnPage')}
				{recordsPerPage.map((n) => {
					const isActive = (n == props.currSizePerPage) ? 'active' : null;
					return (
						<CustomButton
							key={n}
							variant='link'
							active={isActive}
							onClick={(e) => changeSizePerPage(n)}
						>
							{n}
						</CustomButton>
					);
				})}
			</div>
		)
	},
	paginationPanel: (props) => {
		const handleKeyPress = (e, props) => {
			const target = e.target;
			const value = target.value;
			const page_count = Math.ceil(props.components.totalText.props.children[5].props.children / props.sizePerPage)

			if (e.key === 'Enter') {
				if (value > 0 && value <= page_count && value !== '') {
					$(target).removeClass('red-pagination-input')
					props.changePage(parseInt(value))
				}
				else {
					$(target).addClass('red-pagination-input')
					setTimeout(() => $(target).removeClass('red-pagination-input'), 4000)
				}
			}
		}
		return <div className='pagination-wrapper'>
			<div>
				{props.components.totalText}
				{props.components.sizePerPageDropdown}
			</div>
			<div className='pagination-right'>
				<div className='pagination-top-text'>
					<div className='pagination'>
						{props.components.pageList.props.children.map(e => {
							if (e.props.title === "previous page") e = React.cloneElement(e, { children: i18n.t('constants.paginationOptions.prePage') });
							if (e.props.title === "next page") e = React.cloneElement(e, { children: i18n.t('constants.paginationOptions.nextPage') });
							if (e.props.title === "first page") e = React.cloneElement(e, { children: i18n.t('constants.paginationOptions.firstPage') });
							if (e.props.title === "last page") e = React.cloneElement(e, { children: i18n.t('constants.paginationOptions.lastPage') });
							return e
						})}
					</div>
				</div>
				<div className='pagination-go-to-page'>
					{i18n.t('constants.paginationOptions.goToPage')}
					<input
						id='number'
						type='number'
						onKeyPress={(e) => {
							handleKeyPress(e, props);
						}}
					/>
				</div>
			</div>
		</div>
	},
}

export const paginationExportOptions = {
	...paginationOptions,
	exportCSVBtn: (onClick) => (
		<CustomButton onClick={onClick} variant='primary-outline' size='sm' prefix='export-button'>
			{exportIcon}
			<span className='export-label'>{i18n.t('constants.paginationOptions.Export')}</span>
		</CustomButton>
	)
}

export const tableNoPaginationOptions = {
	//WRAPPER CLASSES
	tableContainerClass: 'rosco-table',
	headerContainerClass: 'rosco-table-header',
	bodyContainerClass: 'rosco-table-body',
	trClassName: (row, rowIdx) => {
		return rowIdx % 2 === 0 ? 'tr-column-even-white' : 'tr-column-odd-grey'
	},
	//STYLING
	hover: true,
	bordered: false,
}

export const defaultTableOptions = {
	...tableNoPaginationOptions,
	//PAGINATION OPTIONS
	pagination: true,
	options: paginationOptions,
}

export const tableExportOptions = {
	...defaultTableOptions,
	exportCSV: true,
	options: {
		...paginationExportOptions
	},
}

export const tableAdjustableColOptions = {
	...defaultTableOptions,
	headerContainerClass: 'rosco-table-header custom-table-header-container',
}

export const tableAdjustableColExportOptions = {
	...tableAdjustableColOptions,
	exportCSV: true,
	options: {
		...paginationExportOptions,
	},
}

export const expandTableOptions = {
	...defaultTableOptions,
	expandableRow: () => true,
	expandColumnOptions: {
		expandColumnVisible: true,
		expandColumnComponent: ({ isExpanded }) => <div className={classNames('expand-icon', { 'rotate': isExpanded })}>
			{arrowIcon}
		</div>,
	}
}

export const renderCustomHeader = (num, title) => {

	const documentMouseUp = () => {
		document.removeEventListener('mousemove', splitColumns)
		document.removeEventListener('mouseup', documentMouseUp)
	}

	const splitColumns = (e) => {
		e.preventDefault()

		const tableContainer = $(e.target).closest('.react-bs-table-container');
		const tableHeader = tableContainer.find('.rosco-table-header').find('table');
		const tableBody = tableContainer.find('.rosco-table-body').find('table');
		const tableWidth = tableContainer.width(); //$(".react-bs-table-container" ).width(),
		const colCount = tableBody.find('col:visible').length // $(".rosco-table-body table col").length

		let columnWidthPX;
		let widthColumnBeforePX = 0;
		let n
		if (num !== 0) {
			for (n = 0; n < num; n++) {
				const col = tableHeader.find('th:eq(' + n + ')').width()
				widthColumnBeforePX = widthColumnBeforePX + col;
			}
			columnWidthPX = (e.pageX - (tableContainer.offset().left)) - widthColumnBeforePX;
		}
		else {
			columnWidthPX = e.pageX - (tableContainer.offset().left);
		}

		if (e.pageX < ((tableContainer.offset().left + tableWidth) - 15) && e.pageX > (tableContainer.offset().left) + 15) {
			let widthColumnBeforePercent = 0;
			let k

			for (k = 0; k < num; k++) {
				const col = tableHeader.find('col:eq(' + k + ')').css('width'),
					valueColBefore = parseFloat(col.substring(0, col.length - 1))
				widthColumnBeforePercent = widthColumnBeforePercent + valueColBefore;
			}

			const thisColumnWidthPercent = (columnWidthPX * 100) / tableWidth;
			const next = num + 1;
			const thisNextColumnWidth = tableHeader.find('th:eq(' + num + ')').width() + tableHeader.find('th:eq(' + next + ')').width();
			const thisNextColumnWidthPercent = (thisNextColumnWidth * 100) / tableWidth

			if (thisColumnWidthPercent > 2 && thisColumnWidthPercent < (thisNextColumnWidthPercent - 2)) {
				tableHeader.find('col:eq(' + num + ')').css('width', thisColumnWidthPercent + '%');
				tableHeader.find('col:eq(' + num + ')').css('min-width', thisColumnWidthPercent + '%');

				tableBody.find('col:eq(' + num + ')').css('width', thisColumnWidthPercent + '%');
				tableBody.find('col:eq(' + num + ')').css('min-width', thisColumnWidthPercent + '%');
			}

			let widthColumnAllPercent = 0;
			let i
			for (i = 0; i < colCount; i++) {
				if (i !== next) {
					const col = tableHeader.find('col:eq(' + i + ')').css('width'),
						valueColAll = parseFloat(col.substring(0, col.length - 1))
					widthColumnAllPercent = widthColumnAllPercent + valueColAll;
				}
			}

			const nextColWidth = 100 - widthColumnAllPercent
			tableHeader.find('col:eq(' + next + ')').css('width', nextColWidth + '%');
			tableHeader.find('col:eq(' + next + ')').css('min-width', nextColWidth + '%');

			tableBody.find('col:eq(' + next + ')').css('width', nextColWidth + '%');
			tableBody.find('col:eq(' + next + ')').css('min-width', nextColWidth + '%');
		}
	}


	return (
		<div className="custom-table-header-row" >
			<span className="custom-table-header-title">{title}</span>
			<div
				className="custom-table-header-line"
				id={num}
				onClick={(e) => e.stopPropagation()}
				onMouseDown={(e) => {
					e.preventDefault();

					document.addEventListener('mousemove', splitColumns);
					document.addEventListener('mouseup', documentMouseUp);
				}}
			>
				<div className="th-border first-line"></div>
				<div className="th-border second-line"></div>
			</div>
		</div>
	);
}
