import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Modal } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { apiCall } from '../../../action/RouterActions'
import CustomButton from '../../CustomButton'

const ModalSelectDriver = (props) => {
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'driversPage.modalSelectDriver' });
	const { onHide, drivers, selectedTripId, toggleLoader } = props;

	const [selectedDriverId, setSelectedDriverId] = useState('');

	const fillForm = (event) => {
		const { target } = event;
		const { name } = target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		if (name === "selectedDriverId") setSelectedDriverId(value);
	}

	const Apply = () => {
		if (!selectedDriverId
			|| !selectedTripId
		) {
			toastr.error(t('selectDriver'));
			return
		}

		toggleLoader(true);
		const params = {
			driverId: selectedDriverId,
			trip_id: selectedTripId
		}
		apiCall('POST', '/driver/{driverId}/associate/cardtrip', params)
			.then((res) => {
				toggleLoader(false);
				toastr.success('Success', res.data.response);
				onHide()
			})
			.catch((error) => {
				toggleLoader(false);

				let errDescription = i18n.t('errorMessage.errDescription');
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription);
			})
	}

	return (
		<Modal
			show
			onHide={onHide}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-lg">{t('titleSelectDriver')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div>
					<label className='modal-text'>{t('selectDriverToTrip')}</label>
					<select
						placeholder={t('textSelectDriver')}
						className="rosco-select block"
						name="selectedDriverId"
						onChange={fillForm}
						value={selectedDriverId}
					>
						<option value="">{t('textSelectDriver')}</option>
						{
							drivers.map((item) => item.card_id && <option key={item.id} value={item.id}>{item.name}</option>)
						}
					</select>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<CustomButton variant="primary" onClick={Apply}>{t('btnApply')}</CustomButton>
			</Modal.Footer>
		</Modal>
	)
}

ModalSelectDriver.propTypes = {
	drivers: PropTypes.arrayOf(PropTypes.any).isRequired,
	selectedTripId: PropTypes.number.isRequired,
	onHide: PropTypes.func.isRequired
};

export default connect(
	null,
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(ModalSelectDriver);
