import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { toastr } from 'react-redux-toastr'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import FleetConfigurationModal from '../modals/FleetConfigurationModal'
import CalibrationNeededModal from '../modals/CalibrationNeededModal'
import AddProfileModal from '../modals/AddProfileModal'
import MakePreferredProfileModal from '../modals/MakePreferredProfileModal'
import EditProfileCommentModal from '../modals/EditProfileCommentModal'
import ModalSeeHistory from '../modals/ModalSeeHistory'
import ModalCompareHistory from '../modals/ModalCompareHistory'
import ProfileItem from './ProfileItem'
import SearchBar from '../../dump/menuComponents/SearchBar'
import DeleteProfileModal from '../modals/DeleteProfileModal'
import DeletePreferredProfileModal from '../modals/DeletePreferredProfileModal'
import CustomButton from '../../CustomButton'
import ProfilePreviewPanel from './ProfilePreviewPanel'
import EditProfile from './EditProfile'

import { isInternalUser } from '../../helpers/constants'
import { defaultConfiguration, defaultConfigurationDv6, defaultEventVideoTypes } from '../../helpers/helperConfig'
import { isCustomUserPermissions } from '../../helpers/helperUsers'
import { mergeProfileWithDefault, channelFPSValidation, channelQualityValidation } from '../../helpers/helperProfile'
import { apiCall } from '../../../action/RouterActions'
import { getCompanyProfiles, deleteCompanyProfile, updateCompanyProfile, getCompanyById } from '../../../action/CompaniesActions'

import './fleetConfiguration.sass'
import '../../dump/menuComponents/cloudStorage.sass'

const configurationTypeConversion = (conf) => {
	if (conf.hasOwnProperty('Channels') && conf.Channels.length) {
		conf.Channels.map(item => {
			item.index *= 1
			item.MainFPS *= 1
			item.ParkingFPS *= 1
			item.MainQuality *= 1
			item.ParkingQuality *= 1
			item.Audio *= 1
			item.Record *= 1

			return item
		})
	}
	if (_.has(conf, 'Debug.LcdDisplayMode')) {
		conf.Debug.LcdDisplayMode = conf.Debug.LcdDisplayMode * 1
	}
	if (_.has(conf, 'General.IgnitionShutdownDelay')) {
		conf.General.IgnitionShutdownDelay = conf.General.IgnitionShutdownDelay * 1
	}
	if (_.has(conf, 'Event.Accelerometer.FatalTriggerOutput')) {
		conf.Event.Accelerometer.FatalTriggerOutput = conf.Event.Accelerometer.FatalTriggerOutput * 1
	}
	if (_.has(conf, 'Event.Accelerometer.TriggerOutput')) {
		conf.Event.Accelerometer.TriggerOutput = conf.Event.Accelerometer.TriggerOutput * 1
	}
	if (_.has(conf, 'Event.Accelerometer.Threshold.X')) {
		conf.Event.Accelerometer.Threshold.X = conf.Event.Accelerometer.Threshold.X * 1
	}
	if (_.has(conf, 'Event.Accelerometer.Threshold.Y')) {
		conf.Event.Accelerometer.Threshold.Y = conf.Event.Accelerometer.Threshold.Y * 1
	}
	if (_.has(conf, 'Event.Accelerometer.Threshold.Z')) {
		conf.Event.Accelerometer.Threshold.Z = conf.Event.Accelerometer.Threshold.Z * 1
	}
	if (_.has(conf, 'Event.Accelerometer.FatalThreshold.X')) {
		conf.Event.Accelerometer.FatalThreshold.X = conf.Event.Accelerometer.FatalThreshold.X * 1
	}
	if (_.has(conf, 'Event.Accelerometer.FatalThreshold.Y')) {
		conf.Event.Accelerometer.FatalThreshold.Y = conf.Event.Accelerometer.FatalThreshold.Y * 1
	}
	if (_.has(conf, 'Event.Accelerometer.FatalThreshold.Z')) {
		conf.Event.Accelerometer.FatalThreshold.Z = conf.Event.Accelerometer.FatalThreshold.Z * 1
	}
	if (_.has(conf, 'Event.Accelerometer.ParkingThreshold.X')) {
		conf.Event.Accelerometer.ParkingThreshold.X = conf.Event.Accelerometer.ParkingThreshold.X * 1
	}
	if (_.has(conf, 'Event.Accelerometer.ParkingThreshold.Y')) {
		conf.Event.Accelerometer.ParkingThreshold.Y = conf.Event.Accelerometer.ParkingThreshold.Y * 1
	}
	if (_.has(conf, 'Event.Accelerometer.ParkingThreshold.Z')) {
		conf.Event.Accelerometer.ParkingThreshold.Z = conf.Event.Accelerometer.ParkingThreshold.Z * 1
	}
	if (_.has(conf, 'Event.HighSpeed.Threshold')) {
		conf.Event.HighSpeed.Threshold = conf.Event.HighSpeed.Threshold * 1
	}
	if (_.has(conf, 'Event.HighSpeed.TriggerOutput')) {
		conf.Event.HighSpeed.TriggerOutput = conf.Event.HighSpeed.TriggerOutput * 1
	}
	if (_.has(conf, 'Event.HighTemperature.ShutdownDelay')) {
		conf.Event.HighTemperature.ShutdownDelay = conf.Event.HighTemperature.ShutdownDelay * 1
	}
	if (_.has(conf, 'Event.HighTemperature.Threshold')) {
		conf.Event.HighTemperature.Threshold = conf.Event.HighTemperature.Threshold * 1
	}
	if (_.has(conf, 'Event.Sensor') && conf.Event.Sensor.length) {
		conf.Event.Sensor.map(item => {
			item.index *= 1
			item.TriggerOutput *= 1

			return item
		})
	}
	if (_.has(conf, 'Event.Voltage.HighThreshold')) {
		conf.Event.Voltage.HighThreshold = conf.Event.Voltage.HighThreshold * 1
	}
	if (_.has(conf, 'Event.Voltage.LowThreshold')) {
		conf.Event.Voltage.LowThreshold = conf.Event.Voltage.LowThreshold * 1
	}
	if (_.has(conf, 'Event.Voltage.RecoveryThreshold')) {
		conf.Event.Voltage.RecoveryThreshold = conf.Event.Voltage.RecoveryThreshold * 1
	}
	if (_.has(conf, 'Event.Voltage.ShutdownDelay')) {
		conf.Event.Voltage.ShutdownDelay = conf.Event.Voltage.ShutdownDelay * 1
	}
	if (_.has(conf, 'Event.Adas.SpeedThreshold')) {
		conf.Event.Adas.SpeedThreshold = conf.Event.Adas.SpeedThreshold * 1
	}
	if (_.has(conf, 'Servers.Ads.ShutdownDelay')) {
		conf.Servers.Ads.ShutdownDelay = conf.Servers.Ads.ShutdownDelay * 1
	}
	if (_.has(conf, 'System.BacklightHigh')) {
		conf.System.BacklightHigh = conf.System.BacklightHigh * 1
	}
	if (_.has(conf, 'System.BacklightLow')) {
		conf.System.BacklightLow = conf.System.BacklightLow * 1
	}
	if (_.has(conf, 'System.SpeedUnit')) {
		conf.System.SpeedUnit = conf.System.SpeedUnit * 1
	}

	return conf
}

const FleetConfigurationContainer = (props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'manageVehiclesPage.fleetConfigurationContainer' });
	// console.log('PROPS FleetConfigurationContainer: ', props)
	const { user, company, selectedProfileName, device_id, webfleetDeviceId, toggleLoader } = props;

	const [companyDevices, setCompanyDevices] = useState([])
	const [state, setState] = useState({
		tabKey: 1,
		profiles: [],
		// companyDevices: [],
		profileSearch: '',
		fleet_token: '',
		selectedPreferredProfileId: null, // FWSD-5066, user selects new preferred profile id, when user removes the current  preferred profile
		// configurationInit: _.cloneDeep(defaultConfiguration),
		// configurationdv6Init: _.cloneDeep(defaultConfigurationDv6),
		configurationInit: null,
		configurationdv6Init: null,
		event_typesInit: _.cloneDeep(defaultEventVideoTypes),
		// configuration: _.cloneDeep(defaultConfiguration),
		// configurationdv6: _.cloneDeep(defaultConfigurationDv6),
		configuration: null,
		configurationdv6: null,
		// FWSD-4092, FWSD-4876 - event video types
		// TODO: rename states once the FWSD-4876 done
		event_types: _.cloneDeep(defaultEventVideoTypes),
		advanced_quality_channels: false, // FWSD-7183
		// linked_fps: {dv6_ch1: false, dv6_ch2: false, dv6_ch3: false, dv4_ch1: false, dv4_ch2: false, dv4_ch3: false, dv4_ch4: false }, // FWSD-7201
		linked_fps: {dv6_ch1: false, dv6_ch2: false, dv6_ch3: false }, // FWSD-7201, just for DV6
		errMessage: {},
		showDeletePreferredModal: false,
		showSaveProfileConfirmationModal: false,
		showCalibrationNeededModal: false,
		isNewProfile: false,
		isPushing: false,
		isEditing: false,
		profileDropdownOpen: false,
		companyData: {},
		showAddProfileModal: false,
		showMakePreferredProfileModal: false,
		showEditProfileCommentModal: false,
	})

	const [showFleetConfigurationModal, setShowFleetConfigurationModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [selected_profile, setSelected_profile] = useState(null);
	const [selected_version, setSelected_version] = useState(null);
	const [history, setHistory] = useState(null);
	const [showProfile, setShowProfile] = useState(false);
	const [showModalSeeHistory, setShowModalSeeHistory] = useState(false);
	const [showModalCompareHistory, setShowModalCompareHistory] = useState(false);

	const userRole = user.roles[0];
	const userPermissions = user.permissions;
	const { tabKey, configuration, configurationdv6, showAddProfileModal, showSaveProfileConfirmationModal, showCalibrationNeededModal, showMakePreferredProfileModal, showEditProfileCommentModal, profileSearch, profiles, isNewProfile, isEditing, profileDropdownOpen, errMessage, companyData, isPushing, showDeletePreferredModal, event_types, configurationInit, configurationdv6Init, event_typesInit, advanced_quality_channels, linked_fps } = state
	const isWebfleetMode = webfleetDeviceId.length > 0;
	const isDefaultProfile = selected_profile && selected_profile.name === 'Default';
	const isPreferredProfile = selected_profile && selected_profile.preferred;
	const filteredProfiles = profileSearch ? profiles.filter(profile => profile.name.toLowerCase().includes(profileSearch.toLowerCase())) : profiles;
	const showActions = userRole !== 'sales_demo' && !isNewProfile && isCustomUserPermissions(userRole, userPermissions, 'settings_profile_view');
	// const editOnly = (userRole === 'sales_demo' || (userRole === 'custom_user' && !_.find(userPermissions, { perm_category_id: 4, a_edit: true })));
	// const btnDelete = (userRole === 'sales_demo' || (userRole === 'custom_user' && !_.find(userPermissions, { perm_category_id: 4, a_delete: true })));
	// const btnAdd = (userRole === 'sales_demo' || (userRole === 'custom_user' && !_.find(userPermissions, { perm_category_id: 4, a_add: true })));

	// console.log('STATE FleetConfigurationContainer configurationdv6: ', configurationdv6)

	useEffect(() => {
		// debugger
		getProfiles(selectedProfileName, props.passedCompany)
		if (company && company.company_id) {
			// Reset values when changing company
			setSelected_profile('')
			setState((prevState) => ({ ...prevState, profileSearch: '' }))
			getCompanyById(userRole, company.company_id)
				.then(res => {
					setState((prevState) => {
						return {
							...prevState,
							companyData: res.data.response.company
						}
					})
				})
				.catch(err => {
					console.log(err)
				})
		}
	}, [company.company_id])

	const getProfiles = (argProfileName = null, argCompany = null) => {
		toggleLoader(true);
		const company_name = (argCompany && argCompany.company_name) || company.name || user.company_name
		const partner_name = (argCompany && argCompany.partner_company) || company.partner || user.partner_company_name
		const profileName = argProfileName || selectedProfileName

		getCompanyProfiles({
			user_role: userRole,
			company_name,
			partner_name,
			include_data: 'devices_using,event_types',
		})
			.then((res) => {
				const states = {};
				states.profiles = res.data.response.profiles
				
				if (profileName) {
					const profile = _.find(res.data.response.profiles, item => item.name === profileName)

					if (profile) {
						states.configuration = configurationTypeConversion(JSON.parse(profile.configuration))
						states.configurationdv6 = JSON.parse(profile.configurationdv6)
						states.event_types = { ...defaultEventVideoTypes, ...profile.event_types }
						states.advanced_quality_channels = profile.advanced_quality_channels
						states.linked_fps = detectLinkedFPS(states.configuration, states.configurationdv6)
						setState((prevState) => {
							return {
								...prevState,
								configuration: configurationTypeConversion(JSON.parse(profile.configuration)),
								configurationdv6: JSON.parse(profile.configurationdv6),
								event_types: { ...defaultEventVideoTypes, ...profile.event_types },
								advanced_quality_channels: profile.advanced_quality_channels,
								linked_fps: detectLinkedFPS(states.configuration, states.configurationdv6),
								profileSearch: '',
							}
						});
						setSelected_profile(profile);
						// setShowProfile(true);
					}
					else {
						let defaultProfile = res.data.response.profiles.find(p => p.name === 'Default') || res[0].data.response.profiles[0]
						states.configuration = configurationTypeConversion(JSON.parse(defaultProfile.configuration))
						states.configurationdv6 = JSON.parse(defaultProfile.configurationdv6)
						states.event_types = { ...defaultEventVideoTypes, ...defaultProfile.event_types }
						states.advanced_quality_channels = defaultProfile.advanced_quality_channels
						states.linked_fps = detectLinkedFPS(states.configuration, states.configurationdv6)
						toastr.info(t('toastrProfileNoLongerExists'))
					}
					props.clearSelectedProfile()
				} 
				else {
					let defaultProfile = res.data.response.profiles.find(p => p.name === 'Default') || res.data.response.profiles[0]
					states.configuration = configurationTypeConversion(JSON.parse(defaultProfile.configuration))
					states.configurationdv6 = JSON.parse(defaultProfile.configurationdv6)
					states.event_types = { ...defaultEventVideoTypes, ...defaultProfile.event_types }
					states.advanced_quality_channels = defaultProfile.advanced_quality_channels
					states.linked_fps = detectLinkedFPS(states.configuration, states.configurationdv6)
				}

				states.configurationInit = _.cloneDeep(states.configuration)
				states.configurationdv6Init = _.cloneDeep(states.configurationdv6)
				states.event_typesInit = _.cloneDeep(states.event_types)

				states.profiles.forEach((profile, index) => {
					if (profile.name === 'Default') {
						states.profiles.splice(index, 1)
						states.profiles.unshift(profile)
					}
				})

				setState((prevState) => {
					return {
						...prevState, ...states, profiles: res.data.response.profiles
					}
				});

				toggleLoader(false);
			})
			.catch((error) => {
				toggleLoader(false);
				console.log(error);
				toastr.error(t('toastrErrorGettingProfiles'));
			});
	}

	const detectLinkedFPS = (configurationdv4, configurationdv6) => {
		// FWSD-7201, just for DV6
		return {
			dv6_ch1: configurationdv6?.Channels[0]?.MainFPS === configurationdv6?.Channels[0]?.ParkingFPS || false,
			dv6_ch2: configurationdv6?.Channels[1]?.MainFPS === configurationdv6?.Channels[1]?.ParkingFPS || false,
			dv6_ch3: configurationdv6?.Channels[2]?.MainFPS === configurationdv6?.Channels[2]?.ParkingFPS || false,
			// dv4_ch1: configurationdv4?.Channels[0]?.MainFPS === configurationdv4?.Channels[0]?.ParkingFPS || false,
			// dv4_ch2: configurationdv4?.Channels[1]?.MainFPS === configurationdv4?.Channels[1]?.ParkingFPS || false,
			// dv4_ch3: configurationdv4?.Channels[2]?.MainFPS === configurationdv4?.Channels[2]?.ParkingFPS || false,
			// dv4_ch4: configurationdv4?.Channels[3]?.MainFPS === configurationdv4?.Channels[3]?.ParkingFPS || false,
		}
	}

	const changeProfile = (id) => {
		const profile = _.find(state.profiles, item => (item.id.toString() === id))

		if (profile) {
			// const newConf = configurationTypeConversion({ ...defaultConfiguration, ...JSON.parse(profile.configuration) })
			const newConf = configurationTypeConversion(JSON.parse(profile.configuration))
			// const newConfdv6 = _.merge(defaultConfigurationDv6, JSON.parse(profile.configurationdv6))
			const newConfdv6 = JSON.parse(profile.configurationdv6)
			const mergedConf = _.isEmpty(newConf) ? defaultConfiguration : mergeProfileWithDefault(defaultConfiguration, newConf)
			const mergedConfdv6 = _.isEmpty(newConfdv6) ? defaultConfigurationDv6 :  mergeProfileWithDefault(defaultConfigurationDv6, newConfdv6)
			const eventTypes = { ...defaultEventVideoTypes, ...profile.event_types }
			setState((prevState) => (
				{
					...prevState,
					configurationInit: _.cloneDeep(newConf),
					configurationdv6Init: _.cloneDeep(newConfdv6),
					event_typesInit: _.cloneDeep(eventTypes),
					configuration: mergedConf,
					configurationdv6: mergedConfdv6,
					advanced_quality_channels: profile.advanced_quality_channels,
					linked_fps: detectLinkedFPS(mergedConf, mergedConfdv6),
					event_types: eventTypes,
					// profileSearch: '',
				}
			))
			setSelected_profile(profile)
			// setShowProfile(true);
			window.scrollTo(0, 0)
		} else {
			toastr.info(t('toastrSelectedProfileNotExist'));
		}
	}

	const onClickCompareHistory = (version, history) => {
		setShowModalSeeHistory(false);
		setSelected_version(version);
		setHistory(history);
		setShowModalCompareHistory(true);
	}

	const onClickBack = () => {
		setShowModalSeeHistory(true);
		setShowModalCompareHistory(false);
	}

	const addProfile = (name) => {
		const profile = _.find(profiles, (item) => item.name === (name || 'Default'))
		const parsedConf = configurationTypeConversion(JSON.parse(profile.configuration))
		const parsedConfdv6 = JSON.parse(profile.configurationdv6)
		setState((prevState) => {
			return {
				...prevState,
				// configuration: configurationTypeConversion({ ...defaultConfiguration, ...JSON.parse(profile.configuration) }),
				configuration: parsedConf,
				// configurationdv6: {...defaultConfigurationDv6, ...JSON.parse(profile.configurationdv6)},
				// configurationdv6: _.merge(defaultConfigurationDv6, JSON.parse(profile.configurationdv6)),
				configurationdv6: parsedConfdv6,
				event_types: { ...defaultEventVideoTypes, ...profile.event_types },
				advanced_quality_channels: profile.advanced_quality_channels,
				linked_fps: detectLinkedFPS(parsedConf, parsedConfdv6),
				isNewProfile: true,
				showAddProfileModal: false,
			}
		})
		setSelected_profile(profile);
		setShowProfile(true);
	}

	const setConfiguration = (states) => {
		// debugger
		setState((prevState) => ({ ...prevState, ...states }))
	}

	const goBackSettings = () => {
		setState((prevState) => {
			return {
				...prevState,
				isNewProfile: false,
				tabKey: 1,
				isEditing: false
			}
		});
		setSelected_profile(null);
		setShowProfile(false);
		setState((prevState) => ({ ...prevState, profileSearch: '' }))
	}

	const goBackRedirection = () => {
		if (device_id) {
			device_id === 'all'
				? props.goToManageVehicles()
				: browserHistory.push({ pathname: '/managevehicles/edit', state: { id: device_id, company } })
		}
	}

	// const restoreConfiguration = () => {
	// 	const profile = _.find(profiles, item => (item.id === selected_profile.id))
	// 	if (profile) {
	// 		setState((prevState) => {
	// 			return {
	// 				...prevState,
	// 				configuration: configurationTypeConversion({ ...defaultConfiguration, ...JSON.parse(profile.configuration) }),
	// 				// configurationdv6: {...defaultConfigurationDv6, ...JSON.parse(profile.configurationdv6)},
	// 				configurationdv6: _.merge(defaultConfigurationDv6, JSON.parse(profile.configurationdv6)),
	// 				event_types: { ...defaultEventVideoTypes, ...profile.event_types },
	// 				errMessage: {},
	// 				isEditing: false,
	// 				profileDropdownOpen: false,
	// 			}
	// 		})
	// 	} else {
	// 		toastr.info('The profile you selected does not exist. Please select a different profile.');
	// 	}
	// }

	const fillForm = (e, dv6 = null, argName = null, argValue = null) => {// FWSD-7201, just for DV6
		let { configuration, configurationdv6, linked_fps } = state
		let name = argName || e.target.name
		let value = argValue !== null ? argValue : e.target.value
		const currentConfig = dv6 ? configurationdv6 : configuration

		switch (name) {
			case 'Channels.0.MainFPS':
			case 'Channels.1.MainFPS':
			case 'Channels.2.MainFPS':
			case 'Channels.3.MainFPS':
				// FWSD-7201, just for DV6, while fps linked, the Parking Mode FPS value will mirror the value for Recording Quality FPS
				const channelNumber = name.match(/\d+/)*1 // Output: 0 or 1 or 2 or 3
				const configurationType = dv6 ? '6' : '4'
				value = value * 1

				// If linked then Channels.[XXX].ParkingFPS = Channels.[XXX].MainFPS
				if (linked_fps[`dv${configurationType}_ch${channelNumber + 1}`]) {
					if (dv6) {
						const newConfig = _.set({ ...configurationdv6 }, `Channels.${channelNumber}.ParkingFPS`, value)
						setState((prevState) => ({ ...prevState, configurationdv6: newConfig }))
					}
					else {
						const newConfig = _.set({ ...configuration }, `Channels.${channelNumber}.ParkingFPS`, value)
						setState((prevState) => ({ ...prevState, configuration: newConfig }))
					}
				}

				break
			case 'ParkingMode':
				name = "General.IgnitionShutdownDelay"
				if (e.target.checked) {
					value = 30
				}
				else {
					value = 0
				}
				break
			case 'Channels.0.Audio':
			case 'Channels.1.Audio':
			case 'Channels.2.Audio':
			case 'Channels.3.Audio':
				if (e.target.checked) {
					value = 1
				}
				else {
					value = 0
				}
				break
			case 'Event.HighSpeed.Threshold':
				if (currentConfig.System.SpeedUnit === 1) {
					value = Math.round((value * 1.609344) * 1e2) / 1e2;
				}
				break
		}

		if (dv6) {
			const newConfig = _.set({ ...configurationdv6 }, name, value)
			setState((prevState) => ({ ...prevState, configurationdv6: newConfig }))
		}
		else {
			const newConfig = _.set({ ...configuration }, name, value)
			setState((prevState) => ({ ...prevState, configuration: newConfig }))
		}
	}

	const fillFormBoolean = (e, dv6 = null, argName = null) => {
		const { configurationdv6, configuration } = state
		const name = argName || e.target.name
		const value = e.target.checked

		if (dv6) {
			let newConfig = _.set({ ...configurationdv6 }, name, value)
			setState((prevState) => ({ ...prevState, configurationdv6: newConfig }))
		}
		else {
			let newConfig = _.set({ ...configuration }, name, value)
			setState((prevState) => ({ ...prevState, configuration: newConfig }))
		}
	}

	const fillFormEnableChannel = (e, dv6 = null, argName = null) => {
		const { configurationdv6, configuration } = state
		const name = argName || e.target.name
		const value = e.target.checked
		// FWSD-6666
		const re = /^Channels\.(0|1|2|3)\.Enabled$/i
		const compare = name.match(re) // compare => ['Channels.2.Enabled', '2', index: 0, input: 'Channels.2.Enabled', groups: undefined] 

		if (dv6) {
			let newConfig = _.set({ ...configurationdv6 }, name, value)
			// FWSD-6666
			if (compare) {
				if (value) {
					newConfig = _.set(newConfig, `Channels.${compare[1]}.Substream`, true)
					newConfig = _.set(newConfig, `Channels.${compare[1]}.Record`, 1)
				}
				else {
					newConfig = _.set(newConfig, `Channels.${compare[1]}.Substream`, false)
					newConfig = _.set(newConfig, `Channels.${compare[1]}.Record`, 0)
				}
			}
			setState((prevState) => ({ ...prevState, configurationdv6: newConfig }))
		}
		else {
			let newConfig = _.set({ ...configuration }, name, value)
			// FWSD-6666
			if (compare) {
				if (value) {
					newConfig = _.set(newConfig, `Channels.${compare[1]}.Substream`, true)
					newConfig = _.set(newConfig, `Channels.${compare[1]}.Record`, 1)
				}
				else {
					newConfig = _.set(newConfig, `Channels.${compare[1]}.Substream`, false)
					newConfig = _.set(newConfig, `Channels.${compare[1]}.Record`, 0)
				}
			}
			setState((prevState) => ({ ...prevState, configuration: newConfig }))
		}
	}

	const fillFormInt = (e, dv6 = null, argName = null, argValue = null) => {
		const { configurationdv6, configuration } = state
		const name = argName || e.target.name
		const value = (argValue !== null ? argValue : e.target.value) * 1
		
		if (dv6) {
			const newConfig = _.set({ ...configurationdv6 }, name, value)
			setState((prevState) => ({ ...prevState, configurationdv6: newConfig }))
		}
		else {
			const newConfig = _.set({ ...configuration }, name, value)
			setState((prevState) => ({ ...prevState, configuration: newConfig }))
		}
	}

	const setAdvancedQualityChannels = () => {
		const { configurationdv6, configuration } = state
		// const newChannel = { ...currentConfig.Channels[index] }
		// newChannel.MainFPS = 24
		// fillForm(e, isDV6, `Channels.${index}`, newChannel)

		if (advanced_quality_channels) {
			for (let i in configurationdv6.Channels) {
				configurationdv6.Channels[i].MainFPS = 24
				configurationdv6.Channels[i].MainQuality = 2
				configurationdv6.Channels[i].ParkingFPS = 24
				configurationdv6.Channels[i].ParkingQuality = 1
				
			}

			for (let i in configuration.Channels) {
				configuration.Channels[i].MainFPS = 15
				configuration.Channels[i].MainQuality = 2
				configuration.Channels[i].ParkingFPS = 1
				configuration.Channels[i].ParkingQuality = 1
				
			}

			setState((prevState) => ({ 
				...prevState,
				advanced_quality_channels: false,
				configurationdv6,
				configuration,
				// linked_fps: {dv6_ch1: true, dv6_ch2: true, dv6_ch3: true, dv4_ch1: false, dv4_ch2: false, dv4_ch3: false, dv4_ch4: false } 
				linked_fps: { dv6_ch1: true, dv6_ch2: true, dv6_ch3: true } 
			}))
		}
		else {
			setState((prevState) => ({ ...prevState, advanced_quality_channels: true }))
		}
	}

	const setLinkedFPS = (channelName) => {
		const { configurationdv6, configuration } = state

		const digits = channelName.match(/\d+/g) // Output: ["6", "1"]
		const configurationType = digits[0]
		const channelIndex = digits[1] - 1
		const fpsValue = linked_fps[channelName]
		const newState = { linked_fps: {...linked_fps, [channelName]: !fpsValue} }
		
		if (!fpsValue) {
			if (configurationType === '6') {
				const newConfig = _.set({ ...configurationdv6 }, `Channels.${channelIndex}.ParkingFPS`, configurationdv6.Channels[channelIndex].MainFPS)
				newState.configurationdv6 = newConfig
			}
			else {
				const newConfig = _.set({ ...configuration }, `Channels.${channelIndex}.ParkingFPS`, configurationdv6.Channels[channelIndex].MainFPS)
				newState.configuration = newConfig
			}
		}
		
		// setState((prevState) => ({ ...prevState, linked_fps: {...linked_fps, [channelName]: !linked_fps[channelName]} }))
		setState((prevState) => ({ ...prevState, ...newState }))
	}

	const checkForm = () => {
		let error = false;
		let errMessage = {};

		const getMinSpeedSlider = () => {
			let res = 32
			if (configurationdv6.Event.Adas.Headway.SpeedUnit === 'mph') {
				res = 20
			}
			return res
		}
		const getMaxSpeedSlider = () => {
			let res = 89
			if (configurationdv6.Event.Adas.Headway.SpeedUnit === 'mph') {
				res = 55
			}
			return res
		}
		// const minSpeedValue = getMinSpeedSlider()
		// const maxSpeedValue = getMaxSpeedSlider()

		const minSpeedValue = 32 // kph
		const maxSpeedValue = 89 // kph

		if (!isInternalUser(userRole) && !state.companyData.advanced_profile_settings) {
			for (let i in configuration.Channels) {
				if (configuration.Channels[i].Enabled && [1, 2, 3].indexOf(configuration.Channels[i]['MainQuality']) === -1) {
					error = true;
					if (!errMessage['Channels']) {
						errMessage['Channels'] = [{}, {}, {}, {}];
					}
					errMessage['Channels'][i]['MainFPS'] = t('errSelectChannel');
				}
			}

			if (configuration.Event.Accelerometer.Enabled && [1, 1.1, 1.2, 1.4, 1.5, 2.3, 3, 3.5, 4].indexOf(configuration.Event.Accelerometer.FatalThreshold.X) === -1) {
				error = true;
				errMessage['GSensor'] = t('errSelectValues')
			}
		}
		if (error) {
			setState((prevState) => {
				return {
					...prevState, errMessage, tabKey: 2
				}
			})
			toastr.error(t('toastrSettingsfilledOutCorrectly'));
		}

		// Validation of the Following Distance Time and Minimum Speed values of Headway Monitoring / Tailgating Sensitivity
		if (!error) {
			if (+configurationdv6.Event.Adas.Headway.Ttc.TriggerThreshold < 0.1) {
				error = true
				toastr.error(t('distanceTimeValue<0.1', { value: '"0.1"' }))
			}
			else if (+configurationdv6.Event.Adas.Headway.Ttc.TriggerThreshold > 3) {
				error = true
				toastr.error(t('distanceTimeValue>3', { value: '"3"' }))
			}
			else if (+configurationdv6.Event.Adas.Headway.SpeedThreshold < minSpeedValue) {
				error = true
				toastr.error(`${t('minSpeedLess')} "${getMinSpeedSlider()}"`)
			}
			else if (+configurationdv6.Event.Adas.Headway.SpeedThreshold > maxSpeedValue) {
				error = true
				toastr.error(`${t('minSpeedMore')} "${getMaxSpeedSlider()}"`)
			}

			if (error) {
				setState((prevState) => {
					return {
						...prevState, tabKey: 3
					}
				})
			}
		}

		// Validation of the Channel Recording/Parking Advanced Quality, FWSD-7183
		if (advanced_quality_channels && !error) {
			for (let i in configurationdv6.Channels) {
				if (!error && (channelFPSValidation(configurationdv6.Channels[i].MainFPS) || channelFPSValidation(configurationdv6.Channels[i].ParkingFPS))) {
					error = true;
					toastr.error(`Please enter correct DV6 FPS value of Channel ${+i+1}`)
				}
			}

			for (let i in configurationdv6.Channels) {
				if (!error && (channelQualityValidation(configurationdv6.Channels[i].MainQuality) || channelQualityValidation(configurationdv6.Channels[i].ParkingQuality))) {
					error = true;
					toastr.error(`Please enter correct DV6 Quality value of Channel ${(+i+1)}`)
				}
			}

			for (let i in configuration.Channels) {
				if (!error && (channelFPSValidation(configuration.Channels[i].MainFPS) || channelFPSValidation(configuration.Channels[i].ParkingFPS))) {
					error = true;
					toastr.error(`Please enter correct DV4 FPS value of Channel ${(+i+1)}`)
				}
			}

			for (let i in configuration.Channels) {
				if (!error && (channelQualityValidation(configuration.Channels[i].MainQuality) || channelQualityValidation(configuration.Channels[i].ParkingQuality))) {
					error = true;
					toastr.error(`Please enter correct DV4 Quality value of Channel ${(+i+1)}`)
				}
			}

			if (error) {
				setState((prevState) => ({ ...prevState, tabKey: 2 }))
			}
		}

		return error;
	}

	const openDeleteModal = () => {
		if (selected_profile && selected_profile.name === 'Default') {
			toastr.error(t('errDeleteDefaultProfile'))
		}
		else if (isPreferredProfile) {
			setState((prevState) => {
				return {
					...prevState, showDeletePreferredModal: true, selectedPreferredProfileId: null
				}
			});
		}
		else {
			setShowDeleteModal(true)
			setState((prevState) => {
				return {
					...prevState, profileDropdownOpen: false
				}
			});
		}
	}

	const deleteCompanyProfileFunc = (vehicle_ids = null, newProfileId = null) => {
		const { selectedPreferredProfileId } = state;
		toggleLoader(true)
		setShowDeleteModal(false);
		const requestArray = [];
		requestArray.push(
			deleteCompanyProfile({
				user_role: userRole,
				partner_name: company.partner,
				company_name: company.name,
				profile_id: selected_profile.id,
				preferred_profile_id: selectedPreferredProfileId,
			})
		)
		if (newProfileId && vehicle_ids && vehicle_ids.length) {
			requestArray.push(apiCall('POST', '/profile/{profile_id}/devices/config', {
				profile_id: newProfileId,
				device_ids: vehicle_ids.join(','),
				restart: true,
			}))
		}
		Promise.all(requestArray)
			.then(res => {
				toggleLoader(false);
				toastr.success(t('toastrDeletedProfile', { name: `"${selected_profile.name}"${newProfileId ? ', and applied new profile to vehicles' : ''}` }));
				setState((prevState) => {
					return {
						...prevState, selectedPreferredProfileId: null
					}
				})
				setSelected_profile(null);
				setShowProfile(false);
				getProfiles();
			})
			.catch(err => {
				toggleLoader(false);
				toastr.error(t('toastrUnableDeleteProfile'));
			});
	}

	const deletePreferredCompanyProfile = (newPreferredProfileId) => {
		setShowDeleteModal(true);
		setState((prevState) => {
			return {
				...prevState,
				showDeletePreferredModal: false,
				selectedPreferredProfileId: newPreferredProfileId,
			}
		})
	}

	const openSaveProfileConfirmationModal = () => {
		setState((prevState) => {
			return {
				...prevState, showSaveProfileConfirmationModal: true, profileDropdownOpen: false
			}
		});

		window.scrollTo(0, 0);
	}

	const openFleetConfigurationModal = () => {
		const error = checkForm();
		if (!error) {
			setShowFleetConfigurationModal(true);
		}
		// else {
		// 	toastr.error('Some settings have not been filled out correctly. Resolve them to save this profile.');
		// 	setState({ ...state, tabKey: 2 })
		// }
		// setState({ ...state, profileDropdownOpen: false })

		window.scrollTo(0, 0);
	}

	const openCalibrationNeededModal = () => {
		const error = checkForm();
		if (!error) {
			setState((prevState) => {
				return {
					...prevState, showCalibrationNeededModal: true
				}
			})
		}

		window.scrollTo(0, 0);
	}

	const saveProfile = (vehicle_ids, profileName = null) => {
		const { configuration, configurationdv6, event_types, advanced_quality_channels } = state;

		toggleLoader(true);
		const company_name = company.name || user.company_name;
		const partner_name = company.partner || user.partner_company_name;

		//SAVE PROFILE SETTINGS
		updateCompanyProfile({
			user_role: userRole,
			company_name,
			partner_name,
			name: profileName || selected_profile.name,
			configuration: JSON.stringify(configuration),
			configurationdv6: JSON.stringify(configurationdv6),
			event_types,
			advanced_quality_channels,
		})
			.then(res => {
				toastr.success(t('toastrSavedProfile'));
				setShowFleetConfigurationModal(false);
				//APPLY PROFILE TO VEHICLES
				vehicle_ids.length && pushProfile(res.data.response.profile.id, vehicle_ids)
				getProfiles(res.data.response.profile.name);
			})
			.catch(err => {
				toggleLoader(false);
				toastr.error(t('toastrUnableSaveProfile'))
			});
	}

	const pushProfile = (profile_id, vehicle_ids) => {
		toggleLoader(true);
		const params = {
			profile_id: profile_id,
			device_ids: vehicle_ids.join(','),
			restart: true,
		}
		apiCall('POST', '/profile/{profile_id}/devices/config', params)
			.then(res => {
				toggleLoader(false)
				toastr.success(t('toastrPushedProfile'));
			})
			.catch((error) => {
				toggleLoader(false)
				console.log('!updateProfile error:', error.response, error)
				toastr.error(t('toastrUnablePushProfile'));
			})
	}

	const openMakePreferredProfileModal = () => {
		setState((prevState) => {
			return {
				...prevState, showMakePreferredProfileModal: true
			}
		});
	}

	const closeMakePreferredProfileModal = () => {
		setState((prevState) => {
			return {
				...prevState, showMakePreferredProfileModal: false
			}
		});
	}

	const saveProfileComment = (comments) => {
		const { profiles } = state;

		toggleLoader(true);
		const params = {
			profile_id: selected_profile.id,
			comments
		}

		apiCall('POST', '/profiles/{profile_id}', params)
			.then((res) => {
				console.log('!saveProfileComment res: ', res)
				toggleLoader(false)

				const newProfiles = profiles.map((item) => {
					if (item.id === selected_profile.id) {
						item.comments = comments
					}
					return item
				})

				setState((prevState) => {
					return {
						...prevState,
						profiles: newProfiles,
						showEditProfileCommentModal: false,
					}
				});
				// setSelected_profile(null);

				toastr.success(t('toastrSavedProfile'));
			})
			.catch((error) => {
				toggleLoader(false)
				console.log('!saveProfileComment error: ', error.response, error)
				toastr.error(t('toastrUnableSaveProfile'))
			})
	}

	const setProfilePreferred = () => {
		const { profiles } = state;

		toggleLoader(true);
		const params = {
			profile_id: selected_profile.id,
			preferred: true,
		}

		apiCall('POST', '/profiles/{profile_id}', params)
			.then((res) => {
				console.log('!setProfilePreferred res: ', res)
				toggleLoader(false)

				const newProfiles = profiles.map((item) => {
					if (item.id === selected_profile.id) {
						item.preferred = true
					}
					else {
						item.preferred = false
					}
					return item
				})

				setState((prevState) => {
					return {
						...prevState,
						profiles: newProfiles,
						showMakePreferredProfileModal: false,
					}
				});
				setSelected_profile({ ...selected_profile, preferred: true });

				toastr.success(t('toastrSavedProfile'));
			})
			.catch((error) => {
				toggleLoader(false)
				console.log('!setProfilePreferred error: ', error.response, error)
				toastr.error(t('toastrUnableSaveProfile'))
			})
	}

	const hideCalibrationNeededModal = () => {
		setState((prevState) => {
			return {
				...prevState, showCalibrationNeededModal: false, isPushing: false
			}
		})
	}

	const onSaveClick = () => {
		if (isDefaultProfile) {
			openSaveProfileConfirmationModal()
		}
		else {
			// gets full list of campany devices to get devices with installed current ptrofile
			toggleLoader(true)
			apiCall('GET', '/companies/{company_id}/devices/list', { company_id: company.company_id })
				.then((resDev) => {
					toggleLoader(false)
					const { devices } = resDev.data.response
					// setState({ ...state, companyDevices: devices })
					setCompanyDevices(devices)

					// FWSD-5968, when a user attempts to enable DV6 ADAS in an event profile with devices that are not calibrated will see the Calibration Needed modal.
					if (
						configurationdv6.Event.Adas.LaneDeparture.Enabled ||
						configurationdv6.Event.Adas.ForwardCollision.Enabled ||
						configurationdv6.Event.Adas.Headway.Enabled ||
						configurationdv6.Event.Adas.FollowingDistance.Enabled
					) {
						// gets list of uncalibrated devices with installed current profile. If vehicle width and camera height are == 0, then the camera can be assumed to be not calibrated.
						// TODO: we have to compare the profil's unique id instead of name
						const uncalibratedDevices = devices.filter(item => item.device_type === 'dv6' && item.uploaded_company_profile === selected_profile.name && (!item.adas_camera_height || !item.adas_vehicle_width))
						if (!!uncalibratedDevices.length) {
							openCalibrationNeededModal()
							// setState({ ...state, showCalibrationNeededModal: true })
						}
						else {
							openFleetConfigurationModal()
						}
					}
					else {
						openFleetConfigurationModal()
					}
				})
				.catch(errDev => {
					toggleLoader(false)
					toastr.error(t('toastrUnableGetCompanyDevices'))
				})
		}
	}

	const event_typesDV6 = Object.keys(event_types).reduce((result, key) => {
		if (key.startsWith('dv6')) {
			result[key] = event_types[key]
		}
		return result
	}, {})
	const event_typesDV4 = Object.keys(event_types).reduce((result, key) => {
		if (key.startsWith('dv4')) {
			result[key] = event_types[key]
		}
		return result
	}, {})
	const event_typesDV6Init = Object.keys(event_typesInit).reduce((result, key) => {
		if (key.startsWith('dv6')) {
			result[key] = event_typesInit[key]
		}
		return result
	}, {})
	const event_typesDV4Init = Object.keys(event_typesInit).reduce((result, key) => {
		if (key.startsWith('dv4')) {
			result[key] = event_typesInit[key]
		}
		return result
	}, {})

	// console.log('STATE FleetConfigurationContainer: ', state)
	// console.log('STATE FleetConfigurationContainer tabKey: ', tabKey)

	return (
		<div className="fleet-profiles">
			{showProfile
				? (
					<EditProfile
						user={user}
						selected_profile={selected_profile}
						// configuration={_.isEmpty(configuration) ? defaultConfiguration : mergeProfileWithDefault(defaultConfiguration, configuration)}
						// configurationdv6={_.isEmpty(configurationdv6) ? defaultConfigurationDv6 :  mergeProfileWithDefault(defaultConfigurationDv6, configurationdv6)}
						configuration={configuration}
						configurationdv6={configurationdv6}
						event_types={event_types}
						advanced_quality_channels={advanced_quality_channels}
						linked_fps={linked_fps}
						isNewProfile={isNewProfile}
						// isProfileUpdated={!_.isEqual(configuration, configurationInit) || !_.isEqual(configurationdv6, configurationdv6Init) || !_.isEqual(event_types, event_typesInit)}
						isDV6ProfileUpdated={!_.isEqual(configurationdv6, configurationdv6Init) || !_.isEqual(event_typesDV6, event_typesDV6Init)}
						isDV4ProfileUpdated={!_.isEqual(configuration, configurationInit) || !_.isEqual(event_typesDV4, event_typesDV4Init)}
						setConfiguration={setConfiguration}
						fillFormBoolean={fillFormBoolean}
						fillFormInt={fillFormInt}
						fillForm={fillForm}
						fillFormEnableChannel={fillFormEnableChannel}
						setAdvancedQualityChannels={setAdvancedQualityChannels}
						setLinkedFPS={setLinkedFPS}
						onSaveClick={onSaveClick}
						goBack={goBackSettings}
						openFleetConfigurationModal={openFleetConfigurationModal}
						tabKey={tabKey}
						setTabKey = {(argKey) => setState((prevState) => ({ ...prevState, tabKey: argKey}))}
					/>
				)
				: (
					<>
						<div className="page-subheader">
							<div className={`subheader-section search${isWebfleetMode ? ' w-50' : ''}`}>
								<SearchBar term={profileSearch} onSearch={(e) => setState((prevState) => ({ ...prevState, profileSearch: e.target.value }))} />
							</div>

							{device_id &&
								<CustomButton variant="secondary-outline" onClick={goBackRedirection}>
									{t('btnBack')}
								</CustomButton>
							}
						</div>

						<main className={`panels-wrapper${isWebfleetMode ? ' block' : ''}`}>
							{/* {!isWebfleetMode && <h3 className="category-title">Setting Profiles</h3>} */}
							<div className="setting-profiles-panel main-content-block">
								{!isWebfleetMode && (
									<aside className="asset-description">
										<h3 className="category-title">
											{t('titleSettingProfiles')}
										</h3>
										<p>
											{t('textSettingProfiles')} <strong>{t('textAddNewProfile')}</strong>
										</p>
									</aside>
								)}

								{isCustomUserPermissions(userRole, userPermissions, 'settings_profile_add') &&
									<div className="add-asset-block">
										<CustomButton variant='secondary-outline' onClick={() => setState((prevState) => {
											return {
												...prevState, showAddProfileModal: true
											}
										})}>
											{t('btnAddNewProfile')}
										</CustomButton>
									</div>
								}

								{/* Displays list of company profiles */}
								<div className="list-wrapper">
									{filteredProfiles.map(profile => (
										<ProfileItem
											key={profile.id}
											onClickProfileItem={() => changeProfile(profile.id.toString())}
											profile={profile}
											userRole={userRole}
											withArrow={true}
											isActive={selected_profile && selected_profile.id === profile.id}
										/>
									))}
								</div>
							</div>

							{/* Displays data of selected profile in right Preview Panel */}
							<ProfilePreviewPanel
								user={user}
								selected_profile={selected_profile}
								configuration={configurationInit}
								configurationdv6={configurationdv6Init}
								webfleetDeviceId={webfleetDeviceId}
								showActions={showActions}
								saveProfileComment={saveProfileComment}
								onClickSeeHistory={() => setShowModalSeeHistory(true)}
								openMakePreferredProfileModal={openMakePreferredProfileModal}
								openDeleteModal={openDeleteModal}
								openFleetConfigurationModal={openFleetConfigurationModal}
								onEditProfile={() => { setState((prevState) => ({ ...prevState, isEditing: true })); setShowProfile(true); }}
								setConfiguration={setConfiguration}
							/>
						</main>

						{showAddProfileModal
							&& (
								<AddProfileModal
									profiles={profiles}
									addProfile={addProfile}
									onHide={() => setState((prevState) => {
										return {
											...prevState, showAddProfileModal: false
										}
									})}
								/>
							)}

						{showEditProfileCommentModal
							&& (
								<EditProfileCommentModal
									profile={selected_profile}
									onHide={() => setState((prevState) => {
										return {
											...prevState, showEditProfileCommentModal: false
										}
									})}
									onSave={saveProfileComment}
								/>
							)}

						{showDeleteModal
							&& (
								<DeleteProfileModal
									profiles={profiles.filter(({ name }) => name !== selected_profile.name)}
									profile={selected_profile}
									onHide={() => setShowDeleteModal(false)}
									deleteProfile={deleteCompanyProfileFunc}
								/>
							)}

						{showDeletePreferredModal
							&& (
								<DeletePreferredProfileModal
									profiles={profiles.filter(({ name }) => name !== selected_profile.name)}
									profile={selected_profile}
									onHide={() => setState((prevState) => {
										return {
											...prevState, showDeletePreferredModal: false
										}
									})}
									deleteProfile={deletePreferredCompanyProfile}
								/>
							)}

						{showMakePreferredProfileModal
							&& (
								<MakePreferredProfileModal
									proferredProfile={profiles.find(({ preferred }) => preferred)}
									profile={selected_profile}
									onHide={closeMakePreferredProfileModal}
									onSave={setProfilePreferred}
								/>
							)}
						{showModalSeeHistory
							&& (
								<ModalSeeHistory
									profile={selected_profile}
									onClickCompareHistory={onClickCompareHistory}
									onHide={() => setShowModalSeeHistory(false)}
								/>
							)}
						{showModalCompareHistory
							&& (
								<ModalCompareHistory
									profile={selected_profile}
									version={selected_version}
									history={history}
									onClickBack={onClickBack}
									onHide={() => setShowModalCompareHistory(false)}
								/>
							)}
					</>
				)
			}

			{showCalibrationNeededModal && (
				<CalibrationNeededModal
					profile={selected_profile}
					// devices={[]}
					devices={companyDevices}
					onHide={hideCalibrationNeededModal}
					onContinue={onSaveClick}
				/>
			)}

			{showFleetConfigurationModal && (
				<FleetConfigurationModal
					profiles={profiles}
					profile={selected_profile}
					configurationdv6={configurationdv6}
					action={isNewProfile ? 'Add' : (isPushing ? 'Push' : 'Update')}
					isNewProfile={isNewProfile}
					isDV6ProfileUpdated={!_.isEqual(configurationdv6, configurationdv6Init) || !_.isEqual(event_typesDV6, event_typesDV6Init)}
					isDV4ProfileUpdated={!_.isEqual(configuration, configurationInit) || !_.isEqual(event_typesDV4, event_typesDV4Init)}
					onHide={() => {
						setShowFleetConfigurationModal(false);
						setState((prevState) => {
							return {
								...prevState, isPushing: false
							}
						});
					}}
					saveProfile={saveProfile}
					pushProfile={pushProfile}
				/>
			)}
		</div>
	)
}

FleetConfigurationContainer.propTypes = {
	webfleetDeviceId: PropTypes.string,
}

FleetConfigurationContainer.defaultProps = {
	webfleetDeviceId: '',
}

const mapStateToProps = ({ user, company, webfleetData }) => ({
	user: user.user,
	company: company.company,
	webfleetDeviceId: webfleetData.deviceId,
})

const mapDispatchToProps = dispatch => ({
	toggleLoader: (show) => {
		dispatch({ type: 'TOGGLE_LOADER', payload: show });
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(FleetConfigurationContainer);
