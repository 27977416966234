import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import ReportsChartMain from '../../smart/reportsComponents/ReportsChartMain';
import ReportsContainer from '../../smart/reportsComponents/ReportsContainer';
import CustomReports from '../../smart/reportsComponents/CustomReports';
import '../../../../../../node_modules/react-bootstrap-table/css/react-bootstrap-table.css'
import GreyHeader from '../../GreyHeader'
import CustomButton from '../../CustomButton';
import { sendGA } from '../../helpers/helperUsers'

const Reports = (props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'components.reports' });
	const { user } = props
	const userRole = user.roles[0]

	const [location, setLocation] = useState(null);
	const [tabKey, setTabKey] = useState(1);
	const [showReport, setShowReport] = useState(false);
	const [selectedReport, setSelectedReport] = useState({});

	useEffect(() => {
		const { location } = props;

		let locationNew = null;
		if (location && location.state && location.state.type === 'device_audit_log') {
			locationNew = {
				type: location.state.type,
				device_id: location.state.device_id
			}

			setTabKey(4);
			setLocation(locationNew);
			browserHistory.replace({ pathname: '/reports', state: {} });
		}
	}, [])

	const handleNavigation = (tabKey, report = null) => {
		if (report) {
			setSelectedReport(report)
		}
		else {
			setShowReport(false)
			setSelectedReport({})
		}
		setTabKey(tabKey)

		const label = tabKey === 1 ? 'Standard' : tabKey === 2 ? 'Custom' : 'Charts'
		sendGA({ category: userRole, action: 'Change Reports Tab', label })
	}

	const handleShowReport = () => {
		setShowReport(true)
	}

	return (
		<>
			<GreyHeader
				title='Reports'
				renderSubtitle={
					<nav className='page-subtitle'>
						<CustomButton variant='subtitle' active={tabKey === 1} onClick={() => handleNavigation(1)}>
						{t('btnStandard')}
						</CustomButton>
						<CustomButton variant='subtitle' active={tabKey === 2} onClick={() => handleNavigation(2)}>
						{t('btnCustom')}
						</CustomButton>
						<CustomButton variant='subtitle' active={tabKey === 3} onClick={() => handleNavigation(3)}>
						{t('btnCharts')}
						</CustomButton>
					</nav>
				}
			/>

			{tabKey === 1 && <ReportsContainer handleNavigation={handleNavigation} handleShowReport={handleShowReport} showReport={showReport} selectedReport={selectedReport} />}
			{tabKey === 2 && <CustomReports handleNavigation={handleNavigation} handleShowReport={handleShowReport} showReport={showReport} selectedReport={selectedReport} />}
			{tabKey === 3 && <ReportsChartMain />}
		</>
	)
}

Reports.propTypes = {
	user: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = ({ user }) => ({
	user: user.user
});

export default connect(mapStateToProps)(Reports);
