// FWSD-7380
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import ModalDelete from '../../component/smart/modals/ModalDelete'

const NewMapFeedback = (props) => {
	// console.log('PROPS NewMapFeedback: ', props)
	const { t } = useTranslation('translation', { keyPrefix: 'homePage.newMapFeedback' });
	const { showNewMapFeedbackModal, setShowNewMapFeedbackModal } = props

	// Check if 7 days have passed from HERE map enabling
	useEffect(() => {
		let interval
		let feedback = false
		const rsNewMap = localStorage.getItem("rsNewMap")

		const checkPopupCondition = () => {
			// console.log('!!! Dashboard checkPopupCondition')
			const rsNewMap = localStorage.getItem("rsNewMap")
			if (rsNewMap) {
				const { start, feedback } = JSON.parse(rsNewMap)
				const currentTime = Date.now()
				const elapsedTime = currentTime - start
				// 7 days in milliseconds: 7 * 24 * 60 * 60 * 1000
				if (!feedback && elapsedTime >= 7 * 24 * 60 * 60 * 1000) {
					setShowNewMapFeedbackModal(true) // Show the New Map Feedback modal
				}

				if (feedback) {
					clearInterval(interval)
				}
			}
		}

		if (!feedback && rsNewMap) {
			// Initial check
			checkPopupCondition()
			// Check periodically (e.g., every hour)
			interval = setInterval(checkPopupCondition, 60 * 60 * 1000)
		}

		return () => clearInterval(interval)
	}, []);

	return (
		<>
			{showNewMapFeedbackModal &&
				<ModalDelete
					title={t('title')}
					content={t('content')}
					styleView="primary"
					backdropClassName="upper"
					// closeButton={false}
					showClose
					deleteBtnName={t('deleteBtnName')}
					closeBtnName={t('closeBtnName')}
					closeModal={() => {
						const rsNewMap = localStorage.getItem("rsNewMap")
						if (rsNewMap) {
							const { start, feedback } = JSON.parse(rsNewMap)
							const updatedData = { start, feedback: true }
							localStorage.setItem("rsNewMap", JSON.stringify(updatedData))
						}
						setShowNewMapFeedbackModal(false)
					}}
					deleteModal={() => {
						const rsNewMap = localStorage.getItem("rsNewMap")
						if (rsNewMap) {
							const { start, feedback } = JSON.parse(rsNewMap)
							const updatedData = { start, feedback: true }
							localStorage.setItem("rsNewMap", JSON.stringify(updatedData))
						}
						setShowNewMapFeedbackModal(false)

						const url = 'https://forms.office.com/pages/responsepage.aspx?id=IPxEPf4gkE-Ty8YbJDVv0yxYP3yBpSFPtjdwyBnrYANUNkNTUFhIMTJDWjNPU0tKWDBFMlBVNFRTVy4u&route=shorturl'
						window.open(url, '_blank', 'noopener,noreferrer')
					}}
				/>
			}
		</>
	)
}

// CategoryItem.propTypes = {
// 	text: PropTypes.string.isRequired,
// 	withArrow: PropTypes.bool,
// 	onClick: PropTypes.func,
// }

// CategoryItem.defaultProps = {
// 	withArrow: false,
// };

const mapStateToProps = ({ dashboardData }) => ({
	showNewMapFeedbackModal: dashboardData.showNewMapFeedbackModal,
});

const mapDispatchToProps = dispatch => ({
	setShowNewMapFeedbackModal: (state) => {
		dispatch({ type: 'SET_SHOW_NEW_MAP_FEEDBACK_MODAL', payload: state })
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(NewMapFeedback);

// export default NewMapFeedback