// FWSD-5329
import React from 'react'
import PropTypes from 'prop-types'
import Toggle from 'react-toggle'
import { useTranslation } from 'react-i18next'
import IconTooltip from '../../component/dump/menuComponents/IconTooltip'

const CameraEnableToggle = (props) => {
	const { isActive, onChange, addClass } = props;
	const { t } = useTranslation('translation', { keyPrefix: 'manageVehiclesPage.cameraEnableToggle' });
	return (
		<div className={`toggle-wrapper camera-toggle ${addClass}`}>
			<div className="device-label">
				{t('labelCamera')}
				<IconTooltip
					description={t('description')}
					id="disable-vehicle-tooltip"
				/>
			</div>
			<label>
				<span className="toggle-left-title">{isActive ? t('textEnabled') : t('textDisabled')}</span>
				<Toggle
					name="active"
					icons={false}
					checked={isActive || false}
					onChange={onChange}
				/>
			</label>
		</div>
	)
}

CameraEnableToggle.propTypes = {
	isActive: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
	addClass: PropTypes.string,
}

CameraEnableToggle.defaultProps = {
	addClass: '',
}

export default CameraEnableToggle;
