import React from 'react';
import { Button, Popover, OverlayTrigger } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next'

import { addAuditLogFunc } from '../helpers/helperAuditLog';

const OverlayTriggerNode = (props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'components.overlayTriggerNode' });

	const closePopover = (e) => {
		e.preventDefault();
		document.getElementById("advanced-player-mp4videos").style.display = "none";
	};

	const downloadMP4 = (e, index) => {
		e.preventDefault();
		const { nvrUrl, videoUrls } = props;

		if (nvrUrl || videoUrls) {
			// window.location.href = video_urls[index];
			const re = RegExp(`${index}.mp4`, 'i')
			videoUrls.forEach(item => {
				if (item.search(re) > -1) {
					// addAuditLogFunc('download_clip', { downloaded: item })
					// window.location.href = item
					saveAs(item)
				}
			})
		}
	};

	const saveAs = (url) => {
		const filename = url.substring(url.lastIndexOf('/') + 1).split('?')[0]
		// fetch(url, { mode: 'no-cors' })
		fetch(url)
			.then(response => response.blob())
			.then((blob) => {
				const blobUrl = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = blobUrl;
				a.download = filename;
				document.body.appendChild(a);
				a.click();
				a.remove();
			})
			.catch(e => console.error(e));

		// var filename = url.substring(url.lastIndexOf("/") + 1).split("?")[0];
		// var xhr = new XMLHttpRequest();
		// xhr.responseType = 'blob';
		// xhr.onload = function() {
		// 	const a = document.createElement('a');
		// 	a.href = window.URL.createObjectURL(xhr.response);
		// 	a.download = filename; 
		// 	a.style.display = 'none';
		// 	document.body.appendChild(a);
		// 	a.click();
		//   	a.remove();
		// }
		// xhr.open('GET', url);
		// xhr.send();
	}

	// console.log('RENDER OverlayTriggerNode')
	const { videoUrls } = props;

	const clickMP4Videos = (
		<Popover id="advanced-player-mp4videos">
			<h3 className="popover-title">{t('titleDownloadVideo')}</h3>
			<button type="button" variant="close" className="close-btn" onClick={closePopover}>
				<span aria-hidden="true">×</span><span className="sr-only">{t('btnClose')}</span>
			</button>
			<div className="popover-content">
				{videoUrls.map((elem) => {
					const re = RegExp('(\\d{1}).mp4', 'i')
					const arr = elem.match(re)
					// console.log('elem', elem, arr)
					if (arr && arr[1]) {
						const index = arr[1]
						return (
							<a
								key={index}
								href="/"
								onClick={(e) => downloadMP4(e, index)}
							>
								{`${t('Channel')} ${index}`}
							</a>
						)
					}

					return '';
				})
				}
			</div>
		</Popover>
	);

	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="bottom"
			overlay={clickMP4Videos}
			delayHide={100}
		>
			<Button variant="empty" title={t('buttonTextDownloadMP4')} className='video-player-button'>
				<svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" fill="currentColor" className="bi bi-cloud-arrow-down" viewBox="0 0 16 16">
					<path fillRule="evenodd" d="M7.646 10.854a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 9.293V5.5a.5.5 0 0 0-1 0v3.793L6.354 8.146a.5.5 0 1 0-.708.708l2 2z" />
					<path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
				</svg>
				<span className='download-button-text'>{t('buttonTextDownloadMP4')}</span>
			</Button>
		</OverlayTrigger>
	)
};

OverlayTriggerNode.propTypes = {
	nvrUrl: PropTypes.string.isRequired,
	videoUrls: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default OverlayTriggerNode;
