import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import { Button, Dropdown, Image } from 'react-bootstrap'
import Toggle from 'react-toggle'
import _ from 'lodash'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import AdasCalibrationSettings from '../../../pages/vehicles/AdasCalibrationSettings'
import ModalChangeConfBatch from '../modals/ModalChangeConfBatch'
import { isDv6, isInternalUser, isInternalUserWithPartner, constants } from '../../helpers/constants'
import { vehicleIcons } from '../../helpers/helperVehicle'
import IconTooltip from '../../dump/menuComponents/IconTooltip'
import CustomButton from '../../CustomButton'

const EditAssetDeviceSettings = (props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'manageVehiclesPage.editAssetContainer.editAssetDeviceSettings' });
	const [iconDropdownOpen, setIconDropdownOpen] = useState(false);
	const [showModalApplyProfile, setShowModalApplyProfile] = useState(false);

	const onGroupsChange = (event) => {
		const { device, setPropStates } = props

		setPropStates({ data: { ...device, groups: [event.target.value] } })
	}

	const onInputChange = (event, name = null) => {
		const { device, setPropStates } = props
		const { target } = event;
		const field = target.name || name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		setPropStates({ data: { ...device, [field]: value } })
	}

	const changeVehicleIcon = (icon) => {
		const { device, setPropStates } = props
		setIconDropdownOpen(false)
		setPropStates({ data: { ...device, vehicle_icon: icon } })
	}

	// console.log('PROPS EditAssetDeviceSettings: ', props)
	const { initialData, device, groupsList, user, company, showModalInstallReport, setPropStates, errMessage } = props
	const { device_id, config_checksum, pending_company_profile, uploaded_company_profile, activated_timestamp, groups, pending_config, canbus, data_limit, vehicle_icon, contract_id, partner_name, company_name, active, cardreader } = device
	const { errgroups, errdata_limit } = errMessage

	const userRole = user.roles[0]
	const readOnly = (['user', 'installer', 'partner_view', 'storage_user', 'sales_demo'].indexOf(userRole) > -1
		|| (['custom_user', 'custom_partner'].includes(userRole) && !_.find(user.permissions, { perm_category_id: 5, a_edit: true })))
	const canViewProfile = ['system_admin', 'customer_service', 'fleet_manager', 'fleet_maintainer', 'partner', 'storage_manager'].includes(userRole)
		|| (['custom_user', 'custom_partner'].includes(userRole) && !_.find(user.permissions, { perm_category_id: 3, a_view: true }))
	const hasDiffPendingCanbus = pending_config && _.has(pending_config, 'canbus') && pending_config.canbus !== canbus
	const hasDiffPendingCardreader = pending_config && _.has(pending_config, 'cardreader') && pending_config.cardreader !== cardreader

	const displayDeviceProfileName = () => {
		const hasPendingProfile = canViewProfile && config_checksum === 1 && pending_company_profile && pending_company_profile.toLowerCase() !== 'manual'
		const hasUploadedProfile = canViewProfile && uploaded_company_profile && uploaded_company_profile.toLowerCase() !== 'manual'

		if (hasPendingProfile) {
			return (
				<span style={{ fontSize: '18px', display: 'inline-block', verticalAlign: 'bottom' }}>
					<span style={{ marginRight: '5px' }}>
						{`${t('pendingUpdateTo')} ${pending_company_profile}`}
					</span>
					{/* <span style={{ marginRight: '5px' }}>
							Pending update to
						</span>
					<CustomButton
						prefix="uploaded_company_profile"
						variant="link"
						onClick={() => browserHistory.push({
							pathname: '/managevehicles',
							state: { profile_name: pending_company_profile, tabKey: 2, device_id }
						})}
					>
						{pending_company_profile}
					</CustomButton> */}
				</span>
			)
		}

		if (hasUploadedProfile) {
			// return (
			// 	<CustomButton
			// 		prefix="uploaded_company_profile"
			// 		variant="link"
			// 		onClick={() => browserHistory.push({
			// 			pathname: '/managevehicles',
			// 			state: { profile_name: uploaded_company_profile, tabKey: 2, device_id }
			// 		})}
			// 	>
			// 		{uploaded_company_profile}
			// 	</CustomButton>
			// )
		}

		return <label className="control-label">{uploaded_company_profile || pending_company_profile || t('Manual')}</label>
	}

	const saveAdasCalibrationSettings = (argDevice) => {
		setPropStates({ data: { ...device, ...argDevice } })
	}

	return (
		<section>
			{/** DEVICE SETTINGS */}
			<section>
				<h3 className="category-title" style={{ marginTop: '0.375em' }}>{t('titleDeviceSettings')}</h3>
				<div className="profile-wrapper device-grid">
					<label className="control-label">{t('currentSettingsProfile')}</label>
					{displayDeviceProfileName()}
					{canViewProfile &&
						<div>
							<CustomButton
								variant="primary-outline"
								onClick={() => setShowModalApplyProfile(true)}
								disabled={!activated_timestamp}
							>
								{t('btnUpdateProfile')}
							</CustomButton>
						</div>
					}
				</div>
				<div style={{ display: 'flex', flexDirection: 'column', width: '75%', gap: '25px' }}>
					<div className="three-column-grid with-margin device-grid">
						<div className={`${errgroups ? 'has-error' : ''}`}>
							<label htmlFor="timezone" className="control-label">{t('labelGroup')}</label>
							{(!readOnly && userRole !== 'group_manager') ?
								<span>
									<IconTooltip
										description={t('iconTooltipDescription')}
										id="group-tooltip"
									/>
									<select
										placeholder="select"
										className={`rosco-select block${readOnly ? ' disabled' : ''}`}
										name="groups"
										id="groups"
										value={groups[0] || ''}
										onChange={onGroupsChange}
									>
										<option hidden value={''}>{t('selectGroup')}</option>
										{groupsList.map((group, index) =>
											<option key={group + index} value={group}>{group}</option>
										)}
									</select>
								</span> :
								<input className="vehicle-search" value={groups[0]} readOnly />
							}
							{errgroups && <span className="help-block">{errgroups}</span>}
						</div>

						{['fleet_manager', 'partner', 'system_admin', 'customer_service'].includes(userRole) && isDv6(device) &&
							<div>
								<label htmlFor="CAN Bus" className={`control-label ${hasDiffPendingCanbus ? 'has-pending-config' : ''}`}>
									CAN Bus
									{hasDiffPendingCanbus &&
										<IconTooltip
											description={` ${t('Pending')} CAN Bus = "${constants.canbusItems.find(({ value }) => value === pending_config.canbus).name || t('Different')}" `}
											id="canbus-tooltip"
										/>
									}
									<a href="https://wiki.roscocloud.com/user:product:roscolive2.0:how_to_guide:fleet_administration:fleet_configuration#can_bus" target="_blank">
										{t('linkLearnMore')}
									</a>
								</label>
								<select
									placeholder="select"
									className="rosco-select block"
									name="canbus"
									id="canbus"
									value={canbus || "0"}
									onChange={onInputChange}
								>
									{
										constants.canbusItems.map(({ value, name }) => <option key={value} value={value}>{name}</option>)
									}
								</select>
							</div>
						}

						{isInternalUser(userRole) && (
							<div className={`${errdata_limit ? 'has-error' : ''}`}>
								<label className="control-label">{t('overrideVehicleDataLimit')}</label>
								<select
									placeholder="select"
									className="rosco-select block"
									name="data_limit"
									id="data_limit"
									onChange={onInputChange}
									value={data_limit}
								>
									<option value="0">{t('Unlimited')}</option>
									<option value="1">{t('UseCompanyLimit')}</option>
									<option value="2">500 MB</option>
									<option value="3">1 GB</option>
								</select>
								{errdata_limit && <span className="help-block">{errdata_limit}</span>}
							</div>
						)}

						{(['storage_manager', 'storage_user'].indexOf(userRole) === -1) && (
							<div className="flex-item">
								<label htmlFor="vehicle_icon" className="control-label">{t('Icon')}</label>
								{!readOnly ?
									<Dropdown
										autoClose
										id="icon-dropdown"
										className="icon-dropdown"
										show={iconDropdownOpen}
										onToggle={(isOpen) => setIconDropdownOpen(isOpen)}
									>
										<Dropdown.Toggle>
											<Image src={vehicleIcons.filter((item) => item.value === vehicle_icon)[0].src} responsive />
										</Dropdown.Toggle>
										<Dropdown.Menu>
											<div className="icon-menu">
												{vehicleIcons.map(vehicle =>
													<div className="icon-wrapper" onClick={() => changeVehicleIcon(vehicle.value)} key={vehicle.name} value={vehicle.name}>
														<Image src={vehicle.src} responsive />
													</div>
												)}
											</div>
										</Dropdown.Menu>
									</Dropdown> :
									<Image className="icon-dropdown" src={vehicleIcons.filter((item) => item.value === vehicle_icon)[0].src} responsive />
								}
							</div>
						)}

						{/* {isInternalUser(userRole) && (
						<div>
							<label className="control-label">Contract ID</label>
							<input
								type="text"
								name="contract_id"
								className="vehicle-search"
								placeholder="Contract ID..."
								onChange={onInputChange}
								value={contract_id}
							/>
						</div>
					)} */}
					</div>

					{<div className="card-wrapper" style={{ marginLeft: '15px' }}>
						<AdasCalibrationSettings
							device={device}
							onSave={saveAdasCalibrationSettings}
						/>
					</div>}
				</div>

				{showModalApplyProfile
					&& (
						<ModalChangeConfBatch
							userRole={userRole}
							// companyData={{partner_company: location.state.company.partner, company_name: location.state.company.name}}
							companyData={{ partner_company: partner_name, company_name }}
							selectedDevices={[initialData.device_id]}
							clearSelection={() => setShowModalApplyProfile(false)}
							onHide={() => setShowModalApplyProfile(false)}
						// getCompanyDevices={getDevice}
						/>
					)}
			</section>

			{/* Toggles */}
			{/* <section>
				<div className="three-column-grid with-margin device-grid">
					<div className="toggles-container">
						{isInternalUserWithPartner(userRole) && (
							<div className="toggle-wrapper">
								<div className="device-label">
									Camera
									<IconTooltip
										description="When the device is disabled, it will not communicate with ROSCOLive. This will prevent any events being uploaded while disabled."
										id="disable-vehicle-tooltip"
									/>
								</div>
								<label>
									<span className="toggle-left-title">{active ? 'Enabled' : 'Disabled'}</span>
									<Toggle
										name="active"
										icons={false}
										checked={active || false}
										onChange={onInputChange}
									/>
								</label>
							</div>
						)}
						{(company.driver_recognition !== 'manual' && ['system_admin', 'customer_service', 'fleet_manager', 'group_manager'].indexOf(userRole) > -1) && (
							<div className="toggle-wrapper">
								<div className={`device-label ${hasDiffPendingCardreader ? 'has-pending-config' : ''}`}>
									Driver ID
									<IconTooltip
										description={`Must have a connected Driver ID card reader or compatible camera with Driver ID software. 
											${hasDiffPendingCardreader ? ' Pending Driver ID = ' + _.upperFirst(pending_config.cardreader) : ''} `}
										id="driver-id-tooltip"
									/>
								</div>
								<label>
									<span className="toggle-left-title device-label">{cardreader ? 'Enabled' : 'Disabled'}</span>
									<Toggle
										name="cardreader"
										icons={false}
										checked={cardreader || false}
										onChange={onInputChange}
									/>
								</label>
							</div>
						)}
					</div>
				</div>
			</section>*/}
		</section>
	)
}

EditAssetDeviceSettings.propTypes = {
	user: PropTypes.objectOf(PropTypes.any).isRequired,
	company: PropTypes.objectOf(PropTypes.any).isRequired,
	initialData: PropTypes.objectOf(PropTypes.any).isRequired,
	device: PropTypes.objectOf(PropTypes.any).isRequired,
	errMessage: PropTypes.objectOf(PropTypes.any).isRequired,
	groupsList: PropTypes.arrayOf(PropTypes.string).isRequired,
	setPropStates: PropTypes.func.isRequired,
}

const mapStateToProps = ({ user, company }) => ({
	user: user.user,
	company: company.company,
})

export default connect(mapStateToProps)(EditAssetDeviceSettings);
