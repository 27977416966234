import React from 'react'
import { Link } from 'react-router'
import Collapsible from 'react-collapsible'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { userMenus, firmware } from './MenuItems'
import MenuNode from './MenuNode'
import { sendGA } from '../../helpers/helperUsers'

import './SideMenu.sass'

const SideMenu = (props) => {
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'components.sideMenu' });
	const { sideMenuOpen, user, company, updateSideMenuOpen } = props
	const userRole = user.roles[0]
	const userPermissions = user.permissions || []
	const user_company_permissions = user.company_data;

	let menuItems = {}
	if (['custom_user', 'custom_partner'].includes(userRole)) {
		const menu = [];
		// userMenus[userRole].menu.forEach((menuItem, index) => {
		// 	if (_.find(user_permissions, { 'perm_category_id': index + 1, 'a_view': true })) {
		// 		menu.push(menuItem)
		// 	}
		// })
		// menuItems.menu = [...new Set(menu)]

		const permissions = userPermissions
			.filter(e => e.perm_category_name !== 'Other' && e.a_view === true)
			.map(({ perm_category_id }) => perm_category_id)

		userMenus[userRole].menu.forEach((menuItem, index) => {
			switch (index + 1) {
				case 1:
					if (permissions.includes(1)) { // homepage
						menu.push(menuItem)
					}
					break;
				case 2:
					if (permissions.includes(2)) { // event
						menu.push(menuItem)
					}
					break;
				case 3:
					if (permissions.includes(3)) { // reports
						menu.push(menuItem)
					}
					break;
				case 4:
					if (permissions.includes(4) || permissions.includes(5) || permissions.includes(15)) { // managevehicles
						menu.push(menuItem)
					}
					break;
				case 5:
					if (permissions.includes(6)) { // managegroups
						menu.push(menuItem)
					}
					break;
				case 6:
					if (permissions.includes(11)) { // driver
						menu.push(menuItem)
					}
					break;
				case 7:
					if (permissions.includes(16)) { // manageusers
						menu.push(menuItem)
					}
					break;
				case 8:
					if (permissions.includes(17)) { // firmware
						menu.push(menuItem)
					}
					break;
				default:
					break;
			}
		})
		menuItems.menu = [...new Set(menu)]
	}
	else if (['fleet_manager'].includes(userRole)) {
		menuItems = userMenus[userRole]
		if (user.has_company_firmware_blacklist) {
			menuItems = { menu: [...menuItems.menu, firmware] }
		}
	}
	else {
		menuItems = userMenus[userRole]
	}

	if (!user_company_permissions.storage_active && ['fleet_manager', 'fleet_maintainer', 'group_manager', 'user', 'partner_view'].includes(userRole)) {
		menuItems.menu = menuItems.menu.filter(page => page.linkTo !== '/event')
	}

	const toggleCollapse = (menuItem) => {
		sendGA({ category: userRole, action: 'Main Side Menu', label: menuItem.linkTitle })

		if ($('.sideMenu').hasClass('menuOpenMobile')) {
			$('.sideMenu').removeClass('menuOpenMobile');
			$("body").removeClass('hasMobileMenu');
		}
	}

	const toggleSidenav = () => {
		sendGA({ category: userRole, action: 'Main Side Menu', label: `Side Menu ${sideMenuOpen ? 'Hide' : 'Open'}` })

		localStorage.setItem('openState', JSON.stringify(!sideMenuOpen))
		updateSideMenuOpen(!sideMenuOpen)
	}

	// const onMenuItemClick = (event) => () => {
	// 	let { target } = event;
	// 	while (target !== this) {
	// 		if (target.tagName === 'LI') {
	// 			$(".sideMenu").removeClass('menuOpenMobile');
	// 			$("body").removeClass('hasMobileMenu');
	// 			return;
	// 		}
	// 		target = target.parentNode;
	// 	}
	// }

	const bubbleShow = (menuItem) => {
		const { company, isDevicesHaveProblems, lastViewedVehiclesPage } = props;
		const { linkTo } = menuItem;

		if (location.pathname !== '/managevehicles' && linkTo === '/managevehicles') {
			const obj = lastViewedVehiclesPage.find(({ companyId }) => companyId === company.company_id)
			if (obj && moment.utc().diff(moment.utc(obj.ts, 'YYYYMMDDHHmmss'), 'minutes') < 30) {
				return false
			}

			return isDevicesHaveProblems
		}

		return false

		// const { devices, company, lastViewedVehiclesPage } = props
		// const { linkTo } = menuItem
		// if (location.pathname !== '/managevehicles' && linkTo === '/managevehicles' && devices.length > 0) {
		// 	const obj = lastViewedVehiclesPage.find(({ companyId }) => companyId === company.company_id)
		// 	if (obj && moment.utc().diff(moment.utc(obj.ts, 'YYYYMMDDHHmmss'), 'minutes') < 30) {
		// 		return false
		// 	}
		// 	const cameraErrors = devices.reduce((errors, vehicle) => {
		// 		return vehicle.channel_status.find(channel => channel.fail_ts > (channel.success_ts || '1970-01-01 00:00:00')) ? errors + 1 : errors;
		// 	}, 0)
		// 	const recordingErrors = devices.reduce((errors, vehicle) => {
		// 		// return vehicle.sd_status.recording_status === 'Needs Reformat' ? errors + 1 : errors
		// 		return (!_.isEmpty(vehicle.latest_hdderror) && vehicle.latest_hdderror.count > 0) ? errors + 1 : errors
		// 	}, 0)
		// 	const dataUsageErrors = devices.reduce((errors, vehicle) => {
		// 		return ((vehicle.data_usage) / 1000 > (getDeviceDataLimit(vehicle.data_limit, company.company_data))) ? errors + 1 : errors
		// 	}, 0)
		// 	const outOfDateDevicesId = devices.filter(({ firmware_status }) => firmware_status === 'firmware out of date')
		// 	return !!(cameraErrors || outOfDateDevicesId.length || recordingErrors || dataUsageErrors)
		// }
		// return false
	}

	if (!user || !company) {
		return <div>{t('textLoading')}</div>
	}

	return (
		<nav className={`sideMenu ${sideMenuOpen ? '' : 'sideMenuMin'}`} aria-label="primary navigation">
			<div className="header-logo-container">
				<Link to="/" className="header-logo" id="rosco-header-logo" />
			</div>
			<div className="expand-wrapper">
				<svg width="32" height="32" viewBox="0 0 24 24" focusable="false" role="presentation" onClick={toggleSidenav}>
					<path d="M10.294 9.698a.988.988 0 0 1 0-1.407 1.01 1.01 0 0 1 1.419 0l2.965 2.94a1.09 1.09 0 0 1 0 1.548l-2.955 2.93a1.01 1.01 0 0 1-1.42 0 .988.988 0 0 1 0-1.407l2.318-2.297-2.327-2.307z" fill="currentColor" fillRule="evenodd" />
				</svg>
			</div>

			<div className="sideMenu-nav">
				<ul className="sideMenu-list">
					{menuItems.menu.map((menuItem, index) =>
						<MenuNode
							linkIndex={index === 0}
							linkTo={menuItem.linkTo}
							key={menuItem.linkTitle}
							linkTitle={i18n.t(`constants.pages.title${menuItem.linkTitle.replace(" ", "")}`)}
							itemIcon={menuItem.itemIcon}
							open={sideMenuOpen}
							onClick={() => toggleCollapse(menuItem)}
							bubbleShow={bubbleShow(menuItem)}
						/>
					)}
					{menuItems.collapsible && (
						<ul className="sideMenu-list">
							<Collapsible
								trigger={<div className="menu-item">
									<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-gear" viewBox="0 0 16 16">
										<path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
										<path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
									</svg>
									<span className="text">{t('textFleet')}</span>
								</div>}
								className="collapsible"
								openedClassName="collapsible collapsible-open"
								triggerClassName="trigger"
								triggerOpenedClassName="trigger trigger-open"
								contentInnerClassName="collapsible-menu"
								open={false}
							>
								{menuItems.collapsible.map(menuItem =>
									<MenuNode
										key={menuItem.linkTitle}
										linkTo={menuItem.linkTo}
										linkTitle={i18n.t(`constants.pages.title${menuItem.linkTitle.replace(" ", "")}`)}
										itemIcon={menuItem.itemIcon}
										open={sideMenuOpen}
										onClick={() => toggleCollapse(menuItem)}
										bubbleShow={bubbleShow(menuItem)}
									/>
								)}
							</Collapsible>
						</ul>
					)}
				</ul>
			</div>
		</nav>
	)
}

const mapStateToProps = ({ user, company, assets, dashboardData }) => ({
	user: user.user,
	company: company.company,
	whitelabel: user.whitelabel,
	isDevicesHaveProblems: assets.isDevicesHaveProblems,
	lastViewedVehiclesPage: assets.lastViewedVehiclesPage,
	sideMenuOpen: dashboardData.sideMenuOpen,
})

const mapDispatchToProps = dispatch => ({
	updateSideMenuOpen: (user) => {
		dispatch({ type: 'SET_SIDE_MENU_OPEN', payload: user });
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
