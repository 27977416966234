import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { toastr } from 'react-redux-toastr';
import { useTranslation } from 'react-i18next';

import { addGroup } from '../../../action/GroupsActions';
import { errorDetection } from '../../helpers/helperErrors';

import '../../dump/menuComponents/menuComponents.sass';
import CustomButton from '../../CustomButton';

const AddGroupContainer = (props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'manageGroupsPage.manageAssetsGroups.addGroupContainer' });
    const { user, company, toggleLoader } = props;
    const userRole = user.roles[0];

    const companyName = company.name;
    const partner = company.partner;

    const [data, setData] = useState({
        group_name: '',
        company_name: ''
    })
    const [errMessage, setErrMessage] = useState({
        group_name: '',
        company_name: ''
    })
    const [open, setOpen] = useState(false)
    const [companySaved, setCompanySaved] = useState(false)
    const [upd, setUpd] = useState(false);

    useEffect(() => {
        let dataUpdate = data;
        dataUpdate['company_name'] = companyName;
        dataUpdate['partner_name'] = partner;
        setData(data);
    }, [companyName, partner])

    const handleInputChange = (event) => {
        const target = event.target;
        const input_value = target.value;
        const value = input_value.trim();
        const name = target.name;
        let dataUpdate = data;
        dataUpdate[name] = value;
        setData(dataUpdate);
    }

    const checkForm = () => {
        let updateErrMessage = errMessage;
        let error = false;

        if (data.group_name.includes('/')) {
            error = true;
            updateErrMessage['group_name'] = `${t('errGroupName')} "/"`
        }
        else if (data.group_name.length > 20) {
            error = true;
            updateErrMessage['group_name'] = t('errGroupNameMaxLength')
        }
        else {
            for (let i in data) {
                if (data[i] === '') {
                    error = true;
                    updateErrMessage[i] = t('enterGroupName');
                }
                else if (updateErrMessage[i]) {
                    updateErrMessage[i] = null;
                }
            }
        }
        if (!updateErrMessage['group_name'] && !/^[a-zA-Z0-9 ]+$/.test(data.group_name)) {
            error = true;
            toastr.error(t('errValidGroupName'))
        }

        setErrMessage(updateErrMessage);
        return error;
    }

    const addOneGroup = () => {
        const error = checkForm();
        const state = data;
        setUpd(!upd);

        if (!error) {
            toggleLoader(true);
            addGroup(state, userRole)
                .then(res => {
                    toggleLoader(false);
                    setCompanySaved(true);
                    props.changedGroupsList(state.company_name, state.partner_name);
                    toastr.success("Success", t('groupAdded'));
                })
                .catch((err) => {
                    toggleLoader(false);
                    console.log('addOneGroup error: ', err)

                    let errDescription = t('unableAddGroup')
                    if (err.response.data.body) {
                        errDescription = errorDetection(err.response.data.body) || err.response.data.body
                    }
                    else if (err.response.data.response) {
                        errDescription = errorDetection(err.response.data.response.error) || err.response.error
                    }

                    toastr.error(errDescription)
                });
        }
    }

    const cancel = () => {
        const data = {
            company_name: companyName,
            partner_name: partner,
            group_name: ''
        };

        setData(data);
        setCompanySaved(false);
        setErrMessage({
            group_name: '',
            company_name: ''
        });
    }

    const toggleAddBlock = () => {
        setOpen(!open)
        cancel();
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            addOneGroup()
        }
    };

    const { group_name, company_name } = errMessage;
    const isCompanySaved = companySaved;
    const _company_name = companyName ? companyName : props.user.company_name;

    if (isCompanySaved) {
        return (
            <div className='add-asset-block'>
                <CustomButton variant='secondary-outline' onClick={() => cancel()}>{t('addAnotherGroup')}</CustomButton>
            </div>
        )
    }

    return (
        <div className='add-asset-block'>
            <CustomButton variant="secondary-outline" onClick={toggleAddBlock}>
                {open ? t('cancel') : t('addNewGroup')}
            </CustomButton>
            {open &&
                <div className='edit-content-block add-group-block'>
                    <label className="control-label">{t('groupWillBeAdded')} <b>{_company_name}</b></label>

                    <div className={classnames('add-group-form form-group', { 'has-error': errMessage.group_name })}>
                        <div style={{ maxWidth: '350px' }}>
                            <input
                                type="text"
                                name="group_name"
                                className="vehicle-search"
                                placeholder={t('placeholderGroupName')}
                                onChange={(e) => { handleInputChange(e) }}
                                onKeyPress={handleKeyPress}
                            />
                        </div>
                        {group_name && <span className="help-block">{group_name}</span>}
                    </div>
                    <CustomButton
                        variant='primary'
                        onClick={addOneGroup}
                    >
                        {t('addGroup')}
                    </CustomButton>
                </div>
            }
        </div>
    )
}

export default connect(
    state => ({
        user: state.user.user,
        company: state.company.company
    }),
    dispatch => ({
        toggleLoader: (show) => {
            dispatch({ type: 'TOGGLE_LOADER', payload: show });
        }
    })
)(AddGroupContainer);
