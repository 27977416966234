// FWSD-5368
import React from 'react'
import PropTypes from 'prop-types'
import { Marker, InfoWindow } from "react-google-maps"
import $ from 'jquery'

const TripTimeLocation = (props) => {
	const { timeLocation } = props
	// console.log('TripTimeLocation timeLocation: ', timeLocation)

	return (
		<>
			<Marker
				// position={{ lat: polylines[0].polygon[0].lat, lng: polylines[0].polygon[0].lng }}
				position={timeLocation.position}
				icon={{
					path: google.maps.SymbolPath.CIRCLE,
					fillOpacity: 1,
					scale: 6,
					fillColor: '#f00',
					strokeWeight: 3,
					strokeColor: '#fff',
					anchor: new google.maps.Point(0, 0),
				}}
			/>

			<InfoWindow
				// ref={tripTimeRef}
				// zIndex={10000000}
				position={timeLocation.position}
				options={{
					minWidth: 140,
					// pixelOffset: { width: -100, height: -100 }
					// pixelOffset: new google.maps.Size(-100, -100)
					// maxWidth: 20,
					// ariaLabel: "SDF"
					// content: <div>HELLO</div>
				}}
				style={{ backgroundColor: 'red' }}
				// onPositionChanged={(e) => {
				// 	console.log('onPositionChanged: ', $('.trip-time').parent())
				// 	console.log('onPositionChanged: ', $('.trip-time').parent().parent())
				// 	console.log('onPositionChanged: ', $('.trip-time').parent().parent().find('button'))
				// 	$('.trip-time').parent().parent().find('button').hide()
				// }}
				onDomReady={(e) => {
					// console.log('onDomReady e: ', e)
					// console.log('onDomReady: ', $('.trip-time').parent().parent())
					// console.log('onDomReady: ', $('.trip-time').parent().parent().find('button'))
					// $('.trip-time').parent().parent().find('button').hide()
					$('.trip-time').closest('.gm-style-iw-t').attr('style', 'bottom: 20px !important')
					$('.trip-time').closest('.gm-style-iw').find('button').hide()
				}}
			// anchor={marker}
			>
				<div className="trip-time text-center" style={{ padding: '10px' }}>
					{timeLocation.ts}
				</div>
			</InfoWindow>
		</>
	)
}

TripTimeLocation.propTypes = {
	timeLocation: PropTypes.objectOf(PropTypes.any).isRequired
}

export default TripTimeLocation;
