import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'
import { useTranslation } from 'react-i18next'

import CustomButton from '../CustomButton'
import CustomCheckbox from '../common/CustomCheckbox'
import { apiCall } from '../../action/RouterActions'
import './EventFeedback.sass'

const ModalFeedbackOptions = ({ event, feedback, toggleLoader, onSaveFeedback, onHide }) => {
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'components.modalFeedbackOptions' });
	const [selectedOptions, setSelectedOptions] = useState(feedback.selections || '')
	const [detailsText, setDetailsText] = useState(feedback.comment || '')
	const [errMessage, setErrMessage] = useState({ err_detailsText: '' })

	const AvailableFeedbackOptions = [
		{ value: 1, name: t('value1') },
		{ value: 2, name: t('value2') },
		{ value: 3, name: t('value3') },
		{ value: 4, name: t('value4') },
		{ value: 5, name: t('value5') },
		{ value: 6, name: t('value6') },
	];

	const onOptionClick = (e, valueArg) => {
		e.preventDefault()
		const value = valueArg.toString()

		let selectedOptionsNew = selectedOptions.length ? selectedOptions.split(',') : []
		if (selectedOptionsNew.includes(value)) {
			selectedOptionsNew = selectedOptionsNew.filter(item => item !== value)
		}
		else {
			selectedOptionsNew.push(value)
		}

		selectedOptionsNew = [...new Set(selectedOptionsNew)].join(',')
		setSelectedOptions(selectedOptionsNew)
	}

	const checkForm = () => {
		let error = false;
		let newErrMessage = { ...errMessage }

		if (detailsText.length > 255) {
			error = true;
			newErrMessage.err_detailsText = t('errDetailsText');
		}

		setErrMessage(newErrMessage)
		return error
	}

	const onSubmitClick = (e) => {
		const error = checkForm()
		if (!error) {
			toggleLoader(true)
			const params = {
				event_id: event.id,
				has_feedback: true,
				selections: selectedOptions,
				comment: detailsText,
			}

			apiCall('POST', '/events/{event_id}/feedback', params)
				.then((res, err) => {
					toggleLoader(false)
					onSaveFeedback(true)
					onHide()
				})
				.catch((error) => {
					console.log('!POSR Feedback error:', error.response, error)
					toggleLoader(false)
					let errDescription = i18n.t('errorMessage.errDescription');
					if (error.response.data.response.error) {
						errDescription = error.response.data.response.error
					}
					toastr.error(errDescription);
				})
		}
	}

	return (
		<Modal
			show
			onHide={onHide}
			className="modal-event-feedback"
		>
			<Modal.Header closeButton>
				<Modal.Title>{t('titleEventFeedback')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>
					{t('bodyEventFeedback')}
				</p>

				<div className="feedbacks-wrapper">
					{
						AvailableFeedbackOptions.map(({ value, name }) => {
							return (
								<div key={value} className="feedback-option">
									<a
										onClick={(e) => onOptionClick(e, value)}
										role="menuitem"
										tabIndex="-1"
										href="#"
									>
										<CustomCheckbox
											checked={selectedOptions.includes(value)}
											color="#29a9e0"
										/>
									</a>
									<span style={{ marginLeft: 8 }}>{name}</span>
								</div>
							)
						})
					}
				</div>

				<div className="additional-details">
					<div>{t('additionalDetails')}</div>
					<div className={`${errMessage.err_detailsText ? 'has-error' : ''}`}>
						<textarea
							name="content"
							placeholder={t('placeholderAddDetails')}
							value={detailsText || ''}
							onChange={(e) => setDetailsText(e.target.value)}
							style={{ width: '100%', height: '80px', resize: 'vertical', padding: '5px' }}
						// {...opts}
						/>
						{errMessage.err_detailsText &&
							<span className="help-block">{errMessage.err_detailsText}</span>
						}
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<CustomButton
					variant="primary-outline"
					onClick={onHide}
				>
					{t('btnBack')}
				</CustomButton>
				<CustomButton
					variant="primary"
					prefix={`btn${!selectedOptions ? ' custom-disabled' : ''}`}
					disabled={!selectedOptions}
					onClick={onSubmitClick}
				>
					{t('btnSubmit')}
				</CustomButton>
			</Modal.Footer>
		</Modal>
	)
}

ModalFeedbackOptions.propTypes = {
	// title: PropTypes.string,
	// description: PropTypes.string,
	// exampleFileTitle: PropTypes.string,
	// exampleFileLink: PropTypes.string,
	// btnUploadTitle: PropTypes.string,
	// acceptFileExtensions: PropTypes.string,

	// onFileChange: PropTypes.func.isRequired,
	// onUploadFile: PropTypes.func.isRequired,
	onHide: PropTypes.func.isRequired,
}

// ModalFeedbackOptions.defaultProps = {
// 	title: 'Upload Drivers',
// 	description: 'Upload a CSV/Excel file with driver names and card IDs. Required columns are first name and last name, group and card id are optional.',
// 	exampleFileTitle: 'Example Driver File',
// 	exampleFileLink: '/files/rosco_drivers_example.csv',
// 	btnUploadTitle: 'Add New Drivers',
// 	acceptFileExtensions: '.csv, .xlsx, .xls',
// };

export default ModalFeedbackOptions
