import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { defineEnvironmentDokuWiki } from '../../helpers/labels'
import { generalIcons } from '../../helpers/iconHelpers'
import CustomButton from '../../CustomButton'

const MakePreferredProfileModal = (props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'manageVehiclesPage.fleetConfigurationContainer.makePreferredProfileModal' });
	const { profile, proferredProfile, onHide, onSave, loaderModal } = props;

	return (
		<Modal
			show
			onHide={onHide}
			className="config-modal modal-lg-size"
		>
			<Modal.Header>
				<Modal.Title id="contained-modal-title-lg" className="action-title">
					{t('titleMakePreferredProfile')}
					<div className="header-question">
						<a
							href={`https://${defineEnvironmentDokuWiki()}/user:product:roscolive2.0:how_to_guide:fleet_administration:fleet_configuration`}
							target="_blank"
							title={t("titleWhat'sThis")}
						>
							{generalIcons.headerQuestion}
						</a>
					</div>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="config-modal-body auto-height">
					<label>
						{t('textConfigModal_1')} <strong>{proferredProfile ? proferredProfile.name : 'N/A'}</strong>. <br />
						{t('textConfigModal_2')} <strong>{profile.name}</strong> {t('textConfigModal_3')}
					</label>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<div>
					<CustomButton variant="primary-outline" onClick={onHide}>
						{t('btnNo')}
					</CustomButton>
					<CustomButton variant="primary" onClick={onSave}>
						{t('btnYes')}
					</CustomButton>
				</div>
			</Modal.Footer>
			{loaderModal}
		</Modal>
	)
};

MakePreferredProfileModal.propTypes = {
	profile: PropTypes.objectOf(PropTypes.any).isRequired,
	proferredProfile: PropTypes.objectOf(PropTypes.any).isRequired,
	onHide: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
};

export default connect(
	state => ({
		loaderModal: state.loader.loaderModal
	}),
	dispatch => ({
		toggleLoaderModal: (show) => {
			dispatch({ type: 'TOGGLE_LOADER_MODAL', payload: show });
		}
	})
)(MakePreferredProfileModal);
