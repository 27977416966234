import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export function getUserNotifications(data) {
	//    console.log('!getUserNotifications action data', data);
	//* type необязательный параметр. Если его не указать, тогда возвращает все типы
	//    const url = `${API_URL}/user/notifications?type=glacier`;
	const url = `${API_URL}/user/notifications`;
	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		// "headers": {: {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function removeUserNotification(data) {
	// console.log('!removeUserNotification action data', data);

	const url = `${API_URL}/user/notification/${data.notification_id}`;

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'DELETE',
		// "headers": {: {},
		withCredentials: true,

	}

	return axios(settings);
}

export function viewedUserNotifications(data) {
	// console.log('!viewedUserNotifications action state', data);

	const url = `${API_URL}/user/notifications/markasviewed`;

//    let form = new FormData();
//    form.append("new_password", data.new_password);
//    form.append("new_password_confirm", data.new_password_confirm);

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'POST',
		// "headers": {: {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
//        "data": form,
		// "dataType": 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}


export function assignUsersToCompany(data) {
	console.log('!assignUsersToCompany action data', data);

	let url;
	switch (data.user_role) {
	case 'system_admin':
		url = `${API_URL}/system_admin/partner/${data.partner_name}/company/${data.company_name}/assignedusers`;
		break;
	case 'customer_service':
		url = `${API_URL}/customer_service/partner/${data.partner_name}/company/${data.company_name}/assignedusers`;
		break;
	default:
		break;
	}

	const form = new FormData();
	form.append('emails', data.emails);

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'PUT',
		// "headers": {: {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		dataType: 'jsonp',
		withCredentials: true,
	};

	return axios(settings);
}


export function removeUsersFromCompany(data) {
	console.log('!removeUsersFromCompany action data', data);

	let url;
	switch (data.user_role) {
	case 'system_admin':
		url = `${API_URL}/system_admin/partner/${data.partner_name}/company/${data.company_name}/assignedusers`;
		break;
	case 'customer_service':
		url = `${API_URL}/customer_service/partner/${data.partner_name}/company/${data.company_name}/assignedusers`;
		break;
	default:
		break;
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'DELETE',
		// "headers": {: {},
		withCredentials: true,

	}

	return axios(settings);
}

export function getUsersOfPartner(data) {
	console.log('!getUsersOfPartner action data', data);

	let url;
	switch (data.userRole) {
	case 'system_admin':
		url = `${API_URL}/system_admin/partner/${data.partnerName}/users?${data.filter}`;
		break;
	case 'customer_service':
		url = `${API_URL}/customer_service/partner/${data.partnerName}/users?${data.filter}`;
		break;
	case 'sales_demo':
		url = `${API_URL}/sales_demo/partner/${data.partnerName}/users?${data.filter}`;
		break;
	default:
		url = `${API_URL}/partner/users?${data.filter}`;
		break;
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		// "headers": {: {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function getAssignedUsersOfCompany(data) {
	// eslint-disable-next-line no-console
	console.log('!getAssignedUsersOfCompany action data', data);

	let url;
	switch (data.userRole) {
		case 'system_admin':
			url = `${API_URL}/system_admin/partner/${data.partnerName}/company/${data.companyName}/assignedusers`;
			break;
		case 'customer_service':
			url = `${API_URL}/customer_service/partner/${data.partnerName}/company/${data.companyName}/assignedusers`;
			break;
		default:
			break;
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		// "headers": {: {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function getUserInfo() {
	// console.log('!getUserInfo action');
	const url = `${API_URL}/users/me`;

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		// headers: {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function passwordReset(user) {
	console.log('!passwordReset action');

	const url = `${API_URL}/reset`;

	var form = new FormData();
	form.append('email', user);
	form.append('', '');

	var settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'PUT',
		// "headers": {: {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}



// export function getFleetGroups() { //changed action to GroupsActions {getCompanyGroups}
//     console.log('!getFleetGroups action')
//
//     const url = `${API_URL}/groups`;
//
//     let settings = {
//         "async": true,
//         "crossDomain": true,
//         "url": url,
//         "method": "GET",
//         // "headers": {: {},
//         "dataType": 'jsonp',
//         "withCredentials": true
//     }
//
//     return axios(settings);
// }

export function createFleetUser(data) {
   	const url = `${API_URL}/users/${data.email}`;

   	let form = new FormData();
	form.append('last_name', data.last_name);
	form.append('first_name', data.first_name);
	form.append('roles', data.role);
	form.append('password', data.password);
	form.append('phone_number', '12345678');
	data.company_name ? form.append('company_name', data.company_name) : '';
	data.partner_name ? form.append('partner_name', data.partner_name) : '';

   	var settings = {
       "async": true,
       "crossDomain": true,
       "url": url,
       "method": "PUT",
       // "headers": {: {},
       "processData": false,
       "contentType": false,
       "mimeType": "multipart/form-data",
       "data": form,
       "dataType": 'jsonp',
       "withCredentials": true
   }

   return axios(settings);
}

export function createFleetUserWithGroup (data, userRole, callback) {
	// console.log('!createFleetUserWithGroup action data', data);

	let url;
	switch (userRole) {
		case 'fleet_manager':
		case 'storage_manager':
			url = `${API_URL}/users/${data.email}`;
			break;
		case 'system_admin':
			url = `${API_URL}/system_admin/users/${data.email}`;
			break;
		case 'customer_service':
			url = `${API_URL}/customer_service/users/${data.email}`;
			break;
		case 'partner':
		case 'custom_partner':
			url = `${API_URL}/partners/users/${data.email}`;
			break;
	}

	var form = new FormData();
	form.append('last_name', data.last_name);
	form.append('first_name', data.first_name);
	form.append('roles', data.role);
	form.append('password', data.password);
	form.append('phone_number', '12345678');
	data.company_name ? form.append('company_name', data.company_name) : '';
	data.partner_name ? form.append('partner_name', data.partner_name) : '';

//    for (var value of form.values()) {
//       console.log(value);
//    }
	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'PUT',
		// "headers": {: {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		dataType: 'jsonp',
		withCredentials: true,
	}


	axios(settings)
		.then((resUser, err) => {
			// console.log('!createFleetUserWithGroup create user res:', resUser);
			// console.log(data.group);

			if (data.group) {
				console.log('Adds groups');

				let groups = [];
				if (Array.isArray(data.group)) {
					data.group.forEach((item) => {
						groups.push(item.value);
					});
				}
				else {
					groups = data.group.split(',');
				}

				let i = 0;
				groups.forEach((item) => {

					let groupUrl;
					switch (userRole) {
						case 'fleet_manager':
						case 'storage_manager':
							groupUrl = `${API_URL}/groups/${item}/users/${data.email}`;
							break;
						case 'system_admin':
							// groupUrl = `${API_URL}/system_admin/companies/${data.company_name}/groups/${item}/users/${data.email}`;
							groupUrl = `${API_URL}/system_admin/partner/${data.partner_name}/companies/${data.company_name}/groups/${item}/users/${data.email}`;
							break;
						case 'customer_service':
							groupUrl = `${API_URL}/customer_service/partner/${data.partner_name}/companies/${data.company_name}/groups/${item}/users/${data.email}`;
							break;
						case 'partner':
						case 'custom_partner':
							groupUrl = `${API_URL}/partners/companies/${data.company_name}/groups/${item}/users/${data.email}`;
							break;
						default:
							break;
					}

					let settings = {
						async: true,
						crossDomain: true,
						url: groupUrl,
						method: 'PUT',
						// "headers": {: {},
						dataType: 'jsonp',
						withCredentials: true,
					}

					axios(settings).then((res, err)=>{
						console.log('!createFleetUserWithGroup add user group res');
						console.log(res);

						i++;

						if (i == data.group.length) {
							if (typeof callback === 'function') {
								return callback(null, {result:'ok', user_id: resUser.data.response.user.id})
							}
						}
					})
					.catch((error) => {
						if (typeof callback === 'function') {
							return callback(error, null)
						}
					});
				});
			} else {
				if (typeof callback === 'function') {
					return callback(null, {result:'ok', user_id: resUser.data.response.user.id});
				}
			}

		})
		.catch((error)=>{
			if (typeof callback === 'function') {
				return callback(error, null);
			}
		});
}


export function updateFleetUser(data) {
	console.log('!updateFleetUser action data:', data)

	let url
	switch (data.user_role) {
	case 'fleet_manager':
	case 'fleet_maintainer':
	case 'storage_manager':
	case 'group_manager':
	case 'user':
		url = `${API_URL}/users/${data.user_email}`
		break;
	case 'system_admin':
		url = `${API_URL}/system_admin/users/${data.user_email}`
		break;
	case 'customer_service':
		url = `${API_URL}/customer_service/users/${data.user_email}`
		break;
	case 'partner':
	case 'custom_partner':
		url = `${API_URL}/partners/users/${data.user_email}`
		break;
	default:
		break;
	}

	const form = new FormData();
	const user = data.data
	Object.keys(user).forEach((key) => {
		form.append(key, user[key])
	})

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'POST',
		// "headers": {: {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings)
}

export function updateFleetUserSettings (data) {
	console.log('!updateFleetUserSettings action data:', data)

	let url
	switch (data.user_role) {
		case 'fleet_manager':
		case 'storage_manager':
			url = `${API_URL}/users/${data.user_email}/settings`
			break;
		case 'system_admin':
			url = `${API_URL}/system_admin/users/${data.user_email}/settings`
			break;
		case 'customer_service':
			url = `${API_URL}/customer_service/users/${data.user_email}/settings`
			break;
		case 'partner':
		case 'custom_partner':
			url = `${API_URL}/partners/users/${data.user_email}/settings`
			break;
	}

	const form = new FormData(),
	user = data.data
	for (const key in user){
		form.append(key, user[key])
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'PUT',
		// "headers": {: {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		dataType: 'jsonp',
		withCredentials: true,
	}

	axios(settings)
}



// export function updateFleetUserWithGroup (state, user_role, callback) {
//     console.log('!updateFleetUserWithGroup action data', state, user_role);

//     let url, urlSettings;
//     switch(user_role) {
//         case 'fleet_manager':
//         case 'storage_manager':
//             url = `${API_URL}/users/${state.user.email}`;
//             urlSettings = `${API_URL}/users/${state.user.email}/settings`;
//             break;
//         case 'system_admin':
//             url = `${API_URL}/system_admin/users/${state.user.email}`;
//             urlSettings = `${API_URL}/system_admin/users/${state.user.email}/settings`;
//             break;
//         case 'customer_service':
//             url = `${API_URL}/customer_service/users/${state.user.email}`;
//             urlSettings = `${API_URL}/customer_service/users/${state.user.email}/settings`;
//             break;
//         case 'partner':
//             url = `${API_URL}/partners/users/${state.user.email}`;
//             urlSettings = `${API_URL}/partners/users/${state.user.email}/settings`;
//             break;
//     }

//     let form = new FormData()
//     form.append("last_name", state.data.last_name)
//     form.append("first_name", state.data.first_name)
//     form.append("phone_number", state.data.phone_number)
//     form.append("roles", state.data.roles[0])
//     form.append("alert_gsensor", state.data.alert_gsensor)
//     form.append("alert_critical_gsensor", state.data.alert_critical_gsensor)
//     form.append("alert_speed", state.data.alert_speed)
//     form.append("alert_sensor", state.data.alert_sensor)
//     form.append("alert_sd_error", state.data.alert_sd_error)
//     form.append("alert_unclean_shutdown", state.data.alert_unclean_shutdown)
//     form.append("alert_camera_failure", state.data.alert_camera_failure)
// //    form.append("alert_dv_camera_failure", state.data.alert_dv_camera_failure);
//     form.append("alert_high_temperature", state.data.alert_high_temperature || false)
// //    form.append("speed_unit", state.data.speed_unit);
//     form.append("aggr_notific_interval", state.data.aggr_notific_interval)

//     form.append("alert_inactive_vehicles", state.data.alert_inactive_vehicles)
//     form.append("alert_summary", state.data.alert_summary)
//     form.append("data_usage_notification", state.data.data_usage_notification)
//     form.append("audit_notification", state.data.audit_notification)
//     form.append("flood_notification", state.data.flood_notification)

//     const settings = {
//         "async": true,
//         "crossDomain": true,
//         "url": url,
//         "method": "POST",
//         // "headers": {: {},
//         "processData": false,
//         "contentType": false,
//         "mimeType": "multipart/form-data",
//         "data": form,
//         "dataType": 'jsonp',
//         "withCredentials": true
//     }

// //    console.log('isEqual', _.isEqual(_.sortBy(state.data.groups), _.sortBy(state.user.groups)));

//     axios(settings).then((res, err)=>{
//         console.log('!updateFleetUserWithGroup updated user res', res);


//         let form = new FormData();
//         form.append("inputsensor1_active", state.data.settings.inputsensor1_active);
//         form.append("inputsensor1_name", state.data.settings.inputsensor1_name || "");
//         form.append("inputsensor1_description", state.data.settings.inputsensor1_description || "");
//         form.append("inputsensor2_active", state.data.settings.inputsensor2_active);
//         form.append("inputsensor2_name", state.data.settings.inputsensor2_name || "");
//         form.append("inputsensor2_description", state.data.settings.inputsensor2_description || "");
//         form.append("inputsensor3_active", state.data.settings.inputsensor3_active);
//         form.append("inputsensor3_name", state.data.settings.inputsensor3_name || "");
//         form.append("inputsensor3_description", state.data.settings.inputsensor3_description || "");
//         form.append("inputsensor4_active", state.data.settings.inputsensor4_active);
//         form.append("inputsensor4_name", state.data.settings.inputsensor4_name || "");
//         form.append("inputsensor4_description", state.data.settings.inputsensor4_description || "");

//         const settings = {
//             "async": true,
//             "crossDomain": true,
//             "url": urlSettings,
//             "method": "PUT",
//             // "headers": {: {},
//             "processData": false,
//             "contentType": false,
//             "mimeType": "multipart/form-data",
//             "data": form,
//             "dataType": 'jsonp',
//             "withCredentials": true
//         }
//         axios(settings).then((res, err)=>{
//             console.log('!updateFleetUserWithGroup updated user settings res', res);

//             if (!_.isEqual(_.sortBy(state.data.groups), _.sortBy(state.user.groups))) {
//                 console.log('Adds groups');

//                 //* Was removed groups
//                 const arr_remove = _.difference(state.user.groups, state.data.groups);
//                 let settings_remove = {
//                     "async": true,
//                     "crossDomain": true,
//     //                "url": `${API_URL}/groups/${item}/users/${state.data.email}`,
//                     "method": "DELETE",
//                     // "headers": {: {},
//                     "dataType": 'jsonp',
//                     "withCredentials": true
//                 }

//                 //* Was added groups
//                 const arr_add = _.difference(state.data.groups, state.user.groups);
//                 let settings_add = {
//                     "async": true,
//                     "crossDomain": true,
//     //                "url": `${API_URL}/groups/${item}/users/${state.data.email}`,
//                     "method": "PUT",
//                     // "headers": {: {},
//                     "dataType": 'jsonp',
//                     "withCredentials": true
//                 }

//                 const count = arr_remove.length + arr_add.length;

//                 console.log('count', count);
//                 let i = 0;

//                 console.log('arr_remove', arr_remove);
//                 arr_remove.forEach(function(item) {
//                     // settings_remove.url = `${API_URL}/groups/${item}/users/${state.data.email}`,
//                     switch(user_role) {
//                         case 'fleet_manager':
//                         case 'storage_manager':
//                             settings_remove.url = `${API_URL}/groups/${item}/users/${state.data.email}`;
//                             break;
//                         case 'system_admin':
//                             // settings_remove.url = `${API_URL}/system_admin/companies/${state.data.company_name}/groups/${item}/users/${state.data.email}`;
//                             settings_remove.url = `${API_URL}/system_admin/partner/${state.data.partner_company_name}/companies/${state.data.company_name}/groups/${item}/users/${state.data.email}`;
//                             break;
//                         case 'customer_service':
//                             settings_remove.url = `${API_URL}/customer_service/partner/${state.data.partner_company_name}/companies/${state.data.company_name}/groups/${item}/users/${state.data.email}`;
//                             break;
//                         case 'partner':
//                             settings_remove.url = `${API_URL}/partners/companies/${state.data.company_name}/groups/${item}/users/${state.data.email}`;
//                             break;
//                     }

//                     axios(settings_remove).then((res, err)=>{
//                         console.log('!updateFleetUserWithGroup remove user group res', res);

//                         i++;
//                         if (i >= count) {
//                             if (typeof callback === 'function') {
//                                 return callback(null, {'result':'ok'})
//                             }
//                         }


//                     })
//                     .catch((error)=>{
//                         if (typeof callback === 'function') {
//                             return callback(error, null)
//                         }
//                     });
//                 });

//                 console.log('arr_add', arr_add);
//                 arr_add.forEach(function(item) {
//                     switch(user_role) {
//                         case 'fleet_manager':
//                         case 'storage_manager':
//                             settings_add.url = `${API_URL}/groups/${item}/users/${state.data.email}`;
//                             break;
//                         case 'system_admin':
//                             settings_add.url = `${API_URL}/system_admin/partner/${state.data.partner_company_name}/companies/${state.data.company_name}/groups/${item}/users/${state.data.email}`;
//                             break;
//                         case 'customer_service':
//                             settings_add.url = `${API_URL}/customer_service/partner/${state.data.partner_company_name}/companies/${state.data.company_name}/groups/${item}/users/${state.data.email}`;
//                             break;
//                         case 'partner':
//                             settings_add.url = `${API_URL}/partners/companies/${state.data.company_name}/groups/${item}/users/${state.data.email}`;
//                             break;
//                     }
//                     axios(settings_add).then((res, err)=>{
//                         console.log('!updateFleetUserWithGroup add user group res');
//                         console.log(res);

//                         i++;
//                         if (i >= count) {
//                             if (typeof callback === 'function') {
//                                 return callback(null, {'result':'ok'})
//                             }
//                         }


//                     })
//                     .catch((error)=>{
//                         if (typeof callback === 'function') {
//                             return callback(error, null)
//                         }
//                     });
//                 });

//             }
//             else {
//                 if (typeof callback === 'function') {
//                     return callback(null, {'result':'ok'});
//                 }
//             }

//         })
//         .catch((error)=>{
//             if (typeof callback === 'function') {
//                 return callback(error, null);
//             }
//         });

//     })
//     .catch((error)=>{
//         if (typeof callback === 'function') {
//             return callback(error, null);
//         }
//     });
// }

export function deleteFleetUser(email) {
	// console.log('!deleteFleetUser action data');
	const url = `${API_URL}/users/${email}`
	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'DELETE',
		// "headers": {: {},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function getUserByEmail(user_role, email) {
	console.log('!getUserByEmail action email', user_role, email);

	let url;
	switch (user_role) {
		case 'system_admin':
			url = `${API_URL}/system_admin/users/${email}`;
			break;
		case 'customer_service':
			url = `${API_URL}/customer_service/users/${email}`;
			break;
		case 'partner':
		case 'custom_partner':
			url = `${API_URL}/partners/users/${email}`;
			break;
		case 'fleet_maintainer':
		case 'fleet_manager':
		case 'group_manager':
		case 'storage_manager':
			url = `${API_URL}/users/${email}`;
			break;
		case 'sales_demo':
			url = `${API_URL}/sales_demo/users/${email}`;
			break;
	}
	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		// "headers": {: {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function getUsers(userRole, company) {
	// console.log('!getUsers action', company);
	let url;
	switch (userRole) {
	case 'system_admin':
		url = `${API_URL}/system_admin/partner/${company.partner}/companies/${company.name}/users`;
		break;
	case 'customer_service':
		url = `${API_URL}/customer_service/partner/${company.partner}/companies/${company.name}/users`;
		break;
	case 'partner':
	case 'custom_partner':
		url = `${API_URL}/partners/companies/${company.name}/users`;
		break;
	case 'fleet_maintainer':
	case 'fleet_manager':
	case 'group_manager':
	case 'storage_manager':
	case 'custom_user':
		url = `${API_URL}/users`;
		break;
	case 'sales_demo':
		url = `${API_URL}/sales_demo/partner/${company.partner}/companies/${company.name}/users`;
		break;
	default:
		break;
	}

	if (company.roles) {
		url += `?roles=${company.roles}`
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		// "headers": {: {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function getAllUsers(){
    const url = `${API_URL}/users/list`;

    const settings = {
        "async": true,
        "crossDomain": true,
        "url": url,
        "method": "GET",
        "dataType": 'jsonp',
        "withCredentials": true
    }

    return axios(settings);
}

export function getUser(data){
	const url1 = `${API_URL}/devices`;
	const url = `${API_URL}/users/maxim.kvaternyuk@gmail.com`;
	axios.post(url, {email:'maxim.kvaternyuk@gmail.com', password:'password'});
	return axios.get(url1, {withCredentials:true});
}

export function newUser(data){
   return axios.post('/registration', data);
}
//
// export function fetchAssets() {
//
//     console.log('!logout')
//
//     var settings = {
//         "async": true,
//         "crossDomain": true,
//         "url": "http://ttdevapi.roscocloud.com/api/v0.5/logout",
//         "method": "GET",
//         // "headers": {: {},
//         "withCredentials": true
//     }
//
//     axios(settings)
//         .then(function(response, f, m) {
//             console.log(response);
//             return  true;
//         })
//         .catch(function (error) {
//             console.log('!error');
//             console.log(error);
//         });
//
//
//
//     console.log('!login')
//
//     var form = new FormData();
//     form.append("email", "maxim.kvaternyuk@gmail.com");
//     form.append("password", "password");
//
//     var settings = {
//         "async": true,
//         "crossDomain": true,
//         "url": "http://ttdevapi.roscocloud.com/api/v0.5/login",
//         "method": "POST",
//         // "headers": {: {},
//         "processData": false,
//         "contentType": false,
//         "mimeType": "multipart/form-data",
//         "data": form,
//         "withCredentials": true
//     }
//
//     axios(settings)
//         .then(function(response, f, m) {
//             console.log(response);
//             return  true;
//         })
//         .catch(function (error) {
//             console.log('!error');
//             console.log(error);
//         });
//
//
//
//     console.log('!users')
//
//     var settings = {
//         "async": true,
//         "crossDomain": true,
//         "url": "http://ttdevapi.roscocloud.com/api/v0.5/users",
//         "method": "GET",
//         // "headers": {: {},
//         "withCredentials": true
//     }
//
//     axios(settings)
//         .then(function(response, f, m) {
//             console.log(response);
//             return  true;
//         })
//         .catch(function (error) {
//             console.log('!error');
//             console.log(error);
//         });

// }

export function changeUserPassword (data) {
	console.log('!changePasswordUserAccount action state', data);

	const url = `${API_URL}/users/${data.user_email}/change`;

	const form = new FormData();
	form.append('new_password', data.new_password);
	form.append('new_password_confirm', data.new_password_confirm);

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'POST',
		// "headers": {: {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		// "dataType": 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function activateUserAccount (email) {
	console.log('!action activateUserAccount', email);

	const url = `${API_URL}/customer_service/users/confirm`;

	const form = new FormData();
	form.append('emails', email);

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'POST',
		// "headers": {: {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		withCredentials: true,
	}

	return axios(settings);
}

export function getDokuWikiEULA(env) {
	// console.log('getDokuWikiEULA env: ', env);
	// const url = `https://wiki.roscocloud.com/_export/html/user:product:roscolive:roscolive2.0:eula`;
	let url;

	switch (env) {
		case 'localhost':
			// // url = `http://localhost/dokuwiki/_export/html/user:product:roscolive:roscolive2.0:eula`;
			// url = 'http://localhost/dokuwiki/_export/html/user:product:roscolive2.0:eula';
			// break;
		case 'test.roscolive.com':
		case 'dev.roscolive.com':
		case 'staging.roscolive.com':
			// url = `https://devwiki.roscocloud.com/_export/html/user:product:roscolive:roscolive2.0:eula`;
			// url = 'https://devwiki.roscocloud.com/_export/html/user:product:roscolive2.0:eula';
			// break;
		case 'canada.roscolive.com':
		case 'roscocloud.com':
		case 'www.roscocloud.com':
		case 'fleet.roscolive.com':
			// url = `https://wiki.roscocloud.com/_export/html/user:product:roscolive:roscolive2.0:eula`;
			url = 'https://wiki.roscocloud.com/_export/html/user:product:roscolive2.0:eula';
			break;
	}

	var settings = {
		url,
		method: 'GET',
		dataType: 'text/html',
	};

	return axios(settings);
}

export function getDokuWikiContent(url) {
	console.log('getDokuWikiContent url:', url);

	var settings = {
		url,
		method: 'GET',
		dataType: 'text/html',
	};

	return axios(settings);
}

export function agreeDokuWikiEULA() {
	const url = `${API_URL}/user/eula/view`;

	var settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'POST',
		// "headers": {: {},
		processData: false,
		contentType: false,
		// "mimeType": "multipart/form-data",
		// "data": form,
		withCredentials: true,
	};

	return axios(settings);
}

export function viewedLastReleaseDescription(timeReviewed) {
	const url = `${API_URL}/user/release/view`;

	const form = new FormData()
	form.append('time_reviewed', timeReviewed)

	var settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'POST',
		// "headers": {: {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		withCredentials: true,
	};

	return axios(settings);
}

export function addAuditLog(data) {
	// console.log('!addAuditLog action data:', data)

	const url = `${API_URL}/users/auditlogs`
	const form = new FormData()

	form.append('action', data.action)
	form.append('action_data', JSON.stringify(data.action_data))

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'PUT',
		// "headers": {: {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings)
}

export function getUserLogs(data) {
	console.log('!getUserLogs action data:', data)

	// let url
	// switch (data.user_role) {
	// case 'system_admin':
	// 	url = `${API_URL}/system_admin/users/${data.user_id}/auditlog?start_date=${data.start_date}&end_date=${data.end_date}`
	// 	break
	// default:
	// 	break;
	// }

	const url = `${API_URL}/users/${data.user_id}/auditlog?start_date=${data.start_date}&end_date=${data.end_date}`

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
//        "headers": {Pragma: 'no-cache'},
		withCredentials: true,
	}

	return axios(settings)
}

export function getCompanyLogs(data) {
	console.log('!getUserLogs action data:', data)

	let url
	switch (data.user_role) {
	case 'system_admin':
	case 'customer_service':
	case 'sales_demo':
		url = `${API_URL}/companies/${data.company_id}/auditlog?start_date=${data.start_date}&end_date=${data.end_date}`
		break
	default:
		break;
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
//        "headers": {Pragma: 'no-cache'},
		withCredentials: true,
	}

	return axios(settings)
}