import React, { Component, useState, useEffect } from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { toastr } from 'react-redux-toastr'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { renderCustomHeader, tableAdjustableColOptions } from '../../component/helpers/table'
import { apiCallGet } from '../../action/RouterActions'

const FleetAdminRouterUnpaired = (props) => {
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'modemsPage.fleetAdminRouterUnpaired' });
	const { companyId, toggleLoader } = props;

	const [routers, setRouters] = useState([]);

	useEffect(() => {
		// console.log('!componentWillReceiveProps', nextProps)
		getRoutersList(companyId)
	}, [companyId]);

	const getRoutersList = () => {
		toggleLoader(true);

		const mode = 'unpaired'
		apiCallGet(`/company/${companyId}/router`, { companyId, mode })
			.then((res, err) => {
				console.log('!getUnpairedRoutersList res:', res, err)
				const { response: { routers } } = res.data;
				setRouters(routers);

				toggleLoader(false);
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!getUnpairedRoutersList error:', error.response)
				setRouters([]);
				let errDescription = i18n.t('errorMessage.errDescription');
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription);
			})
	};

	const actionsFormatter = (cell, row, extra) => {
		const { setConfigParams } = props
		return (
			<div>
				<a
					href="/"
					onClick={(e) => { e.preventDefault(); setConfigParams({ routerManagementShow: true, activeDevice: row }) }}
					className="remote-management"
				>
					<img src="/images/remote_management.png" alt="" />
					{t('remoteManagement')}
				</a>
			</div>
		)
	};

	return (
		<div className="manage-users-table">
			<BootstrapTable
				data={routers}
				{...tableAdjustableColOptions}
			>
				<TableHeaderColumn width="15%" dataField="imei" dataSort={true} isKey={true}>
					{renderCustomHeader(0, t('headerIMEI'))}
				</TableHeaderColumn>
				<TableHeaderColumn width="15%" dataField="manufacturer" dataSort={true}>
					{renderCustomHeader(1, t('headerManufacturer'))}
				</TableHeaderColumn>
				<TableHeaderColumn width="15%" dataField="model" dataSort={true}>
					{renderCustomHeader(2, t('headerModel'))}
				</TableHeaderColumn>
				<TableHeaderColumn width="15%" dataField="version" dataSort={true}>
					{renderCustomHeader(3, t('headerVersion'))}
				</TableHeaderColumn>
				<TableHeaderColumn width="25%" dataField="current_firmware">
					{renderCustomHeader(4, t('headerFirmware'))}
				</TableHeaderColumn>
				<TableHeaderColumn
					width="15%"
					dataField="current_firmware"
					dataSort={false}
					dataFormat={actionsFormatter}
				>
					{t('headerActions')}
				</TableHeaderColumn>
			</BootstrapTable>
		</div>
	)
}

export default connect(
	null,
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(FleetAdminRouterUnpaired);
