// FWSD-6482, If a device cannot be reached to complete the request (Offline/Disabled), display a modal informing the user the request cannot be completed that this time. 
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import CustomButton from '../../component/CustomButton'

import './CameraHealth.sass'

const ModalDeviceOfflineDisabled = (props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'modals.modalDeviceOfflineDisabled' });
	// console.log('PROPS ModalDeviceOfflineDisabled: ', props)
	const { devicesOfflineDisabled, onHide } = props

	return (
		<Modal
			show
			onHide={onHide}
			dialogClassName="custom-request-modal modal-error-status"
		>
			<Modal.Header closeButton>
				<Modal.Title>
					{t('titleDeviceOffline')}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{devicesOfflineDisabled.length === 1
					? <>
						{t('textVehicle')} <b>{devicesOfflineDisabled[0]}</b> {t('deviceOfflineDisabled')}
					</>
					: <>
						{t('devicesOfflineDisabled')}
						<br /><br />
						<ul>
							{devicesOfflineDisabled.map((name) => <li>{name}</li>)}
						</ul>
					</>
				}
			</Modal.Body>
			<Modal.Footer bsPrefix="default-modal-footer modal-footer">
				<CustomButton variant="primary" onClick={onHide}>
					{t('btnOk')}
				</CustomButton>
			</Modal.Footer>
		</Modal>
	)
}

ModalDeviceOfflineDisabled.propTypes = {
	devicesOfflineDisabled: PropTypes.arrayOf(PropTypes.string).isRequired,
	onHide: PropTypes.func.isRequired,
}

export default ModalDeviceOfflineDisabled;
