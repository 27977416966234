import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'
import { connect } from 'react-redux'
import Select from 'react-select'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import ModalDelete from './ModalDelete'

import { apiCall } from '../../../action/RouterActions'

//import 'react-select/dist/react-select.css';
import { getCompanyById } from '../../../action/CompaniesActions'
import { isInternalUser } from '../../helpers/constants'
import { RiFileCopyFill } from "react-icons/ri";
import CustomButton from '../../CustomButton';

const ModalManageJobKey = (props) => {
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'components.userDropdown.modalManageJobKey' });
	const { onHide, user, company, toggleLoader } = props;

	const [emails, setEmails] = useState([]);
	const [message, setMessage] = useState('');
	const [errMessage, setErrMessage] = useState({
		erremail: '',
		errmessage: ''
	});
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [companyData, setCompanyData] = useState(null);
	const [upd, setUpd] = useState(false);

	useEffect(() => {
		getCompanyById(user.roles[0], company.company_id)
			.then(res => {
				setCompanyData(res.data.response.company)
			})
			.catch(err => {

			})
	}, []);

	const handleCopyJobKeyClick = (e) => {
		e.preventDefault()

		const str = document.getElementById('fleetJobKey').innerText
		const parent = e.target.closest('div')
		const textarea = document.createElement('textarea')

		textarea.style.height = 0
		textarea.innerText = str;
		textarea.setAttribute('readonly', '')
		parent.appendChild(textarea)

		textarea.select();
		document.execCommand('copy')
		parent.removeChild(textarea)
	}

	const fillForm = (e) => {
		const { target } = e;
		const { name } = target;
		const value = target.type === 'checkbox' ? target.checked : target.value
		if (name === "message") setMessage(value);
	}

	const checkEmail = (argEmail) => {
		const re = /^([a-zA-Z0-9_\-\.\+]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(argEmail)
	}

	const checkForm = (data) => {
		let error = false;
		let errMessageUpdate = errMessage;
		Object.keys(data).forEach((key) => {
			if (data[key] === '' && key !== 'message') {
				error = true;
				errMessageUpdate[`err${key}`] = t('enterYour') + key + '!';
			} else if (errMessageUpdate[`err${key}`]) {
				errMessageUpdate[`err${key}`] = null;
			}
		})

		if (data.message && data.message.length > 250) {
			error = true;
			errMessageUpdate.errmessage = t('errMaxChars');
		}

		setErrMessage(errMessageUpdate);
		return error;
	}

	const handleSendClick = () => {
		const params = {
			company_key: companyData.company_key,
			email: emails.join(','),
			message
		};
		const errForm = checkForm(params)
		setUpd(!upd)
		if (errForm) {
			return
		}

		toggleLoader(true)
		apiCall('POST', '/installerapp/companykey/send', params)
			.then((res, err) => {
				console.log('!handleRegenerateClick res:', res, err)
				toggleLoader(false)
				toastr.success(t('emailSent'))
				onHide()
			})
			.catch((error) => {
				console.log('!handleRegenerateClick error:', error.response, error)
				toggleLoader(false)

				let errDescription = i18n.t('errorMessage.errDescription');
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			})
	}

	const regenarateJobKey = () => {
		toggleLoader(true)
		apiCall('POST', '/installerapp/companykey/regenerate', { company_id: companyData.id })
			.then((res, err) => {
				console.log('!handleRegenerateClick res:', res, err)
				toggleLoader(false)
				toastr.success(t('compantKeyChanged'))

				companyData.company_key = res.data.response.new_company_key
				setCompanyData(companyData)
				closeConfirmModal()
			})
			.catch((error) => {
				console.log('!handleRegenerateClick error:', error.response, error)
				toggleLoader(false)

				let errDescription = i18n.t('errorMessage.errDescription');
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			})
	}

	const closeConfirmModal = () => {
		setShowConfirmModal(false);
	}

	const handleRegenerateClick = () => {
		setShowConfirmModal(true);
	}

	const { erremail, errmessage } = errMessage;

	const emailsList = [];
	if (emails) {
		emails.forEach(item => emailsList.push({ value: item, label: item }));
	}

	return (
		<Modal
			size='lg'
			show
			onHide={onHide}
			className="modalCompanyKey modal-lg-size"
		>
			<Modal.Header closeButton>
				<Modal.Title>
					{t('titleCompanyKey')}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div>
					<div style={{ margin: '5px 0px 10px' }}>
						{companyData && companyData.company_key
							&& (
								<div className='text-center'>
									<span style={{ fontSize: '20px', marginRight: '2px' }} id="fleetJobKey">{companyData.company_key}</span>
									<a href="/" title={t('titleLink')} style={{ fontSize: '17px' }} onClick={handleCopyJobKeyClick}>
										<RiFileCopyFill color='#29a9e0' />
									</a>
								</div>
							)}
					</div>
					<div className={`form-group  ${erremail && 'has-error'}`}>
						<label className="control-label">{t('labelEmails')}</label>
						<Select
							isMulti={true}
							name="email"
							options={emailsList}
							id="email"
							onChange={(items) => {
								setEmails(_.map(items, 'value'))
							}}
							onInputKeyDown={(e) => {
								if (['Enter', ',', ' ', 'Tab'].indexOf(e.key) > -1) {
									e.preventDefault()
									const subscriber = e.target.value
									if (checkEmail(subscriber)) {
										setEmails([...emails, subscriber])
									} else if (e.key === 'Tab' && !subscriber) {
										this.expirationSelect.focus();
									} else {
										toastr.error(t('notValidEmail'));
									}
								}
							}}
							onSelectResetsInput
							onBlur={(e) => {
								if (e.target.value) {
									const subscriber = e.target.value
									if (checkEmail(subscriber)) {
										setEmails([...emails, subscriber])
									} else {
										toastr.error(t('notValidEmail'));
									}
								}
							}}
							placeholder={t('placeholder')}
							className="form-select3 subscribers"
							value={emailsList}
						/>
						{erremail && <span className="help-block">{erremail}</span>}
					</div>
					<div className={`form-group ${errmessage && 'has-error'}`}>
						<label className="control-label">{t('labelMessage')}</label>
						<textarea
							name="message"
							value={message}
							onChange={fillForm}
							style={{ width: '100%', height: '80px', resize: 'vertical' }}
						/>
						{errmessage && <span className="help-block">{errmessage}</span>}
					</div>
				</div>

				<div className='text-right' style={{ textAlign: 'right' }}>
					<CustomButton size='sm' variant="primary" onClick={handleSendClick}>{t('btnSend')}</CustomButton>
				</div>

				{isInternalUser(user.roles[0]) &&
					<fieldset className="text-center" style={{ border: '1px solid #f00', padding: '10px', marginTop: '15px' }}>
						<h4 style={{ color: '#f00', marginTop: '0px' }}>
							{t('caution')}
						</h4>
						<CustomButton variant="delete" onClick={handleRegenerateClick}>{t('btnRegenerateKey')}</CustomButton>
					</fieldset>
				}
			</Modal.Body>

			{showConfirmModal
				&& (
					<ModalDelete
						content={t('modalDeleteContent')}
						deleteBtnName={t('deleteBtnName')}
						backdropClassName="custom-modal-backdrop z-index-highest"
						closeModal={closeConfirmModal}
						deleteModal={regenarateJobKey}
					/>
				)}
		</Modal>
	);
}

ModalManageJobKey.propTypes = {
	onHide: PropTypes.func.isRequired
}

export default connect(
	state => ({
		user: state.user.user,
		company: state.company.company
	}),
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	}),
)(ModalManageJobKey);
