import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { getAllCompanies } from '../../../action/CompaniesActions';

import PartnerAddContainer from './PartnerAddContainer';

import SearchBar from '../../SearchBar';
import CategoryItem from '../../dump/menuComponents/CategoryItem';
import GreyHeader from '../../GreyHeader';

import '../../dump/menuComponents/menuComponents.sass';

const PartnerContainer = (props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'partnersPage.partnerContainer' });
    const { company, user, toggleLoader } = props;
    const userRole = user.roles[0];

    const [partners, setPartners] = useState([])
    const [initialData, setInitialData] = useState([])
    const [searchText, setSearchText] = useState('')
    const [data, setData] = useState({})
    const [companies, setCompanies] = useState([])
    const [modal, setModal] = useState({
        show: false,
        content: '',
        error: false
    })
    const [delete_company, setDelete_company] = useState({})
    const [open, setOpen] = useState(false)

    useEffect(() => {
        getCompanies();
        setSearchText('');
    }, [company.company_id])

    const getCompanies = () => {
        toggleLoader(true);
        const company_type = 'partner';

        getAllCompanies(userRole, company_type)
            .then((res, err) => {
                const partners = res.data.response.companies;
                setPartners(partners)
                setInitialData(partners)
                toggleLoader(false);
            })
            .catch((error) => {
                toggleLoader(false);
            });
    }

    const editPartner = (partner) => {
        browserHistory.push({ pathname: "/partner/edit", state: { company: partner } });
    }

    const onSearch = (e) => {
        const value = e.target.value.toLowerCase();
        const filter = initialData.filter(partner => {

            return (partner.company_name.toLowerCase().includes(value)
                || partner.company_city.toLowerCase().includes(value)
                || partner.company_state.toLowerCase().includes(value)
                || partner.company_zipcode.toLowerCase().includes(value)
                || partner.company_address.toLowerCase().includes(value)
                || partner.company_country.toLowerCase().includes(value)
                || partner.company_phone_number.toLowerCase().includes(value))
        });

        setPartners(filter)
        setSearchText(e.target.value)
    }

    return (
        <>
            <GreyHeader title={t('titlePartners')} hideSubtitle />

            <div className='page-subheader'>
                <div className='subheader-section search'>
                    <SearchBar term={searchText} onSearch={onSearch} />
                </div>
            </div>

            <main className='main-content-block manage-users'>
                <h3 className='category-title'>{t('titlePartners')}</h3>
                <PartnerAddContainer getPartners={getCompanies} />
                <div className="manage-partners">
                    {partners.map(partner =>
                        <CategoryItem
                            key={partner.id}
                            onClick={() => editPartner(partner)}
                            text={partner.company_name || t('unnamed')}
                        />
                    )}
                </div>
            </main>
        </>
    );
}

export default connect(
    state => ({
        user: state.user.user,
        company: state.company.company
    }),
    dispatch => ({
        toggleLoader: (show) => {
            dispatch({ type: 'TOGGLE_LOADER', payload: show });
        }
    })
)(PartnerContainer);
