import React from "react"
import GreyHeader from '../../GreyHeader'
import { useTranslation } from 'react-i18next'

const LegalNotice = (props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'components.legalNotice' });

    return (
        <div>
            <GreyHeader title={t('titleLegalNotice')} hideSubtitle />

            <main className="main-content-block">
                <p dangerouslySetInnerHTML={{ __html: contentBlock_1 }} ></p>
                <h2>{t('contentBlock_2')}</h2>
                <p dangerouslySetInnerHTML={{ __html: contentBlock_3 }}></p>
                <h2>{t('contentBlock_4')}</h2>
                <p>{t('contentBlock_5')}</p>
                <h2>{t('contentBlock_6')}</h2>
                <p dangerouslySetInnerHTML={{ __html: contentBlock_7 }}></p>
            </main>
        </div>
    )
}

export default LegalNotice;
