import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Modal } from 'react-bootstrap'
import SearchBar from '../../../SearchBar'
import { constants } from '../../../helpers/constants'
import { getDevicesList } from '../../../../action/DeviceActions'
import { getUsers } from '../../../../action/ClientActions'
import _ from 'lodash'
import { updateReport } from '../../../../action/ReportsActions'
import { toastr } from 'react-redux-toastr'
import VehicleSelectBox from '../../../dump/menuComponents/VehicleSelectBox'
import { vehicleReportFields } from '../ReportsHelpers'
import { useTranslation } from 'react-i18next'
import CustomButton from '../../../CustomButton'

const EditReportModal = (props) => {
    const { t, i18n } = useTranslation('translation', { keyPrefix: 'reportsPage.editReportModal' });
    const { user, company, reportInfo, onHide, loaderModal, toggleLoaderModal } = props;
    const userRole = user.roles[0];
    const reportUsers = reportInfo.users || [];

    const [step, setStep] = useState(1);
    const [newFields, setNewFields] = useState(reportInfo.fields);
    const [newName, setNewName] = useState(reportInfo.name);
    const [newVehicles, setNewVehicles] = useState(reportInfo.vehicles);
    const [initVehicles, setInitVehicles] = useState();
    const [newUsers, setNewUsers] = useState(reportUsers);
    const [totalVehicles, setTotalVehicles] = useState([]);
    const [totalUsers, setTotalUsers] = useState([]);
    const [userSearch, setUserSearch] = useState('');
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        let reqArray = [];
        reqArray.push(getDevicesList(company.company_id));
        if (['system_admin', 'customer_service', 'partner', 'fleet_manager', 'custom_user', 'group_manager', 'sales_demo', 'custom_partner'].indexOf(userRole) > -1) {
            reqArray.push(getUsers(userRole, company))
        }
        toggleLoaderModal(true);
        Promise.all(reqArray)
            .then(res => {
                let devices = res[0].data.response.devices;
                let users = [];
                if (res.length > 1) {
                    users = res[1].data.response.users.filter(currentUser => currentUser.email !== user.email);
                }
                setTotalVehicles(devices)
                setTotalUsers(users)
                if (reportInfo.vehicles.length === 0) {
                    const data = devices.map(device => device.device_id)
                    setNewVehicles(data)
                    setInitVehicles(data)
                }
                toggleLoaderModal(false);
            })
            .catch((error) => {
                toggleLoaderModal(false);
                toastr.error(t('unknownError'));
            });
    }, [])

    const setField = (field) => {
        const tempFields = [...newFields]
        if (tempFields.includes(field)) {
            tempFields.splice(tempFields.indexOf(field), 1)
        }
        else {
            tempFields.push(field);
        }
        setNewFields(tempFields)
        setErrors([])
    }

    const setUser = (userEmail) => {
        let newEmails = [];
        if (newUsers.includes(userEmail)) {
            newEmails = newUsers.filter(email => email !== userEmail);
        }
        else {
            newEmails = [...newUsers, userEmail];
        }
        setNewUsers(newEmails)
    }

    const setVehicles = (newVehicles) => {
        setNewVehicles(newVehicles)
        setErrors([])
    }

    const selectAllUsers = (e) => {
        let newUsers = []
        if (!e || e.target.checked) {
            newUsers = totalUsers.map(user => {
                return user.email
            })
        }
        else {
            newUsers = reportUsers
        }
        setNewUsers(newUsers)
        setErrors([])
    }

    const saveReport = () => {
        if (newFields === reportInfo.fields && newName === reportInfo.name && initVehicles === newVehicles && newUsers === reportUsers) {
            toastr.warning('', t('warningReportNoChanges'));
        } else {
            if (newVehicles.length === 0) {
                setErrors({ ...errors, assets: t('errorSelectVehicle') })
            } else {
                const data = {};
                if (!_.isEqual(newFields, reportInfo.fields)) {
                    data.fields = newFields
                }
                if (!_.isEqual(newVehicles, reportInfo.vehicles) && !(reportInfo.vehicles.length === 0 && newVehicles.length === totalVehicles.length)) {
                    data.vehicles = newVehicles.length === totalVehicles.length ? JSON.stringify([]) : newVehicles;
                }
                if (!_.isEqual(newUsers, reportUsers)) {
                    data.users = newUsers
                }
                if (reportInfo.name !== newName) {
                    data.name = newName
                }
                toggleLoaderModal(true);
                updateReport(reportInfo.id, data)
                    .then(res => {
                        toggleLoaderModal(false);
                        toastr.success(t('toastrUpdatedReport'))
                        props.onHide(res.data);
                    })
                    .catch(err => {
                        toastr.error(t('toastrUnableUpdateReport'))
                        toggleLoaderModal(false);
                    })
            }
        }
    }

    const nextStep = () => {
        if (!newName) {
            setErrors({ ...errors, name: t('errorEnterVameReport') })
        }
        else if (!newName.trim() || !/^[a-zA-Z0-9 ]+$/.test(newName)) {
            setErrors({ ...errors, name: t('errorEnterValidName') })
        }
        else if (newFields.length <= 1) {
            setErrors({ ...errors, fields: t('errorSelectField') })
        }
        else {
            setStep(2)
        }
    }

    const goBack = () => {
        setStep(1);
    }

    const allUsersChecked = newUsers.length === totalUsers.length;

    return (
        <Modal
            size='lg'
            show
            onHide={onHide}
            className="reports-modal modal-lg-size"
        >
            <Modal.Header closeButton>
                <Modal.Title>{t('textEdit') + (newName || t('textReport'))}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="edit-report-body">
                    {newFields && step === 1 &&
                        <div className='fields-select'>
                            <div className='report-info'>
                                <h4 className='category-title'>
                                    {t('titleReportName')}
                                </h4>
                                <input value={newName} type='text' placeholder={t('titleReportName')} className={'vehicle-search report-input' + (errors['name'] ? ' error' : '')}
                                    onChange={(e) => setNewName(e.target.value)}
                                    onFocus={(e) => setErrors([])}
                                />
                                <div className={'error-msg'}>{errors['name']}</div>
                            </div>
                            <div className='error-msg text-center'>{errors['fields']}</div>
                            {vehicleReportFields.map(category =>
                                <div className='fields-category' key={category.name}>
                                    <h4 className='category-title'>{i18n.t(`constants.vehicleReportCategoryName.${category.name.replace(/\s/g, "_")}`)}</h4>
                                    <div className='category-fields'>
                                        {category.fields.map(field =>
                                            <div className='field-container' key={field.name}>
                                                <input type='checkbox'
                                                    readOnly={field.defaultSelected}
                                                    onChange={() => !field.defaultSelected && setField(field.fieldName)}
                                                    checked={newFields.includes(field.fieldName)}
                                                />
                                                <span className='field'>{i18n.t(`constants.vehicleReportFieldsName.${field.name.replace(/\s/g, "_")}`)}</span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                    {step === 2 &&
                        <div className='details-select'>
                            <h4 className='category-title'>
                                {t('titleReportOptions')}
                            </h4>
                            <div className='modal-column'>
                                {totalVehicles.length > 0 &&
                                    <VehicleSelectBox
                                        vehicles={totalVehicles}
                                        selectedVehicleIds={newVehicles}
                                        error={errors['assets']}
                                        onToggle={setVehicles}
                                    />
                                }
                            </div>
                        </div>
                    }
                    {step === 3 &&
                        <div className='report-category details-select'>
                            <h4 className='category-title'>
                                {t('titleShareOptions')}
                            </h4>
                            <div className='modal-column select-box'>
                                <div className='select-box-header'>
                                    <div className='column-title'>{t('titleUsers')}</div>
                                    <input type='checkbox' checked={allUsersChecked} onChange={selectAllUsers} className='select-all-checkbox' />
                                    <span className='select-all-label'>{t('selectAll') + newUsers.length + t('textSelected')}</span>
                                </div>
                                <SearchBar term={userSearch} onSearch={(e) => setUserSearch(e.target.value)} />
                                <div className='select-box-body'>
                                    <div className={'asset-select' + (allUsersChecked ? ' disabled' : '')}>
                                        {totalUsers.map((user) => {
                                            const name = user.first_name + ' ' + user.last_name;
                                            if (name && (!userSearch || name.toLowerCase().includes(userSearch.toLowerCase()))) {
                                                return (
                                                    <div className='asset-select-wrapper' key={user.email}>
                                                        <input type='checkbox' checked={newUsers.includes(user.email)} onChange={() => setUser(user.email)} />
                                                        <span className='asset-name'>{name + ' - ' + i18n.t(`constants.roleNames.${constants.roleNames[user.roles[0]].replace(/\s/g, "_")}`)}</span>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                {step === 1 &&
                    <CustomButton variant='primary' size='sm' onClick={nextStep}>{t('btnNext1')}</CustomButton>
                }
                {step === 2 && <>
                    <CustomButton variant="primary-outline" size="sm" onClick={goBack}>{t('btnBack2')}</CustomButton>
                    {['user', 'partner_view'].indexOf(userRole) > -1 ?
                        <CustomButton variant="primary" size="sm" onClick={saveReport}>{t('btnUpdate2')}</CustomButton> :
                        <CustomButton variant="primary" size="sm" onClick={() => setStep(3)}>{t('btnNext2')}</CustomButton>
                    }
                </>}
                {step === 3 && <>
                    <CustomButton variant='primary-outline' size='sm' onClick={() => setStep(2)}>{t('btnBack3')}</CustomButton>
                    <CustomButton variant='primary' size='sm' onClick={saveReport}>{t('btnUpdate3')}</CustomButton>
                </>}
            </Modal.Footer>
            {loaderModal}
        </Modal>
    )
}

export default connect(
    state => ({
        user: state.user.user,
        company: state.company.company,
        loaderModal: state.loader.loaderModal,
    }),
    dispatch => ({
        toggleLoaderModal: (show) => {
            dispatch({ type: 'TOGGLE_LOADER_MODAL', payload: show });
        }
    }),
)(EditReportModal);
