import React from 'react';
import { useTranslation } from 'react-i18next';

const LiveVideoErrorContainer = () => {
  const { t } = useTranslation();
  return (
    <div className="main-content" dangerouslySetInnerHTML={{ __html: t('components.liveVideoErrorContainer') }}></div>
  );
}

export default LiveVideoErrorContainer;
