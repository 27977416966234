import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import CustomButton from '../../CustomButton'
import '../../dump/menuComponents/menuComponents.sass'

const ModalUploadFile = ({ title = i18n.t('components.modalUploadFile.defaultTitle'), description = i18n.t('components.modalUploadFile.defaultDescription'), btnUploadTitle = i18n.t('components.modalUploadFile.defaultBtnUploadTitle'), exampleFileTitle = i18n.t('components.modalUploadFile.defaultExampleFileTitle'), exampleFileLink, acceptFileExtensions, onHide, onFileChange, onUploadFile }) => {
	const { t } = useTranslation('translation', { keyPrefix: 'components.modalUploadFile' });

	return (
		<Modal
			show
			onHide={onHide}
			className="modal-batch-function"
		>
			<Modal.Header closeButton>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="manage-users row">
					<div className="col-md-12">
						<div className="col-md-12 form-group">
							<div>{description}</div>
							<a href={exampleFileLink} style={{ float: 'right' }}>{exampleFileTitle}</a>
						</div>

						<div className="col-md-12 form-group">
							<div className="upload-file-form" style={{ marginTop: '35px', marginLeft: '25%', width: '280px' }}>
								<div id="upload-form-label" style={{ width: '176px' }} />
								<div className="selectbutton">{t('selectButton')}</div>
								<input type="file" accept={acceptFileExtensions} style={{ width: '278px' }} name="upload" id="upload-input" onChange={onFileChange} />
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<CustomButton
					variant="primary"
					onClick={onUploadFile}>
					{btnUploadTitle}
				</CustomButton>
			</Modal.Footer>
		</Modal>
	)
}

ModalUploadFile.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	exampleFileTitle: PropTypes.string,
	exampleFileLink: PropTypes.string,
	btnUploadTitle: PropTypes.string,
	acceptFileExtensions: PropTypes.string,

	onFileChange: PropTypes.func.isRequired,
	onUploadFile: PropTypes.func.isRequired,
	onHide: PropTypes.func.isRequired,
};

ModalUploadFile.defaultProps = {
	exampleFileLink: '/files/rosco_drivers_example.csv',
	acceptFileExtensions: '.csv, .xlsx, .xls',
};

export default ModalUploadFile;
