import React, { useState, useEffect } from 'react'
// import PropTypes from 'prop-types'
import { toastr } from 'react-redux-toastr'
import moment from 'moment'
// import momenttz from '../../../helpers/momenttz'
import _ from 'lodash'
import momentDurationFormatSetup from 'moment-duration-format'
import CustomButton from '../../../CustomButton'
import { useTranslation } from 'react-i18next'

import './CustomVideoRequest.sass'

momentDurationFormatSetup(moment)

const hourSelectorTemplate = [
	{ name: '12 am', hour: '00', checked: false, videos: '', hasVideo: false },
	{ name: '1 am', hour: '01', checked: false, videos: '', hasVideo: false },
	{ name: '2 am', hour: '02', checked: false, videos: '', hasVideo: false },
	{ name: '3 am', hour: '03', checked: false, videos: '', hasVideo: false },
	{ name: '4 am', hour: '04', checked: false, videos: '', hasVideo: false },
	{ name: '5 am', hour: '05', checked: false, videos: '', hasVideo: false },
	{ name: '6 am', hour: '06', checked: false, videos: '', hasVideo: false },
	{ name: '7 am', hour: '07', checked: false, videos: '', hasVideo: false },
	{ name: '8 am', hour: '08', checked: false, videos: '', hasVideo: false },
	{ name: '9 am', hour: '09', checked: false, videos: '', hasVideo: false },
	{ name: '10 am', hour: '10', checked: false, videos: '', hasVideo: false },
	{ name: '11 am', hour: '11', checked: false, videos: '', hasVideo: false },
	{ name: '12 pm', hour: '12', checked: false, videos: '', hasVideo: false },
	{ name: '1 pm', hour: '13', checked: false, videos: '', hasVideo: false },
	{ name: '2 pm', hour: '14', checked: false, videos: '', hasVideo: false },
	{ name: '3 pm', hour: '15', checked: false, videos: '', hasVideo: false },
	{ name: '4 pm', hour: '16', checked: false, videos: '', hasVideo: false },
	{ name: '5 pm', hour: '17', checked: false, videos: '', hasVideo: false },
	{ name: '6 pm', hour: '18', checked: false, videos: '', hasVideo: false },
	{ name: '7 pm', hour: '19', checked: false, videos: '', hasVideo: false },
	{ name: '8 pm', hour: '20', checked: false, videos: '', hasVideo: false },
	{ name: '9 pm', hour: '21', checked: false, videos: '', hasVideo: false },
	{ name: '10 pm', hour: '22', checked: false, videos: '', hasVideo: false },
	{ name: '11 pm', hour: '23', checked: false, videos: '', hasVideo: false },
]

const DetailsScenario = (props) => {
	const { availableVideos, setPropTime, userRole } = props
	const { t } = useTranslation('translation', { keyPrefix: 'modals.customVideoRequest.detailsScenario' });
	const [scale, setScale] = useState(5)
	// Each timeline bar has range in seconds, for the 1min scale beginning and end of range are the same, for the 5min scale are different
	const [clickedTime, setClickedTime] = useState({
		start: '',
		end: '',
	})
	const [hourSelector, setHourSelector] = useState(_.cloneDeep(hourSelectorTemplate))
	// const [timeline, setTimeline] = useState([])

	// console.log('hourSelector: ', hourSelector)
	// console.log('clickedTime: ', clickedTime)

	useEffect(() => {
		const sep = (xs, s) => xs.length ? [xs.slice(0, s), ...sep(xs.slice(s), s)] : []

		// console.log('availableVideos: ', availableVideos)
		const hourSelectorNew = _.cloneDeep(hourSelectorTemplate)
		if (availableVideos.length) {
			const separatedVideos = sep(availableVideos, 60)
			for (let i in separatedVideos) {
				const item = separatedVideos[i]
				// console.log('i: ', i, separatedVideos[i], hourSelectorNew[i])
				hourSelectorNew[i].videos = item
				hourSelectorNew[i].hasVideo = /[^0]/.test(item) // checks if hour video contains any character that is not equal to '0'
			}
			// console.log('hourSelectorNew: ', hourSelectorNew)
			setPropTime('', '', !!hourSelectorNew.filter(i => i.hasVideo).length)
		}
		setHourSelector(hourSelectorNew)
		// clears selected minute-bars
		setClickedTime({ start: '', end: '' })

	}, [availableVideos])

	useEffect(() => {
		const { start, end } = clickedTime
		// var date = new Date(0)
		// date.setSeconds(45); 
		// var timeString = date.toISOString().substring(11, 19)
		if (start && end) {
			const date1 = new Date(0)
			date1.setSeconds(start)
			const timeStart = date1.toISOString().substring(11, 19)

			const date2 = new Date(0)
			// date2.setSeconds(end + scale * 60 - 1)
			// date2.setSeconds(end + scale * 60)
			// date2.setSeconds(scale === 1 ? end + scale * 60 : end+1)
			date2.setSeconds(end + 60)
			const timeEnd = date2.toISOString().substring(11, 19)

			setPropTime(timeStart, timeEnd)
		}
		else if (start) {
			const date1 = new Date(0)
			date1.setSeconds(start)
			const timeStart = date1.toISOString().substring(11, 19)

			const date2 = new Date(0)
			// date2.setSeconds(start + scale * 60 - 1)
			// date2.setSeconds(start + scale * 60)
			// date2.setSeconds(scale === 1 ? start + scale * 60 : end+1)
			date2.setSeconds(end + 60)
			const timeEnd = date2.toISOString().substring(11, 19)

			setPropTime(timeStart, timeEnd)
		}
		else if (end) {
			const date1 = new Date(0)
			date1.setSeconds(end)
			const timeStart = date1.toISOString().substring(11, 19)

			const date2 = new Date(0)
			// date2.setSeconds(end + scale * 60 - 1)
			// date2.setSeconds(end + scale * 60)
			// date2.setSeconds(scale === 1 ? end + scale * 60 : end+1)
			date2.setSeconds(end + 60)
			const timeEnd = date2.toISOString().substring(11, 19)

			setPropTime(timeStart, timeEnd)
		}
		else {
			setPropTime('', '')
		}

	}, [clickedTime/*, scale*/])

	const onClickHourBtn = (hourArg) => {
		const hourSelectorNew = [...hourSelector]
		hourSelectorNew[hourArg].checked = !hourSelectorNew[hourArg].checked
		setHourSelector(hourSelectorNew)

		// Clears the time range of CVR if user unselected hour button that was in time range
		if (!hourSelectorNew[hourArg].checked) {
			const { start, end } = clickedTime
			const startOfHour = hourArg * 3600
			const endOfHour = (hourArg + 1) * 3600
			if (startOfHour <= start && start < endOfHour || startOfHour <= end && end < endOfHour) {
				setClickedTime({ start: '', end: '' })
			}
		}
	}

	const hourSelectorRender = () => {
		const hours = []

		for (let i in hourSelector) {
			const { name, hasVideo, checked } = hourSelector[i]
			hours.push(
				<CustomButton
					key={name}
					variant="primary"
					size="icon"
					prefix={`hour-btn${!hasVideo ? ' no-video' : ''}${checked ? ' selected' : ''} `}
					onClick={() => onClickHourBtn(i)}
					ga_params={{ category: userRole, action: 'Custom Video Request', label: 'Hour Btn' }}
				>
					{name}
				</CustomButton>
			)
		}

		return (
			<>
				<div >{hours.slice(0, 12)}</div>
				<hr />
				<div>{hours.slice(12)}</div>
			</>
		)
	}

	const onClickMinuteBar = (timeStartArg, timeEndArg) => {
		// console.log('onClickMinuteBar timeStartArg, timeEndArg: ', timeStartArg, timeEndArg)
		let clickedTimeNew = { ...clickedTime }

		if (scale === 1) {
			if (clickedTime.start === timeStartArg && clickedTime.end === timeStartArg) {
				clickedTimeNew.start = ''
				clickedTimeNew.end = ''
			}
			else if (clickedTime.start === timeStartArg) {
				clickedTimeNew.start = clickedTime.end
			}
			else if (clickedTime.end === timeStartArg) {
				clickedTimeNew.end = clickedTimeNew.start
			}
			else if (!clickedTime.start && !clickedTime.end) {
				clickedTimeNew.start = timeStartArg
				clickedTimeNew.end = timeStartArg
			}
			else if (!clickedTime.start) {
				clickedTimeNew.start = timeStartArg
			}
			else if (!clickedTime.end) {
				if (timeStartArg < clickedTime.start) {
					clickedTimeNew.start = timeStartArg
					clickedTimeNew.end = clickedTime.start
				}
				else {
					clickedTimeNew.end = timeStartArg
				}
			}
			else if (clickedTime.start > timeStartArg) {
				clickedTimeNew.start = timeStartArg
			}
			else if (clickedTime.end < timeStartArg) {
				clickedTimeNew.end = timeStartArg
			}
			else if (clickedTime.end - timeStartArg < timeStartArg - clickedTime.start) {
				clickedTimeNew.end = timeStartArg
			}
			else {
				clickedTimeNew.start = timeStartArg
			}

			if (clickedTimeNew.start && clickedTimeNew.end && clickedTimeNew.end - clickedTimeNew.start > 3600) {
				toastr.error(t('toastrErrDuration'))
			}
			else {
				setClickedTime({ ...clickedTimeNew })
			}
		}
		else {
			if (!clickedTime.start && !clickedTime.end) {
				clickedTimeNew.start = timeStartArg
				clickedTimeNew.end = timeEndArg
			}
			else if (clickedTime.start === timeStartArg && clickedTimeNew.end === timeEndArg) {
				clickedTimeNew.start = ''
				clickedTimeNew.end = ''
			}
			else if (timeStartArg <= clickedTime.start && clickedTime.start <= timeEndArg) {
				clickedTimeNew.start = timeEndArg + 60
			}
			else if (timeStartArg <= clickedTime.end && clickedTime.end <= timeEndArg) {
				clickedTimeNew.end = timeStartArg - 60
			}
			else if (clickedTime.start > timeStartArg) {
				clickedTimeNew.start = timeStartArg
			}
			else if (clickedTime.end < timeEndArg) {
				clickedTimeNew.end = timeEndArg
			}
			else if (clickedTime.end - timeStartArg < timeStartArg - clickedTime.start) {
				clickedTimeNew.end = timeEndArg
			}
			else {
				clickedTimeNew.start = timeStartArg
			}

			if (clickedTimeNew.start && clickedTimeNew.end && clickedTimeNew.end - clickedTimeNew.start > 3600) {
				toastr.error(t('toastrErrDuration'))
			}
			else {
				setClickedTime({ ...clickedTimeNew })
			}
		}
	}

	const timelineRender = () => {
		// const res = []
		const timeline = []
		let prevBarIsVideo
		const selectedHours = hourSelector.filter(({ checked }) => checked)
		// console.log('selectedHours: ', selectedHours)

		for (let i in selectedHours) {
			const { name, hour, videos } = selectedHours[i]
			const videoScaled = videos // for scale = 1min
			// console.log('videoScaled: ', videoScaled)

			timeline.push(<div className="timeline-hour">{name}</div>)

			const secondsPerHour = []
			for (let j = 0; j < videoScaled.length; j = j + scale) {
				// const isVideo = videoScaled.charAt(i) !== '0' // verifyes if curent char contains 1 or 2 values
				// const isVideo = !![...videoScaled.slice(i, i + scale)].filter((item) => item !== '0').length // verifyes if curent char contains 1 or 2 values
				// For 1min scale videoScaled.slice is one value (1), for 5min scale the videoScaled.slice containes 5 values (00011)
				let positionVideo = [...videoScaled.slice(j, j + scale)].findIndex(item => item !== '0')
				let positionLastVideo = [...videoScaled.slice(j, j + scale)].findLastIndex(item => item !== '0')
				const isVideo = positionVideo > -1 // detects if videoScaled.slice containes video
				// For the 1 min scale positionVideo always is -1 or 0.
				positionVideo = positionVideo === -1 ? 0 : positionVideo
				positionLastVideo = positionLastVideo === -1 ? 0 : positionLastVideo

				// Each bar contains count of seconds from 00:00:00 (beginnig) of the day. 
				// If scale is 5mins then videoScaled.slice can contain 00011 (for example: 00 - 00000, 05 - 00011, 10 - 11111)
				// and when user clicks on the 05 min bar we have not to assign Start Time = 05:00 but 08:00 because video for 5,6 and 7 minutes is absent.
				const seconds = hour * 3600 + j * 60 + positionVideo * 60
				const secondsLast = hour * 3600 + j * 60 + positionLastVideo * 60
				// const nextBarseconds = hour * 3600 + (i + scale) * 60 + positionVideo * 60
				// const prevBarseconds = hour * 3600 + (i - scale) * 60 + positionVideo * 60
				const minute = j < 10 ? '0' + j : j
				// console.log('i: ', i, videoScaled[i], seconds, secondsLast, /*prevBarseconds,  nextBarseconds,*/ isVideo, minute, videoScaled.slice(i, i + scale), [...videoScaled.slice(i, i + scale)].findIndex(item => item !== '0'))
				// Detects if current bar should be marked as selected
				// const isSelectedMinute = (clickedTime.start && seconds === clickedTime.start)
				// 	|| (clickedTime.end && seconds === clickedTime.end)
				// 	|| (clickedTime.start && clickedTime.end && seconds > clickedTime.start && seconds < clickedTime.end)

				secondsPerHour.push(({ isVideo, minute, seconds, secondsLast }))
			}

			// console.log('secondsPerHour: ', secondsPerHour)

			// const timeline = []
			for (let j = 0; j < secondsPerHour.length; j++) {
				const { isVideo, minute, seconds, secondsLast } = secondsPerHour[j]
				// const lastSeconds = j === (secondsPerHour.length - 1) ? hour * 3600 + 60 * 60 : secondsPerHour[j+1].seconds
				// const lastSeconds = scale === 1 ? seconds : j === (secondsPerHour.length - 1) ? hour * 3600 + 60 * 60 - 1 : secondsPerHour[j+1].seconds - 1
				const lastSeconds = secondsLast
				const isSelectedMinute =
					scale === 1 ?
						(clickedTime.start && seconds === clickedTime.start)
						|| (clickedTime.end && seconds === clickedTime.end)
						|| (clickedTime.start && clickedTime.end && seconds > clickedTime.start && seconds < clickedTime.end)
						:
						(clickedTime.start && clickedTime.end && (clickedTime.start <= seconds && clickedTime.end >= seconds || clickedTime.start <= lastSeconds && clickedTime.end >= lastSeconds || clickedTime.start <= lastSeconds && clickedTime.start >= seconds || clickedTime.end <= lastSeconds && clickedTime.end >= seconds))
				// (clickedTime.start && clickedTime.end && (clickedTime.start < lastSeconds && clickedTime.start >= seconds || clickedTime.end < lastSeconds && clickedTime.end >= seconds ))
				// || (clickedTime.end && seconds <= clickedTime.end && clickedTime.end > lastSeconds)
				// || (clickedTime.start && seconds <= clickedTime.start &&  clickedTime.start < lastSeconds )
				// (clickedTime.start &&  clickedTime.start >= seconds && clickedTime.start <= lastSeconds)
				// || (clickedTime.end && seconds >= clickedTime.end && clickedTime.end >= lastSeconds)
				//|| (clickedTime.start && clickedTime.end && seconds > clickedTime.start && seconds < clickedTime.end)

				// console.log('secondsPerHour[j]: ', j, secondsPerHour[j], lastSeconds, isSelectedMinute)
				// console.log('clickedTime: ', clickedTime)
				// console.log('timeline: ', timeline)

				if (!isVideo && prevBarIsVideo === true) {
					// Get the last timeline-minute component in the timeline array
					const lastTimelineComponent = timeline[timeline.length - 1]

					// Replace the class of the last component by recreating it with a different class
					timeline[timeline.length - 1] = (
						<div className="timeline-minute end-video-block">
							{lastTimelineComponent.props.children} {/* Keep the same content */}
						</div>
					)
				}

				timeline.push(
					// <div className={`timeline-minute${isVideo && strtEndVideoBlockMarker !== isVideo ? ' start-video-block' : ''} ${isVideo && !secondsPerHour[j+1].isVideo ? ' end-video-block' : ''}`}>
					<div className={`timeline-minute${isVideo && !prevBarIsVideo ? ' start-video-block' : ''}`}>
						<div>{minute}</div>
						<div className="bar">
							{isVideo &&
								<CustomButton
									variant="primary"
									prefix={`hour-btn${isSelectedMinute ? ' selected' : ''}`}
									ga_params={{ category: userRole, action: 'Custom Video Request', label: 'Minute Btn' }}
									onClick={() => onClickMinuteBar(seconds, lastSeconds)}
								/>
							}
						</div>
					</div>
				)
				prevBarIsVideo = isVideo
			}

			// res.push(
			// 	<>
			// 		<div className="timeline-hour">{name}</div>
			// 		{timeline}
			// 	</>
			// )
		}

		// return res
		return timeline
	}

	return (
		<div className="details-scenario text-center">
			<div className="text">{t('textBlock')}</div>

			<div className='tab-hours-btn'>
				<div>
					<CustomButton variant="primary" size="icon" prefix="hour-btn no-hover">
						12 am
					</CustomButton>
					<li><span>{t('tabHoursBtn1')}</span></li>
				</div>
				<div>
					<CustomButton variant="primary" size="icon" prefix="hour-btn no-hover selected">
						12 am
					</CustomButton>
					<li><span>{t('tabHoursBtn2')}</span></li>
				</div>
				<div>
					<CustomButton variant="primary" size="icon" prefix="hour-btn no-hover no-video">
						12 am
					</CustomButton>
					<li><span>{t('tabHoursBtn3')}</span></li>
				</div>
			</div>

			<div className="hour-selector-container">
				<div className="hour-selector-menu">
					{hourSelectorRender()}
				</div>
			</div>

			<div className="text">{t('textBlockTimeline')}</div>

			<label>{t('titleTimelineScale')}</label>
			<div className="tab-duration-btn">
				<CustomButton variant="primary-outline" size="sm" prefix={`hour-btn ${scale === 1 && ' selected no-hover'}`} style={{ marginRight: '10px' }}
					ga_params={{ category: userRole, action: 'Custom Video Request', label: 'Timeline Scale 1' }}
					onClick={() => setScale(1)}
				>
					1 Minute
				</CustomButton>
				<CustomButton variant="primary-outline" size="sm" prefix={`hour-btn ${scale === 5 && ' selected no-hover'}`}
					ga_params={{ category: userRole, action: 'Custom Video Request', label: 'Timeline Scale 5' }}
					onClick={() => setScale(5)}
				>
					5 Minute
				</CustomButton>
			</div>

			{!!hourSelector.filter(i => i.checked).length && (
				<div className="video-availability-timeline">
					{timelineRender()}
				</div>)}
		</div>
	);
}

// DetailsScenarion.propTypes = {
// 	user: PropTypes.objectOf(PropTypes.any).isRequired,
// 	company: PropTypes.objectOf(PropTypes.any).isRequired,
// 	propsSelectedDevice: PropTypes.objectOf(PropTypes.any),
// 	devices: PropTypes.arrayOf(PropTypes.any),
// 	onHide: PropTypes.func.isRequired,
// 	onSave: PropTypes.func.isRequired
// }

// DetailsScenarion.defaultProps = {
// 	propsSelectedDevice: '',
// 	devices: []
// };

export default DetailsScenario;
