import React, { useState, useEffect } from 'react'
import { Marker } from 'react-google-maps'
import { toastr } from 'react-redux-toastr'
import { connect } from 'react-redux'
import _ from 'lodash'

import MarkerAlertPopup from './MarkerAlertPopup'
import { getEventById, getEventSnapshots } from '../../../action/AlertActions'

const MarkerAlertNode = (props) => {
	const { eventId, eventType, position, timestamp, selectedTripsEventIdToShowPopup, setSelectedTripsEventIdToShowPopup, toggleLoader } = props;
	// console.log('PROPS MarkerAlertNode: ', props)

	const [state, setState] = useState({
		// showAlertWindow: false, -- FWSD-4482
		event: null,
		snapshots: []
	});

	// useEffect(() => {
	// 	// setState({ ...state, showAlertWindow: false })
	// 	// setSelectedTripsEventIdToShowPopup(null)
	// }, [])

	useEffect(() => {
		// debugger
		if (+props.selectedDropdownEventId === +props.eventId) {
			setSelectedTripsEventIdToShowPopup(null)
			handleAlertClick()
			// setState({ ...state, showAlertWindow: false });
			// handleAlertClick();
		}

		return () => setSelectedTripsEventIdToShowPopup(null)
	}, [props.selectedDropdownEventId, props.eventId]);

	const handleAlertClick = () => {
		const { eventId, user, company, setSelectedDropdownEventId, selectedTripsEventIdToShowPopup, setSelectedTripsEventIdToShowPopup } = props;

		// if (showAlertWindow) {
		// 	setState({ ...state, showAlertWindow: false });
		//  props.setSelectedDropdownEventId(null);
		// } else {
		if (selectedTripsEventIdToShowPopup === eventId) {
			setSelectedTripsEventIdToShowPopup(null)
			setSelectedDropdownEventId(null)
		} else {
			toggleLoader(true)

			const params = {
				user_role: user.roles[0],
				partner_name: company.partner,
				company_name: company.name,
				event_id: eventId
			}

			getEventById(params)
				.then((resEvent, errEvent) => {
					console.log('handleAlertClick getEventById resEvent, errEvent: ', resEvent, errEvent);

					getEventSnapshots(params)
						.then((resSnapshots, errSnapshots) => {
							toggleLoader(false);
							console.log('handleAlertClick getEventSnapshots resSnapshots, errSnapshots: ', resSnapshots, errSnapshots);

							setState({
								...state,
								event: resEvent.data.response.event,
								snapshots: resSnapshots.data.response.snapshots_urls,
								// showAlertWindow: true
							})
							setSelectedTripsEventIdToShowPopup(eventId)
						})
						.catch((errorSnapshots) => {
							toggleLoader(false);
							console.log('handleAlertClick getEventSnapshots errorSnapshots: ', errorSnapshots);

							setState({
								...state,
								event: resEvent.data.response.event,
								snapshots: [],
								// showAlertWindow: true
							})
							setSelectedTripsEventIdToShowPopup(eventId)
						});
				})
				.catch((errorEvent) => {
					toggleLoader(false);
					console.log('handleAlertClick getEventById errorEvent: ', errorEvent);

					setState({
						...state,
						event: null,
						snapshots: []
					})
				});

		}
	}

	const onCloseAlertWindow = () => {
		// setState({ ...state, showAlertWindow: false })
		props.setSelectedTripsEventIdToShowPopup(null)
		props.setSelectedDropdownEventId(null)
	}

	const setTripAdvancedPlayer = () => {
		console.log('setTripAdvancedPlayer!!!!!!')
		const { setTripAdvancedPlayerEvent } = props

		console.log('setTripAdvancedPlayerEvent event: ', state.event)
		setTripAdvancedPlayerEvent(state.event)
	}

	const setProps = (states) => {
		setState({ ...state, ...states })
	}

	// console.log('PROPS MarkerNode: ', props)
	const alertSymbol = {
		path: 'M19.64 16.36L11.53 2.3A1.85 1.85 0 0 0 10 1.21 1.85 1.85 0 0 0 8.48 2.3L.36 16.36C-.48 17.81.21 19 1.88 19h16.24c1.67 0 2.36-1.19 1.52-2.64zM11 16H9v-2h2zm0-4H9V6h2z',
		fillColor: '#f28333',
		strokeColor: '#fff',
		strokeOpacity: 1,
		strokeWeight: 0,
		fillOpacity: 1,
		scale: 1,
		anchor: new google.maps.Point(10, 10),
	}

	return (
		<Marker
			position={position}
			icon={alertSymbol}
			onClick={handleAlertClick}
		>
			{/* {showAlertWindow */}
			{selectedTripsEventIdToShowPopup && selectedTripsEventIdToShowPopup === eventId
				&& (
					<MarkerAlertPopup
						eventId={eventId}
						eventType={eventType}
						snapshots={state.snapshots}
						event={state.event}
						setTripAdvancedPlayer={setTripAdvancedPlayer}
						onCloseAlertWindow={onCloseAlertWindow}
						setProps={setProps}
						{...props}
					/>
				)}
		</Marker>
	)
	// return (
	// 	<Marker
	// 		position={position}
	// 		icon={alertSymbol}
	// 	/>
	// )
}

const mapStateToProps = ({ user, company, dashboardData }) => ({
	user: user.user,
	company: company.company,
	selectedDropdownEventId: dashboardData.selectedDropdownEventId,
	selectedTripsEventIdToShowPopup: dashboardData.selectedTripsEventIdToShowPopup,
});

const mapDispatchToProps = dispatch => ({
	setTripAdvancedPlayerEvent: (state) => {
		dispatch({ type: 'SET_TRIP_ADVANCED_PLAYER_EVENT', payload: state })
	},
	setSelectedDropdownEventId: (state) => {
		dispatch({ type: 'SET_SELECTED_DROPDOWN_EVENT_ID', payload: state })
	},
	setSelectedTripsEventIdToShowPopup: (state) => {
		dispatch({ type: 'SET_SELECTED_TRIPS_EVENT_ID_TO_SHOW_POPUP', payload: state })
	},
	toggleLoader: (show) => {
		dispatch({ type: 'TOGGLE_LOADER', payload: show });
	}
})

export default connect(mapStateToProps, mapDispatchToProps)(MarkerAlertNode);
