import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { toastr } from 'react-redux-toastr';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { updateUserAccount, saveSettings, getUserNotifications } from '../../../action/AccountActions';
import AccountNotificationsContainer from './AccountNotificationsContainer';
import IconTooltip from '../../dump/menuComponents/IconTooltip';
import CustomButton from '../../CustomButton';

const AccountUserContainer = (props) => {
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'accountPage.accountUserContainer' });
	const { user, updateUser, toggleLoader } = props;
	const userRole = user.roles[0];

	const [data, setData] = useState({ first_name: null, last_name: null })
	const [errMessage, setErrMessage] = useState({ first_name: '', last_name: '' })
	const [disabledButton, setDisabledButton] = useState(true)

	useEffect(() => {
		if (['storage_user', 'provisioning', 'partner_view', 'sales_demo', 'custom_user'].includes(userRole)) {
			const initData = user;
			initData.time_zone = user.settings.time_zone || '';

			setData(initData);
		}
		else {
			getUserSettings()
		}
	}, [])

	const getUserSettings = () => {
		toggleLoader(true);
		getUserNotifications(user.email, userRole)
			.then(res => {
				setData({ ...res.data.response.user, time_zone: user.settings.time_zone || '' })
				toggleLoader(false);
			})
			.catch(err => {
				toggleLoader(false);
			})
	}

	const fillForm = (e) => {
		const { target } = e;
		const { name } = target;

		const value = target.type === 'checkbox' ? target.checked : target.value;

		setData({ ...data, ...{ [name]: value } });
		setDisabledButton(false)
	}

	const checkForm = (data, e) => {
		let error = false;
		const updatedErrMessage = { ...errMessage }

		for (let i in data) {
			if (data[i] === '' && (i === 'first_name' || i === 'last_name')) {
				error = true;
				updatedErrMessage[i] = t('errEnterYour') + i + '!';
			} else if (updatedErrMessage[i]) {
				updatedErrMessage[i] = null;
			}
		}

		setErrMessage(updatedErrMessage);
		return error;
	}

	const sendForm = (e) => {
		e.preventDefault();

		const error = checkForm(data, e);
		if (!error && !disabledButton) {
			toggleLoader(true);

			updateUserAccount(data, userRole)
				.then((res, err) => {
					saveSettings(data)
						.then((res1, err1) => {
							toggleLoader(false);

							const paramUser = res.data.response.user
							paramUser.settings.time_zone = res1.data.response.settings.time_zone
							updateUser(paramUser);
							toastr.success('', t('userChangesSaved'));
							setDisabledButton(true)
						})
						.catch((error) => {
							toggleLoader(false);
							toastr.error(t('unableUpdateSettings'))
						});
				})
				.catch((error) => {
					toggleLoader(false);
					toastr.error(t('unableUpdateSettings'))
				});
		}
	}

	const { time_zone, notifications, first_name, last_name, email, speed_unit } = data;

	const readOnly = ['storage_user', 'provisioning', 'partner_view', 'sales_demo', 'custom_user'].indexOf(userRole) > -1;

	return (
		<>
			{!readOnly
				&& (
					<div className='page-subheader'>
						<CustomButton variant='secondary' size='lg' prefix='save-changes' disabled={disabledButton} onClick={sendForm}>
							{t('btnSaveChanges')}
						</CustomButton>
					</div>
				)}

			<main className="main-content-block">
				<section>
					<h3 className='category-title'>{t('titleProfile')}</h3>
					<div className="account-settings two-column-grid with-margin">
						<form onSubmit={sendForm}>
							<h5 className='category-subtitle'>
								{t('subtitleMyInfo')} <label className='subtitle-label'>{email}</label>
							</h5>
							<div className={classnames('two-column-grid', { 'has-error': (!first_name && first_name !== null) || (!last_name && last_name !== null) })}>
								<div>
									<input type="text" readOnly={readOnly} name="first_name" className="vehicle-search" onChange={fillForm} value={first_name || ''} />
									{(!first_name && first_name !== null) && <span className="help-block">{t('enterFirstName')}</span>}
								</div>
								<div>
									<input type="text" readOnly={readOnly} name="last_name" className="vehicle-search" onChange={fillForm} value={last_name || ''} />
									{(!last_name && last_name !== null) && <span className="help-block">{t('enterLastName')}</span>}
								</div>
							</div>
						</form>

						<div className='region-settings'>
							<h5 className='category-subtitle'>
								{t('subtitleRegionSettings')}
							</h5>
							<div>
								<div className='user-timezone'>
									<IconTooltip
										description={t('userTimezoneDescription')}
										id="vehicle-name-tooltip"
									/>
									<label className="user-settings-label">{t('labelTimezone')}</label>
									<select placeholder="select" disabled={readOnly} className="rosco-select timezone-select" name="time_zone" id="time_zone" value={time_zone} onChange={fillForm}>
										<option value="">{t('optionTimezone')}</option>
										<option value="America/Anchorage">US/Alaska</option>
										<option value="America/Chicago">US/Central</option>
										<option value="America/Denver">US/Mountain</option>
										<option value="America/Detroit">US/Michigan</option>
										<option value="America/Fort_Wayne">US/East-Indiana</option>
										<option value="America/Indiana/Knox">US/Indiana-Starke</option>
										<option value="America/Los_Angeles">US/Pacific</option>
										<option value="America/New_York">US/Eastern</option>
										<option value="America/Phoenix">US/Arizona</option>
										<option value="Pacific/Honolulu">US/Hawaii</option>
									</select>
								</div>
								<div className="speed-units-control">
									<IconTooltip
										description={t('descriptionspeedUnit')}
										id="speed-unit-tooltip"
									/>
									<label className="user-settings-label">{t('labelspeedUnit')}</label>
									{!readOnly ?
										<div className='toggle-buttons button-group'>
											<CustomButton
												variant='toggle'
												size='sm'
												active={speed_unit === 'mph'}
												value='mph'
												name='speed_unit'
												style={{ border: "none" }}
												onClick={fillForm}
											>
												MPH
											</CustomButton>
											<CustomButton
												variant='toggle'
												size='sm'
												active={speed_unit === 'kph'}
												value='kph'
												name='speed_unit'
												style={{ border: "none" }}
												onClick={fillForm}
											>
												KPH
											</CustomButton>
										</div> :
										<span>{speed_unit ? speed_unit.toUpperCase() : ''}</span>
									}
								</div>
							</div>
						</div>
					</div>
				</section>
				{!readOnly &&
					<AccountNotificationsContainer
						userEmail={email}
						userRole={userRole}
						notifications={userRole !== 'storage_manager' ? notifications : data}
						fillForm={fillForm}
						getNotifications={getUserSettings}
					/>
				}
			</main>

		</>
	);
}

export default connect(
	state => ({
		user: state.user.user
	}),
	dispatch => ({
		updateUser: (user) => {
			dispatch({ type: 'GET_USER_SUCCESS', payload: user });
		},
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(AccountUserContainer);
