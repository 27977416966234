import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap'
import Toggle from 'react-toggle'
import PhoneInput from 'react-phone-input-2'
import Select from 'react-select'
import { toastr } from 'react-redux-toastr'
import _ from 'lodash'
import CustomButton from '../../CustomButton'
import { useTranslation } from 'react-i18next'

import SMSAgreementModal from './SMSAgreementModal'
import VehicleSelectBox from '../../dump/menuComponents/VehicleSelectBox'
import { getDevicesList } from '../../../action/DeviceActions'
import { DataPoolUsageOptions, DataPoolWarningOptions, VehicleMaxDataOverage } from '../../helpers/helperNotifications'

import './Modal.sass'
import 'react-phone-input-2/lib/style.css'

const NotificationsModal = (props) => {
    const { t, i18n } = useTranslation('translation', { keyPrefix: 'accountPage.accountNotificationsContainer.notificationsModal' });
    const { user, company, notification, selectedName, selectedTitle, userEmail, readOnly, loaderModal, toggleLoaderModal } = props;
    let initPhone_number = notification.phone_number;

    if (selectedName === 'input_sensor') {
        const obj = notification.find(({ sms }) => sms)
        if (obj) {
            initPhone_number = obj.phone_number
        }
    }

    if (!initPhone_number && user.phone_number && user.phone_number !== '12345678') {
        initPhone_number = user.phone_number;
    }

    // const [sms, setSms] = useState(selectedName === 'input_sensor' ? _.some(notification, {'sms': true}) : notification.sms)
    const [sms, setSms] = useState(notification.sms)
    const [email, setEmail] = useState(notification.email)
    const [vehicles, setVehicles] = useState(selectedName === 'input_sensor' ? notification[0].vehicles || [] : notification.vehicles || [])
    // const [vehicles_email, setVehicles_email] = useState(notification.vehicles_email || [])
    const [phone_number, setPhone_number] = useState(initPhone_number)
    const [groupSearch, setGroupSearch] = useState('')
    const [vehicleSearch, setVehicleSearch] = useState('')
    const [errors, setErrors] = useState({})
    const [totalVehicles, setTotalVehicles] = useState([])
    const [phoneError, setPhoneError] = useState('')
    const [showSensors, setShowSensors] = useState(selectedName === 'input_sensor')
    // const [sensors, setSensors] = useState(selectedName === 'input_sensor' && notification)
    const [sensors, setSensors] = useState(selectedName === 'input_sensor' && JSON.parse(JSON.stringify(notification)))
    const [time, setTime] = useState(selectedName === 'alert_inactive_vehicles' && notification.time)
    // const [vehicle_limit, setVehicle_limit] = useState(['data_pool_warning', 'data_overage_notification'].includes(selectedName) && notification.vehicle_limit)
    const [vehicle_limit, setVehicle_limit] = useState('')
    const [showSMSAgreementModal, setShowSMSAgreementModal] = useState(false)
    const [warning, setWarning] = useState(false)

    useEffect(() => {
        toggleLoaderModal(true);
        getDevicesList(company.company_id)
            .then(res => {
                let totalVehicles = res.data.response.devices
                if (props.webfleetDeviceId) {
                    const arr = webfleetDeviceId.split(',')
                    totalVehicles = totalVehicles.filter(item => arr.includes(item.device_id))
                }
                setTotalVehicles(totalVehicles)
                toggleLoaderModal(false);
            })
            .catch(err => {
                toggleLoaderModal(false);
                console.log(err);
                toastr.error(i18n.t('errorMessage.errDescription'));
            });

        setDefaultVehicleLimit()
    }, [])

    const setDefaultVehicleLimit = () => {
        switch (selectedName) {
            case 'data_pool_usage':
                setVehicle_limit(notification.frequency)
                break
            case 'data_pool_warning':
                setVehicle_limit(notification.threshold)
                break
            default:
                break
        }
    }

    const selectVehicles = (vehicles) => {
        if (vehicles.length === totalVehicles.filter((vehicle) => !!vehicle.activated_timestamp).length) {
            toastr.warning(t('vehiclesExcessiveSMS'))
            setWarning(true)
        }
        setVehicles(vehicles)
        setErrors({});
    }

    const toggleEmail = (e) => {
        if (selectedName === 'input_sensor') {
            const updatedSensors = [...sensors];
            if (e.target.checked) {
                if (updatedSensors.filter(sensor => sensor.name).length === 0) {
                    toastr.warning(t('enterNameSensor'))
                }
                else {
                    updatedSensors.forEach(sensor => {
                        if (sensor.name) {
                            sensor.email = true
                        }
                    })
                }
            }
            else {
                updatedSensors.forEach(sensor => {
                    sensor.email = false
                })
            }
            setSensors(updatedSensors)
        }
        else if (e.target.checked) {
            if (!sms) {
                const vehicleIds = []
                totalVehicles.forEach((vehicle) => {
                    if (!!vehicle.activated_timestamp) vehicleIds.push(vehicle.device_id)
                })

                setVehicles(vehicleIds)
            }
            setEmail(true)
        } else {
            if (!sms) {
                setVehicles([])
            }
            setEmail(false)
        }
    }

    const toggleSms = (e) => {
        if (selectedName === 'input_sensor') {
            const updatedSensors = [...sensors];
            if (e.target.checked) {
                if (updatedSensors.filter(sensor => sensor.name).length === 0) {
                    toastr.warning(t('enterNameSensor'))
                }
                else {
                    updatedSensors.forEach(sensor => {
                        if (sensor.name) {
                            sensor.sms = true
                        }
                    })
                }
            }
            else {
                updatedSensors.forEach(sensor => {
                    sensor.sms = false
                })
            }

            setSensors(updatedSensors)
        }
        else if (e.target.checked) {
            setSms(true)
        } else {
            if (!email) {
                setVehicles([])
            }
            setSms(false)
        }
    }

    const changeInactiveVehiclesTime = (e) => {
        setTime(e.target.value * 1)
    }

    const changeDataUsageVehicleLimit = (limits) => {
        setVehicle_limit(limits.map(i => i = +i.value).sort().join(","))
    }

    const enableSensor = (e, index) => {
        const updatedSensors = [...sensors];
        if (e.target.checked && !updatedSensors[index].name) {
            toastr.warning(t('enterNameSensor'))
        } else {
            updatedSensors[index].email = e.target.checked
            updatedSensors[index].sms = e.target.checked
            setSensors(updatedSensors)
        }
    }

    const changeSensorValue = (e, index) => {
        const updatedSensors = [...sensors];
        updatedSensors[index][e.target.name] = e.target.value;
        setSensors(updatedSensors)
    }

    const checkForm = () => {
        if (vehicles.length === totalVehicles.filter((vehicle) => !!vehicle.activated_timestamp).length) {
            if (!warning) {
                toastr.warning(t('vehiclesExcessiveSMS'));
                setWarning(true);
                return false
            }
        }
        if (sms && !phone_number) {
            setPhoneError(t('enterPhoneNumber'))
            return false
        }
        else if ((sms || email) && !vehicles.length) {
            setErrors({ vehicles: t('selectVehicle') })
            return false
        }
        else {
            return true
        }
    }

    const showAgreementModal = () => {
        if (checkForm()) {
            setShowSMSAgreementModal(true)
        }
    }

    const onSubmit = () => {
        if (checkForm()) {
            let data = {};
            if (selectedName !== 'input_sensor') {
                data[selectedName] = {
                    email: email,
                    sms: sms || false,
                    phone_number: sms ? phone_number : '',
                    vehicles: (sms || email) ? vehicles : []
                }

                switch (selectedName) {
                    case 'alert_inactive_vehicles':
                        data[selectedName].time = time
                        break
                    case 'data_pool_usage':
                        data[selectedName].frequency = vehicle_limit
                        break
                    case 'data_pool_warning':
                        data[selectedName].threshold = vehicle_limit
                        break
                }
            }
            else {
                for (let index = 0; index < 4; index++) {
                    data['inputsensor' + (index + 1)] = {
                        email: sensors[index].email,
                        sms: sensors[index].sms,
                        phone_number: phone_number || '',
                        vehicles: vehicles,
                        name: sensors[index].name,
                        description: sensors[index].description
                    }
                }
            }
            props.updateNotifications(data);
        }
    }

    console.log('PROPS NotificationsModal: ', props)
    console.log('STATES NotificationsModal (sms, user.email, userEmail, user.has_registered_sms: ', sms, user.email, userEmail, user.has_registered_sms)

    const smsEnabled = ['event_breakdown', 'recording_status_notifs', 'reformat_reminder', 'alert_summary', 'audit_notification', 'daily_recording_status_notifs', 'daily_new_cameras_notifs', 'alert_camera_errors', 'data_pool_usage', 'data_pool_warning', 'device_high_data_usage'].indexOf(selectedName) === -1;
    const notificationOptionsDisabled = ['daily_recording_status_notifs', 'daily_new_cameras_notifs'].includes(selectedName);

    const sensorsEmail = (showSensors && sensors.filter(sensor => sensor.email).length) ? true : false;
    const sensorsSms = (showSensors && sensors.filter(sensor => sensor.sms).length) ? true : false;

    return (
        <Modal
            show
            onHide={() => props.hideNotificationsModal(false)}
            className="notifications-modal modal-lg-size"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-lg">{selectedTitle + t('titleNotification')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="notifications-modal-body">
                    <section>
                        <h4 className="category-title">{t('titleNotificationType')}</h4>
                        <div className="type-select">
                            <span className="notif-type">{t('typeEmail')}</span>
                            <Toggle
                                name={notification.name}
                                checked={email || sensorsEmail}
                                onChange={toggleEmail}
                            />
                            {smsEnabled
                                ? (
                                    <span>
                                        <span className="notif-type">{t('typeSMS')}</span>
                                        <Toggle
                                            disabled={readOnly}
                                            name={notification.name}
                                            checked={sms || sensorsSms}
                                            onChange={toggleSms}
                                        />
                                    </span>
                                ) : (
                                    <span className="notif-type">{t('SMSNotAvailable')}</span>
                                )
                            }
                            {selectedName === 'alert_inactive_vehicles' && (
                                <span>
                                    <span>{t('daysInactive')}</span>
                                    <select
                                        name="alert_inactive_vehicles_time"
                                        id="alert_inactive_vehicles_time"
                                        value={time || 5}
                                        onChange={changeInactiveVehiclesTime}
                                        className="rosco-select small"
                                    >
                                        {
                                            _.range(1, 6).map(item => <option key={item} value={item}>{item}</option>)
                                        }
                                    </select>
                                </span>
                            )}
                            {(sms || sensorsSms) && !readOnly &&
                                <div className="phone-number">
                                    <PhoneInput
                                        country={'us'}
                                        value={phone_number}
                                        onChange={phone_number => {
                                            setPhone_number(phone_number);
                                            setPhoneError('');
                                        }}
                                    // isValid={(value, country) => {
                                    // 	if (value.match(/12345/)) {
                                    // 	  return 'Invalid value: '+value+', '+country.name;
                                    // 	} else if (value.match(/1234/)) {
                                    // 	  return false;
                                    // 	} else {
                                    // 	  return true;
                                    // 	}
                                    //   }}
                                    />
                                    <div className="error-msg">{phoneError}</div>
                                </div>
                            }
                            {/* {selectedName === 'data_usage_notification' &&
                                <div>
                                    <span>Alert me when a vehicle has exceeded: </span>
                                    <select
                                        name="data_usage_notification_vehicle_limit"
                                        id="data_usage_notification_vehicle_limit"
                                        value={vehicle_limit || 100}
                                        onChange={changeDataUsageVehicleLimit}
                                        className="rosco-select small"
                                    >
                                        {
                                            [100, 200, 300].map(item => <option key={item} value={item}>{item}%</option>)
                                        }
                                    </select>
                                    <span> of its data limit.</span>
                                </div>
                            } */}
                            {selectedName === 'data_pool_usage' &&
                                <div className="block_notification">
                                    <span style={{ marginRight: '5px' }}>{t('periodReport')}</span>
                                    <Select
                                        isMulti={false}
                                        options={DataPoolUsageOptions.filter(({ value: val }) => !vehicle_limit.split(",").some(i => +i === +val))}
                                        onChange={(frequency) => setVehicle_limit(frequency.value)}
                                        placeholder={t('placeholderFrequency')}
                                        value={DataPoolUsageOptions.find(({ value }) => value === vehicle_limit)}
                                        style={{ minWidth: '160px' }}
                                        className={`rosco-select${(!email && !sms) || readOnly ? ' disabled' : ''}`}
                                    />
                                </div>
                            }
                            {selectedName === 'data_pool_warning' &&
                                <div className="block_notification">
                                    <span style={{ marginRight: '5px' }}>{t('alertDataUsageExceeded')}</span>
                                    <Select
                                        isMulti={true}
                                        options={DataPoolWarningOptions.filter(({ value: val }) => !vehicle_limit.split(",").some(i => +i === +val))}
                                        onChange={changeDataUsageVehicleLimit}
                                        placeholder={t('placeholderThreshold')}
                                        value={vehicle_limit.split(",").map(i => { if (!!i) return i = { value: i, label: i } })}
                                        style={{ minWidth: '160px' }}
                                        className={`rosco-select${(!email && !sms) || readOnly ? ' disabled' : ''}`}
                                    />
                                </div>
                            }
                        </div>
                    </section>

                    {!notificationOptionsDisabled && (
                        <section>
                            <h4 className="category-title">{t('titleNotificationOptions')}</h4>
                            <div className="modal-column">
                                {totalVehicles.length > 0 &&
                                    <VehicleSelectBox
                                        vehicles={totalVehicles}
                                        selectedVehicleIds={vehicles}
                                        readOnly={(!email && !sms) || readOnly}
                                        error={errors['vehicles']}
                                        onToggle={selectVehicles}
                                    />
                                }
                            </div>
                            {/* <div className="type-select">
                            <span className="notif-type">Email Vehicles</span>
                            <Toggle
                                name={notification.name}
                                checked={email || sensorsEmail}
                                onChange={toggleEmail}
                            />
                            <span className="notif-type">SMS Vehicles</span>
                        </div> */}

                            {/* <div className={`${smsEnabled && 'two-columns'}`}>
                            <div className="modal-column">
                                <div>Email Vehicles</div>
                                {totalVehicles.length > 0 &&
                                    <VehicleSelectBox
                                        vehicles={totalVehicles}
                                        selectedVehicleIds={vehicles_email}
                                        readOnly={!email || readOnly}
                                        error={errors['vehicles']}
                                        onToggle={setVehiclesEmail}
                                    />
                                }
                            </div>

                            <div className="modal-column">
                                <div>SMS Vehicles</div>
                                {totalVehicles.length > 0 &&
                                    <VehicleSelectBox
                                        vehicles={totalVehicles}
                                        selectedVehicleIds={vehicles}
                                        readOnly={!(sms || sensorsSms) || readOnly}
                                        error={errors['vehicles']}
                                        onToggle={setVehicles}
                                    />
                                }
                            </div>
                        </div> */}
                        </section>
                    )}

                    {showSensors && _.range(0, 4).map(index =>
                        <div className="sensor-wrapper" key={index}>
                            <div className="sensor-title">{`${t('sensor')} ${index + 1}`}</div>
                            <div className="sensor">
                                <div className="sensor-name">
                                    <input type="text" name="name" className="vehicle-search" placeholder={t('placeholderSensorName')} onChange={(e) => changeSensorValue(e, index)} value={sensors[index].name || ''} />
                                </div>
                                <div className="sensor-toggle">
                                    <label>
                                        <span>{t('enable')}</span>
                                        <Toggle
                                            name={index.toString()}
                                            checked={sensors[index].email || sensors[index].sms}
                                            onChange={(e) => enableSensor(e, index)}
                                        />
                                    </label>
                                </div>
                            </div>

                            <div className="sensor-description">
                                <input type="text" name="description" className="vehicle-search" placeholder={t('placeholderAlertDescription')} onChange={(e) => changeSensorValue(e, index)} value={sensors[index].description || ''} />
                            </div>
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton
                    variant="primary"
                    type="submit"
                    onClick={
                        (sms && user.email === userEmail && !user.has_registered_sms)
                            ? showAgreementModal
                            : onSubmit
                    }
                >
                    {t('btnSave')}
                </CustomButton>
            </Modal.Footer>
            {loaderModal}
            {showSMSAgreementModal &&
                <SMSAgreementModal
                    hideSMSAgreementModal={() => setShowSMSAgreementModal(false)}
                    agreeToSMS={onSubmit}
                />
            }
        </Modal>
    )
}

export default connect(
    state => ({
        user: state.user.user,
        company: state.company.company,
        loaderModal: state.loader.loaderModal
    }),
    dispatch => ({
        toggleLoaderModal: (show) => {
            dispatch({ type: 'TOGGLE_LOADER_MODAL', payload: show });
        }
    }),
)(NotificationsModal);
