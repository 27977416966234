import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { toastr } from 'react-redux-toastr'
import { useTranslation } from 'react-i18next'

import { tableExportOptions } from '../../component/helpers/table'
import ModalEditDriver from './ModalEditDriver'
import ModalDeleteDriver from './ModalDeleteDriver'

import { apiCall } from '../../action/RouterActions'
import { sendDriverQRCode } from '../../action/DriverActions'

import '../../component/dump/menuComponents/menuComponents.sass'
import { formatName } from '../../component/helpers/tablesFuncHelpers'
import CustomButton from '../../component/CustomButton'
import IconTooltip from '../../component/dump/menuComponents/IconTooltip'

const DriversTable = (props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'driversPage.driversTable' });
	const { toggleLoader, drivers, initialData, setPropStates, groups, user } = props;
	const userRole = user.roles[0];

	const [selectedDriver, setSelectedDriver] = useState(null);
	const [showEdit, setShowEdit] = useState(false);
	const [showDelete, setShowDelete] = useState(false);

	const handleDriverEdit = (driverParams, sendQRCode = null) => {
		toggleLoader(true);
		apiCall('POST', `/driver/${selectedDriver.id}`, driverParams)
			.then((res, err) => {
				const parInitialData = initialData.map((item) => {
					if (item.id === selectedDriver.id) {
						return { ...item, ...driverParams }
					}
					return item;
				})
				const parDrivers = drivers.map((item) => {
					if (item.id === selectedDriver.id) {
						return { ...item, ...driverParams }
					}
					return item;
				})
				if (sendQRCode) {
					sendDriverQRCode(selectedDriver.id)
						.then(res => {
							toastr.success(t('sentQRCode'))
						})
						.catch(err => {
							toastr.error(t('unableSendQRCode'))
						})
				}

				setPropStates({
					initialData: parInitialData,
					drivers: parDrivers,
				})
				closeEdit();

				toggleLoader(false);
				toastr.success(t('changesMadeSuccessfully'))
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!handleDriverEdit error: ', error.response, error)

				toastr.error(t('unableEditDriver'));
			})
	};

	const handleDriverDelete = () => {
		toggleLoader(true);
		apiCall('DELETE', '/driver/{driverId}', { driverId: selectedDriver.id })
			.then((res, err) => {
				setPropStates({
					initialData: [...(initialData.filter(({ id }) => id !== selectedDriver.id))],
					drivers: [...(drivers.filter(({ id }) => id !== selectedDriver.id))],
				})
				closeDelete()
				closeEdit()

				toggleLoader(false);
				toastr.success(t('successfullyDeletedDriver'))
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('handleDriverDelete error: ', error.response, error)

				toastr.error(t('unableDeleteDriver'));
			})
	};

	const displayEdit = (row) => {
		setShowEdit(true);
		setSelectedDriver(row);
	};

	const closeEdit = () => {
		setShowEdit(false);
	};

	const displayDelete = (row) => {
		setShowDelete(true);
		setSelectedDriver(row);
	};

	const closeDelete = () => {
		setShowDelete(false);
	};

	const goToTripReplay = (driver) => {
		browserHistory.push({ pathname: '/', state: { id: driver.last_vehicle_id } });
	};

	const formatDriverName = (cell, row) => {
		return <CustomButton variant="link" onClick={() => displayEdit(row)}>{formatName(cell, row)}</CustomButton>
	};

	const formatScore = (cell) => {
		return <span className={cell > 100 ? 'error-label' : ''}>{cell ? cell.toFixed(0) : '-'}</span>
	};

	const formatStatus = (cell, row) => {
		switch (cell) {
			case "current_trip":
				return t('textOnline')
			// return <CustomButton variant='btn-link' onClick={() => goToTripReplay(row)}>Current Trip</CustomButton>
			default:
				return cell ? cell.charAt(0).toUpperCase() + cell.slice(1) : '-';
		}
	};

	const formatVehicleName = (cell, row) => {
		return cell ?
			<CustomButton variant="link" onClick={() => editVehicle(row)}>{cell}</CustomButton> :
			'-'
	};

	const editVehicle = (row) => {
		browserHistory.push({
			pathname: '/managevehicles/edit',
			state: { id: row.last_vehicle_id, company: props.company, type: '/managedrivers' }
		});
	};

	return (
		<div className="drivers-page-table">
			<BootstrapTable
				data={drivers}
				{...tableExportOptions}
				csvFileName={`${t('csvFileName')}.csv`}
			>
				<TableHeaderColumn
					dataField="id"
					isKey
				>
					{t('headerID')}
				</TableHeaderColumn>
				<TableHeaderColumn
					width="20%"
					dataSort
					dataField="first_name"
					dataFormat={['partner_view', 'sales_demo'].includes(userRole) ? formatName : formatDriverName}
					csvFormat={formatName}
					csvHeader={t('headerDriverName')}
				>
					{t('headerDriverName')}
				</TableHeaderColumn>
				<TableHeaderColumn
					width="20%"
					dataSort
					dataField="driver_score"
					dataFormat={formatScore}
					csvHeader={t('headerScore')}
				>
					{t('headerCurrentScore')}
					<IconTooltip
						description={t('description')}
						id="score-tooltip"
					/>
				</TableHeaderColumn>
				<TableHeaderColumn
					width="20%"
					dataSort
					dataField="status"
					dataFormat={formatStatus}
					csvHeader={t('headerStatus')}
				>
					{t('headerStatus')}
				</TableHeaderColumn>
				<TableHeaderColumn
					width="20%"
					dataSort
					dataField="last_vehicle_name"
					dataFormat={formatVehicleName}
					csvHeader={t('csvHeaderLastVehicle')}
				>
					{t('headerVehicle')}
				</TableHeaderColumn>
				<TableHeaderColumn
					width="20%"
					dataSort
					dataField="group_name"
					csvHeader={t('headerGroup')}
					csvFormat={(cell) => cell || '-'}
				>
					{t('headerGroup')}
				</TableHeaderColumn>
				<TableHeaderColumn
					width="20%"
					dataSort
					dataField="card_id"
					csvHeader={t('headerCardID')}
				>
					{t('headerCardID')}
				</TableHeaderColumn>
			</BootstrapTable>

			{showEdit
				&& (
					<ModalEditDriver
						driver={selectedDriver}
						groups={groups}
						handleDriverEdit={handleDriverEdit}
						deleteDriver={displayDelete}
						onHide={closeEdit}
					/>
				)}

			{showDelete
				&& (
					<ModalDeleteDriver
						driver={selectedDriver}
						handleDriverDelete={handleDriverDelete}
						onHide={closeDelete}
					/>
				)}
		</div>
	)
}

DriversTable.propTypes = {
	drivers: PropTypes.arrayOf(PropTypes.any).isRequired,
	initialData: PropTypes.arrayOf(PropTypes.any).isRequired,
	groups: PropTypes.arrayOf(PropTypes.any).isRequired,
	setPropStates: PropTypes.func.isRequired
};

export default connect(
	state => ({
		user: state.user.user,
		company: state.company.company
	}),
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(DriversTable);
