import React, { Component, useState, useEffect, useRef } from 'react'
import { Button } from 'react-bootstrap'
import CustomButton from '../../component/CustomButton'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import classnames from 'classnames'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import FleetAdminRouterUnpaired from './FleetAdminRouterUnpaired'
import RouterFirmwaresListBlock from './RouterFirmwaresListBlock'

import {
	apiCall,
	apiCallGet
} from '../../action/RouterActions'
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai"

const FleetAdminRouterUploadForm = (props) => {
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'modemsPage.fleetAdminRouterUploadForm' });
	const { companyId, setConfigParams, userRole, toggleLoader } = props;

	const [showPanelBody, setShowPanelBody] = useState(true);
	const [firmwares, setFirmwares] = useState([]);
	const [file, setFile] = useState('');
	const [release_note, setRelease_note] = useState('');
	const [errfile, setErrfile] = useState('');

	const fileRef = useRef(null);

	useEffect(() => {
		getRouterFirmwareList();
	}, []);

	const getRouterFirmwareList = () => {
		toggleLoader(true);

		apiCallGet('/router/firmware/list')
			.then((res, err) => {
				console.log('!getRouterFirmwareList res: ', res, err)

				const { response: { firmwares } } = res.data;
				setFirmwares(firmwares);

				toggleLoader(false);
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!getRouterFirmwareList error:', error.response, error)
				setFirmwares([]);
				let errDescription = i18n.t('errorMessage.errDescription');
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription);
			})
	};

	const handleInputChange = (event) => {
		const { target } = event;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const { name } = target
		if (name === 'release_note') setRelease_note(value);
	};

	const onFileChange = (event) => {
		const file = event.target.files[0] || ''
		setFile(file);
	};

	const checkUploadModem = (data, event) => {
		let error = false;
		const errMessage = { errfile: '' };

		for (let i in data) {
			if (i != 'release_note' && (data[i] == '' || data[i] == 'undefined')) {
				error = true
				errMessage['err' + i] = t('enterYour') + i + '!'
			} else if (errMessage[i]) {
				errMessage['err' + i] = null
			}
		}
		setErrfile(...errMessage);
		return error;
	};

	const uploadFirmware = (event) => {
		event.preventDefault()

		const data = { file, release_note };
		const error = checkUploadModem(data, event)

		if (!error) {
			toggleLoader(true);

			apiCall('PUT', '/router/firmware', data)
				.then((res, err) => {
					console.log('!uploadFirmware res: ', res, err)

					getRouterFirmwareList()
					toastr.success('Success', t('modemFirmwareUploaded'))
				})
				.catch((error) => {
					toggleLoader(false);
					console.log('!pushModemFile error:', error.response, error)
					let errDescription = i18n.t('errorMessage.errDescription');
					if (error.response.data.response.error) {
						errDescription = error.response.data.response.error
					}
					toastr.error(errDescription);
				})
		}
	};

	const togglePanelBody = () => {
		setShowPanelBody(!showPanelBody);
	};

	const file_name = file ? file.name : '';
	const release_note_label = { verticalAlign: 'top', fontWeight: 400, marginRight: '5px' };
	const file_uploader_bottom = { paddingBottom: '10px' };

	return (
		<section className="panel panel-default modem-uploader-container table-section">
			<div className='panel-heading' onClick={togglePanelBody}>
				<span className='toggle-accordion'>
					{showPanelBody ? <AiFillCaretUp /> : <AiFillCaretDown />}
				</span>
				<h5>{t('titleRouterUploader')}</h5>
			</div>
			<div className={`panel-body ${!showPanelBody ? 'hide' : ''}`} style={{ margin: '20px 0' }}>
				{userRole !== 'partner'
					&& (
						<div className="col-md-12 modem-uploader-block">
							<div style={file_uploader_bottom}>
								<div className={classnames('form-group upload-file-form', { 'has-error': errfile })}>
									<div id="upload-form-label">{file_name}</div>
									<div className="selectbutton">{t('selectFile')}</div>
									<input type="file" ref={fileRef} name="file" id="upload-input" onChange={onFileChange} />
									<div style={{ height: '32px' }}></div>
									{errfile && <span className="help-block">{errfile}</span>}
								</div>
							</div>

							<div className="form-group firmware-note-container">
								<label htmlFor="release_note" className="control-label" style={release_note_label}>
									{t('labelReleaseNote')}
								</label>
								<textarea
									name="release_note"
									style={{ width: '250px', height: '80px', maxHeight: '200px', resize: 'vertical' }}
									onChange={handleInputChange}
								/>
							</div>

							<div className="upload-wrapper">
								<CustomButton
									variant="secondary"
									onClick={uploadFirmware}
								>
									{t('btnUpload')}
								</CustomButton>
							</div>
						</div>
					)}

				{userRole !== 'partner'
					&& (
						<div>
							<h5 style={{ margin: '15px' }}>{t('titleFirmwaresList')}</h5>
							<RouterFirmwaresListBlock
								firmwares={firmwares}
								renderFirmwaresList={getRouterFirmwareList}
							/>
						</div>
					)}

				<h5 style={{ margin: '30px 0 15px 15px', marginTop: (userRole === 'partner' ? '0' : '30px') }}>{t('titleUnpairedRouters')}</h5>
				<FleetAdminRouterUnpaired
					companyId={companyId}
					setConfigParams={setConfigParams}
				/>
			</div>
		</section>
	)
}

export default connect(
	null,
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(FleetAdminRouterUploadForm);
