import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import CustomButton from '../../CustomButton'
import { useTranslation } from 'react-i18next'

const ModalDelete = (props) => {
	// console.log('PROPS ModalDelete: ', props)
	const { content, closeBtnName, deleteBtnName, closeModal, deleteModal, title, dialogClassName, backdropClassName, showClose, showDelete, closeButton, styleView, styleBtn, useLineBreaking, closeButtonHandle } = props;
	const { t } = useTranslation();
	const confirmCloseBtnName = closeBtnName || t('modals.modalDelete.btnCancel');
	const confirmDeleteBtnName = deleteBtnName || t('modals.modalDelete.btnNameDelete');
	const newContent = useLineBreaking ? content.split('\n').map((str, i) => <p key={i}>{str}</p>) : content;

	return (
		<Modal
			show
			onHide={closeButtonHandle || closeModal}
			dialogClassName={`has-error${dialogClassName ? ' '+dialogClassName : ''}`}
			backdropClassName={backdropClassName}
			className="modal-lg-size"
		>
			<Modal.Header closeButton={closeButton}>
				<Modal.Title className={`${styleView === 'delete' ? 'delete-title' : ''}`}>{title || t('modals.modalDelete.btnDelete')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="modal-text">
					{/* <span>
						{newContent}
					</span> */}
					<span dangerouslySetInnerHTML={{ __html: newContent }} />
				</div>
			</Modal.Body>
			<Modal.Footer bsPrefix="default-modal-footer modal-footer">
				{showClose && <CustomButton variant="primary-outline" style={styleBtn} onClick={closeModal}>{confirmCloseBtnName}</CustomButton >}
				{showDelete && <CustomButton variant={`${styleView === 'delete' ? 'delete' : 'primary'}`} style={styleBtn} onClick={deleteModal}>{confirmDeleteBtnName}</CustomButton >}
			</Modal.Footer>
		</Modal>
	)
}

ModalDelete.propTypes = {
	backdropClassName: PropTypes.string,
	showClose: PropTypes.bool,
	showDelete: PropTypes.bool,
	closeButton: PropTypes.bool,
	styleView: PropTypes.string,
	useLineBreaking: PropTypes.bool,
}

ModalDelete.defaultProps = {
	backdropClassName: 'custom-modal-backdrop',
	showClose: false,
	showDelete: true,
	closeButton: true,
	styleView: 'delete',
	useLineBreaking: false,
}

export default ModalDelete;
