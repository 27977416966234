import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { toastr } from 'react-redux-toastr'
import { Modal, Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { apiCall } from '../../../action/RouterActions'
import { sendGA } from '../../helpers/helperUsers'
import CustomButton from '../../CustomButton'

const ModalRequestContextVideo = (props) => {
	const { user, company, event, onHide, toggleLoader } = props;
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'modals.modalRequestContextVideo' });
	const [duration, setDuration] = useState(20);
	const [note, setNote] = useState('');

	const fillForm = (e) => {
		const { target } = e;
		const { name } = target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		if (name === "duration") setDuration(value);
		if (name === "note") setNote(value);
	}

	const sendRequest = (e) => {
		const userRole = user.roles[0]
		toggleLoader(true);

		const params = {
			event_id: event.id,
			duration,
		}
		if (note) {
			params.note = note
		}
		apiCall('PUT', '/events/context_video_clip', params)
			.then(() => {
				toggleLoader(false);
				toastr.success('', i18n.t('toastMessage.toastrSuccessCVR'));
				sendGA({
					category: userRole,
					action: 'Request Additional Video',
					label: `${event.type}, Length: ${duration} seconds`,
				})
				onHide()
			})
			.catch((error) => {
				let errDescription = i18n.t('errorMessage.errDescription');
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}

	const { vehicle_name, device_id } = event;
	const partnerName = company.partner || user.partner_company_name;
	const companyType = company.type || user.company_type;

	return (
		<Modal
			show
			onHide={onHide}
			dialogClassName="custom-request-modal"
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-lg">
					{`${t('title')} - ${vehicle_name || device_id}`}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div>
					<p style={{ marginBottom: '30px' }}>{t('text')}</p>
					<div className="row">
						<div className="col-xs-12 col-sm-12 col-md-5 form-group">
							<label className="control-label">{t('titleVideoLength')}</label>
							<select placeholder={t('selectPlaceholder')} className="rosco-select" name="duration" value={duration} onChange={fillForm}>
								<option value="20">{t('20_Seconds')}</option>
								{partnerName !== 'Rosco Vision WCS' && companyType !== 'WCS'
									&& <option value="30">{t('30_Seconds')}</option>}
								<option value="60">{t('1_Minute')}</option>
								<option value="120">{t('2_Minutes')}</option>
								<option value="180">{t('3_Minutes')}</option>
								<option value="240">{t('4_Minutes')}</option>
								<option value="300">{t('5_Minutes')}</option>
								{/* <option value="240">4 Minutes</option>
								<option value="300">5 Minutes</option>
								{(partnerName === 'Rosco Vision WCS' || companyType === 'WCS')
									&& (
										<option value="600">10 Minutes</option>
									)}
								{(partnerName === 'Rosco Vision WCS' || companyType === 'WCS')
									&& (
										<option value="900">15 Minutes</option>
									)} */}
							</select>
						</div>

						<div className="col-xs-12 col-sm-12 col-md-12 form-group" style={{ paddingTop: "15px" }}>
							<label className="control-label">{t('titleNote')}</label>
							<input type="text" name="note" placeholder={t('notePlaceholder')} className="vehicle-search" value={note} onChange={fillForm} style={{ marginLeft: '10px', width: 'calc(100% - 50px)' }} />
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer bsPrefix="default-modal-footer modal-footer">
				<CustomButton
					onClick={sendRequest}
					variant="primary"
				>
					{t('buttonRequest')}
				</CustomButton>
			</Modal.Footer>
		</Modal>
	);
}

ModalRequestContextVideo.propTypes = {
	user: PropTypes.objectOf(PropTypes.any).isRequired,
	company: PropTypes.objectOf(PropTypes.any).isRequired,
	event: PropTypes.objectOf(PropTypes.any).isRequired,
	onHide: PropTypes.func.isRequired
}

export default connect(
	state => ({
		user: state.user.user,
		company: state.company.company
	}),
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(ModalRequestContextVideo);
