import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dropdown } from 'react-bootstrap'
import _ from 'lodash'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { useTranslation } from 'react-i18next'

import { apiCall } from '../../action/RouterActions'
import CustomButton from '../../component/CustomButton'
import { CustomButtonToggle } from '../../component/DropdownComponents'
import ModalUploadFile from '../../component/common/modals/ModalUploadFile'

const CompanyEditGeotabSettings = (props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'companiesPage.companyEditGeotabSettings' });
	const [showGeotabPassword, setShowGeotabPassword] = useState(false);
	const [modalUploadFile, setModalUploadFile] = useState(false);
	const [fileUploadDevices, setFileUploadDevices] = useState(null);

	// console.log('PROPS CompanyEditGeotabSettings: ', props)
	// console.log('STATE CompanyEditGeotabSettings: ', state)
	const { company, geotabCredentials, modalConfirm, toggleLoader } = props;

	const onFileChange = (e) => {
		const { value } = e.target;
		const file = e.target.files[0];
		setFileUploadDevices(file);

		let i
		if (value.lastIndexOf('\\')) {
			i = value.lastIndexOf('\\') + 1;
		} else {
			i = value.lastIndexOf('/') + 1;
		}
		const filename = value.slice(i);
		const uploaded = document.getElementById('upload-form-label');
		uploaded.innerHTML = filename;
	}

	const onUploadVehicles = (e) => {
		e.preventDefault()

		if (!fileUploadDevices) {
			toastr.warning(t('selectCSV/ExcelFile'))
			return
		}

		const params = {
			file: fileUploadDevices
		}

		toggleLoader(true);

		apiCall('PUT', `/geotab/companies/${company.id}/devices/file`, params)
			.then((res, err) => {
				toggleLoader(false);
				toastr.success('', t('fileUploaded'))
			})
			.catch((error) => {
				toggleLoader(false);
				const { status, data } = error.response
				if (status >= 400 && status < 500) {
					toastr.error(data.response.error);
				} else {
					toastr.error(t('unableUploadDevices'));
				}
			})
	}

	return (
		<div className='subheader-section company-info'>
			{(company.geotab_database || company.geotab_email)
				? (
					<Dropdown
						drop
						id="geotab-dropdown"
						role="menuitem"
					>
						<Dropdown.Toggle as={CustomButtonToggle} variant='primary-outline' size="lg">
							{t('titleGeotabSettings')}
						</Dropdown.Toggle>
						<Dropdown.Menu className="geotab-relations-menu">
							<div className='dropdown-body'>
								<h5 className='category-title'>{t('titleIntegrationInfo')}</h5>
								<div className='info-section'>
									<b>{t('POCEmail')}</b> {company.geotab_email}
								</div>
								<div className='info-section'>
									{company.geotab_database && <div><b>{t('Database')}</b> {company.geotab_database}</div>}
								</div>
								<div className='info-section'>
									{company.geotab_path && <div><b>{t('URL')}</b> {company.geotab_path}</div>}
								</div>
								{company.geotab_database && <div>
									<h5 className='category-title'>{t('integrationCredentials')}</h5>
									<div className='info-section'>
										<b>Email:</b> {geotabCredentials.email}
									</div>
									{/* <div className='info-section'>
										{showGeotabPassword ?
											<span><b>Password:</b> {geotabCredentials.password}</span> :
											<CustomButton variant="link" onClick={() => setShowGeotabPassword(true)}>{t('btnShowPassword')}</CustomButton>
										}
									</div> */}
								</div>}
							</div>
							<div className='dropdown-footer'>
								{company.geotab_database && (
									<CustomButton variant="primary" style={{ marginRight: "10px" }} onClick={() => setModalUploadFile(true)}>
										{t('btnPairDevices')}
									</CustomButton>
								)}
								<CustomButton variant="primary-outline" onClick={(e) => modalConfirm(e, 'geotab_email')}>
									{t('btnProvisionGeotab')}
								</CustomButton>
								{company.geotab_database && (
									<CustomButton variant="delete" onClick={(e) => modalConfirm(e, 'geotab_relation')}>
										{t('btnDeleteRelation')}
									</CustomButton>
								)}
							</div>
						</Dropdown.Menu>
					</Dropdown>
				) : (
					<CustomButton
						variant="primary-outline"
						size="sm"
						id="geotab-dropdown"
						onClick={(e) => modalConfirm(e, 'geotab_email')}
					>
						{t('btnProvisionGeotab')}
					</CustomButton>
				)
			}

			{modalUploadFile &&
				<ModalUploadFile
					title={t('titleUploadCSV')}
					description={t('description')}
					exampleFileTitle={t('exampleFileTitle')}
					btnUploadTitle={t('btnUploadTitle')}
					exampleFileLink="/files/rosco_devices_example.csv"
					acceptFileExtensions=".csv"
					onFileChange={onFileChange}
					onUploadFile={onUploadVehicles}
					onHide={() => setModalUploadFile(false)}
				/>}
		</div>
	)
}

CompanyEditGeotabSettings.propTypes = {
	company: PropTypes.objectOf(PropTypes.any).isRequired,
	geotabCredentials: PropTypes.objectOf(PropTypes.any).isRequired,
	modalConfirm: PropTypes.func.isRequired,
};

export default connect(
	null,
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(CompanyEditGeotabSettings);
