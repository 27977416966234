import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { Button } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'
import Toggle from 'react-toggle'
import moment from 'moment'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { useTranslation } from 'react-i18next'

import { tableExportOptions, renderCustomHeader } from '../../helpers/table'
import ModalDeviceAuditLog from '../modals/ModalDeviceAuditLog'
import SearchBar from '../../SearchBar'
import GlobalDataThreshold from '../../../pages/companies/GlobalDataThreshold'

import { getAllCompanies, updateCompany } from '../../../action/CompaniesActions'
import CompanyAddContainer from './CompanyAddContainer'

import '../../dump/menuComponents/menuComponents.sass'
import GreyHeader from '../../GreyHeader'
import CustomButton from '../../CustomButton';

const CompanyContainer = (props) => {
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'companiesPage.companyContainer' });
	const { user, company, toggleLoader } = props
	const userRole = user.roles[0]

	const [state, setState] = useState({
		data: {},
		searchText: '',
		initialData: [],
		companies: [],
		modal: {
			show: false,
			content: '',
			error: false,
		},
		delete_company: {},
		open: false,

		showModalDeviceAuditLog: false,
		activeCompany: null,
	});

	useEffect(() => {
		getCompanies();
	}, [])

	const toggleTraceActive = (cell, row) => {
		switch (user.roles[0]) {
			case 'system_admin':
			case 'customer_service':
				return (
					<div className='text-center'>
						<Toggle
							name="trace_active"
							checked={cell}
							onChange={e => changeToggle(e, cell, row)}
							disabled={!row.active}
						/>
					</div>
				)
			case 'partner':
			case 'sales_demo':
				return (
					<div className='text-center'>
						{cell ? <p className="active-dots" /> : <p className="non-active-dots" />}
					</div>
				)
			default:
				return ''
		}
	}

	const toggleLiveActive = (cell, row) => {
		switch (user.roles[0]) {
			case 'system_admin':
			case 'customer_service':
				return (
					<div className='text-center'>
						<Toggle
							name="live_active"
							checked={cell}
							onChange={e => changeToggle(e, cell, row)}
							disabled={!row.active}
						/>
					</div>
				)
			case 'partner':
			case 'sales_demo':
				return (
					<div className='text-center'>
						{cell ? <p className="active-dots" /> : <p className="non-active-dots" />}
					</div>
				)
			default:
				return ''
		}
	}

	const toggleStorageActive = (cell, row) => {
		switch (userRole) {
			case 'system_admin':
			case 'customer_service':
				return (
					<div className='text-center'>
						<Toggle
							name="storage_active"
							checked={cell}
							onChange={(e) => changeToggle(e, cell, row)}
							disabled={!row.active}
						/>
					</div>
				)
			case 'partner':
			case 'sales_demo':
				return (
					<div className='text-center'>
						{cell ? <p className="active-dots" /> : <p className="non-active-dots" />}
					</div>
				)
			default:
				break
		}
	}

	const typeFormatter = (cell, row) => {
		if (cell) {
			return `Geotab - ${cell}`
		}
		if (row.company_type === 'WCS') {
			return 'WCS'
		}
	}

	const changeToggle = (event, cell, row) => {
		const { company, company_collection, editCompany, editCompanyCollection } = props;
		const { target } = event;
		const { name } = target;
		const value = target.checked;

		let newData = {};
		const mapCompanies = state.companies.map((item) => {
			if (item.company_name === row.company_name) {
				item[name] = value;
				newData = {
					live_active: item.live_active,
					storage_active: item.storage_active,
					trace_active: item.trace_active
				}
			}
			return item;
		})
		const data = state.data;
		data.company_name = row.company_name;
		data.owner_company = row.owner_company;
		data.toggles = newData;
		setState((prevState) => {
			return {
				...prevState, data: data, devices: mapCompanies
			}
		});
		updateCompanyToggle(row, name, value);

		// updates the active Company.company_data object and partnerList.company_collection array
		if (['live_active', 'storage_active', 'trace_active'].includes(name)) {
			if (company.company_id === row.id) {
				editCompany({ company_data: { ...company.company_data, [name]: value } });
			}
			const obj = company_collection.find(({ id }) => id === row.id);
			editCompanyCollection({ id: row.id, company_data: { ...obj.company_data, [name]: value } });
		}
	}

	const updateCompanyToggle = (row, name, value) => {
		toggleLoader(true);

		const requestData = {
			company: state.data,
			data: state.data.toggles
		}

		updateCompany(requestData, userRole)
			.then((res, err) => {
				console.log('!AccountEditCompanyContainer updateCompany res: ', res, err);
				toggleLoader(false);

				const active = value ? t('active') : t('notActive');
				const { company_name } = row;
				let type_name;
				switch (name) {
					case 'storage_active':
						type_name = t('storageActive');
						break;
					case 'trace_active':
						type_name = t('traceActive');
						break;
					case 'live_active':
						type_name = t('liveActive');
						break;
					default:
						break
				}
				toastr.success(`"${type_name}" ${t('in')} "${company_name}" ${t('isNow')} ${active}`);
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!AccountEditCompanyContainer updateCompany error', error);
				let errDescription = i18n.t('errorMessage.errDescription');
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}

	const updateLocalCompanyList = (config) => {
		setState((prevState) => {
			return {
				...prevState, ...config
			}
		});
	}

	const getCompanies = () => {
		toggleLoader(true);
		const companyType = 'customer,wcs';
		getAllCompanies(userRole, companyType)
			.then(res => {
				setState((prevState) => {
					return {
						...prevState, companies: res.data.response.companies, initialData: res.data.response.companies
					}
				});
				toggleLoader(false);
			})
			.catch(error => {
				toggleLoader(false);
				let errDescription = i18n.t('errorMessage.errDescription');
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}

	const formatCompanyName = (cell, row) => {
		if (['provisioning', 'sales_demo'].indexOf(userRole) === -1) {
			return (
				<CustomButton variant='link' onClick={() => editCompany(row)}>
					{cell}
				</CustomButton>
			)
		}
		else {
			return cell
		}
	}

	const editCompany = (company) => {
		browserHistory.push({ pathname: '/company/edit', state: { company } });
	}

	const auditLogFormatter = (cell, row) => (
		<CustomButton variant='link' onClick={() => {
			setState((prevState) => {
				return {
					...prevState, showModalDeviceAuditLog: true, activeCompany: row
				}
			});
		}}>
			{t('btnView')}
		</CustomButton>
	)

	const lgClose = () => {
		setState((prevState) => {
			return {
				...prevState, showModalDeviceAuditLog: false
			}
		});
	}

	const dataSearch = (e) => {
		const value = e.target.value.toLowerCase();
		const filter = state.initialData.filter(item => (
			item.company_name.toLowerCase().includes(value)
			|| item.company_city.toLowerCase().includes(value)
			|| item.company_state.toLowerCase().includes(value)
			|| item.company_zipcode.toLowerCase().includes(value)
			|| item.company_address.toLowerCase().includes(value)
			|| item.company_country.toLowerCase().includes(value)
			|| item.company_phone_number?.toLowerCase().includes(value)
		));

		setState((prevState) => {
			return {
				...prevState,
				companies: filter,
				searchText: value,
			}
		});
	}

	const trialDateFormatter = (cell, row) => {
		if (row.trial_status && row.trial_date) {
			return moment(row.trial_date).format('M/DD/YYYY')
		}

		return ''
	};

	const earliestDateSort = (d) => (a, b, order, p) => {
		const a1 = a[p] ? moment(a[p]).valueOf() : 0
		const b1 = b[p] ? moment(b[p]).valueOf() : 0

		if (order === 'desc') {
			return b1 - a1
		}

		return a1 - b1
	};

	const columnClassTrialDate = (fieldValue, row, rowIdx, colIdx) => {
		// fieldValue is column value
		// row is whole row object
		// rowIdx is index of row
		// colIdx is index of column
		if (row.trial_status && moment().diff(row.trial_date, 'hours') > 0) {
			return 'td-column-highlighted'
		}

		return ''
	}

	const formaterDataCSV = (cell) => {
		return cell ? cell : '-'
	}

	return (
		<div>
			<GreyHeader
				title={t('titleCompanies')}
				subtitle={company.partner}
				hideSubtitle={userRole !== 'partner'}
			/>
			<div className='page-subheader'>
				<div className='subheader-section search'>
					<SearchBar
						term={state.searchText}
						onSearch={dataSearch}
					/>
				</div>
			</div>

			{['sales_demo'].indexOf(userRole) === -1
				? (
					<main className="manage-users main-content-block">

						{/* FWSD-5452 Hide "Add a New Company" button on Companies page for all Partner users (EXCEPT TENNA) */}
						{(userRole !== 'partner' || (userRole === 'partner' && user.company_id === 2171)) &&
							<CompanyAddContainer getCompanies={getCompanies} />
						}

						<div className="manage-users-table manage-companies-page-table">
							<BootstrapTable
								data={state.companies}
								{...tableExportOptions}
								tableContainerClass="rosco-table manage-vehicles-table"
								headerContainerClass="rosco-table-header custom-table-header-container manage-vehicles-table-header"
								bodyContainerClass="rosco-table-body manage-vehicles-table-body"
								csvFileName={`${t('companies_report')}.csv`}
							>
								<TableHeaderColumn width="18%" dataField="company_name" dataSort dataFormat={formatCompanyName} csvHeader={t('csvHeaderCompanyName')} isKey>
									{renderCustomHeader(0, t('headerName'))}
								</TableHeaderColumn>

								<TableHeaderColumn width="19%" dataField="owner_company" csvHeader={t('headerPartner')} dataSort>
									{renderCustomHeader(1, t('headerPartner'))}
								</TableHeaderColumn>

								<TableHeaderColumn width="12%" dataField="geotab_database" dataSort={false} csvHeader={t('headerFleetType')} dataFormat={typeFormatter}>
									{renderCustomHeader(2, t('headerFleetType'))}
								</TableHeaderColumn>

								<TableHeaderColumn width="8%" dataField="storage_active" dataSort csvHeader={t('headerStorageActive')} dataFormat={toggleStorageActive}>
									{renderCustomHeader(3, t('headerStorageActive'))}
								</TableHeaderColumn>

								<TableHeaderColumn width="8%" dataField="trace_active" dataSort csvHeader={t('headerTraceActive')} dataFormat={toggleTraceActive}>
									{renderCustomHeader(4, t('headerTraceActive'))}
								</TableHeaderColumn>

								<TableHeaderColumn width="8%" dataField="live_active" dataSort csvHeader={t('headerLiveActive')} dataFormat={toggleLiveActive}>
									{renderCustomHeader(5, t('headerLiveActive'))}
								</TableHeaderColumn>

								<TableHeaderColumn width="8%" dataField="token" csvHeader={t('headerFleetToken')} dataSort>
									{renderCustomHeader(6, t('headerFleetToken'))}
								</TableHeaderColumn>

								{['system_admin', 'customer_service'].indexOf(userRole) > -1
									? (
										<TableHeaderColumn width="8%" dataField="trial_date" dataSort dataFormat={trialDateFormatter} csvHeader={t('headerTrialEndDate')} csvFormat={formaterDataCSV} sortFunc={earliestDateSort('trial_date')} columnClassName={columnClassTrialDate}>
											{renderCustomHeader(6, t('headerTrialEndDate'))}
										</TableHeaderColumn>
									)
									: (
										<TableHeaderColumn width="8%" dataField="trial_date" dataSort dataFormat={trialDateFormatter} csvHeader={t('headerTrialEndDate')} csvFormat={formaterDataCSV} sortFunc={earliestDateSort('trial_date')} columnClassName={columnClassTrialDate}>
											{t('headerTrialEndDate')}
										</TableHeaderColumn>
									)
								}
								{['system_admin', 'customer_service'].indexOf(userRole) > -1
									? (
										<TableHeaderColumn width="7%" dataField="company_name" csvHeader={t('headerAuditLog')} csvFormat={formaterDataCSV} dataFormat={auditLogFormatter}>
											{t('headerAuditLog')}
										</TableHeaderColumn>
									) : null}

							</BootstrapTable>
						</div>

						{/* FWSD-6805
						{['system_admin'].includes(userRole) && <GlobalDataThreshold />}  */}
					</main>
				)
				: (
					<main className="manage-users main-content-block">

						<div className="manage-users-table manage-companies-page-table">
							<BootstrapTable
								data={state.companies}
								{...tableExportOptions}
								csvFileName={`${t('companies_report')}.csv`}
							>
								<TableHeaderColumn width="18%" dataField="company_name" csvHeader={t('csvHeaderCompanyName')} dataSort isKey>
									{renderCustomHeader(0, t('headerName'))}
								</TableHeaderColumn>

								<TableHeaderColumn width="18%" dataField="owner_company" csvHeader={t('headerPartner')} dataSort>
									{renderCustomHeader(1, t('headerPartner'))}
								</TableHeaderColumn>

								<TableHeaderColumn width="11%" dataField="storage_active" csvHeader={t('headerStorageActive')} dataSort dataFormat={toggleStorageActive}>
									{renderCustomHeader(2, t('headerStorageActive'))}
								</TableHeaderColumn>

								<TableHeaderColumn width="11%" dataField="trace_active" csvHeader={t('headerTraceActive')} dataSort dataFormat={toggleTraceActive}>
									{renderCustomHeader(3, t('headerTraceActive'))}
								</TableHeaderColumn>

								<TableHeaderColumn width="11%" dataField="live_active" csvHeader={t('headerLiveActive')} dataSort dataFormat={toggleLiveActive}>
									{renderCustomHeader(4, t('headerLiveActive'))}
								</TableHeaderColumn>

								<TableHeaderColumn width="10%" dataField="token" csvHeader={t('headerFleetToken')} dataSort>
									{renderCustomHeader(5, t('headerFleetToken'))}
								</TableHeaderColumn>

								<TableHeaderColumn width="9%" dataField="trial_date" dataSort dataFormat={trialDateFormatter} csvHeader={t('headerTrialEndDate')} csvFormat={formaterDataCSV} sortFunc={earliestDateSort('trial_date')}>
									{renderCustomHeader(6, t('headerTrialEndDate'))}
								</TableHeaderColumn>

								<TableHeaderColumn width="8%" dataField="company_name" csvHeader={t('headerAuditLog')} csvFormat={formaterDataCSV} dataFormat={auditLogFormatter}>
									{t('headerAuditLog')}
								</TableHeaderColumn>
							</BootstrapTable>
						</div>
					</main>
				)
			}

			{state.showModalDeviceAuditLog
				&& (
					<ModalDeviceAuditLog
						device={state.activeCompany}
						onHide={lgClose}
						user_role={userRole}
						type="company_logs"
					/>
				)}
		</div>
	)
}

export default connect(
	state => ({
		user: state.user.user,
		company: state.company.company,
		company_collection: state.partnerList.company_collection
	}),
	dispatch => ({
		editCompany: data => dispatch({ type: 'EDIT_COMPANY', payload: data }),
		editCompanyCollection: data => dispatch({ type: 'EDIT_COMPANY_COLLECTION', payload: data }),
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		},
	}),
)(CompanyContainer);
