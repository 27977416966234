import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import CompanyEditContainer from './CompanyEditContainer';
import CompanyEditWCSPoints from './CompanyEditWCSPoints';
import CompanyEditWCSPointsHM from './CompanyEditWCSPointsHM';

import '../../component/dump/menuComponents/menuComponents.sass'
import { isInternalUser } from '../../component/helpers/constants';
import CustomButton from '../../component/CustomButton';

const CompanyEditContainerTabs = (props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'companiesPage.companyEditContainerTabs' });
	const { user, location } = props;
	const userRole = user.user.roles[0];
	const [tabKey, setTabKey] = useState(1);
	const [companyEditWCSPoints, setCompanyEditWCSPoints] = useState(null);

	const handleSelectTab = (key) => {
		setTabKey(key);
		if (key === 2) {
			setCompanyEditWCSPoints(true);
		}
	}

	// IF WCS COMPANY CHANGE HEADER TO TABS
	const headerOptions = {
		title: t('titleEditCompany'),
		subtitle: location.state.company.company_name
	}
	if ((location.state.company.partner_company === 'Rosco Vision WCS'
		|| location.state.company.company_type === 'WCS')
		&& isInternalUser(userRole)) {
		headerOptions.title = location.state.company.company_name
		headerOptions.renderSubtitle = (
			<nav className='page-subtitle'>
				<CustomButton variant="subtitle" active={tabKey === 1} onClick={() => handleSelectTab(1)}>
					{t('btnEditCompany')}
				</CustomButton>
				<CustomButton variant="subtitle-nav" active={tabKey === 2} onClick={() => handleSelectTab(2)}>
					{t('btnWiFiPoints')}
				</CustomButton>
			</nav>
		)
	}

	return (
		<div className="edit-company">
			{tabKey === 1 ?
				<CompanyEditContainer
					location={location}
				/> :
				(!isInternalUser(userRole) ?
					<CompanyEditWCSPoints
						companyEditWCSPoints={companyEditWCSPoints}
						location={location}
						headerOptions={headerOptions}
					/> :
					<CompanyEditWCSPointsHM
						companyEditWCSPoints={companyEditWCSPoints}
						location={location}
						headerOptions={headerOptions}
					/>)
			}
		</div>
	)
}

export default connect(
	state => ({
		user: state.user,
	}),
)(CompanyEditContainerTabs);
