import React from 'react'
import PropTypes from 'prop-types'
import Toggle from 'react-toggle'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import LabelTag from '../../component/dump/LabelTag'
import IconTooltip from '../../component/dump/menuComponents/IconTooltip'

import './AdasCalibrationSettings.sass'

const AdasCalibrationSettings = ({ device, onSave, disabled }) => {
	const { t } = useTranslation('translation', { keyPrefix: 'manageVehiclesPage.fleetConfigurationContainer.сalibrationNeededModal.adasCalibrationSettingsModal.adasCalibrationSettings' });

	const hasDiffPendingAdasVehicleHoodLen = device.pending_config && _.has(device.pending_config, 'adas_vehicle_hood_len') && (+device.pending_config.adas_vehicle_hood_len).toFixed(0) !== (+device.adas_vehicle_hood_len).toFixed(0)
	const hasDiffPendingAdasCameraHeight = device.pending_config && _.has(device.pending_config, 'adas_camera_height') && (+device.pending_config.adas_camera_height).toFixed(0) !== (+device.adas_camera_height).toFixed(0)
	const hasDiffPendingAdasCameraOffset = device.pending_config && _.has(device.pending_config, 'adas_camera_offset') && (+device.pending_config.adas_camera_offset).toFixed(0) !== (+device.adas_camera_offset).toFixed(0)
	const hasDiffPendingAdasVehicleWidth = device.pending_config && _.has(device.pending_config, 'adas_vehicle_width') && (+device.pending_config.adas_vehicle_width).toFixed(0) !== (+device.adas_vehicle_width).toFixed(0)

	const onInputChange = (event, name = null) => {
		const { target } = event;
		const field = target.name || name;
		let value = target.type === 'checkbox' ? target.checked : target.value;

		switch (field) {
			case 'adas_units':
				value = value ? 'imperial' : 'metric'
				break;
		}

		onSave({ [field]: value })
	}

	const onMetricInputChange = (e) => {
		const { target } = e
		let { name, value } = target

		value = device.adas_units === 'imperial' ? value * 2.54 : value

		onSave({ [name]: value })
	}

	const numberFormat = (argName, argValue = null) => {
		let value = (argValue || device[argName]) * 1

		if (device.adas_units === 'imperial') {
			value = value / 2.54
		} // ? (data.adas_vehicle_width / 2.54).toFixed(0) : data.adas_vehicle_width.toFixed(0)

		return value ? value.toFixed(0) : value
	}

	return (
		<div className={`adas-calibration${disabled ? ' disabled' : ''}`}>
			<div className="toggles-container">
				<label className='settings-title dms-title'>
					<strong className="device-param">{t('paramADASCalibration')}</strong>
					{/* <LabelTag text="Beta" tag_type="beta" /> */}
				</label>

				<label className="box-toggle">
					<span className="toggle-left-title">{t('titleMetricUnits')}</span>
					<Toggle
						name="adas_units"
						icons={false}
						checked={device.adas_units === 'imperial'}
						onChange={onInputChange}
					/>
					<span>{t('imperialUnits')}</span>
				</label>
			</div>

			<p>
				{t('textADASCalibration_1')}
				<br /><span style={{ color: 'red' }}>{t('textADASCalibration_2')}</span>
			</p>

			<div className="input-wrapper justify-evenly">
				<div className="input-wrapper mb-10">
					<label className={`settings-title${hasDiffPendingAdasVehicleHoodLen ? ' has-pending-config' : ''}`}>
						{t('vehicleHoodLength')}
						{hasDiffPendingAdasVehicleHoodLen &&
							<IconTooltip
								description={`${t('descriptionVehicleHoodLength')} = ${numberFormat('', device.pending_config.adas_vehicle_hood_len)}`}
								id="adas_vehicle_hood_len-tooltip"
							/>
						}
					</label>
					<div className="minimum-speed">
						<input
							type="number"
							step="1"
							min="0"
							max="999"
							maxLength="3"
							value={numberFormat('adas_vehicle_hood_len')}
							className="vehicle-search number-input mr-10"
							name="adas_vehicle_hood_len"
							onChange={onMetricInputChange}
						/>
						<label>{device.adas_units === 'imperial' ? t('textInches') : t('textCentimeters')}</label>
					</div>
				</div>
				<div className="input-wrapper mb-10">
					<label className={`settings-title${hasDiffPendingAdasVehicleWidth ? ' has-pending-config' : ''}`}>
						{t('titleVehicleWidth')}
						{hasDiffPendingAdasVehicleWidth &&
							<IconTooltip
								description={`${t('descriptionVehicleWidth')} = ${numberFormat('', device.pending_config.adas_vehicle_width)}`}
								id="adas_vehicle_width-tooltip"
							/>
						}
					</label>
					<div className="minimum-speed">
						<input
							type="number"
							step="1"
							min="0"
							max="999"
							maxLength="3"
							value={numberFormat('adas_vehicle_width')}
							className="vehicle-search number-input mr-10"
							name="adas_vehicle_width"
							onChange={onMetricInputChange}
						/>
						<label>{device.adas_units === 'imperial' ? t('textInches') : t('textCentimeters')}</label>
					</div>
				</div>
			</div>

			<div className="input-wrapper justify-evenly">
				<div className="input-wrapper mb-10">
					<label className={`settings-title${hasDiffPendingAdasCameraHeight ? ' has-pending-config' : ''}`}>
						{t('titleCameraHeight')}
						{hasDiffPendingAdasCameraHeight &&
							<IconTooltip
								description={`${t('descriptionCameraHeight')} = ${numberFormat('', device.pending_config.adas_camera_height)}`}
								id="adas_camera_height-tooltip"
							/>
						}
					</label>
					<div className="minimum-speed">
						<input
							type="number"
							step="1"
							min="0"
							max="999"
							maxLength="3"
							value={numberFormat('adas_camera_height')}
							className="vehicle-search number-input mr-10"
							name="adas_camera_height"
							onChange={onMetricInputChange}
						/>
						<label>{device.adas_units === 'imperial' ? t('textInches') : t('textCentimeters')}</label>
					</div>
				</div>
				<div className="input-wrapper mb-10">
					<label className={`settings-title${hasDiffPendingAdasCameraOffset ? ' has-pending-config' : ''}`}>
						{t('titleCameraOffset')}
						{hasDiffPendingAdasCameraOffset &&
							<IconTooltip
								description={`${t('descriptionCameraOffset')} = ${numberFormat('', device.pending_config.adas_camera_offset)}`}
								id="adas_camera_offset-tooltip"
							/>
						}
					</label>
					<div className="minimum-speed">
						<input
							type="number"
							step="1"
							min="-999"
							max="999"
							maxLength="3"
							value={numberFormat('adas_camera_offset')}
							// (configurationdv6.Event.Adas.Headway.SpeedThreshold / 1.609344).toFixed(0)}
							className="vehicle-search number-input mr-10"
							name="adas_camera_offset"
							onChange={onMetricInputChange}
						/>
						<label>{device.adas_units === 'imperial' ? t('textInches') : t('textCentimeters')}</label>
					</div>
				</div>
			</div>
		</div>
	)
}

AdasCalibrationSettings.propTypes = {
	device: PropTypes.objectOf(PropTypes.any).isRequired,
	onSave: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
}

AdasCalibrationSettings.defaultProps = {
	disabled: false,
}

export default AdasCalibrationSettings;
