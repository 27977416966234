import React, { useState, useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import { Row, Col, Container } from 'react-bootstrap'
import moment from 'moment'
import _ from 'lodash'
import { toastr } from 'react-redux-toastr'
import { connect } from 'react-redux'
import { TiArrowSortedDown, TiArrowSortedUp, TiArrowUnsorted } from 'react-icons/ti'
import { useTranslation } from 'react-i18next'

import AvailableDatesNav from '../DailyEvents/AvailableDatesNav'
import EventsTable from '../DailyEvents/EventsTable'
import TripsGrid from '../TripsHistory/TripsGrid'
import CustomVideoRequest from '../../../component/smart/modals/CustomVideoRequest'
import VehicleSidebarFilter from './VehicleSidebarFilter'
import EventViewSnapshotModal from '../../../component/smart/modals/EventViewSnapshotModal'
import ModalDelete from '../../../component/smart/modals/ModalDelete'
import useInterval from '../../../component/common/UseInterval'

import { vehicleSidebarIcons } from '../../../helpers/iconHelpers'
import { eventIcons } from '../../../component/helpers/iconHelpers'
import { detectRSSIQuality, getDeviceStatusName, openLiveStream } from '../../../component/helpers/helperVehicle'
import { formatAddress, formatSpeedMPH, formatTimestamp } from '../../../component/helpers/tablesFuncHelpers';
import momenttz from '../../../component/helpers/momenttz'
import { isInternalUser } from '../../../component/helpers/constants'
import { sendGA } from '../../../component/helpers/helperUsers'
import { getAvailableDatesOfDevice, getEventsOfCompanyAndRange, getEventsFilters } from '../../../action/AlertActions'
import { getDeviceAddress } from '../../../action/DeviceActions'
import { apiCall } from '../../../action/RouterActions'

import './VehicleSidebar.sass'
import { isMobileOnly } from 'react-device-detect'
import CustomButton from '../../../component/CustomButton'
import CustomOverlayTrigger from '../../../component/CustomOverlayTrigger'
import { getUserNotifications } from '../../../action/ClientActions'

const VehicleSidebar = (props) => {
	// console.log('PROPS VehicleSidebar: ', props)
	const { user, company, _map, showTripsHistory, setShowTripsHistory, activeTreeItem, markerInfo, onClickPlayback, toggleLoader, monthlyFleetDataUsageAvailable, companyDevicesCount, sessionNotifications, setSessionNotifications, sessionDevicesIds, setSessionDevicesIds } = props
	const userRole = user.roles[0]
	const totalDeviceDataUsage = activeTreeItem.data_usage + activeTreeItem.data_usage_live_stream
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'homePage.vehicleSidebar' });
	// FWSD-6153
	// we should not disable Live Stream in 4 cases: company is Trial, company has 10 or less devices, company has enabled Override Overage Limits toggle, company uses own SIM card
	const isLiveStreamDisabled =
		(
			(!activeTreeItem.online && !activeTreeItem.sleep)
			|| activeTreeItem.disable_live_stream
			|| !company.company_data.live_active
			|| (totalDeviceDataUsage > monthlyFleetDataUsageAvailable && company.company_data.trial_status === false && company.company_data.using_rosco_sim === true && company.company_data.override_overage_limits === false && companyDevicesCount > 10 && !isInternalUser(userRole))
		)
	// FWSD-7559
	const isCVRDisabled = !activeTreeItem.active

	// console.log('activeTreeItem: ', activeTreeItem)
	// console.log('isLiveStreamDisabled1: ', isLiveStreamDisabled)
	// console.log('isLiveStreamDisabled2: ', !activeTreeItem.online && !activeTreeItem.sleep)
	// console.log('isLiveStreamDisabled3: ', activeTreeItem.disable_live_stream)
	// console.log('isLiveStreamDisabled3: ', !company.company_data.live_active)
	// console.log('isLiveStreamDisabled4: ', (totalDeviceDataUsage > monthlyFleetDataUsageAvailable && company.company_data.trial_status === false && company.company_data.using_rosco_sim === true && company.company_data.override_overage_limits === false && companyDevicesCount > 10 && !isInternalUser(userRole)))
	// console.log('totalDeviceDataUsage: ', totalDeviceDataUsage, totalDeviceDataUsage / 1024 / 1024 /1024)
	// console.log('monthlyFleetDataUsageAvailable: ', monthlyFleetDataUsageAvailable, monthlyFleetDataUsageAvailable / 1024 / 1024 /1024)
	// console.log('company.company_data.trial_status === false: ', company.company_data.trial_status === false)
	// console.log('company.company_data.using_rosco_sim === true: ', company.company_data.using_rosco_sim === true)
	// console.log('company.company_data.override_overage_limits === false: ', company.company_data.override_overage_limits === false)
	// console.log('company: ', company)
	// console.log('company.company_data: ', company.company_data)
	const [interval, setIntervalState] = useState(null)
	const [rssi_object, setRssi_object] = useState('')
	const [address, setAddress] = useState('')
	// const [showDeviceSleep, setShowDeviceSleep] = useState(false)
	// const [delayDeviceOnline, setDelayDeviceOnline] = useState(null)

	const [showWakingCameraConfirm, setShowWakingCameraConfirm] = useState(false)

	const [state, setState] = useState({
		eventDate: momenttz(),
		availableDates: [],
		initialEvents: [],
		events: [],
		page: 1,
		sizePerPage: 10,
		totalSize: 0,
		showVideoRequestModal: false,
		requestedEventIDs: [],
		sortOrder: '',
		// filtersVehiclePanel: {
		// 	event_types:[], 
		// 	statuses:{ pending: true, uploaded: true, alert_only: true }
		// },
		filtersVehiclePanel: {
			critical_gsensor: true,
			speed: true,
			driver: true,
			custom: true,
			pending: false,
			harshaccel: true,
			harshbraking: true,
			adas_fcw: true,
			adas_ldw: true,
			adas_pcw: true,
			adas_hw: true,
			tailgating: true,
			distraction: true,
			drowsiness: true,
			phone_use: true,
			yawning: true,
			seatbelt: true,
			smoking: true,
			camerablock: true,
			videouploaded: true,
			videopending: true,
			videononexist: true,
			videoconvertedfailed: true,
			videotransferringfailed: true,
			videofilesconverted: true,
			videofailed: true,
			videoundefined: true,
			feedback: false,
		},
		showSnapshot: false,

		showEventSnapshotModal: false,
		selectedEvent: null,
	})

	const prevStateRef = useRef(state.filtersVehiclePanel)
	const timerStartDeviceWakup = useRef(null) // timer, in 2mins after the device wake up starting will display "Sorry, the device could not wake up, try again later" 

	// useEffect(() => {
	// 	return () => {
	// 		clearLSTimer()
	// 	}
	// }, [])

	useEffect(() => {
		// debugger
		if (!showTripsHistory) {
			getAvailableDates()
		}

		return () => {
			clearTimer()
		}
	}, [activeTreeItem.device_id, showTripsHistory])

	useEffect(() => {
		// debugger
		// Set null address upon changing selected device
		// We should figure out just upon first open VehiclePanel and clicking on the Get Address
		if (markerInfo?.position?.lat) {
			getAddress()
		}
		else {
			setAddress('')
		}
	}, [activeTreeItem.device_id])

	useEffect(() => {
		// debugger
		if (state.availableDates.length) {
			const new_event_date = state.availableDates[state.availableDates.length - 1]
			setState((prevState) => ({ ...prevState, eventDate: new_event_date }))
			getDeviceEventFilters(new_event_date)
			getFleetEvents(state.page, state.sizePerPage, true, new_event_date)
		}
		else {
			setState((prevState) => ({ ...prevState, eventDate: momenttz(), events: [] }))
		}
		// else {
		// 	toggleLoader(false);
		// 	toastr.info('', 'No events available for this company')
		// }
		// }, [state.availableDates.length])
	}, [state.availableDates])

	useEffect(() => {
		setRssi_object(detectRSSIQuality(+activeTreeItem.modem_rssi))
	}, [activeTreeItem.modem_rssi])


	useEffect(() => {
		if (state.sortOrder) {
			getFleetEvents()
		}
	}, [state.sortOrder])

	useEffect(() => {
		if (!_.isEqual(state.filtersVehiclePanel, prevStateRef.current)
		) {
			getFleetEvents()
		}
		prevStateRef.current = _.cloneDeep(state.filtersVehiclePanel)
	}, [state.filtersVehiclePanel])

	// // FWSD-6986
	// // After clicking on LS button if device in SLEEP mode:
	// // 1. UI sends wakeup request
	// // 2. starts 5sec interval with checking if device has woken up (not sleep) and online and opens LS popup
	// // 3. starts 2min timer to display "Sorry" popup if during 2mins device has not woken up
	// useInterval((e) => {
	// 	if (activeTreeItem.online) { // device woke up
	// 		clearLSTimer()
	// 		// console.log('Device woke up')
	// 		setShowDeviceSleep(false)
	// 		toggleLoader(false)
	// 		if (activeTreeItem.online) {
	// 			const el = document.getElementById('liveStreamBtn')
	// 			if (el) {
	// 				el.click()
	// 			}
	// 		}
	// 	}
	// 	// console.log('!!!!!!!!!!!!!!!! Device online sleep, online: ', activeTreeItem.sleep, activeTreeItem.online)
	// }, delayDeviceOnline)

	const getAvailableDates = (callback, argDeviceId = null) => {
		const data = {
			device_id: argDeviceId || activeTreeItem.device_id,
			start_ts: '20150101000000',
		};
		getAvailableDatesOfDevice(data)
			.then(async (res, err) => {
				// console.log('!getAvailableDatesOfDevice res: ', res, err);
				const response = await res.data.response.events_dates
				// setState({ ...state, availableDates: response })
				setState((prevState) => ({ ...prevState, availableDates: response }))
				if (callback && typeof callback === 'function') {
					callback();
				}
			})
			.catch((error) => {
				console.log('!getAvailableDatesOfDevice error: ', error.response, error);

				let errDescription = i18n.t('errorMessage.errDescription');
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}

	const getDeviceEventFilters = (eventDateArg = null) => {
		const { user, company } = props
		const paramEventDate = eventDateArg || state.eventDate

		const paramsFilters = {
			user_role: userRole,
			company_name: company.name || user.company_name,
			partner_name: company.partner,
			start_ts: momenttz(moment(paramEventDate).format('YYYY-MM-DD 00:00:00')).utc().format('YYYYMMDDHHmmss'),
			end_ts: momenttz(moment(paramEventDate).format('YYYY-MM-DD 23:59:59')).utc().format('YYYYMMDDHHmmss'),
			device_id: activeTreeItem.device_id,
			geotab: 1,
		}

		// getEventsFilters(paramsFilters)
		//     .then(res => {
		//         // console.log('!getDeviceEventFilters res: ', res, err)
		// 		const filtersRes = res.data.response
		// 		const filtersVehiclePanelEventTypesResult = filtersRes.reduce((result, item) => {
		// 			if (item.geotab === 1) {
		// 				item.name = detectFilterName(item)
		// 				item.checked = true
		// 				result.push(item)	
		// 			}
		// 			return result
		// 		}, [])
		// 		const newFiltersVehiclePanel = { ...state.filtersVehiclePanel, event_types: filtersVehiclePanelEventTypesResult }

		// 		setState((prevState) => { return { ...prevState, filtersVehiclePanel: newFiltersVehiclePanel } })
		//     }) 
		//     .catch(error => {

		//         console.log('!getDeviceEventFilters error: ', error.response, error)

		//         if (error.response && error.response.data.response.error) {
		//             toastr.error(error.response.data.response.error)
		//         } else {
		//             toastr.error(JSON.stringify(error))
		//         }
		//     })
	}

	const getFleetEvents = (page = state.page, sizePerPage = state.sizePerPage, showLoader = true, argEventDate = null, company_name = null, callback = null, eventIds = null) => {
		// debugger
		const { user, company, activeTreeItem } = props
		const paramEventDate = argEventDate || state.eventDate
		const paramsEvents = {
			user_role: userRole,
			company_name: company.name || user.company_name,
			partner_name: company.partner,
			start_ts: momenttz(moment(paramEventDate).format('YYYY-MM-DD 00:00:00')).utc().format('YYYYMMDDHHmmss'),
			end_ts: momenttz(moment(paramEventDate).format('YYYY-MM-DD 23:59:59')).utc().format('YYYYMMDDHHmmss'),
			page_number: page,
			page_size: sizePerPage,
			// filters: getUncheckedFilters(),
			filters: getFilters(),
			videostatuses: getFiltersVideoStatuses(),
			search_type: 'deviceid',
			search: activeTreeItem.device_id,
			event_ids: eventIds,
		}

		if (state.sortOrder) {
			paramsEvents.order = `&order=timestamp&direction=${state.sortOrder}`
		}

		if (eventIds) {
			delete paramsEvents.page_number
			delete paramsEvents.page_size
		}


		if (showLoader) {
			toggleLoader(true)
		}

		getEventsOfCompanyAndRange(paramsEvents)
			.then(res => {
				const eventsRes = res.data.response

				if (eventIds && eventIds.length) {
					if (eventsRes.events.length) {
						const filteredEvents = state.events.map((event) => {
							const index = eventIds.indexOf(event.id);
							if (index > -1) {
								const obj = _.find(eventsRes.events, ['id', event.id]);
								if (obj
									&& ((obj.uploading_status === 'VIDEO_UPLOADED'
										&& obj.snapshot_status === 'SNAPSHOT_UPLOADED')
										|| obj.uploading_status === 'VIDEO_NON_EXIST'
									)
								) {
									let requestedEventIDs = state.requestedEventIDs;
									requestedEventIDs.splice(index, 1);
									setState((prevState) => { return { ...prevState, requestedEventIDs } })
									return obj
								}
							}

							return event
						})
						setState((prevState) => { return { ...prevState, events: filteredEvents } })
					}
				}
				else {
					setState((prevState) => {
						return {
							...prevState,
							events: eventsRes.events,
							totalSize: eventsRes.total_number,
							page: (eventsRes.page_number) ? eventsRes.page_number * 1 : page,
							sizePerPage: sizePerPage
						}
					})
				}

				if (!eventsRes.events.length) {
					toastr.info('', i18n.t('toastMessage.toastrDeviceEvents'));
				}

				if (callback && typeof callback === 'function') {
					callback();
				}

				if (showLoader) {
					toggleLoader(false);
				}
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!getEventsOfCompanyAndRange error: ', error.response, error);

				let errDescription = i18n.t('errorMessage.errDescription');
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}

	// const getUncheckedFilters = () => {
	// 	const res = [];

	// 	_.forOwn(state.filtersVehiclePanel.statuses, (value, key) => {
	// 		if (!value) {
	// 			res.push(key)
	// 		}
	// 	})

	// 	state.filtersVehiclePanel.event_types.filter(({ checked }) => !checked).forEach(({fulltype}) => res.push(fulltype))

	// 	return res
	// }

	const getFilters = () => {
		const res = []

		_.forOwn(state.filtersVehiclePanel, (value, key) => {
			if (!value && !key.includes("video")) {
				res.push(key)
			}
		})

		return res
	}

	const getFiltersVideoStatuses = () => {
		const res = [];
		let videostatuses = state.filtersVehiclePanel;
		_.forOwn(videostatuses, (value, key) => {
			if (!value && key.includes("video")) {
				res.push(key)
			}
		})

		return _.uniq(res).join(',');
	}

	// const startLSTimers = () => {
	// 	toggleLoader(true)
	// 	// console.log('startLSTimers')
	// 	//sends the device wakeup request 
	// 	apiCall('POST', '/devices/{device_id}/wakeup', { device_id: activeTreeItem.device_id })
	// 		.then((res, err) => {
	// 			// starts 5sec interval with checking if device has woken up (not sleep) and online and opens LS popup
	// 			setShowDeviceSleep(`Please wait while we wake the camera for live stream...`)
	// 			setDelayDeviceOnline(5000)
	// 			// console.log('startLSTimers setDelayDeviceOnline 5000')
	// 			// starts 2min timer to display "Sorry" popup if during 2mins device has not woken up
	// 			if (timerStartDeviceWakup.current) {
	// 				clearTimeout(timerStartDeviceWakup.current)
	// 				timerStartDeviceWakup.current = null
	// 			}
	// 			timerStartDeviceWakup.current = setTimeout(() => {
	// 				// console.log('!!!!!!!!!!! 2mins')
	// 				// console.log('timerStartDeviceWakup 2 minutes have passed')
	// 				setShowDeviceSleep(`Sorry, the device could not wake up, try again later.`)
	// 				toggleLoader(false)
	// 				clearLSTimer()
	// 			}, 120000)
	// 		})
	// 		.catch((error) => {
	// 			console.log('!startLSTimers error: ', error.response)
	// 			let errDescription = 'Unable to push wakeup to device. Please try again later.'
	// 			if (error.response.data.response.error) {
	// 				errDescription = error.response.data.response.error
	// 			}
	// 			toastr.error(errDescription)
	// 		})
	// }

	// const clearLSTimer = () => {
	// 	// console.log('clearLSTimer setDelayDeviceOnline null')
	// 	setDelayDeviceOnline(null)

	// 	clearTimeout(timerStartDeviceWakup.current)
	// 	timerStartDeviceWakup.current = null
	// }

	const onLiveStreamClick = (e, device) => {
		e.preventDefault()

		if (!device.online) {
			// toastr.warning('', 'Device is offline')
			toggleLoader(true)
			// Sends the device wakeup request 
			apiCall('POST', '/devices/{device_id}/wakeup', { device_id: device.device_id })
				.then((res) => {
					toggleLoader(false)

					// Shows the Waking Camera Confirm popup
					setShowWakingCameraConfirm(true)
					// Adds new device_id for requesting the device status each 5 secs
					setSessionDevicesIds([...new Set([...sessionDevicesIds, device.device_id])])
					// Adds new inactive notification which will be available in the User Notifications menu after waking up the device. In that moment status will be changed to 'active'.
					const newNotification = {
						status: 'inactive',
						notification: `Vehicle '${device.vehicle_name}' is now available for Live Streaming`,
						type: 'session',
						user_id: user.id,
						device_id: device.device_id,
						vehicle_name: device.vehicle_name,
						company_id: device.company_id, // company id, we need it to enable corresponding item in the Companies dropdown of right top corner
						company_name: company.name,
						partner_name: company.partner,  // partner name, we need it to enable corresponding item in the Partners dropdown of right top corner
					}
					setSessionNotifications([...sessionNotifications, newNotification])
				})
				.catch((error) => {
					toggleLoader(false)
					console.log('!Device wakeup error: ', error.response)
					let errDescription = i18n.t('errorMessage.errDescription');
					if (error.response?.data?.response?.error) {
						errDescription = error.response.data.response.error
					}
					toastr.error(errDescription)
				})
			return
		}
		else if (device.disable_live_stream) {
			toastr.warning('', t('streamDisabledContent'))
			return
		}

		openLiveStream(userRole, device)
	}

	const openClip = (event, row) => {
		event.preventDefault();
		if (!isMobileOnly) {
			props.openAdvancedPlayer(row);
		}
		else {
			const eventId = row.id;

			// Stores required params for the separate browser window to the local storage.
			const winOpen = {
				eventId,
				type: row.type,
				custom_event_type: row.custom_event_type,
				userRole: userRole,
				companyName: company.name,
				partnerName: company.partner,
				driverRecognition: company.driver_recognition,
				user: user,
			}
			localStorage.setItem('winOpen', JSON.stringify(winOpen));

			sendGA({ category: userRole, action: 'Open Event Video', label: row.type })

			const width = 1200
			const height = 700
			const w = window
			const d = document
			const e = d.documentElement
			const g = d.getElementsByTagName('body')[0]
			const winWidth = w.innerWidth || e.clientWidth || g.clientWidth
			const winHeight = w.innerHeight || e.clientHeight || g.clientHeight
			const winX = (document.all) ? window.screenLeft : window.screenX
			const winY = (document.all) ? window.screenTop : window.screenY
			const left = ((winWidth - width) / 2) + winX
			const top = ((winHeight - height) / 2) + winY
			const windowSpecs = `toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=1,width=${width},height=${height},left=${left},top=${top}`
			const target = eventId
			const win = window.open(`/event/video/${target}`, `${target}1`, windowSpecs);

			return win
		}
	}

	const openEventSnapshotModal = (row) => {
		setState((prevState) => ({ ...prevState, selectedEvent: row, showEventSnapshotModal: true }))
	}

	const sendRequestContextVideo = (eventArg) => {
		toggleLoader(true);

		const params = {
			event_id: eventArg.id,
			duration: 20,
		}
		apiCall('PUT', '/events/context_video_clip', params)
			.then(() => {
				toggleLoader(false);
				toastr.success('', i18n.t('toastMessage.toastrSuccessCVR'));
			})
			.catch((error) => {
				let errDescription = i18n.t('errorMessage.errDescription');
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}

	const openTripsHistory = (event) => {
		// debugger
		event.preventDefault();
		setShowTripsHistory(!showTripsHistory)
		sendGA({ category: userRole, action: 'Trip Replay', label: 'Open Trip Replay' })
	}

	const openVideoRequest = () => {
		setState({ ...state, showVideoRequestModal: true });
		sendGA({ category: userRole, action: 'Custom Video Request', label: 'Open Custom Video Request' })
	}

	const openManageVehicles = () => {
		const params = {
			name: company.name,
			partner: company.partner,
		}

		sendGA({ category: userRole, action: 'Manage Vehicles', label: 'Open Manage Vehicles' })

		browserHistory.push({ pathname: '/managevehicles/edit', state: { id: activeTreeItem.device_id, company: params, type: 'vehicle_sidebar', last_disconnected_ts: activeTreeItem.last_disconnected_ts } })

	}

	const onCloseVideoRequest = () => {
		sendGA({ category: userRole, action: 'Custom Video Request', label: 'Close Custom Video Request' })
		setState((prevState) => { return { ...prevState, showVideoRequestModal: false } })
	}

	const onSaveVideoRequest = (data) => {
		const paramRequestedEventIDs = [...state.requestedEventIDs, data.event_id]
		sendGA({ category: userRole, action: 'Custom Video Request', label: 'Submit Custom Video Request' })
		// setState((prevState) => { return { ...prevState, showVideoRequestModal: false, paramRequestedEventIDs } })
		setState((prevState) => { return { ...prevState, paramRequestedEventIDs } })

		getAvailableDates(() => {
			if (state.availableDates.length) {
				setState((prevState) => { return { ...prevState, eventDate: data.event_ts } })
				getFleetEvents(
					1,
					state.sizePerPage,
					true,
					data.event_ts
				)
			} else {
				toastr.info('', i18n.t('toastMessage.toastrDeviceEvents'))
			}
		})

		if (interval) {
			clearInterval(interval)
			setIntervalState(null);
		}
		setIntervalState(setInterval(() => {
			if (!state.requestedEventIDs.length) {
				clearInterval(interval)
				setIntervalState(null);
			} else {
				console.log('INTERVAL')
				getFleetEvents(state.page, state.sizePerPage, false, null, null, null, state.requestedEventIDs.join(','))
			}
		}, 5000));
	}

	const clearTimer = () => {
		if (interval) {
			clearInterval(interval)
			setIntervalState(null);
		}
	}

	const liveVideoAccess = () => {
		const userPermissions = user.permissions

		if (!company.company_data.live_active && ['fleet_manager', 'fleet_maintainer', 'group_manager', 'user'].indexOf(userRole) > -1) {
			return false
		}
		// FWSD-4552
		if (['custom_user', 'custom_partner'].includes(userRole) && !userPermissions.find((item) => item.perm_category_id === 14 && item.a_view)) {
			return false
		}

		return true
	}

	const editVehicleAccess = () => {
		if (['custom_user', 'custom_partner'].includes(user.roles[0]) && !_.find(user.permissions, { perm_category_id: 5, a_view: true })) {
			return false
		}

		return true
	}

	const getAddress = (e = null) => {
		e && e.stopPropagation()

		if (!markerInfo || !markerInfo.position) {
			return i18n.t('homePage.addressNotAvailable')
		}
		const data = { lat: markerInfo.position.lat, lng: markerInfo.position.lng };

		getDeviceAddress(data)
			.then((res) => {
				setAddress(formatAddress(res.data.response.address))
			})
			.catch(err => {
				console.log(err)
			})
	}

	const getSortIcon = () => {
		switch (state.sortOrder) {
			case 'asc':
				return <TiArrowSortedDown />
			case 'desc':
				return <TiArrowSortedUp />
			default:
				return <TiArrowUnsorted />
		}
	}

	const detectFilterName = (filterArg) => {
		let name = ''

		switch (filterArg.type) {
			case 'driver':
				name = 'External Driver Event'
				break
			case 'sensor':
				name = 'Input Sensor'
				break
			case 'gsensor':
				name = 'G-Sensor'
				break
			case 'critical_gsensor':
				name = 'Critical G-Sensor'
				break
			case 'custom':
				name = 'Custom Video'
				break
			case 'harshbraking':
				name = 'Harsh Braking'
				break
			case 'phoneuse':
				name = 'Phone Use'
				break
			case 'harshaccel':
				name = 'Harsh Acceleration'
				break
			/*case 'gnovideo':
				name = 'No Video Available'
				break
			case 'gpending':
				name = 'Pending'
				break*/
			case 'videouploaded':
				name = 'Uploaded'
				break
			case 'videopending':
				name = 'Pending'
				break
			case 'videononexist':
				name = 'No Video Found'
				break
			case 'videoconvertedfailed':
				name = 'Conversion Error'
				break
			case 'videotransferringfailed':
				name = 'Download Error'
				break
			case 'videofilesconverted':
				name = 'Files Converted'
				break
			case 'videofailed':
				name = 'Event Failed'
				break
			case 'videoundefined':
				name = 'Unknown'
				break
			case 'speed':
			case 'pending':
			case 'flood':
			case 'yawning':
			case 'drowsiness':
			case 'distraction':
			case 'seatbelt':
			case 'smoking':
				name = _.upperFirst(filterArg.type)
				break
			default:
				name = filterArg.type
				break
		}

		return name
	}

	const onSortClick = () => {
		if (state.sortOrder === 'desc') {
			setState((prevState) => ({ ...prevState, sortOrder: 'asc' }))
		}
		else {
			setState((prevState) => ({ ...prevState, sortOrder: 'desc' }))
		}
	}

	const changeView = () => {
		setState((prevState) => ({ ...prevState, showSnapshot: !state.showSnapshot }))
	}

	const getDriverName = () => {
		const redirect = () => {
			browserHistory.push({ pathname: "/managedrivers" })
		}

		let res = ''
		const hasDriverPageAccess = ['system_admin', 'customer_service', 'partner', 'fleet_manager', 'partner_view', 'sales_demo', 'fleet_maintainer'].includes(userRole)

		if (activeTreeItem.driver_name) {
			if (hasDriverPageAccess) {
				res = <CustomButton variant="link" onClick={redirect}>{activeTreeItem.driver_name}</CustomButton>
			}
			else {
				res = <>{activeTreeItem.driver_name}</>
			}
		}

		return res
	}

	const setFiltersVehiclePanel = (filtersArg) => {
		setState((prevState) => ({ ...prevState, filtersVehiclePanel: filtersArg }))
		// getFleetEvents()
	}

	// FWSD-6748
	const getLiveStreamDisabledContent = () => {
		if (totalDeviceDataUsage > monthlyFleetDataUsageAvailable) {
			return t('dataUsageStreamDisabledContent')
		}
		if (!activeTreeItem.online && !activeTreeItem.sleep) {
			return t('offlineStreamDisabledContent')
		}
		if (!activeTreeItem.disable_live_stream || !company.company_data.live_active) {
			return t('streamDisabledContent')
		}

		return t('defaultStreamDisabledContent')
	}

	const setPropStates = (states, callback = null) => {
		setState({ ...state, ...states });
		if (typeof callback === 'function') {
			callback();
		}
	}

	let menuItemWidth = 3
	if (!liveVideoAccess() && !editVehicleAccess()) {
		menuItemWidth = 6
	}
	else if (!liveVideoAccess() || !editVehicleAccess()) {
		menuItemWidth = 4
	}

	if (!activeTreeItem) {
		return <div>{t('noActiveTreeItem')}</div>
	}

	const CustomVideoRequestMemo = useMemo(() =>
	(<CustomVideoRequest
		propsSelectedDevice={activeTreeItem}
		propsSelectedDate={state.eventDate}
		// deviceSelection={false}
		// device={activeTreeItem}
		onHide={onCloseVideoRequest}
		onSave={onSaveVideoRequest}
	/>), [activeTreeItem]);

	// console.log('STATE VehicleSidebar state: ', state)
	// console.log('PROPS VehicleSidebar: ', props)

	return (
		<div className="sidebarContent">
			<div className="headerWrapper">
				<div className="header">
					<div>
						<span className="vehicle-name">{activeTreeItem.vehicle_name || activeTreeItem.device_id}</span>
						<span className="vehicle-group">({activeTreeItem.group_name})</span>
					</div>
					{editVehicleAccess() &&
						<CustomButton variant="link" onClick={openManageVehicles}>
							{vehicleSidebarIcons.editVehicle}
						</CustomButton>
					}
					{/* {activeTreeItem.driver_name
						&& <span>{`   - ${activeTreeItem.driver_name}`}</span>} */}
				</div>
				<div className="vehicle-data">
					<div className="status-row">
						<span className="title">{t('titleStatus')}</span>
						<div className={`icon ${activeTreeItem.status.replace(' ', '_')}`} />
						<span className="title">{i18n.t(`homePage.fleetStatuses.${getDeviceStatusName(activeTreeItem.status).replace(' ', '_')}`, getDeviceStatusName(activeTreeItem.status))}</span>
					</div>
					<div className="status-row">
						<span className="title">{t('titleSignal')}</span>
						<div className="rssi-signal">
							{activeTreeItem.online && !!activeTreeItem.modem_rssi && !!rssi_object &&
								<img src={`/images/${rssi_object.image}`} alt={t('activeTreeItemAlt')} />
							}
						</div>
						{/* {activeTreeItem.modem_rssi} */}
					</div>
					<div className="status-row">
						<span className="title">{t('titleSpeed')}</span>
						{activeTreeItem.online && activeTreeItem?.ignition?.state && markerInfo?.infoData?.spd && (
							<div className='node-icon-wrapper'>
								{formatSpeedMPH(markerInfo.infoData.spd)}
							</div>
						)}
					</div>
					<div className="status-row">
						<span className="title">{t('titleDriver')}</span>
						<span className="title">{getDriverName()}</span>
					</div>
					<div className="status-row">
						<span className="title">{t('titleLocation')}</span>
						{address
							? address
							:
							<CustomButton variant="link" className='address-button node-icon-wrapper' onClick={getAddress} role="menuitem">
								{t('addressButton')}
							</CustomButton>
						}
					</div>
					{address &&
						<div className="status-row">
							<span className="title" />
							<CustomButton variant="link" className='address-button node-icon-wrapper' onClick={getAddress} role="menuitem">
								{t('addressButton')}
							</CustomButton>
						</div>
					}
					{/* TODO: remove upon commit to PROD, used for TESTING */}
					{/* {markerInfo && <div>{JSON.stringify(markerInfo.position)}</div>} */}
				</div>
				<div className="navigation">
					{liveVideoAccess() && (
						(/*!isInternalUser(userRole) &&*/ isLiveStreamDisabled === true)
							? (
								<CustomOverlayTrigger
									{...{ type: 'warn', title: t('titleLiveStreamingPaused'), body: getLiveStreamDisabledContent() }}
									placement="top"
									// trigger="hover"
									trigger={["hover", "hover"]}
									closeBtn={false}
									prefix='warn'
								>
									<div>
										<CustomButton variant='link' prefix="menuItem disabled" title={t('titleLiveStreaming')} disabled style={{ cursor: 'auto' }}>
											{vehicleSidebarIcons.liveStream}
										</CustomButton>
									</div>
								</CustomOverlayTrigger>
							)
							:
							// <CustomButton id="liveStreamBtn" variant='link' prefix='menuItem' onClick={openLive} title='Live Stream'>
							<CustomButton id="liveStreamBtn" variant='link' prefix='menuItem' onClick={(e) => onLiveStreamClick(e, activeTreeItem)} title='Live Stream'>
								{vehicleSidebarIcons.liveStream}
							</CustomButton>
					)}
					{isCVRDisabled
						?
						<CustomButton variant='link' prefix='menuItem disabled' title={t('titleCVR')} disabled style={{ cursor: 'auto' }}>
							{vehicleSidebarIcons.customVideoRequest}
						</CustomButton>
						:
						<CustomButton variant='link' prefix='menuItem' onClick={openVideoRequest} title={t('titleCVR')}>
							{vehicleSidebarIcons.customVideoRequest}
						</CustomButton>
					}
					<CustomButton variant='link' prefix='menuItem' onClick={openTripsHistory} title={t('titleRouteHistory')}>
						{showTripsHistory && <div className="stop-route-history-title">{t('titleLiveStreamStopped')}</div>}
						{vehicleSidebarIcons.routeHistory}
					</CustomButton>
				</div>
			</div>

			{showTripsHistory
				? (
					<TripsGrid
						_map={_map}
						eventDate={state.eventDate}
						activeTreeItem={activeTreeItem}
						setPropStates={setPropStates}
						onClickPlayback={onClickPlayback}
					/>
				)
				: (
					<div className="vehicle-events-wrapper">
						<div className="date date-container-mobile">
							<AvailableDatesNav
								eventDate={state.eventDate}
								availableDates={state.availableDates}
								sizePerPage={state.sizePerPage}
								setPropStates={setPropStates}
								getFleetEvents={getFleetEvents}
								getDeviceEventFilters={getDeviceEventFilters}
								clearTimer={clearTimer}
							/>
						</div>
						<div className="vehicle-events-view">
							<div className="order-filter">
								<CustomButton variant="link" style={{ fontSize: '21px', padding: 0, color: '#000' }} onClick={onSortClick}>
									{getSortIcon()}
								</CustomButton>
								<VehicleSidebarFilter
									userRole={userRole}
									user={user}
									filters={state.filtersVehiclePanel}
									setFiltersVehiclePanel={setFiltersVehiclePanel}
								/>
							</div>

							{/* Events view selection */}
							<div className='view-format-select'>
								<CustomButton variant="primary-outline" size="icon" active={!state.showSnapshot} onClick={changeView}>
									{eventIcons.tableViewIcon}
								</CustomButton>

								<CustomButton variant="primary-outline" size="icon" active={state.showSnapshot} onClick={changeView}>
									{eventIcons.gridViewIcon}
								</CustomButton>
							</div>
						</div>
						<EventsTable
							events={state.events}
							showSnapshot={state.showSnapshot}
							openClip={openClip}
							openEventSnapshot={openEventSnapshotModal}
							sendRequestContextVideo={sendRequestContextVideo}
						/>
					</div>
				)
			}

			{state.showVideoRequestModal && (CustomVideoRequestMemo)}

			{state.showEventSnapshotModal &&
				<EventViewSnapshotModal
					event={state.selectedEvent}
					onHide={() => setState((prevState) => ({ ...prevState, showEventSnapshotModal: false, selectedEvent: null }))}
				/>
			}

			{/* {showDeviceSleep &&
				<ModalDelete
					title={t('titleShowDeviceSleep')}
					content={showDeviceSleep}
					closeButton={false}
					showClose={false}
					showDelete={false}
					closeModal={() => {
						setShowDeviceSleep(false)
						toggleLoader(false)
					}}
				/>
			} */}

			{showWakingCameraConfirm && (
				<ModalDelete
					title="Waking Up Camera"
					content={`Please wait, waking camera to connect for live stream. \n\n You will receive a notification when live streaming is available.`}
					styleView="primary"
					deleteBtnName="OK"
					closeButton={false}
					deleteModal={() => setShowWakingCameraConfirm(false)}
				/>
			)}
		</div>
	)
}

VehicleSidebar.propTypes = {
	user: PropTypes.objectOf(PropTypes.any).isRequired,
	company: PropTypes.objectOf(PropTypes.any).isRequired,
	showTripsHistory: PropTypes.bool.isRequired,
	activeTreeItem: PropTypes.object,
	companyDevicesCount: PropTypes.number.isRequired,
	setShowTripsHistory: PropTypes.func.isRequired,
}

const mapStateToProps = ({ user, company, dashboardData, notification }) => ({
	user: user.user,
	company: company.company,
	showTripsHistory: dashboardData.showTripsHistory,
	sessionNotifications: notification.sessionNotifications,
	sessionDevicesIds: notification.sessionDevicesIds,
});

const mapDispatchToProps = dispatch => ({
	setShowTripsHistory: (state) => {
		dispatch({ type: 'SET_SHOW_TRIP_HISTORY', payload: state });
	},
	toggleLoader: (show) => {
		dispatch({ type: 'TOGGLE_LOADER', payload: show });
	},
	setSessionNotifications: (state) => {
		dispatch({ type: 'SET_SESSION_NOTIFICATIONS', payload: state });
	},
	setSessionDevicesIds: (state) => {
		dispatch({ type: 'SET_SESSION_DEVICES_IDS', payload: state });
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(VehicleSidebar);
