import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { addReportSubscription, deleteReportSubscription, getReportSubscriptions, updateReportSubscription } from '../../../../action/ReportsActions'
import { toastr } from 'react-redux-toastr'
import { useTranslation } from 'react-i18next'
import CustomButton from '../../../CustomButton'

const AddSubscriptionModal = (props) => {
	const { user, report, company, onHide, loaderModal, toggleLoaderModal } = props;
	const { t, i18n } = useTranslation('translation', { keyPrefix: 'reportsPage.addSubscriptionModal' });
	const [subscriptions, setSubscriptions] = useState([])
	const [initData, setInitData] = useState([])
	const [showConfirmModal, setShowConfirmModal] = useState(false)
	const [selectedReport, setSelectedReport] = useState(null)

	useEffect(() => {
		getSubscriptions()
	}, [])

	const getSubscriptions = () => {
		let fields = ''
		if (report) {
			fields = 'report_type=' + report.type + '&report_id=' + report.report_id
		}
		toggleLoaderModal(true);
		getReportSubscriptions(user.email, fields)
			.then(res => {
				let subscriptions = res.data.response.subscriptions;
				if (!subscriptions.length) {
					subscriptions.push({
						frequency: 'weekly',
						day_run: 'Mon',
						subscribers: [],
						currentEmail: '',
						emailError: ''
					})
				}
				setInitData(JSON.parse(JSON.stringify(subscriptions)));
				setSubscriptions(subscriptions);
				toggleLoaderModal(false);
			})
			.catch(err => {
				toggleLoaderModal(false);
				console.log(err)
			})
	}

	const setSubscriptionOptions = (index, field, value) => {
		const newSubscriptions = [...subscriptions]

		if (field === 'frequency' && ["weekly", "biweekly"].includes(value) && !newSubscriptions[index].day_run) {
			newSubscriptions[index].day_run = 'Mon'
		}
		newSubscriptions[index][field] = value
		setSubscriptions(newSubscriptions)
	}

	const checkEmail = (argEmail) => {
		const re = /^([a-zA-Z0-9_\-\.\+]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(argEmail)
	}

	const removeEmail = (index, email) => {
		const subIndex = subscriptions[index].subscribers.indexOf(email);
		const newSubscribers = [...subscriptions[index].subscribers];
		newSubscribers.splice(subIndex, 1)
		setSubscriptionOptions(index, 'subscribers', newSubscribers)
	}

	const addSubscription = () => {
		setSubscriptions([...subscriptions, {
			frequency: 'weekly',
			day_run: 'Mon',
			subscribers: [],
			currentEmail: '',
			emailError: ''
		}])
	}

	const removeSubscription = (index) => {
		const newSubscriptions = [...subscriptions];
		newSubscriptions.splice(index, 1)
		setSubscriptions(newSubscriptions)
	}

	const submitSubscription = () => {
		const requestArray = [];
		for (let [index, sub] of subscriptions.entries()) {
			if (sub.id) {
				const initSub = initData[index]
				if (!_.isEqual(sub, initSub)) {
					const data = {
						id: sub.id
					}
					for (let field of ['day_run', 'subscribers', 'frequency']) {
						if (!_.isEqual(initSub[field], sub[field])) {
							data[field] = sub[field]
						}
					}
					// if (!data.subscribers?.length) {
					// 	delete data.subscribers
					// }
					if (!data.subscribers.includes(user.email)) {
						data.subscribers.push(user.email);
					}
					requestArray.push(updateReportSubscription(data));
				}
			}
			else {
				const subscribers = sub.subscribers;
				if (!subscribers.includes(user.email)) subscribers.push(user.email);
				const data = {
					report_id: report.report_id,
					report_type: report.type,
					subscribers: subscribers,
					frequency: sub.frequency,
					company_id: company.company_id
				}
				if (['biweekly', 'weekly'].includes(sub.frequency)) {
					data.day_run = sub.day_run
				}
				requestArray.push(addReportSubscription(data));
			}
		}
		if (requestArray.length) {
			toggleLoaderModal(true);
			Promise.all(requestArray)
				.then(res => {
					toggleLoaderModal(false);
					toastr.success(t('toastrUpdatedSettings'))
					onHide();
				})
				.catch(err => {
					toggleLoaderModal(false);
					toastr.error(t('toastrUnableUpdateSettings'))
				})
		}
		else {
			toastr.info(t('toastrNoChangesMade'))
			onHide();
		}
	}

	const unsubscribe = () => {
		if (user.id === selectedReport.owner_id || selectedReport.subscribers.length === 1) {
			toggleLoaderModal(true);
			deleteReportSubscription(selectedReport.id)
				.then(res => {
					toastr.success(t('toastrUnsubscribed', { selectedReportName: selectedReport.report_name }))
					setSelectedReport({})
					setShowConfirmModal(false)
					getSubscriptions();
				})
				.catch(err => {
					toastr.error(t('toastrUnableUnsubscribe'))
					setSelectedReport({})
					setShowConfirmModal(false)
					getSubscriptions();
				})
		}
		else {
			toggleLoaderModal(true);
			let data = {
				id: selectedReport.id,
				subscribers: selectedReport.subscribers.filter(user => user !== props.user.email)
			}
			updateReportSubscription(data)
				.then(res => {
					toastr.success(t('toastrUnsubscribed', { selectedReportName: selectedReport.report_name }))
					setSelectedReport({})
					setShowConfirmModal(false)
					getSubscriptions();
				})
				.catch(err => {
					toastr.error(t('toastrUnableUnsubscribe'))
					setSelectedReport({})
					setShowConfirmModal(false)
					getSubscriptions();
				})
		}
	}

	return (
		<Modal
			show
			onHide={onHide}
			className="reports-modal modal-lg-size"
		>
			<Modal.Header closeButton>
				<Modal.Title>{t('mainTitle')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="share-report-body">
					<div className='modal-subtitle'>
						{t('subTitle')}
					</div>
					{subscriptions.map((sub, index) =>
						(!sub.id || user.id === sub.owner_id)
							? (
								<div className='modal-grid' key={index}>
									<div className='grid-item'>
										<span className='column-header'>{t('columnHeader')}</span>
										<select
											placeholder="weekly"
											className="rosco-select"
											onChange={(e) => setSubscriptionOptions(index, 'frequency', e.target.value)}
											value={sub.frequency}
										>
											<option value="daily">{i18n.t('reportsPage.reportConst.daily')}</option>
											<option value="weekly">{i18n.t('reportsPage.reportConst.weekly')}</option>
											<option value="biweekly">{i18n.t('reportsPage.reportConst.biweekly')}</option>
											<option value="monthly">{i18n.t('reportsPage.reportConst.monthly')}</option>
										</select>
									</div>
									<div className='grid-item'>
										<span className='column-header'>{t('headerAddAdditional')}</span>
										<input type='text' style={{ paddingTop: '13px' }} className='vehicle-search' placeholder={t('placeholderEnterEmails')} value={sub.currentEmail}
											onKeyDown={(e) => {
												if (['Enter', ',', ' ', 'Tab'].indexOf(e.key) > -1) {
													e.preventDefault()
													const email = e.target.value
													if (checkEmail(email)) {
														setSubscriptionOptions(index, 'subscribers', [...sub.subscribers, email])
														setSubscriptionOptions(index, 'currentEmail', '')
													}
													else {
														setSubscriptionOptions(index, 'emailError', t('textEnterValidEmail'))
													}
												}
											}}
											onBlur={(e) => {
												const email = e.target.value
												if (checkEmail(email)) {
													setSubscriptionOptions(index, 'subscribers', [...sub.subscribers, email])
													setSubscriptionOptions(index, 'currentEmail', '')
												}
												else {
													email && setSubscriptionOptions(index, 'emailError', t('textEnterValidEmail'))
												}
											}}
											onChange={(e) => {
												setSubscriptionOptions(index, 'currentEmail', e.target.value)
												setSubscriptionOptions(index, 'emailError', '')
											}}
										/>
										<span className='error-message'>{sub.emailError}</span>
										{sub.subscribers.map(email =>
											<div className='email-wrapper' key={email}>
												<span className='email'>{email}</span>
												<button className='remove' onClick={() => removeEmail(index, email)}>x</button>
											</div>
										)}
									</div>
									{["weekly", "biweekly"].includes(sub.frequency) &&
										<div className='grid-item day-select'>
											<span className='column-header'>{t('headerSelectDay')}</span>
											<div className='button-group date-select'>
												<CustomButton size='sm' variant='primary' active={sub.day_run === 'Sun'} onClick={() => setSubscriptionOptions(index, 'day_run', 'Sun')}>{i18n.t('reportsPage.reportConst.Sun')}</CustomButton>
												<CustomButton size='sm' variant='primary' active={sub.day_run === 'Mon'} onClick={() => setSubscriptionOptions(index, 'day_run', 'Mon')}>{i18n.t('reportsPage.reportConst.Mon')}</CustomButton>
												<CustomButton size='sm' variant='primary' active={sub.day_run === 'Tue'} onClick={() => setSubscriptionOptions(index, 'day_run', 'Tue')}>{i18n.t('reportsPage.reportConst.Tue')}</CustomButton>
												<CustomButton size='sm' variant='primary' active={sub.day_run === 'Wed'} onClick={() => setSubscriptionOptions(index, 'day_run', 'Wed')}>{i18n.t('reportsPage.reportConst.Wed')}</CustomButton>
												<CustomButton size='sm' variant='primary' active={sub.day_run === 'Thu'} onClick={() => setSubscriptionOptions(index, 'day_run', 'Thu')}>{i18n.t('reportsPage.reportConst.Thu')}</CustomButton>
												<CustomButton size='sm' variant='primary' active={sub.day_run === 'Fri'} onClick={() => setSubscriptionOptions(index, 'day_run', 'Fri')}>{i18n.t('reportsPage.reportConst.Fri')}</CustomButton>
												<CustomButton size='sm' variant='primary' active={sub.day_run === 'Sat'} onClick={() => setSubscriptionOptions(index, 'day_run', 'Sat')}>{i18n.t('reportsPage.reportConst.Sat')}</CustomButton>
											</div>
										</div>
									}
									{sub.id
										?
										<div className='grid-item day-select'>
											<CustomButton
												variant="link"
												onClick={() => {
													setSelectedReport(sub)
													setShowConfirmModal(true)
												}}
											>
												{t('btnUnsubscribe')}
											</CustomButton>
										</div>
										:
										index !== 0 &&
										<div className='grid-item day-select'>
											<CustomButton variant="link" onClick={() => removeSubscription(index)}>{t('btnDelete')}</CustomButton>
										</div>
									}
								</div>
							) : (
								<div className='subscription-wrapper' key={index}>
									<div className='subscription-details'>
										<div className='frequency'>
											<strong>{sub.frequency === 'biweekly' ? 'Bi-Weekly' : (sub.frequency.charAt(0).toUpperCase() + sub.frequency.substring(1))}</strong>{t('textReport')}
											{["weekly", "biweekly"].includes(sub.frequency) &&
												<span className='day-run'>
													{t('textScheduledFor')}<strong>{sub.day_run}</strong>
												</span>
											}
											{sub.last_run &&
												<span>{t('textLastRun')}<strong>{sub.last_run}</strong></span>
											}
										</div>
									</div>
									<div className='grid-item day-select'>
										<CustomButton
											variant="link"
											onClick={() => {
												setSelectedReport(sub)
												setShowConfirmModal(true)
											}}
										>
											{t('btnUnsubscribeDay')}
										</CustomButton>
									</div>
								</div>
							)
					)}
					{subscriptions.length < 4 &&
						<div className='add-subscription-wrapper'>
							<CustomButton size="lg" variant='secondary-outline' onClick={addSubscription}>{t('btnAddAnotherSub')}</CustomButton>
						</div>
					}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<div className="button-wrapper">
					<CustomButton size='sm' variant="primary" onClick={submitSubscription}>{t('btnApply')}</CustomButton>
				</div>
			</Modal.Footer>
			{loaderModal}
			{showConfirmModal &&
				<Modal
					show
					onHide={() => setShowConfirmModal(false)}
					className="reports-modal modal-lg-size"
				>
					<Modal.Header closeButton>
						<Modal.Title>{t('btnAreYouSure')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p className='text-center'>
							{t('btnUnsubscribeReport', { selectedReportName: i18n.t(`reportsPage.standardReportData.${selectedReport.report_name.replace(/\s/g, "_")}`) })}
						</p>
					</Modal.Body>
					<Modal.Footer>
						<CustomButton variant="delete" onClick={unsubscribe}>{t('btnFooterUnsubscribe')}</CustomButton>
					</Modal.Footer>
				</Modal>
			}
		</Modal>
	)
}

export default connect(
	state => ({
		user: state.user.user,
		company: state.company.company,
		loaderModal: state.loader.loaderModal,
	}),
	dispatch => ({
		toggleLoaderModal: (show) => {
			dispatch({ type: 'TOGGLE_LOADER_MODAL', payload: show });
		}
	})
)(AddSubscriptionModal);
