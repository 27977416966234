// FWSD-5066, user has to select another profile to be preferred when removes the current preferred profile

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import CustomButton from '../../CustomButton'

const DeletePreferredProfileModal = (props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'manageVehiclesPage.fleetConfigurationContainer.deletePreferredProfileModal' });
	const { profiles, profile, onHide, deleteProfile } = props;

	const [newProfileId, setNewProfileId] = useState(0);

	const selectNewProfile = (e) => {
		setNewProfileId(e.target.value);
	}

	return (
		<Modal
			show
			onHide={onHide}
			className="config-modal modal-lg-size"
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-lg">{t('titleDeletePreferredProfile')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="config-modal-body">
					<label><strong>{profile.name}</strong> <span dangerouslySetInnerHTML={{ __html: t('textPreferredProfile') }} /></label>
					<div className="text-center">
						<select
							name="profile-select"
							className="rosco-select"
							value={newProfileId}
							onChange={selectNewProfile}
						>
							<option hidden value={-1}>{t('selectNewProfile')}</option>
							{profiles.map((item) =>
								<option key={item.id} value={item.id} className="menu-item">{item.name}</option>
							)}
						</select>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<CustomButton variant="primary-outline" onClick={onHide}>
					{t('btnCancel')}
				</CustomButton>
				<CustomButton variant="primary" onClick={() => deleteProfile(newProfileId)}>
					{t('btnNext')}
				</CustomButton>
			</Modal.Footer>
		</Modal>
	);
}

DeletePreferredProfileModal.propTypes = {
	profile: PropTypes.object.isRequired,
	profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
	deleteProfile: PropTypes.func.isRequired,
	onHide: PropTypes.func.isRequired,
}

export default DeletePreferredProfileModal;
