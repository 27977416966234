import React from 'react';
import { Link } from 'react-router';
import { useTranslation } from 'react-i18next';

const NotFoundComponent = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.notFoundComponent' });

  return(
    <div className='panel-body'>
      <article>
        <h3>{t('error404')}</h3>
        <h5>{t('pageNotFound')}</h5>
        <div>{t('goBack')}<Link to="/">{t('linkHome')}</Link></div>
      </article>
    </div>
  );
}

export default NotFoundComponent;
