import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import GreyHeader from '../../component/GreyHeader'
import DriversPage from './DriversPage'
import DriversScoring from './DriversScoring'
import DriversCoaching from './DriversCoaching'
import CustomButton from '../../component/CustomButton';
import { sendGA } from '../../component/helpers/helperUsers'

const DriversContainer = (props) => {
	const { t } = useTranslation('translation', { keyPrefix: 'driversPage.driversContainer' });
	const { location, user } = props;
	const userRole = user.roles[0];
	const [tabKey, setTabKey] = useState(1);

	const handleNavigation = (tabKey) => {
		setTabKey(tabKey)

		const label = tabKey === 1 ? 'At a Glance' : tabKey === 2 ? 'Scoring' : 'Coaching'
		sendGA({ category: userRole, action: 'Change Drivers Tab', label })
	}

	return (
		<>
			<GreyHeader
				title={t('titleDrivers')}
				renderSubtitle={
					<nav className='page-subtitle'>
						<Button variant='subtitle' className={classNames('subtitle-nav', { 'active': tabKey === 1 })} onClick={() => handleNavigation(1)}>
							{t('btnAtAGlance')}
						</Button>
						<Button variant='subtitle' className={classNames('subtitle-nav', { 'active': tabKey === 2 })} onClick={() => handleNavigation(2)}>
							{t('btnScoring')}
						</Button>
						<Button variant='subtitle' className={classNames('subtitle-nav', { 'active': tabKey === 3 })} onClick={() => handleNavigation(3)}>
							{t('btnCoaching')}
						</Button>
					</nav>
				}
			/>

			{tabKey === 1 &&
				<DriversPage
					location={location}
				/>
			}
			{tabKey === 2 &&
				<DriversScoring />
			}
			{tabKey === 3 &&
				<DriversCoaching />
			}
		</>
	);
};

export default connect(
	state => ({
		user: state.user.user,
		company: state.company.company,
	}),
)(DriversContainer);
